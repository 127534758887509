import React, { Component } from "react";
import axios from "axios";
import "./SelfAssesmentForm.scss";
import { connect } from "react-redux";
import { store } from "../../../../reducers/Persist";
import dataResource from "../../../../constants/dataResource.js";
import config from "../../../../constants/apiList";

const tags = [
  "Skin",
  "Hair",
  "Fitness",
  "Health",
  "Nutrition",
  "Performance",
  "Sleep",
  "Stress"
];
class SelfAssessmentForm extends Component {
  // add state object
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      selectedTag: "DA",
      FU_ques: {},
      questionMap: {}
    };
  }
  jsonEscape = (str = "") => {
    return str
      ?.replace(/\n/g, ". ")
      ?.replace(/\r/g, "\\\\r")
      ?.replace(/\t/g, "\\\\t");
  };

  fetchDAFormJson = async () => {
    const daFormUrls = {
      MM: config.url.DA_QUESTION_JSON_MM,
      BW: config.url.DA_QUESTION_JSON_BW
    };
    const url = daFormUrls[this.props.brand];
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common["Authorization"];
      const daFormResponse = await instance.get(url);
      let questionMap = {};
      for (const data of daFormResponse.data) {
        questionMap[data.question] = data.text;
      }
      this.setState({
        questionMap: questionMap
      });
    } catch (err) {
      console.log("Unable to fetch DA form JSON", err);
    }
  };

  parseFUQuestions = fuQuestionString => {
    try {
      return JSON.parse(fuQuestionString);
    } catch (err) {
      console.log("ERROR IN Parsing", err);
      return null;
    }
  };
  componentDidMount() {
    this.fetchDAFormJson();
    let item = {};
    for (let i = 0; i < tags.length; i++) {
      if (!!this.props.prescriptionData?.form_details?.[tags[i]]) {
        let temp = {
          [tags[i]]: this.props.prescriptionData?.form_details?.[tags[i]]
        };
        item = { ...item, ...temp };
      }
    }

    this.setState({ formData: item });
    if (Object.keys(item).length > 0)
      this.setState({ selectedTag: Object.keys(item)[0] });
    if (this.props.prescriptionData.FU_ques) {
      let obj = this.parseFUQuestions(
        this?.props?.prescriptionData?.FU_ques || {}
      );
      this.setState({
        FU_ques: !!obj ? obj : {}
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.prescriptionData.FU_ques !==
        this.props.prescriptionData.FU_ques &&
      this.props.prescriptionData.FU_ques
    ) {
      let obj = this.parseFUQuestions(
        this?.props?.prescriptionData?.FU_ques || {}
      );
      this.setState({
        FU_ques: !!obj ? obj : {}
      });
    }
  }

  render() {
    return (
      <div className="margin-30 SelfAssesmentForm ">
        <div className="title">Patient Form</div>
        <div className="tag-row">
          {Object.keys(this.state.formData).map((key, index) => {
            return (
              <div
                key={index}
                className={
                  key === this.state.selectedTag ? "selectedTag" : "tags"
                }
                onClick={() => this.setState({ selectedTag: key })}
              >
                {key}
              </div>
            );
          })}
          {!!this.props.prescriptionData?.form_details ? null : (
            <div
              className={
                "DA" === this.state.selectedTag ? "selectedTag" : "tags"
              }
              onClick={() => {
                this.setState({ selectedTag: "DA" });
              }}
            >
              {"Direct Appointment"}
            </div>
          )}
        </div>
        <div className="qna">
          {this.state.formData?.[this.state.selectedTag] &&
            Object.keys(this.state.formData?.[this.state.selectedTag])?.map(
              (item, index) => {
                if (this.state.formData?.[this.state.selectedTag]?.[item])
                  return (
                    <div key={index}>
                      <div className="question">{item}</div>
                      <div className="answer">
                        <div className="ans"> A: </div>{" "}
                        {this.state.formData?.[this.state.selectedTag]?.[item]}
                      </div>
                      {index !==
                        Object.keys(
                          this.state.formData?.[this.state.selectedTag]
                        )?.length -
                          1 && <hr className="divider" />}
                    </div>
                  );
                else return null;
              }
            )}
          {this.state.selectedTag === "DA" ? (
            Object.keys(this.state.FU_ques).length > 0 &&
            Object.keys(this.state.FU_ques).map(
              (val, index) =>
                (dataResource.FU_Questions[val] ||
                  this.state.questionMap[val] ||
                  val) &&
                this.state.FU_ques[val] && (
                  <div className="selfAssessment__questionAnswer" key={index}>
                    <h6>
                      {dataResource.FU_Questions[val] ||
                        this.state.questionMap[val] ||
                        val}
                    </h6>
                    <p>A : {this.state.FU_ques[val]}</p>
                  </div>
                )
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
const mapSateToProps = (state, ownProps) => ({
  prescriptionData: store.getState().rxWriterReducer.prescriptionData,
  brand: store.getState().rxWriterReducer.brand
});

export default connect(mapSateToProps)(SelfAssessmentForm);

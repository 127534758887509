import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HeaderActionTable from "../../common/Tables/HeaderActionTable.js";
import HeaderActionTable2 from "../../common/Tables/HeaderActionTable2.js";
import Loader from "react-loader-spinner";

export default function CapacityManagement() {
  let today = moment().format("YYYY-MM-DD");
  const [brand, setBrand] = useState("all");
  const [doctors, setDoctors] = useState([]);
  const [doctorIds, setDoctorIds] = useState([]);
  const [hourwiseData, setHourwiseData] = useState({});
  const [hourwiseColumns, setHourwiseColumns] = useState([]);
  const [hourwiseRows, setHourwiseRows] = useState([]);
  const [viewType, setViewType] = useState("all");
  const [showHourwiseLoader, setShowHourwiseLoader] = useState(false);

  const [fromMonth, setFromMonth] = useState(
    moment().subtract(1, "month").format("YYYY-MM")
  );
  const [monthwiseData, setMonthwiseData] = useState({});
  const [monthwiseColumns, setMonthwiseColumns] = useState([]);
  const [monthwiseRows, setMonthwiseRows] = useState([]);
  const [interval, setInterval] = useState("history");
  const [daywiseFromDate, setDaywiseFromDate] = useState(
    moment().subtract(1, "d").format("YYYY-MM-DD")
  );
  const [daywiseToDate, setDaywiseToDate] = useState(
    moment().subtract(1, "d").format("YYYY-MM-DD")
  );
  const [daywiseInterval, setDaywiseInterval] = useState("history");
  const [daywiseColumns, setDaywiseColumns] = useState([]);
  const [daywiseRows, setDaywiseRows] = useState([]);
  const [daywiseData, setDaywiseData] = useState([]);

  const [doctorwiseData, setDoctorwiseData] = useState([]);
  const [doctorwiseRows, setDoctorwiseRows] = useState([]);
  const [doctorwiseColumns, setDoctorwiseColumns] = useState([]);
  const [doctorwiseDay, setDoctorwiseDay] = useState(
    moment().utcOffset(330).format("YYYY-MM-DD")
  );

  const arrowButtonClickHandler = evt => {
    getHourwiseData();
    getMonthwiseData();
    getDaywiseData();
    getDoctorwiseData();
  };
  const handleDoctorSelect = evt => {
    if (evt.target.name === "all") {
      setDoctorIds([]);
      return;
    }
    const doctorId = Number(evt.target.name);
    if (evt.target.checked) {
      setDoctorIds([...doctorIds, doctorId]);
    } else {
      const temp = [...doctorIds];
      temp.splice(temp.indexOf(doctorId), 1);
      // console.log(temp);
      setDoctorIds([...temp]);
    }
  };
  const handleViewTypeChange = evt => {
    const vt = evt.target.value;
    setViewType(vt);
    generateHourwiseRows(hourwiseData, vt);
  };
  useEffect(() => {
    axios
      .post(config.url.GET_DOCTOR)
      .then(res => {
        // console.log(res.data.body.data.rows);
        setDoctors(res.data.body.data.rows);
        generateMonthwiseColumns();
        generateDaywiseColumns();
        generateDoctorwiseColumns();
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getHourwiseData();
  }, [brand]);
  useEffect(() => {
    getMonthwiseData();
  }, [brand, fromMonth, interval]);
  useEffect(() => {
    getDaywiseData();
  }, [brand, daywiseFromDate, daywiseToDate, daywiseInterval]);
  useEffect(() => {
    getDoctorwiseData();
  }, [brand, doctorwiseDay]);
  const getDaywiseData = () => {
    setDaywiseRows([]);
    const reqBody = {
      doctorIds,
      brand,
      fromDate: daywiseFromDate,
      toDate: daywiseToDate
    };
    const url =
      daywiseInterval === "history"
        ? config.url.UTILIZATION_DAYWISE_HISTORY
        : config.url.UTILIZATION_DAYWISE_REALTIME;
    axios
      .post(url, reqBody)
      .then(res => {
        // console.log("DAY WISE", res);
        setDaywiseData(res.data.data);
        generateDaywiseRows(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getDoctorwiseData = () => {
    setDoctorwiseRows([]);
    // const doctorwiseDay='2021-03-03';
    // console.log(doctorwiseDay);
    const doctorwiseRequestData = {
      doctorIds,
      brand,
      appointmentDate: doctorwiseDay
    };
    axios
      .post(config.url.CAPACITY_MANAGEMENT_DOCTORWISE, doctorwiseRequestData)
      .then(res => {
        console.log("DOCTOR WISE RES", res.data.data);
        setDoctorwiseData(res.data.data);
        generateDoctorwiseRows(res.data.data);
      })
      .catch(err => {
        console.log("ERROR IN DOCTORWISE", err);
      });
  };
  const getMonthwiseData = () => {
    setMonthwiseRows([]);
    const reqBody = {
      doctorIds,
      brand,
      fromMonth
    };
    const url =
      interval === "history"
        ? config.url.UTILIZATION_MONTHWISE_HISTORY
        : config.url.UTILIZATION_MONTHWISE_REALTIME;
    axios
      .post(url, reqBody)
      .then(res => {
        console.log("MONTH WISE", res);
        setMonthwiseData(res.data.data);
        generateMonthwiseRows(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getHourwiseData = () => {
    setHourwiseRows([]);
    setShowHourwiseLoader(true);
    const reqBody = {
      doctorIds,
      brand
    };
    axios
      .post(config.url.CAPACITY_MANAGEMENT_HOURWISE, reqBody)
      .then(res => {
        // console.log(res);
        setHourwiseData(res.data.data);
        generateHourwiseColumns(res.data.data);
        generateHourwiseRows(res.data.data, viewType);
        setShowHourwiseLoader(false);
      })
      .catch(err => {
        console.log(err);
        setShowHourwiseLoader(false);
      });
  };
  const generateHourwiseColumns = data => {
    const dateKeys = Object.keys(data);
    let cols = ["Slots", ...dateKeys].map(col => {
      return {
        title: col === "Slots" ? "Slots" : moment(col).format("D MMM"),
        field: col
      };
    });
    setHourwiseColumns(cols);
  };
  const generateHourwiseRows = (data, viewType) => {
    const dateKeys = Object.keys(data);
    const timeKeys = Object.keys(data[dateKeys[0]]);
    let rows = [];
    const totalOfRows = {
      Slots: "Total"
    };
    timeKeys.forEach((timeKey, index) => {
      const [startTime, endTime] = timeKey.split("-");
      const row = {
        Slots:
          moment("1900-01-01 " + startTime).format("h A") +
          " - " +
          moment("1900-01-01 " + endTime).format("h A")
      };
      dateKeys.forEach((dateKey, index) => {
        const rowValue =
          viewType === "all"
            ? `${data[dateKey][timeKey].appointments} | ${data[dateKey][timeKey].capacity} | ${data[dateKey][timeKey].utilization}%`
            : data[dateKey][timeKey][viewType];
        row[dateKey] = rowValue + (viewType === "utilization" ? "%" : "");
        if (!totalOfRows[dateKey])
          totalOfRows[dateKey] = { appointments: 0, capacity: 0 };
        totalOfRows[dateKey].appointments +=
          data[dateKey][timeKey].appointments;
        totalOfRows[dateKey].capacity += data[dateKey][timeKey].capacity;
      });
      rows.push(row);
    });
    for (let dateKey in totalOfRows) {
      if (dateKey === "Slots") continue;
      totalOfRows[dateKey].utilization =
        totalOfRows[dateKey].capacity > 0
          ? (
              (totalOfRows[dateKey].appointments /
                totalOfRows[dateKey].capacity) *
              100
            ).toFixed(2)
          : 0;
      const rowValue =
        viewType === "all"
          ? `${totalOfRows[dateKey].appointments} | ${totalOfRows[dateKey].capacity} | ${totalOfRows[dateKey].utilization}%`
          : totalOfRows[dateKey][viewType];
      totalOfRows[dateKey] = rowValue + (viewType === "utilization" ? "%" : "");
    }
    rows.push(totalOfRows);
    // console.log("ROWS", rows);
    setHourwiseRows(rows);
  };
  const generateMonthwiseColumns = () => {
    let cols = ["Month", "Appointments", "Capacity", "Utilization"].map(col => {
      return {
        title: col,
        field: col
      };
    });
    setMonthwiseColumns(cols);
  };
  const generateMonthwiseRows = data => {
    const rows = [];
    for (let i = moment(fromMonth), j = 1; j <= 6; ++j) {
      const monthKey = i.format("MMM YYYY");
      if (data[monthKey]) {
        const row = {};
        row["Month"] = monthKey;
        row["Appointments"] = data[monthKey].appointments;
        row["Capacity"] = data[monthKey].capacity;
        row["Utilization"] = data[monthKey].utilization;
        rows.push(row);
      }
      i =
        interval === "history"
          ? moment(i).subtract(1, "month")
          : moment(i).add(1, "month");
    }
    setMonthwiseRows(rows);
  };
  const generateDaywiseColumns = data => {
    let cols = ["Day", "Appointments", "Capacity", "Utilization"].map(col => {
      return {
        title: col,
        field: col
      };
    });
    setDaywiseColumns(cols);
  };
  const generateDaywiseRows = data => {
    const rows = [];
    const dateLimit =
      moment(daywiseToDate, "YYYY-MM-DD").diff(
        moment(daywiseFromDate, "YYYY-MM-DD"),
        "d"
      ) + 1;
    for (let i = moment(daywiseFromDate), j = 1; j <= dateLimit; ++j) {
      const dateKey = i.format("YYYY-MM-DD");
      if (data[dateKey]) {
        const row = {};
        row["Day"] = i.format("D MMM YY");
        row["Appointments"] = data[dateKey].appointments;
        row["Capacity"] = data[dateKey].capacity;
        row["Utilization"] = data[dateKey].utilization;
        rows.push(row);
      }
      i = moment(i).add(1, "d");
    }
    setDaywiseRows(rows);
  };
  const generateDoctorwiseColumns = () => {
    const columns = ["Doctor"];
    const startTime = "09:00:00";
    const endTime = "19:00:00";
    const timeLimit =
      moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"), "hours") *
        2 +
      1;
    const durationMins = 30;
    for (
      let i = moment(startTime, "HH:mm:ss"), j = 1;
      j <= timeLimit;
      ++j, i = moment(i).add(durationMins, "minutes")
    ) {
      const time = i.format("h:mm A");
      columns.push(time);
    }
    const cols = columns.map(col => {
      if (col === "Doctor") {
        return {
          title: col,
          field: col,
          cellStyle: {
            minWidth: 180,
            fontWeight: 500,
            backgroundColor: "rgb(240,240,240)"
          }
        };
      }
      return {
        title: col,
        field: col,
        cellStyle: rowData => {
          let backgroundColor = "initial";
          if (Number(rowData) > 3) backgroundColor = "rgba(200,0,0,0.6)";
          else if (Number(rowData) < 3) backgroundColor = "rgb(0,200,150)";
          return { backgroundColor, border: "1px solid rgb(240,240,240)" };
        }
      };
    });
    setDoctorwiseColumns(cols);
  };
  const generateDoctorwiseRows = data => {
    const cols = [];
    const startTime = "09:00:00";
    const endTime = "19:00:00";
    const timeLimit =
      moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"), "hours") *
        2 +
      1;
    const durationMins = 30;
    for (
      let i = moment(startTime, "HH:mm:ss"), j = 1;
      j <= timeLimit;
      ++j, i = moment(i).add(durationMins, "minutes")
    ) {
      const time = i.format("HH:mm:ss");
      cols.push(time);
    }
    const rows = [];
    for (const doctorId in data) {
      const doctorwiseData = data[doctorId];
      const row = {
        Doctor: doctorwiseData.doctorName
      };
      cols.forEach(col => {
        const key = moment(col, "HH:mm:ss").format("h:mm A");
        row[key] = doctorwiseData.appointments[col]
          ? doctorwiseData.appointments[col]
          : 0;
      });
      rows.push(row);
    }
    // console.log("DOCTOR WISE ROWS", rows);
    setDoctorwiseRows(rows);
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => setBrand("all")}
          className={
            brand === "all"
              ? `brand-filter-button brand-filter-all active`
              : `brand-filter-button brand-filter-all`
          }
        >
          ALL
        </div>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
        <select
          onChange={handleViewTypeChange}
          style={{ marginLeft: "10px", padding: "5px" }}
        >
          <option value="all">ALL</option>
          <option value="appointments">Appointments</option>
          <option value="capacity">Capacity</option>
          <option value="utilization">Utilization</option>
        </select>
      </div>

      <div style={{ display: "flex" }}>
        <Dropdown className="m-2">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">
            Select Doctors
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              maxHeight: "200px",
              overflow: "auto",
              width: "max-content"
            }}
          >
            <Dropdown.Item onClick={handleDoctorSelect} name="all">
              All
            </Dropdown.Item>
            {doctors.map((doctor, index) => {
              return (
                <>
                  <FormControlLabel
                    style={{ margin: "1px" }}
                    control={
                      <Checkbox
                        checked={doctorIds.includes(doctor.doctor_id)}
                        name={doctor.doctor_id}
                        onChange={handleDoctorSelect}
                      />
                    }
                    label={doctor.doctor_name}
                  />
                  <br />
                </>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <IconButton
          color="primary"
          aria-label="Go"
          onClick={arrowButtonClickHandler}
        >
          <ArrowForwardIcon />
        </IconButton>
      </div>
      {showHourwiseLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <>
          {hourwiseRows.length > 0 ? (
            <Table4
              cols={hourwiseColumns}
              rows={hourwiseRows}
              title="Capacity Management (Hour wise)"
              pageSize={11}
            />
          ) : null}
          <HeaderActionTable
            title="Month wise"
            cols={monthwiseColumns}
            rows={monthwiseRows}
            fromMonth={fromMonth}
            setFromMonth={setFromMonth}
            pageSize={6}
            interval={interval}
            setInterval={setInterval}
          />
          <HeaderActionTable2
            title="Day wise"
            cols={daywiseColumns}
            rows={daywiseRows}
            fromDate={daywiseFromDate}
            toDate={daywiseToDate}
            setFromDate={setDaywiseFromDate}
            setToDate={setDaywiseToDate}
            pageSize={10}
            interval={daywiseInterval}
            setInterval={setDaywiseInterval}
          />
          <input
            type="date"
            value={doctorwiseDay}
            className="form-control"
            onChange={evt => {
              setDoctorwiseDay(evt.target.value);
            }}
            style={{ width: "max-content", margin: "30px 0px -10px 10px" }}
          />
          <Table4
            cols={doctorwiseColumns}
            rows={doctorwiseRows}
            title="Doctorwise View"
            pageSize={20}
          />
        </>
      )}
    </>
  );
}

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./checkin.scss";
import Axios from "axios";
import { toast } from "react-toastify";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
function CheckinDoctor({ doctorId }) {
  const checkinDoctor = async () => {
    try {
      await Axios.post(CONFIG_CONSTANT.url.CHECK_DOCTOR_IN, { doctorId });
      toast.success("SUCCESS: Doctor checkedin");
    } catch (err) {
      console.log("ERROR in checkin doctor", err);
      toast.error("ERROR: Could not checkin doctor");
    }
  };
  return (
    <div className="checkin-toggle">
      <Button className="toggler-button" size="sm" onClick={checkinDoctor}>
        Checkin Doctor
      </Button>
    </div>
  );
}
export default CheckinDoctor;

const initialState = {
  rules: {}
};
const recommendationRulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RECOMMENDATION_RULES":
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.payload.brand]: action.payload.rules
        }
      };
    case "DELETE_RECOMMENDATION_RULE":
      const curRules = [];
      for (const rule of state.rules[action.payload.brand])
        if (rule.id !== action.payload.id) curRules.push(rule);
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.payload.brand]: curRules
        }
      };
    case "ADD_RECOMMENDATION_RULE":
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.payload.brand]: [
            action.payload.rule,
            ...state.rules[action.payload.brand]
          ]
        }
      };
    case "UPDATE_RECOMMENDATION_RULE":
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.payload.brand]: state.rules[action.payload.brand].map(
            rule => {
              if (rule.id === action.payload.rule.id)
                return { ...action.payload.rule };
              return rule;
            }
          )
        }
      };
    default:
      return state;
  }
};
export default recommendationRulesReducer;

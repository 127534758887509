import React from "react";
import { store } from "../../../../../reducers/Persist";
import { connect } from "react-redux";
import "./styles.scss";

class PastDoctorNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pastMedicalHistory: []
    };
    // child in pastMedicalHistory will look like this...
    // {
    //   doctorName: "",
    //   appointmentId: "",
    //   appointmentDate: "",
    //   appointmentTime: "",
    //   appointmentNotes: ""
    // }
  }

  componentDidMount() {
    let monthArr = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let tempArr = [];
    if (this.props.patient_medical_history)
      this.props.patient_medical_history.map((val, i) => {
        if (val.observations.length > 0) {
          tempArr.push(val);
        }
      });
    if (tempArr.length > 0) {
      let pastMedicalArr = [];
      tempArr.map(val => {
        val.observations.map(observation => {
          if (observation.observation_type === "doctor_notes") {
            let metadata = JSON.parse(observation.metadata);
            if (observation.observation_text.length > 0 && metadata) {
              //conversion to IST
              var currentTime = new Date(val.prescription_date);
              var currentOffset = currentTime.getTimezoneOffset();

              var ISTOffset = 330; // IST offset UTC +5:30
              let date = new Date(
                currentTime.getTime() + (ISTOffset + currentOffset) * 60000
              );

              let hr = Number(date.getHours()) + 5;
              let min = Number(date.getMinutes()) + 30;
              if (min > 59) {
                min = min - 60;
                hr++;
              }
              min = min < 10 ? "0" + min : min;
              let ampm = hr >= 12 ? "PM" : "AM";
              hr = hr % 12;
              hr = hr ? hr : "1";
              pastMedicalArr.push({
                doctorName: metadata?.doctorName,
                appointmentId: "NA",
                appointmentDate:
                  monthArr[new Date(val.prescription_date).getMonth()] +
                  " " +
                  date.getDate() +
                  ", " +
                  date.getFullYear(),
                appointmentTime: hr + ":" + min + " " + ampm,
                appointmentNotes: observation.observation_text
              });
            }
          }
        });
      });
      this.setState({
        pastMedicalHistory: pastMedicalArr
      });
    }
  }
  render() {
    return (
      <>
        {this.state.pastMedicalHistory.length > 0 ? (
          <div className="pastDoctorNotes">
            <h3>Past Doctor Notes</h3>
            {this.state.pastMedicalHistory.map((val, i) => (
              <div
                className="pastDoctorNotes__container"
                style={{ borderTop: `${i > 0 ? "1px dashed gray" : ""}` }}
                key={i}
              >
                <div className="pastDoctorNotes__container__top">
                  <p className="PDN__doctorName">{val.doctorName}</p>
                  {/* <p>
                    Appointment ID: <span>{val.appointmentId}</span>
                  </p> */}
                  <div className="PDN__timeDate">
                    <p>{val.appointmentTime}</p>
                    <p className="PDN__Date">{val.appointmentDate}</p>
                  </div>
                </div>
                <div className="pastDoctorNotes__container__bottom">
                  {val.appointmentNotes}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = () => {
  return {
    patient_medical_history:
      store.getState().rxWriterReducer.patient_medical_history
  };
};

export default connect(mapStateToProps)(PastDoctorNotes);

import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table6 from "../../common/Tables/Table6.js";
import { appointmentsTodaySummary, appointmentsTodayTable } from "./NewCols.js";
import Checkbox from "@material-ui/core/Checkbox";

export default function AppointmentsPending({
  reqBody,
  rowStyle,
  setCountPending
}) {
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState({
    ORDER: [],
    FORM: [],
    DA: [],
    MA: [],
    "FOLLOW UP": [],
    ALL: []
  });
  const [tab, setTab] = useState("ALL");
  const [attemptFilter, setAttemptFilter] = useState(false);
  useEffect(() => {
    axios
      .post(config.url.PENDING_APPOINTMENTS, reqBody)
      .then(res => {
        let { summary, results } = res.data;
        results = results.filter(row => !row.cancelled);
        setCountPending(results.length);
        setData({
          appointmentsPendingTable: results,
          pending_summary: [summary]
        });
        prepareFilteredData(results, tab);
        if (attemptFilter) applyAttemptFilter();
      })
      .catch(err => {
        console.log(err);
      });
  }, [reqBody?.from_date, reqBody?.to_date]);
  const prepareFilteredData = data => {
    const tempFilteredData = {
      ORDER: [],
      FORM: [],
      DA: [],
      MA: [],
      "FOLLOW UP": [],
      ALL: [...data]
    };
    data.forEach(apt => {
      switch (apt.source) {
        case "O":
          tempFilteredData["ORDER"].push(apt);
          break;
        case "FU":
          tempFilteredData["FOLLOW UP"].push(apt);
          break;
        case "F":
          tempFilteredData["FORM"].push(apt);
          break;
        case "MA":
          tempFilteredData["MA"].push(apt);
          break;
        case "DA":
          tempFilteredData["DA"].push(apt);
          break;
        default:
          break;
      }
    });
    setFilteredData(tempFilteredData);
  };
  const onTabChange = newTab => {
    if (tab === newTab) return;
    setTab(newTab);
  };
  const checkboxChangeHandler = evt => {
    if (evt.target.checked) {
      applyAttemptFilter();
      setAttemptFilter(true);
    } else {
      prepareFilteredData(data.appointmentsPendingTable);
      setAttemptFilter(false);
    }
  };
  const applyAttemptFilter = () => {
    const tempFilteredData = { ...filteredData };
    for (const tab in tempFilteredData) {
      tempFilteredData[tab] = tempFilteredData[tab].filter(
        apt => apt.no_of_attempts < 6
      );
    }
    setFilteredData(tempFilteredData);
  };
  return data ? (
    <>
      <div>
        <Checkbox
          checked={attemptFilter}
          onChange={checkboxChangeHandler}
          color="primary"
        />
        <span style={{ fontWeight: "500" }}>Only Less than 6 Attempts</span>
      </div>
      <div className="nav-tab">
        <div
          className={`tab ${tab === "ALL" ? "active" : ""}`}
          onClick={evt => onTabChange("ALL")}
        >
          ALL {`(${filteredData["ALL"].length})`}
        </div>
        <div
          className={`tab ${tab === "ORDER" ? "active" : ""}`}
          onClick={evt => onTabChange("ORDER")}
        >
          ORDER {`(${filteredData["ORDER"].length})`}
        </div>
        <div
          className={`tab ${tab === "FORM" ? "active" : ""}`}
          onClick={evt => onTabChange("FORM")}
        >
          FORM {`(${filteredData["FORM"].length})`}
        </div>
        <div
          className={`tab ${tab === "DA" ? "active" : ""}`}
          onClick={evt => onTabChange("DA")}
        >
          DA {`(${filteredData["DA"].length})`}
        </div>
        <div
          className={`tab ${tab === "MA" ? "active" : ""}`}
          onClick={evt => onTabChange("MA")}
        >
          MA {`(${filteredData["MA"].length})`}
        </div>

        <div
          className={`tab ${tab === "FOLLOW UP" ? "active" : ""}`}
          onClick={evt => onTabChange("FOLLOW UP")}
        >
          FOLLOW UP {`(${filteredData["FOLLOW UP"].length})`}
        </div>
      </div>
      <Table6
        title="ALL"
        pageSize={100}
        cols={appointmentsTodayTable}
        rows={filteredData[tab] || []}
        rowStyle={rowStyle}
        search={true}
      />
    </>
  ) : null;
}

import React, { Component } from "react";
import "./PreviewPrescription.scss";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import { toast } from "react-toastify";
class PreviewPrescription extends Component {
  handleClick = () => {
    let tempVal = this.props.observations[this.props.id];
    store.dispatch({
      type: "EDIT_IS_FIELD_RECOMMENDED",
      payload: {
        key: "isSpecialistConsultRecommended",
        value:
          tempVal.specialConsult.pretext.length > 0 ||
          tempVal.specialConsult.text.length > 0
      }
    });
    store.dispatch({
      type: "EDIT_IS_FIELD_RECOMMENDED",
      payload: {
        key: "isSuggestProcedureRecommended",
        value:
          tempVal.suggestProcedure.text.length > 0 ||
          tempVal.suggestProcedure.pretext.length > 0
      }
    });
    store.dispatch({
      type: "EDIT_IS_FIELD_RECOMMENDED",
      payload: {
        key: "isSuggestDiagnosticsRecommended",
        value:
          tempVal.suggestDiagnostics.text.length > 0 ||
          tempVal.suggestDiagnostics.pretext.length > 0
      }
    });
    if (
      this.props.otherProducts.length === 0 &&
      this.props.orderedProducts.top.length === 0 &&
      this.props.orderedProducts.bottom.length === 0 &&
      this.props.recommendedProducts.length === 0
    ) {
      if (
        tempVal.suggestProcedure.text.length > 0 ||
        tempVal.suggestProcedure.pretext.length > 0 ||
        tempVal.suggestDiagnostics.text.length > 0 ||
        tempVal.suggestDiagnostics.pretext.length > 0 ||
        tempVal.specialConsult.text.length > 0 ||
        tempVal.specialConsult.pretext.length > 0 ||
        tempVal.lifestyle.text.length > 0 ||
        tempVal.lifestyle.pretext.length > 0
      ) {
        store.dispatch({
          type: "SET_DUMMY_PRODUCT",
          payload: true
        });
        store.dispatch({ type: "CHANGE_TAB", data: 3 });
        return;
      } else toast.error("Add at least one product or recommendation");
    } else {
      if (
        !this.props.followup.followupDurationValue ||
        !this.props.followup.followupDurationUnit
      ) {
        toast.error("Please enter the follow up duration");
      } else {
        store.dispatch({
          type: "SET_DUMMY_PRODUCT",
          payload: false
        });
        store.dispatch({ type: "CHANGE_TAB", data: 3 });
      }
    }
  };
  render() {
    return (
      <button
        className="preview-prescription-button"
        type="submit"
        onClick={() => this.handleClick()}
      >
        Preview Prescription
      </button>
    );
  }
}
const mapStateToProps = () => {
  const id = store.getState().rxWriterReducer.id;
  return {
    followup: store.getState().rxWriterReducer.followup,
    otherProducts: store.getState().observationReducer[id].otherProducts,
    orderedProducts: store.getState().orderedProducts,
    recommendedProducts: store.getState().recommendedProducts,
    observations: store.getState().observationReducer,
    id: store.getState().rxWriterReducer.id
  };
};
export default connect(mapStateToProps)(PreviewPrescription);

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../DashboardPage/DashboardPage.scss";
import { Refresh, Logout, DateGroup, DateGroup2 } from "./AdminNav";
import DataTable2 from "../../common/Tables/DataTable2";
import DataTable3 from "../../common/Tables/DataTable3";
import config from "../../../constants/apiList";
import { Link, useLocation } from "react-router-dom";
import "./AdminPage.scss";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import moment from "moment";
import RecommendationEngine from "../RecommendationEngine";
import axios from "axios";
import DoctorAi from "../DoctorAi/DoctorAi";
import {
  hoursCols,
  hoursCols2,
  scheduleCols,
  formDataCols,
  summaryCols,
  consultationCols,
  dailyAppCols,
  dailyFFCols,
  weeklyCols,
  weeklyFFCols,
  pendingTodayCols,
  pendingCumulativeCols,
  codAndPrepaidCols,
  codVsPrepaidCols,
  doctorTableCols,
  doctorCols,
  breakHoursCols,
  reviewsCols,
  newDoctorHours,
  doctorBrandColumns,
  doctorLanguageColumns,
  doctorCategoryColumns
} from "./Columns";
import DataTable1 from "../../common/Tables/DataTable1";
import { BsSearch } from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditableDataTable2 from "./EditableDataTable2";
import EditableDataTable3 from "./EditableDataTable3";
import CustomDateTable from "./CustomDateTable";
import { FormDataSearchTable } from "../CustomerServicePage/CustomerServicePage";
import EditableDataTable from "./EditableDataTable";
import EditableDataTable4 from "./EditableDataTable4";
import ActiveStatus from "./ActiveStatus";
import { useAsync } from "../../../hooks";
import { store } from "../../../reducers/Persist";
import PCI from "./PCI";
import FullSlots from "./FullSlots";
import ProductsView from "./ProductsView";
import CapacityManagement from "./CapacityManagement";
import DoctorPerformanceView from "../DoctorPerformanceView/DoctorPerformanceView";
import ShortCalls from "../ShortCalls/ShortCalls";
import SourceView from "../SourceView/SourceView";
import AppointmentTrail from "../AppointmentTrail";
import SystemCheck from "../SystemCheck/SystemCheck";
import RxTracker from "../RxTracker/RxTracker";
import RescheduleAppointment from "../RescheduleAppointment/RescheduleAppointment";
import StatusUpdater from "../StatusUpdater/statusUpdater";
import Reviews from "./Reviews";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import NewAdminDashboardTab from "./NewAdminDashboardTab";
import PendingOrdersView from "../PendingOrdersView/PendingOrdersView";
import DownloadImages from "../DownloadImages/DownloadImages";
import DownloadVCRecording from "../DownloadVCRecording/DownloadVCRecording";
import VideocallTiming from "../VideocallTiming/VideocallTiming";
import DAExport from "../DAExport";
import BulkOOCTable from "../BulkOOC/BulkOOCTable";
import AppointmentCounts from "../AppointmentCounts/AppointmentCounts";
import AuditTrail from "../AuditTrail";
import DoctorStatusView from "../DoctorStatusView";
import AdminOverview from "../AdminOverview";
import AppointmentAudioLogs from "../AppointmentAudioLogs";
import DoctorStatusLogs from "../DoctorStatusLogs";
import EditableDataTable5 from "./EditableDataTable5";
import SpoofEmailId from "../SpoofEmailId";
import DochubUserLogs from "../DochubUserLogs";
import { getRxWriterLink } from "../../../helpers/rx-writer.helpers";
import DoctorView from "../DoctorView";

const appointmentAction = [
  {
    icon: "launch",
    tooltip: "Open Prescription",
    onClick: (event, rowData) => {
      let param = rowData.brand + "-" + rowData.source + "-" + rowData.id;
      const oldCategories = ["SH", "HC", "OF"];
      if (oldCategories.includes(rowData.source)) {
        param = rowData.source_id;
      }
      window.open(getRxWriterLink(param), "_blank");
    }
  },
  {
    icon: "update",
    tooltip: "Reschedule Appointment",
    onClick: (event, rowData) => {
      const appointmentId = `${rowData.brand}-${rowData.source}-${rowData.id}`;
      window.open(
        `${CONFIG_CONSTANTS.url.UI_DR_DASHBOARD_URL}admin/rescheduleAppointment/${appointmentId}`,
        "_blank"
      );
    }
  }
];

const doctorScheduleActions = [
  {
    icon: "launch",
    tooltip: "View appointment",
    onClick: (event, rowData) => {
      let param = rowData.brand + "-" + rowData.source + "-" + rowData.id;
      const oldCategories = ["SH", "HC", "OF"];
      if (oldCategories.includes(rowData.source)) {
        param = rowData.source_id;
      }
      window.open(getRxWriterLink(param), "_blank");
    }
  },
  {
    icon: "update",
    tooltip: "Reschedule Appointment",
    onClick: (event, rowData) => {
      const appointmentId = `${rowData.brand}-${rowData.source}-${rowData.id}`;
      window.open(
        `${CONFIG_CONSTANTS.url.UI_DR_DASHBOARD_URL}admin/rescheduleAppointment/${appointmentId}`,
        "_blank"
      );
    }
  }
];

const colMapper = col => {
  return {
    title: col,
    field: col
  };
};

const getColor = (status, row) => {
  let { appointment_date, from_time, to_time, confirmed, cancelled } = row;
  if (cancelled === 1) return { backgroundColor: "#D3D3D3" };
  let st = status.toUpperCase();
  let endStates = ["T", "C", "NI", "NS", "OP", "NA", "CN", "OTC", "RF"];
  let currentTimeStamp = ~~(+new Date() / 1000);
  let fromTimeStamp = moment(appointment_date + " " + from_time).unix();
  let toTimeStamp = moment(appointment_date + " " + to_time).unix();
  if (endStates.includes(st)) {
    return { backgroundColor: "#33FF4F" };
  }
  if (
    currentTimeStamp >= fromTimeStamp &&
    currentTimeStamp <= toTimeStamp &&
    !endStates.includes(st)
  ) {
    return { backgroundColor: "#FFA833" };
  }
  if (currentTimeStamp > toTimeStamp && !endStates.includes(st)) {
    return { backgroundColor: "#FF3333" };
  }
  if (confirmed === 1) return { backgroundColor: "#ffd399" };
};

const DateGroup3 = () => {
  const { date, setDate } = useContext(dateContext);

  const [dates, setDates] = useState({
    start: date.startDate,
    end: date.endDate
  });

  const handleChange = (e, type) => {
    if (type === "start") {
      setDates({ ...dates, start: e.target.value });
    }
    if (type === "end") {
      setDates({ ...dates, end: e.target.value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDate({ startDate: dates.start, endDate: dates.end });
  };

  return (
    <div className="dategroup">
      <form className="form">
        <input
          type="date"
          className="input"
          value={dates.start}
          onChange={e => handleChange(e, "start")}
        />
        <input
          type="date"
          className="input"
          value={dates.end}
          onChange={e => handleChange(e, "end")}
        />
        <IconButton color="primary" aria-label="Go" onClick={handleSubmit}>
          <ArrowForwardIcon />
        </IconButton>
      </form>
    </div>
  );
};

const NewAdminDashboardView = () => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  const [date, setDate] = useState({
    startDate: today,
    endDate: today
  });
  const [idArr, setIdArr] = useState([]);
  useEffect(() => {
    axios.post(CONFIG_CONSTANTS.url.DOCTOR_LIST_URL, {}).then(response => {
      let arr = [];
      response.data.results.map((item, index) => {
        arr.push([item["doctor_id"], item["doctor_name"]]);
      });
      setIdArr([...arr]);
    });
  }, []);

  const [arr, setArr] = useState([]);
  useEffect(() => {
    setArr([...idArr.map(a => a[0])]);
  }, [idArr]);
  const [val, setVal] = useState(-1);

  return arr.length ? (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup3 />
        <select
          style={{ marginLeft: "10px" }}
          onChange={e => {
            if (Number(e.target.value) === -1)
              setArr([...idArr.map((a, index) => a[0])]);
            else setArr([+e.target.value]);
            setVal(+e.target.value);
          }}
        >
          <option value={-1} selected={val === -1}>
            ALL
          </option>
          {idArr.map((arr, index) => {
            return (
              <option
                key={"" + index}
                value={+arr[0]}
                selected={val === +arr[0]}
              >
                {arr[1]}
              </option>
            );
          })}
        </select>
        <NewAdminDashboardTab
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          arr={arr}
        />
      </div>
    </dateContext.Provider>
  ) : null;
};

export default function AdminPage2() {
  const startDate = useSelector(state => state.appointmentReducer.start);
  const endDate = useSelector(state => state.appointmentReducer.end);
  const [page, setPage] = useState(null);
  const [idArr, setIdArr] = useState([]);
  // const history = useHistory();
  const location = useLocation();
  const reqBody1 = {
    admin_key: localStorage.getItem("adminKey"),
    from_date: startDate,
    to_date: endDate
  };
  const reqBody2 = { admin_key: localStorage.getItem("adminKey") };
  useEffect(() => {
    setPage(location.pathname.split("/admin/")[1]);
  }, [location]);

  useEffect(() => {
    axios
      .post(CONFIG_CONSTANTS.url.DOCTOR_LIST_URL, {
        admin_key: localStorage.getItem("adminKey")
      })
      .then(
        response => {
          let res = {};
          response.data.results &&
            response.data.results.map((item, index) => {
              res = { ...res, [item["doctor_id"]]: item["doctor_name"] };
            });
          hoursCols[0]["lookup"] = res;
          hoursCols2[1]["lookup"] = res;
          scheduleCols[0]["lookup"] = res;
          scheduleCols[scheduleCols.length - 3]["lookup"] = res;
          reviewsCols[0]["lookup"] = res;
        },
        err => {
          console.log(err);
        }
      );
  }, []);

  const renderSwitch = () => {
    let appointmentId = false;
    if (/^(rescheduleAppointment).*/.test(page)) {
      const matches = page.match(/(?<=rescheduleAppointment\/).*/);
      if (matches && matches.length) {
        appointmentId = matches[0];
        // we are not using setPage as it rerenders the component and results in infinite loop
        return (
          <RescheduleAppointment appointmentId={appointmentId} isAdmin={true} />
        );
      }
    }
    switch (page) {
      // case "doctor":
      //   return <DoctorTable />;
      case "doctors":
        return <DoctorView />;
      case "doctorSchedule":
        return (
          <>
            <DoctorScheduleTable />
          </>
        );
      case "recommendationEngine":
        return <RecommendationEngine />;
      case "doctorHours":
        return (
          <>
            <EditableDataTable5
              title="Doctor Hours"
              cols={newDoctorHours}
              url={config.url.NEW_DOCTOR_HOURS}
              reqBody={reqBody2}
              urlPost={config.url.UPDATE_DOCTOR_HOURS}
            />
          </>
        );
      case "doctorBrandDetails":
        return (
          <>
            <EditableDataTable5
              title="Doctor Brand"
              cols={doctorBrandColumns}
              url={config.url.DOCTOR_BRAND_MAPPING}
              reqBody={reqBody2}
              urlPost={config.url.UPDATE_DOCTOR_BRAND}
            />
          </>
        );
      case "doctorLanguageDetails":
        return (
          <>
            <EditableDataTable5
              title="Doctor Language"
              cols={doctorLanguageColumns}
              url={config.url.DOCTOR_LANGUAGE_MAPPING}
              reqBody={reqBody2}
              urlPost={config.url.UPDATE_DOCTOR_LANGUAGE}
            />
          </>
        );
      case "doctorCategoryDetails":
        return (
          <>
            <EditableDataTable5
              title="Doctor Category"
              cols={doctorCategoryColumns}
              url={config.url.DOCTOR_CATEGORY_MAPPING}
              reqBody={reqBody2}
              urlPost={config.url.UPDATE_DOCTOR_CATEGORY}
            />
          </>
        );
      case "rescheduleAppointment":
        return <RescheduleAppointment isAdmin={true} />;
      case "statusUpdater":
        return <StatusUpdater />;
      case "productsView":
        return <ProductsView />;
      case "outOfClinic":
        return <DoctorOOCTable />;
      case "bulkOutOfClinic":
        return <BulkOOCTable />;
      case "pciAdminDashboard":
        return <PCITable brand="both" />;
      case "pciAdminDashboard/MM":
        return <PCITable brand="MM" />;
      case "pciAdminDashboard/BW":
        return <PCITable brand="BW" />;
      case "fullSlots":
        return <FullSlots />;
      case "capacityManagement":
        return <CapacityManagement />;
      case "doctorPerformanceView":
        return <DoctorPerformanceView />;
      case "reviews":
        return <ReviewsTable />;
      case "DownloadImages":
        return <DownloadImages />;
      case "downloadVCRecording":
        return <DownloadVCRecording />;
      case "videocallTiming":
        return <VideocallTiming />;
      case "pendingOrdersView":
        return <PendingOrdersView />;
      case "shortCalls":
        return <ShortCalls />;
      case "sourceView":
        return <SourceView />;
      case "systemCheck":
        return <SystemCheck />;
      case "rxTracker":
        return <RxTracker />;
      case "daExport":
        return <DAExport />;
      case "doctorAI":
        return <DoctorAi />;
      case "appointmentTrail":
        return <AppointmentTrail />;
      case "dochubUserLogs":
        return <DochubUserLogs />;
      case "codAndPrepaid":
        return (
          <>
            <DataTable3
              title="COD & Prepaid"
              cols={codAndPrepaidCols}
              url={config.url.AD_COD_PREPAID}
              reqBody={reqBody2}
            />
            <DataTable3
              title="COD vs Prepaid (Appointment Date)"
              cols={codVsPrepaidCols}
              url={config.url.AD_COD_VS_PREPAID}
              reqBody={reqBody2}
            />
          </>
        );
      case "summary":
        return (
          <>
            <DateGroup />
            <DataTable3
              title="Forms Summary"
              cols={["day", "DIRECT", "HC", "SH"]
                .map(el => colMapper(el))
                .concat([
                  {
                    title: "Total",
                    field: "Total",
                    render: row => Number(row.HC) + Number(row.SH)
                  }
                ])}
              url={config.url.AD_FORM_SUMMARY}
              reqBody={reqBody2}
            />
            <DataTable3
              title="Weekly Summary"
              cols={["weekNo", "HC", "SH"]
                .map(el => colMapper(el))
                .concat([
                  {
                    title: "Total",
                    field: "Total",
                    render: row => Number(row.HC) + Number(row.SH)
                  }
                ])}
              url={config.url.AD_WEEKLY_SUMMARY}
              reqBody={reqBody2}
            />
            <DataTable2
              title="Doctor Summary"
              cols={summaryCols}
              url={config.url.DOCTOR_SUMMARY}
              reqBody={reqBody1}
            />
          </>
        );
      case "consultations":
        return (
          <>
            <DateGroup />
            <DataTable3
              title="Doctor Consultations"
              cols={consultationCols}
              url={config.url.DOCTOR_CONSULTATIONS}
              reqBody={reqBody1}
            />
          </>
        );
      case "pending":
        return (
          <>
            <TableRowBox>
              <DataTable2
                title="Pending Completed Today"
                cols={pendingTodayCols}
                url={config.url.AD_PENDING_COMPLETED_TODAY}
                reqBody={reqBody2}
              />
              <DataTable3
                title="Pending Cumulative"
                cols={pendingCumulativeCols}
                url={config.url.AD_PENDING_CUMULATIVE}
                reqBody={reqBody2}
              />
            </TableRowBox>
          </>
        );
      case "formData":
        return (
          <>
            <CustomDateTable
              title="Form Data"
              cols={formDataCols}
              url={config.url.AD_FORM_DATA2}
              reqBody={reqBody2}
            />
            <FormDataSearchTable />
          </>
        );
      case "dashboard":
        return <NewAdminDashboardView idArr={idArr} />;
      case "overview":
        return <AdminOverview />;
      case "weeklyView":
        return <WeeklyView />;
      case "dailyView":
        return (
          <>
            <DateGroup />
            <DailyView reqBody1={reqBody1} reqBody2={reqBody2} />
          </>
        );
      case "doctorOnboardView":
        return <DoctorOnboardView />;
      case "activeStatus":
        return <ActiveStatus />;
      case "doctorStatusView":
        return <DoctorStatusView />;
      case "appointmentCounts":
        return <AppointmentCounts />;
      case "auditTrail":
        return <AuditTrail />;
      case "appointmentAudioLogs":
        return <AppointmentAudioLogs />;
      case "doctorStatusLogs":
        return <DoctorStatusLogs />;
      case "spoofEmailId":
        return <SpoofEmailId />;
      default: {
        return <SearchTable />;
      }
    }
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
      />
      <div className="dashboard-page-container">
        <div>
          <ProSidebar>
            <Menu
              iconShape="square"
              style={{
                height: "75vh",
                overflow: "auto",
                paddingBottom: "20px"
              }}
            >
              {/* <MenuItem>
                <Link className={!page ? "active" : ""} to="/admin">
                  Search
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "doctor" ? "active" : ""}
                  to="/admin/doctor"
                >
                  Doctor
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "doctors" ? "active" : ""}
                  to="/admin/doctors"
                >
                  Doctor
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "dashboard" ? "active" : ""}
                  to="/admin/dashboard"
                >
                  Dashboard
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "overview" ? "active" : ""}
                  to="/admin/overview"
                >
                  Overview
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "systemCheck" ? "active" : ""}
                  to="/admin/systemCheck"
                >
                  System Health
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "recommendationEngine" ? "active" : ""}
                  to="/admin/recommendationEngine"
                >
                  Recommendation Engine
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "doctorSchedule" ? "active" : ""}
                  to="/admin/doctorSchedule"
                >
                  Doctor Schedule
                </Link>
              </MenuItem> */}
              <MenuItem>
                <Link
                  className={page === "doctorAI" ? "active" : ""}
                  to="/admin/doctorAI"
                >
                  Doctor AI
                </Link>
              </MenuItem>
              {/* <MenuItem>
                <Link
                  className={page === "doctorHours" ? "active" : ""}
                  to="/admin/doctorHours"
                >
                  Doctor Hours
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "doctorBrandDetails" ? "active" : ""}
                  to="/admin/doctorBrandDetails"
                >
                  Brand Mapping
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "doctorLanguageDetails" ? "active" : ""}
                  to="/admin/doctorLanguageDetails"
                >
                  Language Mapping
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "doctorCategoryDetails" ? "active" : ""}
                  to="/admin/doctorCategoryDetails"
                >
                  Category Mapping
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "rescheduleAppointment" ? "active" : ""}
                  to="/admin/rescheduleAppointment"
                >
                  Reschedule Appointment
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "statusUpdater" ? "active" : ""}
                  to="/admin/statusUpdater"
                >
                  Status Updater
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "productsView" ? "active" : ""}
                  to="/admin/productsView"
                >
                  Products
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "outOfClinic" ? "active" : ""}
                  to="/admin/outOfClinic"
                >
                  Out Of Clinic
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "bulkOutOfClinic" ? "active" : ""}
                  to="/admin/bulkOutOfClinic"
                >
                  Bulk Out Of Clinic
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "pciAdminDashboard" ? "active" : ""}
                  to="/admin/pciAdminDashboard"
                >
                  PCI - Overall
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "pciAdminDashboard/MM" ? "active" : ""}
                  to="/admin/pciAdminDashboard/MM"
                >
                  PCI - MM
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "pciAdminDashboard/BW" ? "active" : ""}
                  to="/admin/pciAdminDashboard/BW"
                >
                  PCI - BW
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "reviews" ? "active" : ""}
                  to="/admin/reviews"
                >
                  Reviews
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "codAndPrepaid" ? "active" : ""}
                  to="/admin/codAndPrepaid"
                >
                  COD and Prepaid
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "summary" ? "active" : ""}
                  to="/admin/summary"
                >
                  Summary
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "consultations" ? "active" : ""}
                  to="/admin/consultations"
                >
                  Consultations
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "pending" ? "active" : ""}
                  to="/admin/pending"
                >
                  Pending
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "formData" ? "active" : ""}
                  to="/admin/formData"
                >
                  Form Data
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "customerServiceBooking" ? "active" : ""}
                  to="/csbooking"
                >
                  CS Book Appointment
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "doctorStatusView" ? "active" : ""}
                  to="/admin/doctorStatusView"
                >
                  Doctor status view
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "shortCalls" ? "active" : ""}
                  to="/admin/shortCalls"
                >
                  Short Calls
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "doctorPerformanceView" ? "active" : ""}
                  to="/admin/doctorPerformanceView"
                >
                  Doctors Performance
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "rxTracker" ? "active" : ""}
                  to="/admin/rxTracker"
                >
                  RX Tracker
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "daExport" ? "active" : ""}
                  to="/admin/daExport"
                >
                  DA Export
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "weeklyView" ? "active" : ""}
                  to="/admin/weeklyView"
                >
                  Weekly View
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "dailyView" ? "active" : ""}
                  to="/admin/dailyView"
                >
                  Daily View
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "doctorOnboardView" ? "active" : ""}
                  to="/admin/doctorOnboardView"
                >
                  Doctor Onboard View
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "pendingOrdersView" ? "active" : ""}
                  to="/admin/pendingOrdersView"
                >
                  Pending Orders View
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "DownloadImages" ? "active" : ""}
                  to="/admin/DownloadImages"
                >
                  Download Image
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "appointmentCounts" ? "active" : ""}
                  to="/admin/appointmentCounts"
                >
                  Appointment Counts
                </Link>
              </MenuItem> */}
              {/* <MenuItem>
                <Link
                  className={page === "auditTrail" ? "active" : ""}
                  to="/admin/auditTrail"
                >
                  Audit Trail
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "appointmentAudioLogs" ? "active" : ""}
                  to="/admin/appointmentAudioLogs"
                >
                  Appointment Audio Logs
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "doctorStatusLogs" ? "active" : ""}
                  to="/admin/doctorStatusLogs"
                >
                  Doctor Status Logs
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={page === "spoofEmailId" ? "active" : ""}
                  to="/admin/spoofEmailId"
                >
                  Spoof Email Id
                </Link>
              </MenuItem> */}
            </Menu>
            <div className="bottom-button-container">
              <Menu>
                <MenuItem>
                  <Refresh />
                </MenuItem>
                <MenuItem>
                  <Logout />
                </MenuItem>
              </Menu>
            </div>
          </ProSidebar>
        </div>
        <div>{renderSwitch()}</div>
      </div>
    </>
  );
}

const SearchTable = () => {
  const [query, setQuery] = React.useState("");
  const [reqBody, setReqBody] = React.useState({
    admin_key: localStorage.getItem("adminKey"),
    search_item: ""
  });
  const handleSubmit = e => {
    e.preventDefault();
    setReqBody({
      ...reqBody,
      search_item: query
    });
  };
  // console.log(scheduleCols);
  let newCols = [];
  scheduleCols.map(item => {
    let obj = {
      field: item.field.field,
      title: item.field.title
    };
    newCols.push(obj);
  });
  return (
    <>
      <form id="searchform" onSubmit={handleSubmit}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            className="searchBoxInput"
            type="text"
            onChange={e => setQuery(e.target.value)}
            value={query}
            placeholder="Search here..."
          />
          <button className="button" type="submit">
            <BsSearch size="1.5em" />
          </button>
        </div>
      </form>
      {query && (
        <DataTable1
          title="Search Results"
          cols={scheduleCols}
          url={config.url.AD_SEARCH}
          reqBody={reqBody}
          actions={appointmentAction}
        />
      )}
    </>
  );
};

const DoctorTable = () => {
  const reqBody = {
    admin_key: localStorage.getItem("adminKey")
  };

  return (
    <div className="doctorTable">
      <EditableDataTable4
        title="Doctor"
        cols={doctorCols}
        url={config.url.GET_DOCTOR}
        reqBody={reqBody}
        urlPost={config.url.ADD_DOCTOR}
        urlUpdate={config.url.UPDATE_DOCTOR}
        search={true}
        pageSize={15}
      />
    </div>
  );
};
export const dateContext = React.createContext();
const DoctorScheduleTable = () => {
  const [flag, setFlag] = useState(true);
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  const [date, setDate] = useState({ startDate: today, endDate: today });

  const reqBody = {
    admin_key: localStorage.getItem("adminKey"),
    from_date: date.startDate,
    to_date: date.endDate,
    created_date: flag ? 0 : 1
  };
  const handleChange = e => {
    e.target.value === "0" ? setFlag(true) : setFlag(false);
  };
  const setDateWrapper = newDateRange => {
    const MAX_LIMIT = 15;
    const { startDate, endDate } = newDateRange;
    const diff = moment(endDate, "YYYY-MM-DD").diff(
      moment(startDate, "YYYY-MM-DD"),
      "days"
    );
    if (diff >= MAX_LIMIT) {
      return toast.warn(`Date range can not be larger than ${MAX_LIMIT}`);
    }
    setDate(newDateRange);
  };

  return (
    <dateContext.Provider value={{ date, setDate: setDateWrapper }}>
      <div className="docSchedule">
        <DateGroup2 />
        <form onChange={handleChange} style={{ marginBottom: "1rem" }}>
          <label htmlFor="appointment_date">Appointment Date</label>
          <input
            type="radio"
            id="appointment_date"
            name="date"
            value="0"
            defaultChecked
          />
          <label htmlFor="created_date">Created Date</label>
          <input type="radio" id="created_date" name="date" value="1" />
        </form>

        <EditableDataTable2
          title="Doctor Schedule"
          cols={scheduleCols}
          url={config.url.DOCTOR_SCHEDULE}
          reqBody={{
            ...reqBody,
            from_date: date.startDate,
            to_date: date.endDate
          }}
          actions={doctorScheduleActions}
          rowStyle={r => getColor(r.status, r)}
          urlPost={config.url.REASSIGN_DOCTOR}
          search={true}
          pageSize={15}
          fromS3={true}
        />
      </div>
    </dateContext.Provider>
  );
};

const PCITable = ({ brand }) => {
  const [filterBySubmit, setFilterBySubmit] = useState(false);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   let today = new Date();
  //   today =
  //     today.getFullYear() +
  //     "-" +
  //     (today.getMonth() + 1 < 10
  //       ? "0" + (today.getMonth() + 1)
  //       : today.getMonth() + 1) +
  //     "-" +
  //     (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  //   let firstDayOfMonth = today.slice(0, -2) + "01";
  //   dispatch({
  //     type: "DATES",
  //     data: {
  //       start: firstDayOfMonth,
  //       end: today
  //     }
  //   });
  //   setTimeout(() => {
  //     dispatch({
  //       type: "REFRESH"
  //     })
  //   }, 300);
  // }, [dispatch]);
  // const startDate = useSelector(state => state.appointmentReducer.start);
  // const endDate = useSelector(state => state.appointmentReducer.end);

  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  let firstDayOfMonth = today.slice(0, -2) + "01";
  const [date, setDate] = useState({
    startDate: firstDayOfMonth,
    endDate: today
  });
  const reqBody = {
    doctor_id: store.getState().userReducer.hash_id,
    from_date: date.startDate,
    to_date: date.endDate,
    filterBySubmit
  };

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="pciTable">
        <DateGroup2 />
        <div style={{ display: "flex" }}>
          <div onClick={evt => setFilterBySubmit(false)}>
            <label style={{ margin: "0px 10px", cursor: "pointer" }}>
              Appointment Date
            </label>
            <input type="radio" name="date" checked={!filterBySubmit} />
          </div>
          <div onClick={evt => setFilterBySubmit(true)}>
            <label style={{ margin: "0px 10px", cursor: "pointer" }}>
              Submit Date
            </label>
            <input type="radio" name="date" checked={filterBySubmit} />
          </div>
        </div>
        <PCI reqBody={reqBody} brand={brand} />
      </div>
    </dateContext.Provider>
  );
};

const DoctorOOCTable = () => {
  const [flag, setFlag] = useState(true);
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().add(30, "days").format("YYYY-MM-DD")
  );
  const reqBody = {
    admin_key: localStorage.getItem("adminKey"),
    auto_reassign: flag ? 1 : 0,
    startDate,
    endDate
  };
  const handleChange = () => {
    setFlag(!flag);
  };

  return (
    <>
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={startDate}
          onChange={evt => setStartDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={endDate}
          onChange={evt => setEndDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
      </div>
      <form style={{ marginBottom: "0rem" }}>
        <label htmlFor="auto_reassign">Auto Reassign</label>
        <input
          type="checkbox"
          id="auto_reassign"
          onChange={handleChange}
          defaultChecked
        />
      </form>

      <EditableDataTable3
        title="Doctor OOC"
        cols={hoursCols2}
        url={config.url.DOCTOR_OOC}
        reqBody={reqBody}
        urlPost={config.url.DOCTOR_OOC_SAVE}
      />
    </>
  );
};

const ReviewsTable = () => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  let firstDayOfMonth = today.slice(0, -2) + "01";
  const [date, setDate] = useState({
    startDate: firstDayOfMonth,
    endDate: today
  });
  const reqBody = {
    doctor_id: store.getState().userReducer.hash_id,
    from_date: date.startDate,
    to_date: date.endDate
  };

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="reviewTable">
        <DateGroup2 />
        <Reviews reqBody={reqBody} />
      </div>
    </dateContext.Provider>
  );
};
const WeeklyView = React.memo(() => {
  const reqBody2 = { admin_key: localStorage.getItem("adminKey") };
  return (
    <>
      <TableRowBox>
        <DataTable3
          title="Weekly O (Form Filled)"
          cols={weeklyFFCols}
          url={config.url.WEEKLY_FF_O}
          reqBody={reqBody2}
        />
        <DataTable3
          title="Weekly FU (Form Filled)"
          cols={weeklyFFCols}
          url={config.url.WEEKLY_FF_FU}
          reqBody={reqBody2}
        />
        <DataTable3
          title="Weekly F (Form Filled)"
          cols={weeklyFFCols}
          url={config.url.WEEKLY_FF_F}
          reqBody={reqBody2}
        />
      </TableRowBox>
      <TableRowBox>
        <DataTable3
          title="Weekly O (Appointment)"
          cols={weeklyCols}
          url={config.url.WEEKLY_O}
          reqBody={reqBody2}
        />
        <DataTable3
          title="Weekly FU (Appointment)"
          cols={weeklyCols}
          url={config.url.WEEKLY_FU}
          reqBody={reqBody2}
        />
        <DataTable3
          title="Weekly F (Appointment)"
          cols={weeklyCols}
          url={config.url.WEEKLY_F}
          reqBody={reqBody2}
        />
      </TableRowBox>
    </>
  );
});

const DailyView = ({ reqBody1, reqBody2 }) => {
  return (
    <>
      <TableRowBox>
        <DataTable3
          title="Daily O (Appointment)"
          cols={dailyAppCols}
          url={config.url.DAILY_O}
          reqBody={reqBody1}
        />
        <DataTable3
          title="Daily FU (Appointment)"
          cols={dailyAppCols}
          url={config.url.DAILY_FU}
          reqBody={reqBody1}
        />
        <DataTable3
          title="Daily F (Appointment)"
          cols={dailyAppCols}
          url={config.url.DAILY_F}
          reqBody={reqBody1}
        />
      </TableRowBox>
      <TableRowBox>
        <DataTable3
          title="Daily O (Form Filled)"
          cols={dailyFFCols}
          url={config.url.DAILY_FF_O}
          reqBody={reqBody2}
        />
        <DataTable3
          title="Daily FU (Form Filled)"
          cols={dailyFFCols}
          url={config.url.DAILY_FF_FU}
          reqBody={reqBody2}
        />
        <DataTable3
          title="Daily F (Form Filled)"
          cols={dailyFFCols}
          url={config.url.DAILY_FF_F}
          reqBody={reqBody2}
        />
      </TableRowBox>
    </>
  );
};

const DoctorOnboardView = React.memo(() => {
  const reqBody = { admin_key: localStorage.getItem("adminKey") };
  return (
    <>
      <EditableDataTable
        title="Doctor Table View"
        cols={doctorTableCols}
        url={config.url.DOCTOR_TABLE}
        reqBody={reqBody}
        urlPost={config.url.DOCTOR_TABLE}
      />
    </>
  );
});

const TableRowBox = props => {
  return <div className="table-row-box spaced-l">{props.children}</div>;
};

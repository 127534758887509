export const allDoctorsOverviewColumns = [
  {
    title: "Doctor",
    field: "doctor"
  },
  {
    title: "Total",
    field: "total"
  },
  {
    title: "Cancelled",
    field: "cancelled"
  },
  {
    title: "Addressed",
    field: "addressed"
  },
  {
    title: "Total IA",
    field: "totalIA"
  },
  {
    title: "IA Addressal",
    field: "iAAddressal"
  },
  {
    title: "IA Completed",
    field: "iACompleted"
  },
  {
    title: "Total S",
    field: "totalSchedued"
  },
  {
    title: "S Addressal",
    field: "scheduledAddressal"
  },
  {
    title: "S Completed",
    field: "scheduledCompleted"
  },
  {
    title: "Total LSD",
    field: "totalLSD"
  },
  {
    title: "LSD Addressal",
    field: "lsdAddressal"
  },
  {
    title: "LSD Completed",
    field: "lsdCompleted"
  },
  {
    title: "Valid Total",
    field: "validTotal"
  },
  {
    title: "Valid Completed",
    field: "validCompleted"
  },
  {
    title: "PCI",
    field: "averagePci"
  },
  {
    title: "Avg. Duration",
    field: "averageDuration"
  },
  {
    title: "Active Hours",
    field: "activeHours"
  }
];

/**
 * Formats the text to display in the table
 * @param {number} val
 * @returns {string}
 */
export function dataTextFormat(value, valuePercentage) {
  return `${value || 0} | ${valuePercentage || 0}%`;
}

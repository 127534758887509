import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import { Button } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const OOC_FORM = [
  {
    label: "Active",
    value: 1
  },
  {
    label: "Absent",
    value: 2
  },
  {
    label: "Leave",
    value: 3
  }
];

function BulkOOCTable(props) {
  const [rows, setRows] = useState([]);
  const [selectedDoctorIds, setSelectedDoctorIds] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [fromDateTime, setFromDateTime] = useState(
    moment().format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [toDateTime, setToDateTime] = useState(
    moment().add(30, "minutes").format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [typeOfOoc, setTypeOfOoc] = useState(3);
  const [remarks, setRemarks] = useState("");
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      title: "Select",
      field: "action",
      cellStyle: { maxWidth: "5px", wordBreak: "break-all" }
    },
    {
      title: "Doctor Name",
      field: "doctor_name"
    }
  ];

  const fetchDoctorsList = async () => {
    setShowLoader(true);
    const requestData = {};
    await axios
      .post(CONFIG_CONSTANT.url.DOCTOR_LIST_URL, requestData)
      .then(res => {
        if (res.data.statusCode == 200) {
          generateRows(res.data.results);
        } else {
          console.log(res.data);
        }
        setShowLoader(false);
      })
      .catch(err => {
        setShowLoader(false);
        console.log("ERROR IN DOCTOR_LIST_URL", err);
      });
  };

  const handleDoctorSelected = (evt, row) => {
    const checked = evt.target.checked;
    if (checked) {
      setSelectedDoctorIds([...selectedDoctorIds, row.doctor_id]);
    } else {
      const temp = [...selectedDoctorIds];
      const index = temp.indexOf(row.doctor_id);
      temp.splice(index, 1);
      setSelectedDoctorIds(temp);
    }
  };

  useEffect(() => {
    generateRows(rows);
  }, [selectedDoctorIds]);

  const generateRows = data => {
    const tempRows = [];
    for (const row of data) {
      row.action = (
        <div>
          <Checkbox
            checked={selectedDoctorIds.includes(row.doctor_id)}
            onChange={evt => {
              handleDoctorSelected(evt, row);
            }}
          />
        </div>
      );
      tempRows.push(row);
    }
    setRows(tempRows);
  };

  const markBulkOOC = () => {
    const reqBody = {
      type: "add",
      data: {
        doctor_id: selectedDoctorIds,
        date_time_from: fromDateTime,
        date_time_to: toDateTime,
        typeOfOoc,
        remarks
      },
      autoReassign: 1
    };
    axios
      .post(CONFIG_CONSTANT.url.DOCTOR_OOC_SAVE, reqBody)
      .then(res => {
        console.log("---res.data---", res.data);
        setShowModal(false);
        toast.success(res.data.results || "Successfully saved changes");
      })
      .catch(err => {
        setShowLoader(false);
        console.log("ERROR IN markBulkOOC", err);
        setShowModal(false);
        toast.error(err?.response?.data?.results || "Error");
      })
      .finally(() => {
        setRemarks("");
      });
  };

  useEffect(() => {
    fetchDoctorsList();
  }, []);

  return (
    <>
      <div className="bulk-ooc-wrapper">
        <Button
          className="bulk-ooc-button"
          disabled={!selectedDoctorIds.length}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Mark Selected as OOC
        </Button>
      </div>
      <Table4
        title="Bulk Out Of Clinic"
        cols={columns}
        rows={rows}
        pageSize={50}
        search={true}
        exportButton={false}
      />
      <Modal
        show={showModal}
        backdrop="static"
        size="lg"
        dialogClassName="modal-center bulk-ooc-modal"
        centered
        toggle={() => setShowModal(!showModal)}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk OOC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="datetime-local"
              className="input"
              value={fromDateTime}
              onChange={evt =>
                setFromDateTime(
                  moment(evt.target.value).format("YYYY-MM-DD[T]HH:mm:ss")
                )
              }
            />
            <input
              type="datetime-local"
              className="input"
              value={toDateTime}
              onChange={evt =>
                setToDateTime(
                  moment(evt.target.value).format("YYYY-MM-DD[T]HH:mm:ss")
                )
              }
            />
            <form id="oocForm">
              <p>Please select Type of OOC:</p>
              {OOC_FORM.map(oocType => {
                return (
                  <>
                    <label for={oocType.value}>{oocType.label}</label>
                    <input
                      name={"ooc"}
                      type="radio"
                      value={oocType.value}
                      onChange={event => {
                        setTypeOfOoc(event.target.value);
                      }}
                    />
                  </>
                );
              })}
            </form>
            <input
              type="text"
              className="input"
              value={remarks}
              onChange={evt => setRemarks(evt.target.value)}
            />
            <Button
              className="modal-ooc-button"
              onClick={() => {
                markBulkOOC();
              }}
            >
              Mark Selected as OOC
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
      />
    </>
  );
}

export default BulkOOCTable;

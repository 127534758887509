import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../constants/apiList.js";
import Card from "./Card";
import Card2 from "./Card2.js";
import Card3 from "./Card3.js";

export default function NewAdminDashboardTab({ reqBody, arr }) {
  const [data, setData] = useState({});
  const [tab, setTab] = useState("ALL");

  useEffect(() => {
    axios
      .post(config.url.ADMIN_DASHBOARDAPPOINTMENTS, {
        ...reqBody,
        doctor_id_requested: arr
      })
      .then(res => {
        let { summary, summary_mm, summary_bw } = res.data;
        let summary1 = summary,
          summary_mm1 = summary_mm,
          summary_bw1 = summary_bw;
        axios
          .post(config.url.ADMIN_DASHBOARD_PENDINGAPPOINTMENTS, {
            ...reqBody,
            doctor_id_requested: arr
          })
          .then(res => {
            let { summary, summary_mm, summary_bw } = res.data;
            setData({
              summary: [summary1],
              summary_mm: [summary_mm1],
              summary_bw: [summary_bw1],
              pending_summary: [summary],
              pending_summary_mm: [summary_mm],
              pending_summary_bw: [summary_bw]
            });
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  }, [reqBody?.from_date, reqBody?.to_date]);

  return data ? (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{
            padding: "10px 15px",
            color: tab === "ALL" ? "red" : "green",
            cursor: "pointer"
          }}
          onClick={() => setTab("ALL")}
        >
          ALL
        </div>
        <div
          style={{
            padding: "10px 15px",
            color: tab === "MM" ? "red" : "green",
            cursor: "pointer"
          }}
          onClick={() => setTab("MM")}
        >
          MM
        </div>
        <div
          style={{
            padding: "10px 15px",
            color: tab === "BW" ? "red" : "green",
            cursor: "pointer"
          }}
          onClick={() => setTab("BW")}
        >
          BW
        </div>
      </div>

      <div style={{ padding: "10px", color: "black", fontSize: "20px" }}>
        Total
      </div>
      <Card
        data2={
          tab === "ALL"
            ? data.summary || []
            : tab === "MM"
            ? data.summary_mm || []
            : data.summary_bw || []
        }
      />
      <div style={{ padding: "10px", color: "black", fontSize: "20px" }}>
        Pending
      </div>
      <Card2
        data2={
          tab === "ALL"
            ? data.pending_summary || []
            : tab === "MM"
            ? data.pending_summary_mm || []
            : data.pending_summary_bw || []
        }
      />
      <div style={{ padding: "10px", color: "black", fontSize: "20px" }}>
        Summary
      </div>
      <Card3
        data2={
          tab === "ALL"
            ? data.summary || []
            : tab === "MM"
            ? data.summary_mm || []
            : data.summary_bw || []
        }
      />
      {/* <Table6
        title="Pending"
        cols={newDashboardPending}
        rows={
          tab === "ALL"
            ? data.pending_summary || []
            : tab === "MM"
            ? data.pending_summary_mm || []
            : data.pending_summary_bw || []
        }
        pagin={false}
      />
      <Table6
        title="Summary"
        cols={newDashboardSummary}
        rows={
          tab === "ALL"
            ? data.summary || []
            : tab === "MM"
            ? data.summary_mm || []
            : data.summary_bw || []
        }
        pagin={false}
      /> */}
    </>
  ) : null;
}

export const GTM_EVENTS = {
  LOGIN: "login",
  CHECK_IN: "checkin",
  CHECK_OUT: "checkout",
  LOGOUT: "logout",
  APPOINTMENT_NOTIFICATION_VIEWED: "appointmentNotificationViewed",
  APPOINTMENT_NOTIFICATION_CLOSED: "appointmentNotificationClosed",
  OVERVIEW_VIEWED: "overviewViewed",
  PENDING_SECTION_VIEWED: "pendingSectionViewed",
  APPOINTMENT_VIEWED: "appointmentViewed",
  UNAUTHORIZED_APPOINTMENT_VIEWED: "unauthorizedAppointmentViewed",
  ASSIGN_MODAL_VIEWED: "assignModalViewed",
  REASSIGNED_APPOINTMENT: "reassingedAppointment",
  STATUS_UPDATED: "statusUpdated",
  RAISE_TICKET_CLICKED: "raiseTicketClicked",
  PHONE_CALL_CLICKED: "phoneCallClicked",
  LAST_RX_VIEWED: "lastRxViewed",
  QUICK_ACCESS_VIEWED: "quickAccessViewed",
  QUICK_ACCESS_CLOSED: "quickAccessClosed",
  MODIFIED_APPOINTMENT_CLICKED: "modifiedAppointmentClicked",
  RESCHEDULE_MESSAGE_SENT: "rescheduleMessageSent",
  APPOINTMENT_RESCHEDULED: "appointmentRescheduled",
  LANGUAGE_CHANGED: "languageChanged",
  TIMELINE_RX_VIEWED: "timelineRxViewed",
  IMAGE_TIMELINE_VIEWED: "imageTimelineViewed",
  IMAGE_REQUESTED: "imageRequested",
  OBSERVATION_VIEWED: "observationViewed",
  IMAGE_REFRESHED: "imageRefreshed",
  CONCERN_ADDED: "concernAdded",
  PRESCRIPTION_SECTION_VIEWED: "prescriptionSectionViewed",
  PRESCRIPTION_GENERATED: "prescriptionGenerated",
  PRESCRIPTION_SENT: "prescriptionSent",
  RX_WRITER_OPENED: "rxWriterOpened",
  PRODUCTS_RECOMMENDED: "productRecommended",
  PRODUCT_ADDED: "productAdded",
  PRODUCT_REMOVED: "productRemoved"
};

export const GTM_ENTRY_POINTS = {
  RX_WRITER_OPENED: {
    DASHBOARD_HEADER_SEARCH: "Dashboard Header Search",
    RX_WRITER_HEADER_SEARCH: "Rx Writer Header Search",
    VIDEO_APPOINTMENT_NOTIFICATION: "Videocall Notification",
    AUDIO_APPOINTMENT_NOTIFICATION: "Audiocall Notification",
    PENDING_APPPOINTMENT: "Pending Appointment"
  },
  STATUS_UPDATED: {
    RX_WRITER_HEADER: "Rx Writer Header",
    APPOINTMENT_DETAILS_SECTION: "Appointment Details Section",
    POST_PRESCRIPTION_SENT: "Post Prescription Sent"
  }
};

import React from "react";
import "./MainRecommendation.scss";
import RecommendedTag from "../RecommendedTag";
import { Combobox } from "react-widgets";
import { store } from "../../../../../../reducers/Persist";
import { connect } from "react-redux";
import { toast } from "react-toastify";
class MainRecommendation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: [],
      productName: ""
    };
  }
  handleAddProduct = () => {
    if (
      this.state.curProduct &&
      Object.keys(this.state.curProduct).length > 0
    ) {
      let flag = true;
      for (const product of this.props.products) {
        if (product.id === this.state.curProduct.id) {
          toast.info(`${this.state.curProduct.name} has already been added`);
          flag = false;
          break;
        }
      }
      if (flag) {
        store.dispatch({
          type: "ADD_RECOMMENDATION_MAIN_PRODUCT",
          payload: this.state.curProduct
        });
        this.setState({
          productName: "",
          curProduct: {}
        });
      }
    } else toast.error("Please select a product");
  };
  onSelectProduct = val => {
    /* TODO: fix this */
    this.setState(
      {
        curProduct: undefined
      },
      () => {
        this.setState({ curProduct: val });
      }
    );
  };
  render() {
    return (
      <div className="main-recommendation-container">
        <select
          onChange={e => {
            store.dispatch({
              type: "ADD_RECOMMENDATION_DIAGNOSIS",
              payload: e.target.value
            });
          }}
          style={{ width: "100%" }}
        >
          <option value="" selected disabled hidden>
            Select
          </option>
          {this.props.presetData.map((val, index) => (
            <option
              key={index}
              value={val.id}
              selected={Number(this.props.diagnosisKey) === val.id}
            >
              {val.observation_preset_text}
            </option>
          ))}
        </select>
        <div className="product-row">
          <Combobox
            containerClassName="main-recommnedation-search-combobox"
            hideCaret={true}
            placeholder="Type product name here..."
            data={this.props.get_products.filter(
              product => !product.hideInList
            )}
            onChange={value => {
              this.setState({
                ...this.state,
                productName: value
              });
            }}
            onSelect={val => this.onSelectProduct(val)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                this.setState({
                  ...this.state,
                  productName: ""
                });
              }
            }}
            filter="contains"
            textField="name"
            value={this.state.productName}
          />
          <button
            onClick={() => this.handleAddProduct()}
            className="product-button recommend"
          >
            {" "}
            Recommend{" "}
          </button>
        </div>
        {this.props.products?.length > 0 && (
          <div className="recommended-products">
            {this.props.products.map((val, index) => (
              <RecommendedTag
                product={val}
                productName={val.name}
                handleDelete={(isRecommended, id) => {
                  console.log("id", id);
                  store.dispatch({
                    type: "DELETE_RECOMMENDATION_MAIN_PRODUCT",
                    payload: id
                  });
                }}
                type="recommended"
                key={index}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {
    diagnosisKey: store.getState().recommendationBuilderReducer.diagnosis,
    products: store.getState().recommendationBuilderReducer.products
  };
};
export default connect(mapStateToProps)(MainRecommendation);

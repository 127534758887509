import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table5 from "../../common/Tables/Table5.js";
import { reviewsCols } from "./Columns.js";

export default function Reviews({ reqBody }) {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .post(config.url.GET_REVIEWS, reqBody)
      .then(res => {
        setData(res.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  }, [reqBody?.from_date, reqBody?.to_date]);

  return data ? (
    <Table5
      title="Reviews"
      cols={reviewsCols}
      rows={data}
      pageSize={100}
      search={true}
    />
  ) : null;
}

export const MarkIrrelevance = ({ rowData: { nps_irrelevant, id } }) => {
  const [irrelevant, setIrrelevant] = useState(nps_irrelevant);
  const [yes, setYes] = useState(nps_irrelevant);

  const handleClick = e => {
    e.preventDefault();
    axios
      .post(config.url.MARK_IRRELEVANCE, { nps_irrelevant: irrelevant ^ 1, id })
      .then(res => {
        irrelevant ^ 1
          ? alert("Marked Irrelevant!")
          : alert("Marked Relevant!");
        setIrrelevant(irrelevant ^ 1);
      })
      .catch(err => {
        alert("Some error occured!");
      });
  };

  useEffect(() => {
    setYes(irrelevant);
  }, [irrelevant]);

  let styleObj = {
    height: "25px",
    color: "white",
    borderRadius: "3px",
    padding: "5px",
    margin: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  return (
    <div
      onClick={handleClick}
      style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}
    >
      <div style={{ backgroundColor: yes ? "red" : "grey", ...styleObj }}>
        Irrelevant
      </div>
      <div style={{ backgroundColor: !yes ? "green" : "grey", ...styleObj }}>
        Relevant
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";

import "./styles.scss";
import OrderTableCard from "./OrderTableCard";
import { useSelector } from "react-redux";
import OrderSelectCard from "../../OrderSelect/OrderSelectCard";
const OrderTable = ({ parent = "", data }) => {
  let [showMessage, setShowMessage] = useState(false);
  let orderedProducts = useSelector(state => state.orderedProducts.top);
  let orderId = useSelector(state => state.orderedProducts.topOrderId);
  const handleMouseEnter = () => {
    setShowMessage(true);
  };
  const handleMouseLeave = () => {
    setShowMessage(false);
  };
  if (parent.length > 0) {
    for (let key in data) {
      orderedProducts = [...data[key]];
      orderId = key;
    }
  }
  return (
    <div className="orderTable">
      <div className="orderTable_head">
        {orderedProducts.length > 0 ? <h2>Order ID : {orderId}</h2> : null}

        <div className="orderTable_head_right">
          <span>
            <p onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              i
            </p>
            <div
              className="knowMore__message"
              style={!showMessage ? { display: "none" } : null}
            >
              These products have already been dispatched for the patient
              <div className="orderTable_head_right_arrow-down"></div>
            </div>
          </span>
          <p> Know More</p>
        </div>
      </div>
      <div className="orderTable_content">
        <div className="orderTable_content_header">
          <div className="OT_header_product">
            <p>Product</p>
          </div>
          <div className="OT_header_days">Days</div>
          <div className="OT_header_cartQty">Cart Qty.</div>
          <div className="OT_header_instructions">Instructions</div>
          <div className="OT_header_ingredients">Ingredients</div>
          <div className="OT_header_modeOfAction">Mode of Action</div>
          <div className="OT_header_expectationSetting">
            Expectation Setting
          </div>
        </div>
        {orderedProducts?.map((val, i) =>
          parent.length > 0 ? (
            <OrderTableCard product={val} />
          ) : (
            <OrderSelectCard
              key={val.name + i}
              product={val}
              index={i}
              size={orderedProducts?.length}
              parent="orderedProducts"
            />
          )
        )}
      </div>
    </div>
  );
};
export default OrderTable;

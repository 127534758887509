import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../constants/apiList.js";
import { ReviewTitleContainer, TitleSpan } from "./styles";
import MaterialTable from "material-table";
import { MaterialTableWrapper } from "../AppointmentSummary/common/MaterialTableWrapper/index.js";

export const reviewsColsDoctor = [].concat(
  [
    "brand",
    "source",
    "id",
    "patient_name",
    "category",
    "nps_score",
    "nps_suggestion",
    "nps_score_given_at"
  ].map(col => {
    return {
      title: col
        .split("_")
        .map(s => s.charAt(0).toUpperCase() + s.slice(1))
        .join(" "),
      field: col
    };
  })
);

export default function Reviews({ reqBody }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .post(config.url.GET_DOCTOR_REVIEWS, reqBody)
      .then(res => {
        let data1 = res.data.results;
        data1.forEach(row => {
          row.nps_suggestion = row.nps_suggestion ? row.nps_suggestion : "-";
        });
        data1 = data1.filter(row => !row.nps_irrelevant);
        setData(data1);
      })
      .catch(err => {
        console.log(err);
      });
  }, [reqBody?.from_date, reqBody?.to_date]);

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, []);

  return (
    <MaterialTableWrapper>
      <MaterialTable
        title={<ReviewTitle />}
        columns={reviewsColsDoctor}
        data={data || []}
        page={20}
        options={{
          draggable: false,
          pageSize: 10,
          headerStyle: {
            backgroundColor: "rgba(34, 84, 138, 1)",
            color: "white",
            fontSize: "16px",
            border: "none"
          },

          rowStyle: {
            backgroundColor: "white",
            width: "min-content",
            padding: "20px",
            borderRadius: "20px"
          },
          cellStyle: {
            fontSize: matches ? "13px" : "15px",
            borderBottom: "5px solid rgba(246, 248, 251, 1)",
            backgroundColor: "white",
            color: "#6C6C6C"
          }
        }}
        style={{
          margin: "10px",
          padding: "10px",
          marginTop: "0px",
          paddingTop: "0px",
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: "40px"
        }}
      />
    </MaterialTableWrapper>
  );
}

function ReviewTitle() {
  return (
    <ReviewTitleContainer>
      <TitleSpan>Reviews</TitleSpan>
    </ReviewTitleContainer>
  );
}

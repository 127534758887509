import moment from "moment";

const dateToday = moment().format("YYYY-MM-DD");

const initialState = {
  start: dateToday,
  end: dateToday,
  refresh: Math.random()
};

const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATES":
      return { ...state, start: action.data.start, end: action.data.end };
    case "REFRESH":
      return { ...state, refresh: Math.random() };
    default:
      return { ...state };
  }
};

export default appointmentReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import BottomTabComponent from "./BottomTabComponent";
import { store } from "../../../../reducers/Persist";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../../constants/apiList";
import "./Footer.scss";

class Footer extends Component {
  onClickTab = tabNumber => {
    if (this.props.currentTab === 0) {
      const { age, gender, name } = this.props.patientDetails;
      const unfilled = [];
      if (!name?.trim()) unfilled.push("Name");
      if (!age || !age?.toString()?.trim()) unfilled.push("Age");
      if (!gender?.trim()) unfilled.push("Gender");
      if (unfilled.length > 0) {
        toast.error(
          "Please fill the following field(s): \n " + unfilled.join(", ")
        );
        return;
      } else {
        // api call
        const editProfilePayload = this.props.patientDetails;
        editProfilePayload.patient_medical_history = null;
        editProfilePayload.userId = this.props?.prescriptionData.user_id;
        editProfilePayload.brand = this.props?.prescriptionData.brand;
        editProfilePayload.maritalStatus =
          this.props?.patientDetails?.marital_status;

        axios
          .post(config.url.EDIT_PROFILE, editProfilePayload)
          .then(res => {
            this.setState({
              ...this.state
            });
            store.dispatch({
              type: "CHANGE_PATIENT_DETAILS_EDITABLE",
              data: false
            });
            store.dispatch({ type: "CHANGE_TAB", data: tabNumber });
          })
          .catch(err => {
            this.setState({ loading: false });
            console.log(err);
            toast.error("Something went wrong");
            return;
          });
      }
    } else {
      store.dispatch({ type: "CHANGE_TAB", data: tabNumber });
    }
  };

  render() {
    return (
      <Card className="root">
        <div className="center row space-around">
          <BottomTabComponent
            onClick={() => this.onClickTab(0)}
            className={
              this.props.currentTab === 0 ? "selected" : "non-selected"
            }
            title="Patient File"
          />
          <BottomTabComponent
            onClick={() => {
              if (this.props.unlockedTabs.observations) this.onClickTab(1);
            }}
            className={
              this.props.currentTab === 1
                ? "selected"
                : this.props.unlockedTabs.observations
                ? "non-selected"
                : "locked"
            }
            title="Observations"
          />
          <BottomTabComponent
            onClick={() => {
              if (this.props.unlockedTabs.prescription) this.onClickTab(2);
            }}
            className={
              this.props.currentTab === 2 || this.props.currentTab === 3
                ? "selected"
                : this.props.unlockedTabs.prescription
                ? "non-selected"
                : "locked"
            }
            title="Prescription"
          />
          {/* <BottomTabComponent
            onClick={() => onClickTab(3)}
            className="center"
            title="Prescription Preview"
          /> */}
        </div>
      </Card>
    );
  }
}

const mapSateToProps = (state, ownProps) => ({
  currentTab: store.getState().footerReducer.currentTab,
  unlockedTabs: store.getState().footerReducer.unlockedTabs,
  patientDetails: store.getState().rxWriterReducer.patientDetails,
  prescriptionData: store.getState().rxWriterReducer.prescriptionData
});

export default connect(mapSateToProps)(Footer);

import React, { Component } from "react";
import { Grid, Avatar } from "@material-ui/core";
import "./PatientDetails.scss";
import axios from "axios";
import config from "../../../../constants/apiList";
import { FiCopy } from "react-icons/fi";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import GeneralHistoryReport from "./GeneralHistoryReport";
import { toast } from "react-toastify";
import ImageTimelineButton from "./ImageTimelineButton";
import PrescriptionReportButton from "./PrescriptionReportButton";
import ViewReportButton from "./ViewReportButton";
class PatientDetails extends Component {
  constructor() {
    super();
    this.state = {
      openReport: false,
      openPrescription: false,
      openImages: false,
      openHistory: false,
      isLoading: false,
      observations: [],
      patientDetails: this.props?.patientDetails?.length
        ? {
            name: this.props.patientDetails.name,
            age: this.props.patientDetails.age,
            weight: this.props.patientDetails.weight,
            gender: this.props.patientDetails.gender,
            marital_status: this.props.patientDetails.marital_status,
            address: this.props.patientDetails.address
          }
        : {
            name: "",
            age: "",
            weight: "",
            gender: "",
            marital_status: "",
            address: ""
          },
      history: [],
      lastDiagnosis: "",
      familyHistory: "",
      allergies: "",
      habits: "",
      medicalHistory: ""
    };
  }
  handleSave = () => {
    const { age, gender, name } = this.state.patientDetails;
    const unfilled = [];
    if (!name?.trim()) unfilled.push("Name");
    if (!age || !age?.toString()?.trim()) unfilled.push("Age");
    if (!gender?.trim()) unfilled.push("Gender");
    if (unfilled.length > 0) {
      toast.error(
        "Please fill the following field(s): \n " + unfilled.join(", ")
      );
    } else {
      this.setState({
        ...this.state,
        isLoading: true
      });
      // call API to save changes
      const editProfilePayload = this.props.patientDetails;
      editProfilePayload.patient_medical_history = null;
      editProfilePayload.userId = this.props?.prescriptionData.user_id;
      editProfilePayload.brand = this.props?.prescriptionData.brand;
      editProfilePayload.maritalStatus =
        this.props?.patientDetails?.marital_status;

      axios
        .post(config.url.EDIT_PROFILE, editProfilePayload)
        .then(res => {
          this.setState({
            ...this.state,
            isLoading: false
          });
          store.dispatch({
            type: "CHANGE_PATIENT_DETAILS",
            data: this.state.patientDetails
          });
          store.dispatch({
            type: "CHANGE_PATIENT_DETAILS_EDITABLE",
            data: false
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    }
  };

  handleGetProfile = () => {
    if (!this.props.id) {
      return;
    }
    const payload = {
      phone: this.props.prescriptionData?.phone,
      email: this.props.prescriptionData?.email,
      brand: this.props.prescriptionData?.brand,
      appointmentId: this.props.id
    };
    this.setState({ isLoading: true });
    axios
      .post(config.url.GET_PROFILE, payload)
      .then(res => {
        let observationArr = [];
        let presetDataArr = [];
        let observation_preset_id_array = [];
        if (
          res.data.data.patient_medical_history &&
          res.data.data.patient_medical_history.length > 0
        ) {
          observationArr = [
            ...res.data.data.patient_medical_history[
              res.data.data.patient_medical_history.length - 1
            ]?.observations
          ];
          presetDataArr = [
            ...res.data.data.patient_medical_history[
              res.data.data.patient_medical_history.length - 1
            ].presetData
          ];
          // Gets presetData
          axios.get(config.url.GET_PRESET_URL).then(res => {
            presetDataArr.map(val => {
              observation_preset_id_array.push(val.id);
            });
            res.data.data.map(val => {
              presetDataArr.map(val1 => {
                if (val1.observation_preset_id == val.id) {
                  if (val.observation_type === "family_history") {
                    this.setState({
                      ...this.state,
                      familyHistory:
                        this.state.familyHistory.length === 0
                          ? val?.observation_preset_text
                          : val?.observation_preset_text +
                            ", " +
                            this.state.familyHistory
                    });
                  } else if (val.observation_type === "medical_history") {
                    this.setState({
                      ...this.state,
                      medicalHistory:
                        this.state.medicalHistory.length === 0
                          ? val?.observation_preset_text
                          : val?.observation_preset_text +
                            ", " +
                            this.state.medicalHistory
                    });
                  }
                }
              });
              /*if (presetDataArr.includes(val.id.toString())) {
                if (val.observation_type === "family_history") {
                  this.setState({
                    ...this.state,
                    familyHistory:
                      this.state.familyHistory.length === 0
                        ? val?.observation_preset_text
                        : this.state.familyHistory +
                          ", " +
                          val?.observation_preset_text
                  });
                } else if (val.observation_type === "medical_history") {
                  this.setState({
                    ...this.state,
                    medicalHistory:
                      this.state.medicalHistory.length === 0
                        ? val?.observation_preset_text
                        : this.state.medicalHistory +
                          ", " +
                          val?.observation_preset_text
                  });
                  console.log("Medical"+this.state.medicalHistory,val?.observation_text);
                }
              } */
            });
          });
          // lastField = [
          //   ...res.data.data.patient_medical_history[
          //     res.data.data.patient_medical_history.length - 1
          //   ]
          // ];
        }
        observationArr.map(val => {
          let metadata = JSON.parse(val.metadata);
          if (val.observation_type === "diagnosis") {
            this.setState({
              ...this.state,
              lastDiagnosis:
                this.state.lastDiagnosis.length > 0
                  ? this.state.lastDiagnosis +
                    ", " +
                    val.observation_text +
                    " " +
                    (metadata?.type === "provisional" ? "(P)" : "(F)")
                  : val.observation_text +
                    " " +
                    (metadata?.type === "provisional" ? "(P)" : "(F)")
            });
          } else if (val.observation_type === "allergy_specifications") {
            this.setState({
              ...this.state,
              allergies:
                this.state.allergies.length > 0
                  ? this.state.allergies +
                    ", " +
                    val.observation_text +
                    " " +
                    "(" +
                    metadata?.type +
                    ")"
                  : val.observation_text + " " + "(" + metadata?.type + ")"
            });
          } else if (val.observation_type === "family_history") {
            this.setState({
              ...this.state,
              familyHistory: val?.observation_text
            });
          } else if (val.observation_type === "medical_history") {
            this.setState({
              ...this.state,
              medicalHistory: val?.observation_text
            });
          } else if (val.observation_type === "lifestyle_existing") {
            this.setState({
              ...this.state,
              habits:
                this.state.habits.length > 0
                  ? this.state.habits +
                    ", " +
                    val.observation_text +
                    " " +
                    "(" +
                    metadata?.type +
                    ")"
                  : val.observation_text +
                    " " +
                    metadata?.diet +
                    ", " +
                    metadata?.sleep +
                    ", " +
                    metadata?.exercise +
                    ", " +
                    metadata?.stress +
                    ", " +
                    metadata?.habits
            });
          }
        });
        store.dispatch({
          type: "ADD_PATIENT_MEDICAL_HISTORY",
          data: res.data.data.patient_medical_history
        });

        this.setState({
          patientDetails: res.data.data,
          isLoading: false
        });
        if (res.data.data.patient_medical__history?.length > 0) {
          this.setState({
            observations: [
              ...res.data.data.patient_medical_history[
                res.data.data.patient_medical_history.length - 1
              ].observations
            ]
          });
        }
        //giving gender a default value based on brand or if it already coming
        if (!this.state.patientDetails.gender) {
          if (this.state.patientDetails.brand == "BW") {
            this.setState({
              patientDetails: {
                ...this.state.patientDetails,
                gender: "female"
              }
            });
          }
          if (this.state.patientDetails.brand == "MM") {
            this.setState({
              patientDetails: {
                ...this.state.patientDetails,
                gender: "male"
              }
            });
          }
        }

        Object.keys(this.props.patientDetails).length === 0 &&
          store.dispatch({
            type: "CHANGE_PATIENT_DETAILS",
            data: res.data.data
          });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };
  componentDidMount() {
    const func = () => {
      this.handleGetProfile();
    };
    func();
  }

  render() {
    let { doctor_assigned } = this.props.prescriptionData;
    doctor_assigned = !!doctor_assigned ? doctor_assigned : "-";
    return (
      <div className="patientDetails">
        {this.state.isLoading ? (
          <div className="loader-container">
            <Loader type="TailSpin" />
          </div>
        ) : (
          <Grid container spacing={2} item={true}>
            <Grid xs={10} className="header-row" item={true}>
              <Avatar
                alt={this.state.patientDetails?.name}
                className="avatar"
              />
              <div className="column">
                <div className="row">
                  <div className="name">
                    <input
                      value={this.state.patientDetails.name || ""}
                      className="input-field"
                      style={{
                        fontSize: "24px",
                        paddingLeft: "10px",
                        marginBottom: "2px"
                      }}
                      onChange={e => {
                        this.setState({
                          patientDetails: {
                            ...this.state.patientDetails,
                            name: e.target.value
                          }
                        });
                        store.dispatch({
                          type: "CHANGE_PATIENT_DETAILS_DATA",
                          key: "name",
                          data: e.target.value
                        });
                      }}
                      name="name"
                    />
                  </div>
                  <img
                    src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1639987597/dochub/rx-writer/New%20Patient.png"
                    width={115}
                    alt="new patient"
                    className="new-patient"
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {this.props.prescriptionData.mode && (
                    <div className="call-type-tag">
                      {this.props.prescriptionData?.mode === "bw_video"
                        ? "Video call"
                        : "Voice Call"}
                    </div>
                  )}
                  {this.props.prescriptionData.category_label && (
                    <div className="tag">
                      {this.props.prescriptionData.category_label}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid xs={2} item={true}>
              {/* <button
                className={this.props.editable ? "save-button" : "edit-button"}
                onClick={
                  this.props.editable
                    ? this.handleSave
                    : () =>
                        store.dispatch({
                          type: "CHANGE_PATIENT_DETAILS_EDITABLE",
                          data: true
                        })
                }
              >
                {this.props.editable ? "Save" : "Edit"}
              </button> */}
            </Grid>
            <Grid xs={3} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">Age</div>
                <input
                  value={this.state.patientDetails.age || ""}
                  className="input-field"
                  onChange={e => {
                    this.setState({
                      patientDetails: {
                        ...this.state.patientDetails,
                        age: e.target.value
                      }
                    });
                    store.dispatch({
                      type: "CHANGE_PATIENT_DETAILS_DATA",
                      key: "age",
                      data: e.target.value
                    });
                  }}
                  name="age"
                />
              </div>
              <div
                style={{
                  borderTop: "1px dashed #b5b5b5",
                  margin: "0 -12px 25px 0",
                  paddingLeft: "20px"
                }}
              />
              <div className="patient-detail">
                <div className="subheading">Evaluated</div>
                <div className="value">
                  {this.state.lastDiagnosis.length > 0 ? "Yes" : "No"}
                </div>
              </div>
              <div className="patient-detail">
                <div className="subheading">Medical History</div>
                <div className="value">{this.state.medicalHistory || "-"}</div>
              </div>
              <div className="patient-detail">
                <div className="subheading">Assigned Doctor</div>
                <div className="value">{doctor_assigned}</div>
              </div>
            </Grid>
            <Grid xs={3} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">Gender</div>
                <select
                  value={this.state.patientDetails.gender}
                  onChange={e => {
                    this.setState({
                      patientDetails: {
                        ...this.state.patientDetails,
                        gender: e.target.value
                      }
                    });
                    store.dispatch({
                      type: "CHANGE_PATIENT_DETAILS_DATA",
                      key: "gender",
                      data: e.target.value
                    });
                  }}
                >
                  {["Male", "Female", "Other"].map((val, index) => (
                    <option key={index} value={val.toLowerCase()}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  borderTop: "1px dashed #b5b5b5",
                  margin: "0 -12px 25px -12px"
                }}
              />
              <div className="patient-detail">
                <div className="subheading">Family History</div>
                <div className="value">{this.state.familyHistory || "-"}</div>
              </div>
              <div className="patient-detail">
                <div className="subheading">Allergies</div>
                <div className="value">{this.state.allergies || "-"}</div>
              </div>
              <div className="patient-detail">
                <div className="subheading">Habits</div>
                <div className="value">{this.state.habits || "-"}</div>
              </div>
            </Grid>
            <Grid xs={3} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">City</div>
                <input
                  type="text"
                  value={this.state.patientDetails.address || ""}
                  rows="5"
                  className="input-field input-overflow"
                  onChange={e => {
                    this.setState({
                      patientDetails: {
                        ...this.state.patientDetails,
                        address: e.target.value
                      }
                    });
                    store.dispatch({
                      type: "CHANGE_PATIENT_DETAILS_DATA",
                      key: "address",
                      data: e.target.value
                    });
                  }}
                  name="address"
                />
              </div>
              <div
                style={{
                  borderTop: "1px dashed #b5b5b5",
                  margin: "0 -14px 25px -12px"
                }}
              />
              <div className="patient-detail">
                <div className="subheading">Booking Mode</div>
                <div className="value">
                  {this.props.prescriptionData.source_label || "-"}
                </div>
              </div>
              <div className="patient-detail">
                <div className="subheading">Last Diagnosis</div>
                <div className="value">{this.state.lastDiagnosis || "-"}</div>
              </div>
              <div className="patient-detail">
                <div className="subheading">Email ID</div>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="value">
                      {this.state.patientDetails.email || "-"}
                    </div>
                    <FiCopy
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "5px"
                      }}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          this.state.patientDetails.email
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid xs={3} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading"></div>
                <div className="value"></div>
              </div>
              <div
                style={{
                  paddingRight: "20px",
                  borderTop: "1px dashed #b5b5b5",
                  marginBottom: "25px"
                }}
              />

              <div className="patient-detail">
                <div className="subheading">Weight</div>
                <div className="value">
                  {this.state.patientDetails.weight
                    ? this.state.patientDetails.weight.toString() + " Kgs"
                    : "-"}
                </div>
              </div>
              <div className="patient-detail">
                <div className="subheading">Marital Status</div>
                <div className="value">
                  {this.props?.patientDetails?.marital_status ||
                    this.state?.patientDetails?.marital_status ||
                    "-"}
                </div>
              </div>
              <div className="patient-detail">
                <div className="subheading">Phone Number</div>
                <div className="value">
                  {this.state.patientDetails.phone || "-"}
                </div>
              </div>
            </Grid>
            <hr className="section-break" />
            <Grid xs={3} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">Last Diagnostic Report</div>
                <ViewReportButton />
              </div>
            </Grid>
            <Grid xs={3} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">Last Prescription</div>
                <PrescriptionReportButton />
              </div>
            </Grid>
            <Grid xs={3} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">Images</div>
                <ImageTimelineButton />
              </div>
            </Grid>
            <Grid xs={3} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">General History</div>
                <GeneralHistoryReport />
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapSateToProps = (state, ownProps) => ({
  prescriptionData: store.getState().rxWriterReducer.prescriptionData,
  patientDetails: store.getState().rxWriterReducer.patientDetails,
  id: store.getState().rxWriterReducer.id,
  patient_medical_history:
    store.getState().rxWriterReducer.patient_medical_history
});
export default connect(mapSateToProps)(PatientDetails);

import React from "react";
import { LoadingOverLayContainer } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function LoadingOverLay({ show }) {
  if (!show) return null;
  return (
    <LoadingOverLayContainer>
      <FontAwesomeIcon size="10x" color="#83B692" icon={faSpinner} spin />
    </LoadingOverLayContainer>
  );
}

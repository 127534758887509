import dataResource from "../constants/dataResource";

export const iaEnabled = brand => {
  if (!brand) return false;
  const enabledFor = {
    BW: true,
    MM: true,
    LJ: true
  };
  return !!enabledFor[brand.toUpperCase()];
};

export const iaEnabledForDoctor = brands => {
  if (!(brands && brands.length)) return true;
  for (const brand of brands) {
    if (!iaEnabled(brand)) return false;
  }
  return true;
};

import React from "react";
import "./SearchProduct.scss";
import config from "../../../../../constants/apiList";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Combobox } from "react-widgets";
import OrderSelect from "../../OrderSelect";
import { store } from "../../../../../reducers/Persist";
import { connect } from "react-redux";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
class SearchProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      products: [],
      productName: "",
      productSelected: false,
      selectedProduct: {}
    };
  }

  componentDidMount() {
    let payload2 = {
      brand: this.props.brand,
      doctorId: this.props.doctorId
    };
    this.setState({ loading: false });
    axios
      .post(config.url.GET_BODYWISE_PRODUCTS_URL, payload2)
      .then(res => {
        let arr = [];
        for (const prod in res.data.body.data) {
          const product = res.data.body.data[prod];
          // if (product.hide_on_rx == 1) continue; // HIDE ON RX
          arr.push(product);
        }
        this.setState({ products: arr, loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  }
  orderedProducts = store.getState().orderedProducts.top;
  render() {
    const { index } = this.props;
    const dispatch = store.dispatch;

    return (
      <div className="search-product-container">
        {this.state.productSelected ? (
          <></>
        ) : this.state.loading ? (
          <div className="loader-container">
            <Loader type="TailSpin" />
          </div>
        ) : (
          <div className="search-product-body">
            <div className="subheading">Search for a product</div>
            <Combobox
              containerClassName="search-combobox"
              hideCaret={true}
              placeholder="Type product name here..."
              data={this.state.products.filter(product => !product.hideInList)}
              onChange={value => {
                this.setState({ productName: value });
              }}
              onSelect={val => {
                this.setState({
                  ...this.state,
                  productSelected: false,
                  selectedProduct: val,
                  productName: ""
                });
                if (this.orderedProducts.length === 0) {
                  if (this.props.aboveProduct) {
                    dispatch({
                      type: "CHANGE_RECOMMENDED_DATA",
                      payload: {
                        index: index,
                        newData: { ...val, currentFreqIndex: 0 }
                      }
                    });
                  } else {
                    if (!!this.props.cancelDelete) {
                      dispatch({
                        type: "ADD_RECOMMENDED_PRODUCT",
                        payload: { ...val, currentFreqIndex: 0 },
                        cancelDelete: true
                      });
                    } else {
                      dispatch({
                        type: "ADD_RECOMMENDED_PRODUCT",
                        payload: { ...val, currentFreqIndex: 0 }
                      });
                    }
                  }
                } else if (this.props.parent === "recommendedProducts") {
                  dispatch({
                    type: "CHANGE_RECOMMENDED_DATA",
                    payload: {
                      index,
                      newData: { ...val, currentFreqIndex: 0 }
                    }
                  });
                } else {
                  const order_id =
                    this?.orderedProducts?.find(product => !!product.order_id)
                      ?.order_id || "";
                  if (this.props.aboveProduct) {
                    dispatch({
                      type: "CHANGE_TOP_DATA",
                      payload: {
                        index,
                        newData: { ...val, order_id, currentFreqIndex: 0 }
                      }
                    });
                  } else {
                    dispatch({
                      type: "ADD_TOP_PRODUCT",
                      payload: { ...val, order_id, currentFreqIndex: 0 }
                    });
                  }
                }
                addToGTM({
                  event: GTM_EVENTS?.PRODUCT_ADDED,
                  addedProductData: val
                });
                this.props.handleAddBtn(); // this button adds the product and makes the product ///// uneditable
              }}
              onKeyPress={e => {
                if (e.key == "Enter") {
                  this.setState({
                    ...this.state,
                    productName: ""
                  });
                }
              }}
              filter="contains"
              textField="name"
              value={this.state.productName}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {
    brand: store.getState().rxWriterReducer.brand,
    doctorId: store.getState().userReducer.hash_id
  };
};
export default connect(mapStateToProps)(SearchProduct);

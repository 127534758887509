const dataResource = {
  adminDashboardKey: "password",
  categoryList: ["HC", "SH", "OF", "F", "O", "FU", "DA", "MA"],
  categoryMap: {
    is_hc: "Hair",
    is_sh: "Performance",
    skin: "Skin",
    weight: "Weight",
    uti: "Bodycare",
    "body-care": "Bodycare",
    pcos: "PCOS",
    beard: "Beard",
    general: "General",
    nutrition: "Nutrition",
    immediate: "Immediate"
  },
  complaintsList: [
    "Decreased erections",
    "Decreased ejaculation time",
    "Decreased sexual desire",
    "Decreased sexual performance",
    "Hair fall",
    "Hair thinning",
    "Dandruff",
    "Itchy scalp",
    "Receding Hairline",
    "No Complaints",
    "Acne",
    "Acne Spots",
    "Acne Scars",
    "Sensitive Skin",
    "Fine lines",
    "Wrinkles",
    "Uneven skin tone",
    "Tan lines",
    "Sun damage",
    "Overweight",
    "Combination skin"
  ],
  statusListObj: {
    s: "Scheduled",
    c: "Completed",
    r: "Ringing",
    b: "Busy",
    so: "Switched off",
    d: "Disconnected",
    cb: "Call back",
    rs: "Rescheduled",
    wn: "Wrong no",
    ni: "Not interested",
    ns: "Not Suitable",
    re: "Repeat",
    l: "Language",
    op: "Ordered",
    nr: "No Ring",
    od: "Other doctor",
    co: "Cool off",
    t: "testing",
    na: "NA Completed",
    cn: "IVR Cancelled",
    rf: "Completed + Specialist Refer",
    otc: "Completed + No RX",
    p: "Pending",
    pl: "Pending + Language",
    pi: "Pending + Image",
    pc: "Pending + Callback",
    pbt: "Pending + Brand Transfer",
    pp: "Pending + Prescription",
    csp: "Completed + Suggest Procedure",
    csd: "Completed + Suggest Diagnostics",
    rc: "Rx Clear",
    cq: "Completed + Query",
    cp: "Completed + Provisional",
    ip: "Invalid Patient"
  },
  remarksList: [
    "Remark 1",
    "Remark 2",
    "Remark 3",
    "Remark 4",
    "Remark 5",
    "Remark 6"
  ],
  historyList: [
    "DM",
    "HTN",
    "IHD",
    "Liver disease",
    "Drug allergies",
    "No DM / HTN / IHD / Liver Disease / Drug Allergies",
    "Hypothyroidism",
    "PCOS"
  ],
  instructionsList: [
    "30 minutes of regular exercise",
    "Medicines not to be taken with alcohol (gap of 8 hours)",
    "Use a wide toothed wood comb after Minoxidil application, comb for 5 minutes",
    "Medicines to be taken after food",
    "Drink 3 to 4 liters of water everyday",
    "Get 6 to 8 hours of sleep",
    "Eat a balanced diet, include green leafy vegetables, fruits and nuts",
    "Activator Hygiene - Wash with warm water. Immerse Activator into alcohol / surgical spirit, needles should be immersed. Keep for 5-10 minutes. Remove Activator from alcohol / surgical spirit and allow to dry. Repeat steps before and after using the Activator"
  ],
  diagnosisList: [
    "Erectile dysfunction",
    "Mild erectile dysfunction",
    "Premature ejaculation",
    "Mild premature ejaculation",
    "Decreased sexual performance",
    "Androgenic Alopecia",
    "Dandruff",
    "Telogen Effluvium",
    "Post inflammatory hyperpigmentation",
    "Pustular acne",
    "Papular acne",
    "Nodular acne",
    "Cystic acne",
    "Comedones",
    "Post inflammatory Erythema",
    "Female pattern hairloss",
    "Photoaging",
    "Elastosis"
  ],
  staticListOfMeds: [
    {
      prod_name: "Nicotex 2 mg",
      prod_id: "new",
      usage_frequency: ["SOS"],
      usage_instructions: [
        "Chew the Nicotex gum slowly when you feel an urge to smoke, place and rest it between your cheek and gum- to allow nicotine absorption through the inner lining of the mouth. Park the Nicotex gum for about 30 minutes or until the taste dissipates"
      ]
    },
    {
      prod_name: "Nicotex 4 mg",
      prod_id: "new",
      usage_frequency: ["SOS"],
      usage_instructions: [
        "Chew the Nicotex gum slowly when you feel an urge to smoke, place and rest it between your cheek and gum"
      ]
    },
    {
      prod_name: "Vital Performance test",
      prod_id: "new",
      usage_frequency: ["1"],
      usage_instructions: ["CBC,FBS,TSH,Sr. Total Testosterone,Lipid Profile ."]
    },
    {
      prod_name: "Advanced Performance test",
      prod_id: "new",
      usage_frequency: ["1"],
      usage_instructions: [
        "CBC,FBS,TSH,Sr. Total Testosterone,Lipid Profile,SGOT & SGPT,Sr. Creatinine"
      ]
    }

    // {
    //   prod_name: "Trichortan Forte",
    //   prod_id: "new",
    //   usage_frequency: ["Daily"],
    //   usage_instructions: ["Take 1 tablet daily after lunch"]
    // },
    // {
    //   prod_name: "Cetzine",
    //   prod_id: "new",
    //   usage_frequency: ["SOS"],
    //   usage_instructions: ["Take 1 tablet in case of itching or sudden redness"]
    // },
    // {
    //   prod_name: "Redensyl 3%",
    //   prod_id: "new",
    //   usage_frequency: ["Daily"],
    //   usage_instructions: [
    //     "Use daily, 1 hour before going to sleep. Spray it on the affected areas of your scalp, massage it into your scalp for 2 mins for increased absorption. Keep it overnight. (You will get this product at a local Pharmacy.)"
    //   ]
    // },
    // {
    //   prod_name: "KETO AZ Antidandruff Shampoo",
    //   prod_id: "new",
    //   usage_frequency: ["Weekly"],
    //   usage_instructions: [
    //     "Use twice a week. Apply to wet hair and massage it onto the scalp. Leave it on for 2 to 3 mins and rinse with cold water."
    //   ]
    // }
  ],
  apiRemarks: [
    { text: "remark1", timestamp: "time1" },
    { text: "remark2", timestamp: "time2" },
    { text: "remark3", timestamp: "time3" },
    { text: "remark4", timestamp: "time4" },
    { text: "remark5", timestamp: "time5" }
  ],
  statusList: [
    { value: "s", label: "Scheduled" },
    { value: "na", label: "NA Completed" },
    { value: "c", label: "Completed" },
    { value: "r", label: "Ringing" },
    { value: "b", label: "Busy" },
    { value: "so", label: "Switched Off" },
    { value: "d", label: "Disconnecting" },
    { value: "rs", label: "Rescheduled" },
    { value: "wn", label: "Wrong No" },
    { value: "ni", label: "Not Interested" },
    { value: "ns", label: "Not suitable" },
    { value: "re", label: "Repeat" },
    { value: "l", label: "Language" },
    { value: "nr", label: "No ring" },
    { value: "cn", label: "IVR Cancelled" },
    { value: "co", label: "Cool off" },
    { value: "t", label: "Testing" },
    { value: "rf", label: "Completed + Specialist Refer" },
    { value: "otc", label: "Completed + No RX" },
    { value: "p", label: "Pending" },
    { value: "csp", label: "Completed + Suggest Procedure" },
    { value: "csd", label: "Completed + Suggest Diagnostics" },
    { value: "pl", label: "Pending + Language" },
    { value: "pi", label: "Pending + Image" },
    { value: "pc", label: "Pending + Callback" },
    { value: "pbt", label: "Pending + Brand Transfer" },
    { value: "pp", label: "Pending + Prescription" },
    { value: "cq", label: "Completed + Query" }
  ],
  patientFileStatusList: [
    { value: "r", label: "Ringing" },
    { value: "b", label: "Busy" },
    { value: "so", label: "Switched Off" },
    { value: "d", label: "Disconnecting" },
    { value: "nr", label: "No ring" },
    { value: "pl", label: "Pending + Language" },
    { value: "pi", label: "Pending + Image" },
    { value: "pc", label: "Pending + Callback" },
    { value: "pbt", label: "Pending + Brand Transfer" },
    { value: "pp", label: "Pending + Prescription" },
    { value: "ni", label: "Not Interested" },
    { value: "wn", label: "Wrong No" },
    { value: "re", label: "Repeat" },
    { value: "t", label: "Testing" },
    { value: "cq", label: "Completed + Query" }
  ],
  postRxSendingStatusList: [
    { value: "c", label: "Completed" },
    { value: "ns", label: "Not suitable" }
  ],
  patientHistory: [
    {
      id: 1,
      activityName: "Electronics",
      description: "Hypertens NOS-antepartum",
      dateTime: "2019-11-05 21:43:47"
    },
    {
      id: 2,
      activityName: "Games",
      description: "Fall into well",
      dateTime: "2019-11-16 17:41:38"
    },
    {
      id: 3,
      activityName: "Automotive",
      description: "Failure in dosage NEC",
      dateTime: "2019-12-05 08:35:21"
    },
    {
      id: 4,
      activityName: "Garden",
      description: "Mild preeclamp-del w p/p",
      dateTime: "2020-03-03 22:06:00"
    },
    {
      id: 5,
      activityName: "Sports",
      description: "Disloc lst cerv vert-opn",
      dateTime: "2019-09-18 19:18:03"
    },
    {
      id: 6,
      activityName: "Outdoors",
      description: "TB sp crd absc-micro dx",
      dateTime: "2019-07-10 10:09:11"
    },
    {
      id: 7,
      activityName: "Shoes",
      description: "Hodgkins paragran mult",
      dateTime: "2020-03-06 05:40:29"
    },
    {
      id: 8,
      activityName: "Home",
      description: "Respiratory comp NEC",
      dateTime: "2020-03-22 01:53:28"
    },
    {
      id: 9,
      activityName: "Jewelery",
      description: "Congenital coxa vara",
      dateTime: "2020-03-30 07:27:30"
    },
    {
      id: 10,
      activityName: "Garden",
      description: "Anom digestive syst NEC",
      dateTime: "2019-11-03 09:53:18"
    },
    {
      id: 11,
      activityName: "Beauty",
      description: "Psychic factor w oth dis",
      dateTime: "2019-07-15 23:59:41"
    },
    {
      id: 12,
      activityName: "Clothing",
      description: "Inflam/tox neuropthy NOS",
      dateTime: "2019-08-17 21:25:11"
    },
    {
      id: 13,
      activityName: "Health",
      description: "Cord around neck-deliver",
      dateTime: "2019-09-16 23:26:58"
    },
    {
      id: 14,
      activityName: "Home",
      description: "Opn wnd leg NEC w tendon",
      dateTime: "2020-04-08 12:05:15"
    },
    {
      id: 15,
      activityName: "Outdoors",
      description: "Autoimmune hepatitis",
      dateTime: "2019-07-01 06:52:21"
    },
    {
      id: 16,
      activityName: "Beauty",
      description: "Swelling in head & neck",
      dateTime: "2019-08-23 19:28:27"
    },
    {
      id: 17,
      activityName: "Outdoors",
      description: "Fx upper end fibula-clos",
      dateTime: "2019-10-25 07:05:50"
    },
    {
      id: 18,
      activityName: "Kids",
      description: "Chondroectoderm dysplas",
      dateTime: "2019-06-08 16:49:05"
    },
    {
      id: 19,
      activityName: "Books",
      description: "Oth ob surg compl-unspec",
      dateTime: "2019-09-29 23:45:20"
    },
    {
      id: 20,
      activityName: "Shoes",
      description: "Traum arthropath-forearm",
      dateTime: "2019-10-30 13:13:32"
    },
    {
      id: 21,
      activityName: "Health",
      description: "Op subarach hem-brf coma",
      dateTime: "2019-12-05 22:11:08"
    }
  ],
  FU_Questions: {
    how_long: "How long have you used the products?",
    face_issues: "Did you face any issues while using the products?",
    elaborate: "If yes - Please elaborate your issues",
    anything_else: "Anything else you would like our experts to know?",
    category: "What do you need help with?",
    hair_ques: "What do you want to improve?(Hair)",
    hair_ques_2: "Which of the following best describes your hair loss?",
    performance_ques: "What do you want to improve?(Performance)",
    performance_issue:
      "What specific concerns would you like to discuss?(Performance)",
    skin_ques: "Please Describe your Skin",
    skin_ques_v2: "Please Describe your Skin",
    skin_acne_ques: "How often does your acne/pimple appear?",
    skin_aging_ques: "Describe The Wrinkles Around Your Eyes And Forehead.",
    skin_type_ques: "Desribe your Skin Type",
    beard_ques: "How would you describe your beard?",
    beard_strength: "What is the strongest part of your beard?",
    beard_identify: "What do you identify with?",
    beard_identify_v2: "What do you need help with?",
    performance_help: "What do you need help with?",
    weight_identify: "Which of the following do you identify with?",
    weight_frequency: "What is the frequency of your meals?",
    weight_calories: "Where do you get most of your calories from?",
    weight_habits: "Which of the following do you identify with?"
  },
  languages: [
    "English",
    "Hindi",
    "Tamil",
    "Telugu",
    "Kannada",
    "Malayalam",
    "Marathi",
    "Bengali"
  ],
  heightOptions: [
    {
      value: "4' 3''",
      label: "4ft 3in"
    },
    {
      value: "4' 4''",
      label: "4ft 4in"
    },
    {
      value: "4' 5''",
      label: "4ft 5in"
    },
    {
      value: "4' 6''",
      label: "4ft 6in"
    },
    {
      value: "4' 7''",
      label: "4ft 7in"
    },
    {
      value: "4' 8''",
      label: "4ft 8in"
    },
    {
      value: "4' 9''",
      label: "4ft 9in"
    },
    {
      value: "4' 10''",
      label: "4ft 10in"
    },
    {
      value: "4' 11''",
      label: "4ft 11in"
    },
    {
      value: "5' 0''",
      label: "5ft 0in"
    },
    {
      value: "5' 1''",
      label: "5ft 1in"
    },
    {
      value: "5' 2''",
      label: "5ft 2in"
    },
    {
      value: "5' 3''",
      label: "5ft 3in"
    },
    {
      value: "5' 4''",
      label: "5ft 4in"
    },
    {
      value: "5' 5''",
      label: "5ft 5in"
    },
    {
      value: "5' 6''",
      label: "5ft 6in"
    },
    {
      value: "5' 7''",
      label: "5ft 7in"
    },
    {
      value: "5' 8''",
      label: "5ft 8in"
    },
    {
      value: "5' 9''",
      label: "5ft 9in"
    },
    {
      value: "5' 10''",
      label: "5ft 10in"
    },
    {
      value: "5' 11''",
      label: "5ft 11in"
    },
    {
      value: "6' 0''",
      label: "6ft 0in"
    },
    {
      value: "6' 1''",
      label: "6ft 1in"
    },
    {
      value: "6' 2''",
      label: "6ft 2in"
    },
    {
      value: "6' 3''",
      label: "6ft 3in"
    },
    {
      value: "6' 4''",
      label: "6ft 4in"
    },
    {
      value: "6' 5''",
      label: "6ft 5in"
    },
    {
      value: "6' 6''",
      label: "6ft 6in"
    },
    {
      value: "6' 7''",
      label: "6ft 7in"
    },
    {
      value: "6' 8''",
      label: "6ft 8in"
    },
    {
      value: "6' 9''",
      label: "6ft 9in"
    }
  ],
  COOL_OFF_TYPE: {
    ADD: "add",
    REMOVE: "remove"
  },
  CALL_STATUS: {
    INPROGRESS: "in-progress",
    COMPLETED: "completed"
  },
  APPOINTMENT_TYPE: {
    INSTANT: "IA",
    SCHEDULED: "S",
    LAST_DOCTOR_SCHEDULED: "LSD"
  },
  CURRENT_APPOINTMENT_STATUS_LIST: ["s", "rs"],
  BRANDS_SHORT_NAME: {
    MM: "MM",
    BW: "BW",
    LJ: "LJ"
  },
  BRANDS_SHORT_TO_FULL_NAME: {
    MM: "Man Matters",
    BW: "Be Bodywise",
    LJ: "Little Joys"
  },
  AUDIT_EVENT_LOGS: [
    "doctor_status_updated",
    "short_call",
    "appointment_modified",
    "video_link_sent",
    "image_uploaded",
    "image_requested",
    "reschedule_message_sent",
    "audio_call_end",
    "prescription_generated",
    "prescription_sent",
    "status_updated",
    "patient_dropped",
    "doctor_dropped",
    "patient_joined",
    "doctor_joined",
    "reassignment",
    "assignment",
    "ivr_confirmed",
    "ivr_call",
    "appointment_created"
  ],
  DOCTOR_STATUS_LIST: {
    ACTIVE: 1,
    BUSY: 2,
    COOLOFF: 3,
    BREAK: 4,
    OUT_OF_CLINIC: 5,
    STEP_AWAY: 6,
    CHECKED_OUT: 7
  },
  DATE_FORMATS: {
    LONG_DATETIME_FORMAT: "dddd, DD MMMM YY hh:mm A",
    DEFAULT_DATETIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
    LONG_DATE_FORMAT: "dddd, DD MMMM YY",
    LONG_TIME_FORMAT: "HH:mm:ss",
    SHORT_TIME_FORMAT: "hh:mm A",
    SHORT_TIME_FORMAT_WITH_SECONDS: "hh:mm:ss A",
    SHORT_DATE_FORMAT: "YYYY-MM-DD",
    MINI_TIME_FORMAT: "HH:mm",
    DAY_NUMBER_FORMAT: "d",
    DAY_OF_MONTH_DATETIME_FORMAT: "Do MMM, YYYY h:mm A"
  },
  BRANDS_NOTIFICATION: {
    MM: {
      BRAND: "MM",
      TITLE: "You have a new appointment to Man Matters",
      BODY: "",
      DATA: "",
      TIMEOUT: "5000",
      FAVICON:
        "https://ik.manmatters.com/mosaic-wellness/image/upload/f_auto,w_800,c_limit/v1656921531/mm_logo.png"
    },
    BW: {
      BRAND: "BW",
      TITLE: "You have a new appointment to Be Bodywise",
      BODY: "",
      DATA: "",
      TIMEOUT: "5000",
      FAVICON:
        "https://ik.manmatters.com/mosaic-wellness/image/upload/f_auto,w_800,c_limit/v1656921410/bw_logo.png"
    },
    LJ: {
      BRAND: "LJ",
      TITLE: "You have a new appointment to Little Joys",
      BODY: "",
      DATA: "",
      TIMEOUT: "5000",
      FAVICON:
        "https://ik.ourlittlejoys.com/media/little-joys/logon_e4W8-oW7c.png"
    }
  },
  COMPLETED_APPOINTMENT_STATUSES: ["c", "csp", "cq", "csd", "ns"],
  IMAGE_SUPPORTED_BRAND: ["MM", "BW", "LJ"],
  RESCHEDULE_SUPPORTED_BRAND: ["MM", "BW"]
};

export default dataResource;

import { dev } from "./dev";
import { stg } from "./stg";
import { prod } from "./prod";
import { omkar } from "./omkar";
import { uat } from "./uat";

let config = dev;

switch (process.env.REACT_APP_ENV) {
  case "development":
    config = dev;
    break;
  case "staging":
    config = stg;
    break;
  case "uat":
    config = uat;
    break;
  case "omkar":
    config = omkar;
    break;
  case "production":
    config = prod;
    break;
  default:
    config = dev;
}

// config = stg;

export default config;

import React, { Component } from "react";
import config from "../../../../constants/apiList";
import axios from "axios";
import { alertBox } from "../../../common/PopupBox/PopupBox";
import moment from "moment";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import "./History.scss";
import Iframe from "react-iframe";
export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: this.props.history
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.history !== this.props.history) {
      this.setState({
        history: this.props.history
      });
    }
  }
  orderPlacedShowClickHandler = (index, orderDetailsRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    const tempHistory = [...this.state.history];
    const tempItem = { ...item };
    tempItem.showing = true;
    tempHistory.splice(index, 1, tempItem);
    this.setState({ history: tempHistory });
    axios
      .post(config.url.ORDER_DETAILS_URL, orderDetailsRequestData)
      .then(response => {
        if (!response.data) {
          alertBox("cannot retrieve data");
          return;
        }
        const recommended = [];
        response.data.body.data.product_kits.map((prod, index) => {
          prod.product_items.map((singleItem, index) => {
            recommended.push(singleItem.name);
          });
        });
        response.data.body.data.product_items.map((prod, index) => {
          recommended.push(prod.name);
        });
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.showing = false;
        tempItem.recommendedProducts = recommended;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("OrderDetails API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.showing = false;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  formFilledShowClickHandler = (index, prescriptionRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    axios
      .post(config.url.PRESCRIPTION_URL, prescriptionRequestData)
      .then(response => {
        if (!response.data) {
          alertBox("cannot retrieve data");
          return;
        }
        const recommended = [];
        response.data.body.data.product_kits.map((prod, index) => {
          prod.product_items.map((singleItem, index) => {
            recommended.push(singleItem.name);
          });
        });
        response.data.body.data.product_items.map((prod, index) => {
          recommended.push(prod.name);
        });
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.recommendedProducts = recommended;
        tempItem.form_details = response.data.body.data.form_details;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("PRESCRIPTION API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  formSubmittedShowClickHandler = (index, formSubmitRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    axios
      .post(config.url.FORM_SUBMIT_EVENT_URL, formSubmitRequestData)
      .then(response => {
        if (!response.data) {
          alertBox("cannot retrieve data");
          return;
        }
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.form_details = response.data.form_details;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("FormSubmit API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  prescriptionGeneratedShowClickHandler = (e, getPdfRequestData) => {
    axios
      .post(config.url.GET_PDF_URL, getPdfRequestData)
      .then(response => {
        this.setState({ pdf: response.data.body.data, showPdfModal: true });
      })
      .catch(err => {
        console.log("IN GET PDF ERROR", err);
        alertBox("Unable to show Prescription. Try again Later");
      });
  };
  renderOrderPlacedCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    let orderDetailsRequestData = {
      form: item.category ? item.category : "OF",
      order_id: item.form_id ? item.form_id : item.order_id,
      event_type: item.event_type,
      brand: this.props.brand
    };
    return (
      <div className="history-card order-placed">
        <div className="history-card-title">
          {this.getDisplayTitle(item.event_type)} {item.order_id}
        </div>
        <div className="history-date-and-button-container">
          <div>{createdDate}</div>
          <button
            className="history-card-show-button order-placed"
            onClick={evt =>
              this.orderPlacedShowClickHandler(index, orderDetailsRequestData)
            }
          >
            {item.showProducts ? (
              "Hide"
            ) : item.showing ? (
              <Loader type="TailSpin" color="#00BFFF" height={20} width={20} />
            ) : (
              "Show"
            )}
          </button>
        </div>
        <div className="history-order-status">
          Order Status: <span>{this.getDisplayTitle(item.order_status)}</span>
        </div>
        {item.type === "RX" ? (
          <div>
            Appointment Schedule: <span>{item.appointment_schedule}</span>
          </div>
        ) : null}
        <div>
          <span>{this.props.userInfo?.data?.name}</span> ordered on{" "}
          <span>{createdDate}</span>
        </div>
        {item.type === "RX" && item.doctor_assigned ? (
          <div>
            The consultation was assigned to <span>{item.doctor_assigned}</span>
          </div>
        ) : null}
        {item.showProducts ? (
          <>
            <div style={{ marginTop: "10px" }}>
              <span>Recommended Products:</span>
            </div>
            <div>
              {item.recommendedProducts && item.recommendedProducts.length > 0
                ? item.recommendedProducts &&
                  item.recommendedProducts.join(", ")
                : "No Product to show."}
            </div>
          </>
        ) : null}
      </div>
    );
  };
  renderQNA = form_details => {
    let data = {
      Hair: form_details.Hair,
      Weight: form_details.Weight,
      Skin: form_details.Skin,
      Performance: form_details.Performance,
      Fitness: form_details.Fitness,
      Nutrition: form_details.Nutrition,
      Stress: form_details.Stress,
      Sleep: form_details.Sleep,
      Lifestyle: form_details.Lifestyle,
      Wellness: form_details.Wellness,
      Health: form_details.Health
    };
    let renderView = [];
    for (let [key, value] of Object.entries(data)) {
      if (value && Object.keys(value).length > 0) {
        let qnaHead = (
          <div>
            <u>
              <b>{key}</b>
            </u>
          </div>
        );
        renderView.push(qnaHead);
        for (let [key2, value2] of Object.entries(value)) {
          if (Object.keys(value).length > 0) {
            let qnaView = (
              <div>
                <div>Q :{key2}</div>
                <div>
                  <span className="ans">A : </span> {value2}
                </div>
                <br />
              </div>
            );
            renderView.push(qnaView);
          }
        }
      }
    }
    return renderView;
  };
  renderFormFilledCard = (item, index) => {
    let prescriptionRequestData = {
      doctorId: this.props.doctorId,
      form: item.category ? item.category : "OF",
      id: item.form_id ? item.form_id : item.order_id
    };
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className="history-card form-filled">
        <div className="history-card-title">{displayTitle}</div>
        <div className="history-date-and-button-container">
          <div>{createdDate}</div>
          <button
            className="history-card-show-button form-filled"
            onClick={evt =>
              this.formFilledShowClickHandler(index, prescriptionRequestData)
            }
          >
            {item.showProducts ? "Hide" : "Show"}
          </button>
        </div>
        <div>
          <span>{this.props.userInfo?.data?.name}</span> filled the{" "}
          {displayTitle.slice(0, displayTitle.indexOf(" "))} Form on{" "}
          <span>{createdDate} </span>
          and was recommended.
          <br /> The consultation was assigned to{" "}
          <span>{item.doctor_assigned}</span>.
        </div>
        {item.showProducts ? (
          <div>
            <div>
              <span className="highlight">Recommended Products:</span>
            </div>
            {item.recommendedProducts && item.recommendedProducts.length > 0
              ? item.recommendedProducts.join(",")
              : "No Product to show."}
            <div>
              <span className="highlight">QNA</span>
            </div>
            {item.form_details ? (
              <div style={{ maxHeight: "200px", overflow: "auto" }}>
                {this.renderQNA(item.form_details)}
              </div>
            ) : (
              "No QNA available!"
            )}
          </div>
        ) : null}
      </div>
    );
  };
  renderFormSubmittedCard = (item, index) => {
    let formSubmitRequestData = {
      brand: this.props.brand,
      doctorId: this.props.doctorId,
      formID: item.form_id
    };
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className="history-card form-submitted">
        <div className="history-card-title">{displayTitle}</div>
        <div className="history-date-and-button-container">
          <div>{createdDate}</div>
          <button
            className="history-card-show-button form-submitted"
            onClick={evt =>
              this.formSubmittedShowClickHandler(index, formSubmitRequestData)
            }
          >
            {item.showProducts ? "Hide" : "Show"}
          </button>
        </div>
        <div>
          <span>{this.props.userInfo?.data?.name}</span> filled the Form on{" "}
          <span>{createdDate} </span>
        </div>
        {item.showProducts ? (
          <div>
            <div>
              <span>QNA</span>
            </div>
            {item.form_details ? (
              <div style={{ maxHeight: "200px", overflow: "auto" }}>
                {this.renderQNA(item.form_details)}
              </div>
            ) : (
              "No QNA available!"
            )}
          </div>
        ) : null}
      </div>
    );
  };
  renderAppointmentRescheduleCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className="history-card appointment-rescheduled">
        <div className="history-card-title">{displayTitle}</div>
        <div className="history-date-container">{createdDate}</div>
        <div>
          Rescheduled By: <span>{this.getDisplayTitle(item.rescheduleBy)}</span>
        </div>
        <div>
          New Appointment: <span>{item.new_appointment}</span>
        </div>
        <div>
          Previous Appointment: <span>{item.previous_appointmnet}</span>
        </div>
      </div>
    );
  };
  renderAppointmentBookedCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);

    return (
      <div className="history-card appointment-booked">
        <div className="history-card-title">{displayTitle}</div>
        <div className="history-date-container">{createdDate}</div>
        <div>
          Appointment Schedule: <span>{item.appointment_schedule}</span>
        </div>
      </div>
    );
  };
  renderPrescriptionGeneratedCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    let getPdfRequestData = {
      doctorId: this.props.doctorId,
      prescriptionId: item.prescription_id,
      brand: this.props.brand
    };

    return (
      <div className="history-card prescription-generated">
        <div className="history-card-title">
          {displayTitle}
          <button
            className="history-card-show-button prescription-generated"
            onClick={evt =>
              this.prescriptionGeneratedShowClickHandler(
                index,
                getPdfRequestData
              )
            }
          >
            {item.showProducts ? "Hide" : "Show"}
          </button>
        </div>
        <div className="history-date-container">{createdDate}</div>
        <div>Prescription has been successfully generated.</div>
      </div>
    );
  };
  getDisplayTitle = eventType => {
    let words = eventType.split("_");
    let title = "";
    words.forEach((word, index) => {
      if (word && word.length > 0)
        title += word.charAt(0).toUpperCase() + word.slice(1) + " ";
    });
    return title;
  };
  renderCard = (item, index) => {
    switch (item.event_type) {
      case "order_placed":
        return this.renderOrderPlacedCard(item, index);
      case "subscription_order_placed":
        return this.renderOrderPlacedCard(item, index);
      case "hair_form_filled":
        return this.renderFormFilledCard(item, index);
      case "performance_form_filled":
        return this.renderFormFilledCard(item, index);
      case "form_submmited":
        return this.renderFormSubmittedCard(item, index);
      case "form_submitted":
        return this.renderFormSubmittedCard(item, index);
      case "appointment_rescheduled":
        return this.renderAppointmentRescheduleCard(item, index);
      case "appointment_booked":
        return this.renderAppointmentBookedCard(item, index);
      case "prescription_generated":
        return this.renderPrescriptionGeneratedCard(item, index);
      case "diet_plan_created":
        return this.renderPrescriptionGeneratedCard(item, index);
      default:
        return (
          <div style={{ margin: "2px", border: "1px solid black" }}>
            {this.getDisplayTitle(item.event_type)}
          </div>
        );
    }
  };
  render() {
    return (
      <div>
        <Modal
          show={this.state.showPdfModal}
          onHide={() => this.setState({ showPdfModal: false })}
          dialogClassName="history-pdf-modal"
        >
          <Iframe
            url={this.state.pdf}
            width="700px"
            height="600px"
            id="iframe"
            name="iframe"
            allowtransparency="true"
            style={{ background: "white" }}
            display="initial"
          />
        </Modal>
        {this.state.history && this.state.history.length > 0 ? (
          this.state.history.map((item, index) => {
            return this.renderCard(item, index);
          })
        ) : (
          <center>No Record Found</center>
        )}
      </div>
    );
  }
}

import styled from "styled-components";
export const TimerWrapper = styled.div`
  border-radius: 6px;
  font-family: Inter;
  display: flex;
  padding: 4px 8px 4px 8px;
  align-items: center;
  margin-bottom: 10px;
  // column-gap: 12px;
  width: fit-content;
  position: relative;
  background-color: rgba(190, 227, 248, 1);
  color: rgba(42, 67, 101, 1);
  font-size: 16px;
  line-height: 16px;
`;

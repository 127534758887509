const COLUMNS = [
  {
    title: "User Id",
    field: "userId",
    customFilterAndSearch: (term, rowData) => term == rowData.userId
  },
  {
    title: "Action",
    field: "actionName"
  },
  { title: "Action Type", field: "actionType" },
  { title: "Updated Data", field: "updatedData" },
  { title: "Timestamp", field: "createdAt" }
];

export default COLUMNS;

import "./styles.scss";

import React, { useEffect, useState } from "react";

import Loader from "react-loader-spinner";
import OrderSelectCard from "../../OrderSelect/OrderSelectCard";
import RecommendedTableCard from "./RecommendedTableCard";
import axios from "axios";
import config from "../../../../../constants/apiList";
import { store } from "../../../../../reducers/Persist";
import { useSelector } from "react-redux";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants";

const OrderTable = () => {
  const dispatch = store.dispatch;
  const [loadingState, setLoadingState] = useState(true);
  let recommendedProducts = useSelector(state => state.recommendedProducts);
  let orderedProducts = useSelector(state => state.orderedProducts.top);
  let state = useSelector(state => state.orderedProducts);
  let doctor_id = useSelector(state => state.userReducer.hash_id);
  let { form, brand } = useSelector(state => state.rxWriterReducer);
  let path = window.location.pathname;
  let appointmentId = useSelector(state => state.rxWriterReducer.id);
  let getRecommendedProduct =
    store.getState().rxWriterReducer.recommendedProducts;
  let getRestrictedProducts =
    store.getState().rxWriterReducer.restrictedProducts;
  useEffect(() => {
    let payload2 = {
      brand: brand,
      doctorId: doctor_id
    };
    let payload3 = {
      appointmentId: appointmentId,
      source: form,
      brand: brand
    };
    !(recommendedProducts.length > 0) &&
      axios
        .post(config.url.GET_PRESCRIPTION_PRODUCTS, payload3)
        .then(res => {
          let temp = [];
          let orderTemp = [];
          let orderedProductObj = res.data.data.orderedProducts;
          // WE have to extract first key-value and seperate it with the other pairs
          let i = 1;
          let firstKeyArr = [];
          let remObj = [];
          for (let key in orderedProductObj) {
            const allow_edit = orderedProductObj[key].allow_edit;
            const row = orderedProductObj[key].products.map(item => {
              return {
                ...item,
                order_id: key,
                allow_edit: allow_edit && !item?.isVirtual
              };
            });
            if (i === 1) {
              if (store?.getState()?.orderedProducts?.top?.length === 0) {
                dispatch({
                  type: "ADD_TOP_ORDER_ID",
                  payload: key
                });
                firstKeyArr = [...row];
                i++;
              }
            } else {
              let payloadObj = {};
              payloadObj[`${key}`] = row;
              // dispatch({
              //   type: "ADD_BOTTOM_PRODUCT",
              //   payload: { ...payloadObj }
              // });
              // console.log("bottom prods payloadObj", payloadObj);
              // remObj.push({ key: orderedProductObj[key] });
              remObj.push(payloadObj);
            }
          }
          // GETTING PRODUCTID'S OF ORDEREDPRODUCTS
          firstKeyArr.map(val =>
            orderTemp.push({
              product_id: val.product_id,
              order_id: val.order_id,
              quantity: val.quantity,
              allow_edit: val.allow_edit
            })
          );
          // GETTING PRODUCTID'S OF RECOMMENDEDPRODUCTS
          res.data.data.recommendedProducts.map(val => {
            let id = !!val.id ? val.id : val.product_id;
            temp.push(id);
          });
          if (getRecommendedProduct.length > 0) {
            for (const [key, value] of getRecommendedProduct.entries()) {
              let id = value;
              temp.push(id);
            }
          }
          temp = [...new Set(temp)];
          if (getRestrictedProducts.length > 0) {
            for (const [key, value] of getRestrictedProducts.entries()) {
              if (temp.includes(value)) {
                const index = temp.indexOf(value);
                temp.splice(index, 1);
              }
            }
          }
          addToGTM({
            event: GTM_EVENTS?.PRODUCTS_RECOMMENDED,
            recommendedProductsData: {
              productIds: temp || []
            }
          });
          axios
            .post(config.url.GET_BODYWISE_PRODUCTS_URL, payload2)
            .then(res2 => {
              temp.map(val => {
                if (val in res2.data.body.data) {
                  dispatch({
                    type: "ADD_RECOMMENDED_PRODUCT",
                    payload: { ...res2.data.body.data[val] },
                    edit: false
                  });
                  setLoadingState(false);
                }
              });

              orderTemp.map(val => {
                if (val.product_id in res2.data.body.data) {
                  dispatch({
                    type: "ADD_TOP_PRODUCT",
                    payload: {
                      ...res2.data.body.data[val.product_id],
                      cart_quantity: val.quantity,
                      order_id: val.order_id,
                      allow_edit: val.allow_edit
                    }
                  });
                }
              });
              remObj.map(val => {
                for (let key in val) {
                  let ArrInFrontKey = [];
                  let prodIdArr = val[key];
                  prodIdArr.map(prodIdObj => {
                    let productId = prodIdObj.product_id;
                    if (productId in res2.data.body.data) {
                      ArrInFrontKey.push({ ...res2.data.body.data[productId] });
                    }
                  });
                  if (ArrInFrontKey.length > 0) {
                    dispatch({
                      type: "ADD_BOTTOM_PRODUCT",
                      payload: { [key]: [...ArrInFrontKey] }
                    });
                  }
                }
              });
            });
        })
        .catch(e => console.log(e, "this is an error"));
  }, []);
  useEffect(() => {
    let n = recommendedProducts.length;
    if (n > 0) {
      let temp = [];
      temp = [recommendedProducts[0]];
      let count = 1;
      for (let i = 1; i < n; i++) {
        if (recommendedProducts[i].name === recommendedProducts[i - 1].name) {
          count++;
        } else {
          temp[temp.length - 1].cart_quantity = count;
          temp.push(recommendedProducts[i]);
          count = 1;
        }
      }
      if (count !== 1) temp[temp.length - 1].cart_quantity = 3;
      dispatch({
        type: "UPDATE_RECOMMENDED_PRODUCT",
        payload: temp
      });
    }
  }, [recommendedProducts.length]);
  return (
    <>
      {recommendedProducts.length > 0 ? (
        <div className="recommendedTable">
          <div className="recommendedTable_content">
            <div className="recommendedTable_content_header">
              <div className="RT_header_product">
                <p>Product</p>
              </div>
              <div className="RT_header_days">Days</div>
              <div className="RT_header_cartQty">Cart Qty.</div>
              <div className="RT_header_instructions">Instructions</div>
              <div className="RT_header_ingredients">Ingredients</div>
              <div className="RT_header_modeOfAction">Mode of Action</div>
              <div className="RT_header_expectationSetting">
                Expectation Setting
              </div>
            </div>
            {orderedProducts.length > 0
              ? recommendedProducts?.map((val, i) => (
                  <RecommendedTableCard
                    key={val.name}
                    product={val}
                    index={i}
                  />
                ))
              : orderedProducts.length === 0 &&
                recommendedProducts.map((val, i) => (
                  <OrderSelectCard
                    key={val.name + i}
                    product={val}
                    index={i}
                    parent="recommendedProducts"
                  />
                ))}
          </div>
        </div>
      ) : null}
    </>
  );
};
export default OrderTable;

import React from "react";
import { Modal } from "react-bootstrap";
import "./ReassignModal.scss";

const UnauthorizedModal = ({ show }) => {
  return (
    <Modal show={show} centered dialogClassName="reassign-modal">
      <div className="reassign-modal__unauthorized-modal">
        <div className="reassign-modal__unauthorized-modal__icon-section">
          <img
            src={
              "https://ik.manmatters.com/mosaic-wellness/image/upload/v1653907259/dochub/rx-writer/Check_Out.png"
            }
            className="reassign-modal__unauthorized-modal__icon"
          />
        </div>
        <div className="reassign-modal__unauthorized-modal__message">
          You are not authorised to edit this appointment currently
        </div>
      </div>
    </Modal>
  );
};

export default UnauthorizedModal;

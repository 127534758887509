import React from "react";
import "./RecommendationBuilder.scss";
import NewRecommendation from "./NewRecommendation";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import config from "../../../../constants/apiList";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { Popover } from "@material-ui/core";

class RecommendationBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: "BW",
      loading: false,
      anchorEl: null,
      deleteLoading: false,
      editing: false,
      allRules: [],
      ruleId: null,
      displayRules: {
        BW: [],
        MM: [],
        LJ: []
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (
      Object.keys(this.props.bwProducts).length !==
      Object.keys(prevProps.bwProducts).length
    ) {
      const brandRules = {
        BW: [],
        MM: [],
        LJ: []
      };
      for (const rule of this.state.allRules) {
        if (brandRules[rule.brand]) {
          const tempConditions = rule.conditions
            ? JSON.parse(rule.conditions)
            : [];
          const tempProductConstraints = rule.product_constraints
            ? JSON.parse(rule.product_constraints)
            : [];
          const tempAgeConstraints = rule.age_constraints
            ? JSON.parse(rule.age_constraints)
            : [];
          const productConstraints = [],
            ageConstraints = [];
          for (const constraint of tempProductConstraints) {
            productConstraints.push({
              ...constraint,
              thresholdProduct: {
                ...this.props.bwProducts[constraint.product_id],
                id: constraint.product_id
              },
              condition_type: "threshold"
            });
          }
          for (const constraint of tempAgeConstraints) {
            ageConstraints.push({
              ...constraint,
              condition_type: "age"
            });
          }
          brandRules[rule.brand].push({
            ...rule,
            products: JSON?.parse(rule.products),
            conditions: [
              ...tempConditions,
              ...ageConstraints,
              ...productConstraints
            ],
            age_constraints: [],
            product_constraints: []
          });
        }
      }
      let temp = {};
      for (const brand in brandRules) {
        temp[brand] = brandRules[brand];
        store.dispatch({
          type: "SET_RECOMMENDATION_RULES",
          payload: {
            brand,
            rules: brandRules[brand]
          }
        });
      }
      this.setState({ displayRules: temp });
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    const brandRules = {
      BW: [],
      MM: [],
      LJ: []
    };
    axios
      .get(config.url.GET_ALL_RECOMMENDATION_RULES)
      .then(res => {
        if (res.data.success) {
          this.setState({ allRules: res.data.data });
          for (const rule of res.data.data) {
            if (brandRules[rule.brand]) {
              const tempConditions = rule.conditions
                ? JSON.parse(rule.conditions)
                : [];
              const tempProductConstraints = rule.product_constraints
                ? JSON.parse(rule.product_constraints)
                : [];
              const tempAgeConstraints = rule.age_constraints
                ? JSON.parse(rule.age_constraints)
                : [];
              const productConstraints = [],
                ageConstraints = [];
              for (const constraint of tempProductConstraints) {
                productConstraints.push({
                  ...constraint,
                  thresholdProduct: {
                    ...this.props.bwProducts[constraint.product_id],
                    id: constraint.product_id
                  },
                  condition_type: "threshold"
                });
              }
              for (const constraint of tempAgeConstraints) {
                ageConstraints.push({
                  ...constraint,
                  condition_type: "age"
                });
              }
              brandRules[rule.brand].push({
                ...rule,
                products: JSON?.parse(rule.products),
                conditions: [
                  ...tempConditions,
                  ...ageConstraints,
                  ...productConstraints
                ],
                age_constraints: [],
                product_constraints: []
              });
            }
          }
          let temp = {};

          for (const brand in brandRules) {
            temp[brand] = brandRules[brand];
            store.dispatch({
              type: "SET_RECOMMENDATION_RULES",
              payload: {
                brand,
                rules: brandRules[brand]
              }
            });
          }
          this.setState({ displayRules: temp });
        } else toast.error("Failed to get recommendation rules");
      })
      .catch(err => {
        toast.error("Something went wrong");
        console.log(err);
      })
      .finally(() => this.setState({ loading: false }));
  }

  handleDeleteRule = (brand, id) => {
    this.setState({ deleteLoading: true });
    axios
      .post(config.url.DELETE_RECOMMENDATION_RULE, { id })
      .then(res => {
        if (res.data.success) {
          toast.success("Deleted rule successfully!");
          store.dispatch({
            type: "DELETE_RECOMMENDATION_RULE",
            payload: { brand, id }
          });
        }
      })
      .catch(err => {
        toast.error("Something went wrong");
        console.log(err);
      })
      .finally(() => {
        this.setState({ deleteLoading: false });
      });
  };
  handleEditClick = rule => {
    const products = [];
    for (const productId of rule.products) {
      products.push({
        ...this.props.bwProducts[productId],
        id: productId
      });
    }
    let obj = { ...rule, products, conditions: [] };
    for (const condition of rule.conditions) {
      const added_products = [],
        removed_products = [];
      for (const productId of condition.added_products) {
        added_products.push({
          ...this.props.bwProducts[productId],
          id: productId
        });
      }
      for (const productId of condition.removed_products) {
        removed_products.push({
          ...this.props.bwProducts[productId],
          id: productId
        });
      }
      obj.conditions.push({
        ...condition,
        added_products,
        removed_products
      });
    }
    store.dispatch({ type: "SET_RECOMMENDATION_BUILDER_STATE", payload: obj });
    this.setState({ editing: true });
  };

  onSearchRecommendationRules = e => {
    const { rules, presetIdToObj } = this.props;
    const { brand } = this.state;
    let searchTerm = e.target.value;
    const newData = rules[brand].filter(function (item) {
      let itemData =
        presetIdToObj[item.diagnosis]?.observation_preset_text.toUpperCase();
      const textData = searchTerm.toUpperCase();
      return itemData?.indexOf(textData) > -1;
    });
    let displayRules = { ...rules };
    displayRules[brand] = newData;
    this.setState({
      displayRules: displayRules
    });
  };

  render() {
    const popoverOpen = Boolean(this.state.anchorEl);
    const id = popoverOpen ? "simple-popover" : undefined;
    return (
      <div className="recommendation-builder-container">
        {
          //check if presets from the preset builder tab have been loaded
          this.props.presetLoading ? (
            <Loader type="TailSpin" />
          ) : (
            <div>
              <div className="header-row">
                <form className="brands">
                  <input
                    type="radio"
                    id="BW"
                    name="brand"
                    value="BW"
                    checked={this.state.brand === "BW"}
                    onClick={() => this.setState({ brand: "BW" })}
                    readOnly
                    style={{ marginRight: "5px" }}
                  />
                  <label htmlFor="BW">be Bodywise</label>
                  <input
                    type="radio"
                    id="MM"
                    name="brand"
                    value="MM"
                    checked={this.state.brand === "MM"}
                    onClick={() => this.setState({ brand: "MM" })}
                    readOnly
                    className="brand-value"
                  />
                  <label htmlFor="MM">Man Matters</label>
                  <input
                    type="radio"
                    id="LJ"
                    name="brand"
                    value="LJ"
                    checked={this.state.brand === "LJ"}
                    onClick={() => this.setState({ brand: "LJ" })}
                    readOnly
                    className="brand-value"
                  />
                  <label htmlFor="LJ">Little Joys</label>
                </form>
                <NewRecommendation
                  brand={this.state.brand}
                  editing={this.state.editing}
                  setEdit={value => this.setState({ editing: value })}
                />
              </div>
              <div className="header__searchBox">
                <input
                  type="text"
                  placeholder="Search Diagnostic"
                  onChange={e => this.onSearchRecommendationRules(e)}
                  autoFocus={true}
                />
              </div>
              {this.state.loading ? (
                <div className="loading-container">
                  <Loader type="TailSpin" />
                </div>
              ) : (
                <div className="items">
                  {this.state.displayRules[this.state.brand] &&
                    this.state.displayRules[this.state.brand].map(rule => (
                      <div className="item" key={rule.id}>
                        <div className="item-title">
                          {
                            this.props.presetIdToObj[rule.diagnosis]
                              ?.observation_preset_text
                          }
                        </div>
                        <div className="tags">
                          {rule.conditions.map((tag, index) => {
                            if (this.props.presetIdToObj[tag.condition_preset])
                              return (
                                <div className="tag" key={index}>
                                  {
                                    this.props.presetIdToObj[
                                      tag.condition_preset
                                    ]?.observation_preset_text
                                  }
                                </div>
                              );
                            else return null;
                          })}
                        </div>
                        <div className="buttons">
                          <EditOutlined
                            className="button"
                            onClick={() => this.handleEditClick(rule)}
                          />
                          <DeleteOutline
                            className="button delete-button"
                            onClick={e => {
                              this.setState({
                                anchorEl: e.currentTarget,
                                ruleId: rule.id
                              });
                            }}
                          ></DeleteOutline>
                          <Popover
                            id={id}
                            open={popoverOpen}
                            onClose={() => this.setState({ anchorEl: null })}
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                            }}
                            className="delete-popover"
                          >
                            <div style={{ margin: "10px" }}>
                              <div>
                                Are you sure you want to delete this rule?
                                <div style={{ display: "flex" }}>
                                  {this.state.deleteLoading ? (
                                    <Loader
                                      type="TailSpin"
                                      width="25"
                                      height="25"
                                    />
                                  ) : (
                                    <div>
                                      <button
                                        className="popover-button yes-button"
                                        onClick={() => {
                                          this.handleDeleteRule(
                                            this.state.brand,
                                            this.state.ruleId
                                          );
                                          this.setState({ anchorEl: null });
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        className="popover-button no-button"
                                        onClick={() =>
                                          this.setState({ anchorEl: null })
                                        }
                                      >
                                        No
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Popover>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          )
        }
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {
    rules: store.getState().recommendationRulesReducer.rules,
    presetIdToObj: store.getState().rxWriterReducer.presetIdToObj,
    bwProducts: store.getState().rxWriterReducer.bwProducts
  };
};
export default connect(mapStateToProps)(RecommendationBuilder);

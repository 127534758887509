import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import "./styles.scss";
import { store } from "../../../../../reducers/Persist";
import SearchProduct from "../../AddProduct/SearchProduct";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants";
import { toast } from "react-toastify";

const OrderSelectCard = ({
  product = {},
  index,
  parent = "orderedProduct",
  size
}) => {
  const {
    usage_frequency = [],
    usage_unit = [],
    usage_instructions = [],
    name
  } = product;

  let initialProduct = { ...product };
  const [currentFreqIndex, setCurrentFreqIndex] = useState(
    product.currentFreqIndex || 0
  );
  const [currentUnitIndex, setCurrentUnitIndex] = useState(
    product.currentUnitIndex || 0
  );
  // state
  const [editableData, setEditableData] = useState({
    days: product.days || 30,
    cart_quantity: product.cart_quantity || 1,
    usage_instructions: usage_instructions
  });
  const [editState, setEditState] = useState(product.edit);
  const disable_edit =
    product.allow_edit === undefined
      ? false
      : product.allow_edit
      ? false
      : true;

  const isLastItem = product => {
    if (size === 1) {
      const alertMessage =
        "Minimum 1 product required in order. Please add another before removing this product.";
      console.error(alertMessage, product);
      toast.error(alertMessage);
      return true;
    }
    return false;
  };

  const handleDelete = () => {
    if (parent === "recommendedProducts") {
      store.dispatch({
        type: "DELETE_RECOMMENDED_PRODUCT",
        payload: index
      });
    } else {
      if (!isLastItem(product)) {
        store.dispatch({
          type: "DELETE_TOP_PRODUCT",
          payload: index
        });
      }
    }
    addToGTM({
      event: GTM_EVENTS?.PRODUCT_REMOVED,
      removedProductData: product
    });
  };
  //  function executes when cancel button is clicked
  const handleCancel = () => {
    setEditState(false);
    if (parent === "recommendedProducts") {
      if (!!product.cancelDelete) {
        store.dispatch({
          type: "DELETE_RECOMMENDED_PRODUCT",
          payload: index
        });
        return;
      }
      setEditableData({
        days: product.days || 30,
        cart_quantity: product.cart_quantity || 1,
        usage_instructions: usage_instructions
      });
      store.dispatch({
        type: "CHANGE_RECOMMENDED_DATA",
        payload: {
          index: index,
          newData: {
            ...initialProduct,
            currentFreqIndex: currentFreqIndex,
            currentUnitIndex: currentUnitIndex
          }
        }
      });
    } else if (parent === "orderedProducts") {
      if (!!product.cancelDelete && !isLastItem(product)) {
        store.dispatch({
          type: "DELETE_TOP_PRODUCT",
          payload: index
        });
      }
      setEditableData({
        days: product.days || 30,
        cart_quantity: product.cart_quantity || 1,
        usage_instructions: initialProduct?.usage_instructions
      });
      store.dispatch({
        type: "CHANGE_TOP_DATA",
        payload: {
          index: index,
          newData: {
            ...initialProduct,
            currentUnitIndex: currentUnitIndex,
            currentFreqIndex: currentFreqIndex
          }
        }
      });
    }
  };
  // function executes when edit button is clicked
  const handleEdit = () => {
    setEditState(true);
    if (parent === "recommendedProducts") {
      store.dispatch({
        type: "EDIT_RECOMMENDED_PRODUCT",
        payload: index
      });
    } else {
      store.dispatch({
        type: "EDIT_TOP_PRODUCT",
        payload: index
      });
    }
  };
  // Function adds product in redux
  const handleAdd = () => {
    setEditState(false);
    if (parent === "recommendedProducts") {
      product.cart_quantity = editableData?.cart_quantity || 1;
      product.days = editableData?.days || 30;
      product.usage_instructions = editableData?.usage_instructions;
      store.dispatch({
        type: "CHANGE_RECOMMENDED_DATA",
        payload: {
          index: index,
          newData: {
            ...product,
            currentFreqIndex: currentFreqIndex,
            currentUnitIndex: currentUnitIndex
          }
        }
      });
      store.dispatch({
        type: "COMPLETELY__ADD_RECOMMENDED_PRODUCT",
        payload: index
      });
    } else if (parent == "orderedProducts") {
      product.cart_quantity = editableData?.cart_quantity || 1;
      product.days = editableData?.days || 30;
      product.usage_instructions = editableData?.usage_instructions;
      store.dispatch({
        type: "CHANGE_TOP_DATA",
        payload: {
          index: index,
          newData: {
            ...product,
            currentFreqIndex: currentFreqIndex,
            currentUnitIndex: currentUnitIndex
          }
        }
      });
    } else {
      store.dispatch({
        type: "COMPLETELY_ADD_PRODUCT",
        payload: index
      });
    }
  };
  return (
    <div className="orderSelectCard_container">
      {editState ? (
        <SearchProduct
          handleAddBtn={() => null}
          aboveProduct={true}
          index={index}
          parent={parent}
        />
      ) : null}
      <div className="orderSelectCard">
        <div className="orderSelectCard_product">
          <div
            className="OSC_product_img"
            style={{ width: "100%", backgroundImage: `url(${product.photos})` }}
          >
            {/* <img src={product.photos} alt={"product_image"} /> */}
          </div>
          <div className="OSC_product_desc">
            <h4 title={name}>{name}</h4>
            {!!usage_unit && usage_unit.length > 0 && usage_unit[0].length > 0 && (
              <select
                disabled={!editState}
                className={`${
                  !editState
                    ? "OSC__product_usage OSC__Remove_Arrow_Select OSC_notActive_Select OSC_notActive_unit"
                    : "OSC__product_usage"
                }`}
                onChange={e => {
                  let tempUnitIndex = usage_unit.indexOf(e.target.value);
                  setCurrentUnitIndex(tempUnitIndex);
                }}
              >
                {usage_unit?.map((val, index) => (
                  <option
                    key={index}
                    value={val}
                    selected={currentUnitIndex === index}
                  >
                    {val}
                  </option>
                ))}
              </select>
            )}
            {
              <select
                disabled={!editState}
                className={`${
                  !editState
                    ? "OSC__product_frequency OSC_notActive_frequency OSC__Remove_Arrow_Select OSC_notActive_Select"
                    : "OSC__product_frequency"
                }`}
                onChange={e => {
                  let tempFreqIndex = usage_frequency.indexOf(e.target.value);
                  setCurrentFreqIndex(tempFreqIndex);
                }}
              >
                {usage_frequency?.map((val, index) => (
                  <option
                    key={index}
                    value={val}
                    selected={index === currentFreqIndex}
                  >
                    {val}
                  </option>
                ))}
              </select>
            }
          </div>
        </div>
        <div className="orderSelectCard_days">
          <input
            title={product.days}
            type="number"
            className="OSC__input_days"
            value={editableData.days}
            disabled={!editState}
            onChange={e => {
              setEditableData({
                ...editableData,
                days: e.target.value
              });
            }}
            className={`${!editState ? "OSC_notActive" : ""}`}
          />
        </div>
        <div className="orderSelectCard_cartQty">
          <input
            title={product?.cart_quantity}
            type="number"
            className="OSC__input_cartQty"
            value={editableData.cart_quantity}
            onChange={e => {
              setEditableData({
                ...editableData,
                cart_quantity: e.target.value
              });
            }}
            disabled={!editState}
            className={`${!editState ? "OSC_notActive" : ""}`}
          />
        </div>
        <div className="orderSelectCard_instructions">
          {!!editableData.usage_instructions ? (
            <textarea
              title={editableData?.usage_instructions[currentFreqIndex] || "NA"}
              value={editableData?.usage_instructions[currentFreqIndex] || "NA"}
              disabled={!editState}
              className={`${!editState ? "OSC_notActive" : ""}`}
              style={{ overflowY: editState ? "scroll" : "hidden" }}
              onChange={e => {
                let temp = !!product?.usage_instructions
                  ? [...product?.usage_instructions]
                  : [];
                temp[currentFreqIndex] = e.target.value;
                setEditableData({
                  ...editableData,
                  usage_instructions: [...temp]
                });
              }}
            />
          ) : (
            "-"
          )}
        </div>
        <div className="orderSelectCard_ingredients">
          {product.doc_ingredients?.length > 0
            ? product.doc_ingredients?.map((val, index) => (
                <p key={index} title={val}>
                  {val}
                </p>
              ))
            : "NA"}
        </div>
        <div className="orderSelectCard_modeOfAction">
          {product.doc_mode_of_action?.length > 0
            ? product.doc_mode_of_action?.map((val, index) => (
                <p key={index} title={val}>
                  {val}
                </p>
              ))
            : "NA"}
        </div>
        <div className="orderSelectCard_expectationSetting">
          <div className="OSC_expectationSetting_left">
            {product.doc_expectation_setting?.length > 0
              ? product.doc_expectation_setting?.map((val, index) => (
                  <p key={index} title={val}>
                    {val}
                  </p>
                ))
              : "NA"}
          </div>
          {!disable_edit && !editState ? (
            <div className="OSC_expectationSetting_right">
              <EditOutlined onClick={handleEdit} className="OSC-editIcon" />
              <DeleteOutline
                onClick={handleDelete}
                className="OSC-deleteIcon"
              />
            </div>
          ) : null}
        </div>
      </div>
      {editState ? (
        <div className="orderSelectCard_container_bottom">
          <button onClick={handleCancel} className="orderSelectCard_cancelBtn">
            Cancel
          </button>
          <button
            onClick={() => handleAdd(index)}
            className="orderSelectCard_addBtn"
          >
            Done
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default OrderSelectCard;

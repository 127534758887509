import React, { Component } from "react";
import "./MealDetails2.scss";
import Select from "react-select";
import PrescriptionViewer from "../PrescriptionViewer/PrescriptionViewer";
import PrescriptionEditor from "../PrescriptionEditor/PrescriptionEditor";
import ListInput from "../../common/ListInput/ListInput";
import ListDropdownInput from "../../common/ListDropdownInput/ListDropdownInput";
import {
  Button,
  Dropdown,
  DropdownButton,
  Alert,
  Modal,
  Form,
  Table
} from "react-bootstrap";
// import { Left } from "react-bootstrap/lib/Media";
var lifestyleManagement = [
  { value: "0", label: `Select option` },
  { value: "1", label: `Avoid Smoking` },
  { value: "2", label: `Reduce smoking` },
  { value: "3", label: `Reduce the Alcohol Intake to once in 15 Days` },
  {
    value: "4",
    label: `While drinking Alcohol, consciuosly take care of the hydration. Drink 2 Glasses of water for 1 glass of your drink`
  },
  { value: "5", label: `Do 10 mins of meditation everyday` },
  { value: "6", label: `Spend time doing some activities you like` },
  { value: "7", label: `Avoid the use of Gadgets 1 hour before sleeping` },
  { value: "8", label: `Take a warm water shower before sleeping` },
  { value: "9", label: `Avoid caffeine intake before sleeping` },
  { value: "10", label: `Practice Gratitude on a daily basis` },
  { value: "11", label: `Avoid bright lights before sleep` }
];
var portions = [
  { value: "0", label: `Select portion` },
  {
    value: "1",
    label: `Fruits like Apple, guava, pear, Orange, sweetlime, kiwi - 1 nos`
  },
  { value: "2", label: `Fruits like papaya - 2 Slices` },
  { value: "3", label: `Fruits like pomogranate - 1/2 katori` },
  { value: "4", label: `Bananas/ Chickoo/Seetaphal - 1 nos` },
  { value: "5", label: `Grapes/ Cherries 10-12 nos` },
  { value: "6", label: `Litchee/ Strawberry- 6-8 nos` }
];
var medicalIssues = [
  { value: "0", label: `Select medical tip` },
  {
    value: "1",
    label: `Avoid intake of foods like Cabbage, cauliflower, Broccoli in raw form, You can eat them in cooked form once a week`
  },
  { value: "2", label: `Avoid intake of Soya and Soya products` },
  { value: "3", label: `Restrict the intake of Fruits after 7 pm.` },
  { value: "4", label: `Minimize your sugar intake` },
  { value: "5", label: `Don’t eat a lot of refined carbs` },
  { value: "6", label: `Use plenty of herbs and spices` },
  { value: "7", label: `Limit the salt intake` },
  {
    value: "8",
    label: `Avoid intake of foods like Papads, pickles, packaged and ready to cook foods and foods with added preservatives`
  },
  {
    value: "9",
    label: `Drink a glass of warm milk with nutneg powder before sleeping`
  },
  { value: "10", label: `Do kegel exercises 2-3 times a day` },
  { value: "11", label: `Avoid intake of pulses and lentils` }
];
var dietTips = [
  { value: "0", label: `Select Diet Tip` },
  { value: "1", label: "Start the Lunch and Dinner with salads" },
  { value: "2", label: "Avoid overeating" },
  { value: "3", label: "Eat meals in the mentioned portions" },
  {
    value: "4",
    label: "Try to include a variety of vegetables in the daily routine."
  },
  { value: "5", label: "Have an early dinner" },
  { value: "6", label: "Have a Light dinner" },
  { vlloalue: "7", label: "Avoid skipping the meals" },
  { value: "8", label: "Avoid keeping long gaps inbetween the meals." },
  { value: "9", label: "Use extra virgin olive oil" },
  { value: "10", label: "Avoid processed junk food (eat real food instead)" },
  {
    value: "11",
    label:
      "when on travel , you can always carry healthly fillers ( snacks ) like roasted soya nuts, channa, peanuts , plain roasted makhanas, dry khakra."
  },
  {
    value: "12",
    label: `use cow's milk / skim milk instead of buffalo's milk.`
  },
  { value: "13", label: "Use extra virgin olive oil" },
  { value: "14", label: `Have whole fruits instead of fruit juices.` }
];
var exerciseList = [
  { value: "0", label: `Select exercise` },
  { value: "1", label: `Walk for 10 mins at home only post Lunch and Dinner.` },
  {
    value: "2",
    label: `If not able to go for a walk or jog, try to do 20 mins of spot walking.`
  },
  { value: "3", label: `Try to gradually increase the exercise intensity.` },
  {
    value: "4",
    label: `Try to include basic weights while working out at home.`
  },
  {
    value: "6",
    label: `Do walking for 30 mins followed by stretching exercises for 15 mins`
  },
  {
    value: "7",
    label: `Include 10-15 mins of Floor Exercises in the routines`
  },
  { value: "8", label: `Sit in Vrajrasan after meals` },
  { value: "9", label: `Try to cover 10k step counts everyday` }
];
const exerciseSuggestionList = [
  "Walk for 10 mins at home only post Lunch and Dinner.",
  "If not able to go for a walk or jog, try to do 20 mins of spot walking.",
  "Try to gradually increase the exercise intensity.",
  "Try to include basic weights while working out at home.",
  "Do walking for 30 mins followed by stretching exercises for 15 mins",
  "Include 10-15 mins of Floor Exercises in the routines",
  "Sit in Vrajrasan after meals",
  "Try to cover 10k step counts everyday"
];
const dietSuggestionList = [
  "Start the Lunch and Dinner with salads",
  "Avoid overeating",
  "Eat meals in the mentioned portions",
  "Try to include a variety of vegetables in the daily routine.",
  "Have an early dinner",
  "Have a Light dinner",
  "Avoid skipping the meals",
  "Avoid keeping long gaps inbetween the meals.",
  "Use extra virgin olive oil",
  "Avoid processed junk food (eat real food instead)",
  "when on travel , you can always carry healthly fillers ( snacks ) like roasted soya nuts, channa, peanuts , plain roasted makhanas, dry khakra.",
  "use cow's milk / skim milk instead of buffalo's milk.",
  "Use extra virgin olive oil",
  "Have whole fruits instead of fruit juices."
];
const medicalSuggestionList = [
  "Avoid intake of foods like Cabbage, cauliflower, Broccoli in raw form, You can eat them in cooked form once a week",
  "Avoid intake of Soya and Soya products",
  "Restrict the intake of Fruits after 7 pm.",
  "Minimize your sugar intake",
  "Don’t eat a lot of refined carbs",
  "Use plenty of herbs and spices",
  "Limit the salt intake",
  "Avoid intake of foods like Papads, pickles, packaged and ready to cook foods and foods with added preservatives",
  "Drink a glass of warm milk with nutneg powder before sleeping",
  "Do kegel exercises 2-3 times a day",
  "Avoid intake of pulses and lentils"
];
const portionSuggestionList = [
  "Fruits like Apple, guava, pear, Orange, sweetlime, kiwi - 1 nos",
  "Fruits like papaya - 2 Slices",
  "Fruits like pomogranate - 1/2 katori",
  "Bananas/ Chickoo/Seetaphal - 1 nos",
  "Grapes/ Cherries 10-12 nos",
  "Litchee/ Strawberry- 6-8 nos"
];
class MealDetails2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exercise_changes: [{ value: "0", label: "Select exercise" }],
      medical_tips: [{ value: "0", label: "Select medical tip" }],
      diet_tips: [{ value: "0", label: "Select diet tips" }],
      portions: [{ value: "0", label: "Select portion" }],
      editItem: {},
      remarks: "",
      exercise_remarks: "",
      diet_remarks: "",
      medical_remarks: "",
      portion_remarks: "",
      exerciseRemarks: [],
      exerciseTips: [],
      exerciseSuggestions: this.getFilteredSuggestions(
        exerciseSuggestionList,
        this.props.patientChanges.exerciseTips
      ),
      dietRemarks: [],
      dietTips: [],
      dietSuggestions: this.getFilteredSuggestions(
        dietSuggestionList,
        this.props.patientChanges.dietTips
      ),
      medicalRemarks: [],
      medicalTips: [],
      medicalSuggestions: this.getFilteredSuggestions(
        medicalSuggestionList,
        this.props.patientChanges.medicalTips
      ),
      portionRemarks: [],
      portions: [],
      portionSuggestions: this.getFilteredSuggestions(
        portionSuggestionList,
        this.props.patientChanges.portions
      ),
      ...this.props.patientChanges
    };
    this.age = React.createRef();
    this.height = React.createRef();
    this.weight = React.createRef();
    this.bmi = React.createRef();
    this.water_intake = React.createRef();
    this.diet_type = React.createRef();
    this.allergies = React.createRef();
    this.patient_medical_history = React.createRef();
    this.parent_medical_history = React.createRef();
    this.meal = React.createRef();
    this.sleep_patterns = React.createRef();
    this.fitness_routine = React.createRef();
    this.alcohol_intake = React.createRef();
    this.smoking = React.createRef();
    this.plan_duration = React.createRef();
    this.plan_goal = React.createRef();
    this.avoid = React.createRef();
    this.onRemarkAddHandler = (remark, type) => {
      const remarks = [...this.state[`${type}Remarks`]];
      remarks.push(remark);
      this.setState({ [`${type}Remarks`]: remarks });
      this.props.updateData("page3", { [`${type}Remarks`]: remarks });
    };
    this.onRemarkRemoveHandler = (index, type) => {
      const remarks = [...this.state[`${type}Remarks`]];
      remarks.splice(index, 1);
      this.setState({ [`${type}Remarks`]: remarks });
      this.props.updateData("page3", { [`${type}Remarks`]: remarks });
    };
    this.onTipAddHandler = (tip, type) => {
      const tipKey = type === "portion" ? `${type}s` : `${type}Tips`;
      const suggestionKey = `${type}Suggestions`;
      const tips = [...this.state[tipKey]];
      tips.push(tip);
      const suggestions = [...this.state[suggestionKey]];
      suggestions.splice(suggestions.indexOf(tip), 1);
      this.setState({ [tipKey]: tips, [suggestionKey]: suggestions });
      this.props.updateData("page3", { [tipKey]: tips });
    };
    this.onTipRemoveHandler = (index, type) => {
      const tipKey = type === "portion" ? `${type}s` : `${type}Tips`;
      const suggestionKey = `${type}Suggestions`;
      const tips = [...this.state[tipKey]];
      const tip = tips[index];
      tips.splice(index, 1);
      const suggestions = [...this.state[suggestionKey]];
      suggestions.push(tip);
      this.setState({ [tipKey]: tips, [suggestionKey]: suggestions });
      this.props.updateData("page3", { [tipKey]: tips });
    };
  }
  getFilteredSuggestions = (data, filterData) => {
    if (!(data && data.length)) return [];
    return data.filter(ele => !filterData.includes(ele));
  };
  handleStatusChange = (change, index) => {
    console.log(change);
    let exercise_changes = this.state.exercise_changes;
    exercise_changes[index] = change;
    this.setState(
      { exercise_changes: exercise_changes },
      this.props.updateData("page3", this.state)
    );
  };
  renderDropdown = category => {
    let renderView = [];
    let temp = [];
    switch (category) {
      case "diet_tips":
        temp = dietTips;
        break;
      case "medical_tips":
        temp = medicalIssues;
        break;
      case "portions":
        temp = portions;
        break;
      case "exercise_changes":
        temp = exerciseList;
        break;
    }
    this.state[category].map((item, index) => {
      let view = (
        <div style={{ marginTop: "5px" }}>
          <div className="dropdownContainer">
            <div classname="dropdownChild" style={{ float: "left" }}>
              <Select
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                options={temp}
                value={this.state[category][index]}
                onChange={e => {
                  console.log(e);
                  console.log("line 108");
                  console.log(index);
                  let temp = [...this.state[category]];
                  temp[index] = e;
                  this.setState(
                    { [category]: temp },
                    this.props.updateData("page3", this.state)
                  );
                }}
                className="sib--st-dd"
              />
            </div>
            <div
              classname="dropdownChild2"
              style={{
                float: "left",
                padding: "15px 0",
                color: "red",
                fontWeight: "900",
                fontSize: "24px"
              }}
              onClick={() => this.removeTip(category, index)}
            >
              <p>-</p>
            </div>
          </div>
        </div>
      );
      renderView.push(view);
    });
    return renderView;
  };
  componentDidMount() {
    console.log("99999");
    console.log(this.props.productData);
  }

  removeTip = (category, index) => {
    console.log("remove tip");
    console.log(category);
    console.log(index);
    let temp = this.state[category];
    temp.splice(index, 1);
    this.setState({ [category]: temp });
  };

  handleAddExercise = () => {
    let temp = [...this.state.exercise_changes];
    temp.push(exerciseList[0]);
    this.setState(
      { exercise_changes: temp },
      this.props.updateData("page3", this.state)
    );
  };
  handleAddDiet = () => {
    let temp = [...this.state.diet_tips];
    temp.push(dietTips[0]);
    this.setState(
      { diet_tips: temp },
      this.props.updateData("page3", this.state)
    );
  };
  handleAddMedical = () => {
    let temp = [...this.state.medical_tips];
    temp.push(medicalIssues[0]);
    this.setState(
      { medical_tips: temp },
      this.props.updateData("page3", this.state)
    );
  };
  handleAddPortion = () => {
    let temp = [...this.state.portions];
    temp.push(portions[0]);
    this.setState(
      { portions: temp },
      this.props.updateData("page3", this.state)
    );
  };

  render() {
    return (
      <div>
        <div className="mealinfo-container">
          <div className="mealinfo">
            <h3 className="sectionHeading">Exercise Changes</h3>
            <ListDropdownInput
              collections={this.state.exerciseTips}
              suggestions={this.state.exerciseSuggestions}
              onAdd={remark => this.onTipAddHandler(remark, "exercise")}
              onRemove={index => this.onTipRemoveHandler(index, "exercise")}
              placeholder="Add Exercise"
              listTitle="Exercises"
            />
            <hr />
            <ListInput
              collections={this.state.exerciseRemarks}
              onAdd={remark => this.onRemarkAddHandler(remark, "exercise")}
              onRemove={index => this.onRemarkRemoveHandler(index, "exercise")}
              placeholder="Add Remark"
              listTitle="Remarks"
            />

            {/* {this.state.exercise_changes.length > 0
              ? this.renderDropdown("exercise_changes")
              : null} */}
            {/* <textarea
              cols={37}
              rows={2}
              placeholder=" Enter remarks"
              type="text"
              required
              value={this.state.exercise_remarks}
              name="remarks"
              onChange={event =>
                this.setState(
                  { exercise_remarks: event.target.value },
                  this.props.updateData("page3", this.state)
                )
              }
              className="sib--remark-ta"
              style={{
                marginTop: "5px",
                resize: "none",
                marginLeft: "4%",
                border: "1px solid #ddd",
                padding: "5px",
                color: "hsl(0,0%,20%)"
              }}
            />

            <div
              style={{
                cursor: "pointer",
                color: "#2F80ED",
                marginRight: "auto"
              }}
              onClick={() => this.handleAddExercise()}
            >
              {" "}
              + Add New Item{" "}
            </div> */}
          </div>
          <div className="mealinfo">
            <h3 className="sectionHeading">Diet Tips</h3>
            <ListDropdownInput
              collections={this.state.dietTips}
              suggestions={this.state.dietSuggestions}
              onAdd={remark => this.onTipAddHandler(remark, "diet")}
              onRemove={index => this.onTipRemoveHandler(index, "diet")}
              placeholder="Add Diet"
              listTitle="Diets"
            />
            <hr />
            <ListInput
              collections={this.state.dietRemarks}
              onAdd={remark => this.onRemarkAddHandler(remark, "diet")}
              onRemove={index => this.onRemarkRemoveHandler(index, "diet")}
              placeholder="Add Remark"
              listTitle="Remarks"
            />
            {/* {this.state.diet_tips.length > 0
              ? this.renderDropdown("diet_tips")
              : null}
            <textarea
              cols={37}
              rows={2}
              placeholder=" Enter remarks"
              type="text"
              required
              value={this.state.diet_remarks}
              name="remarks"
              onChange={event =>
                this.setState(
                  { diet_remarks: event.target.value },
                  this.props.updateData("page3", this.state)
                )
              }
              className="sib--remark-ta"
              style={{
                marginTop: "5px",
                resize: "none",
                marginLeft: "15px",
                border: "1px solid #ddd",
                padding: "5px",
                color: "hsl(0,0%,20%)"
              }}
            />
            <div
              style={{
                cursor: "pointer",
                color: "#2F80ED",
                marginRight: "auto"
              }}
              onClick={() => this.handleAddDiet()}
            >
              {" "}
              + Add New Item{" "}
            </div> */}
          </div>
        </div>
        <div className="mealinfo-container">
          <div className="mealinfo">
            <h3 className="sectionHeading">Medical Tips</h3>
            <ListDropdownInput
              collections={this.state.medicalTips}
              suggestions={this.state.medicalSuggestions}
              onAdd={remark => this.onTipAddHandler(remark, "medical")}
              onRemove={index => this.onTipRemoveHandler(index, "medical")}
              placeholder="Add Medical Tips"
              listTitle="Medical Tips"
            />
            <hr />
            <ListInput
              collections={this.state.medicalRemarks}
              onAdd={remark => this.onRemarkAddHandler(remark, "medical")}
              onRemove={index => this.onRemarkRemoveHandler(index, "medical")}
              placeholder="Add Remark"
              listTitle="Remarks"
            />

            {/* {this.state.medical_tips.length > 0
              ? this.renderDropdown("medical_tips")
              : null}
            <textarea
              cols={37}
              rows={2}
              placeholder=" Enter remarks"
              type="text"
              required
              value={this.state.medical_remarks}
              name="remarks"
              onChange={event =>
                this.setState(
                  { medical_remarks: event.target.value },
                  this.props.updateData("page3", this.state)
                )
              }
              className="sib--remark-ta"
              style={{
                marginTop: "5px",
                resize: "none",
                marginLeft: "4%",
                border: "1px solid #ddd",
                padding: "5px",
                color: "hsl(0,0%,20%)"
              }}
            />
            <div
              style={{
                cursor: "pointer",
                color: "#2F80ED",
                marginRight: "auto"
              }}
              onClick={() => this.handleAddMedical()}
            >
              {" "}
              + Add New Item{" "}
            </div> */}
          </div>
          <div className="mealinfo">
            <h3 className="sectionHeading">Portions</h3>
            <ListDropdownInput
              collections={this.state.portions}
              suggestions={this.state.portionSuggestions}
              onAdd={remark => this.onTipAddHandler(remark, "portion")}
              onRemove={index => this.onTipRemoveHandler(index, "portion")}
              placeholder="Add Portion"
              listTitle="Portions"
            />
            <hr />
            <ListInput
              collections={this.state.portionRemarks}
              onAdd={remark => this.onRemarkAddHandler(remark, "portion")}
              onRemove={index => this.onRemarkRemoveHandler(index, "portion")}
              placeholder="Add Remark"
              listTitle="Remarks"
            />

            {/* {this.state.portions.length > 0
              ? this.renderDropdown("portions")
              : null}
            <textarea
              cols={37}
              rows={2}
              placeholder=" Enter remarks"
              type="text"
              required
              value={this.state.portion_remarks}
              name="remarks"
              onChange={event =>
                this.setState(
                  { portion_remarks: event.target.value },
                  this.props.updateData("page3", this.state)
                )
              }
              className="sib--remark-ta"
              style={{
                marginTop: "5px",
                resize: "none",
                marginLeft: "4%",
                border: "1px solid #ddd",
                padding: "5px",
                color: "hsl(0,0%,20%)"
              }}
            />

            <div
              style={{
                cursor: "pointer",
                color: "#2F80ED",
                marginRight: "auto"
              }}
              onClick={() => this.handleAddPortion()}
            >
              {" "}
              + Add New Item{" "}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default MealDetails2;

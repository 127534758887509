import styled from "styled-components";

export const NoDataFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  width: 100%;
`;

export const TableContainer = styled.div`
  overflow-x: scroll;
  display: block;
`;

import React, { Component } from "react";
import { CardHeader } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import moment from "moment";
import config from "../../../../constants/apiList";
import axios from "axios";
import "./Timeline.scss";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import ViewPrescriptionModal from "./ViewPrescriptionModal";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";
//TODO: Move style out of this component.

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, prescriptionId: null, loading: false };
  }
  handleViewPrescriptionClick = ind => {
    const prescriptionId = this?.state?.pastAppointments?.[ind]?.prescriptionId;
    addToGTM({
      event: GTM_EVENTS?.TIMELINE_RX_VIEWED,
      timelineRxData: {
        prescriptionId
      }
    });
    this.setState({ prescriptionId }, () => {
      this.setState({ isOpen: true });
    });
  };
  componentDidMount() {
    if (!this.props.id) {
      return;
    }
    const payload = {
      appointmentId: this.props.id,
      brand: this.props.brand
    };
    this.setState({ loading: true });
    axios
      .post(config.url.GET_TIMELINE, payload)
      .then(res => {
        this.setState(
          { ...this.state, loading: false, ...res.data.data },
          () => {
            this.state.pastAppointments &&
              this.setState({
                pastAppointments: [...this.state.pastAppointments].sort(
                  function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                  }
                )
              });
          }
        );
      })
      .catch(err => console.log(err));
  }
  render() {
    return (
      <div className="margin-30 Timeline">
        <Modal
          show={this.state.isOpen}
          onHide={() => this.setState({ isOpen: false })}
          dialogClassName="view-prescription-modal"
        >
          <ViewPrescriptionModal
            prescriptionId={this.state.prescriptionId}
            closeModal={() => this.setState({ isOpen: false })}
          />
        </Modal>
        <div
          style={{
            display: "flex",
            flexDirection: "row ",
            justifyContent: "space-between"
          }}
        >
          <h2 className="TimelineTitle">Timeline</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row ",
              border: " 1px solid lightgray",
              borderRadius: "12px",
              fontSize: "smaller"
            }}
          >
            <div style={{ padding: "10px" }}>
              <div>Forms</div>

              <div style={{ fontWeight: "bold" }}>
                {this.state.forms === undefined ? "NA" : this.state.forms}
              </div>
            </div>
            <Divider orientation="vertical" flexItem variant="middle" />
            <div style={{ padding: "10px" }}>
              <div>Orders</div>
              <div style={{ fontWeight: "bold" }}>
                {this.state.orders === undefined ? "NA" : this.state.orders}
              </div>
            </div>
            <Divider orientation="vertical" flexItem variant="middle" />
            <div style={{ padding: "10px" }}>
              <div>Doctors</div>
              <div style={{ fontWeight: "bold" }}>
                {this.state.doctors === undefined ? "NA" : this.state.doctors}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "row " }}>
            <div style={{ fontWeight: "bold", marginRight: "10px" }}>
              Patient Since
            </div>
            <div>
              {this.state.patientSince
                ? moment(this.state.patientSince).format("MMMM DD, YYYY")
                : "NA"}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row " }}>
            <div style={{ fontWeight: "bold", marginRight: "10px" }}>
              Last Consultation{" "}
            </div>
            <div>
              {this.state.lastConsultation
                ? moment(this.state.lastConsultation).format("MMMM DD, YYYY")
                : "NA"}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row " }}>
            <div style={{ fontWeight: "bold", marginRight: "10px" }}>
              Appointments{" "}
            </div>
            <div>
              {this.state.appointments === undefined
                ? "NA"
                : this.state.appointments}
            </div>
          </div>
        </div>
        {this.state.pastAppointments && (
          <div
            style={{
              display: "flex",
              width: "100%",
              overflowX: "scroll",
              maxHeight: "480px",
              overflowY: "hidden",
              margin: "10px 0",
              padding: "10px 0"
            }}
          >
            {this.state.pastAppointments &&
              this.state.pastAppointments?.map((item, index) => {
                return (
                  <div className="TimelineCard" key={Math.random()}>
                    <div className="row">
                      <div className="left">Date</div>
                      <div className="right">
                        {moment(item?.date).format("MMMM DD, YYYY")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="left">Treatment Type</div>
                      <div className="right">
                        {item?.treatments?.map((treatment, ind) => {
                          if (
                            treatment?.category &&
                            this.props.presetIdToObj?.[treatment?.category]
                          ) {
                            return (
                              <span
                                key={ind}
                                style={{
                                  border: " 1px solid black",
                                  borderRadius: "12px",
                                  margin: "2px",
                                  padding: "0px 15px 0px 15px "
                                }}
                              >
                                {
                                  this.props.presetIdToObj?.[
                                    treatment?.category
                                  ]?.observation_preset_text
                                }
                              </span>
                            );
                          } else return null;
                        })}
                      </div>
                    </div>
                    <div className="row">
                      <div className="left">Rx Sent</div>
                      <div className="right">
                        {item.rxSentProducts.map((product, index) => {
                          return (
                            <div key={index}>
                              <span
                                key={index}
                                title={this.props?.bwProducts[product]?.name}
                              >
                                {this.props?.bwProducts[product]?.name}
                              </span>
                              {index > 0 && <>, </>}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="row">
                      <div className="left">Patient Order</div>
                      <div className="right">
                        {item.orderedProducts.map((product, index) => {
                          return (
                            <span
                              key={index}
                              style={{
                                margin: "4px 0",
                                display: "flex",
                                flexWrap: "wrap",
                                whiteSpace: "nowrap",
                                fontWeight: 400,
                                lineHeight: "22px"
                              }}
                              title={this.props?.bwProducts[product]?.name}
                            >
                              {this.props?.bwProducts[product]?.name}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                    <div className="row">
                      <div className="left">Images</div>
                      <div className="right">{item?.imageUploaded}</div>
                    </div>
                    <button
                      onClick={() => this.handleViewPrescriptionClick(index)}
                    >
                      View Prescription
                    </button>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    prescriptionData: store.getState().rxWriterReducer.prescriptionData,
    patientDetails: store.getState().rxWriterReducer.patientDetails,
    brand: store.getState().rxWriterReducer.brand,
    id: store.getState().rxWriterReducer.id,
    presetIdToObj: store.getState().rxWriterReducer.presetIdToObj,
    bwProducts: store.getState().orderedProducts.bwProductData
  };
};
export default connect(mapStateToProps)(Timeline);

import React from "react";
import "./ConditionSecond.scss";
import RecommendedTag from "../RecommendedTag";
export default class ConditionSecond extends React.Component {
  render() {
    return (
      <div className="second-condition-container">
        <div className="top-row">
          <div className="type">
            {this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)}
          </div>
          {this.props.type !== "age" && (
            <input
              placeholder="Enter product name here"
              className="product-name"
            />
          )}
          <select className="comparator">
            <option value="">Select</option>
            <option value="more than">More than</option>
          </select>
          <input placeholder="Number" className="num" />
          <select className="time-period">
            <option value="days"> Days </option>
            <option value="weeks"> Weeks </option>
            <option value="months"> Months </option>
            <option value="years"> Years </option>
          </select>
        </div>
        <div className="product-row">
          <input className="search-product" />
          <button className="product-button recommend"> Recommend </button>
          <button className="product-button restrict"> Restrict</button>
        </div>
        <div className="recommended-products">
          <RecommendedTag
            productName="product 1"
            handleDelete={() => {
              console.log("hi");
            }}
            type="recommended"
          />
          <RecommendedTag
            productName="product 1"
            handleDelete={() => {
              console.log("hi");
            }}
            type="restricted"
          />
        </div>
      </div>
    );
  }
}

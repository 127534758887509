import React, { useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList";
import { BsSearch } from "react-icons/bs";
import moment from "moment";
import { toast } from "react-toastify";
import "./StatusUpdate.scss";
import dataResource from "../../../constants/dataResource";

const StatusUpdater = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [appointmentID, setAppointmentID] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [brand, setBrand] = useState("");
  const [date, setDate] = useState("");
  const [language, setLanguage] = useState("");
  const [slot, setSlot] = useState("");
  const [doctorID, setDoctorID] = useState("");
  const [source, setSource] = useState("");
  const [phone, setPhone] = useState("");
  const [showData, setShowData] = useState(false);
  const [showNOtFound, setShowNotFound] = useState(false);

  const onAppointmentIDSubmit = async () => {
    const payload = {
      appointmentID: appointmentID
    };
    await axios
      .post(config.url.CALL_STATUS_URL, payload)
      .then(res => {
        if (res.data.statusCode == 200) {
          setBrand(res.data.body.brand);
          setSource(res.data.body.source);
          setDoctorID(res.data.body.doctor_id);
          setDate(res.data.body.date);
          setSlot(res.data.body.slot);
          setLanguage(res.data.body.language);
          setCallStatus(res.data.body.status);
          setPhone(res.data.body.phone);
          setShowData(true);
        } else {
          setShowNotFound(true);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onClickUpdate = async () => {
    const payload = {
      doctorId: doctorID,
      form: source,
      id: appointmentID,
      remarks: remarks,
      status: callStatus
    };
    await axios
      .post(config.url.UPDATE_STATUS_URL, payload)
      .then(res => {
        toast.success(res.data.body.result);
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div style={{ padding: "2%", display: "flex" }}>
      <div style={{ margin: "auto", width: "40%" }}>
        <h4> Status Updater</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>Enter Appointment Id</div>
          <input
            className="searchBoxInput"
            style={{ textTransform: "uppercase" }}
            type="text"
            onChange={e => {
              setSearchQuery(e.target.value);
              setAppointmentID(
                e.target.value.split("-")[2] || e.target.value.split("-")[0]
              );
            }}
            value={searchQuery}
            placeholder="MM-F-1024"
          />
          <button
            className="button"
            type="submit"
            onClick={() => onAppointmentIDSubmit()}
          >
            <BsSearch size="1.5em" />
          </button>
        </div>
        <br />
        {showNOtFound == true && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div></div>
            <h4 style={{ color: "red" }}>
              Please Enter a Valid Appointment ID
            </h4>
          </div>
        )}
        {showData === true && (
          <div>
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 180 }}
            >
              <div>ID</div>
              <span>
                {brand}-{source}-{appointmentID}
              </span>
            </div>
            <br />
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 160 }}
            >
              <div>Date</div>
              <span>{moment(date).format("MMMM DD, YYYY")}</span>
            </div>
            <br />
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 170 }}
            >
              <div>Slot</div>
              <span>{slot}</span>
            </div>
            <br />
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 120 }}
            >
              <div>Language</div>
              <span>{language}</span>
            </div>
            <br />
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 80 }}
            >
              <div>Phone Number</div>
              <span>{phone}</span>
            </div>
            <br />
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 120 }}
            >
              <div>Call Status</div>
              <select
                onChange={e => {
                  setCallStatus(e.target.value);
                }}
                className={"select"}
              >
                <option value="" selected hidden>
                  {" "}
                  Select{" "}
                </option>
                {dataResource.statusList.map(status => {
                  return (
                    <option
                      value={status.value}
                      key={status.value}
                      selected={callStatus === status.value}
                    >
                      {status.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <br />
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 140 }}
            >
              <div>Remarks</div>
              <textarea
                name="remark"
                placeholder="Click to start Typing"
                value={remarks}
                onChange={e => setRemarks(e.target.value)}
                className={"textarea"}
              />
            </div>
            <br />
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 40 }}
            >
              <div></div>
              <button
                className="Updatebutton"
                type="submit"
                onClick={onClickUpdate}
              >
                Update
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusUpdater;

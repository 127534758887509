import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import noop from "lodash/noop";

export default function CheckInOutModal({
  titleText = "You have been",
  onCheckInClick = noop,
  isLoading = false
}) {
  return (
    <>
      <div>
        <div>
          <Modal
            show={true}
            keyboard={false}
            dialogClassName="modal-center"
            centered
            style={{ overflow: "hidden" }}
          >
            <div className={styles["checkout-notification-wrapper"]}>
              <div className={styles["header-section"]}></div>
              <div className={styles["icon-section"]}>
                <img
                  className={styles["icon"]}
                  src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1653907259/dochub/rx-writer/Check_Out.png"
                />
              </div>
              <div className={styles["message-section"]}>
                <div className={styles["message"]}>{titleText}</div>
                <div className={styles["message-highlight"]}>Checked-Out</div>
              </div>
              <div className={styles["button-section"]}>
                <button
                  className={styles["view-button"]}
                  onClick={onCheckInClick}
                  disabled={isLoading}
                >
                  Check-In
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

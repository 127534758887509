const STATUS_COLOR = {
  1: "#B9E28C",
  2: "#E75A7C",
  3: "#BBD5ED",
  4: "#86615C",
  5: "#F75C03",
  6: "#F1C40F",
  7: "#38023B"
};
export const getStatusColor = status => {
  return STATUS_COLOR[status];
};

import React, { Component } from "react";
import Combobox from "react-widgets/lib/Combobox";

import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import "./PrescriptionEditor.scss";
import { alertBox } from "../../common/PopupBox/PopupBox";

class PrescriptionEditor extends Component {
  // initial seperated to reset state on clear
  initialState = {
    type: "",
    kitListProducts: [],
    currentProduct: {},
    unitListSP: [],
    frequencyListSP: [],
    notesSP: [],
    unitListKP: [],
    frequencyListKP: [],
    notesListKP: [],
    cusUnitSgl: "",
    cusFrequencySgl: "",
    cusNotesSgl: "",
    cusDosageSgl: "",
    kUnit: [],
    kFrequency: [],
    kNotes: [],
    cusPrescriptionQtySgl: "1",
    cusCartQtySgl: "1",
    cusDaysSgl: "",
    pdName: ""
  };

  state = { updatedProductsList: [], ...this.initialState };

  constructor() {
    super();
    this.productList = ({ item }) => item.prod_name;
    this.cusName = React.createRef();
    this.cusUnit = React.createRef();
    this.cusFrequency = React.createRef();
    this.cusNotes = React.createRef();
    this.cusPrescriptionQty = React.createRef();
    this.cusCartQty = React.createRef();
    this.cusDays = React.createRef();
    this.cusDosage = React.createRef();
    this.kPrescriptionQty = [];
    this.kCartQty = [];
    this.kDays = [];
    this.unitK = [];
    this.kDosage = [];
  }

  componentDidMount() {
    //console.log(this.props.productData);
    //console.log(this.props.editItem);
    let addItemSelection = {
      prod_name: "NEW",
      prod_id: "new"
    };
    console.log("cdm prescription editor");
    let updatedProductList = [addItemSelection, ...this.props.productData];
    let out_of_stock = [
      2235645, 2185823, 1185963, 2265331, 1377956, 1246476, 1082786, 2171963,
      607779
    ];
    updatedProductList = updatedProductList.filter(
      data =>
        out_of_stock.indexOf(data.prod_id) == -1 && data.prod_type !== "payg"
    ); // PRODUCTS WITH ID 2235645 and 2185823 HIDDEN DUE TO OUT OF STOCK.
    console.log(updatedProductList);
    this.setState({ updatedProductsList: updatedProductList });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(
      "^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
    );
    console.log(this.props);
    console.log("cdu");
    console.log(prevProps);

    if (
      this.props.editItem.product &&
      prevProps.editItem !== this.props.editItem
    ) {
      console.log("assign value for edit called");
      this.assignValueForEdit();
    }
  }

  assignValueForEdit() {
    console.log(
      "******************************************************************"
    );
    this.setState({
      type: "single",
      pdName: this.props.editItem.product,
      currentProduct: this.props.editItem.product,
      cusUnitSgl: this.props.editItem.unit,
      cusFrequencySgl: this.props.editItem.frequency,
      cusNotesSgl: this.props.editItem.notes,
      cusDaysSgl: this.props.editItem.days,
      cusPrescriptionQtySgl: this.props.editItem.prescription_quantity,
      cusCartQtySgl: this.props.editItem.cart_quantity,
      cusDosageSgl: this.props.editItem.dosage,
      frequencyListSP: this.props.editItem.product.usage_frequency || [],
      unitListSP: this.props.editItem.product.usage_unit || [],
      notesSP: this.props.editItem.product.usage_instructions || []
    });
  }

  assignProductById(id) {
    return this.props.productData.filter(elem => elem.prod_id === id);
  }

  ddUpdated(change) {
    console.log("dd_updated");
    let selectedProduct = change.value;
    if (selectedProduct.prod_id === "new" && !selectedProduct.usage_frequency) {
      this.setState({ type: "new" });
    } else if (selectedProduct.prod_type === "kit") {
      this.updateKitType(selectedProduct);
    } else {
      console.log("this bitch.....");
      this.setState({
        type: "single",
        currentProduct: selectedProduct,
        unitListSP: selectedProduct.usage_unit || [],
        frequencyListSP: selectedProduct.usage_frequency || [],
        notesSP: selectedProduct.usage_instructions || [],
        cusCartQtySgl: selectedProduct.cart_quantity || 1,
        cusPrescriptionQtySgl: selectedProduct.prescription_quantity || 1,
        cusUnitSgl: selectedProduct.usage_unit
          ? selectedProduct.usage_unit[0]
          : "",
        cusFrequencySgl: selectedProduct.usage_frequency
          ? selectedProduct.usage_frequency[0]
          : "",
        cusNotesSgl: selectedProduct.usage_instructions
          ? selectedProduct.usage_instructions[0]
          : ""
      });
    }
  }

  ddChanged(change) {
    console.log("dd_changed");
    if (!change.value.prod_id) {
      this.setState({ pdName: change.value, type: "" });
    } else {
      this.setState({ pdName: change.value });
    }
  }

  updateKitType(selectedProduct) {
    this.setState({ type: "kit" });
    let listObj = [];
    let unitKitList = [];
    let frequencyKitList = [];
    let notesKitList = [];
    let f = [...this.state.kFrequency];
    let n = [...this.state.kNotes];
    let u = [...this.state.kUnit];
    for (let i = 0; i < selectedProduct.kit_contents_ids.length; i++) {
      let objOnId = this.assignProductById(
        selectedProduct.kit_contents_ids[i]
      )[0];
      listObj.push(objOnId);
      this.kDays[i] = React.createRef();
      this.unitK[i] = React.createRef();
      this.kPrescriptionQty[i] = React.createRef();
      this.kCartQty[i] = React.createRef();
      this.kDosage[i] = React.createRef();
      unitKitList[i] = objOnId.usage_unit || [];
      frequencyKitList[i] = objOnId.usage_frequency || [];
      notesKitList[i] = objOnId.usage_instructions || [];
      f[i] = frequencyKitList[i][0] || "";
      n[i] = notesKitList[i][0] || "";
      u[i] = unitKitList[i][0] || "";
    }
    this.setState({
      kitListProducts: listObj,
      unitListKP: unitKitList,
      frequencyListKP: frequencyKitList,
      notesListKP: notesKitList,
      kFrequency: f,
      kNotes: n,
      kUnit: u
    });
  }

  clearFields() {
    this.setState(this.initialState);
    this.kPrescriptionQty = [];
    this.kCartQty = [];
    this.kDays = [];
    this.kDosage = [];
    this.kUnit = [];
    this.unitK = [];
    this.kFrequency = [];
    this.kNotes = [];
    this.kUnit = [];
  }

  handleSubmit() {
    if (this.state.type === "kit") {
      this.getKitData();
    } else if (this.state.type === "single") {
      this.getSingleProductData();
    } else {
      this.getNewProductData();
    }
    this.clearFields();
  }

  getKitData() {
    let currentProductDetails = [];
    for (let i = 0; i < this.state.kitListProducts.length; i++) {
      let tempObj = {};
      tempObj["product"] = this.state.kitListProducts[i];
      tempObj["unit"] = this.state.kUnit[i];
      // tempObj["unit"] = this.state.unitK[i];
      tempObj["frequency"] = this.state.kFrequency[i];
      tempObj["days"] = this.kDays[i].current.value;
      tempObj["prescription_quantity"] = this.kPrescriptionQty[i].current.value;
      tempObj["cart_quantity"] = this.kCartQty[i].current.value;
      // tempObj["dosage"] = this.kDosage[i].current.value
      // ? this.kDosage[i].current.value
      // : "";
      tempObj["notes"] = this.state.kNotes[i];
      currentProductDetails.push(tempObj);
    }
    if (this.props.keyVal) {
      this.props.getDetails(currentProductDetails, this.props.keyVal);
    } else {
      this.props.getDetails(currentProductDetails);
    }
  }

  getSingleProductData() {
    let currentProductDetails = [];
    let tempObj = {};
    tempObj["product"] = this.state.currentProduct;
    tempObj["unit"] = this.state.cusUnitSgl;
    tempObj["frequency"] = this.state.cusFrequencySgl;
    tempObj["days"] = this.state.cusDaysSgl;
    tempObj["prescription_quantity"] = this.state.cusPrescriptionQtySgl;
    tempObj["cart_quantity"] = this.state.cusCartQtySgl;
    tempObj["notes"] = this.state.cusNotesSgl;
    tempObj["dosage"] = this.state.cusDosageSgl ? this.state.cusDosageSgl : "";
    currentProductDetails.push(tempObj);
    if (this.props.keyVal) {
      this.props.getDetails(currentProductDetails, this.props.keyVal);
    } else {
      this.props.getDetails(currentProductDetails);
    }
    //this.props.getDetails(currentProductDetails);
  }

  getNewProductData() {
    let currentProductDetails = [];
    let tempObj = {};
    if (!this.cusName.current.value) {
      alertBox(
        "Please Select 'NEW' to add a product or select a product from the list",
        () => {}
      );
    } else {
      tempObj["product"] = {
        prod_name: this.cusName.current.value,
        prod_id: "new"
      };
      tempObj["unit"] = this.cusUnit.current.value;
      tempObj["days"] = this.cusDays.current.value;
      tempObj["frequency"] = this.cusFrequency.current.value;
      tempObj["cart_quantity"] = this.state.cusCartQtySgl;
      tempObj["prescription_quantity"] = this.state.cusPrescriptionQtySgl;
      tempObj["notes"] = this.cusNotes.current.value;
      // tempObj["dosage"] = this.cusDosage.current
      //   ? this.cusDosage.current.value
      //   : "";
      currentProductDetails.push(tempObj);
      if (this.props.keyVal) {
        this.props.getDetails(currentProductDetails, this.props.keyVal);
      } else {
        this.props.getDetails(currentProductDetails);
      }
      //this.props.getDetails(currentProductDetails);
    }
  }

  selectedFrequency(index, kitIndex) {
    if (kitIndex === "single") {
      this.setState({
        cusFrequencySgl: this.state.frequencyListSP[index],
        cusNotesSgl: this.state.notesSP[index]
      });
    } else {
      let f = [...this.state.kFrequency];
      let n = [...this.state.kNotes];
      let u = [...this.state.kUnit];
      f[kitIndex] = this.state.frequencyListKP[kitIndex][index];
      n[kitIndex] = this.state.notesListKP[kitIndex][index];
      this.setState({
        kFrequency: f,
        kNotes: n,
        kUnit: u
      });
    }
  }

  selectedUnit(index, kitIndex) {
    if (kitIndex === "single") {
      this.setState({
        cusUnitSgl: this.state.unitListSP[index]
      });
    } else {
      let u = [...this.state.kUnit];
      u[kitIndex] = this.state.unitListKP[kitIndex][index];
      this.setState({
        kUnit: u
      });
    }
  }

  indexChange(unit, index, value, key) {
    let itemArray = [...unit];
    itemArray[index] = value;
    let obj = {};
    obj[key] = itemArray;
    this.setState(obj);
  }

  render() {
    return (
      <>
        <h3 className="sectionHeading">Prescription Editor</h3>
        <div>
          <div className="sec-box" data-test="editor-container">
            <div className="formLabel">Select/Add Product</div>
            <div className="item">
              <Combobox
                data={this.state.updatedProductsList}
                itemComponent={this.productList}
                onSelect={value => this.ddUpdated({ value })}
                onChange={value => this.ddChanged({ value })}
                filter="contains"
                textField="prod_name"
                value={this.state.pdName || ""}
              />
            </div>
          </div>
          {/* New Prescription Flow */}
          {this.state.type === "new" && (
            <>
              <div className="sec-box">
                <div className="title">Name</div>
                <div className="item">
                  <input
                    className="nameInput"
                    id="nameInput"
                    type="text"
                    ref={this.cusName}
                    placeholder="Enter name"
                  />
                </div>
              </div>

              <div className="sec-box">
                <div className="title">Unit</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.cusUnit}
                    placeholder="Enter Unit"
                    value={this.state.cusUnitSgl}
                    onChange={event =>
                      this.setState({ cusUnitSgl: event.target.value })
                    }
                  />
                </div>
              </div>

              <div className="sec-box">
                <div className="title">Frequency</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.cusFrequency}
                    placeholder="Enter Frequency"
                    value={this.state.cusFrequencySgl}
                    onChange={event =>
                      this.setState({ cusFrequencySgl: event.target.value })
                    }
                  />
                </div>
              </div>

              <div className="sec-box">
                <div className="title">Days</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.cusDays}
                    placeholder="Enter Days"
                    value={this.state.cusDaysSgl}
                    onChange={event =>
                      this.setState({ cusDaysSgl: event.target.value })
                    }
                  />
                </div>
              </div>

              {/* <div className="sec-box">
                <div className="title">Dosage</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.cusDosage}
                    placeholder="Enter Dosage"
                  />
                </div>
              </div> */}

              <div className="sec-box">
                <div className="title">Cart Quantity</div>
                <div className="item">
                  <input
                    type="text"
                    value={this.state.cusCartQtySgl}
                    placeholder="Cart Quantity"
                    defaultValue="1"
                    onChange={event =>
                      this.setState({ cusCartQtySgl: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="title">Prescription Quantity</div>
                <div className="item">
                  <input
                    type="text"
                    value={this.state.cusPrescriptionQtySgl}
                    placeholder="Prescription Quantity"
                    defaultValue="1"
                    onChange={event =>
                      this.setState({
                        cusPrescriptionQtySgl: event.target.value
                      })
                    }
                  />
                </div>
              </div>

              <div className="sec-box">
                <div className="title">Instructions</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.cusNotes}
                    placeholder="Enter Instructions"
                    value={this.state.cusNotesSgl}
                    onChange={event =>
                      this.setState({ cusNotesSgl: event.target.value })
                    }
                  />
                </div>
              </div>
            </>
          )}
          {/* Kit Prescription Flow */}
          {this.state.type === "kit" && (
            <>
              {this.state.kitListProducts &&
                this.state.kitListProducts.map((element, index) => (
                  <div className="kit-item-box" key={index}>
                    <div className="sec-box">
                      <div className="title">Name</div>
                      <strong className="item">{element.prod_name}</strong>
                    </div>
                    <div className="sec-box">
                      <div className="title">Unit</div>
                      <div className="item">
                        <input
                          type="text"
                          value={this.state.kUnit[index]}
                          placeholder="Enter Unit"
                          onChange={event =>
                            this.indexChange(
                              this.state.kUnit,
                              index,
                              event.target.value,
                              "kUnit"
                            )
                          }
                        />
                        {this.state.unitListKP &&
                          this.state.unitListKP[index].length > 0 && (
                            <div className="dd">
                              <DropdownButton
                                variant="Secondary"
                                id="dropdown-item-button"
                                title=""
                              >
                                {this.state.unitListKP[index].map((elem, i) => (
                                  <Dropdown.Item
                                    key={i}
                                    as="button"
                                    onClick={() => this.selectedUnit(i, index)}
                                  >
                                    {elem}
                                  </Dropdown.Item>
                                ))}
                              </DropdownButton>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="sec-box">
                      <div className="title">Frequency</div>
                      <div className="item">
                        <input
                          type="text"
                          value={this.state.kFrequency[index]}
                          placeholder="Enter Frequency"
                          onChange={event =>
                            this.indexChange(
                              this.state.kFrequency,
                              index,
                              event.target.value,
                              "kFrequency"
                            )
                          }
                        />
                        {this.state.frequencyListKP &&
                          this.state.frequencyListKP[index].length > 0 && (
                            <div className="dd">
                              <DropdownButton
                                variant="Secondary"
                                id="dropdown-item-button"
                                title=""
                              >
                                {this.state.frequencyListKP[index].map(
                                  (elem, i) => (
                                    <Dropdown.Item
                                      as="button"
                                      key={i}
                                      onClick={() =>
                                        this.selectedFrequency(i, index)
                                      }
                                    >
                                      {elem}
                                    </Dropdown.Item>
                                  )
                                )}
                              </DropdownButton>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="sec-box">
                      <div className="title">Days</div>
                      <div className="item">
                        <input
                          type="text"
                          ref={this.kDays[index]}
                          placeholder="Enter Days"
                        />
                      </div>
                    </div>

                    {/* <div className="sec-box">
                      <div className="title">Dosage</div>
                      <div className="item">
                        <input
                          type="text"
                          ref={this.kDosage[index]}
                          placeholder="Enter Dosage"
                        />
                      </div>
                    </div> */}

                    <div className="sec-box">
                      <div className="title">Prescription Quantity</div>
                      <div className="item">
                        <input
                          type="text"
                          ref={this.kPrescriptionQty[index]}
                          placeholder="Prescription Quantity"
                          defaultValue="1"
                        />
                      </div>
                    </div>
                    <div className="sec-box">
                      <div className="title">Cart Quantity</div>
                      <div className="item">
                        <input
                          type="text"
                          ref={this.kCartQty[index]}
                          placeholder="Cart Quantity"
                          defaultValue="1"
                        />
                      </div>
                    </div>

                    <div className="sec-box">
                      <div className="title">Instructions</div>
                      <div className="item">
                        <input
                          type="text"
                          value={this.state.kNotes[index]}
                          placeholder="Enter Instructions"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
          {/* Single Product prescription flow */}
          {this.state.type === "single" && (
            <>
              <div className="sec-box">
                <div className="title">Unit</div>
                <div className="item">
                  <input
                    type="text"
                    placeholder="Enter Unit"
                    value={this.state.cusUnitSgl}
                    onChange={event =>
                      this.setState({ cusUnitSgl: event.target.value })
                    }
                  />
                  {this.state.unitListSP && this.state.unitListSP.length > 0 && (
                    <div className="dd">
                      <DropdownButton
                        variant="Secondary"
                        id="dropdown-item-button"
                        title=""
                      >
                        {this.state.unitListSP.map((elem, index) => (
                          <Dropdown.Item
                            as="button"
                            key={index}
                            onClick={() => this.selectedUnit(index, "single")}
                          >
                            {elem}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>
                  )}
                </div>
              </div>
              <div className="sec-box">
                <div className="title">Frequency</div>
                <div className="item">
                  <input
                    type="text"
                    placeholder="Enter Frequency"
                    value={this.state.cusFrequencySgl}
                    onChange={event =>
                      this.setState({ cusFrequencySgl: event.target.value })
                    }
                    style={{ height: "50px" }}
                  />
                  {this.state.frequencyListSP &&
                    this.state.frequencyListSP.length > 0 && (
                      <div className="dd">
                        <DropdownButton
                          variant="Secondary"
                          id="dropdown-item-button"
                          title=""
                        >
                          {this.state.frequencyListSP.map((elem, index) => (
                            <Dropdown.Item
                              as="button"
                              key={index}
                              onClick={() =>
                                this.selectedFrequency(index, "single")
                              }
                            >
                              {elem}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                    )}
                </div>
              </div>

              <div className="sec-box">
                <div className="title">Days</div>
                <div className="item">
                  <input
                    type="text"
                    value={this.state.cusDaysSgl}
                    placeholder="Enter Days"
                    onChange={event =>
                      this.setState({ cusDaysSgl: event.target.value })
                    }
                  />
                </div>
              </div>

              {/* <div className="sec-box">
                <div className="title">Dosage</div>
                <div className="item">
                  <input
                    type="text"
                    value={this.state.cusDosageSgl}
                    placeholder="Enter Dosage"
                    onChange={(event) =>
                      this.setState({ cusDosageSgl: event.target.value })
                    }
                  />
                </div>
              </div> */}

              <div className="sec-box">
                <div className="title">Prescription Quantity</div>
                <div className="item">
                  <input
                    type="text"
                    value={this.state.cusPrescriptionQtySgl}
                    placeholder="Prescription Quantity"
                    defaultValue="1"
                    onChange={event =>
                      this.setState({
                        cusPrescriptionQtySgl: event.target.value
                      })
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="title">Cart Quantity</div>
                <div className="item">
                  <input
                    type="text"
                    value={this.state.cusCartQtySgl}
                    placeholder="Cart Quantity"
                    defaultValue="1"
                    onChange={event =>
                      this.setState({ cusCartQtySgl: event.target.value })
                    }
                  />
                </div>
              </div>

              <div className="sec-box">
                <div className="title">Instructions</div>
                <div className="item">
                  <input
                    type="text"
                    value={this.state.cusNotesSgl}
                    placeholder="Enter Instructions"
                    onChange={event =>
                      this.setState({ cusNotesSgl: event.target.value })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="sec-box">
          {this.state.type && (
            <Button
              className="btn"
              onClick={() => this.handleSubmit()}
              style={{ width: "100%", margin: "10px 0" }}
            >
              Add
            </Button>
          )}
        </div>
      </>
    );
  }
}

export default PrescriptionEditor;

const Utility = {
  getIST: str => {
    let d = new Date(str + " UTC");
    let hours = d.getHours();
    let min = String(d.getMinutes()).length > 1 ? d.getMinutes() : "0" + d.getMinutes();
    let day = d.getDate();
    let month = d.toLocaleString("en-US", { month: "long" });
    let year = d.getFullYear();
    let ans = hours + ":" + min + " " + day + "-" + month + "-" + year;
    return ans;
  },

  dateSortFn: (a, b) => {
    return new Date(b.dateTime) - new Date(a.dateTime);
  },

  formatDateforDisplay: date => {
    return date.toDateString() + ", " + date.toLocaleTimeString();
  },

  formatISTDateforDisplay: str => {
    let date = new Date(str + " UTC");
    return date.toDateString() + ", " + date.toLocaleTimeString();
  },
  exportCSVFile: (items, fileTitle, headers) => {
    if (headers) {
      items.unshift(headers);
    }
    let jsonObject = JSON.stringify(items);

    let csv = convertToCSV(jsonObject);

    let exportedFilenmae = fileTitle + ".csv" || "export.csv";

    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
};

const convertToCSV = objArray => {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
};

export default Utility;

import React from "react";
import { Modal } from "react-bootstrap";
import "./NewRecommendation.scss";
import ConditionFirst from "./ConditionFirst";
import ConditionSecond from "./ConditionSecond";
import MainRecommendation from "./MainRecommendation";
import axios from "axios";
import config from "../../../../../constants/apiList";
import { store } from "../../../../../reducers/Persist";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { Popover } from "@material-ui/core";
import { toast } from "react-toastify";

const firstCondition = [
  "allergy_food_specification",
  "allergy_animal_specification",
  "allergy_drug_specification",
  "allergy_environmental_specification",
  "allergy_other_specification",
  "concern",
  "past_treatments",
  "medical_history",
  "family_history"
];
const secondCondition = ["age", "threshold"];
const conditionTitleMap = {
  allergy_food_specification: "Food Allergies",
  allergy_animal_specification: "Animal Allergies",
  allergy_drug_specification: "Drug Allergies",
  allergy_environmental_specification: "Environmental Allergies",
  allergy_other_specification: "Other Allergies",
  concern: "Concerns",
  past_treatments: "Past Treatments",
  medical_history: "Medical History",
  family_history: "Family History",
  age: "Age",
  threshold: "Threshold"
};
class NewRecommendation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isAdding: false,
      selectedVal: "",
      conditions: [],
      loading: false,
      productsLoading: false,
      diagnosisPreset: [],
      get_products: [],
      presetData: {},
      anchorEl: null,
      submitLoading: false
    };
    this.deleteCondition = this.deleteCondition.bind(this);
  }
  deleteCondition(index) {
    store.dispatch({ type: "DELETE_RECOMMENDATION_CONDITION", payload: index });
  }
  componentDidMount() {
    this.getPresets();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brand != this.props.brand) {
      this.getPresets();
    }
  }
  getPresets = () => {
    this.setState({ loading: true });
    const brand = this.props?.brand;
    const url = `${config.url.GET_PRESET_URL}?brand=${brand}`;
    axios.get(url).then(res => {
      let tempArr = [];
      tempArr = Object.values(res.data.data).filter(
        val => val.observation_type === "diagnosis"
      );
      store.dispatch({
        type: "CHANGE_PRESET_DATA",
        data: { ...res.data.data }
      });
      this.setState({
        ...this.state,
        diagnosisPreset: [...tempArr],
        presetData: { ...res.data.data }
      });
    });
    this.setState({ productsLoading: true });
    const payload = {
      brand: this.props.brand || "BW",
      doctorId: this.props.doctorId
    };
    axios
      .post(config.url.GET_BODYWISE_PRODUCTS_URL, payload)
      .then(res => {
        let arr = [];
        for (const id in res.data.body.data) {
          arr.push({
            ...res.data.body.data[id],
            id
          });
        }
        store.dispatch({
          type: "CHANGE_BODYWISE_DATA",
          data: res.data.body.data
        });
        this.setState({ get_products: arr });
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ productsLoading: false });
      });
  };
  handleSubmit = () => {
    this.setState({ submitLoading: true });
    const data = store.getState().recommendationBuilderReducer;
    const conditions = [],
      product_constraints = [],
      age_constraints = [];
    const durationMap = {
      days: 1,
      weeks: 7,
      months: 30,
      years: 365
    };
    data.conditions.forEach(val => {
      if (val.condition_type === "age") {
        age_constraints.push({
          condition_type: val.condition_type,
          condition: val.comparator,
          added_products: val.added_products.map(product => product.id),
          removed_products: val.removed_products.map(product => product.id),
          age: Number(val.durationValue) * durationMap[val.durationPeriod],
          durationValue: val.durationValue,
          comparator: val.comparator,
          durationPeriod: val.durationPeriod
        });
      } else if (val.condition_type === "threshold") {
        product_constraints.push({
          condition_type: val.condition_type,
          product_id: val.thresholdProduct.id,
          thresholdProduct: val.thresholdProduct,
          duration: Number(val.durationValue) * durationMap[val.durationPeriod],
          condition: val.comparator,
          comparator: val.comparator,
          improvement: val.improvement === "true" ? true : false,
          added_products: val.added_products.map(product => product.id),
          removed_products: val.removed_products.map(product => product.id),
          durationValue: val.durationValue,
          durationPeriod: val.durationPeriod
        });
      } else {
        conditions.push({
          condition_type: val.condition_type,
          condition_preset: val.condition_preset,
          added_products: val.added_products.map(product => product.id),
          removed_products: val.removed_products.map(product => product.id)
        });
      }
    });
    const payload = {
      ...data,
      brand: this.props.brand,
      products: data.products?.map(val => {
        return val.id;
      }),
      conditions: conditions,
      age_constraints: age_constraints,
      product_constraints: product_constraints
    };
    const url = this.props.editing
      ? config.url.EDIT_RECOMMENDATION_RULE
      : config.url.CREATE_RECOMMENDATION_RULE;
    axios
      .post(url, payload)
      .then(res => {
        if (res.data.success) {
          store.dispatch({
            type: "CLEAR_RECOMMENDATION_BUILDER"
          });
          if (this.props.editing) {
            store.dispatch({
              type: "UPDATE_RECOMMENDATION_RULE",
              payload: {
                rule: {
                  ...payload,
                  conditions: [
                    ...conditions,
                    ...age_constraints,
                    ...product_constraints
                  ]
                },
                id: payload.id,
                brand: this.props.brand
              }
            });
            this.setState({ submitLoading: false });
            this.props.setEdit(false);
            toast.success("Recommendation Rule Updated Successfully");
          } else {
            store.dispatch({
              type: "ADD_RECOMMENDATION_RULE",
              payload: {
                brand: this.props.brand,
                rule: {
                  ...payload,
                  conditions: [
                    ...conditions,
                    ...age_constraints,
                    ...product_constraints
                  ],
                  id: res.data.data.id
                }
              }
            });
            toast.success("Added recommendation rule successfully");
          }
          this.setState({ isOpen: false });
        } else toast.error("Failed to add recommendation rule");
      })
      .catch(err => {
        toast.error("Something went wrong!");
        console.log(err);
      })
      .finally(() => {
        this.setState({ submitLoading: false });
      });
  };
  handleCancelEdit = () => {
    this.props.setEdit(false);
    store.dispatch({ type: "CLEAR_RECOMMENDATION_BUILDER" });
  };
  render() {
    const popoverOpen = Boolean(this.state.anchorEl);
    const id = popoverOpen ? "simple-popover" : undefined;
    return (
      <div>
        <Modal
          show={this.state.isOpen || this.props.editing}
          dialogClassName="new-recommendation-modal"
          centered
        >
          <MainRecommendation
            presetData={this.state.diagnosisPreset}
            get_products={this.state.get_products}
          />
          {this.props.conditions.map((condition, index) => {
            return (
              <ConditionFirst
                key={index}
                index={index}
                type={conditionTitleMap[condition.condition_type]}
                deleteCondition={() => this.deleteCondition(index)}
                observation_type={condition.condition_type}
                presetData={this.state.presetData}
                get_products={this.state.get_products}
                condition_type={condition.condition_type}
              />
            );
          })}

          {this.state.isAdding ? (
            <div className="is-adding">
              <select
                className="new-concern"
                onChange={e =>
                  this.setState({
                    ...this.state,
                    selectedVal: e.target.value
                  })
                }
              >
                <option value="" selected hidden disabled>
                  Select Condition type
                </option>
                {firstCondition.map((condition, index) => {
                  return (
                    <option value={condition} key={index}>
                      {" "}
                      {conditionTitleMap[condition]}{" "}
                    </option>
                  );
                })}
                {secondCondition.map((condition, index) => {
                  return (
                    <option value={condition} key={index}>
                      {" "}
                      {conditionTitleMap[condition]}{" "}
                    </option>
                  );
                })}
              </select>
              <button
                className="done-button"
                disabled={!this.state.selectedVal}
                onClick={() => {
                  store.dispatch({
                    type: "ADD_RECOMMENDATION_CONDITION",
                    payload: { condition_type: this.state.selectedVal }
                  });
                  this.setState({
                    ...this.state,
                    isAdding: false,
                    selectedVal: ""
                  });
                }}
              >
                Done
              </button>
            </div>
          ) : (
            <button
              className="new-condition"
              onClick={() => this.setState({ isAdding: true })}
            >
              + Add New Condition
            </button>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {!this.props.editing ? (
              <button
                className="submit"
                onClick={() => {
                  this.setState({
                    isOpen: false,
                    isAdding: false,
                    selectedVal: ""
                  });
                }}
              >
                Save Draft
              </button>
            ) : (
              <></>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%"
              }}
            >
              {this.props.editing ? (
                <button
                  className="submit cancel"
                  onClick={this.handleCancelEdit}
                >
                  Cancel
                </button>
              ) : (
                <div style={{ display: "flex" }}>
                  <button
                    disabled={popoverOpen}
                    className="submit cancel"
                    onClick={e => this.setState({ anchorEl: e.currentTarget })}
                  >
                    Clear
                    <Popover
                      id={id}
                      open={popoverOpen}
                      onClose={() => this.setState({ anchorEl: null })}
                      anchorEl={this.state.anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      className="delete-popover"
                    >
                      <div style={{ margin: "10px" }}>
                        <div>
                          Are you sure you want to clear this rule?
                          <div style={{ display: "flex" }}>
                            <button
                              className="popover-button yes-button"
                              onClick={() => {
                                store.dispatch({
                                  type: "CLEAR_RECOMMENDATION_BUILDER"
                                });
                                this.setState({ anchorEl: null });
                                this.setState({ isOpen: false });
                              }}
                            >
                              Yes
                            </button>
                            <button
                              className="popover-button no-button"
                              onClick={() => this.setState({ anchorEl: null })}
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </Popover>
                  </button>
                </div>
              )}
              {this.props.editing ? (
                <>
                  {this.state.submitLoading ? (
                    <Loader type="TailSpin" width="25" height="25" />
                  ) : (
                    <button className="submit" onClick={this.handleSubmit}>
                      Update
                    </button>
                  )}
                </>
              ) : (
                <>
                  {this.state.submitLoading ? (
                    <Loader type="TailSpin" width="25" height="25" />
                  ) : (
                    <button className="submit" onClick={this.handleSubmit}>
                      Submit
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal>
        {this.state.loading || this.state.productsLoading ? (
          <Loader type="TailSpin" width="25" height="25" />
        ) : (
          <button
            onClick={() => this.setState({ isOpen: true })}
            className="add-new-rule"
          >
            Add new Rule
          </button>
        )}
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {
    doctorId: store.getState().userReducer.hash_id,
    conditions: store.getState().recommendationBuilderReducer.conditions,
    recommendationBuilder: store.getState().recommendationBuilderReducer
  };
};
export default connect(mapStateToProps)(NewRecommendation);

import React, { useState } from "react";
import PrescriptionPdf from "./PrescriptionPdf";
import { store } from "../../../reducers/Persist";
import { useSelector } from "react-redux";
import "./PrescriptionPreview.scss";

const PrescriptionPreview = () => {
  const { pdfLoading } = useSelector(state => state.rxWriterReducer);
  const { form } = useSelector(state => state.rxWriterReducer);
  const { showUpdateStatusLink } = useSelector(state => state.rxWriterReducer);
  const [checkboxActive, setCheckboxActive] = useState(
    form != "O" ? true : false
  );
  return (
    <div className="prescription-preview-container">
      <PrescriptionPdf />
      {pdfLoading === false ? (
        <center>
          <span className="prescription-preview-button-row"></span>
        </center>
      ) : null}
      <center>
        <div
          className="prescription-preview-go-back"
          onClick={() => {
            store.dispatch({ type: "CHANGE_TAB", data: 2 });
            store.dispatch({ type: "PRESCRIPTION_PDF_LOADING_TRUE" });
          }}
        >
          <u>Go Back to Prescription Builder</u>
        </div>
      </center>
      {showUpdateStatusLink && (
        <center>
          <div
            className="prescription-preview-go-back"
            onClick={() => {
              store.dispatch({
                type: "SET_UPDATE_STATUS_MODAL",
                payload: true
              });
            }}
            style={{ color: "#22548A", marginTop: "15px" }}
          >
            <u>Update Status</u>
          </div>
        </center>
      )}
    </div>
  );
};

export default PrescriptionPreview;

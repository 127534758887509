import { Modal, Button } from "react-bootstrap";
import React from "react";
import {
  AudioAppointmentText,
  AudioAppointmentCategoryBlock
} from "./current-appointment.modal.styles";
import AudioIcon from "./audio.svg";
import "./current-appointment.modal.scss";
import dataResource from "../../../../constants/dataResource";

const CurrentAppointmentNoticationModal = ({
  show,
  handleClose,
  handleBtnClick,
  patientName,
  categories,
  source
}) => {
  return (
    <Modal show={show} size="2xl" centered={true} onHide={handleClose}>
      <Modal.Header closeButton />
      <Modal.Body style={{ width: "700px" }}>
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ marginRight: "16px" }}>
            <AudioIcon />
          </div>

          <div>
            {patientName ? (
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#030303"
                }}
              >
                {patientName}
              </div>
            ) : (
              ""
            )}
            <div style={{ display: "flex" }}>
              <div
                style={{
                  color: "white",
                  paddingLeft: "6px",
                  paddingRight: "6px",
                  borderRadius: "8px",
                  background: source === "O" ? "#319795" : "#D53F8C"
                }}
              >
                {source === "O" ? "Order" : source === "DA" ? "Direct" : "Form"}
                &nbsp;appointment
              </div>

              <div
                style={{
                  marginLeft: "12px",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                {categories?.length
                  ? categories?.map(_category => (
                      <CategoryBlock category={_category} />
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <AudioAppointmentText>
          You have an{" "}
          <span style={{ fontWeight: "bold", color: "#48BB78" }}>
            Incoming Appointment
          </span>
        </AudioAppointmentText>
        <Button
          variant="success"
          style={{
            width: "100%",
            backgroundColor: "#379F8E",
            fontWeight: "bold"
          }}
          onClick={handleBtnClick}
        >
          View Appointment
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CurrentAppointmentNoticationModal;

const CategoryBlock = ({ category }) => {
  const categoryName = dataResource?.categoryMap[category]
    ? dataResource?.categoryMap[category]
    : category;

  return (
    <AudioAppointmentCategoryBlock>
      {categoryName}
    </AudioAppointmentCategoryBlock>
  );
};

import React, { useEffect, useState } from "react";
import { TimerWrapper } from "./styles";
import noop from "lodash/noop";

const StepAwayTimer = ({
  stepAway,
  stepAwayTimeLeft,
  triggerNotification = noop
}) => {
  const getDisplayTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secondsStr = Math.floor(seconds % 60);

    const formattedTime = `${minutes.toString().padStart(2, "0")}:${secondsStr
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const [timeLeft, setTimeLeft] = useState(stepAwayTimeLeft);
  const [intervalId, setIntervalId] = useState(0);

  useEffect(() => {
    setTimeLeft(stepAwayTimeLeft);
  }, [stepAwayTimeLeft]);

  useEffect(() => {
    if (intervalId) clearInterval(intervalId);
    if (timeLeft <= 0) return;
    // Show notification 2 minutes before step away time end
    if (timeLeft === 60 * 2) triggerNotification(true);
    const id = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    setIntervalId(id);
  }, [timeLeft]);

  if (timeLeft <= 0) return <></>;
  return (
    <div style={{ position: "relative" }}>
      <TimerWrapper>{getDisplayTime(timeLeft)}</TimerWrapper>
    </div>
  );
};

export default StepAwayTimer;

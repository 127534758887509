import styled from "styled-components";

export const StatusLogsViewerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const LogsViewerDiv = styled.div`
  position: relative;
  height: 40px;
  width: 18px;
  margin: 0px;
  background-color: ${props => props.statusColor ?? "black"};
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  transition: width 0.3s ease-out;
  &:hover {
    width: 100px;
  }

  &:hover::before {
    content: attr(data-logTime);
    color: black;
    position: absolute;
    top: -40px;
    left: -15px;
    transition: all 0.3s ease-out;
    white-space: nowrap;
  }

  &:nth-child(-n + 4) {
    &:hover::before {
      left: 0px;
    }
  }

  &:last-child {
    &:hover::before {
      left: 21px;
    }
  }
`;

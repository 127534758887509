import React, { useMemo, useState, useEffect } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  AppointmentAudioLogsContainer,
  AppointmentAudioLogsHeader,
  SubmitButton,
  InfoTable
} from "./styles";
import MaterialTable from "material-table";
import { MaterialTableWrapper } from "../NewDoctorDashboard/V2/AppointmentSummary/common/MaterialTableWrapper";
import { audioLogsColumns } from "./columns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { toast } from "react-toastify";
import { faFilter, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import config from "../../../constants/apiList";
import { Modal } from "react-bootstrap";

export default function AppointmentAudioLogs() {
  const [selectedFromDateTime, setSelectedFromDateTime] = useState(
    new Date(moment().subtract(1, "hour"))
  );
  const processedFromDateTime = useMemo(() => {
    return moment(selectedFromDateTime).format("YYYY-MM-DD HH:mm:ss");
  }, [selectedFromDateTime]);

  const [selectedToDateTime, setSelectedToDateTime] = useState(new Date());
  const processedToDateTime = useMemo(() => {
    return moment(selectedToDateTime).format("YYYY-MM-DD HH:mm:ss");
  }, [selectedToDateTime]);

  const [isLoading, setIsLoading] = useState(false);
  const [audioLogsData, setAudioLogsData] = useState([]);

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  const [showInfoModal, setShowInfoModal] = useState(false);

  const fetchAudioLogs = () => {
    setIsLoading(true);
    axios
      .post(config.url.APPOINTMENT_AUDIO_LOGS, {
        from_time: processedFromDateTime,
        to_time: processedToDateTime
      })
      .then(res => {
        setIsLoading(false);
        setAudioLogsData(res.data?.data?.appointmentAudioStatus?.result);
      })
      .catch(err => toast.error(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  const handleInfoClick = () => {
    setShowInfoModal(true);
  };

  const handleModalClose = () => {
    setShowInfoModal(false);
  };

  useEffect(() => {
    fetchAudioLogs();
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, []);

  return (
    <AppointmentAudioLogsContainer>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AppointmentAudioLogsHeader>
          <DateTimePicker
            label="From"
            inputVariant="outlined"
            value={selectedFromDateTime}
            onChange={setSelectedFromDateTime}
            disableFuture
          />
          <DateTimePicker
            label="To"
            inputVariant="outlined"
            value={selectedToDateTime}
            onChange={setSelectedToDateTime}
            disableFuture
          />
          <SubmitButton onClick={fetchAudioLogs}>View</SubmitButton>
        </AppointmentAudioLogsHeader>
      </MuiPickersUtilsProvider>
      <MaterialTableWrapper>
        <MaterialTable
          icons={{
            Filter: () => <FontAwesomeIcon icon={faFilter} />
          }}
          title={
            <h4 style={{ margin: 0, padding: 0 }}>
              Appointment Audit Logs{" "}
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ cursor: "pointer" }}
                onClick={handleInfoClick}
              />
            </h4>
          }
          columns={audioLogsColumns}
          isLoading={isLoading}
          data={audioLogsData || []}
          page={10}
          options={{
            search: true,
            paging: true,
            sorting: false,
            filtering: true,
            exportButton: true,
            exportAllData: true,
            print: true,
            draggable: false,
            pageSize: 9,
            headerStyle: {
              backgroundColor: "black",
              color: "#fff",
              fontSize: "14px"
            },

            rowStyle: {
              backgroundColor: "white",
              width: "min-content",
              padding: "20px"
            },
            cellStyle: {
              fontSize: matches ? "13px" : "15px",
              backgroundColor: "white",
              color: "#6C6C6C"
            }
          }}
          style={{
            margin: "10px",
            padding: "10px",
            marginTop: "0px",
            paddingTop: "0px",
            backgroundColor: "transparent",
            boxShadow: "none"
          }}
        />
      </MaterialTableWrapper>
      <Modal
        show={showInfoModal}
        size="lg"
        centered={true}
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <h4 style={{ margin: 0, padding: 0 }}>What these statuses mean?</h4>
        </Modal.Header>
        <Modal.Body style={{ width: "50vw" }}>
          <InfoTable>
            <thead>
              <tr>
                <th style={{ marginRight: "5px" }}>Overall Status</th>
                <th style={{ marginRight: "5px" }}>Doctor Leg Status</th>
                <th style={{ marginRight: "5px" }}>Patient Leg Status</th>
                <th style={{ marginRight: "5px" }}>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ color: "green" }}>completed</td>
                <td style={{ color: "green" }}>completed</td>
                <td style={{ color: "green" }}>completed</td>
                <td>
                  The audio call was connected successfully by both doctor and
                  patient
                </td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>no-answer</td>
                <td style={{ color: "green" }}>completed</td>
                <td style={{ color: "red" }}>no-answer</td>
                <td>
                  Doctor Picked up the call, but patient didn't pick up the call
                </td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>busy</td>
                <td style={{ color: "green" }}>completed</td>
                <td style={{ color: "red" }}>busy</td>
                <td>Doctor Picked up the call, but patient was busy</td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>failed</td>
                <td style={{ color: "green" }}>completed</td>
                <td style={{ color: "red" }}>failed</td>
                <td>
                  Doctor Picked up the call, Exotel dialed the patient number
                  but Exotel's carriers could not connect the call. Possible
                  reasons include the number is switched off or not reachable,
                  the number is invalid, transient connection error, etc.
                </td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>failed</td>
                <td style={{ color: "green" }}>completed</td>
                <td style={{ color: "red" }}>canceled</td>
                <td>
                  Doctor Picked up the call, but patient rejected the incoming
                  call
                </td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>failed</td>
                <td style={{ color: "green" }}>completed</td>
                <td style={{ color: "red" }}>N/A (empty)</td>
                <td>
                  Doctor Picked up the call but the call didn't have a second
                  leg or the user disconnected before Exotel began to dial.
                </td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>no-answer</td>
                <td style={{ color: "red" }}>no-answer</td>
                <td style={{ color: "red" }}>N/A (empty)</td>
                <td>Doctor didn't picked up the call</td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>failed</td>
                <td style={{ color: "red" }}>canceled</td>
                <td style={{ color: "red" }}>N/A (empty)</td>
                <td>Doctor rejected the incoming call</td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>busy</td>
                <td style={{ color: "red" }}>busy</td>
                <td style={{ color: "red" }}>N/A (empty)</td>
                <td>
                  Exotel dialed the Doctor number but received a busy response.
                </td>
              </tr>
              <tr>
                <td style={{ color: "red" }}>failed</td>
                <td style={{ color: "red" }}>failed</td>
                <td style={{ color: "red" }}>N/A (empty)</td>
                <td>
                  Exotel's carriers could not connect Doctor's number. Possible
                  reasons include the number is switched off or not reachable,
                  the number is invalid, transient connection error etc.
                </td>
              </tr>
            </tbody>
          </InfoTable>
        </Modal.Body>
      </Modal>
    </AppointmentAudioLogsContainer>
  );
}

import React from "react";
import "./BottomTabComponent.scss";

const BottomTabComponent = ({ title, onClick, className }) => {
  return (
    <div className={className} onClick={() => onClick()}>
      {title}
    </div>
  );
};

export default BottomTabComponent;

import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { forwardRef } from "react";
import UpdateIcon from "@material-ui/icons/Update";
const tableIcons = {
  reschedule: forwardRef((props, ref) => <UpdateIcon {...props} ref={ref} />)
};

export default function EditableTable({
  title,
  cols,
  rows,
  setUpdatedData,
  search,
  pageSize,
  rowStyle,
  actions
}) {
  return (
    <MaterialTable
      icons={tableIcons}
      title={title}
      columns={cols}
      data={rows}
      options={{
        // search: false,
        search: search ? search : false,
        exportButton: true,
        exportAllData: true,
        pageSize: pageSize ? pageSize : 10,
        headerStyle: {
          backgroundColor: "#003459",
          color: "#FFF"
        },
        rowStyle: rowStyle,
        filtering: true,
        toolbarButtonAlignment: "left",
        searchFieldAlignment: "left"
      }}
      components={{
        Pagination: props => {
          return (
            <td>
              <table
                style={{
                  position: "relative",
                  bottom: 0,
                  left: 0,
                  width: "100%"
                }}
              >
                <tbody>
                  <tr>
                    <TablePagination {...props} />
                  </tr>
                </tbody>
              </table>
            </td>
          );
        }
      }}
      actions={actions}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              setUpdatedData([...rows, newData]);

              resolve();
            }, 1000);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...rows];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setUpdatedData([...dataUpdate]);

              resolve();
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...rows];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setUpdatedData([...dataDelete]);
              resolve();
            }, 1000);
          })
      }}
      style={{ border: "1px solid black" }}
    />
  );
}

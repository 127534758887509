import React, { useState, useEffect } from "react";
import "./BMI.scss";
import { store } from "../../../../../reducers/Persist";
import { useSelector } from "react-redux";

export default function BMI(props) {
  const apptId = useSelector(state => state.rxWriterReducer.id);
  const weight = useSelector(state => state.observationReducer[apptId].weight);
  const curHeight = useSelector(
    state => state.observationReducer[apptId].height
  );
  const currentBMI = () => {
    const feet = parseInt(curHeight.split("'")[0]);
    const inches = parseInt(curHeight.split("'")[1].split('"')[0]);
    const totalInches = feet * 12 + inches;
    const metres = totalInches * 0.0254;
    return (weight / (metres * metres)).toFixed(2);
  };
  const [heightArr, setHeightArr] = useState([]);
  useEffect(() => {
    const tempArr = [];
    for (let i = 6; i < 12; i++) tempArr.push(`4'${i}''`);
    for (let i = 0; i < 12; i++) tempArr.push(`5'${i}''`);
    for (let i = 0; i < 7; i++) tempArr.push(`6'${i}''`);
    setHeightArr(tempArr);
  }, []);
  return (
    <div className="bmi">
      <div className="weight-height-container">
        <div className="subheading">Weight</div>
        <input
          type="number"
          placeholder="NN"
          className="weight-input"
          value={weight}
          onChange={e => {
            store.dispatch({
              type: "SET_WEIGHT_HEIGHT_DATA",
              payload: {
                key: "weight",
                value: e.target.value
              }
            });
          }}
        />
      </div>
      <div className="weight-height-container">
        <div className="subheading">Height</div>
        <select
          onChange={e => {
            store.dispatch({
              type: "SET_WEIGHT_HEIGHT_DATA",
              payload: {
                key: "height",
                value: e.target.value
              }
            });
          }}
        >
          <option value="" selected disabled hidden>
            Select
          </option>
          {heightArr.map((height, index) => {
            return (
              <option
                key={index}
                value={height}
                selected={height === curHeight}
              >
                {height}
              </option>
            );
          })}
        </select>
      </div>
      <div className="bmi-container">
        <div className="bmi-subheading">BMI</div>
        <p className="bmi-value">{weight && curHeight ? currentBMI() : "--"}</p>
      </div>
      {/* <div className="bmi-display">
        <p className="bmi-subheading">BMI</p>
        <p className="bmi-value">{weight && curHeight ? currentBMI() : "--"}</p>
      </div> */}
    </div>
  );
}

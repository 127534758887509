import React, { useContext, useEffect, useState } from "react";
import CurrentStatusIndicator from "./CurrentStatusIndicator";
import { CurrentAppointmentContext } from "./withSocketHOC";
import { getCallStatusFromRx } from "./audio.status.helper";

const CurrentAppointment = ({ appointmentData, isInstant }) => {
  // const [, currentAppointmentData] =
  //   useContext(CurrentAppointmentContext);

  // const [audioStatus, setAudioStatus] = useState(appointmentData?.es_audio_status)

  // useEffect(() => {
  //   if (!isInstant) {
  //     setAudioStatus(appointmentData?.es_audio_status)
  //     return
  //   }

  //   if (!currentAppointmentData || parseInt(currentAppointmentData?.data?.appointmentId) !== parseInt(appointmentData?.id)) {
  //     setAudioStatus(appointmentData?.es_audio_status)
  //     return
  //   }

  //   setAudioStatus(audioStatusCompare(currentAppointmentData?.status, appointmentData?.es_audio_status))

  // }, [appointmentData, currentAppointmentData, isInstant])

  return (
    <CurrentStatusIndicator
      isInstant={isInstant}
      status={getCallStatusFromRx(appointmentData)}
      doctorAttempts={0}
      patientAttempts={0}
    />
  );
};

export default CurrentAppointment;

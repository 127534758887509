import styled from "styled-components";

export const DoctorStatusLogsContainer = styled.div`
  width: 84vw;

  h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
`;

export const DoctorLogDivParentContainer = styled.div`
  margin: 10px 10px 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 10px;
  border-radius: 5px;
`;

export const DoctorLogDivContainer = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 40px;
`;

export const DoctorIdSection = styled.p`
  font-weight: bold;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;

  &:hover {
    overflow: visible;
  }
  span {
    font-size: 1rem;
    position: relative;
    bottom: 1px;
  }
`;

export const SectionHeaderContainer = styled.div`
  width: max-content;
  min-width: 10%;
`;
export const SectionBodyContainer = styled.div`
  width: 75%;
  overflow-x: scroll;
`;

export const ActiveHoursContainer = styled.div`
  p {
    margin: 0;
  }
`;

export const ActiveHoursHeader = styled.p`
  font-weight: bold;
`;

export const OOCHoursContainer = styled.div`
  margin-top: 10px;
  p {
    margin: 0;
  }
`;

export const OOCHoursHeader = styled.p`
  font-weight: bold;
`;

export const DoctorStatusLogsHeader = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DoctorStatusLogsDateTime = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const CircularProgressContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

export const SubmitButton = styled.button`
  width: 80px;
  padding: 5px;
  border-radius: 5px;
  background-color: black;
  color: white;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const DoctorStatusLogsSelect = styled.div``;

import React from "react";
import dataResource from "../../../../constants/dataResource";
import OptionButton from "./OptionButton";
import noop from "lodash/noop";

const StepAwayOptions = ({
  doctorStatus = 0,
  stepAwayAvailable = false,
  durationOptions = [],
  attemptsLeft = 0,
  loading = false,
  updateStepAway = noop
}) => {
  const disableStepAway =
    doctorStatus === dataResource?.DOCTOR_STATUS_LIST?.BUSY;
  if (doctorStatus === dataResource?.DOCTOR_STATUS_LIST?.STEP_AWAY) {
    return (
      <OptionButton
        stepAway={false}
        updateStepAway={updateStepAway}
        loading={loading}
        durationOptions={durationOptions}
      />
    );
  }
  if (stepAwayAvailable) {
    return (
      <OptionButton
        stepAway={true}
        attemptsLeft={attemptsLeft}
        updateStepAway={updateStepAway}
        loading={loading}
        disabled={disableStepAway}
        durationOptions={durationOptions}
      />
    );
  }
  return <></>;
};

export default StepAwayOptions;

import React, { useEffect, useMemo, useState } from "react";
import DoctorLog from "./doctorLogContainer";
import {
  DoctorStatusLogsContainer,
  DoctorStatusLogsHeader,
  DoctorStatusLogsDateTime,
  CircularProgressContainer,
  SubmitButton,
  DoctorStatusLogsSelect,
  HeaderContent
} from "./styles";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import config from "../../../constants/apiList";
import { convertAPIDate } from "./helper/date-helper";
import axios from "axios";
import { toast } from "react-toastify";
import DoctorStatusLegends from "./legends";
import moment from "moment";
import dataResource from "../../../constants/dataResource";

export default function DoctorStatusLogs() {
  const [fetchedData, setFetchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDoctorIds, setSelectedDoctorIds] = useState([]);
  const [requestSuccessTime, setRequestSuccessTime] = useState(null);

  const doctors = useMemo(() => {
    return (
      fetchedData?.map(item => ({
        doctorId: item.doctorId,
        doctorName: item.doctorName
      })) ?? []
    );
  }, [fetchedData]);

  const data = useMemo(() => {
    if (selectedDoctorIds.length > 0) {
      const tempData = [...fetchedData].filter(item =>
        selectedDoctorIds.includes(item.doctorId)
      );
      return tempData;
    } else {
      return fetchedData;
    }
  }, [selectedDoctorIds]);

  useEffect(() => {
    setSelectedDoctorIds([]);
  }, [fetchedData]);

  useEffect(() => {
    handleClick();
  }, []);

  const handleClick = () => {
    setIsLoading(true);
    axios
      .post(config.url.GET_ALL_DOCTOR_ACTIVITY_STATUS_LOGS, {
        date: convertAPIDate(selectedDate)
      })
      .then(res => {
        setFetchedData(res.data?.data ?? []);
        setRequestSuccessTime(
          moment().format(dataResource.DATE_FORMATS.SHORT_TIME_FORMAT)
        );
      })
      .catch(err => toast.error(err.message))
      .finally(() => setIsLoading(false));
  };

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setSelectedDoctorIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <DoctorStatusLogsContainer>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <HeaderContent>
          <h1>Doctor Status Logs</h1>
          <DoctorStatusLegends />
        </HeaderContent>
        <DoctorStatusLogsHeader>
          <DoctorStatusLogsDateTime>
            <DatePicker
              label="Logs Date"
              inputVariant="outlined"
              disableFuture
              value={selectedDate}
              onChange={setSelectedDate}
            />
            <SubmitButton onClick={handleClick} disabled={isLoading}>
              View
            </SubmitButton>
          </DoctorStatusLogsDateTime>
          <DoctorStatusLogsSelect>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="doctor-status-multiple-id-label">
                Select Doctors
              </InputLabel>
              <Select
                multiple
                value={selectedDoctorIds}
                onChange={handleChange}
                input={<OutlinedInput label="Select Doctors" />}
                renderValue={selected => selected.join(", ")}
                labelId="doctor-status-multiple-id-label"
              >
                {doctors.map(({ doctorId, doctorName }) => (
                  <MenuItem key={doctorId} value={doctorId}>
                    <Checkbox
                      checked={selectedDoctorIds.indexOf(doctorId) > -1}
                    />
                    <ListItemText
                      primary={doctorName + " (" + doctorId + ")"}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DoctorStatusLogsSelect>
        </DoctorStatusLogsHeader>
        {!isLoading &&
          data.map((doctorData, index) => (
            <DoctorLog
              key={index}
              data={doctorData}
              selectedDate={selectedDate}
              requestSuccessTime={requestSuccessTime}
            />
          ))}

        {isLoading && (
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        )}
      </MuiPickersUtilsProvider>
    </DoctorStatusLogsContainer>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import moment from "moment";
import Loader from "react-loader-spinner";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export default function ProductsView() {
  let today = moment().format("YYYY-MM-DD");
  const [fromDate, setfromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  const [brand, setBrand] = useState("MM");
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const reqBody = {
    from_date: fromDate,
    to_date: toDate,
    brand
  };

  useEffect(() => {
    setShowLoader(true);
    axios
      .post(config.url.PRODUCTS_VIEW, reqBody)
      .then(res => {
        if (res.data.body.data.result) {
          setRows([]);
          setShowLoader(false);
        } else {
          generateRows(res.data.body.data);
          setShowLoader(false);
        }
      })
      .catch(err => {
        console.log(err);
        setRows([]);
        setShowLoader(false);
      });
  }, [brand, refresh]);

  const cols = [
    {
      title: "Brand",
      field: "brand"
    },
    {
      title: "ID",
      field: "appointmentId"
    },
    {
      title: "Email",
      field: "email"
    },
    {
      title: "Phone",
      field: "phone"
    },
    {
      title: "Source",
      field: "source"
    },
    {
      title: "Recommended",
      field: "recommended",
      cellStyle: { minWidth: "250px" }
    },
    {
      title: "Recommended Count",
      field: "recommended_count"
    },
    {
      title: "Recommended Value",
      field: "recommended_value"
    },
    {
      title: "Prescribed",
      field: "prescribed",
      cellStyle: { minWidth: "250px" }
    },
    {
      title: "Prescribed Count",
      field: "prescribed_count"
    },
    {
      title: "Prescribed Value",
      field: "prescribed_value"
    },
    {
      title: "Ordered",
      field: "ordered",
      cellStyle: { minWidth: "250px" }
    },
    {
      title: "Ordered Count",
      field: "ordered_count"
    },
    {
      title: "Ordered Value",
      field: "ordered_value"
    },
    {
      title: "Ordered Items",
      field: "ordered_items",
      cellStyle: { minWidth: "250px" }
    },
    {
      title: "External",
      field: "external",
      cellStyle: { minWidth: "250px" }
    },
    {
      title: "External Count",
      field: "external_count"
    },
    {
      title: "External Value",
      field: "external_value"
    },
    {
      title: "Doctor Added",
      field: "doc_added",
      cellStyle: { minWidth: "250px" }
    },
    {
      title: "Doctor Removed",
      field: "doc_removed",
      cellStyle: { minWidth: "250px" }
    },
    {
      title: "Patient Added",
      field: "patient_added",
      cellStyle: { minWidth: "250px" }
    },
    {
      title: "Patient Removed",
      field: "patient_removed",
      cellStyle: { minWidth: "250px" }
    }
  ];
  const generateRows = data => {
    const rows = [];
    for (const appointmentId in data) {
      const productData = data[appointmentId];
      const row = {
        brand: productData.brand,
        appointmentId: productData.appointmentId,
        email: productData.email,
        phone: productData.phone,
        source: productData.source
      };
      row.recommended =
        productData.recommended_products &&
        productData.recommended_products.length
          ? productData.recommended_products.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      row.recommended_count =
        productData.recommended_products &&
        productData.recommended_products.length
          ? productData.recommended_products.reduce((sum, current) => {
              return sum + parseInt(current.quantity);
            }, 0)
          : 0;
      row.recommended_value =
        productData.recommended_products &&
        productData.recommended_products.length
          ? productData.recommended_products.reduce((sum, current) => {
              const price =
                parseInt(current.price || 0) * parseInt(current.quantity || 1);
              return sum + parseInt(price);
            }, 0)
          : 0;
      row.prescribed =
        productData.prescribed_products &&
        productData.prescribed_products.length
          ? productData.prescribed_products.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      row.prescribed_count =
        productData.prescribed_products &&
        productData.prescribed_products.length
          ? productData.prescribed_products.reduce((sum, current) => {
              return sum + parseInt(current.quantity);
            }, 0)
          : 0;
      row.prescribed_value =
        productData.prescribed_products &&
        productData.prescribed_products.length
          ? productData.prescribed_products.reduce((sum, current) => {
              const price =
                parseInt(current.price || 0) * parseInt(current.quantity || 1);
              return sum + parseInt(price);
            }, 0)
          : 0;
      row.ordered =
        productData.ordered_products && productData.ordered_products.length
          ? productData.ordered_products.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      row.ordered_count =
        productData.ordered_products && productData.ordered_products.length
          ? productData.ordered_products.reduce((sum, current) => {
              return sum + parseInt(current.quantity);
            }, 0)
          : 0;
      row.ordered_value =
        productData.ordered_products && productData.ordered_products.length
          ? productData.ordered_products.reduce((sum, current) => {
              const price =
                parseInt(current.price || 0) * parseInt(current.quantity || 1);
              return sum + parseInt(price);
            }, 0)
          : 0;
      row.ordered_items =
        productData.ordered_items && productData.ordered_items.length
          ? productData.ordered_items.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      row.external =
        productData.external_products && productData.external_products.length
          ? productData.external_products.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      row.external_count =
        productData.external_products && productData.external_products.length
          ? productData.external_products.reduce((sum, current) => {
              return sum + parseInt(current.quantity);
            }, 0)
          : 0;
      row.external_value =
        productData.external_products && productData.external_products.length
          ? productData.external_products.reduce((sum, current) => {
              const price =
                parseInt(current.price || 0) * parseInt(current.quantity || 1);
              return sum + parseInt(price);
            }, 0)
          : 0;
      row.doc_added =
        productData.doctor_added && productData.doctor_added.length
          ? productData.doctor_added.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      row.doc_removed =
        productData.doctor_removed && productData.doctor_removed.length
          ? productData.doctor_removed.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      row.patient_added =
        productData.patient_added && productData.patient_added.length
          ? productData.patient_added.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      row.patient_removed =
        productData.patient_removed && productData.patient_removed.length
          ? productData.patient_removed.reduce((total, current) => {
              return `${total}${total && current.name ? ", " : ""}${
                current.name || ""
              }`;
            }, "")
          : "-";
      // if nothing is prescribed, dont fill doc added doc removed, if
      if (row.prescribed == "-") {
        row.doc_added = "-";
        row.doc_removed = "-";
      }
      if (row.ordered == "-") {
        row.patient_added = "-";
        row.patient_removed = "-";
      }
      rows.push(row);
    }
    setRows(rows);
  };
  const handleSubmit = e => {
    e.preventDefault();
    // console.log(refresh);
    setRefresh(!refresh);
  };
  return (
    <>
      <br></br>
      <input
        type="date"
        className="input"
        value={fromDate}
        onChange={e => setfromDate(e.target.value)}
      />
      <input
        type="date"
        className="input"
        value={toDate}
        onChange={e => setToDate(e.target.value)}
      />
      <IconButton color="primary" aria-label="Go" onClick={handleSubmit}>
        <ArrowForwardIcon />
      </IconButton>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>
      {showLoader ? (
        <div className="loader-cm" style={{ marginTop: "20px" }}>
          Loading... Please Wait
          <div className="Loader-box">
            <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
          </div>
        </div>
      ) : (
        <Table4 cols={cols} rows={rows} title="Doctor Products" pageSize={20} />
      )}
    </>
  );
}

import React from "react";
import "./ViewReportButton.scss";

export default class ViewReportButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  render() {
    return (
      <div>
        <button
          disabled
          className="view-report-button"
          onClick={() => this.setState({ openReport: true })}
        >
          View Report
        </button>
      </div>
    );
  }
}

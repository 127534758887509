import React, { useEffect } from "react";
import "./styles.scss";
import { store } from "../../../../../reducers/Persist";
import { useSelector } from "react-redux";
const Lifestyle = () => {
  const diet = ["Vegetarian", "Non-Vegetarian", "Eggitarian", "Vegan"];
  const sleep = ["Regular", "Irregular"];
  const stress = ["Low", "Moderate", "High"];
  const exercise = ["Sedentary", "Light", "Moderate", "Everyday", "Extreme"];
  const apptId = useSelector(state => state.rxWriterReducer.id);
  const defaultVals = useSelector(
    state => state.observationReducer[apptId].lifestyleExisting.metadata
  );
  const lifestyleCheck = useSelector(
    state => state.observationCheckReducer.lifestyle
  );
  const patient_medical_history = useSelector(
    state => state.rxWriterReducer.patient_medical_history
  );
  useEffect(() => {
    if (patient_medical_history.length > 0) {
      let lifestyleExisting = {},
        sample = {
          metadata: {
            diet: "",
            sleep: "",
            exercise: "",
            stress: "",
            habits: {
              None: false,
              Alcohol: false,
              Tobacco: false,
              Narcotics: false,
              Caffeine: false,
              Others: false
            },
            habitOther: ""
          }
        };
      const lastRecord =
        patient_medical_history[patient_medical_history.length - 1];
      for (const observation of lastRecord.observations) {
        if (observation.observation_type === "lifestyle_existing") {
          lifestyleExisting = JSON.parse(observation.metadata);
          break;
        }
      }
      for (const key in sample.metadata) {
        if (key === "habits") {
          let flag = true;
          for (const habit in defaultVals.habits)
            if (defaultVals.habits[habit]) {
              flag = false;
              sample.metadata.habits[habit] = true;
            }
          if (flag && lifestyleExisting[key]) {
            for (const habit of lifestyleExisting[key]) {
              sample.metadata.habits[habit] = true;
            }
          }
        } else if (!defaultVals[key])
          sample.metadata[key] = lifestyleExisting[key];
        else sample.metadata[key] = defaultVals[key];
      }
      store.dispatch({ type: "SET_LIFESTYLE_EXISTING", payload: sample });
    }
  }, [patient_medical_history]);
  const handleSelect = (key, value) => {
    store.dispatch({
      type: "SET_OBJECT_CHECK",
      payload: {
        category: "lifestyle",
        key: key,
        value: false
      }
    });
    store.dispatch({
      type: "SET_ADDITIONAL_INFO_LIFESTYLE",
      payload: {
        key: key,
        value: value
      }
    });
  };
  return (
    <div className="lifestyle">
      {/* <p className="lifestyle_title">Lifestyle</p> */}
      <div className="lifestyle_row">
        <div className="lifestyle_row_left">
          <div style={{ display: "flex" }}>
            <p>Diet</p>
            <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <select
              onChange={e => handleSelect("diet", e.target.value)}
              style={{
                border: lifestyleCheck.diet ? "1px solid #E86669" : "none"
              }}
            >
              <option value="select" selected>
                -select-
              </option>
              {diet.map(val => (
                <option
                  value={val}
                  key={val}
                  selected={val === defaultVals["diet"]}
                >
                  {val}
                </option>
              ))}
            </select>
            {lifestyleCheck.diet && (
              <div style={{ color: "#E86669" }}>
                This field cannot be left blank
              </div>
            )}
          </div>
        </div>
        <div className="lifestyle_row_right">
          <div style={{ display: "flex" }}>
            <p>Sleep</p>
            <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <select
              onChange={e => handleSelect("sleep", e.target.value)}
              style={{
                border: lifestyleCheck.sleep ? "1px solid #E86669" : "none"
              }}
            >
              <option value="select">-select-</option>
              {sleep.map(val => (
                <option
                  value={val}
                  key={val}
                  selected={val === defaultVals["sleep"]}
                >
                  {val}
                </option>
              ))}
            </select>
            {lifestyleCheck.sleep && (
              <div style={{ color: "#E86669" }}>
                This field cannot be left blank
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="lifestyle_row">
        <div className="lifestyle_row_left">
          <div style={{ display: "flex" }}>
            <p>Exercise</p>
            <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <select
              onChange={e => handleSelect("exercise", e.target.value)}
              style={{
                border: lifestyleCheck.exercise ? "1px solid #E86669" : "none"
              }}
            >
              <option value="select">-select-</option>
              {exercise.map(val => (
                <option
                  value={val}
                  key={val}
                  selected={val === defaultVals["exercise"]}
                >
                  {val}
                </option>
              ))}
            </select>
            {lifestyleCheck.exercise && (
              <div style={{ color: "#E86669" }}>
                This field cannot be left blank
              </div>
            )}
          </div>
        </div>
        <div className="lifestyle_row_right">
          <div style={{ display: "flex" }}>
            <p>Stress</p>
            <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <select
              onChange={e => handleSelect("stress", e.target.value)}
              style={{
                border: lifestyleCheck.stress ? "1px solid #E86669" : "none"
              }}
            >
              <option value="select">-select-</option>
              {stress.map(val => (
                <option
                  value={val}
                  key={val}
                  selected={val === defaultVals["stress"]}
                >
                  {val}
                </option>
              ))}
            </select>
            {lifestyleCheck.stress && (
              <div style={{ color: "#E86669" }}>
                This field cannot be left blank
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lifestyle;

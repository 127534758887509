import React, { Component } from "react";
import "./QuickView.scss";
import axios from "axios";
import CONFIG_CONSTANTS from "./../../../constants/apiList";
import { store } from "../../../reducers/Persist";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";
import Iframe from "react-iframe";
import { alertBox } from "./../../common/PopupBox/PopupBox";
import moment from "moment";

class QuickView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: 0,
      orders: 0,
      forms: 0,
      doctors: 0,
      lastAppointmentDate: "",
      previousDoctor: "",
      lastRxId: -1,
      lastOrderId: -1,
      complaints: "",
      history: "",
      diagnosis: "",
      lastOrderProducts: [],
      showModal: false,
      pdf: ""
    };
    this.handleClose = () => {
      this.setState({ showModal: false, pdf: "" });
    };
    this.handleModal = (e, prescription_id) => {
      e.preventDefault();
      let data = {
        doctorId: store.getState().userReducer.hash_id,
        prescriptionId: prescription_id,
        brand: this.props.brand
      };
      console.log("data for pdf:", data);
      axios.post(CONFIG_CONSTANTS.url.GET_PDF_URL, data).then(response => {
        this.setState({ pdf: response.data.body.data, showModal: true });
      });
    };

    this.getOrderPlaced = () => {
      console.log(this.props.userInfo);
      // let url = `https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/order-details`
      const data = {
        brand: this.props.brand,
        doctor_id: store.getState().userReducer.hash_id,
        event_type: "order_placed",
        order_id: this.state.lastOrderId
      };
      axios
        .post(CONFIG_CONSTANTS.url.ORDER_DETAILS_URL, data)
        .then(response => {
          console.log("response.data");
          console.log(response.data);
          if (!response.data) {
            alertBox("cannot retrieve data");
            return;
          }
          let recommended = [];
          if (response.data.body.data.product_kits) {
            response.data.body.data.product_kits.map((prod, index) => {
              if (prod.product_items) {
                prod.product_items.map((singleItem, index) => {
                  recommended.push(singleItem.name);
                });
              }
            });
          }
          if (response.data.body.data.product_items) {
            response.data.body.data.product_items.map((prod, index) => {
              recommended.push(prod.name);
            });
          }
          this.setState({ lastOrderProducts: recommended });
        });
    };
  }
  componentDidMount() {
    const url =
      "https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/quickView";
    const appointmentId = this.props.appointmentId.slice(
      this.props.appointmentId.lastIndexOf("-") + 1
    );
    const data = {
      appointmentId,
      brand: this.props.brand,
      doctorId: this.props.doctorId,
      email: this.props.email
    };
    axios.post(CONFIG_CONSTANTS.url.GET_QUICK_VIEW, data).then(response => {
      console.log("MY Response", response);
      this.setState({ ...response.data.body.data });
      this.getOrderPlaced();
    });
    console.log("Component did mount invoked");
  }
  render() {
    return (
      <div className="quick-view-container">
        <h4 className="sectionHeading">Quick View</h4>
        <div className="quick-view-main-container">
          <div className="last-updates">
            <div>
              <span className="field">Last Appointment:</span>
              <span className="value">
                {this.state.lastAppointmentDate
                  ? moment(this.state.lastAppointmentDate)
                      .local()
                      .format("DD MMM YYYY")
                  : "N/A"}
              </span>
              <span className="field">Previous Doctor:</span>
              <span className="value">
                {this.state.previousDoctor ? this.state.previousDoctor : "N/A"}
              </span>
            </div>

            <div>
              <span className="field">Last Rx:</span>
              <span className="value">
                {this.state.lastRxId !== -1 ? (
                  <Button
                    className="btn-sm btn-primary"
                    variant="clear"
                    onClick={e => {
                      this.handleModal(e, this.state.lastRxId);
                    }}
                  >
                    {this.state.showModal ? "Hide" : "Show"}
                  </Button>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
            <div>
              <span className="field">Last Order:</span>
              <span className="value">
                <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                  {this.state.lastOrderProducts.map((prod, index) => {
                    return <li key={index}>{prod}</li>;
                  })}
                </ul>
              </span>
            </div>
          </div>
          <div className="totals">
            <table>
              <tr>
                <td>
                  <span className="field">Appointments:</span>
                  <span className="value">{this.state.appointments}</span>
                </td>
                <td>
                  <span className="field">Forms:</span>
                  <span className="value">{this.state.forms}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="field">Orders:</span>
                  <span className="value">{this.state.orders}</span>
                </td>
                <td>
                  <span className="field">Doctors:</span>
                  <span className="value">{this.state.doctors}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div>
            <div>
              <span className="field">Complaints:</span>
              <span className="value">{this.state.complaints}</span>
            </div>
            <div>
              <span className="field">History:</span>
              <span className="value">{this.state.history}</span>
            </div>
            <div>
              <span className="field">Diagnosis:</span>
              <span className="value">{this.state.diagnosis}</span>
            </div>
          </div>
        </div>

        <Modal
          className="xyz"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={this.handleClose}
        >
          <Modal.Body className="mdl-body">
            {!this.state.pdf && (
              <div className="loader-cm">
                Loading Prescription...Please Wait
                <div className="Loader-box">
                  <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </div>
              </div>
            )}
            <Iframe
              url={this.state.pdf}
              width="700px"
              height="700px"
              id="myId"
              className="myClassname"
              display="initial"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default QuickView;

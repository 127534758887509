import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import CurrentAppointment from "../../../CurrentAppointment";
import { appointmentsTodayTable, allCallsRows } from "../../NewCols";
import { DateGroup } from "../../DashboardPage2";
import { AllAppointmentsSummaryTitleContainer, TitleSpan } from "./styles";
import { MaterialTableWrapper } from "../common/MaterialTableWrapper";

const allCallsColumns = [
  ...appointmentsTodayTable,
  ...allCallsRows,
  {
    title: "",
    render: data => <CurrentAppointment appointmentData={data} />
  }
];

export default function AllAppointmentsWrapper({ data }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, []);
  return (
    <MaterialTableWrapper>
      <MaterialTable
        title={<AllAppointmentsTitle />}
        columns={allCallsColumns}
        data={data.appointmentsPendingTable || []}
        page={10}
        options={{
          search: false,
          paging: false,
          sorting: false,
          draggable: false,
          headerStyle: {
            backgroundColor: "transparent",
            color: "#000",
            fontSize: "14px",
            paddingBottom: "33px",
            border: "none"
          },

          rowStyle: {
            backgroundColor: "white",
            width: "min-content",
            padding: "20px"
          },
          cellStyle: {
            fontSize: matches ? "13px" : "15px",
            backgroundColor: "white",
            color: "#6C6C6C"
          }
        }}
        style={{
          margin: "10px",
          padding: "10px",
          marginTop: "0px",
          paddingTop: "0px",
          backgroundColor: "transparent",
          boxShadow: "none"
        }}
      />
    </MaterialTableWrapper>
  );
}

function AllAppointmentsTitle() {
  return (
    <AllAppointmentsSummaryTitleContainer>
      <TitleSpan>All Appointments</TitleSpan>
      <DateGroup />
    </AllAppointmentsSummaryTitleContainer>
  );
}

import React, { useState, useEffect } from "react";
import Overview from "../NewDoctorDashboard/Overview";
import styles from "./styles.module.scss";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import axios from "axios";
import Combobox from "react-widgets/lib/Combobox";

const AdminOverview = () => {
  const [doctorId, setDoctorId] = useState(0);
  const [doctorName, setDoctorName] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [isDoctorComboDisabled, setIsDoctorComboDisabled] = useState(false);

  useEffect(() => {
    axios
      .post(CONFIG_CONSTANTS.url.DOCTOR_LIST_URL, {})
      .then(response => {
        setDoctors(
          [
            { doctor_id: 0, doctor_name: "ALL" },
            ...(response?.data?.results || [])
          ].map(doctor => ({
            name: doctor.doctor_name,
            value: doctor.doctor_id
          }))
        );
      })
      .catch(err => {
        console.log("ERROR IN get doctors", err);
      });
  }, []);

  return (
    <div className={styles["admin-overview"]}>
      <div className={styles["doctor-option-section"]}>
        <div className={styles["input-label"]}>Doctor</div>
        <div className={styles["dropdown-wrapper"]}>
          <Combobox
            containerClassName="search-combobox"
            hideCaret={true}
            placeholder="Search Doctor"
            data={doctors}
            onChange={doctor => {
              setDoctorId(doctor.value);
              setDoctorName(doctor.name);
            }}
            filter="contains"
            textField="name"
            value={doctorName}
            disabled={isDoctorComboDisabled}
          />
        </div>
      </div>
      <Overview
        doctorId={doctorId}
        isAdmin={true}
        setDoctorComboDisabled={setIsDoctorComboDisabled}
      />
    </div>
  );
};

export default AdminOverview;

import React from "react";

const ImageSlide = ({ url }) => {
  const styles = {
    backgroundImage: `url(${url})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "300px"
  };

  return <div className="image-slide" style={styles}></div>;
};

export default ImageSlide;

import React from "react";

function LoadingLogin({ message }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "2rem"
      }}
    >
      Loading.... {message}
    </div>
  );
}

export default LoadingLogin;

import { DeleteOutline, OpenWith } from "@material-ui/icons";
import React from "react";
import "./styles.scss";
import { store } from "../../../../../../reducers/Persist";

const RecommendedTableCard = ({ product, index }) => {
  const dispatch = store.dispatch;
  const handleDelete = e => {
    e.preventDefault();
    dispatch({
      type: "DELETE_RECOMMENDED_PRODUCT",
      payload: index
    });
  };
  const handleTransfer = e => {
    e.preventDefault();
    dispatch({
      type: "ADD_TOP_PRODUCT",
      payload: { ...product }
    });
    dispatch({
      type: "DELETE_RECOMMENDED_PRODUCT",
      payload: index
    });
  };
  return (
    <div className="recommendedTableCard">
      <div className="recommendedTableCard_product">
        <div
          className="RTC_product_img"
          style={{ backgroundImage: `url(${product.photos})` }}
        >
          {/* <img src={product.photos} alt={"product_image"} /> */}
        </div>
        <div className="RTC_product_desc">
          <h4 title={product.name}>{product.name}</h4>
          <div title={"Units: " + product?.usage_unit?.[0]}>
            Units: {product?.usage_unit?.[0] || "NA"}
          </div>
          {product?.usage_frequency?.[0] ? (
            <div title={product?.usage_frequency?.[0]}>
              {product?.usage_frequency?.[0]}
            </div>
          ) : null}
        </div>
      </div>
      <div className="recommendedTableCard_days" title={product.days || 30}>
        <p>{product.days || 30}</p>
      </div>
      <div
        className="recommendedTableCard_cartQty"
        title={product.cart_quantity}
      >
        <p>{product.cart_quantity}</p>
      </div>
      <div
        className="recommendedTableCard_instructions"
        title={product?.usage_instructions?.[0]}
      >
        <p>{product?.usage_instructions?.[0] || "NA"}</p>
      </div>
      <div
        className="recommendedTableCard_ingredients"
        title={product.ingredients || "NA"}
      >
        {product.doc_ingredients?.length > 0
          ? product.doc_ingredients?.map((val, index) => <p>{val}</p>)
          : "NA"}
      </div>
      <div className="recommendedTableCard_modeOfAction">
        {product.doc_mode_of_action?.length > 0
          ? product.doc_mode_of_action?.map((val, index) => (
              <p key={index} title={val}>
                {val}
              </p>
            ))
          : "NA"}
      </div>
      <div className="recommendedTableCard_expectationSetting">
        <div className="RTC_expectationSetting_left">
          {product.doc_expectation_setting?.length > 0
            ? product.doc_expectation_setting?.map((val, index) => (
                <p key={index} title={val}>
                  {val}
                </p>
              ))
            : "-"}
        </div>
        <div className="RTC_expectationSetting_right">
          <div onClick={handleTransfer}>
            <OpenWith className="RTC-openWithIcon" />
          </div>
          <div onClick={handleDelete}>
            <DeleteOutline className="RTC-deleteIcon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedTableCard;

import styled from "styled-components";

export const MaterialTableWrapper = styled.div`

.MuiTableRow-root.MuiTableRow-head {
    th:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    th:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  .MuiTableHead-root:after {
    content: "";
    display: block;
    height: 0.5em;
    width: 100%;
    background: transparent;
  }

  .MuiTableBody-root tr {
    td:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    td:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
`
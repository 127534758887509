import React from "react";
import "./styles.scss";
import ConcernsCardChild from "./ConcernsCardChild";

const ConcernsCard = () => {
  return (
    <div className="concernsCard__wrapper">
      <h4 className="concernsCard__title">Concerns</h4>
      <div className="concernsCard__container">
        <ConcernsCardChild />
      </div>
    </div>
  );
};

export default ConcernsCard;

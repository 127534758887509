import React, { useState } from "react";
import {
  OptionButtonWrapper,
  DurationOptionsListWrapper,
  ConfirmButtonWrapper,
  OptionWrapper,
  AttemptLeftWrapper
} from "./styles";
import CONSTANTS from "./constants";
import noop from "lodash/noop";

const OptionButton = ({
  stepAway,
  updateStepAway = noop,
  loading = false,
  disabled = false,
  durationOptions = [],
  attemptsLeft = 0
}) => {
  const CONSTANT_DETAILS = stepAway ? CONSTANTS?.STEP_AWAY : CONSTANTS?.STEP_IN;
  const { PRIMARY_COLOR, SECONDARY_COLOR, BUTTON_ICON, BUTTON_TEXT } =
    CONSTANT_DETAILS;
  const [showDurationOptions, setShowDurationOptions] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(0);
  return (
    <>
      <OptionButtonWrapper
        onClick={() => !stepAway && updateStepAway(stepAway)}
        primaryColor={PRIMARY_COLOR}
        secondaryColor={SECONDARY_COLOR}
        disabled={loading || disabled}
        onMouseEnter={() => setShowDurationOptions(stepAway)}
        onMouseLeave={() => setShowDurationOptions(false)}
      >
        {BUTTON_ICON}
        <span>{BUTTON_TEXT}</span>
        {attemptsLeft > 0 ? (
          <AttemptLeftWrapper>{attemptsLeft}</AttemptLeftWrapper>
        ) : null}

        <DurationOptionsList
          durationOptions={durationOptions}
          showDurationOptions={showDurationOptions}
          setSelectedDuration={setSelectedDuration}
          selectedDuration={selectedDuration}
          onConfirm={() => updateStepAway(stepAway, selectedDuration)}
        />
      </OptionButtonWrapper>
    </>
  );
};

const DurationOptionsList = ({
  durationOptions = [],
  showDurationOptions = false,
  selectedDuration = 0,
  setSelectedDuration = noop,
  onConfirm = noop
}) => {
  const { CONFIRM } = CONSTANTS;
  return (
    <DurationOptionsListWrapper show={showDurationOptions}>
      <OptionWrapper>
        {durationOptions?.map(option => {
          return (
            <button
              type="button"
              onClick={() => setSelectedDuration(option.duration)}
              disabled={option.disable}
            >
              <span>{option.title}</span>
              <input
                name="duration"
                type="radio"
                checked={selectedDuration === option.duration}
                disabled={option.disable}
              />
            </button>
          );
        })}
      </OptionWrapper>
      <ConfirmButtonWrapper
        primaryColor={CONFIRM.PRIMARY_COLOR}
        secondaryColor={CONFIRM.SECONDARY_COLOR}
        disabled={!selectedDuration}
        onClick={onConfirm}
      >
        <span>{CONFIRM.BUTTON_TEXT}</span>
      </ConfirmButtonWrapper>
    </DurationOptionsListWrapper>
  );
};

export default OptionButton;

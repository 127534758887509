import React, { useMemo } from "react";
import MaterialTable from "material-table";
import moment from "moment";
import { Button } from "react-bootstrap";

export default function HeaderActionTable({
  title,
  cols,
  rows,
  pageSize,
  search,
  pagin,
  fromMonth,
  setFromMonth,
  interval,
  setInterval
}) {
  return (
    <div id={title.replace(/\s/g, "").toLowerCase()}>
      <MaterialTable
        columns={cols}
        data={useMemo(() => rows, [rows])}
        title={title}
        options={{
          exportButton: true,
          exportAllData: true,
          pageSize: pageSize ? pageSize : 1,
          paging: pagin === undefined ? true : false,
          search: search ? true : false,
          sorting: false,
          headerStyle: {
            backgroundColor: "#003459",
            color: "#FFF"
          }
        }}
        components={{
          Action: props => (
            <div
              style={{
                textAlign: "left",
                float: "left"
              }}
            >
              <Button
                style={{ margin: "2px 5px" }}
                variant={`${interval === "realtime" ? "outline-" : ""}dark`}
                onClick={() => {
                  setInterval("history");
                  setFromMonth(moment().subtract(1, "month").format("YYYY-MM"));
                }}
              >
                History
              </Button>
              <Button
                style={{ margin: "2px 5px" }}
                variant={`${interval === "history" ? "outline-" : ""}info`}
                onClick={() => {
                  setInterval("realtime");
                  setFromMonth(moment().add(1, "month").format("YYYY-MM"));
                }}
              >
                Realtime
              </Button>
              <input
                type="month"
                className="input"
                value={fromMonth}
                max={
                  interval === "history"
                    ? moment().subtract(1, "month").format("YYYY-MM")
                    : null
                }
                min={
                  interval === "realtime"
                    ? moment().add(1, "month").format("YYYY-MM")
                    : null
                }
                // max={moment().subtract(1,'month').format("YYYY-MM")}
                onChange={evt => setFromMonth(evt.target.value)}
              />
            </div>
          )
        }}
        actions={[
          {
            onClick: (event, rowData) => alert("something "),
            isFreeAction: true
            // onClick: (event, rowData) => alert("You saved " + rowData.name),
          }
        ]}
        style={{ margin: "20px 10px 10px" }}
      />
    </div>
  );
}

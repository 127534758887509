import React, { Component } from "react";
import "./RecommendationsForLifestyle.scss";
import RxWriterInputBox from "../../common/RxWriterInputBox/RxwriterInputBox";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
class RecommendationsForLifestyle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lifestyleData: [],
      specialConsultData: [],
      suggestDiagnosticsData: [],
      suggestProcedureData: []
    };
  }
  componentDidMount() {
    let lifestyleData = [],
      specialConsultData = [],
      suggestDiagnosticsData = [],
      suggestProcedureData = [];
    for (let i = 0; i < this.props.presetData?.length; i++) {
      if (this.props.presetData[i].brand === this.props.brand) {
        switch (this.props.presetData[i].observation_type) {
          case "lifestyle":
            lifestyleData.push(this.props.presetData[i]);
            break;
          case "special_consult":
            specialConsultData.push(this.props.presetData[i]);
            break;
          case "suggest_diagnostics":
            suggestDiagnosticsData.push(this.props.presetData[i]);
            break;
          case "suggest_procedure":
            suggestProcedureData.push(this.props.presetData[i]);
            break;
          default:
            continue;
        }
      }
    }
    this.setState({
      lifestyleData,
      specialConsultData,
      suggestDiagnosticsData,
      suggestProcedureData
    });
  }
  handlePresetSelectLifestyle = value => {
    store.dispatch({
      type: "SET_LIFESTYLE_PRETEXT",
      payload: value
    });
  };
  handlePresetSelectspecialConsult = value => {
    store.dispatch({
      type: "SET_SPECIAL_CONSULT_PRETEXT",
      payload: value
    });
  };
  handlePresetSelectSuggestDiagnostics = value => {
    store.dispatch({
      type: "SET_SUGGEST_DIAGNOSTICS_PRETEXT",
      payload: value
    });
  };
  handlePresetSelectSuggestProcedure = value => {
    store.dispatch({
      type: "SET_SUGGEST_PROCEDURE_PRETEXT",
      payload: value
    });
  };

  render() {
    return (
      <div>
        <div className="title">Additional Recommendations</div>
        <div className="margin-30 RecommendationsForLifestyle">
          <div className="recommendation-item">
            <RxWriterInputBox
              headerTitle="Lifestyle"
              mainPresetData={this.state.lifestyleData}
              onChangeInput={text =>
                store.dispatch({ type: "SET_LIFESTYLE_TEXT", payload: text })
              }
              presetValue={this.props.lifestyleData.pretext}
              textValue={this.props.lifestyleData.text}
              inputPlaceHolder="Click to start typing"
              inputBoxHeight={100}
              style={{ marginTop: "20px" }}
              onPresetSelect={value => this.handlePresetSelectLifestyle(value)}
            />
          </div>
          <div className="recommendation-item">
            <RxWriterInputBox
              headerTitle="Specialist Consult"
              mainPresetData={this.state.specialConsultData}
              onChangeInput={text =>
                store.dispatch({
                  type: "SET_SPECIAL_CONSULT_TEXT",
                  payload: text
                })
              }
              presetValue={this.props.specialConsultData.pretext}
              textValue={this.props.specialConsultData.text}
              inputPlaceHolder="Click to start typing"
              inputBoxHeight={100}
              onPresetSelect={value =>
                this.handlePresetSelectspecialConsult(value)
              }
            />
          </div>
          <div className="recommendation-item">
            <RxWriterInputBox
              headerTitle="Suggest Diagnostics"
              mainPresetData={this.state.suggestDiagnosticsData}
              onChangeInput={text =>
                store.dispatch({
                  type: "SET_SUGGEST_DIAGNOSTICS_TEXT",
                  payload: text
                })
              }
              presetValue={this.props.suggestDiagnosticsData.pretext}
              textValue={this.props.suggestDiagnosticsData.text}
              inputPlaceHolder="Click to start typing"
              inputBoxHeight={100}
              style={{ marginBottom: "20px" }}
              onPresetSelect={value =>
                this.handlePresetSelectSuggestDiagnostics(value)
              }
            />
          </div>
          <div className="recommendation-item">
            <RxWriterInputBox
              headerTitle="Suggest Procedure"
              mainPresetData={this.state.suggestProcedureData}
              onChangeInput={text =>
                store.dispatch({
                  type: "SET_SUGGEST_PROCEDURE_TEXT",
                  payload: text
                })
              }
              presetValue={this.props.suggestProcedureData.pretext}
              textValue={this.props.suggestProcedureData.text}
              inputPlaceHolder="Click to start typing"
              inputBoxHeight={100}
              style={{ marginBottom: "20px" }}
              onPresetSelect={value =>
                this.handlePresetSelectSuggestProcedure(value)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapSateToProps = state => {
  const id = store.getState().rxWriterReducer.id;
  return {
    presetData: store.getState().rxWriterReducer.presetData,
    lifestyleData: store.getState().observationReducer[id].lifestyle,
    specialConsultData: store.getState().observationReducer[id].specialConsult,
    suggestDiagnosticsData:
      store.getState().observationReducer[id].suggestDiagnostics,
    suggestProcedureData:
      store.getState().observationReducer[id].suggestProcedure,
    brand: store.getState().rxWriterReducer.brand
  };
};
export default connect(mapSateToProps)(RecommendationsForLifestyle);

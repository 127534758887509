import React, { Component } from "react";
import "./ListInput.scss";
import AddIcon from "@material-ui/icons/Check";
import { IconButton } from "@material-ui/core";
export default class ListInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
    this.addHandler = value => {
      if (this.state.text.length > 0) {
        this.props.onAdd(this.state.text);
      }
      this.setState({ text: "" });
    };
    this.onInputHandler = evt => {
      if (evt.keyCode === 13) {
        const value = evt.target.value.trim();
        this.addHandler(value);
      }
    };
    this.onRemoveClickHandler = evt => {
      const index = Number(evt.target.id);
      props.onRemove(index);
    };
  }
  render() {
    let placeholder = this.props.placeholder;
    return (
      <div>
        <div className="list-input">
          <div
            style={{
              fontWeight: "bold",
              fontSize: "14px",
              color: "rgb(80,80,80)"
            }}
            hidden={this.props.collections.length === 0}
          >
            {this.props.listTitle}
          </div>
          <ul className="collection-list">
            {this.props.collections.map((item, index) => {
              return (
                <li key={index} className="collection-li">
                  <div>{item}</div>{" "}
                  <button
                    type="button"
                    id={index}
                    className="collection-remove-button"
                    onClick={this.onRemoveClickHandler}
                    style={{ outline: "none" }}
                  >
                    X
                  </button>
                </li>
              );
            })}
          </ul>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              className="form-control"
              onKeyDown={this.onInputHandler}
              placeholder={placeholder}
              value={this.state.text}
              onChange={evt => {
                this.setState({ text: evt.target.value });
              }}
            />
            <IconButton
              color=""
              aria-label="Go"
              onClick={this.addHandler}
              style={{
                margin: "3px",
                height: "32px",
                width: "32px",
                outline: "none",
                color: "rgb(0,190,150)"
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";
import "./styles.scss";
import { store, persistor } from "../../../../../reducers/Persist";
import RxWriterInputBox from "../../../common/RxWriterInputBox/RxwriterInputBox";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import moment from "moment";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants";

const ConcernsCardChild = props => {
  const brand = useSelector(state => state.rxWriterReducer.brand);
  const apptId = useSelector(state => state.rxWriterReducer.id);
  const presetData = useSelector(state => state.rxWriterReducer.presetData);
  const concerns = useSelector(
    state => state.observationReducer[apptId].concerns
  );
  const concernsCheck = useSelector(
    state => state.observationCheckReducer.concerns
  );
  const presetIdToObj = useSelector(
    state => state.rxWriterReducer.presetIdToObj
  );
  const [presetConcernsData, setPresetConcernsData] = useState([]);
  const [complaintCategory, setComplaintCategory] = useState([]);

  useEffect(() => {
    let arrData = [],
      category = [];
    for (const i in presetData) {
      if (brand === presetData[i].brand) {
        if (presetData[i].observation_type === "concern") {
          arrData.push(presetData[i]);
        } else if (presetData[i].observation_type === "category") {
          category.push(presetData[i]);
        }
      }
    }
    setPresetConcernsData(arrData);
    setComplaintCategory(category);
  }, [presetData, brand]);
  const handlePresetSelect = (index, value) => {
    store.dispatch({
      type: "SET_ARRAY_OBJECT_CHECK",
      payload: {
        category: "concerns",
        index: index,
        value: false,
        key: "text"
      }
    });
    store.dispatch({
      type: "SET_CONCERN_DATA",
      payload: {
        index: index,
        value: value,
        key: "pretext"
      }
    });
  };
  const handleSeveritySelect = (index, value) => {
    store.dispatch({
      type: "SET_ARRAY_OBJECT_CHECK",
      payload: {
        category: "concerns",
        index: index,
        value: false,
        key: "severity"
      }
    });
    store.dispatch({
      type: "SET_CONCERN_METADATA",
      payload: {
        index: index,
        value: value,
        key: "severity"
      }
    });
  };

  const handleDateSelect = (index, key, value) => {
    store.dispatch({
      type: "SET_CONCERN_METADATA",
      payload: {
        index: index,
        key: key,
        value: value
      }
    });
  };

  const handleDelete = e => {
    e.preventDefault();
    store.dispatch({ type: "DELETE_CONCERNS_CHECK" });
    store.dispatch({ type: "DELETE_CONCERN" });
  };
  const handleAdd = e => {
    e.preventDefault();
    store.dispatch({ type: "ADD_CONCERNS_CHECK" });
    store.dispatch({ type: "ADD_CONCERN" });
    addToGTM({
      event: GTM_EVENTS?.CONCERN_ADDED
    });
  };

  return (
    <div className="concernsCard">
      {concerns?.map((concern, index) => (
        <div key={index} className="concers_content">
          <div className="concers_row">
            <div className="concerns_row_left">
              <div style={{ display: "flex" }}>
                <p>Category of Complaint</p>
                <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <select
                  onChange={e => {
                    store.dispatch({
                      type: "SET_ARRAY_OBJECT_CHECK",
                      payload: {
                        category: "concerns",
                        index: index,
                        value: false,
                        key: "category"
                      }
                    });
                    store.dispatch({
                      type: "SET_CONCERN_METADATA",
                      payload: {
                        index: index,
                        value: e.target.value,
                        key: "category"
                      }
                    });
                    if (
                      presetIdToObj[e.target.value]?.observation_preset_text ===
                      "PCOS"
                    ) {
                      store.dispatch({
                        type: "SET_ARRAY_OBJECT_CHECK",
                        payload: {
                          category: "concerns",
                          index: index,
                          value: false,
                          key: "severity"
                        }
                      });
                    }
                  }}
                  style={{
                    border: concernsCheck[index].category
                      ? "1px solid #E86669"
                      : "none"
                  }}
                >
                  <option>-select-</option>
                  {complaintCategory.map(val => (
                    <option
                      value={val.id}
                      key={val.id}
                      selected={concern.metadata.category === val.id.toString()}
                    >
                      {val.observation_preset_text}
                    </option>
                  ))}
                </select>
                {concernsCheck[index].category && (
                  <div style={{ color: "#E86669", marginTop: "2px" }}>
                    This field cannot be left blank
                  </div>
                )}
              </div>
            </div>
            <div className="concerns_row_right">
              <RxWriterInputBox
                headerTitle="Chief Complaints"
                isRequired={true}
                mainPresetData={presetConcernsData}
                onChangeInput={text => {
                  store.dispatch({
                    type: "SET_ARRAY_OBJECT_CHECK",
                    payload: {
                      category: "concerns",
                      index: index,
                      value: false,
                      key: "text"
                    }
                  });
                  store.dispatch({
                    type: "SET_CONCERN_DATA",
                    payload: {
                      index: index,
                      value: text,
                      key: "text"
                    }
                  });
                }}
                presetValue={concern?.pretext}
                textValue={concern?.text}
                inputPlaceHolder="Please start type here"
                inputBoxHeight={60}
                onPresetSelect={value => handlePresetSelect(index, value)}
                alert={concernsCheck[index].text}
              />
            </div>
          </div>
          <div className="concers_row">
            <div className="concerns_row_left">
              <div style={{ display: "flex" }}>
                <p>Severity</p>
                {(!concern.metadata.category ||
                  (concern.metadata.category &&
                    presetIdToObj[concern.metadata.category]
                      ?.observation_preset_text !== "PCOS")) && (
                  <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <select
                  onChange={e => handleSeveritySelect(index, e.target.value)}
                  style={{
                    border: concernsCheck[index].severity
                      ? "1px solid #E86669"
                      : "none"
                  }}
                >
                  <option>select</option>
                  <option
                    selected={concern.metadata.severity === "low"}
                    value="low"
                  >
                    Low
                  </option>
                  <option
                    selected={concern.metadata.severity === "moderate"}
                    value="moderate"
                  >
                    Moderate
                  </option>
                  <option
                    selected={concern.metadata.severity === "high"}
                    value="high"
                  >
                    High
                  </option>
                  <option
                    selected={concern.metadata.severity === "critical"}
                    value="critical"
                  >
                    Critical
                  </option>
                </select>
                {concernsCheck[index].severity && (
                  <div style={{ color: "#E86669", marginTop: "2px" }}>
                    This field cannot be left blank
                  </div>
                )}
              </div>
            </div>
            <div className="concerns_row_right">
              <div style={{ display: "flex" }}>
                <p>Onset Since</p>
                <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
              </div>
              <div />
              <div className="concerns_row_right_inputs">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <input
                    type="number"
                    placeholder="NN"
                    value={concern.metadata?.onsetValue || ""}
                    onChange={e => {
                      store.dispatch({
                        type: "SET_ARRAY_OBJECT_CHECK",
                        payload: {
                          category: "concerns",
                          index: index,
                          value: false,
                          key: "onsetValue"
                        }
                      });
                      handleDateSelect(index, "onsetValue", e.target.value);
                    }}
                    className="day"
                    style={{
                      border: concernsCheck[index].onsetValue
                        ? "1px solid #E86669"
                        : "none"
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <select
                    onChange={e => {
                      store.dispatch({
                        type: "SET_ARRAY_OBJECT_CHECK",
                        payload: {
                          category: "concerns",
                          index: index,
                          value: false,
                          key: "onsetDuration"
                        }
                      });
                      handleDateSelect(index, "onsetDuration", e.target.value);
                    }}
                    className="onset-month"
                    style={{
                      border: concernsCheck[index].onsetDuration
                        ? "1px solid #E86669"
                        : "none"
                    }}
                  >
                    <option value="select" selected disabled hidden>
                      {" "}
                      -Select-{" "}
                    </option>
                    <option
                      selected={concern.metadata.onsetDuration === "DAYS"}
                      value="DAYS"
                    >
                      Days
                    </option>
                    <option
                      selected={concern.metadata.onsetDuration === "WEEKS"}
                      value="WEEKS"
                    >
                      Weeks
                    </option>
                    <option
                      selected={concern.metadata.onsetDuration === "MONTHS"}
                      value="MONTHS"
                    >
                      Months
                    </option>
                    <option
                      selected={concern.metadata.onsetDuration === "YEARS"}
                      value="YEARS"
                    >
                      Years
                    </option>
                  </select>
                </div>
              </div>
              {(concernsCheck[index].onsetValue ||
                concernsCheck[index].onsetDuration) && (
                <div style={{ color: "#E86669", marginTop: "2px" }}>
                  This field cannot be left blank
                </div>
              )}
            </div>
          </div>
          {index > 0 ? (
            <button
              className="concernsCard__deleteBtn"
              onClick={e => handleDelete(e, index)}
            >
              Delete
            </button>
          ) : (
            <></>
          )}
        </div>
      ))}
      <button className="concernsCard__addBtn" onClick={handleAdd}>
        Add Concerns +
      </button>
    </div>
  );
};
const mapStateToProps = () => {
  return {
    presetIdToObj: store.getState().rxWriterReducer.presetIdToObj
  };
};
export default connect(mapStateToProps)(ConcernsCardChild);

import React, { Component } from "react";
import { Button, Form, Modal, Col } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import Axios from "axios";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import config from "../../../constants/apiList";
import Scheduler from "../../core/Scheduler/Scheduler";
import "./RescheduleAppointment.scss";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import axios from "axios";
import Loader from "react-loader-spinner";
import Combobox from "react-widgets/lib/Combobox";

var language = [
  "English",
  "Hindi",
  "Tamil",
  "Telugu",
  "Kannada",
  "Malayalam",
  "Marathi",
  "Bengali"
];

export default class RescheduleAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorList: [],
      filteredDoctorList: [],
      originalDoctorId: "",
      selectedDoctorId: null,
      searchQuery: "",
      appointment_id: "",
      renderScheduler: false,
      anyDoctor: false,
      shouldHide: true,
      noAppointment: false,
      loading: false,
      appointmentData: {
        doctor_id: "",
        appointment_label: "",
        categories: "",
        email: "",
        phone: "",
        name: "",
        brand: "",
        source: "",
        type: { value: "" },
        appointment_date: "",
        slot_start_time: "",
        slot_end_time: "",
        from_time: "",
        to_time: ""
      },
      language: "English",
      app: false,
      paid: false
    };
  }
  async componentDidMount() {
    axios
      .post(CONFIG_CONSTANTS.url.GET_DOCTORS_BY_CATEGORY, {})
      .then(async response => {
        const doctorList = response.data.results;
        this.setState({ doctorList: doctorList });
        if (this.props.appointmentId) {
          const appointmentLabel = this.props.appointmentId;
          const appointmentId =
            this.props.appointmentId.split("-")[2] ||
            this.props.appointmentId.split("-")[0];
          await this.fetchAppointmentDetails(appointmentId);
          this.setState({
            searchQuery: appointmentLabel,
            appointment_id: appointmentId
          });
        } else {
          this.setState({ filteredDoctorList: doctorList });
        }
      });
  }
  filterDoctors = (brand, language, paid, app) => {
    brand = brand || this.state.appointmentData.brand;
    language = language || this.state.language;
    paid = paid || this.state.paid;
    //console.log("HI"+paid);
    app = app || this.state.app;
    let filteredList = [];
    let filteredListBasedOnPaid = [];
    let filteredListBasedOnLanguage = [];
    let filteredListBasedOnApp = [];
    if (brand) {
      this.state.doctorList.forEach(doctor => {
        if (brand == "MM" && doctor.is_men == 1) filteredList.push(doctor);
        if (brand == "BW" && doctor.is_women == 1) filteredList.push(doctor);
        if (brand == "LJ" && doctor.is_men == 0 && doctor.is_women == 0)
          filteredList.push(doctor);
      });
    } else {
      filteredList = this.state.doctorList;
    }
    filteredList.forEach(doctor => {
      if (paid == true && doctor.paid == 1) {
        filteredListBasedOnPaid.push(doctor);
      }
      if (paid == false && doctor.paid != 1)
        filteredListBasedOnPaid.push(doctor);
    });
    filteredListBasedOnPaid.forEach(doctor => {
      if (doctor.languages) {
        const languageData = doctor.languages.split(",");
        if (languageData.indexOf(language) != -1) {
          filteredListBasedOnLanguage.push(doctor);
        }
      }
    });
    filteredListBasedOnLanguage.forEach(doctor => {
      if (app && doctor.app == 1) filteredListBasedOnApp.push(doctor);
      else if (app == false && (doctor.web_app == null || doctor.web_app == 1))
        filteredListBasedOnApp.push(doctor);
    });

    let newFilteredList = filteredListBasedOnApp.map(doctor => {
      return {
        name: doctor.doctor_name,
        value: doctor.doctor_id
      };
    });
    this.setState({
      filteredDoctorList: filteredList,
      filteredListBasedOnPaid,
      filteredListBasedOnLanguage,
      filteredListBasedOnApp,
      newFilteredList
    });
  };
  handleFormSubmit = async e => {
    e.preventDefault();
    await this.fetchAppointmentDetails(this.state.appointment_id);
    const pathPrefix = window.location.href.match(
      /.*(rescheduleAppointment)/
    )[0];
    const newPath = `${pathPrefix}/${this.state.searchQuery}`;
    window.history.pushState({ path: newPath }, "", newPath);
  };
  async fetchAppointmentDetails(appointmentId) {
    const reqBody = {
      appointment_id: appointmentId
    };
    // this.setState({loading: true});
    const appointmentResult = await Axios.post(
      config.url.GET_APPOINTMENT_DETAILS,
      reqBody
    );
    // this.setState({loading: false});
    if (!appointmentResult.data.result.success) {
      console.log(
        "Error in fetching appointment details",
        appointmentResult.data.result.data
      );
      this.setState({ noAppointment: true, shouldHide: false });
      return false;
    }
    const appointment = appointmentResult.data.result.data;
    console.log("---appointment---", appointment);
    const doctorObj = this.state.doctorList.find(
      doctor => doctor.doctor_id == appointment.doctor_id
    );
    const appointmentData = {
      appointment_id: appointment.id,
      appointment_label: `${appointment.brand}-${appointment.source}-${appointment.id}`,
      doctor_id: appointment.doctor_id,
      doctor_name: doctorObj ? doctorObj.doctor_name : "",
      categories: appointment.category,
      type: { value: appointment.source },
      email: appointment.email,
      phone: appointment.phone,
      name: appointment.patient_name,
      brand: appointment.brand,
      source: appointment.source,
      appointment_date: appointment.appointment_date,
      slot_start_time: moment(appointment.from_time, "hh:mm:ss").format(
        "h:mm A"
      ),
      slot_end_time: moment(appointment.to_time, "hh:mm:ss").format("h:mm A"),
      from_time: appointment.from_time,
      to_time: appointment.to_time
    };
    this.renderScheduler = true;
    this.filterDoctors(
      appointment.brand,
      this.state.language,
      this.state.paid,
      this.state.app
    );
    this.setState({
      noAppointment: false,
      appointmentData: appointmentData,
      originalDoctorId: appointment.doctor_id,
      selectedDoctorId: appointment.doctor_id,
      appointment_id: appointment.id,
      shouldHide: false
      //app: appointment.app,
      //language: appointment.language
    });
  }
  handleDoctorRadio = event => {
    if (event.target.value === "specific") {
      this.setState({
        anyDoctor: false,
        selectedDoctorId: this.state.originalDoctorId
      });
    }
    if (event.target.value === "any") {
      this.setState({ anyDoctor: true });
    }
  };
  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.paid !== prevState.paid ||
      this.state.app !== prevState.app ||
      this.state.language !== prevState.language
    ) {
      this.filterDoctors(
        this.state.appointmentData.brand,
        this.state.language,
        this.state.paid,
        this.state.app
      );
    }
  }

  setLanguage = e => {
    this.setState({
      language: e.target.value
    });
  };

  render() {
    return (
      <>
        <div style={{ padding: "2%", display: "flex" }}>
          <div style={{ margin: "auto", width: "40%" }}>
            <h4
              className="sectionHeading"
              style={{ color: "#3f51b5", marginTop: "15px" }}
            >
              Reschedule Appointment
            </h4>
            <form id="searchform" onSubmit={this.handleFormSubmit}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>Enter Appointment Id</div>
                <input
                  className="searchBoxInput"
                  style={{ textTransform: "uppercase" }}
                  type="text"
                  onChange={e =>
                    this.setState({
                      searchQuery: e.target.value,
                      appointment_id:
                        e.target.value.split("-")[2] ||
                        e.target.value.split("-")[0]
                    })
                  }
                  value={this.state.searchQuery}
                  placeholder="MM-F-1024"
                />
                <button className="button" type="submit">
                  <BsSearch size="1.5em" />
                </button>
              </div>
            </form>

            {this.state.loading ? (
              <div className="Loader-box" style="display:flex">
                <div style="margin:auto">
                  <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </div>
              </div>
            ) : (
              <>
                <div
                  style={this.state.shouldHide ? { display: "none" } : {}}
                  className="originalAppointment"
                >
                  {this.state.noAppointment ? (
                    "No such appointment found"
                  ) : (
                    <>
                      Appointment{" "}
                      <b>{this.state.appointmentData.appointment_label}</b> is
                      scheduled with{" "}
                      <b>{this.state.appointmentData.doctor_name}</b> on{" "}
                      <b>{this.state.appointmentData.appointment_date}</b> at{" "}
                      <b>
                        {" "}
                        {this.state.appointmentData.slot_start_time} -{" "}
                        {this.state.appointmentData.slot_end_time}
                      </b>
                    </>
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <div className="paid-button-group">
                    <div
                      onClick={() => this.setState({ app: false })}
                      style={{ padding: "4px 8px" }}
                      className={
                        !this.state.app
                          ? `brand-filter-button brand-filter-all active`
                          : `brand-filter-button brand-filter-all`
                      }
                    >
                      WEB
                    </div>
                    <div
                      onClick={() => this.setState({ app: true })}
                      style={{ padding: "4px 8px" }}
                      className={
                        this.state.app
                          ? `brand-filter-button brand-filter-mm active`
                          : `brand-filter-button brand-filter-mm`
                      }
                    >
                      APP
                    </div>
                  </div>
                  <div className="paid-button-group paid-group">
                    <div
                      onClick={() => this.setState({ paid: false })}
                      style={{ padding: "4px 8px" }}
                      className={`paid-button free ${
                        !this.state.paid ? "active" : ""
                      }`}
                    >
                      FREE
                    </div>
                    <div
                      onClick={() => this.setState({ paid: true })}
                      style={{ padding: "4px 8px" }}
                      className={`paid-button paid ${
                        this.state.paid ? "active" : ""
                      }`}
                    >
                      PAID
                    </div>
                  </div>
                </div>
                <div>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      as="select"
                      type="language"
                      placeholder="Enter Language"
                      defaultValue={this.state.language}
                      value={this.state.language}
                      name="language"
                      onChange={this.setLanguage}
                    >
                      {language.map((item, index) => {
                        return (
                          <option key={index} value={item.label}>
                            {item}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="anyDoctor">
                  <div
                    onChange={e => {
                      this.handleDoctorRadio(e);
                    }}
                  >
                    <Form.Check
                      type="radio"
                      id="select_doctor"
                      name="doctorRadio"
                      value="specific"
                      label="Select Doctor"
                      defaultChecked
                    />
                    <Form.Check
                      type="radio"
                      id="any_doctor"
                      name="doctorRadio"
                      value="any"
                      label="Any Doctor"
                    />
                  </div>
                  {!this.state.anyDoctor && (
                    <Form.Group
                      as={Col}
                      controlId="formGridState"
                      style={{ padding: 0, marginBottom: 0 }}
                    >
                      <Form.Label>Doctor</Form.Label>
                      {/* <Form.Control
                        as="select"
                        value={this.state.selectedDoctorId}
                        name="doctor"
                        onChange={event => {
                          this.setState({
                            selectedDoctorId: event.target.value
                          });
                        }}
                      >
                        {this.state.filteredDoctorList.map((item, index) => {
                          return (
                            <option key={index} value={item.doctor_id}>
                              {item.doctor_name}
                            </option>
                          );
                        })}
                      </Form.Control> */}
                      <Combobox
                        containerClassName="search-combobox"
                        hideCaret={true}
                        placeholder="Type Doctor name here..."
                        data={this.state.newFilteredList} //list display
                        onChange={value => {
                          //list search typing
                          console.log("this is" + value.value);
                          this.setState({ selectedDoctorId: value.value }); //also may be last_known
                        }}
                        onSelect={val => {
                          //selection
                          this.setState({
                            ...this.state,
                            selectedDoctorId: ""
                          });
                        }}
                        onKeyPress={e => {
                          if (e.key == "Enter") {
                            this.setState({
                              ...this.state,
                              selectedDoctorId: ""
                            });
                          }
                        }}
                        filter="contains"
                        textField="name"
                        value={this.state.doctorID}
                      />
                    </Form.Group>
                  )}
                </div>
              </>
            )}
            {this.renderScheduler && (
              <div style={{ maxWidth: "100%" }}>
                <Scheduler
                  appointment_id={this.state.appointmentData.appointment_id}
                  doctorID={
                    this.state.anyDoctor ? false : this.state.selectedDoctorId
                  }
                  categories={this.state.appointmentData.categories}
                  email={this.state.appointmentData.email}
                  phone={this.state.appointmentData.phone}
                  name={this.state.appointmentData.name}
                  source={this.state.appointmentData.source}
                  brand={this.state.appointmentData.brand}
                  userId={this.state.appointment_id}
                  type={this.state.appointmentData.type}
                  closeScheduler={() => {}}
                  handleSlotUpdate={() => {}}
                  app={this.state.app}
                  paid={this.state.paid}
                  language={this.state.language}
                  anyDoctor={this.state.anyDoctor}
                  isAdmin={this.props.isAdmin}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

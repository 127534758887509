const prescription_orderSelect = (state = [], action) => {
  const { type, payload } = action;
  let temp, index, arr;
  switch (type) {
    case "ADD_PRODUCT":
      return [...state, { ...payload, edit: true }];
    case "DELETE_PRODUCT":
      temp = [...state];
      index = payload;
      temp = temp.filter((val, i) => i !== index);
      return [...temp];
    case "COMPLETELY_ADD_PRODUCT":
      index = payload;
      arr = [...state];
      arr[index] = { ...arr[index], edit: false };
      return [...arr];
    case "EDIT_PRODUCT":
      index = payload;
      arr = [...state];
      arr[index] = { ...arr[index], edit: true };
      return [...arr];
    case "CHANGE_DATA":
      index = payload.index;
      const { newData } = payload;
      const NewArr = [...state];
      NewArr[index] = { ...newData, edit: true };
      return [...NewArr];

    default:
      return [...state];
  }
};
export default prescription_orderSelect;

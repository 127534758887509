import React, { useEffect, useState, useContext, useRef } from "react";
import "./DashboardPage.scss";
import { Link, useLocation } from "react-router-dom";
import "./AdminPage.scss";

import CONFIG_CONSTANTS from "../../../constants/apiList";
import moment from "moment";
import Axios from "axios";
import PCI from "../DashboardPage/PCI";
import NewDashboard from "./NewDashboard";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AppointmentsPending from "./AppointmentsPending";
import { Button } from "react-bootstrap";
import { store } from "../../../reducers/Persist";
import YourPerformance from "../DashboardPage/YourPerformance";
import Reviews from "./V2/ReviewSummary";
import ActiveStatus from "../AdminPage/ActiveStatus";
import AppointmentsPriority from "./AppointmentsPriority";
import AppointmentsSummary from "./AppointmentsSummary";
import { Comp } from "./Comp";
import { rxOpenedClevertap } from "../../../helpers";
import { getRxWriterLink } from "../../../helpers/rx-writer.helpers";

const appointmentAction = [
  {
    icon: "launch",
    tooltip: "Open Prescription",
    onClick: (event, rowData) => {
      let param = rowData.brand + "-" + rowData.source + "-" + rowData.id;
      const oldCategories = ["SH", "HC", "OF"];
      if (oldCategories.includes(rowData.source)) {
        param = rowData.source_id;
      }
      window.open(getRxWriterLink(param), "_self");
    }
  }
];

const getColor = row => {
  let { appointment_date, from_time, to_time, status, confirmed, cancelled } =
    row;
  if (
    appointment_date === undefined ||
    from_time === undefined ||
    to_time === undefined ||
    status === undefined
  )
    return {};
  if (cancelled === 1) return { backgroundColor: "#D3D3D3" };
  let st = status.toUpperCase();
  let endStates = ["T", "C", "NI", "NS", "OP", "NA", "CN", "OTC", "RF"];
  let currentTimeStamp = ~~(+new Date() / 1000);
  let fromTimeStamp = moment(appointment_date + " " + from_time).unix();
  let toTimeStamp = moment(appointment_date + " " + to_time).unix();
  if (endStates.includes(st)) {
    return { backgroundColor: "#33FF4F" };
  }
  if (
    currentTimeStamp >= fromTimeStamp &&
    currentTimeStamp <= toTimeStamp &&
    !endStates.includes(st)
  ) {
    return { backgroundColor: "#FFA833" };
  }
  if (currentTimeStamp > toTimeStamp && !endStates.includes(st)) {
    return { backgroundColor: "#e47d7d" };
  }
  if (confirmed === 1) return { backgroundColor: "#ffd399" };
};
const getColor2 = row => {
  let { appointment_date, from_time, to_time, status, updated_dttm } = row;
  if (
    appointment_date === undefined ||
    from_time === undefined ||
    to_time === undefined ||
    status === undefined ||
    updated_dttm === undefined
  )
    return {};
  let today = new Date();
  if (moment(today).format("YYYY-MM-DD") === updated_dttm.slice(0, 10)) {
    return { backgroundColor: "#99FCA6" };
  }
  let st = status.toUpperCase();
  let endStates = ["T", "C", "NI", "NS", "OP", "NA", "CN"];
  let currentTimeStamp = ~~(+new Date() / 1000);
  let fromTimeStamp = moment(appointment_date + " " + from_time).unix();
  let toTimeStamp = moment(appointment_date + " " + to_time).unix();
  if (endStates.includes(st)) {
    return { backgroundColor: "#33FF4F" };
  }
  if (
    currentTimeStamp >= fromTimeStamp &&
    currentTimeStamp <= toTimeStamp &&
    !endStates.includes(st)
  ) {
    return { backgroundColor: "#FFA833" };
  }
  if (currentTimeStamp > toTimeStamp && !endStates.includes(st)) {
    return { backgroundColor: "#e47d7d" };
  }
};

const dateContext = React.createContext();

const PCITable = ({ brand }) => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  let firstDayOfMonth = today.slice(0, -2) + "01";
  const [date, setDate] = useState({
    startDate: firstDayOfMonth,
    endDate: today
  });
  const reqBody = {
    from_date: date.startDate,
    to_date: date.endDate
  };

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="pciTable">
        <DateGroup />
        <PCI
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          brand={brand}
        />
      </div>
    </dateContext.Provider>
  );
};

const NewDashboardView = ({ page }) => {
  const [date, setDate] = useState(() => {
    let today = new Date();
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
    return {
      startDate: today,
      endDate: today
    };
  });

  if (page || page === null || !date) return <></>;

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup />
        <NewDashboard
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
        />
      </div>
    </dateContext.Provider>
  );
};

const AppointmentsSummaryView = ({
  setCountToday,
  setCountPending,
  setCountPriority
}) => {
  const [date, setDate] = useState(() => {
    let today = new Date();
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
    return {
      startDate: today,
      endDate: today
    };
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup />
        <AppointmentsSummary
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          rowStyle={row => getColor(row)}
          setCountToday={setCountToday}
          setCountPending={setCountPending}
          setCountPriority={setCountPriority}
        />
      </div>
    </dateContext.Provider>
  );
};

const AppointmentsPendingView = ({ setCountPending }) => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  const [date, setDate] = useState({
    startDate: today,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup />
        <AppointmentsPending
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          rowStyle={row => getColor2(row)}
          setCountPending={setCountPending}
        />
      </div>
    </dateContext.Provider>
  );
};

const AppointmentsPriorityView = ({ setCountPriority }) => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  const [date, setDate] = useState({
    startDate: today,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup />
        <AppointmentsPriority
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          rowStyle={row => getColor(row)}
          setCountPriority={setCountPriority}
        />
      </div>
    </dateContext.Provider>
  );
};

const YourPerformanceView = () => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  const [date, setDate] = useState({
    startDate: today,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup />
        <YourPerformance
          reqBody={{
            from_date: date.startDate,
            to_date: date.endDate,
            doctor_id: store.getState().userReducer.hash_id
          }}
        />
      </div>
    </dateContext.Provider>
  );
};

function CheckOut({ setIsCheckedOut }) {
  return (
    <div>
      <Button
        variant="primary"
        onClick={() => {
          setIsCheckedOut(true);
          Axios.post(CONFIG_CONSTANTS.url.SAVE_CHECKIN_CHECKOUT, {
            record_type: "co"
          })
            .then(res => {
              console.log(res);
            })
            .catch(err => {
              console.log(err);
            });
        }}
      >
        Check Out
      </Button>
    </div>
  );
}

const ReviewsTable = () => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  let firstDayOfMonth = today.slice(0, -2) + "01";
  const [date, setDate] = useState({
    startDate: firstDayOfMonth,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="reviewTable">
        <DateGroup />
        <Reviews
          reqBody={{
            from_date: date.startDate,
            to_date: date.endDate,
            doctor_id: store.getState().userReducer.hash_id
          }}
        />
      </div>
    </dateContext.Provider>
  );
};

function DashboardPage2({ setIsCheckedOut }) {
  const [page, setPage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setPage(location.pathname.split("/dashboard/")[1] || "");
  }, [location.pathname]);

  //Send request to an api every minute
  useEffect(() => {
    let today = new Date();
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

    if (localStorage.getItem("activityDay") !== today) {
      localStorage.setItem("activityDay", today);
      localStorage.removeItem("lastRequestTime");
    }

    window.activityInterval = setInterval(() => {
      let currTime = Date.now();
      let lastRequestTime = parseInt(localStorage.getItem("lastRequestTime"));
      if (lastRequestTime && currTime - lastRequestTime > 20 * 60 * 1000) {
        clearInterval(parseInt(localStorage.getItem("EveryMinApiIntervalId")));
      } else {
        Axios.post(CONFIG_CONSTANTS.url.HIT_EVERY_MIN_API, {
          doctor_id: store.getState().userReducer.hash_id
        }).then(res => {});
      }
    }, 60 * 1000);
    localStorage.setItem("EveryMinApiIntervalId", window.activityInterval);
    return () => clearInterval(window.activityInterval);
  }, []);
  //End sending request every minute

  const [rerender, setRerender] = useState(true);
  useEffect(() => {
    window.refreshRowStatus = setInterval(() => setRerender(!rerender), 30000);
    return () => clearInterval(window.refreshRowStatus);
  }, []);
  const renderSwitch = (setCountToday, setCountPending, setCountPriority) => {
    switch (page) {
      case "appointments/summary":
        return (
          <AppointmentsSummaryView
            setCountToday={setCountToday}
            setCountPending={setCountPending}
            setCountPriority={setCountPriority}
          />
        );

      case "appointments/pending":
        return <AppointmentsPendingView setCountPending={setCountPending} />;

      case "appointments/priority":
        return <AppointmentsPriorityView setCountPriority={setCountPriority} />;

      case "yourPerformance": {
        return <YourPerformanceView />;
      }

      case "pci":
        return <PCITable brand="both" />;
      case "pci/MM":
        return <PCITable brand="MM" />;
      case "pci/BW":
        return <PCITable brand="BW" />;
      case "reviews":
        return <ReviewsTable />;

      case "onlineStatus": {
        return <ActiveStatus />;
      }

      default:
        return <></>;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#E5E5E5",
        minHeight: "900px"
      }}
    >
      <div
        style={{
          width: "250px",
          minHeight: "100%",
          marginRight: "5px",
          backgroundColor: "#E5E5E5",
          borderRight: "1px solid #9E9E9E",
          paddingRight: "5px"
        }}
      >
        <IdGroup />
        <div
          style={{
            color: "#9E9E9E",
            height: "100%",
            fontWeight: "1000"
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "10px",
              backgroundColor: !page ? "#E5E8Eb" : "#E5E5E5",
              borderLeft: !page ? "4px solid #154166" : "#E5E5E5"
            }}
          >
            <Link className={!page ? "active" : "active2"} to="/dashboard">
              Dashboard
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              backgroundColor:
                page && page.includes("appointments") ? "#E5E8Eb" : "#E5E5E5",
              borderLeft:
                page && page.includes("appointments")
                  ? "4px solid #154166"
                  : "#E5E5E5"
            }}
          >
            <Link
              className={
                page && page.includes("appointments") ? "active" : "active2"
              }
              to="/dashboard/appointments/summary"
            >
              Appointments
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              backgroundColor:
                page === "yourPerformance" ? "#E5E8Eb" : "#E5E5E5",
              borderLeft:
                page === "yourPerformance" ? "4px solid #154166" : "#E5E5E5"
            }}
          >
            <Link
              className={page === "yourPerformance" ? "active" : "active2"}
              to="/dashboard/yourPerformance"
            >
              Your Performance
            </Link>
          </div>
          {/* <div
            style={{
              display: "flex",
              padding: "10px",
              backgroundColor: page === "onlineStatus" ? "#E5E8Eb" : "#E5E5E5",
              borderLeft:
                page === "onlineStatus" ? "4px solid #154166" : "#E5E5E5"
            }}
          >
            <Link
              className={page === "onlineStatus" ? "active" : "active2"}
              to="/dashboard/onlineStatus"
            >
              Online Status
            </Link>
          </div> */}
          <div
            style={{
              display: "flex",
              padding: "10px",
              backgroundColor: page === "pci" ? "#E5E8Eb" : "#E5E5E5",
              borderLeft: page === "pci" ? "4px solid #154166" : "#E5E5E5"
            }}
          >
            <Link
              className={page === "pci" ? "active" : "active2"}
              to="/dashboard/pci"
            >
              PCI - Overall
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              backgroundColor: page === "pci/MM" ? "#E5E8Eb" : "#E5E5E5",
              borderLeft: page === "pci/MM" ? "4px solid #154166" : "#E5E5E5"
            }}
          >
            <Link
              className={page === "pci/MM" ? "active" : "active2"}
              to="/dashboard/pci/MM"
            >
              PCI - MM
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              backgroundColor: page === "pci/BW" ? "#E5E8Eb" : "#E5E5E5",
              borderLeft: page === "pci/BW" ? "4px solid #154166" : "#E5E5E5"
            }}
          >
            <Link
              className={page === "pci/BW" ? "active" : "active2"}
              to="/dashboard/pci/BW"
            >
              PCI - BW
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              backgroundColor: page === "reviews" ? "#E5E8Eb" : "#E5E5E5",
              borderLeft: page === "reviews" ? "4px solid #154166" : "#E5E5E5"
            }}
          >
            <Link
              className={page === "reviews" ? "active" : "active2"}
              to="/dashboard/reviews"
            >
              Reviews
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px"
            }}
          >
            <CheckOut setIsCheckedOut={setIsCheckedOut} />
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px"
            }}
          >
            <Logout />
          </div>
        </div>
      </div>
      {page && page.includes("appointments") && (
        <Comp renderSwitch={renderSwitch} page={page} />
      )}
      {(!page || !page?.includes("appointments")) && (
        <div style={{ flexGrow: "1", backgroundColor: "#E5E5E5" }}>
          {!page ? <NewDashboardView page={page} /> : renderSwitch()}
        </div>
      )}
    </div>
  );
}

const Logout = () => {
  const handleClick = async () => {
    await Axios.get(CONFIG_CONSTANTS.AUTH.LOGOUT, { withCredentials: true });
    console.log("clearing loginKey");
    localStorage.removeItem("jwt");
    store.dispatch({ type: "CLEAR" });
    window.location.href = "/login";
  };
  return (
    <div>
      <button
        type="button"
        className="button button--red"
        onClick={handleClick}
      >
        Logout
      </button>
    </div>
  );
};

export const IdGroup = () => {
  const [id, setId] = useState("");
  const handleSubmit = () => {
    const appointmentData = id?.split("-");
    const payload = {
      doctorId: store.getState().userReducer.hash_id,
      source: appointmentData.length === 3 ? appointmentData[1] : "",
      id: appointmentData.length === 3 ? appointmentData[2] : "",
      brand: appointmentData.length === 3 ? appointmentData[0] : ""
    };
    payload["form"] = payload["source"];
    rxOpenedClevertap(payload);
    window.open(getRxWriterLink(id), "_self");
  };
  const handleChange = e => {
    e.preventDefault();
    setId(e.target.value);
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        width: "245px",
        justifyContent: "space-between",
        margin: "5px 5px 5px 1px"
      }}
    >
      <input
        type="text"
        placeholder="Enter Id"
        className="input2"
        id="id"
        value={id}
        onChange={handleChange}
      />
      <br />
      <br />
      <button
        type="submit"
        disabled={!id}
        className={"button button--primary" + (id ? "" : " disabled")}
      >
        Go to App
      </button>
    </form>
  );
};

export const DateGroup = () => {
  const { date, setDate } = useContext(dateContext);

  const [dates, setDates] = useState({
    start: date.startDate,
    end: date.endDate
  });

  const handleChange = (e, type) => {
    if (type === "start") {
      setDates({ ...dates, start: e.target.value });
    }
    if (type === "end") {
      setDates({ ...dates, end: e.target.value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDate({ startDate: dates.start, endDate: dates.end });
  };

  return (
    <div className="dategroup">
      <form className="form">
        <input
          type="date"
          className="input"
          value={dates.start}
          onChange={e => handleChange(e, "start")}
        />
        <input
          type="date"
          className="input"
          value={dates.end}
          onChange={e => handleChange(e, "end")}
        />
        <IconButton color="primary" aria-label="Go" onClick={handleSubmit}>
          <ArrowForwardIcon />
        </IconButton>
      </form>
    </div>
  );
};

export default React.memo(DashboardPage2);

const CONSTANTS = {
  DOCTOR_STATUS_MAP: {
    0: { label: "NONE", value: "none" },
    1: { label: "ACTIVE", value: "active" },
    2: { label: "BUSY", value: "blocked" },
    3: { label: "COOL OFF", value: "coolOff" },
    4: { label: "BREAK TIME", value: "breakTime" },
    5: { label: "OUT OF OFFICE", value: "outOfOffice" },
    6: { label: "STEP AWAY", value: "stepAway" },
    7: { label: "CHECKED OUT", value: "checkedOut" }
  },
  DEFAULT_DOCTOR_STATUS: { label: "NONE", value: "none" },
  LABEL_PREFIX: "Doctor Status"
};
export default CONSTANTS;

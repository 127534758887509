import React, { useContext, useState } from "react";
import DayTypeCol from "./DayTypeCol";
import DayTypeRow from "./DayTypeRow";
import { GlobalDataContext } from "./MealPlanner2";
import MealTypeCol from "./MealTypeCol";
import MealTypeRow from "./MealTypeRow";

const days1 = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun"
};
const reverseDays1 = { Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6, Sun: 7 };

const daysArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const mealType2 = {
  "Early Morning": {
    key: 1,
    dafaultTime: "07:00"
  },
  Breakfast: {
    key: 2,
    dafaultTime: "09:00"
  },
  "Mid Meal": {
    key: 3,
    dafaultTime: "11:00"
  },
  Lunch: {
    key: 4,
    dafaultTime: "13:00"
  },
  Snacks: {
    key: 5,
    dafaultTime: "15:00"
  },
  Dinner: {
    key: 6,
    dafaultTime: "20:00"
  }
};

export default function Table({ header }) {
  const { tableState, setTableState } = useContext(GlobalDataContext);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "200px 800px 800px 800px 800px 800px 800px 800px",
        marginTop: "20px",
        overflow: "auto",
        backgroundColor: "white",
        width: "95vw",
        height: "95vh"
      }}
    >
      {header.map((col, index) => {
        return (
          <div
            key={index}
            style={{
              backgroundColor: "#154166",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              padding: "16px 34px",
              margin: "0 5px 0 10px",
              fontWeight: "400",
              borderRadius: "4px"
            }}
          >
            {col}
          </div>
        );
      })}
      {tableState["Meal Type"].map((col, ind) => {
        return (
          <>
            <MealTypeRow defaultMealType={col} key={ind} ind={ind} />
            {header.slice(1).map((day, index) => {
              return (
                <DayTypeRow
                  defaultMealType={col}
                  day={day}
                  ind={ind}
                  index={index}
                  key={index}
                />
              );
            })}
          </>
        );
      })}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table6 from "../../common/Tables/Table6.js";
import { newDashboardPending, newDashboardSummary } from "./NewCols.js";
import Card from "./Card";
import Card2 from "./Card2.js";
import Card3 from "./Card3.js";
import { toast } from "react-toastify";
export default function NewDashboard({ reqBody }) {
  const [data, setData] = useState(null);
  const [tab, setTab] = useState("ALL");

  async function fetchAppointments() {
    if (!reqBody?.from_date || !reqBody?.to_date) return;
    axios
      .post(config.url.APPOINTMENTS, reqBody)
      .then(res => {
        let { summary, summary_mm, summary_bw } = res.data;
        let summary1 = summary,
          summary_mm1 = summary_mm,
          summary_bw1 = summary_bw;
        axios
          .post(config.url.PENDING_APPOINTMENTS, reqBody)
          .then(res => {
            let { summary, summary_mm, summary_bw } = res.data;
            setData({
              summary: [summary1],
              summary_mm: [summary_mm1],
              summary_bw: [summary_bw1],
              pending_summary: [summary],
              pending_summary_mm: [summary_mm],
              pending_summary_bw: [summary_bw]
            });
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        if (err.response.status == 400) {
          toast.warn("Date Range should have a maximum range of 3 days");
        }
        console.log(err);
      });
  }
  useEffect(() => {
    fetchAppointments();
  }, [reqBody?.from_date, reqBody?.to_date]);

  return data ? (
    <>
      <div
        style={{
          display: "flex",
          fontWeight: "1000"
        }}
      >
        <div
          style={{
            padding: "10px 15px",
            color: tab === "ALL" ? "red" : "green",
            cursor: "pointer"
          }}
          onClick={() => setTab("ALL")}
        >
          <div
            style={{
              padding: "7px",
              backgroundColor: "white",
              boxShadow: "2px 2px 2px 2px gray"
            }}
          >
            ALL
          </div>
        </div>
        <div
          style={{
            padding: "10px 15px",
            color: tab === "MM" ? "red" : "green",
            cursor: "pointer"
          }}
          onClick={() => setTab("MM")}
        >
          <div
            style={{
              padding: "7px",
              backgroundColor: "white",
              boxShadow: "2px 2px 2px 2px gray"
            }}
          >
            MM
          </div>
        </div>
        <div
          style={{
            padding: "10px 15px",
            color: tab === "BW" ? "red" : "green",
            cursor: "pointer"
          }}
          onClick={() => setTab("BW")}
        >
          <div
            style={{
              padding: "7px",
              backgroundColor: "white",
              boxShadow: "2px 2px 2px 2px gray"
            }}
          >
            BW
          </div>
        </div>
      </div>
      <div style={{ padding: "10px", color: "black", fontSize: "20px" }}>
        Total
      </div>
      <Card
        data2={
          tab === "ALL"
            ? data.summary || []
            : tab === "MM"
            ? data.summary_mm || []
            : data.summary_bw || []
        }
      />
      <div style={{ padding: "10px", color: "black", fontSize: "20px" }}>
        Pending
      </div>
      <Card2
        data2={
          tab === "ALL"
            ? data.pending_summary || []
            : tab === "MM"
            ? data.pending_summary_mm || []
            : data.pending_summary_bw || []
        }
      />
      <div style={{ padding: "10px", color: "black", fontSize: "20px" }}>
        Summary
      </div>
      <Card3
        data2={
          tab === "ALL"
            ? data.summary || []
            : tab === "MM"
            ? data.summary_mm || []
            : data.summary_bw || []
        }
      />
      {/* <Table6
        title="Pending"
        cols={newDashboardPending}
        rows={
          tab === "ALL"
            ? data.pending_summary || []
            : tab === "MM"
            ? data.pending_summary_mm || []
            : data.pending_summary_bw || []
        }
        pagin={false}
      />
      <Table6
        title="Summary"
        cols={newDashboardSummary}
        rows={
          tab === "ALL"
            ? data.summary || []
            : tab === "MM"
            ? data.summary_mm || []
            : data.summary_bw || []
        }
        pagin={false}
      /> */}
    </>
  ) : null;
}

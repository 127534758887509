import React from "react";
import styles from "./styles.module.scss";
import CONSTANTS from "./constants";

const StatusDisplay = ({ doctorStatus = 0 }) => {
  const getStatusLabel = () => {
    return (
      CONSTANTS?.DOCTOR_STATUS_MAP?.[doctorStatus]?.label ||
      CONSTANTS?.DEFAULT_DOCTOR_STATUS?.label ||
      ""
    );
  };
  const getVariant = () => {
    return (
      CONSTANTS?.DOCTOR_STATUS_MAP?.[doctorStatus]?.value ||
      CONSTANTS?.DEFAULT_DOCTOR_STATUS?.value ||
      ""
    );
  };
  return (
    <div className={styles["status-display"]}>
      <div className={styles["wrapper"]}>
        <div className={`${styles["main-section"]} ${styles[getVariant()]}`}>
          <div className={styles["label-prefix"]}>
            {CONSTANTS?.LABEL_PREFIX}
          </div>
          <div className={styles["label-text"]}>{getStatusLabel()}</div>
        </div>
      </div>
    </div>
  );
};

export default StatusDisplay;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./DashboardPage.scss";
import { IdGroup, Logout } from "./AdminNav";
import DataTable2 from "../../common/Tables/DataTable2";
import DataTable3 from "../../common/Tables/DataTable3";
import NewDataTableViewHistory from "../../common/Tables/NewDataTableViewHistory";
import NewDataTableViewHistory2 from "../../common/Tables/NewDataTableViewHistory2";
import config from "../../../constants/apiList";
import { Link, useLocation } from "react-router-dom";
import "./AdminPage.scss";
import { store } from "../../../reducers/Persist";

import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import moment from "moment";
import DashboardInfo from "./DashboardInfo";
import YourPerformance from "./YourPerformance";
import Axios from "axios";
import ActiveStatus from "../AdminPage/ActiveStatus";
import { DateGroup2 } from "./AdminNav";
import PCI from "../DashboardPage/PCI";
import Reviews from "../NewDoctorDashboard/V2/ReviewSummary";
import { getRxWriterLink } from "../../../helpers/rx-writer.helpers";
const appointmentAction = [
  {
    icon: "launch",
    tooltip: "Open Prescription",
    onClick: (event, rowData) => {
      let param = rowData.brand + "-" + rowData.source + "-" + rowData.id;
      const oldCategories = ["SH", "HC", "OF"];
      if (oldCategories.includes(rowData.source)) {
        param = rowData.source_id;
      }
      window.open(getRxWriterLink(param), "_self");
    }
  }
];

const colMapper = col => {
  return {
    title: col,
    field: col
  };
};

const getColor = row => {
  let { appointment_date, from_time, to_time, status, confirmed, cancelled } =
    row;
  if (cancelled === 1) return { backgroundColor: "#D3D3D3" };
  let st = status.toUpperCase();
  let endStates = ["T", "C", "NI", "NS", "OP", "NA", "CN", "OTC", "RF"];
  let currentTimeStamp = ~~(+new Date() / 1000);
  let fromTimeStamp = moment(appointment_date + " " + from_time).unix();
  let toTimeStamp = moment(appointment_date + " " + to_time).unix();
  if (endStates.includes(st)) {
    return { backgroundColor: "#33FF4F" };
  }
  if (
    currentTimeStamp >= fromTimeStamp &&
    currentTimeStamp <= toTimeStamp &&
    !endStates.includes(st)
  ) {
    return { backgroundColor: "#FFA833" };
  }
  if (currentTimeStamp > toTimeStamp && !endStates.includes(st)) {
    return { backgroundColor: "#e47d7d" };
  }
  if (confirmed === 1) return { backgroundColor: "#ffd399" };
};
const getColor2 = row => {
  let { appointment_date, from_time, to_time, status, updated_dttm } = row;
  let today = new Date();
  if (moment(today).format("YYYY-MM-DD") === updated_dttm.slice(0, 10)) {
    return { backgroundColor: "#99FCA6" };
  }
  let st = status.toUpperCase();
  let endStates = ["T", "C", "NI", "NS", "OP", "NA", "CN"];
  let currentTimeStamp = ~~(+new Date() / 1000);
  let fromTimeStamp = moment(appointment_date + " " + from_time).unix();
  let toTimeStamp = moment(appointment_date + " " + to_time).unix();
  if (endStates.includes(st)) {
    return { backgroundColor: "#33FF4F" };
  }
  if (
    currentTimeStamp >= fromTimeStamp &&
    currentTimeStamp <= toTimeStamp &&
    !endStates.includes(st)
  ) {
    return { backgroundColor: "#FFA833" };
  }
  if (currentTimeStamp > toTimeStamp && !endStates.includes(st)) {
    return { backgroundColor: "#e47d7d" };
  }
};

const appointmentCols = ["status"].concat([
  "no_of_attempts",
  "appointment_date",
  "from_time",
  "to_time",
  "patient_name",
  "phone",
  "brand",
  "source",
  "id",
  "category",
  "prescription_order_ids",
  "confirmed",
  "cancelled",
  "Rescheduled",
  "prescription_generated",
  "reschedule_sent",
  "image_uploaded",
  "image_upload_reminder_sent"
]);

export const dateContext = React.createContext();

const PCITable = ({ brand }) => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  let firstDayOfMonth = today.slice(0, -2) + "01";
  const [date, setDate] = useState({
    startDate: firstDayOfMonth,
    endDate: today
  });
  const reqBody = {
    doctor_id: store.getState().userReducer.hash_id,
    from_date: date.startDate,
    to_date: date.endDate
  };

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="pciTable">
        <DateGroup2 />
        <PCI reqBody={reqBody} brand={brand} />
      </div>
    </dateContext.Provider>
  );
};

const ReviewsTable = () => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  let firstDayOfMonth = today.slice(0, -2) + "01";
  const [date, setDate] = useState({
    startDate: firstDayOfMonth,
    endDate: today
  });
  const reqBody = {
    doctor_id: store.getState().userReducer.hash_id,
    from_date: date.startDate,
    to_date: date.endDate
  };

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="reviewTable">
        <DateGroup2 />
        <Reviews reqBody={reqBody} />
      </div>
    </dateContext.Provider>
  );
};

export default function DashboardPage() {
  const reqBody = { doctor_id: store.getState().userReducer.hash_id };
  const startDate = useSelector(state => state.appointmentReducer.start);
  const endDate = useSelector(state => state.appointmentReducer.end);
  const [page, setPage] = useState(null);
  // const history = useHistory();
  const location = useLocation();
  const reqBody1 = {
    doctor_id: store.getState().userReducer.hash_id,
    from_date: startDate,
    to_date: endDate
  };
  useEffect(() => {
    setPage(location.pathname.split("/dashboard/")[1]);
  }, [location]);
  //Send request to an api every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      let currTime = Date.now();
      let lastRequestTime = parseInt(localStorage.getItem("lastRequestTime"));
      if (currTime - lastRequestTime > 20 * 60) {
        clearInterval(parseInt(localStorage.getItem("EveryMinApiIntervalId")));
      } else {
        Axios.post(CONFIG_CONSTANTS.url.HIT_EVERY_MIN_API, {
          doctor_id: store.getState().userReducer.hash_id
        }).then(res => {});
      }
    }, 60 * 1000);
    localStorage.setItem("EveryMinApiIntervalId", intervalId);
    return () => clearInterval(intervalId);
  }, []);
  //End sending request every minute
  const [rerender, setRerender] = useState(true);
  useEffect(() => {
    window.refreshRowStatus = setInterval(() => setRerender(!rerender), 30000);
    return () => clearInterval(window.refreshRowStatus);
  });

  const renderSwitch = () => {
    switch (page) {
      case "appointments": {
        return (
          <NewDataTableViewHistory
            title="Appointments"
            cols={appointmentCols}
            url={config.url.APPOINTMENTS}
            reqBody={reqBody1}
            actions={appointmentAction}
            rowStyle={r => getColor(r)}
            search={true}
            pageSize={100}
            pageSizeOptions={[100, 200, 500]}
            obj={{ rerender, setRerender }}
          />
        );
      }
      case "missedCalls": {
        return (
          <NewDataTableViewHistory
            title="Missed Calls"
            cols={[...appointmentCols, "request_callback_time"]}
            url={config.url.MISSED_CALLS}
            reqBody={reqBody1}
            actions={appointmentAction}
            rowStyle={r => getColor(r)}
            search={true}
            pageSize={100}
            pageSizeOptions={[100, 200, 500]}
            obj={{ rerender, setRerender }}
          />
        );
      }
      case "pendingAppointments": {
        return (
          <NewDataTableViewHistory2
            title="Pending Appointments"
            cols={["last_attempted_at", ...appointmentCols]}
            url={config.url.PENDING_APPOINTMENTS}
            reqBody={reqBody1}
            actions={appointmentAction}
            rowStyle={r => getColor2(r)}
            search={true}
            pageSize={100}
            pageSizeOptions={[100, 200, 500]}
            obj={{ rerender, setRerender }}
          />
        );
      }
      case "yourPerformance": {
        return <YourPerformance reqBody={reqBody1} />;
      }
      case "pci":
        return <PCITable brand="both" />;
      case "pci/MM":
        return <PCITable brand="MM" />;
      case "pci/BW":
        return <PCITable brand="BW" />;
      case "reviews":
        return <ReviewsTable />;
      case "pendingUpdatedToday": {
        return (
          <DataTable2
            title="Pending Updated Today"
            cols={appointmentCols}
            url={config.url.PENDING_UPDATED_TODAY}
            reqBody={reqBody}
            actions={appointmentAction}
            rowStyle={r => getColor(r)}
            search={true}
          />
        );
      }
      case "todayReport1": {
        return (
          <DataTable3
            title="Today Report 1"
            cols={smallCols}
            url={config.url.TODAY_REPORT_1}
            reqBody={reqBody1}
          />
        );
      }
      case "pendingToday1": {
        return (
          <DataTable3
            title="Pending Today 1"
            cols={smallCols}
            url={config.url.PENDING_REPORT_1}
            reqBody={reqBody}
          />
        );
      }
      case "pendingCumulative1": {
        return (
          <DataTable3
            title="Pending Cumulative 1"
            cols={smallCols}
            url={config.url.PENDING_CUMULATIVE_REPORT_1}
            reqBody={reqBody}
          />
        );
      }
      case "todayReport2": {
        return (
          <DataTable3
            title="Today Report 2"
            cols={aggrColumns}
            url={config.url.TODAY_REPORT_2}
            reqBody={reqBody1}
          />
        );
      }
      case "pendingToday2": {
        return (
          <DataTable3
            title="Pending Today 2"
            cols={[
              "allRows",
              "Ringing",
              "Disconnected",
              "Cool_off",
              "Busy",
              "NI",
              "NS",
              "Ordered",
              "Switched_Off",
              "Testing",
              "Completed"
            ]
              .map(col => colMapper(col))
              .concat([
                {
                  title: "Total",
                  field: "Total",
                  render: row => row.allRows - row.Ordered
                },
                {
                  title: "Valid",
                  field: "Valid",
                  render: row =>
                    row.allRows - row.Ordered - row.NI - row.NS - row.Testing
                },
                {
                  title: "%Complete",
                  field: "%Complete",
                  render: row => getPerCompleted(row)
                }
              ])}
            url={config.url.PENDING_REPORT_2}
            reqBody={reqBody}
          />
        );
      }
      case "pendingCumulative2": {
        return (
          <DataTable3
            title="Pending Cumulative 2"
            cols={aggrColumns}
            url={config.url.PENDING_CUMULATIVE_REPORT_2}
            reqBody={reqBody}
          />
        );
      }
      case "onlineStatus": {
        return <ActiveStatus />;
      }
      default: {
        return <DashboardInfo reqBody={reqBody} />;
      }
    }
  };
  return (
    <div className="dashboard-page-container">
      <div>
        <ProSidebar>
          <Menu iconShape="square">
            <MenuItem>
              <IdGroup />
            </MenuItem>
            <MenuItem>
              <Link className={!page ? "active" : ""} to="/dashboard">
                Dashboard
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "appointments" ? "active" : ""}
                to="/dashboard/appointments"
              >
                Appointments
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "missedCalls" ? "active" : ""}
                to="/dashboard/missedCalls"
              >
                Missed Calls
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "pendingAppointments" ? "active" : ""}
                to="/dashboard/pendingAppointments"
              >
                Pending Appointments
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "yourPerformance" ? "active" : ""}
                to="/dashboard/yourPerformance"
              >
                Your Performance
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "onlineStatus" ? "active" : ""}
                to="/dashboard/onlineStatus"
              >
                Online Status
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "pci" ? "active" : ""}
                to="/dashboard/pci"
              >
                PCI - Overall
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "pci/MM" ? "active" : ""}
                to="/dashboard/pci/MM"
              >
                PCI - MM
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "pci/BW" ? "active" : ""}
                to="/dashboard/pci/BW"
              >
                PCI - BW
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "reviews" ? "active" : ""}
                to="/dashboard/reviews"
              >
                Reviews
              </Link>
            </MenuItem>
            <MenuItem>
              <Logout />
            </MenuItem>
            {/* <MenuItem>
              <Link
                className={page === "pendingUpdatedToday" ? "active" : ""}
                to="pendingUpdatedToday"
              >
                Pending Updated Today
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "todayReport1" ? "active" : ""}
                to="todayReport1"
              >
                Today Report 1
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "pendingToday1" ? "active" : ""}
                to="pendingToday1"
              >
                Pending Today 1
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={
                  page === "appoipendingCumulative1ntments" ? "active" : ""
                }
                to="pendingCumulative1"
              >
                Pending Cumulative 1
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "todayReport2" ? "active" : ""}
                to="todayReport2"
              >
                Today Report 2
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "pendingToday2" ? "active" : ""}
                to="pendingToday2"
              >
                Pending Today 2
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className={page === "pendingCumulative2" ? "active" : ""}
                to="pendingCumulative2"
              >
                Pending Cumulative 2
              </Link>
            </MenuItem> */}
          </Menu>
        </ProSidebar>
      </div>
      <div>{renderSwitch()}</div>
      {/* <DataTable2
        title="Appointments"
        cols={appointmentCols}
        url={config.url.APPOINTMENTS}
        reqBody={reqBody1}
        actions={appointmentAction}
        rowStyle={r => getColor(r.status)}
        search={true}
      />
      <DataTable2
        title="Pending Appointments"
        cols={appointmentCols}
        url={config.url.PENDING_APPOINTMENTS}
        reqBody={reqBody}
        actions={appointmentAction}
        rowStyle={r => getColor(r.status)}
        search={true}
      />
      <DataTable2
        title="Pending Updated Today"
        cols={appointmentCols}
        url={config.url.PENDING_UPDATED_TODAY}
        reqBody={reqBody}
        actions={appointmentAction}
        rowStyle={r => getColor(r.status)}
        search={true}
      />
      <TableRowBox>
        <TableColBox>
          <DataTable3
            title="Today Report 1"
            cols={smallCols}
            url={config.url.TODAY_REPORT_1}
            reqBody={reqBody1}
          />
          <DataTable3
            title="Today Report 2"
            cols={aggrColumns}
            url={config.url.TODAY_REPORT_2}
            reqBody={reqBody1}
          />
        </TableColBox>
        <TableColBox>
          <DataTable3
            title="Pending Today 1"
            cols={smallCols}
            url={config.url.PENDING_REPORT_1}
            reqBody={reqBody}
          />
          <DataTable3
            title="Pending Today 2"
            cols={[
              "allRows",
              "Ringing",
              "Disconnected",
              "Cool_off",
              "Busy",
              "NI",
              "NS",
              "Ordered",
              "Switched_Off",
              "Testing",
              "Completed"
            ]
              .map(col => colMapper(col))
              .concat([
                {
                  title: "Total",
                  field: "Total",
                  render: row => row.allRows - row.Ordered
                },
                {
                  title: "Valid",
                  field: "Valid",
                  render: row =>
                    row.allRows - row.Ordered - row.NI - row.NS - row.Testing
                },
                {
                  title: "%Complete",
                  field: "%Complete",
                  render: row => getPerCompleted(row)
                }
              ])}
            url={config.url.PENDING_REPORT_2}
            reqBody={reqBody}
          />
        </TableColBox>
        <TableColBox>
          <DataTable3
            title="Pending Cumulative 1"
            cols={smallCols}
            url={config.url.PENDING_CUMULATIVE_REPORT_1}
            reqBody={reqBody}
          />
          <DataTable3
            title="Pending Cumulative 2"
            cols={aggrColumns}
            url={config.url.PENDING_CUMULATIVE_REPORT_2}
            reqBody={reqBody}
          />
        </TableColBox>
      </TableRowBox> */}
    </div>
  );
}

const smallCols = [
  {
    title: "Total",
    field: "Total",
    render: row => row.allRows - row.Ordered
  },
  {
    title: "Valid",
    field: "Valid",
    render: row => row.allRows - row.Ordered - row.NI - row.NS - row.Testing
  },
  {
    title: "Completed",
    field: "Completed"
  },
  {
    title: "%Completed",
    field: "%Completed",
    render: row => getPerCompleted(row)
  },
  {
    title: "Scheduled",
    field: "Scheduled"
  }
];

const getPerCompleted = datapt => {
  const NmR = datapt.Completed * 100;
  const DnR =
    datapt.allRows - datapt.NI - datapt.NS - datapt.Testing - datapt.Ordered;
  const returnVal = (NmR / DnR).toFixed(2);
  return returnVal;
};

const aggrColumns = [
  {
    title: "Source",
    field: "source"
  },
  {
    title: "Total",
    field: "Total",
    render: row => row.allRows - row.Ordered
  },
  {
    title: "Valid",
    field: "Valid",
    render: row => row.allRows - row.Ordered - row.NI - row.NS - row.Testing
  },
  {
    title: "Completed",
    field: "Completed"
  },
  {
    title: "%Completed",
    field: "%Completed",
    render: row => getPerCompleted(row)
  },
  {
    title: "Scheduled",
    field: "Scheduled"
  }
];

const TableRowBox = props => {
  return <div className="table-row-box spaced-l">{props.children}</div>;
};
const TableColBox = props => {
  return <div className="table-row-child spaced-t">{props.children}</div>;
};

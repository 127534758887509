import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import "./YourPerformance.scss";
import { DateGroup } from "./AdminNav.js";
import Card from "./Card.js";

export default function YourPerformance({ reqBody }) {
  const endStates = useRef([
    "ni",
    "ns",
    "t",
    "c",
    "op",
    "na",
    "cn",
    "otc",
    "rf"
  ]);
  // let data1 = {
  //   brand: 'MM - Others',
  //   totalAppointments: 100,
  //   completed: 90,
  //   totalPending: 10,
  //   rxGenerated: 25
  // }
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState("");
  const [data5, setData5] = useState("");
  const [data6, setData6] = useState("");
  const [data7, setData7] = useState("");
  const [data8, setData8] = useState("");
  const [data9, setData9] = useState("");

  useEffect(() => {
    axios.post(config.url.YOUR_PERFORMANCE, reqBody).then(res => {
      let data = res.data.body.data;
      let totalAppointments, totalPending, completed, rxGenerated;

      function getData(brand, category) {
        let result;
        if (!category) result = data.filter(row => row.brand === brand);
        else {
          if (
            category === "is_sh" ||
            category === "is_hc" ||
            category === "skin"
          )
            result = data.filter(
              row => row.brand === brand && row.category === category
            );
          else {
            result = data.filter(
              row =>
                row.brand === brand &&
                row.category !== "is_sh" &&
                row.category !== "is_hc" &&
                row.category !== "skin"
            );
          }
        }
        totalAppointments = result.length;
        totalPending = result.filter(
          row => !endStates.current.includes(row.status)
        ).length;
        completed = result.filter(row => endStates.current.includes(row.status))
          .length;
        rxGenerated = result.filter(row => row.prescription_generated).length;

        return { totalAppointments, totalPending, completed, rxGenerated };
      }

      setData1({ brand: "MM", ...getData("MM") });
      setData2({ brand: "BW", ...getData("BW") });
      setData3({ brand: "MM - Hair", ...getData("MM", "is_hc") });
      setData4({ brand: "MM - Skin", ...getData("MM", "skin") });
      setData5({ brand: "MM- Performance", ...getData("MM", "is_sh") });
      setData6({ brand: "MM - Others", ...getData("MM", "others") });
      setData7({ brand: "BW - Hair", ...getData("BW", "is_hc") });
      setData8({ brand: "BW - Skin", ...getData("BW", "skin") });
      setData9({ brand: "BW - Others", ...getData("BW", "others") });
    });
  }, [reqBody, endStates]);

  return (
    <>
      <div className="yourPerformance">
        Your Performance Data:
        <br />
        {data1 && (data1.totalAppointments ? <Card data={data1} /> : null)}
        {data2 && (data2.totalAppointments ? <Card data={data2} /> : null)}
        {data3 && (data3.totalAppointments ? <Card data={data3} /> : null)}
        {data4 && (data4.totalAppointments ? <Card data={data4} /> : null)}
        {data5 && (data5.totalAppointments ? <Card data={data5} /> : null)}
        {data6 && (data6.totalAppointments ? <Card data={data6} /> : null)}
        {data7 && (data7.totalAppointments ? <Card data={data7} /> : null)}
        {data8 && (data8.totalAppointments ? <Card data={data8} /> : null)}
        {data9 && (data9.totalAppointments ? <Card data={data9} /> : null)}
      </div>
    </>
  );
}

import React from "react";
import dataResource from "../../../../constants/dataResource";
import styles from "./styles.module.scss";

export const columns = [
  {
    title: "Status",
    field: "status",
    render: row => dataResource?.statusListObj?.[row.status]
  },
  {
    title: "Confirmed",
    field: "confirmed",
    render: row => (row.confirmed ? "Yes" : "-")
  },
  {
    title: "Patient ID",
    field: "patientId",
    render: row => `${row.brand}-${row.source}-${row.appointmentId}`
  },
  {
    title: "Date",
    field: "appointmentDate"
  },
  {
    title: "Start Time",
    field: "fromTime"
  },
  {
    title: "Patient Name",
    field: "patientName"
  },
  {
    title: "Concern",
    field: "category",
    render: row => {
      return (
        row.category &&
        row.category
          .split(",")
          .map(c => (
            <div className={`${styles["category-chip"]} ${styles[c]}`}>
              {dataResource?.categoryMap?.[c]}
            </div>
          ))
      );
    }
  }
];

import axios from "axios";
import React, { useState } from "react";
import config from "../../../../../../../constants/apiList";
import Loader from "react-loader-spinner";
import { Button } from "./styles";
import { addToGTM } from "../../../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../../../constants/tagManager.constants";

export default function AskImage({ rowData }) {
  const [requested, setRequested] = useState(
    rowData.image_upload_requested === 1
  );

  const [done, setDone] = useState("");

  const handleClick = () => {
    setDone("requesting");
    axios
      .post(config.url.PRESCRIPTION_IMAGE_UPLOAD_URL, {
        id: rowData.id,
        category: rowData.source
      })
      .then(res => {
        setRequested(true);
        setDone("requested");
        addToGTM({
          event: GTM_EVENTS?.IMAGE_REQUESTED
        });
      })
      .catch(err => {
        alert("error occured while requesting image");
        setDone("error");
      });
  };

  return (
    <div style={{ display: "flex" }}>
      {rowData.image_uploaded ? (
        <Button data-upload="true">Uploaded</Button>
      ) : (
        <Button
          onClick={handleClick}
          disabled={requested || done === "requesting"}
        >
          {requested ? "Requested" : "Request Img"}
        </Button>
      )}
      {done === "requesting" && (
        <div>
          <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
        </div>
      )}
    </div>
  );
}

const initialState = {
  diagnosis: "",
  products: [],
  conditions: []
};
/* 
  "age_constraints":[
      { "age": 50, "condition": "less", "added_product": [5], "removed_products": [2]},
      { "age": 85, "condition": "greater", "added_product": [5], "removed_products": [2]}
    ],
     "product_constraints": [
      { "product_id": 3, "duration": 180, "condition": "less", "improvement": true,"added_product": [5], "removed_products": [2] }
    ],
*/
const sampleCondition = {
  condition_type: "",
  condition_preset: "",
  added_products: [],
  removed_products: [],
  age: "",
  comparator: "",
  thrsholdProduct: "",
  durationValue: "",
  durationPeriod: "",
  improvement: ""
};
const recommendationBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RECOMMENDATION_BUILDER_STATE":
      return {
        ...action.payload
      };
    case "ADD_RECOMMENDATION_DIAGNOSIS":
      return {
        ...state,
        diagnosis: action.payload
      };
    case "ADD_RECOMMENDATION_MAIN_PRODUCT":
      return {
        ...state,
        products: [...state.products, action.payload]
      };
    case "DELETE_RECOMMENDATION_MAIN_PRODUCT":
      return {
        ...state,
        products: state.products.filter(
          product => product.id !== action.payload
        )
      };
    case "ADD_RECOMMENDATION_CONDITION":
      return {
        ...state,
        conditions: [
          ...state.conditions,
          {
            ...sampleCondition,
            condition_type: action.payload.condition_type
          }
        ]
      };
    case "DELETE_RECOMMENDATION_CONDITION":
      const tempConditions = [];
      for (let i = 0; i < state.conditions.length; i++) {
        if (i !== Number(action.payload)) {
          tempConditions.push(state.conditions[i]);
        }
      }
      return {
        ...state,
        conditions: tempConditions
      };
    case "SET_RECOMMENDATION_CONDITION_PRESET":
      return {
        ...state,
        conditions: state.conditions.map((condition, index) => {
          if (index === action.payload.index) {
            return {
              ...condition,
              condition_preset: action.payload.condition_preset
            };
          }
          return condition;
        })
      };
    case "ADD_RECOMMENDATION_CONDITION_PRODUCT":
      return {
        ...state,
        conditions: state.conditions.map((condition, index) => {
          if (index === action.payload.index) {
            if (action.payload.isRecommended)
              return {
                ...condition,
                added_products: [
                  ...condition.added_products,
                  action.payload.product
                ]
              };
            else
              return {
                ...condition,
                removed_products: [
                  ...condition.removed_products,
                  action.payload.product
                ]
              };
          }
          return condition;
        })
      };
    case "DELETE_RECOMMENDATION_CONDITION_PRODUCT":
      return {
        ...state,
        conditions: state.conditions.map((condition, index) => {
          if (index === action.payload.index) {
            if (action.payload.isRecommended)
              return {
                ...condition,
                added_products: condition.added_products.filter(
                  product => product.id !== action.payload.id
                )
              };
            else
              return {
                ...condition,
                removed_products: condition.removed_products.filter(
                  product => product.id !== action.payload.id
                )
              };
          }
          return condition;
        })
      };
    case "SET_CONDITION_KEY":
      return {
        ...state,
        conditions: state.conditions.map((condition, index) => {
          if (index === action.payload.index) {
            return {
              ...condition,
              [action.payload.key]: action.payload.value
            };
          }
          return condition;
        })
      };
    case "CLEAR_RECOMMENDATION_BUILDER":
      return {
        ...initialState
      };
    default:
      return state;
  }
};
export default recommendationBuilderReducer;

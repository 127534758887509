import React, { useState, useEffect, useMemo } from "react";
import { NoDataFound, TableContainer } from "./styles";
import MaterialTable from "material-table";
import { MaterialTableWrapper } from "../V2/AppointmentSummary/common/MaterialTableWrapper";
import { allDoctorsOverviewColumns, dataTextFormat } from "./data";
import { pendingMetricsColumns } from "./assignmentMetricsColumn";
export default function AssignmentMetricsOverviewSection({ responseData }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  const data = useMemo(
    () =>
      responseData?.sections?.map(rowData => ({
        apptType: rowData?.type,
        total: rowData?.totalAppt,
        assigned: rowData?.assignedAppts,
        assignmentRate: rowData?.assignedRate,
        assignedAddressed: rowData?.assignedAddressed,
        assignedAddressal: rowData?.assignedAddressal,
        assignedCompleted: rowData?.assignedCompleted,
        assignedCompletion: rowData?.assignedCompletion,
        validAssigned: rowData?.validAssigned,
        validAssignedCompletion: rowData?.validAssignedCompletion,
        validCompletion: rowData?.validCompletion,
        averagePci: rowData?.averagePci,
        averageDuration: rowData?.averageDuration,
        validTotal: rowData?.validTotal
      })),
    [responseData]
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, []);

  if (!data || (data && data.length === 0))
    return (
      <NoDataFound>
        <span>No Data Found</span>
      </NoDataFound>
    );
  return (
    <TableContainer>
      <MaterialTableWrapper>
        <MaterialTable
          title={null}
          columns={pendingMetricsColumns}
          data={data || []}
          page={10}
          options={{
            search: true,
            paging: true,
            exportAllData: true,
            exportButton: true,
            sorting: false,
            draggable: false,
            headerStyle: {
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "14px"
            },

            rowStyle: {
              backgroundColor: "white",
              width: "min-content",
              padding: "20px"
            },
            cellStyle: {
              fontSize: matches ? "13px" : "15px",
              backgroundColor: "white",
              color: "#6C6C6C"
            }
          }}
          style={{
            margin: "10px",
            padding: "10px",
            marginTop: "0px",
            paddingTop: "0px",
            backgroundColor: "transparent",
            boxShadow: "none"
          }}
        />
      </MaterialTableWrapper>
    </TableContainer>
  );
}

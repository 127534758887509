import React, { useState } from "react";
import styles from "./styles.module.scss";
import CONSTANTS from "./constants";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import {
  GTM_ENTRY_POINTS,
  GTM_EVENTS
} from "../../../../constants/tagManager.constants";
import { getRxWriterLink } from "../../../../helpers/rx-writer.helpers";

const SearchSection = () => {
  const [appointmentReference, setAppointmentReference] = useState("");
  const openRxWriter = () => {
    addToGTM({
      event: GTM_EVENTS?.RX_WRITER_OPENED,
      redirectDetails: {
        entryPoint: GTM_ENTRY_POINTS?.RX_WRITER_OPENED?.DASHBOARD_HEADER_SEARCH
      }
    });
    const redirectLink = getRxWriterLink(appointmentReference);
    window && window.open(redirectLink, "_self");
  };
  return (
    <div className={styles["search-section"]}>
      <div className={styles["form"]}>
        <div className={styles["form-wrapper"]}>
          <input
            className={styles["search-input"]}
            placeholder={CONSTANTS.SEARCH_PLACEHOLDER}
            value={appointmentReference}
            onChange={evt => setAppointmentReference(evt.target.value)}
          />
          <button
            className={styles["search-button"]}
            disabled={!appointmentReference}
            onClick={openRxWriter}
          >
            <img
              src={CONSTANTS?.SEARCH_ICON}
              className={styles["search-button-icon"]}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;

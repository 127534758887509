import React from "react";
import "./PastMedical.scss";
import RxWriterInputBox from "../../../common/RxWriterInputBox/RxwriterInputBox";
import { store } from "../../../../../reducers/Persist";
import { connect } from "react-redux";

class PastMedical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pastTreatmentsData: [],
      medicalHistoryData: [],
      familyHistoryData: [],
      otherDetailsData: []
    };
  }

  componentDidMount() {
    let pastTreatmentsData = [],
      medicalHistoryData = [],
      familyHistoryData = [],
      otherDetailsData = [];
    for (let i = 0; i < this.props.presetData?.length; i++) {
      if (this.props.presetData[i].brand === this.props.brand) {
        switch (this.props.presetData[i]?.observation_type) {
          case "past_treatments":
            pastTreatmentsData.push(this.props.presetData[i]);
            break;
          case "medical_history":
            medicalHistoryData.push(this.props.presetData[i]);
            break;
          case "family_history":
            familyHistoryData.push(this.props.presetData[i]);
            break;
          case "other_details":
            otherDetailsData.push(this.props.presetData[i]);
            break;
          default:
            continue;
        }
      }
    }
    if (this.props.patient_medical_history.length > 0) {
      let pastMedicalTypes = {
        past_treatments: {
          text: "",
          pretext: [],
          metadata: { isEntering: "" }
        },
        family_history: {
          text: "",
          pretext: [],
          metadata: { isEntering: "" }
        },
        medical_history: {
          text: "",
          pretext: [],
          metadata: { isEntering: "" }
        },
        other_details: {
          text: "",
          pretext: [],
          metadata: { isEntering: "" }
        }
      };
      const lastRecord =
        this.props.patient_medical_history[
          this.props.patient_medical_history.length - 1
        ];
      for (const observation of lastRecord.observations) {
        if (!!pastMedicalTypes[observation?.observation_type]) {
          pastMedicalTypes[observation?.observation_type] = {
            ...pastMedicalTypes[observation?.observation_type],
            text: observation.observation_text,
            metadata: JSON.parse(observation.metadata)
          };
        }
      }
      for (const preset of lastRecord.presetData) {
        const observationType =
          this.props.presetIdToObj[preset.observation_preset_id]
            ?.observation_type;
        if (pastMedicalTypes[observationType]) {
          pastMedicalTypes[observationType].pretext.push(
            preset.observation_preset_id
          );
        }
      }
      const typeMap = {
        past_treatments: "pastTreatments",
        medical_history: "medicalHistory",
        family_history: "familyHistory",
        other_details: "otherDetails"
      };
      const staticSample = {
        text: "",
        pretext: [],
        metadata: { isEntering: "" }
      };
      for (const key in pastMedicalTypes) {
        if (
          JSON.stringify(this.props[typeMap[key]]) ===
          JSON.stringify(staticSample)
        ) {
          store.dispatch({
            type: "SET_PAST_MEDICAL",
            payload: { key: typeMap[key], value: pastMedicalTypes[key] }
          });
        }
      }
    }

    this.setState({
      pastTreatmentsData,
      medicalHistoryData,
      familyHistoryData,
      otherDetailsData
    });
  }
  handlePresetSelectPastTreatments = value => {
    store.dispatch({
      type: "SET_KEY_CHECK",
      payload: { key: "past_treatments", value: false }
    });
    store.dispatch({
      type: "SET_PAST_TREATMENTS_PRETEXT",
      payload: value
    });
  };
  handlePresetSelectMedicalHistory = value => {
    store.dispatch({
      type: "SET_KEY_CHECK",
      payload: { key: "medical_history", value: false }
    });
    store.dispatch({
      type: "SET_MEDICAL_HISTORY_PRETEXT",
      payload: value
    });
  };
  handlePresetSelectFamilyHistory = value => {
    store.dispatch({
      type: "SET_KEY_CHECK",
      payload: { key: "family_history", value: false }
    });
    store.dispatch({
      type: "SET_FAMILY_HISTORY_PRETEXT",
      payload: value
    });
  };
  handlePresetSelectOtherDetails = value => {
    store.dispatch({
      type: "SET_OTHER_DETAILS_PRETEXT",
      payload: value
    });
  };

  render() {
    return (
      <div className="past-medical-container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px"
          }}
        >
          <div style={{ width: "48%" }}>
            <RxWriterInputBox
              headerTitle="Past Treatments"
              isRequired={true}
              enterYesNo={true}
              typeVal={this.props.pastTreatments.metadata?.isEntering}
              changeYesNo={value => {
                store.dispatch({
                  type: "SET_KEY_CHECK",
                  payload: { key: "past_treatments_y_n", value: false }
                });
                store.dispatch({
                  type: "RESET_PAST_MEDICAL_FIELD",
                  payload: "pastTreatments"
                });
                store.dispatch({
                  type: "SET_PAST_TREATMENTS_METADATA",
                  payload: value
                });
              }}
              typeAlert={this.props.pastTreatmentsYN}
              mainPresetData={this.state.pastTreatmentsData}
              onChangeInput={text => {
                store.dispatch({
                  type: "SET_KEY_CHECK",
                  payload: { key: "past_treatments", value: false }
                });
                store.dispatch({
                  type: "SET_PAST_TREATMENTS_TEXT",
                  payload: text
                });
              }}
              presetValue={this.props.pastTreatments.pretext}
              textValue={this.props.pastTreatments.text}
              inputPlaceHolder="Click to start typing"
              inputBoxHeight={60}
              onPresetSelect={value =>
                this.handlePresetSelectPastTreatments(value)
              }
              alert={this.props.pastTreatmentsCheck}
            />
          </div>
          <div style={{ width: "48%" }}>
            <RxWriterInputBox
              headerTitle="Medical History"
              isRequired={true}
              enterYesNo={true}
              typeVal={this.props.medicalHistory.metadata?.isEntering}
              typeAlert={this.props.medicalHistoryYN}
              changeYesNo={value => {
                store.dispatch({
                  type: "SET_KEY_CHECK",
                  payload: { key: "medical_history_y_n", value: false }
                });
                store.dispatch({
                  type: "RESET_PAST_MEDICAL_FIELD",
                  payload: "medicalHistory"
                });
                store.dispatch({
                  type: "SET_MEDICAL_HISTORY_METADATA",
                  payload: value
                });
              }}
              mainPresetData={this.state.medicalHistoryData}
              onChangeInput={text => {
                store.dispatch({
                  type: "SET_KEY_CHECK",
                  payload: { key: "medical_history", value: false }
                });
                store.dispatch({
                  type: "SET_MEDICAL_HISTORY_TEXT",
                  payload: text
                });
              }}
              presetValue={this.props.medicalHistory.pretext}
              textValue={this.props.medicalHistory.text}
              inputPlaceHolder="Click to start typing"
              inputBoxHeight={60}
              onPresetSelect={value =>
                this.handlePresetSelectMedicalHistory(value)
              }
              alert={this.props.medicalHistoryCheck}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div style={{ width: "48%" }}>
            <RxWriterInputBox
              headerTitle="Family History"
              isRequired={true}
              changeYesNo={value => {
                store.dispatch({
                  type: "SET_KEY_CHECK",
                  payload: { key: "family_history_y_n", value: false }
                });
                store.dispatch({
                  type: "RESET_PAST_MEDICAL_FIELD",
                  payload: "familyHistory"
                });
                store.dispatch({
                  type: "SET_FAMILY_HISTORY_METADATA",
                  payload: value
                });
              }}
              enterYesNo={true}
              typeAlert={this.props.familyHistoryYN}
              typeVal={this.props.familyHistory.metadata?.isEntering}
              mainPresetData={this.state.familyHistoryData}
              onChangeInput={text => {
                store.dispatch({
                  type: "SET_KEY_CHECK",
                  payload: { key: "family_history", value: false }
                });
                store.dispatch({
                  type: "SET_FAMILY_HISTORY_TEXT",
                  payload: text
                });
              }}
              presetValue={this.props.familyHistory.pretext}
              textValue={this.props.familyHistory.text}
              inputPlaceHolder="Click to start typing"
              inputBoxHeight={60}
              onPresetSelect={value =>
                this.handlePresetSelectFamilyHistory(value)
              }
              alert={this.props.familyHistoryCheck}
            />
          </div>
          <div style={{ width: "48%" }}>
            <RxWriterInputBox
              headerTitle="Other Details"
              changeYesNo={value => {
                store.dispatch({
                  type: "RESET_PAST_MEDICAL_FIELD",
                  payload: "otherDetails"
                });
                store.dispatch({
                  type: "SET_OTHER_DETAILS_METADATA",
                  payload: value
                });
              }}
              mainPresetData={this.state.otherDetailsData}
              typeVal={this.props.otherDetails.metadata?.isEntering}
              enterYesNo={true}
              onChangeInput={text => {
                store.dispatch({
                  type: "SET_OTHER_DETAILS_TEXT",
                  payload: text
                });
              }}
              presetValue={this.props.otherDetails.pretext}
              textValue={this.props.otherDetails.text}
              inputPlaceHolder="Click to start typing"
              inputBoxHeight={60}
              onPresetSelect={value =>
                this.handlePresetSelectOtherDetails(value)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = () => {
  const id = store.getState().rxWriterReducer.id;
  return {
    presetData: store.getState().rxWriterReducer.presetData,
    pastTreatments: store.getState().observationReducer[id].pastTreatments,
    medicalHistory: store.getState().observationReducer[id].medicalHistory,
    familyHistory: store.getState().observationReducer[id].familyHistory,
    otherDetails: store.getState().observationReducer[id].otherDetails,
    brand: store.getState().rxWriterReducer.brand,
    pastTreatmentsCheck:
      store.getState().observationCheckReducer.past_treatments,
    familyHistoryCheck: store.getState().observationCheckReducer.family_history,
    medicalHistoryCheck:
      store.getState().observationCheckReducer.medical_history,
    pastTreatmentsYN:
      store.getState().observationCheckReducer.past_treatments_y_n,
    familyHistoryYN:
      store.getState().observationCheckReducer.family_history_y_n,
    medicalHistoryYN:
      store.getState().observationCheckReducer.medical_history_y_n,
    patient_medical_history:
      store.getState().rxWriterReducer.patient_medical_history,
    presetIdToObj: store.getState().rxWriterReducer.presetIdToObj
  };
};
export default connect(mapStateToProps)(PastMedical);

import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import FileDowanload from "js-file-download";
import JSZip from "jszip";
import { Button } from "react-bootstrap";
function DownloadImages(props) {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [brand, setBrand] = useState("MM");
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderDownload, setShowLoaderDownload] = useState(false);
  const columns = [
    { title: "Appointment: ID", field: "appointmentId" },
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "phone" },
    { title: "Download", field: "action" }
  ];

  const generateRows = data => {
    const tempRows = [];
    for (const row of data) {
      row.action = (
        <div>
          <Button className="dochub-button" onClick={evt => downloadZip(row)}>
            Download
          </Button>
        </div>
      );
      tempRows.push(row);
    }
    setRows(tempRows);
  };
  const listUploadedImages = async () => {
    setShowLoader(true);
    const requestData = {
      startDate: fromDate,
      endDate: toDate,
      brand
    };
    await axios
      .post(CONFIG_CONSTANT.url.LIST_UPLOADED_IMAGES, requestData)
      .then(res => {
        console.log("RES", res);
        if (res.data.success) {
          console.log("RES", res);
          generateRows(res.data.result);
        } else {
          console.log(res.data.message);
        }
        setShowLoader(false);
      })
      .catch(err => {
        setShowLoader(false);
        console.log("ERROR IN /listUploadedImages", err);
      });
  };
  const downloadZip = async data => {
    setShowLoaderDownload(true);
    const requestData = {
      appointmentId: data.appointmentId
    };
    await axios
      .post(CONFIG_CONSTANT.url.DOWNLOAD_ZIP, requestData)
      .then(async res => {
        console.log(res);
        if (res.data.success) {
          const instance = axios.create();
          delete instance.defaults.headers.common["Authorization"];
          const res2 = await instance.get(res.data.url);
          console.log(res2);
          const zip = new JSZip();
          const fileName = `${data.email}_${data.appointmentId}_IMAGES.zip`;
          zip.file(fileName, res2.data.zipBuffer.data, { binary: true });
          zip.generateAsync({ type: "blob", compression: "DEFLATE" }).then(
            function (zipFile) {
              console.log(zipFile);
              FileDowanload(zipFile, fileName);
              setShowLoaderDownload(false);
            },
            function (error) {
              console.log("Error in compression", error);
              setShowLoaderDownload(false);
            }
          );
        } else {
          console.log(res.data.message);
          setShowLoaderDownload(false);
        }
      })
      .catch(e => {
        console.log(e);
        setShowLoaderDownload(false);
      });
  };
  useEffect(() => {
    listUploadedImages();
  }, [brand]);

  return (
    <div>
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={fromDate}
          onChange={evt => setFromDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={toDate}
          onChange={evt => setToDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={listUploadedImages}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>
      {showLoaderDownload ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            D O W N L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        ""
      )}

      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <div>
          <Table4
            title="Rx Images"
            cols={columns}
            rows={rows}
            pageSize={10}
            search={true}
          />
        </div>
      )}
    </div>
  );
}

export default DownloadImages;

import styled from "styled-components";

export const ImagesTitle = styled.h1`
  color: #2a4ba1;
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  margin: 20px 0px;
  margin-bottom: 10px;
`;

export const Paragraph = styled.p`
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 25px;
`;

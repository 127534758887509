import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useVideoSocket } from "../../hooks/useVideoSocket";
import { Modal, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { showNotification } from "../../../helpers/index";
import { playNotification } from "../../../helpers/index";
import { addToGTM } from "../../../helpers/tagManager.helper";
import {
  GTM_ENTRY_POINTS,
  GTM_EVENTS
} from "../../../constants/tagManager.constants";
import { getRxWriterLink } from "../../../helpers/rx-writer.helpers";

export default function VideoModal(props) {
  const { doctorId } = props;

  const userId = useSelector(state => state.userReducer?.user_id);
  const jwtToken = useMemo(() => localStorage.getItem("jwt") ?? "", [userId]);

  const [showVCPopup, setShowVCPopup] = useState(true);
  const [appointmentInfo, setAppointmentInfo] = useState(null);

  const {
    appointmentStatus,
    data,
    onJoinClicked,
    onRejectedClicked,
    socketStatus
  } = useVideoSocket(userId, jwtToken, doctorId);
  useEffect(() => {
    if (!showVCPopup) {
      playNotification();
    }
  }, [showVCPopup]);
  useEffect(() => {
    if (!appointmentInfo) return;
    if (appointmentInfo) {
      const { brand, source, appointmentId } = appointmentInfo;
      const appointmentReference = `${brand}-${source}-${appointmentId}`;
      const videoRedirectPath = getRxWriterLink(appointmentReference, {
        openVC: "true"
      });
      showNotification({
        brand: brand,
        link: videoRedirectPath,
        title: "",
        body: "",
        data: "",
        secTimeout: ""
      });
    }
  }, [appointmentInfo]);
  useEffect(() => {
    if (appointmentStatus?.isAppointmentMissed) {
      setShowVCPopup(false);
    }
  }, [appointmentStatus?.isAppointmentMissed]);

  const onRejectClick = () => {
    addToGTM({
      event: GTM_EVENTS?.APPOINTMENT_NOTIFICATION_CLOSED,
      appointmentNotificationClosedDetails: {
        callType: "video",
        appointmentId: appointmentInfo?.appointmentId
      }
    });
    setShowVCPopup(false);
    onRejectedClicked();
  };

  const onJoin = () => {
    if (!appointmentInfo) return;
    onJoinClicked();
    setShowVCPopup(false);
    const { brand, source, appointmentId } = appointmentInfo;
    const appointmentReference = `${brand}-${source}-${appointmentId}`;
    addToGTM({
      event: GTM_EVENTS?.RX_WRITER_OPENED,
      redirectDetails: {
        entryPoint:
          GTM_ENTRY_POINTS?.RX_WRITER_OPENED?.VIDEO_APPOINTMENT_NOTIFICATION
      }
    });
    window.open(
      getRxWriterLink(appointmentReference, { openVC: "true" }),
      "_self"
    );
  };
  useEffect(() => {
    if (appointmentStatus.isAppointmentReceived) {
      addToGTM({
        event: GTM_EVENTS?.APPOINTMENT_NOTIFICATION_VIEWED,
        appointmentNotificationDetails: {
          callType: "video",
          appointmentId: data?.data?.appointmentInfo?.appointmentId
        }
      });
      setShowVCPopup(true);
      setAppointmentInfo(data?.data?.appointmentInfo);
    }
  }, [appointmentStatus]);

  if (!socketStatus.isSocketConnected || socketStatus.isSocketError)
    return <></>;
  if (!(showVCPopup && appointmentInfo)) return <></>;

  return (
    <>
      <div>
        <div>
          <Modal
            show={showVCPopup}
            keyboard={false}
            dialogClassName="modal-center"
            centered
            style={{ overflow: "hidden" }}
          >
            <div className={styles["appointment-notification-wrapper"]}>
              <div className={styles["header-section"]}>
                <button
                  className={styles["close-button"]}
                  onClick={onRejectClick}
                >
                  <img
                    className={styles["close-button-icon"]}
                    src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1652106584/dochub/rx-writer/Subtract_5.png"
                  />
                </button>
              </div>
              <div className={styles["icon-section"]}>
                <img
                  className={styles["icon"]}
                  src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1652106471/dochub/rx-writer/Group_1.png"
                />
              </div>
              <div className={styles["message-section"]}>
                <div className={styles["message"]}>You have a</div>
                <div className={styles["message-highlight"]}>
                  New Appointment
                </div>
              </div>
              <div className={styles["button-section"]}>
                <button className={styles["view-button"]} onClick={onJoin}>
                  View
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./styles.scss";
import dataResource from "../../../../constants/dataResource";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import config from "../../../../constants/apiList";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { toast } from "react-toastify";
import { store } from "../../../../reducers/Persist";
import moment from "moment";
import { connect } from "react-redux";
import clevertap from "clevertap-web-sdk";
import { Select, MenuItem } from "@mui/material";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import {
  GTM_ENTRY_POINTS,
  GTM_EVENTS
} from "../../../../constants/tagManager.constants";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#E4AE2C"
  },
  "& .MuiRating-iconHover": {
    color: "#EFCE80"
  }
});
const extraStatuses = {
  suggestProcedure: { value: "csp", label: "Completed + Suggest Procedure" },
  suggestDiagnostics: {
    value: "csd",
    label: "Completed + Suggest Diagnostics"
  },
  specialistConsult: { value: "rf", label: "Completed + Specialist Refer" }
};
class AppointmentDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remarkActive: false,
      status: props.status,
      remarkActiveText: "Select Preset",
      remarksList: new Array(dataResource.remarksList.length).fill(false),
      remarks: "",
      stars: null
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.status != this.props.status)
      this.setState({ status: this.props.status });
  };
  componentDidMount = () => {
    const payload = {
      appointmentID: this.props.id
    };
    axios
      .post(config.url.CALL_STATUS_URL, payload)
      .then(res => {
        if (res.data.statusCode == 200) {
          this.setState({ status: res.data.body.status });
        } else {
          toast.success(res.data.body);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  onRemarksSelect = e => {
    let tempText;
    if (this.state.remarkActive === false) {
      tempText = "Type & Search";
    } else {
      tempText = "Select Preset";
    }
    this.setState({
      remarkActive: !this.state.remarkActive,
      remarkActiveText: tempText
    });
  };

  onSubmit = () => {
    if (this.state.stars && this.state.status !== null) {
      let remarksStr = this.state.remarks;
      for (let i = 0; i < this.state.remarksList.length; i++) {
        if (this.state.remarksList[i]) {
          if (remarksStr.length > 0) {
            remarksStr += ", ";
          }
          remarksStr += dataResource.remarksList[i];
        }
      }
      const payload = {
        doctorId: this.props.doctorId,
        form: this.props.form,
        id: this.props.id,
        remarks: remarksStr,
        status: this.state.status,
        appointment_rating: this.state.stars,
        user_id: this.props.user_id,
        prescription_id: this.props.prescription_id
      };
      axios
        .post(config.url.UPDATE_STATUS_URL, payload)
        .then(res => {
          toast.success(res.data.body.result);
          this.props.onClose();
          addToGTM({
            event: GTM_EVENTS?.STATUS_UPDATED,
            statusUpdatedData: {
              status: this.state.status,
              remarks: remarksStr,
              entryPoint:
                GTM_ENTRY_POINTS?.STATUS_UPDATED?.POST_PRESCRIPTION_SENT
            }
          });
          clevertap.event.push("Status Updated", {
            Source: this.props.form,
            Category: this.props.prescriptionData.category_label,
            "Appointment Status": payload.status,
            "Call Type":
              this.props.prescriptionData?.mode === "bw_video"
                ? "Video call"
                : "Voice Call",
            Appointments: this.props.id,
            Brand: this.props.brand,
            "Doctor ID": this.props.prescriptionData.doctor_id,
            "Doctor Name": this.props.patientDetails.doctor_name,
            "Call Duration": this.props.call_duration,
            Langauge: this.props.language
          });
          store.dispatch({
            type: "ADD_REMARK",
            data: { remarks: remarksStr, status: this.state.status }
          });
          this.setState({
            remarks: "",
            remarksList: new Array(dataResource.remarksList.length).fill(false)
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else toast.error("Enter Patient rating & Status to submit");
  };
  render() {
    return (
      <div className="appointmentDetailsModal">
        <Modal
          show={this.props.open}
          // onHide={() => this.props.onClose()}
          centered
          dialogClassName="prescription-report-modal"
        >
          <div className="appointmentDetailsModal__content">
            <h2>Appointment Details</h2>
            <div className="appointmentDetailsModal__content__header">
              <div>
                <span>ID</span>
                <p>
                  {this.props.brand}-{this.props.form}-{this.props.id}
                </p>
              </div>

              <div>
                <span>Date</span>
                <p>
                  {moment(this.props.prescriptionData.appointment_date).format(
                    "MMMM DD, YYYY"
                  )}
                </p>
              </div>
              <div>
                <span>Slot</span>
                <p>{this.props.prescriptionData.appointment_slot}</p>
              </div>
            </div>
            <div className="appointmentDetailsModal__content__body">
              <div className="appointmentDetailsModal__content__body__left">
                <p>Call Status</p>
                {/* <div div className="callStatus_right"> */}
                <Select
                  onChange={e => {
                    this.setState({ status: e.target.value });
                  }}
                  value={this.state.status}
                  className="callStatus__ADM"
                >
                  <MenuItem value="" selected hidden>
                    Select
                  </MenuItem>
                  {dataResource.postRxSendingStatusList.map(status => {
                    return (
                      <MenuItem
                        value={status.value}
                        key={status.value}
                        selected={this.state.status === status.value}
                      >
                        {status.label}
                      </MenuItem>
                    );
                  })}
                  {this.props.isSpecialistConsultRecommended && (
                    <MenuItem
                      value={extraStatuses.specialistConsult.value}
                      key={extraStatuses.specialistConsult.value}
                      selected={
                        this.state.status ===
                        extraStatuses.specialistConsult.value
                      }
                    >
                      {extraStatuses.specialistConsult.label}
                    </MenuItem>
                  )}
                  {this.props.isSuggestDiagnosticsRecommended && (
                    <MenuItem
                      value={extraStatuses.suggestDiagnostics.value}
                      key={extraStatuses.suggestDiagnostics.value}
                      selected={
                        this.state.status ===
                        extraStatuses.suggestDiagnostics.value
                      }
                    >
                      {extraStatuses.suggestDiagnostics.label}
                    </MenuItem>
                  )}
                  {this.props.isSuggestProcedureRecommended && (
                    <MenuItem
                      value={extraStatuses.suggestProcedure.value}
                      key={extraStatuses.suggestProcedure.value}
                      selected={
                        this.state.status ===
                        extraStatuses.suggestProcedure.value
                      }
                    >
                      {extraStatuses.suggestProcedure.label}
                    </MenuItem>
                  )}
                </Select>
                {/* </div> */}
              </div>
              <div className="appointmentDetailsModal__content__body__right">
                <div style={{ display: "flex" }}>
                  <p>Remarks</p>
                  <div className="remarks_right">
                    {/* <div
                      className="remarks_select"
                      onClick={this.onRemarksSelect}
                    >
                      {this.state.remarkActiveText}{" "}
                      {this.state.remarkActive ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </div> */}
                    {/* <div className="remarks_select_options">
                      {this.state.remarkActive &&
                        dataResource.remarksList.map((remark, index) => {
                          return (
                            <div
                              className="remarks_select_options__option"
                              key={index}
                            >
                              <input
                                type="checkbox"
                                id={`AD-${remark}`}
                                checked={this.state.remarksList[index]}
                                onChange={e => {
                                  let tempRemarksList = this.state.remarksList;
                                  tempRemarksList[index] =
                                    !tempRemarksList[index];
                                  this.setState({
                                    remarksList: tempRemarksList
                                  });
                                }}
                              />
                              <label htmlFor={`AD-${remark}`}>{remark}</label>
                            </div>
                          );
                        })}
                    </div> */}
                  </div>
                </div>
                <textarea
                  name="remark"
                  placeholder="Click to start Typing"
                  value={this.state.remarks}
                  onChange={e => this.setState({ remarks: e.target.value })}
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    resize: "none",
                    outline: "none",
                    border: 0,
                    padding: "4px",
                    background: "#F6F6F6"
                  }}
                />
              </div>
            </div>
            <div className="call-rating">
              <StyledRating
                name="customized-color"
                value={this.state.stars}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    this.setState({ stars: newValue });
                  }
                }}
                icon={<StarRoundedIcon style={{ fontSize: 50 }} />}
                emptyIcon={<StarRoundedIcon style={{ fontSize: 50 }} />}
              />
              <div>
                {" "}
                On a scale of 1-5, how likely is the patient to start the
                prescribed treatment?
              </div>
            </div>
            <div className="appointmentDetailsModal__content__bottom">
              <button onClick={this.onSubmit}>Submit</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    doctorId: store.getState().userReducer.hash_id,
    form: store.getState().rxWriterReducer.form,
    id: store.getState().rxWriterReducer.id,
    brand: store.getState().rxWriterReducer.brand,
    prescriptionData: store.getState().rxWriterReducer.prescriptionData,
    isSpecialistConsultRecommended:
      store.getState().rxWriterReducer.isSpecialistConsultRecommended,
    isSuggestProcedureRecommended:
      store.getState().rxWriterReducer.isSuggestProcedureRecommended,
    isSuggestDiagnosticsRecommended:
      store.getState().rxWriterReducer.isSuggestDiagnosticsRecommended,
    user_id: store.getState().rxWriterReducer.prescriptionData.user_id,
    prescription_id: store.getState().rxWriterReducer.prescriptionId,
    call_duration: store.getState().rxWriterReducer.call_duration,
    loggedInDr: store.getState().rxWriterReducer.loggedInDr,
    patientDetails: store.getState().rxWriterReducer.patientDetails,
    language: store.getState().rxWriterReducer.language
  };
};

export default connect(mapStateToProps)(AppointmentDetailsModal);

import React, { Component } from "react";

import Header from "./common/Header/Header";
import Footer from "./common/Footer/index";
import Loader from "react-loader-spinner";
import PatientFile from "./PatientFile";
import Observations from "./Observations";
import Perscription from "./Perscription";
import PrescriptionPreview from "./PrescriptionPreview";
import { store } from "../../reducers/Persist";
import { connect } from "react-redux";
import "./rxwriter.scss";
import axios from "axios";
import config from "../../constants/apiList";
import ReassignModal from "./ReassignModal";
import ViewSingleImageModal from "./common/ViewSingleImageModal";
import moment from "moment";
import QuickAccess from "./common/QuickAccess";
import clevertap from "clevertap-web-sdk";
import dataResource from "../../constants/dataResource";
import { addToGTM } from "../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../constants/tagManager.constants";
import { Modal } from "react-bootstrap";
import { isMobileCheck } from "../../helpers/isMobile.helper";
class RxWriterScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      loading: true,
      showReassignModal: true,
      disableActivity: false,
      isMobile: false,
      redirectModal: false
    };
  }
  getIDandCategory() {
    let idandCategory = this.state.id;
    let input = idandCategory.split("-");
    let category = input[1].toUpperCase();
    let brand = input[0].toUpperCase();
    return {
      id: input[2],
      category: category,
      brand: brand
    };
  }
  componentDidMount() {
    //this.setState({ loading: true });
    let idCategory = this.getIDandCategory();
    store.dispatch({ type: "SET_ALL_FALSE" });
    const payload = {
      doctorId: this.props.docKey,
      form: idCategory.category,
      id: idCategory.id,
      brand: idCategory.brand
    };
    const { standby, is_men, is_women } = this.props.user || {};
    axios
      .post(config.url.PRESCRIPTION_URL, payload)
      .then(res => {
        const appointmentComplete =
          dataResource.COMPLETED_APPOINTMENT_STATUSES.includes(
            res?.data?.body?.data?.status
          )
            ? true
            : false;
        if (
          res?.data?.body?.data?.doctor_id ===
            this?.props?.loggedInDr?.doctor_id ||
          this?.props?.user?.role_name === "admin"
        ) {
          this.setState({ showReassignModal: false });
        } else if (!standby) {
          this.setState({ disableActivity: true });
        } else if (standby && appointmentComplete) {
          this.setState({ disableActivity: true });
        }
        store.dispatch({
          type: "CHANGE_PRESCRIPTION_DATA",
          data: res.data.body.data
        });
        addToGTM({
          event: GTM_EVENTS?.APPOINTMENT_VIEWED,
          appointmentData: {
            ...(res?.data?.body?.data || {}),
            appointmentId: idCategory?.id
          }
        });
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });

    //API call to get body wise product
    let payload2 = {
      brand: idCategory.brand,
      doctorId: this.props.docKey
    };
    axios
      .post(config.url.GET_BODYWISE_PRODUCTS_URL, payload2)
      .then(res => {
        store.dispatch({
          type: "SET_BW_PRODUCT_DATA",
          payload: { bwProductData: res.data.body.data }
        });
      })
      .catch(err => {
        console.log(err);
      });

    //Api call to get preset data
    axios
      .get(config.url.GET_PRESET_URL)
      .then(res => {
        store.dispatch({ type: "CHANGE_PRESET_DATA", data: res.data.data });
        let obj = {};
        for (const item of res.data.data) {
          obj[item.id] = item;
        }
        store.dispatch({ type: "CHANGE_PRESET_ID_TO_OBJ", data: obj });
      })
      .catch(err => {
        console.log(err);
      });
    store.dispatch({ type: "CHANGE_BRAND", data: idCategory.brand });
    store.dispatch({ type: "CHANGE_FORM", data: idCategory.category });
    store.dispatch({ type: "CHANGE_ID", data: idCategory.id });
    const isMobile = isMobileCheck();
    this.setState({ isMobile });
  }

  handleRedirect = () => {
    this.setState({ redirectModal: false });
    this.props.history.push("/dashboard");
  };

  render() {
    return (
      <div className="rxwriter-container">
        <Modal
          show={this.state.isMobile}
          centered
          dialogClassName="redirect-modal"
        >
          <div
            style={{
              margin: "10px",
              opacity: "0.5",
              fontSize: "20px"
            }}
          >
            Kindly use a laptop/desktop to access DocHub
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <button
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                borderRadius: "12px",
                padding: "10px",
                margin: "10px",
                color: "white",
                fontWeight: "600",
                backgroundColor: "#379F8E"
              }}
              onClick={this.handleRedirect}
            >
              Redirect to dashboard
            </button>
          </div>
        </Modal>
        {!!this.props.showSingleImageModal &&
          this.props.showSingleImageModal.show && (
            <ViewSingleImageModal
              modalOpen={this.props.showSingleImageModal.show}
              closeModal={() =>
                store.dispatch({
                  type: "CLOSE_SINGLE_IMAGE_MODAL"
                })
              }
              image_src={this.props.showSingleImageModal.image_src}
              image_arr={this.props.showSingleImageModal.image_arr}
              date={
                !!this.props.showSingleImageModal.image_date &&
                moment(this.props.showSingleImageModal.image_date).format(
                  "MMMM D, YYYY (dddd)"
                )
              }
            />
          )}
        {this.state.loading ? (
          <div className="rxwriter-loader-container">
            <Loader type="TailSpin" />
          </div>
        ) : (
          <div>
            {!this.state.isMobile && (
              <ReassignModal
                show={this.state.showReassignModal}
                setShow={show => this.setState({ showReassignModal: show })}
                appointmentId={this.state.id?.split("-")[2]}
                doctorId={this.props.loggedInDr.doctor_id}
                disableActivity={this.state.disableActivity}
              />
            )}
            <Header
              showReassignModal={() =>
                this.setState({ showReassignModal: true })
              }
            />
            <QuickAccess />
            <div className="RxWriterScreenBody">
              {this.props.currentTab === 0 ? (
                <PatientFile
                  showReassignModal={() =>
                    this.setState({ showReassignModal: true })
                  }
                />
              ) : null}
              {this.props.currentTab === 1 ? <Observations /> : null}
              {this.props.currentTab === 2 ? <Perscription /> : null}
              {/* //FIXME: Just at development remove before build */}
              {this.props.currentTab === 3 ? <PrescriptionPreview /> : null}
            </div>
            <Footer />
          </div>
        )}
      </div>
    );
  }
}
const mapSateToProps = state => {
  const id = store.getState().rxWriterReducer.id;
  return {
    currentTab: store.getState().footerReducer.currentTab,
    loggedInDr: store.getState().rxWriterReducer.loggedInDr,
    user: store.getState().userReducer,
    docKey: store.getState().userReducer.hash_id,
    rx: store.getState().rxWriterReducer,
    showSingleImageModal:
      store.getState().observationReducer[id]?.singleImageModal
  };
};

export default connect(mapSateToProps)(RxWriterScreen);

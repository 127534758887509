import React, { useEffect, useState } from "react";
import "./styles.scss";
import OrderSelectCard from "./OrderSelectCard";
import { store } from "../../../../reducers/Persist";
import { useSelector } from "react-redux";
// import axios from "axios";
// import config from "../../../../constants/apiList";

const OrderSelect = ({ editActive }) => {
  const { prescription_orderSelect: products } = useSelector(state => state);
  return (
    <>
      {products.length > 0 ? (
        <div className="orderSelect">
          <div className="orderSelect_content">
            <div className="orderSelect_content_header">
              <div className="OS_header_product">
                <p>Product</p>
              </div>
              <div className="OS_header_days">Days</div>
              <div className="OS_header_cartQty">Cart Qty.</div>
              <div className="OS_header_instructions">Instructions</div>
              <div className="OS_header_ingredients">Ingredients</div>
              <div className="OS_header_modeOfAction">Mode of Action</div>
              <div className="OS_header_expectationSetting">
                Expectation Setting
              </div>
            </div>
            {products.map((val, index) => (
              <OrderSelectCard
                product={val}
                key={index}
                index={index}
                editActive={true}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};
export default OrderSelect;

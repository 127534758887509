import React, { useMemo } from "react";
import MaterialTable from "material-table";

export default function Table5({ title, cols, rows, pageSize, search }) {
  return (
    <div id={title.replace(/\s/g, "").toLowerCase()}>
      <MaterialTable
        columns={cols}
        data={useMemo(() => rows, [rows])}
        title={title}
        options={{
          search: search ? search : false,
          exportButton: true,
          exportAllData: true,
          pageSize: pageSize ? pageSize : 100,
          sorting: false,
          headerStyle: {
            backgroundColor: "#003459",
            color: "#FFF"
          },
          filtering: true
        }}
        style={{ margin: "20px 10px 10px" }}
      />
    </div>
  );
}

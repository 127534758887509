import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import PatientInfo from "../PatientInfo/PatientInfo";
import "./PrescriptionPage.scss";
import {
  Button,
  Dropdown,
  DropdownButton,
  Alert,
  Modal,
  Form,
  Table
} from "react-bootstrap";
import Iframe from "react-iframe";
import Loader from "react-loader-spinner";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import PrescriptionViewer from "../PrescriptionViewer/PrescriptionViewer";
import PrescriptionEditor from "../PrescriptionEditor/PrescriptionEditor";
import { alertBox, confirmBox } from "../../common/PopupBox/PopupBox";
import History from "./../History/History";
import dataResource from "../../../constants/dataResource";
import UtilityService from "./../../../services/utilities";
import { store } from "../../../reducers/Persist";
import MealPlanner from "../NewPrescription/MealPlanner";
import QuickView from "../QuickView/QuickView";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { FaVideo } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import RefreshIcon from "@material-ui/icons/Refresh";
import ImageTimeLine from "./ImageTimeLine";
import StatusPopUp2 from "./StatusPopUp2";
import CustomDropdownInput from "../../common/CustomDropdownInput";
import ReassignModal from "../ReassignModal";
import Axios from "axios";
import { isMobileCheck } from "../../../helpers/isMobile.helper";
import { addToGTM } from "../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../constants/tagManager.constants";
import { getRxWriterLink } from "../../../helpers/rx-writer.helpers";

var statusBox = {};
var statusObj = {};
let bw_prod = {};
let prod_data = [];
dataResource.statusList.forEach((obj, index) => {
  statusObj[obj.value] = obj.label;
});
var stateObj = {};

let nutritionist_id = [44, 45, 46, 47, 154, 150, 152, 151, 156, 153, 155];
export default class PrescriptionPage extends Component {
  constructor(props) {
    super(props);
    this.userID = React.createRef();
    this.state = this.getInitialState();
  }
  getInitialState = () => ({
    /* state props */
    image_timeline: null,
    category1: "",
    form_details: {},
    data: {},
    statusModal: false,
    report_data: {},
    report_show: false,
    report_pdf: "",
    report_prescription_id: "",
    report_email: "",
    report_emailState: "",
    activityLog: [],
    recommended: [],
    other_recommended: [],
    recommended_new: {},
    //recommendedBW:[],
    all_bw_products: [],
    remarks: "",
    complaints: "",
    history: "",
    diagnosis: "",
    description: "",
    instructions: [],
    prescriptionsList_other: [],
    prescriptionsList: [],
    prescriptionsList_new: {},
    //prescriptionsListBW:[],
    show: false,
    bodyData: null,
    productData: null,
    userId: null,
    category: null,
    editItem: {},
    editItem_new: {},
    recommendationChange: 0,
    recommendationChange_new: {},
    remarkList: dataResource.remarksList,
    complaintList: dataResource.complaintsList,
    diagnosisList: dataResource.diagnosisList,
    historyList: dataResource.historyList,
    instructionsList: dataResource.instructionsList,
    status: { value: "o", label: "Open" },
    orderPlaced: 0,
    orderId: "",
    showingPatientHistory: false,
    rescheduled: false,
    docKey: store.getState().userReducer.hash_id,
    prescriptionID: "",
    loggedInDr: {},
    spinner: false,
    doctor_id: -1,
    nicotex: false,
    customer_id: null,
    app_installed: false,
    app: false,
    mode: null,
    fetchModeLoader: false,
    nutritionistList: [],
    showAssignModal: false,
    isMobile: isMobileCheck(),
    redirectModal: false
  });
  fetchNutritionist = async () => {
    const id = this.props.match.params.id;
    if (!(id && id.length)) return;
    const idParameters = id.split("-");
    const brand = idParameters[0];
    const brandCategory = {
      MM: "is_men",
      BW: "is_women"
    };
    const requestData = {
      categories: ["weight", brandCategory[brand]]
    };
    await axios
      .post(CONFIG_CONSTANTS.url.GET_DOCTORS_BY_CATEGORY, requestData)
      .then(res => {
        if (res.data.success) {
          this.setState({
            nutritionistList: res.data.results.map(doctor => doctor.doctor_id)
          });
        } else {
          console.log(
            "ERROR while fetching Nutrtionist List",
            res.data.message
          );
        }
      })
      .catch(err => {
        console.log("Unable to fetch Nutrtionist list", err);
      });
  };
  updateShowAssignModal = () => {
    const loggedInDoctorId =
      this.state.loggedInDr && this.state.loggedInDr.doctor_id;
    const appointmentDoctorId =
      this.state.bodyData &&
      this.state.bodyData.body &&
      this.state.bodyData.body.data &&
      this.state.bodyData.body.data.doctor_id;
    if (!(appointmentDoctorId && loggedInDoctorId)) return;
    this.setState({ showAssignModal: loggedInDoctorId != appointmentDoctorId });
  };
  componentDidMount() {
    stateObj = this.state;
    this.setupBeforeUnloadListener();
    !!store.getState().userReducer
      ? this.setState({ docKey: store.getState().userReducer.hash_id }, () => {
          console.log(this.state);
          let data = {
            doctor_id: store.getState().userReducer.hash_id
          };
          let link = CONFIG_CONSTANTS.url.GET_DOCTOR_INFO_URL;
          axios.post(link, data).then(resp => {
            if (resp.status == 200) {
              this.setState(
                { loggedInDr: resp.data.body.data },
                this.updateShowAssignModal
              );
            }
          });

          if (this.props.match.params.id) {
            this.userID.current.value = this.props.match.params.id;
            this.handleUserSearch();
          }
        })
      : setTimeout(() => {
          this.setState(
            { docKey: store.getState().userReducer.hash_id },
            () => {
              let data = {
                doctor_id: store.getState().userReducer.hash_id
              };
              let link = CONFIG_CONSTANTS.url.GET_DOCTOR_INFO_URL;
              axios.post(link, data).then(resp => {
                if (resp.status == 200) {
                  this.setState(
                    { loggedInDr: resp.data.body.data },
                    this.updateShowAssignModal
                  );
                }
              });

              //console.log(this.state);
              if (this.props.match.params.id) {
                this.userID.current.value = this.props.match.params.id;
                this.handleUserSearch();
              }
            }
          );
        }, 2000);
  }
  setModalState = (p, v) => this.setState({ [p]: v });
  setupBeforeUnloadListener = () => {
    // let data = {
    //   doctorId: this.state.docKey,
    //   form: this.state.category.value,
    //   id: this.state.userId,
    //   status: this.state.status.value,
    //   remarks: this.state.remarks,
    // };
    // let statusData = {
    //   status:data.status,
    //   remarks:data.remarks
    // }
    window.addEventListener("beforeunload", event => {
      let data = {
        doctorId: this.state.docKey,
        form: this.state.category?.value,
        id: this.state.userId,
        status: this.state.status.value,
        remarks: this.state.remarks
      };
      let statusData = {
        status: data.status,
        remarks: data.remarks
      };
      //event.returnValue = `Are you sure you want to leave?`;

      if (
        !window.forceReloadWithoutChecking &&
        statusData.status == statusBox.status.value &&
        statusData.remarks == statusBox.remarks &&
        this.state.orderPlaced == 0
      ) {
        event.returnValue = `Are you sure you want to leave without updating?`;
      }
    });
  };
  componentDidUpdate() {
    //this.setupBeforeUnloadListener();
    window.onpopstate = e => {
      //your code...
      this.userID.current.value = this.props.match.params.id;
      this.handleUserSearch();
    };
  }

  onInstructionChange = instructions => {
    this.setState({
      instructions
    });
  };

  setPrescriptionID = prescription_id => {
    this.setState({ prescriptionID: prescription_id });
  };

  showPatientHistory = () => {
    this.setState({ showingPatientHistory: true });
  };

  closeHistory = () => {
    this.setState({ showingPatientHistory: false });
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleParentState = data => {
    let obj = {
      ...this.state,
      bodyData: { ...this.state.bodyData, body: data }
    };
    this.setState(obj, this.updateShowAssignModal);
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleClose_new(e) {
    if (e) e.preventDefault();
    console.log("handleclose new");
    console.log(this.state);
    this.setState({ report_show: false });
  }

  handleShow_new() {
    this.setState({ report_show: true });
  }

  handleGetDetails = data => {
    console.log(data);
    let prescriptionsList = [...this.state.prescriptionsList, ...data];
    let tempList = prescriptionsList;
    const recommendArr = this.state.recommended.map(item => ({
      id: item.id,
      quantity: parseInt(item.quantity ? item.quantity : 0)
    }));
    const changedArr = tempList.map(item => ({
      id: item.product.prod_id,
      quantity: Number(item.cart_quantity)
    }));
    recommendArr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    changedArr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    let changeFlag = !(
      JSON.stringify(recommendArr) == JSON.stringify(changedArr)
    );
    let flag = changeFlag ? 1 : 0;
    //let temp_recommendationChange_new= this.state.recommendationChange_new;
    //temp_recommendationChange_new[value] =flag;
    console.log(prescriptionsList);
    this.setState({
      prescriptionsList: prescriptionsList,
      editItem: {}
      //recommendationChange_new: temp_recommendationChange_new
    });
  };
  handleGetDetails_new = (data, value) => {
    console.log(data);
    let prescriptionsList = [
      ...this.state.prescriptionsList_new[value],
      ...data
    ];

    let tempList = prescriptionsList;
    const recommendArr = this.state.recommended_new[value].map(item => ({
      id: item.id,
      quantity: parseInt(item.quantity ? item.quantity : 0)
    }));
    const changedArr = tempList.map(item => ({
      id: item.product.prod_id,
      quantity: Number(item.cart_quantity)
    }));
    recommendArr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    changedArr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    let changeFlag = !(
      JSON.stringify(recommendArr) == JSON.stringify(changedArr)
    );
    let flag = changeFlag ? 1 : 0;
    console.log(prescriptionsList);
    let temp_prescriptionsList = this.state.prescriptionsList_new;
    temp_prescriptionsList[value] = prescriptionsList;
    console.log("bingooooooo");
    this.setState({
      prescriptionsList_new: temp_prescriptionsList,
      editItem: {},
      recommendationChange: flag
    });
  };
  getFormattedDateUTC(date) {
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    return (
      date.getFullYear() +
      "-" +
      ("00" + date.getMonth()).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2)
    );
  }

  pushActivityToState() {
    const date = new Date();
    const newDate = new Date(date.setMonth(date.getMonth() + 1));
    //var dateStr = this.getFormattedDateUTC(newDate);
    var dateStr = moment().subtract(5.5, "hours").format("YYYY-MM-DD HH:mm:ss");
    let newActivity = {
      updated_dttm: dateStr,
      remarks: this.state.remarks,
      status: this.state.status.value
    };
    let temp = [...this.state.activityLog];
    temp.unshift(newActivity);
    this.setState({ activityLog: temp });
  }

  handleWindowClose = () => {
    let data = {
      doctorId: this.state.docKey,
      form: this.state.category.value,
      id: this.state.userId,
      status: this.state.status.value,
      remarks: this.state.remarks
    };
    let statusData = {
      status: data.status,
      remarks: data.remarks
    };
    if (
      statusData.status == statusBox.status.value &&
      statusData.remarks == statusBox.remarks
    ) {
      return "You have unsaved changes. Are you sure you want to leave?";
    }
    return "You have attempted to leave this page. Are you sure?";
  };

  // if(statusData.status==statusBox.status.value&&statusData.remarks==statusBox.remarks){
  //   alertBox("Are you sure you want to proceed without updating?");
  // }
  //}

  handleRemarks = e => {
    e.preventDefault();
    this.pushActivityToState();
    let data = {
      doctorId: this.state.docKey,
      form: this.state.category.value,
      id: this.state.userId,
      status: this.state.status.value,
      remarks: this.state.remarks
    };
    // let statusData = {
    //   status:data.status,
    //   remarks:data.remarks
    // }
    // if(statusData.status==statusBox.status.value&&statusData.remarks==statusBox.remarks){
    //   alertBox("Are you sure you want to proceed without updating?");
    // }
    this.setState({ data: data });
    axios.post(CONFIG_CONSTANTS.url.UPDATE_STATUS_URL, data).then(resp => {
      this.setState({ remarks: "" });
    });
  };

  createRecommendedListBW() {
    let recommended = [];
    let other_recommended = [];
    let prodMap = this.state.all_bw_products ? this.state.all_bw_products : [];
    this.state.bodyData &&
      this.state.all_bw_products &&
      this.state.bodyData.body.data.products.map(item => {
        if (this.state.all_bw_products[item.id]) {
          let record = this.state.all_bw_products[item.id];

          let obj = {
            notes: record.usage_instructions || "",
            unit: record.usage_unit ? record.usage_unit : "",
            frequency: record.usage_frequency ? record.usage_frequency : "",
            days: 0,
            name: record.name,
            id: item,
            quantity: record.quantity ? record.quantity : 0,
            cart_quantity: item.cart_quantity ? item.cart_quantity : 1
          };
          recommended.push(obj);
        }
      });

    this.setState({
      recommended: recommended
    });
    this.state.bodyData &&
      this.state.all_bw_products &&
      this.state.bodyData.body.data.other_products &&
      this.state.bodyData.body.data.other_products.map(item => {
        let record = this.state.all_bw_products[item.id];

        let obj = {
          notes: record.usage_instructions || "",
          unit: record.usage_unit ? record.usage_unit : "",
          frequency: record.usage_frequency ? record.usage_frequency : "",
          days: 0,
          name: record.name,
          id: item,
          quantity: record.quantity ? record.quantity : 0,
          cart_quantity: item.cart_quantity ? item.cart_quantity : 1
        };
        other_recommended.push(obj);
      });
    this.setState({
      other_recommended: other_recommended
    });
  }
  createRecommendedListBW_new() {
    let recommended = [];
    let prodMap = this.state.all_bw_products ? this.state.all_bw_products : [];
    this.state.bodyData &&
      this.state.all_bw_products &&
      this.state.bodyData.body.data.products.map(item => {
        let record = this.state.all_bw_products[item.id];

        let obj = {
          product: { prod_name: record.name },
          notes: record.usage_instructions || "",
          unit: record.usage_unit ? record.usage_unit : "",
          frequency: record.usage_frequency ? record.usage_frequency : "",
          days: 0,
          name: record.name,
          id: item,
          quantity: record.quantity ? record.quantity : 0,
          cart_quantity: item.cart_quantity ? item.cart_quantity : 1
        };
        recommended.push(obj);
      });
    let temp_recommended_new = {};
    this.state.bodyData &&
      this.state.bodyData.body.data.prescription_order_ids.map(id => {
        temp_recommended_new[id] = [];
        console.log(id + " line 363");
        let map1 = new Map();
        this.state.bodyData.body.data.newItems[id].forEach((item, index) => {
          console.log("line 367");
          console.log(item);
          let record = this.state.all_bw_products[item.product_id];
          let obj = {
            notes: record.usage_instructions || "",
            unit: record.usage_unit ? record.usage_unit : "",
            frequency: record.usage_frequency ? record.usage_frequency : "",
            days: 0,
            name: record.name,
            id: item,
            quantity: record.quantity ? record.quantity : 0,
            cart_quantity: item.quantity ? item.quantity : 1
          };
          temp_recommended_new[id].push(obj);
        });
      });
    this.setState({
      recommended_new: temp_recommended_new,
      prescriptionsList: recommended
    });
  }
  createRecommendedListNew() {
    console.log("line 280");
    this.state.bodyData &&
      this.state.bodyData.body.data.prescription_order_ids.map(id => {
        console.log(id);
        let map1 = new Map();
        if (
          this.state.bodyData &&
          this.state.bodyData.body.data.product_items_new &&
          this.state.bodyData.body.data.product_items_new[id]
        ) {
          this.state.bodyData &&
            this.state.bodyData.body.data.product_items_new[id].forEach(
              (item, index) => {
                console.log("287");
                console.log(
                  this.state.bodyData.body.data.product_items_new[id]
                );
                map1.set(item.product_id, {
                  notes: item.instruction[0] || "",
                  unit: item.unit ? item.unit[0] : "",
                  frequency: item.frequency ? item.frequency[0] : "",
                  days: 0,
                  name: item.name,
                  id: item.product_id,
                  quantity: item.quantity
                });
              }
            );
        }
        console.log("line 300");
        if (
          this.state.bodyData &&
          this.state.bodyData.body.data.product_kits_new &&
          this.state.bodyData.body.data.product_kits_new[id]
        ) {
          this.state.bodyData &&
            this.state.bodyData.body.data.product_kits_new[id].forEach(
              (item, index) => {
                item.product_items.forEach((singleItem, index) => {
                  if (map1.has(singleItem.product_id)) {
                    let quant =
                      map1.get(singleItem.product_id).quantity +
                      singleItem.quantity;
                    map1.set(singleItem.product_id, {
                      name: singleItem.name,
                      notes: singleItem.instruction
                        ? singleItem.instruction[0]
                        : "",
                      days: 0,
                      unit: singleItem.unit ? singleItem.unit[0] : "",
                      frequency: singleItem.frequency
                        ? singleItem.frequency[0]
                        : "",
                      id: singleItem.product_id,
                      quantity: quant
                    });
                  } else {
                    console.log(singleItem);
                    console.log("323");
                    map1.set(singleItem.product_id, {
                      name: singleItem.name,
                      notes: singleItem.instruction
                        ? singleItem.instruction
                        : "",
                      days: 0,
                      unit: singleItem.unit ? singleItem.unit[0] : "",
                      id: singleItem.product_id,
                      quantity: singleItem.quantity,
                      frequency: singleItem.frequency
                        ? singleItem.frequency[0]
                        : ""
                    });
                  }
                });
              }
            );
        }
        console.log("line 335");
        for (const value of map1.entries()) {
          let temp = this.state.recommended_new;
          console.log(temp);
          console.log("line 341");
          console.log(id);
          if (!temp[id]) {
            temp[id] = [];
          }
          temp[id] = [...temp[id], value[1]];
          this.setState({
            recommended_new: temp
            // recommended_new[id]
            // recommended: [...this.state.recommended, value[1]]
          });
        }
      });

    // console.log("**********recommended**************")
    // console.log(this.state.recommended)
  }
  createRecommendedList() {
    let map1_other = new Map();
    let map1 = new Map();
    this.state.bodyData &&
      this.state.bodyData.body.data.product_items.forEach((item, index) => {
        map1.set(item.product_id, {
          notes: item.instruction[0] || "",
          unit: item.unit ? item.unit[0] : "",
          frequency: item.frequency ? item.frequency[0] : "",
          days: 0,
          name: item.name,
          id: item.product_id,
          quantity: item.quantity
        });
      });
    this.state.bodyData &&
      this.state.bodyData.body.data.product_kits.forEach((item, index) => {
        item.product_items.forEach((singleItem, index) => {
          if (map1.has(singleItem.product_id)) {
            let quant =
              map1.get(singleItem.product_id).quantity + singleItem.quantity;
            // console.log(map1);
            // console.log(singleItem);
            // console.log(quant);
            map1.set(singleItem.product_id, {
              name: singleItem.name,
              notes: singleItem.instruction[0] || "",
              days: 0,
              unit: singleItem.unit ? singleItem.unit[0] : "",
              frequency: singleItem.frequency ? singleItem.frequency[0] : "",
              id: singleItem.product_id,
              quantity: quant
            });
          } else {
            map1.set(singleItem.product_id, {
              name: singleItem.name,
              notes: singleItem.instruction ? singleItem.instruction[0] : "",
              days: 0,
              unit: singleItem.unit ? singleItem.unit[0] : "",
              id: singleItem.product_id,
              quantity: singleItem.quantity,
              frequency: singleItem.frequency ? singleItem.frequency[0] : ""
            });
          }
        });
      });
    for (const value of map1.entries()) {
      this.setState({
        recommended: [...this.state.recommended, value[1]]
      });
    }
    // console.log("**********recommended**************")
    // console.log(this.state.recommended)
    //********************HANDLE OTHER RECOMMENDED PRODUCTS********************
    let other_map1 = new Map();
    this.state.bodyData &&
      this.state.bodyData.body &&
      this.state.bodyData.body.data &&
      this.state.bodyData.body.data.other_product_items &&
      this.state.bodyData.body.data.other_product_items.forEach(
        (item, index) => {
          other_map1.set(item.product_id, {
            notes: item.instruction[0] || "",
            unit: item.unit ? item.unit[0] : "",
            frequency: item.frequency ? item.frequency[0] : "",
            days: 0,
            name: item.name,
            id: item.product_id,
            quantity: item.quantity
          });
        }
      );
    this.state.bodyData &&
      this.state.bodyData.body &&
      this.state.bodyData.body.data &&
      this.state.bodyData.body.data.other_product_kits &&
      this.state.bodyData.body.data.other_product_kits.forEach(
        (item, index) => {
          item.product_items.forEach((singleItem, index) => {
            if (map1.has(singleItem.product_id)) {
              let quant =
                map1.get(singleItem.product_id).quantity + singleItem.quantity;
              // console.log(map1);
              // console.log(singleItem);
              // console.log(quant);
              map1.set(singleItem.product_id, {
                name: singleItem.name,
                notes: singleItem.instruction[0] || "",
                days: 0,
                unit: singleItem.unit ? singleItem.unit[0] : "",
                frequency: singleItem.frequency ? singleItem.frequency[0] : "",
                id: singleItem.product_id,
                quantity: quant
              });
            } else {
              other_map1.set(singleItem.product_id, {
                name: singleItem.name,
                notes: singleItem.instruction[0] || "",
                days: 0,
                unit: singleItem.unit ? singleItem.unit[0] : "",
                id: singleItem.product_id,
                quantity: singleItem.quantity,
                frequency: singleItem.frequency ? singleItem.frequency[0] : ""
              });
            }
          });
        }
      );
    for (const value of other_map1.entries()) {
      this.setState({
        other_recommended: [...this.state.other_recommended, value[1]]
      });
    }
  }

  getIDandCategory() {
    let idandCategory = this.userID.current.value;
    // console.log(idandCategory);
    // console.log("idandCategory");
    let input = idandCategory.split("-");
    let category = input[1].toUpperCase();
    let brand = input[0].toUpperCase();
    if (dataResource.categoryList.includes(category)) {
      this.setState({
        userId: input[2],
        category: { value: category },
        brand: brand
      });
    } else {
      alertBox("Invalid Category...Please check the input", () => {
        this.clearState();
      });
    }
    return {
      id: input[2],
      category: category,
      brand: brand
    };
  }

  handleUserSearch = () => {
    this.fetchNutritionist();
    var dataDoctorInfo = {
      doctor_id: store.getState().userReducer.hash_id
    };
    let link = CONFIG_CONSTANTS.url.GET_DOCTOR_INFO_URL;
    axios.post(link, dataDoctorInfo).then(resp => {
      if (resp.status == 200) {
        this.setState(
          { loggedInDr: resp.data.body.data },
          this.updateShowAssignModal
        );
      }
    });
    this.setState(this.getInitialState(), () => {
      if (this.props.match.params.id !== this.userID.current.value) {
        this.props.history.push(getRxWriterLink(this.userID.current.value));
      }
      let idCategory = this.getIDandCategory();
      let data = {
        doctorId: this.state.docKey,
        form: idCategory.category,
        id: idCategory.id,
        brand: idCategory.brand
      };
      this.setState({ spinner: true });
      axios.post(CONFIG_CONSTANTS.url.PRESCRIPTION_URL, data).then(response => {
        if (response.statusCode == 200 || response.data.statusCode == 200) {
          let status = dataResource.statusList.filter(
            sItem => response.data.body.data.status === sItem.value
          );
          let nicotex = false;
          if (
            response.data.body.data.form_details &&
            response.data.body.data.form_details["Health"][
              "How many cigarettes do you smoke in a day?"
            ]
          ) {
            nicotex =
              response.data.body.data.form_details["Health"][
                "How many cigarettes do you smoke in a day?"
              ];
            if (nicotex.includes("10")) {
              nicotex = "2mg";
            } else if (nicotex.includes("30")) {
              nicotex = "4mg";
            }
          }
          this.setState({
            category1: response.data.body.data.category,
            form_details: response.data.body.data.form_details
              ? response.data.body.data.form_details
              : {},
            image_timeline: {
              email: response.data.body.data.email,
              phone: response.data.body.data.phone
            },
            schedule_token: response.data.body.data.schedule_token,
            customer_id: response.data.body.data.user_id,
            app_installed: response.data.body.data.app_installed,
            app: response.data.body.data.app,
            mode: response.data.body.data.mode,
            doctor_id: response.data.body.data.doctor_id,
            nicotex
          });
          let data = {
            brand: "BW",
            doctorId: store.getState().userReducer.hash_id
          };
          this.setState(
            {
              activityLog: [...response.data.body.data.remarks].reverse(),
              other_recommended: [],
              recommended: [],
              remarks: "",
              complaints: "",
              history: "",
              diagnosis: "",
              description: "",
              prescriptionsList: [],
              prescriptionsList_other: [],
              prescriptionsList_other: [],
              bodyData: response.data,
              report_email: response.data.body.data.email,
              // all_bw_products: res.data.body.data,
              status: status[0],
              orderPlaced: response.data.body.data.form_order_placed,
              orderId: response.data.body.data.order_id,
              rescheduled: response.data.body.data.form_order_placed
              // productData:
              //   this.state.brand == "BW"
              //     ? productDataWithCustomProducts
              //     : this.state.productData
            },
            this.updateShowAssignModal
          );
          this.getProductDetails();
          let temp_pres_new = this.state.prescriptionsList_new;
          let temp_editItem_new = this.state.editItem_new;
          if (response.data.body.data.newItems) {
            Object.keys(response.data.body.data.newItems).map(value => {
              console.log("*************DAYUM*************");
              temp_pres_new[value] = [];
              temp_editItem_new[value] = {};
            });
          }
          this.setState({
            prescriptionsList_new: temp_pres_new,
            editItem_new: temp_editItem_new
          });
          statusBox = {
            status: status[0],
            remarks: ""
          };
          addToGTM({
            event: GTM_EVENTS?.APPOINTMENT_VIEWED,
            appointmentData: {
              ...(response?.data?.body?.data || {}),
              appointmentId: idCategory?.id
            }
          });
        }
      });
    });
  };

  getProductDetails() {
    if (this.state.brand == "MM") {
      this.getMMProducts();
    } else if (this.state.brand == "BW") {
      this.getBWProducts();
    } else {
      this.setState({ spinner: false, productData: [] });
    }
  }

  // getProducts(pdName) {
  //   let productURL =
  //     pdName === "hm"
  //       ? CONFIG_CONSTANTS.url.HM_PRODUCTS_URL
  //       : CONFIG_CONSTANTS.url.PM_PRODUCTS_URL;
  //   axios
  //     .get(productURL)
  //     .then(response => {
  //       this.assignAndMapProducts(response.data);
  //     })
  //     .catch(function (response) {});
  // }

  getMMProducts() {
    delete axios.defaults.headers.common["Authorization"];
    const productUrls = [
      axios.get(CONFIG_CONSTANTS.url.HM_PRODUCTS_URL),
      axios.get(CONFIG_CONSTANTS.url.PM_PRODUCTS_URL),
      axios.get(CONFIG_CONSTANTS.url.IM_PRODUCTS_URL),
      axios.get(CONFIG_CONSTANTS.url.HYM_PRODUCTS_URL),
      axios.get(CONFIG_CONSTANTS.url.SM_PRODUCTS_URL),
      axios.get(CONFIG_CONSTANTS.url.SKM_PRODUCTS_URL),
      axios.get(CONFIG_CONSTANTS.url.WM_PRODUCTS_URL),
      axios.get(CONFIG_CONSTANTS.url.NM_PRODUCTS_URL),
      axios.get(CONFIG_CONSTANTS.url.BM_PRODUCTS_URL)
    ];
    axios
      .all(productUrls)
      .then(productsResponse => {
        this.setState({ spinner: false });
        let response = [];
        console.log("---productsResponse----", productsResponse);
        productsResponse.forEach(productResponse => {
          if (productResponse.data) {
            response = [...response, ...productResponse.data];
          }
        });
        console.log("--response----", response);
        if (!!localStorage.getItem("jwt")) {
          axios.defaults.headers.common["Authorization"] =
            localStorage.getItem("jwt");
        }

        this.createRecommendedList();
        if (this.state.bodyData.body.data.prescription_order_ids)
          this.createRecommendedListNew();
        this.assignAndMapProductsMM(response);
      })
      .catch(err => {
        console.log("--getMMProducts-err----", err);
        return err;
      });
  }

  getBWProducts = () => {
    let data = {
      brand: "BW",
      doctorId: store.getState().userReducer.hash_id
    };
    let product_response = [];
    //let url = `https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/get_products`;
    axios
      .post(CONFIG_CONSTANTS.url.GET_BODYWISE_PRODUCTS_URL, data)
      .then(response => {
        this.setState({ spinner: false });
        console.log("**************get_all_products**************");
        console.log(response.data.body.data);
        bw_prod = response.data.body.data;
        const entries = Object.entries(response.data.body.data);
        entries.map(item => {
          let obj = {
            id: item[0],
            prod_id: item[0],
            prod_cat: item[1].category,
            name: item[1].name,
            prod_name: item[1].name,
            frequency: item[1].usage_frequency ? item[1].usage_frequency : "",
            usage_frequency: item[1].usage_frequency
              ? item[1].usage_frequency
              : "",
            notes: item[1].usage_instructions ? item[1].usage_instructions : "",
            usage_instructions: item[1].usage_instructions
              ? item[1].usage_instructions
              : "",
            unit: item[1].usage_unit ? item[1].usage_unit : "",
            quantity: item[1].quantity ? item[1].quantity : 1,
            prescription_quantity: item[1].prescription_quantity
              ? item[1].prescription_quantity
              : 1,
            cart_quantity: item[1].cart_quantity ? item[1].cart_quantity : 1,
            prod_type: "single"
          };
          product_response.push(obj);
        });
        this.setState({
          all_bw_products: response.data.body.data
        });
        this.createRecommendedListBW();
        // if (this.state.bodyData.body.data.prescription_order_ids)
        if (
          this.state.bodyData &&
          this.state.bodyData.body &&
          this.state.bodyData.body.data &&
          this.state.bodyData.body.data.prescription_order_ids
        )
          this.createRecommendedListBW_new();
        this.assignAndMapProductsBW(product_response);
      });
  };

  assignAndMapProductsBW(response) {
    let productDataWithCustomProducts = [
      ...response,
      ...dataResource.staticListOfMeds
    ];
    this.setState({ productData: productDataWithCustomProducts });

    this.state.productData &&
      this.state.recommended.forEach((item, index) => {
        this.state.productData &&
          this.state.productData.forEach((product, index) => {
            if (item.id.id == product.prod_id) {
              console.log(item);
              console.log("*&*&*&*&*&*&*&^*&*^&^&&&");
              console.log(product);
              let temp = [...this.state.prescriptionsList];
              product["prod_name"] = item.name;
              product["prod_type"] = "single product";
              let obj = {
                product: product,
                notes:
                  item.notes.constructor === Array ? item.notes[0] : item.notes,
                days: "",
                // Prescription Quantity:
                // if there in JSON -> get from JSON
                // else: if order -> get from order
                // else : 1
                prescription_quantity: product.prescription_quantity
                  ? product.prescription_quantity
                  : item.quantity
                  ? item.quantity
                  : 1,
                // Cart quantity:
                // If order -> get from order
                // else: get from json
                // else: 1
                cart_quantity: item.id.cart_quantity
                  ? item.id.cart_quantity
                  : product.cart_quantity
                  ? product.cart_quantity
                  : 1,
                dosage: "",
                unit: item.unit,
                frequency:
                  item.frequency.constructor === Array
                    ? item.frequency[0]
                    : item.frequency
              };
              temp.push(obj);
              console.log(obj);
              this.setState({ prescriptionsList: temp });
            }
          });
      });
    //MULTIPLE ORDERS RX START

    this.state.recommended_new &&
      Object.keys(this.state.recommended_new).map(recommended_id => {
        let rec = this.state.recommended_new[recommended_id];
        rec.forEach((item, index) => {
          this.state.productData &&
            this.state.productData.forEach((product, index) => {
              if (item.id.product_id === product.prod_id) {
                if (!this.state.prescriptionsList_new[recommended_id]) {
                  let temp_pres_list = this.state.prescriptionsList_new;
                  temp_pres_list[recommended_id] = [];
                  this.setState({ prescriptionsList_new: temp_pres_list });
                }
                let temp = [
                  ...this.state.prescriptionsList_new[recommended_id]
                ];
                let obj = {
                  product: product,
                  notes:
                    item.notes.constructor === Array
                      ? item.notes[0]
                      : item.notes,
                  days: "",
                  // Prescription Quantity:
                  // if there in JSON -> get from JSON
                  // else: if order -> get from order
                  // else : 1
                  prescription_quantity: product.prescription_quantity
                    ? product.prescription_quantity
                    : item.quantity
                    ? item.quantity
                    : 1,
                  // Cart quantity:
                  // If order -> get from order
                  // else: get from json
                  // else: 1
                  cart_quantity: item.cart_quantity
                    ? item.cart_quantity
                    : product.cart_quantity
                    ? product.cart_quantity
                    : 1,
                  dosage: "",
                  unit: item.unit,
                  frequency:
                    item.frequency.constructor === Array
                      ? item.frequency[0]
                      : item.frequency
                };
                temp.push(obj);
                let temp_list = this.state.prescriptionsList_new;
                temp_list[recommended_id] = temp;
                this.setState({ prescriptionsList_new: temp_list });
              }
            });
        });
      });
    let temp_prescriptionsList_final = [];
    let temp_prescriptionsList = this.state.prescriptionsList;
    let unique_order_products = [];
    let temp_prescriptionsList_new = this.state.prescriptionsList_new;
    let temp_order_ids = Object.keys(temp_prescriptionsList_new);
    temp_order_ids.forEach((item, index) => {
      temp_prescriptionsList_new[item].forEach((item2, index2) => {
        if (!unique_order_products.includes(item2.product.prod_id)) {
          unique_order_products.push(item2.product.prod_id);
        }
      });
    });

    temp_prescriptionsList.forEach((item, index) => {
      if (!unique_order_products.includes(item.product.prod_id)) {
        temp_prescriptionsList_final.push(item);
      }
    });
    this.setState({ prescriptionsList: temp_prescriptionsList_final });
    //***************************OTHER PRODUCTS***************************** */
    this.state.productData &&
      this.state.other_recommended.forEach((item, index) => {
        this.state.productData &&
          this.state.productData.forEach((product, index) => {
            if (item.id.id == product.prod_id) {
              console.log(item);
              console.log("*&*&*&*&*&*&*&^*&*^&^&&&");
              console.log(product);
              let temp = [...this.state.prescriptionsList_other];
              product["prod_name"] = item.name;
              product["prod_type"] = "single product";
              let obj = {
                product: product,
                notes:
                  item.notes.constructor === Array ? item.notes[0] : item.notes,
                days: "",
                // Prescription Quantity:
                // if there in JSON -> get from JSON
                // else: if order -> get from order
                // else : 1
                prescription_quantity: product.prescription_quantity
                  ? product.prescription_quantity
                  : item.quantity
                  ? item.quantity
                  : 1,
                // Cart quantity:
                // If order -> get from order
                // else: get from json
                // else: 1
                cart_quantity: item.id.cart_quantity
                  ? item.id.cart_quantity
                  : product.cart_quantity
                  ? product.cart_quantity
                  : 1,
                dosage: "",
                unit: item.unit,
                frequency:
                  item.frequency.constructor === Array
                    ? item.frequency[0]
                    : item.frequency
              };
              temp.push(obj);
              console.log(obj);
              this.setState({ prescriptionsList_other: temp });
            }
          });
      });
    //*****************OTHER RECOMMENDED PRODUCT END*******************
  }

  assignAndMapProductsMM(response) {
    let productDataWithCustomProducts = [
      ...response,
      ...dataResource.staticListOfMeds
    ];
    productDataWithCustomProducts = productDataWithCustomProducts.filter(
      item => {
        return item.prod_name != "Redensyl 3%";
      }
    );
    this.setState({ productData: productDataWithCustomProducts });
    this.state.recommended.forEach((item, index) => {
      this.state.productData &&
        this.state.productData.forEach((product, index) => {
          if (item.id === product.prod_id) {
            let temp = [...this.state.prescriptionsList];
            let obj = {
              product: product,
              notes:
                item.notes.constructor === Array ? item.notes[0] : item.notes,
              days: "",
              // Prescription Quantity:
              // if there in JSON -> get from JSON
              // else: if order -> get from order
              // else : 1
              prescription_quantity: product.prescription_quantity
                ? product.prescription_quantity
                : item.quantity
                ? item.quantity
                : 1,
              // Cart quantity:
              // If order -> get from order

              // else: get from json
              // else: 1
              cart_quantity: item.quantity
                ? item.quantity
                : product.cart_quantity
                ? product.cart_quantity
                : 1,
              dosage: "",
              unit: item.unit,
              frequency:
                item.frequency.constructor === Array
                  ? item.frequency[0]
                  : item.frequency
            };
            temp.push(obj);

            this.setState({ prescriptionsList: temp });
          }
        });
    });

    //*****************OTHER RECOMMENDED PRODUCT START*****************

    this.state.other_recommended.forEach((item, index) => {
      this.state.productData &&
        this.state.productData.forEach((product, index) => {
          if (item.id === product.prod_id) {
            let temp = [...this.state.prescriptionsList_other];
            let obj = {
              product: product,
              notes:
                item.notes.constructor === Array ? item.notes[0] : item.notes,
              days: "",
              // Prescription Quantity:
              // if there in JSON -> get from JSON
              // else: if order -> get from order
              // else : 1
              prescription_quantity: product.prescription_quantity
                ? product.prescription_quantity
                : item.quantity
                ? item.quantity
                : 1,
              // Cart quantity:
              // If order -> get from order

              // else: get from json
              // else: 1
              cart_quantity: item.quantity
                ? item.quantity
                : product.cart_quantity
                ? product.cart_quantity
                : 1,
              dosage: "",
              unit: item.unit,
              frequency:
                item.frequency.constructor === Array
                  ? item.frequency[0]
                  : item.frequency
            };
            temp.push(obj);

            this.setState({ prescriptionsList_other: temp });
          }
        });
    });
    //*****************OTHER RECOMMENDED PRODUCT END*******************

    //RVP MULTIPLE ORDER START

    this.state.recommended_new &&
      Object.keys(this.state.recommended_new).map(recommended_id => {
        let rec = this.state.recommended_new[recommended_id];
        rec.forEach((item, index) => {
          this.state.productData &&
            this.state.productData.forEach((product, index) => {
              if (item.id === product.prod_id) {
                if (!this.state.prescriptionsList_new[recommended_id]) {
                  let temp_pres_list = this.state.prescriptionsList_new;
                  temp_pres_list[recommended_id] = [];
                  this.setState({ prescriptionsList_new: temp_pres_list });
                }
                let temp = [
                  ...this.state.prescriptionsList_new[recommended_id]
                ];
                let obj = {
                  product: product,
                  notes:
                    item.notes.constructor === Array
                      ? item.notes[0]
                      : item.notes,
                  days: "",
                  // Prescription Quantity:
                  // if there in JSON -> get from JSON
                  // else: if order -> get from order
                  // else : 1
                  prescription_quantity: product.prescription_quantity
                    ? product.prescription_quantity
                    : item.quantity
                    ? item.quantity
                    : 1,
                  // Cart quantity:
                  // If order -> get from order
                  // else: get from json
                  // else: 1
                  cart_quantity: item.quantity
                    ? item.quantity
                    : product.cart_quantity
                    ? product.cart_quantity
                    : 1,
                  dosage: "",
                  unit: item.unit,
                  frequency:
                    item.frequency.constructor === Array
                      ? item.frequency[0]
                      : item.frequency
                };
                temp.push(obj);
                let temp_list = this.state.prescriptionsList_new;
                temp_list[recommended_id] = temp;
                this.setState({ prescriptionsList_new: temp_list });
              }
            });
        });
      });
    let temp_prescriptionsList_final = [];
    let temp_prescriptionsList = this.state.prescriptionsList;
    let unique_order_products = [];
    let temp_prescriptionsList_new = this.state.prescriptionsList_new;
    let temp_order_ids = Object.keys(temp_prescriptionsList_new);
    temp_order_ids.forEach((item, index) => {
      temp_prescriptionsList_new[item].forEach((item2, index2) => {
        if (!unique_order_products.includes(item2.product.prod_id)) {
          unique_order_products.push(item2.product.prod_id);
        }
      });
    });

    temp_prescriptionsList.forEach((item, index) => {
      if (!unique_order_products.includes(item.product.prod_id)) {
        temp_prescriptionsList_final.push(item);
      }
    });
    this.setState({ prescriptionsList: temp_prescriptionsList_final });
  }

  handleChanges = category => {
    this.setState({ category });
  };

  handleStatusChange = change => {
    this.setState({ status: change });
    // let data = {
    //   doctorId: this.state.docKey,
    //   form: this.state.category.value,
    //   id: this.state.userId,
    //   status: change.value,
    // };
    // axios.post(CONFIG_CONSTANTS.url.UPDATE_STATUS_URL, data).then((resp) => {
    //   console.log("status updated");
    // });
  };

  handleMoveOther = index => {
    let tempList = [...this.state.prescriptionsList_other];
    console.log(tempList[index]);
    let move_item = tempList[index];
    // let order_id_arr = Object.keys(this.state.prescriptionsList_new);
    let temp_prescriptionList = this.state.prescriptionsList;
    temp_prescriptionList.push(move_item);
    tempList.splice(index, 1);
    this.setState({
      prescriptionsList: temp_prescriptionList,
      prescriptionsList_other: tempList
    });
  };

  handleMoveToOrder = index => {
    let tempList = [...this.state.prescriptionsList];
    console.log(tempList[index]);
    let move_item = tempList[index];
    let order_id_arr = Object.keys(this.state.prescriptionsList_new);
    let newOrderList = [];
    let order_arr = this.state.prescriptionsList_new[order_id_arr[0]];
    let insertFlag = true;
    order_arr.map(item => {
      if (item.product.prod_id == move_item.product.prod_id) insertFlag = false;
    });
    if (insertFlag) {
      let temp_prescriptionsList_new = this.state.prescriptionsList_new;
      temp_prescriptionsList_new[order_id_arr[0]].push(move_item);
    }
    console.log(insertFlag);

    tempList.splice(index, 1);
    this.setState({ prescriptionsList: tempList });
  };

  handleDeleteOther = index => {
    let tempList = [...this.state.prescriptionsList_other];
    tempList.splice(index, 1);
    this.setState({
      prescriptionsList_other: tempList
    });
  };

  handleDelete = index => {
    let tempList = [...this.state.prescriptionsList];
    tempList.splice(index, 1);
    const recommendArr = this.state.recommended.map(item => ({
      id: item.id,
      quantity: item.quantity
    }));
    const changedArr = tempList.map(item => ({
      id: item.product.prod_id,
      quantity: Number(item.cart_quantity)
    }));
    recommendArr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    changedArr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    let changeFlag = !(
      JSON.stringify(recommendArr) == JSON.stringify(changedArr)
    );
    let flag = changeFlag ? 1 : 0;
    this.setState({ prescriptionsList: tempList, recommendationChange: flag });
  };

  handleDelete_new = (index, value) => {
    let tempList = [...this.state.prescriptionsList_new[value]];
    tempList.splice(index, 1);
    const recommendArr = this.state.recommended_new[value].map(item => ({
      id: item.id,
      quantity: item.quantity
    }));
    const changedArr = tempList.map(item => ({
      id: item.product.prod_id,
      quantity: Number(item.cart_quantity)
    }));
    recommendArr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    changedArr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    let changeFlag = !(
      JSON.stringify(recommendArr) == JSON.stringify(changedArr)
    );
    let flag = changeFlag ? 1 : 0;
    let temp_recommendationChange_new = this.state.recommendationChange_new;
    temp_recommendationChange_new[value] = flag;
    let temp_prescriptionsList_new = this.state.prescriptionsList_new;
    temp_prescriptionsList_new[value] = tempList;
    this.setState({
      prescriptionsList_new: temp_prescriptionsList_new,
      recommendationChange_new: temp_recommendationChange_new
    });
  };

  handlePatientData = () => {};

  handleEdit_new = (index, value) => {
    console.log("handle edit new");
    let temp_editItem = this.state.editItem_new;
    // let temp_edit = this.state.editItem_new
    console.log(`INDEX:${index} VALUE:${value}`);
    console.log(this.state.prescriptionsList_new);
    temp_editItem[value] = this.state.prescriptionsList_new[value][index];
    this.setState(
      {
        editItem_new: temp_editItem,
        editItem: temp_editItem[value]
      },
      () => {
        let tempPLArray = [...this.state.prescriptionsList_new[value]];
        tempPLArray.splice(index, 1);
        let temp_prescriptionsList = this.state.prescriptionsList_new;
        temp_prescriptionsList[value] = tempPLArray;
        this.setState({
          prescriptionsList_new: temp_prescriptionsList
        });
      }
    );
  };

  handleEdit = index => {
    this.setState({ editItem: this.state.prescriptionsList[index] }, () => {
      let tempPLArray = [...this.state.prescriptionsList];
      tempPLArray.splice(index, 1);
      this.setState({ prescriptionsList: tempPLArray });
    });
  };

  logoutUser = () => {
    confirmBox("Are you sure you want to logout?", async () => {
      await Axios.get(CONFIG_CONSTANTS.AUTH.LOGOUT, { withCredentials: true });
      localStorage.clear();
      store.dispatch({ type: "CLEAR" });
      this.props.history.push("/");
    });
  };
  getAppointmentMode = async () => {
    this.setState({ fetchModeLoader: true });
    const requestData = {
      appointmentId: this.state.userId
    };
    await axios
      .post(CONFIG_CONSTANTS.url.GET_APPOINTMENT_MODE, requestData)
      .then(res => {
        if (res.data.success) {
          this.setState({ mode: res.data.mode });
        }
        this.setState({ fetchModeLoader: false });
      })
      .catch(err => {
        this.setState({ fetchModeLoader: false });
        console.log("ERROR IN /getAppointmentMode", err);
      });
  };

  nextPatient = () => {
    this.setState(this.getInitialState(), () => {
      this.userID.current.value = "";
    });
  };

  clearState = () => {
    this.setState(this.getInitialState());
  };

  selectedObservations = (setKey, getKey, index) => {
    let obj = {};
    obj[setKey] = this.state[setKey]
      ? this.state[setKey] + ", " + this.state[getKey][index]
      : this.state[getKey][index];
    this.setState(obj);
  };

  loadNewOrder = id => {
    this.clearState();
    this.userID.current.value = "OF-" + id;
    this.handleUserSearch();
  };

  setPatientMedicalRecords() {
    return {
      category: this.state.category1,
      complaints: this.state.complaints,
      history: this.state.history,
      diagnosis: this.state.diagnosis,
      instruction: this.state.instructions
    };
  }

  setPatientRecords() {
    return {
      name: this.state.bodyData.body.data.name,
      age: this.state.bodyData.body.data.form_details
        ? this.state.bodyData.body.data.form_details.age
        : null,
      gender:
        this.state.bodyData.body.data.gender ||
        (this.state.brand == "BW" ? "Female" : "Male"),
      email: this.state.bodyData.body.data.email,
      phone: this.state.bodyData.body.data.phone
    };
  }

  setMedicineRecords() {
    let medicines = [];
    //console.log(this.props.prescriptionsList);
    Object.keys(this.state.prescriptionsList_new).map(order_id => {
      this.state.prescriptionsList_new[order_id] &&
        this.state.prescriptionsList_new[order_id].forEach((elem, index) => {
          medicines.push({
            order_id: order_id,
            product_id: elem.product.prod_id,
            category: elem.product.prod_cat,
            name: elem.product.generic_name || elem.product.prod_name,
            unit: elem.unit,
            frequency: elem.frequency,
            noOfDays: elem.days,
            quantity: elem.prescription_quantity
              ? elem.prescription_quantity
              : 1,
            cart_quantity: elem.cart_quantity ? elem.cart_quantity : 1,
            instruction: elem.notes
          });
        });
    });
    // this.state.prescriptionsList &&
    //     this.state.prescriptionsList.forEach((elem, index) => {
    //       medicines.push({
    //         order_id:null,
    //         product_id: elem.product.prod_id,
    //         name: elem.product.generic_name || elem.product.prod_name,
    //         unit: elem.unit,
    //         frequency: elem.frequency,
    //         noOfDays: elem.days,
    //         quantity: elem.prescription_quantity
    //           ? elem.prescription_quantity
    //           : 1,
    //         cart_quantity: elem.cart_quantity ? elem.cart_quantity : 1,
    //         instruction: elem.notes
    //       });
    //     });
    return medicines;
  }

  handleCheck = e => {
    this.setState({ report_confirmCheck: e.target.checked });
  };

  sendEmail = e => {
    this.setState({ report_emailState: "sending" });
    let data = {
      doctorId: store.getState().userReducer.hash_id,
      prescriptionId: this.state.report_prescription_id,
      sendTo: this.state.report_email,
      order_confirmed: this.state.report_confirmCheck ? 1 : 0,
      brand: this.state.brand,
      complaints: this.state.complaints,
      history: this.state.history,
      diagnosis: this.state.diagnosis
    };

    axios.post(CONFIG_CONSTANTS.url.SEND_REPORT_URL, data, {}).then(res => {
      if (res.data.statusCode == 200) {
        this.setState({ report_emailState: "sent", statusModal: true }, () => {
          setTimeout(() => {
            this.setState({ report_show: false, report_emailState: "" });
          }, 2000);
        });
      } else if (res.data.statusCode == 400) {
        this.setState({ report_show: false, report_emailState: "" });
        alertBox(res.data.body.data.result);
      } else {
        this.setState({ report_show: false, report_emailState: "" });
        alertBox(
          "There seems to be a issue with sending the email. Please contact Admin"
        );
      }
    });
  };

  // //handleMoveToOrder
  // handleMoveToOrder = e =>{
  //   console.log("Move to order!");
  //   let order_id_arr = Object.keys(this.state.prescriptionsList_new);
  //     let newOrderList = [];
  //     let arr1 = this.state.prescriptionsList_new[order_id_arr[0]];
  //     let arr2 = this.state.prescriptionsList;
  //     newOrderList = [...arr1];
  //   this.state.prescriptionsList.map((item)=>{
  //     console.log(item);
  //   });

  // }

  previewBlock = () => {
    return (
      <>
        {this.state.bodyData ? (
          <div className="parent_2">
            <div className="oib--inp-box123 float-child1">
              {/* <div className="formLabel">Enter Instructions</div>
              <textarea
                cols={40}
                rows={2}
                placeholder="Enter Instructions"
                type="text"
                required
                name="instructions"
                value={this.state.instructions}
                onChange={this.handleChange}
                className="oib--ta"
                style={{ resize: "none", width: "350px" }}
              />
              <div className="dd">
                <DropdownButton
                  variant="Secondary"
                  id="dropdown-item-button"
                  title=""
                >
                  {this.state.instructionsList.map((elem, index) => (
                    <Dropdown.Item
                      as="button"
                      key={index}
                      onClick={() =>
                        this.selectedObservations(
                          "instructions",
                          "instructionsList",
                          index
                        )
                      }
                    >
                      {elem}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div> */}
              <div className="prescription-page__instructions-wrapper">
                <CustomDropdownInput
                  listTitle="Selected Instructions"
                  placeholder="Enter Instruction"
                  suggestions={dataResource.instructionsList}
                  onChange={this.onInstructionChange}
                />
              </div>
            </div>
            {this.state.bodyData ? (
              <>
                <div className="float-child2">
                  {this.state.bodyData.body.data.prescription_order_ids ? (
                    <Button
                      className="v-btn"
                      variant="primary"
                      onClick={e => this.handlePreview(e)}
                      style={{ width: "100%" }}
                      disabled={
                        !Object.keys(this.state.prescriptionsList_new) ||
                        Object.keys(this.state.prescriptionsList_new).length ===
                          0
                      }
                    >
                      Preview
                    </Button>
                  ) : (
                    <Button
                      className="v-btn"
                      variant="primary"
                      onClick={e => {
                        this.child.handlePreview(e);
                      }}
                      style={{ width: "100%", margin: "10px 0" }}
                      disabled={
                        !this.state.prescriptionsList ||
                        this.state.prescriptionsList.length === 0
                      }
                    >
                      Preview
                    </Button>
                  )}
                </div>
                <Modal
                  className="xyz"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.state.report_show}
                  // onHide={this.handleClose_new(e)}
                  onHide={e => this.handleClose_new(e)}
                >
                  <Modal.Body className="mdl-body">
                    {!this.state.pdf && (
                      <div className="loader-cm">
                        Loading Prescription...Please Wait
                        <div className="Loader-box">
                          <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={30}
                            width={30}
                          />
                        </div>
                      </div>
                    )}
                    <Iframe
                      url={this.state.report_pdf}
                      width="700px"
                      height="700px"
                      id="myId"
                      className="myClassname"
                      display="initial"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={e => this.handleClose_new(e)}
                      // {this.handleClose_new(e)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={e => this.sendEmail(e)}
                      disabled={
                        this.state.report_emailState ||
                        !this.state.report_confirmCheck
                      }
                    >
                      Send Email
                    </Button>
                    <div className="userEmail">
                      <input
                        placeholder="Enter Email ID"
                        type="text"
                        required
                        name="userId"
                        value={this.state.report_email}
                        onChange={e => {
                          this.setState({ report_email: e.target.value });
                        }}
                      />

                      {
                        <div className="confirmed-check">
                          <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                              value={this.state.report_confirmCheck}
                              onChange={this.handleCheck}
                              type="checkbox"
                              label="Mark Order as Confirmed"
                            />
                          </Form.Group>
                        </div>
                      }
                      {this.state.report_emailState === "sending" && (
                        <div className="loader-cm inln-blk">
                          <div className="Loader-box">
                            <Loader
                              type="TailSpin"
                              color="#00BFFF"
                              height={30}
                              width={30}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.report_emailState === "sent" && (
                        <div className="loader-cm  inln-blk">
                          Email Sent Successfully
                        </div>
                      )}
                    </div>
                  </Modal.Footer>
                </Modal>
              </>
            ) : null}
          </div>
        ) : null}
      </>
    );
  };

  handlePreview = e => {
    e.preventDefault();
    this.setState({ report_show: true });
    let reqData = {
      doctorId: store.getState().userReducer.hash_id,
      brand: this.state.brand,
      patient: this.setPatientRecords(),
      patientMedical: this.setPatientMedicalRecords(),
      medicines: this.setMedicineRecords(),
      // recommendedChanged: this.props.recommendationChange,
      recommendedChanged: 0,
      form: this.state.category.value,
      id: this.state.userId
    };
    this.setState({ report_data: reqData });
    axios
      .post(CONFIG_CONSTANTS.url.GENERATE_REPORT_URL, reqData, {})
      .then(res => {
        console.log(res);
        this.setPrescriptionID(res.data.body.data.prescription_id);
        this.setState({
          report_show: true,
          report_pdf: res.data.body.data.url,
          report_prescription_id: res.data.body.data.prescription_id
        });
      });
  };
  handleRedirect = () => {
    this.setState({ redirectModal: false });
    this.props.history.push("/dashboard");
  };

  render() {
    // const categories = [
    //   { label: "Hair Matters", value: "HM" },
    //   { label: "Performance Matters", value: "SH" },
    //   { label: "Order Placed", value: "OF" },
    // ];

    return (
      <div
        data-test="prescriptionPage-container"
        className="prescriptionPage-container"
        style={{
          overflow: "scroll"
        }}
      >
        <Modal
          show={this.state.isMobile}
          centered
          dialogClassName="redirect-modal"
        >
          <div
            style={{
              margin: "10px",
              opacity: "0.5",
              fontSize: "20px"
            }}
          >
            Kindly use a laptop/desktop to access DocHub
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <button
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                borderRadius: "12px",
                padding: "10px",
                margin: "10px",
                color: "white",
                fontWeight: "600",
                backgroundColor: "#379F8E"
              }}
              onClick={this.handleRedirect}
            >
              Redirect to dashboard
            </button>
          </div>
        </Modal>
        {!this.state.isMobile && (
          <ReassignModal
            show={this.state.showAssignModal}
            setShow={show => this.setState({ showAssignModal: show })}
            appointmentId={this.state.userId}
            doctorId={this.state.loggedInDr.doctor_id}
          />
        )}
        <div className="container-box">
          <div style={{ display: "flex" }}>
            <div className="userIdInput">
              <input
                placeholder="Enter Patient ID"
                type="text"
                required
                name="userId"
                onChange={this.handleChange}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    this.handleUserSearch();
                  }
                }}
                className="usi-inp"
                ref={this.userID}
              />
            </div>

            <div className="searchButton">
              <Button
                className="btn btn-s"
                variant="primary"
                onClick={e => this.handleUserSearch()}
                disabled={!this.userID.current}
              >
                Search
              </Button>
            </div>
            <div
              className="app-installed-label"
              hidden={!this.state.app_installed}
            >
              <CheckCircleIcon /> App Installed
            </div>
            <div
              className="app-installed-label"
              hidden={!(this.state.mode === "bw_video")}
              style={{ backgroundColor: "#675680" }}
            >
              <FaVideo /> BW VIDEOCALL
            </div>
            <div
              className="app-installed-label"
              hidden={!(this.state.mode === "bw_call")}
              style={{ backgroundColor: "#675680" }}
            >
              <MdCall /> BW CALL
            </div>
            <div>
              {this.state.fetchModeLoader ? (
                <Loader
                  type="TailSpin"
                  color="#027bfe"
                  height={40}
                  width={40}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    marginRight: "10px"
                  }}
                />
              ) : (
                <RefreshIcon
                  onClick={() => this.getAppointmentMode()}
                  style={{
                    color: "#027bfe",
                    fontSize: "40px",
                    marginLeft: "10px",
                    cursor: "pointer"
                  }}
                />
              )}
            </div>
          </div>
          <div className="action-box">
            <Button
              className="btn btn-primary"
              variant="primary"
              onClick={() => this.nextPatient()}
            >
              Next Patient
            </Button>
            <Button onClick={() => this.logoutUser()}>Logout</Button>
          </div>
        </div>

        <div>
          <div></div>
          <div className="spacer"></div>
          <br />
        </div>
        {this.state.orderPlaced > 0 && (
          <div className="sweet-alert-box">
            <Alert variant="info">
              A order has been placed with order id{" "}
              <Alert.Link
                href={getRxWriterLink(
                  `${this.state.brand}-O-${this.state.orderId}`
                )}
              >
                {this.state.brand}-O-{this.state.orderId}
              </Alert.Link>{" "}
            </Alert>
          </div>
        )}
        {this.state.rescheduled > 0 && (
          <div className="sweet-alert-box">
            <Alert variant="info">The Appointment has been rescheduled</Alert>
          </div>
        )}
        {this.state &&
          this.state.docKey &&
          this.state.category &&
          this.state.category1 &&
          this.state.bodyData &&
          this.state.productData &&
          // this.state.productData.length > 6 &&
          this.state.category1.includes("weight") &&
          this.state.loggedInDr &&
          this.state.nutritionistList.includes(
            this.state.loggedInDr.doctor_id
          ) && (
            <>
              <MealPlanner
                schedule_token={this.state.schedule_token}
                category={this.state.category}
                docKey={this.state.docKey}
                userId={this.state.userId}
                loggedInDr={this.state.loggedInDr}
                form_details={this.state.form_details}
                getDetails={this.handleGetDetails}
                productData={this.state.productData}
                editItem={this.state.editItem}
                handlePreview={this.handlePreview}
                setPrescriptionID={this.setPrescriptionID}
                ref={instance => {
                  this.child = instance;
                }}
                orderPlaced={this.state.orderPlaced}
                recommended={this.state.recommended}
                userInfo={this.state.bodyData.body}
                prescriptionsList={this.state.prescriptionsList}
                deleteElement={this.handleDelete}
                handleMove={this.handleMoveToOrder}
                editElement={this.handleEdit}
                complaints={this.state.complaints}
                history={this.state.history}
                diagnosis={this.state.diagnosis}
                instructions={this.state.instructions}
                description={this.state.description}
                recommendationChange={this.state.recommendationChange}
                isEdit={this.state.editItem.product ? true : false}
                form={this.state.category.value}
                brand={this.state.brand}
                type_text={"normal_form"}
                prescription_order_ids={
                  this.state.bodyData.body.data.prescription_order_ids
                }
                optional_rx={this.state.bodyData.body.data.optional_rx}
                doctor_id={this.state.doctor_id}
                categories={this.state.category1}
              />
              <Modal
                className="xyz"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.report_show}
                // onHide={this.handleClose_new(e)}
                onHide={e => this.handleClose_new(e)}
              >
                <Modal.Body className="mdl-body">
                  {!this.state.pdf && (
                    <div className="loader-cm">
                      Loading Prescription...Please Wait
                      <div className="Loader-box">
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={30}
                          width={30}
                        />
                      </div>
                    </div>
                  )}
                  <Iframe
                    url={this.state.report_pdf}
                    width="700px"
                    height="700px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={e => this.handleClose_new(e)}
                    // {this.handleClose_new(e)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={e => this.sendEmail(e)}
                    disabled={
                      this.state.report_emailState ||
                      !this.state.report_confirmCheck
                    }
                  >
                    Send Email
                  </Button>
                  <div className="userEmail">
                    <input
                      placeholder="Enter Email ID"
                      type="text"
                      required
                      name="userId"
                      value={this.state.report_email}
                      onChange={e => {
                        this.setState({ report_email: e.target.value });
                      }}
                    />

                    {
                      <div className="confirmed-check">
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check
                            value={this.state.report_confirmCheck}
                            onChange={this.handleCheck}
                            type="checkbox"
                            label="Mark Order as Confirmed"
                          />
                        </Form.Group>
                      </div>
                    }
                    {this.state.report_emailState === "sending" && (
                      <div className="loader-cm inln-blk">
                        <div className="Loader-box">
                          <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={30}
                            width={30}
                          />
                        </div>
                      </div>
                    )}
                    {this.state.report_emailState === "sent" && (
                      <div className="loader-cm  inln-blk">
                        Email Sent Successfully
                      </div>
                    )}
                  </div>
                </Modal.Footer>
              </Modal>
            </>
          )}
        {this.state &&
          this.state.category1 &&
          this.state.productData &&
          this.state.loggedInDr &&
          !this.state.nutritionistList.includes(
            this.state.loggedInDr.doctor_id
          ) &&
          !this.state.isMobile && (
            <div className="top-container">
              <div className="rowC">
                <div style={{ display: "flex" }}>
                  <div
                    className="child2"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {this.state.bodyData &&
                    this.state.category1 &&
                    this.state.loggedInDr &&
                    this.state.loggedInDr.doctor_id ? (
                      <PatientInfo
                        categories={this.state.category1}
                        brand={this.state.brand}
                        userId={this.state.userId}
                        type={this.state.category}
                        userInfo={this.state.bodyData.body}
                        schedule_token={this.state.schedule_token}
                        showHistory={this.showPatientHistory}
                        showingPatientHistory={this.state.showingPatientHistory}
                        prescriptionID={this.state.prescriptionID}
                        category={this.state.category.value}
                        closeHistory={this.closeHistory}
                        loggedInDr={this.state.loggedInDr}
                        handleParentState={this.handleParentState}
                        doctor_id={this.state.doctor_id}
                        customer_id={this.state.customer_id}
                        app_installed={this.state.app_installed}
                        mode={this.state.mode}
                        app={this.state.app}
                      ></PatientInfo>
                    ) : null}
                  </div>
                  {(this.state.orderPlaced == 0 || this.state.category) &&
                  this.state.productData &&
                  this.state.productData.length > 6 ? (
                    <div
                      className="child2-r"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="status-info-box">
                        <h4 className="sectionHeading">Status</h4>
                        {this.state.category && (
                          <div>
                            <div className="status-drop">
                              Call Status
                              <div
                                className="categoryDropdown"
                                style={{ marginTop: "5px" }}
                              >
                                <Select
                                  options={dataResource.statusList}
                                  value={this.state.status}
                                  onChange={this.handleStatusChange}
                                  className="sib--st-dd"
                                />
                              </div>
                            </div>
                            Enter Remarks
                            <textarea
                              cols={20}
                              rows={2}
                              placeholder=" Enter Remarks"
                              type="text"
                              required
                              value={this.state.remarks}
                              name="remarks"
                              onChange={this.handleChange}
                              className="sib--remark-ta"
                              style={{ marginTop: "5px", resize: "none" }}
                            />
                            <div className="dd" style={{ marginTop: "5px" }}>
                              <DropdownButton
                                variant="Secondary"
                                id="dropdown-item-button"
                                title=""
                              >
                                {this.state.remarkList.map((elem, index) => (
                                  <Dropdown.Item
                                    as="button"
                                    key={index}
                                    onClick={() =>
                                      this.selectedObservations(
                                        "remarks",
                                        "remarkList",
                                        index
                                      )
                                    }
                                  >
                                    {elem}
                                  </Dropdown.Item>
                                ))}
                              </DropdownButton>
                            </div>
                            <div className="sib--remark-btn-box">
                              <Button
                                className="btn btn-s"
                                variant="primary"
                                onClick={e => this.handleRemarks(e)}
                              >
                                Update
                              </Button>
                            </div>
                          </div>
                        )}

                        <div className="sib--activity-logs">
                          <h5>Recent updates</h5>
                          {this.state.activityLog ? (
                            <div className="sib--activity-box">
                              {this.state.activityLog.map((elem, index) => (
                                <div className="remarkBox">
                                  {/* <p>{elem.remarks}-{elem.updated_dttm}-{elem.status}</p> */}
                                  <div className="activitytop">
                                    <div className="remarkStatus">
                                      {statusObj[elem.status]}
                                    </div>
                                    <div className="timestamp">
                                      {UtilityService.getIST(elem.updated_dttm)}
                                    </div>
                                  </div>
                                  <div className="remark">{elem.remarks}</div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="sib--no-log">
                              No Recent Activity
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="observations-info-box">
                        <h4 className="sectionHeading">Observations</h4>
                        {this.state.bodyData && (
                          <>
                            <div>
                              <div className="oib--inp-box">
                                <div style={{ marginBottom: "10px" }}>
                                  Enter Complaints
                                </div>
                                <textarea
                                  cols={40}
                                  rows={2}
                                  placeholder=" Enter Complaints"
                                  type="text"
                                  required
                                  value={this.state.complaints}
                                  name="complaints"
                                  onChange={this.handleChange}
                                  className="oib--ta"
                                  style={{ resize: "none" }}
                                />
                                <div className="dd">
                                  <DropdownButton
                                    variant="Secondary"
                                    id="dropdown-item-button"
                                    title=""
                                  >
                                    {this.state.complaintList.map(
                                      (elem, index) => (
                                        <Dropdown.Item
                                          as="button"
                                          key={index}
                                          onClick={() =>
                                            this.selectedObservations(
                                              "complaints",
                                              "complaintList",
                                              index
                                            )
                                          }
                                        >
                                          {elem}
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                              <div className="oib--inp-box">
                                <div style={{ marginBottom: "10px" }}>
                                  Enter History
                                </div>
                                <textarea
                                  cols={40}
                                  rows={2}
                                  placeholder="Enter History"
                                  type="text"
                                  required
                                  name="history"
                                  value={this.state.history}
                                  onChange={this.handleChange}
                                  className="oib--ta"
                                  style={{ resize: "none" }}
                                />
                                <div className="dd">
                                  <DropdownButton
                                    variant="Secondary"
                                    id="dropdown-item-button"
                                    title=""
                                  >
                                    {this.state.historyList.map(
                                      (elem, index) => (
                                        <Dropdown.Item
                                          as="button"
                                          key={index}
                                          onClick={() =>
                                            this.selectedObservations(
                                              "history",
                                              "historyList",
                                              index
                                            )
                                          }
                                        >
                                          {elem}
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="oib--inp-box">
                                <div style={{ marginBottom: "10px" }}>
                                  Enter Diagnosis
                                </div>
                                <textarea
                                  cols={40}
                                  rows={2}
                                  placeholder="Enter Diagnosis"
                                  type="text"
                                  required
                                  name="diagnosis"
                                  value={this.state.diagnosis}
                                  onChange={this.handleChange}
                                  className="oib--ta"
                                  style={{ resize: "none" }}
                                />
                                <div className="dd">
                                  <DropdownButton
                                    variant="Secondary"
                                    id="dropdown-item-button"
                                    title=""
                                  >
                                    {this.state.diagnosisList.map(
                                      (elem, index) => (
                                        <Dropdown.Item
                                          as="button"
                                          key={index}
                                          onClick={() =>
                                            this.selectedObservations(
                                              "diagnosis",
                                              "diagnosisList",
                                              index
                                            )
                                          }
                                        >
                                          {elem}
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                              <div className="sib--remark-btn-box">
                                <Button
                                  className="btn btn-s"
                                  variant="primary"
                                  onClick={() => {
                                    console.log("Handle this");
                                  }}
                                  style={{ width: "100%" }}
                                >
                                  Update
                                </Button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                {this.state.bodyData ? (
                  <QuickView
                    appointmentId={this.props.match.params.id}
                    brand={this.state.brand}
                    email={this.state.report_email}
                    doctorId={this.state.docKey}
                  />
                ) : null}
                {this.state &&
                  this.state.bodyData &&
                  this.state.image_timeline && (
                    <ImageTimeLine param={this.state.image_timeline} />
                  )}
                <div style={{ display: "flex" }}>
                  {this.state.category && this.state.category.value != "O" ? (
                    <div>
                      {(this.state.orderPlaced == 0 || this.state.category) &&
                      this.state.productData &&
                      this.state.productData.length > 6 ? (
                        <div className="qna-info-box-2">
                          <div className="left-child">
                            {this.state.bodyData &&
                            !this.state.bodyData.body.data
                              .prescription_order_ids &&
                            !(
                              this.state.category &&
                              this.state.category.value != "O"
                            ) ? null : (
                              <PrescriptionEditor
                                productData={this.state.productData}
                                getDetails={this.handleGetDetails}
                                editItem={this.state.editItem}
                                brand={this.state.brand}
                              ></PrescriptionEditor>
                            )}
                            {this.state.bodyData &&
                            !this.state.bodyData.body.data
                              .prescription_order_ids &&
                            !(
                              this.state.category &&
                              this.state.category.value != "O"
                            )
                              ? null
                              : this.previewBlock()}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div>
                    {this.state.bodyData &&
                    !this.state.bodyData.body.data.prescription_order_ids &&
                    !(this.state.category && this.state.category.value != "O")
                      ? null
                      : this.state.category &&
                        this.state.category.value != "O" &&
                        Object.keys(this.state.recommended_new).map(
                          (value, index) => {
                            return (
                              <div classname="parent_one">
                                <div className="child1">
                                  {this.state.bodyData ? (
                                    <div
                                      className="shining-right-child"
                                      style={{ width: "100%" }}
                                    >
                                      <PrescriptionViewer
                                        category={this.state.category1}
                                        setModalState={this.setModalState}
                                        setPrescriptionID={
                                          this.setPrescriptionID
                                        }
                                        orderPlaced={this.state.orderPlaced}
                                        recommended={
                                          this.state.recommended_new[value]
                                        }
                                        key={value}
                                        keyVal={value}
                                        indexVal={index}
                                        userInfo={this.state.bodyData.body}
                                        prescriptionsList={
                                          this.state.prescriptionsList_new[
                                            value
                                          ]
                                        }
                                        deleteElement={this.handleDelete_new}
                                        editElement={this.handleEdit_new}
                                        complaints={this.state.complaints}
                                        history={this.state.history}
                                        diagnosis={this.state.diagnosis}
                                        description={this.state.description}
                                        instructions={this.state.instructions}
                                        recommendationChange={
                                          this.state.recommendationChange
                                        }
                                        isEdit={
                                          this.state.editItem.product
                                            ? true
                                            : false
                                        }
                                        form={this.state.category.value}
                                        userId={this.state.userId}
                                        brand={this.state.brand}
                                        prescription_order_ids={
                                          this.state.bodyData.body.data
                                            .prescription_order_ids
                                        }
                                        optional_rx={
                                          this.state.bodyData.body.data
                                            .optional_rx
                                            ? this.state.bodyData.body.data
                                                .optional_rx[index]
                                            : null
                                        }
                                        nicotex={this.state.nicotex}
                                      ></PrescriptionViewer>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }
                        )}
                    {this.state.bodyData &&
                    this.state.category &&
                    this.state.category.value != "O" ? (
                      <div className="qna-info-box-3">
                        <h4 className="sectionHeading">Recommended Products</h4>
                        <PrescriptionViewer
                          category={this.state.category1}
                          setModalState={this.setModalState}
                          setPrescriptionID={this.setPrescriptionID}
                          ref={instance => {
                            this.child = instance;
                          }}
                          orderPlaced={this.state.orderPlaced}
                          recommended={this.state.recommended}
                          userInfo={this.state.bodyData.body}
                          prescriptionsList={this.state.prescriptionsList}
                          deleteElement={this.handleDelete}
                          handleMove={this.handleMoveToOrder}
                          editElement={this.handleEdit}
                          complaints={this.state.complaints}
                          history={this.state.history}
                          diagnosis={this.state.diagnosis}
                          instructions={this.state.instructions}
                          description={this.state.description}
                          recommendationChange={this.state.recommendationChange}
                          isEdit={this.state.editItem.product ? true : false}
                          form={this.state.category.value}
                          userId={this.state.userId}
                          brand={this.state.brand}
                          type_text={"normal_form"}
                          prescription_order_ids={
                            this.state.bodyData.body.data.prescription_order_ids
                          }
                          nicotex={this.state.nicotex}
                        ></PrescriptionViewer>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="diag-box"></div>

              {this.state.category &&
                this.state.category.value == "O" &&
                Object.keys(this.state.recommended_new).map((value, index) => {
                  return (
                    <div classname="parent_one">
                      <div className="child1">
                        {/* {this.state.orderPlaced == 0 &&
                    this.state.prescriptionsList_new[value].length >= 0 &&
                    this.state.category.value ? ( */}
                        {this.state.category &&
                          this.state.category.value == "O" && (
                            <div
                              className="left-child"
                              style={{ backgroundColor: "white" }}
                            >
                              {index == 0 &&
                              this.state.productData &&
                              this.state.productData.length > 6 ? (
                                <PrescriptionEditor
                                  productData={this.state.productData}
                                  getDetails={this.handleGetDetails_new}
                                  editItem={this.state.editItem_new[value]}
                                  brand={this.state.brand}
                                  key={value}
                                  keyVal={value}
                                ></PrescriptionEditor>
                              ) : null}
                              {index == 0 ? this.previewBlock() : null}
                            </div>
                          )}

                        {/* // ) : null} */}

                        {this.state.bodyData ? (
                          <div className="shining-right-child">
                            <PrescriptionViewer
                              category={this.state.category1}
                              setModalState={this.setModalState}
                              setPrescriptionID={this.setPrescriptionID}
                              orderPlaced={this.state.orderPlaced}
                              recommended={this.state.recommended_new[value]}
                              key={value}
                              keyVal={value}
                              indexVal={index}
                              userInfo={this.state.bodyData.body}
                              prescriptionsList={
                                this.state.prescriptionsList_new[value]
                              }
                              deleteElement={this.handleDelete_new}
                              editElement={this.handleEdit_new}
                              complaints={this.state.complaints}
                              history={this.state.history}
                              diagnosis={this.state.diagnosis}
                              description={this.state.description}
                              instructions={this.state.instructions}
                              recommendationChange={
                                this.state.recommendationChange
                              }
                              isEdit={
                                this.state.editItem.product ? true : false
                              }
                              form={this.state.category.value}
                              userId={this.state.userId}
                              brand={this.state.brand}
                              prescription_order_ids={
                                this.state.bodyData.body.data
                                  .prescription_order_ids
                              }
                              optional_rx={
                                this.state.bodyData.body.data.optional_rx
                                  ? this.state.bodyData.body.data.optional_rx[
                                      index
                                    ]
                                  : null
                              }
                              nicotex={this.state.nicotex}
                            ></PrescriptionViewer>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}

              {this.state.category && this.state.category.value != "O" ? (
                <div className="child1">
                  {/* {(this.state.orderPlaced == 0 || this.state.category) &&
                this.state.productData &&
                this.state.productData.length > 6 ? (
                  <div className="left-child">
                    {this.state.bodyData &&
                    this.state.bodyData.body.data
                      .prescription_order_ids ? null : (
                      <PrescriptionEditor
                        productData={this.state.productData}
                        getDetails={this.handleGetDetails}
                        editItem={this.state.editItem}
                        brand={this.state.brand}
                      ></PrescriptionEditor>
                    )}
                    {this.state.bodyData &&
                    this.state.bodyData.body.data.prescription_order_ids
                      ? null
                      : this.previewBlock()}
                  </div>
                ) : null} */}
                  {/* {this.state.bodyData ? (
                  <div
                    className={
                      this.state.bodyData &&
                      this.state.bodyData.body.data.prescription_order_ids
                        ? "right-child"
                        : "shining-right-child"
                    }
                  >
                    <PrescriptionViewer
                      setPrescriptionID={this.setPrescriptionID}
                      ref={instance => {
                        this.child = instance;
                      }}
                      orderPlaced={this.state.orderPlaced}
                      recommended={this.state.recommended}
                      userInfo={this.state.bodyData.body}
                      prescriptionsList={this.state.prescriptionsList}
                      deleteElement={this.handleDelete}
                      handleMove={this.handleMoveToOrder}
                      editElement={this.handleEdit}
                      complaints={this.state.complaints}
                      history={this.state.history}
                      diagnosis={this.state.diagnosis}
                      instructions={this.state.instructions}
                      description={this.state.description}
                      recommendationChange={this.state.recommendationChange}
                      isEdit={this.state.editItem.product ? true : false}
                      form={this.state.category.value}
                      userId={this.state.userId}
                      brand={this.state.brand}
                      type_text={"normal_form"}
                      prescription_order_ids={
                        this.state.bodyData.body.data.prescription_order_ids
                      }
                    ></PrescriptionViewer>
                  </div>
                ) : null} */}
                </div>
              ) : null}

              {this.state.bodyData &&
              this.state.prescriptionsList_other &&
              this.state.prescriptionsList_other.length > 0 ? (
                <div className="child1">
                  <div className="left-child"></div>
                  <div className="right-child">
                    <PrescriptionViewer
                      category={this.state.category1}
                      setModalState={this.setModalState}
                      type_text={"other_recommended_products"}
                      setPrescriptionID={this.setPrescriptionID}
                      orderPlaced={this.state.orderPlaced}
                      recommended={this.state.recommended}
                      userInfo={this.state.bodyData.body}
                      prescriptionsList={this.state.prescriptionsList_other}
                      deleteElement={this.handleDeleteOther}
                      editElement={this.handleEdit}
                      complaints={this.state.complaints}
                      history={this.state.history}
                      diagnosis={this.state.diagnosis}
                      description={this.state.description}
                      recommendationChange={this.state.recommendationChange}
                      isEdit={this.state.editItem.product ? true : false}
                      form={this.state.category.value}
                      userId={this.state.userId}
                      brand={this.state.brand}
                      handleMoveOther={this.handleMoveOther}
                      nicotex={this.state.nicotex}
                    ></PrescriptionViewer>
                  </div>
                </div>
              ) : null}
              {/* {this.previewBlock()} */}
              {/* {this.state.bodyData ? (
              <div className="parent_2">
                <div className="oib--inp-box123 float-child1">
                  <div className="label-inp">Enter Instructions</div>
                  <textarea
                    cols={40}
                    rows={2}
                    placeholder="Enter Instructions"
                    type="text"
                    required
                    name="instructions"
                    value={this.state.instructions}
                    onChange={this.handleChange}
                    className="oib--ta"
                  />
                  <div className="dd">
                    <DropdownButton
                      variant="Secondary"
                      id="dropdown-item-button"
                      title=""
                    >
                      {this.state.instructionsList.map((elem, index) => (
                        <Dropdown.Item
                          as="button"
                          key={index}
                          onClick={() =>
                            this.selectedObservations(
                              "instructions",
                              "instructionsList",
                              index
                            )
                          }
                        >
                          {elem}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                </div>
                {this.state.bodyData
                 ? (
                  <>
                    <div className="float-child2">
                      {this.state.bodyData.body.data.prescription_order_ids?
                      (
                        <Button
                        className="v-btn"
                        variant="primary"
                        onClick={e => this.handlePreview(e)}
                        >
                          Preview
                        </Button>
                      ):
                      (
                        <Button
                        className="v-btn"
                        variant="primary"
                        onClick={(e) => { this.child.handlePreview(e); }}
                        >
                          Preview
                        </Button>
                      )}

                    </div>
                    <Modal
                      className="xyz"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={this.state.report_show}
                      // onHide={this.handleClose_new(e)}
                      onHide={e => this.handleClose_new(e)}
                    >
                      <Modal.Body className="mdl-body">
                        {!this.state.pdf && (
                          <div className="loader-cm">
                            Loading Prescription...Please Wait
                            <div className="Loader-box">
                              <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={30}
                                width={30}
                              />
                            </div>
                          </div>
                        )}
                        <Iframe
                          url={this.state.report_pdf}
                          width="700px"
                          height="700px"
                          id="myId"
                          className="myClassname"
                          display="initial"
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={e => this.handleClose_new(e)}
                          // {this.handleClose_new(e)}
                        >
                          Close
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={e => this.sendEmail(e)}
                          disabled={
                            this.state.report_emailState ||
                            !this.state.report_confirmCheck
                          }
                        >
                          Send Email
                        </Button>
                        <div className="userEmail">
                          <input
                            placeholder="Enter Email ID"
                            type="text"
                            required
                            name="userId"
                            value={this.state.report_email}
                            onChange={e => {
                              this.setState({ report_email: e.target.value });
                            }}
                          />

                          {
                            <div className="confirmed-check">
                              <Form.Group controlId="formBasicCheckbox">
                                <Form.Check
                                  value={this.state.report_confirmCheck}
                                  onChange={this.handleCheck}
                                  type="checkbox"
                                  label="Mark Order as Confirmed"
                                />
                              </Form.Group>
                            </div>
                          }
                          {this.state.report_emailState === "sending" && (
                            <div className="loader-cm inln-blk">
                              <div className="Loader-box">
                                <Loader
                                  type="TailSpin"
                                  color="#00BFFF"
                                  height={30}
                                  width={30}
                                />
                              </div>
                            </div>
                          )}
                          {this.state.report_emailState === "sent" && (
                            <div className="loader-cm  inln-blk">
                              Email Sent Successfully
                            </div>
                          )}
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : null}
              </div>
            ) : null} */}
            </div>
          )}
        {this.state &&
          this.state.category &&
          this.state.docKey &&
          this.state.userId &&
          this.state.activityLog && (
            <Modal
              show={this.state.statusModal}
              backdrop="static"
              keyboard={false}
              dialogClassName="modal-center"
              centered
              onHide={() => this.setState({ statusModal: false })}
            >
              <Modal.Header closeButton />
              <Modal.Body>
                <StatusPopUp2
                  form={this.state.category.value}
                  doctorId={this.state.docKey}
                  id={this.state.userId}
                  setModalState={this.setModalState}
                  activityLog={this.state.activityLog}
                />
              </Modal.Body>
            </Modal>
          )}
        {this.state && this.state.spinner ? (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : null}
      </div>
    );
  }
}

import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./styles.module.scss";
import noop from "lodash/noop";
import { CONSTANTS } from "./constants";

export default function StepInModal({
  onStepInClick = noop,
  isLoading = false,
  onClose = noop
}) {
  return (
    <>
      <div>
        <div>
          <Modal
            show={true}
            keyboard={false}
            dialogClassName="modal-center"
            centered
            closeButton={true}
            style={{ overflow: "hidden" }}
          >
            <div className={styles["stepin-notification-wrapper"]}>
              <div className={styles["header-section"]}>
                <button className={styles["close-button"]} onClick={onClose}>
                  <img src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1652106584/dochub/rx-writer/Subtract_5.png" />
                </button>
              </div>
              <div className={styles["icon-section"]}>
                <img
                  className={styles["icon"]}
                  src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1653907259/dochub/rx-writer/Check_Out.png"
                />
              </div>
              <div className={styles["message-section"]}>
                <div className={styles["message"]}>{CONSTANTS.TITLE}</div>
                <div className={styles["message-highlight"]}>
                  {CONSTANTS.SUBTITLE}
                </div>
              </div>
              <div className={styles["button-section"]}>
                <button
                  className={styles["view-button"]}
                  onClick={onStepInClick}
                  disabled={isLoading}
                >
                  {CONSTANTS.BUTTON_TEXT}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { InputAdornment, IconButton, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";

import config from "../../../constants/apiList";
import { scheduleCols, formDataCols } from "../AdminPage/Columns";
import DataTable3 from "../../common/Tables/DataTable3";
import DataTable2 from "../../common/Tables/DataTable2";
import { getRxWriterLink } from "../../../helpers/rx-writer.helpers";

const appointmentAction = [
  {
    icon: "launch",
    tooltip: "Open Prescription",
    onClick: (event, rowData) => {
      let param = rowData.source + "-" + rowData.id;
      const oldCategories = ["SH", "HC", "OF"];
      if (oldCategories.includes(rowData.source)) {
        param = rowData.source_id;
      }
      window.open(getRxWriterLink(param), "_self");
    }
  }
];

export default function CustomerServicePage() {
  return (
    <div style={{ display: "grid", placeItems: "center" }}>
      <CustomerServiceTable />
      <FormDataSearchTable />
    </div>
  );
}

const CustomerServiceTable = () => {
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const reqBody = {
    admin_key: localStorage.getItem("customerServiceKey"),
    search_item: search
  };
  const handleSubmit = e => {
    e.preventDefault();
    setSearch(query);
  };
  return (
    <>
      <div>
        <h2>Customer Service</h2>
      </div>
      <form id="searchform" onSubmit={handleSubmit}>
        <TextField
          label="Search Here"
          onChange={e => setQuery(e.target.value)}
          value={query}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </form>
      {search && (
        <DataTable3
          title="Search Results"
          cols={scheduleCols}
          url={config.url.AD_SEARCH}
          reqBody={reqBody}
          actions={appointmentAction}
        />
      )}
    </>
  );
};

export const FormDataSearchTable = () => {
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const reqBody = {
    admin_key: localStorage.getItem("customerServiceKey"),
    search: search,
    reqDate: date
  };
  const handleSubmit = e => {
    e.preventDefault();
    setSearch(query);
  };
  return (
    <>
      <div>
        <h2>Form Data Search</h2>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <form>
          <input
            type="date"
            className="input"
            onChange={e => setDate(e.target.value)}
            value={date}
          />
        </form>
        <form id="searchform2" onSubmit={handleSubmit}>
          <TextField
            label="Search Here"
            onChange={e => setQuery(e.target.value)}
            value={query}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </form>
      </div>
      {search && (
        <DataTable2
          title="Search Results"
          cols={formDataCols}
          url={config.url.FORM_DATA_SEARCH}
          reqBody={reqBody}
          actions={appointmentAction}
        />
      )}
    </>
  );
};

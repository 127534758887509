import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { store } from "./reducers/Persist";
import Loader from "react-loader-spinner";
import { customRoles } from "./constants/roles.constants";

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        store.getState().userReducer &&
        store.getState().userReducer.role_name === "admin" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const LoginNormalRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const roleName = store.getState()?.userReducer?.role_name;
    if (roleName) {
      switch (roleName) {
        case "admin":
          history.push("/admin");
          break;
        case "doctor":
          history.push("/dashboard");
          break;
        case "assistant":
          history.push("/cs");
          break;
        default: {
          break;
        }
      }
      if (customRoles.includes(roleName)) history.push("/custom/dashboard");
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader type="TailSpin" />
      </div>
    );
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};

const CSPrivateRoute = ({ component: Component, ...rest }) => {
  const role_names = new Set([
    "assistant",
    "admin",
    "external_reschedule",
    "external_view_only"
  ]);
  return (
    <Route
      {...rest}
      render={props =>
        store.getState().userReducer &&
        role_names.has(store.getState().userReducer.role_name) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const DoctorPrivateRoute = ({ component: Component, ...rest }) => {
  const role_names = new Set(["admin", "doctor"]);
  return (
    <Route
      {...rest}
      render={props =>
        store.getState().userReducer &&
        role_names.has(store.getState().userReducer.role_name) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const CustomRolePrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        store.getState().userReducer &&
        customRoles.includes(store.getState().userReducer.role_name) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export {
  CustomRolePrivateRoute,
  AdminPrivateRoute,
  LoginNormalRoute,
  CSPrivateRoute,
  DoctorPrivateRoute
};

import React from "react";
import { convertActiveHoursTime } from "./helper/date-helper";

export default function ActiveHours({ data }) {
  return (
    <>
      {data.map((item, index) => {
        return (
          <p key={index}>
            {convertActiveHoursTime(item.fromTime)} -{" "}
            {convertActiveHoursTime(item.toTime)}
          </p>
        );
      })}
    </>
  );
}

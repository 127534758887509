import React from "react";
import "./ConditionFirst.scss";
import RecommendedTag from "../RecommendedTag";
import { Combobox } from "react-widgets";
import { store } from "../../../../../../reducers/Persist";
import { connect } from "react-redux";
import { toast } from "react-toastify";
class ConditionFirst extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendedProducts: [],
      restrictedProducts: [],
      presets: [],
      productName: "",
      curProduct: {},
      thresholdProductName:
        this.props.conditions[this.props.index]?.thresholdProduct?.name,
      thresholdProduct: {}
    };
  }
  handleAddProduct = isRecommended => {
    if (
      this.state.curProduct &&
      Object.keys(this.state.curProduct).length > 0
    ) {
      const condition = this.props.conditions[this.props.index];
      if (isRecommended) {
        if (
          condition.added_products.find(
            val => val.id === this.state.curProduct.id
          )
        ) {
          toast.info(
            `${this.state.curProduct.name} has already been recommended`
          );
        } else if (
          condition.removed_products.find(
            val => val.id === this.state.curProduct.id
          )
        ) {
          toast.info(`Cannot recommmend a restricted product`);
        } else {
          store.dispatch({
            type: "ADD_RECOMMENDATION_CONDITION_PRODUCT",
            payload: {
              index: this.props.index,
              isRecommended: true,
              product: this.state.curProduct
            }
          });
        }
      } else {
        if (
          condition.removed_products.find(
            val => val.id === this.state.curProduct.id
          )
        ) {
          toast.info(
            `${this.state.curProduct.name} has already been restricted`
          );
        } else if (
          condition.added_products.find(
            val => val.id === this.state.curProduct.id
          )
        ) {
          toast.info(`Cannot restrict a recommended product`);
        } else {
          store.dispatch({
            type: "ADD_RECOMMENDATION_CONDITION_PRODUCT",
            payload: {
              index: this.props.index,
              isRecommended: false,
              product: this.state.curProduct
            }
          });
        }
      }
    } else {
      toast.error("Please select a product");
    }
  };
  componentDidMount() {
    let temp = [];
    Object.values(this.props.presetData).map(val => {
      if (val.observation_type === this.props.observation_type)
        temp.push({ ...val });
    });
    this.setState({ ...this.state, presets: [...temp] });
  }
  render() {
    return (
      <div className="first-condition-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="type">{this.props.type}</div>
            {this.props.condition_type === "age" ||
            this.props.condition_type === "threshold" ? (
              <div style={{ display: "flex" }}>
                {this.props.condition_type !== "age" && (
                  <Combobox
                    containerClassName="first-condition-search-combobox"
                    style={{ width: "40%", marginRight: "10px" }}
                    hideCaret={true}
                    placeholder="Type product name here..."
                    data={this.props.get_products.filter(
                      product => !product.hideInList
                    )}
                    onChange={value => {
                      this.setState({
                        ...this.state,
                        thresholdProductName: value
                      });
                    }}
                    onSelect={val => {
                      store.dispatch({
                        type: "SET_CONDITION_KEY",
                        payload: {
                          index: this.props.index,
                          key: "thresholdProduct",
                          value: val
                        }
                      });
                    }}
                    onKeyPress={e => {
                      if (e.key === "Enter") {
                        this.setState({
                          ...this.state,
                          thresholdProductName: ""
                        });
                      }
                    }}
                    filter="contains"
                    textField="name"
                    value={this.state.thresholdProductName}
                  />
                )}
                <select
                  className="comparator"
                  onChange={e => {
                    store.dispatch({
                      type: "SET_CONDITION_KEY",
                      payload: {
                        index: this.props.index,
                        key: "comparator",
                        value: e.target.value
                      }
                    });
                  }}
                >
                  <option value="" selected hidden disabled>
                    Select
                  </option>
                  <option
                    value="greater"
                    selected={
                      this.props.conditions[this.props.index]?.comparator ===
                        "greater" ||
                      this.props.conditions[this.props.index]?.condition ===
                        "greater"
                    }
                  >
                    More than
                  </option>
                  <option
                    value="less"
                    selected={
                      this.props.conditions[this.props.index]?.comparator ===
                        "less" ||
                      this.props.conditions[this.props.index]?.condition ===
                        "less"
                    }
                  >
                    Less than
                  </option>
                </select>
                <input
                  onChange={e => {
                    store.dispatch({
                      type: "SET_CONDITION_KEY",
                      payload: {
                        index: this.props.index,
                        key: "durationValue",
                        value: e.target.value
                      }
                    });
                  }}
                  placeholder="Number"
                  className="num"
                  type="number"
                  value={this.props.conditions[this.props.index]?.durationValue}
                />
                <select
                  className="time-period"
                  onChange={e => {
                    store.dispatch({
                      type: "SET_CONDITION_KEY",
                      payload: {
                        index: this.props.index,
                        key: "durationPeriod",
                        value: e.target.value
                      }
                    });
                  }}
                >
                  <option value="" selected hidden disabled>
                    Select
                  </option>
                  <option
                    value="days"
                    selected={
                      this.props.conditions[this.props.index]
                        ?.durationPeriod === "days"
                    }
                  >
                    {" "}
                    Days{" "}
                  </option>
                  <option
                    value="weeks"
                    selected={
                      this.props.conditions[this.props.index]
                        ?.durationPeriod === "weeks"
                    }
                  >
                    {" "}
                    Weeks{" "}
                  </option>
                  <option
                    value="months"
                    selected={
                      this.props.conditions[this.props.index]
                        ?.durationPeriod === "months"
                    }
                  >
                    {" "}
                    Months{" "}
                  </option>
                  <option
                    value="years"
                    selected={
                      this.props.conditions[this.props.index]
                        ?.durationPeriod === "years"
                    }
                  >
                    {" "}
                    Years{" "}
                  </option>
                </select>
                {this.props.condition_type !== "age" && (
                  <select
                    className="improvement"
                    onChange={e => {
                      store.dispatch({
                        type: "SET_CONDITION_KEY",
                        payload: {
                          index: this.props.index,
                          key: "improvement",
                          value: e.target.value
                        }
                      });
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Improvement
                    </option>
                    <option
                      value="true"
                      selected={
                        this.props.conditions[this.props.index]?.improvement ===
                          "true" ||
                        this.props.conditions[this.props.index]?.improvement ===
                          true
                      }
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                      selected={
                        this.props.conditions[this.props.index]?.improvement ===
                          "false" ||
                        this.props.conditions[this.props.index]?.improvement ===
                          false
                      }
                    >
                      No
                    </option>
                  </select>
                )}
              </div>
            ) : (
              <select
                style={{ width: "100%" }}
                onChange={e => {
                  store.dispatch({
                    type: "SET_RECOMMENDATION_CONDITION_PRESET",
                    payload: {
                      condition_preset: e.target.value,
                      index: this.props.index
                    }
                  });
                }}
                className="select-preset"
              >
                <option value="" selected disabled hidden>
                  Select
                </option>
                {this.state.presets.map((val, index) => (
                  <option
                    key={index}
                    value={val.id}
                    selected={
                      Number(
                        this.props.conditions[this.props.index].condition_preset
                      ) === val.id
                    }
                  >
                    {val.observation_preset_text}
                  </option>
                ))}
              </select>
            )}
          </div>
          <img
            loading="lazy"
            src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
            alt="close"
            onClick={this.props.deleteCondition}
            style={{
              margin: "-10px -7px 20px 7px",
              width: "25px",
              height: "25px",
              cursor: "pointer",
              float: "right"
            }}
          />
        </div>
        <div className="product-row">
          <Combobox
            containerClassName="first-condition-search-combobox"
            hideCaret={true}
            placeholder="Type product name here..."
            data={this.props.get_products.filter(
              product => !product.hideInList
            )}
            onChange={value => {
              this.setState({
                ...this.state,
                productName: value
              });
            }}
            onSelect={val => {
              this.setState(
                {
                  curProduct: undefined
                },
                () => {
                  this.setState({ curProduct: val });
                }
              );
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                this.setState({
                  ...this.state,
                  productName: ""
                });
              }
            }}
            filter="contains"
            textField="name"
            value={this.state.productName}
          />
          <button
            onClick={() => this.handleAddProduct(true)}
            className="product-button recommend"
          >
            {" "}
            Recommend{" "}
          </button>
          <button
            onClick={() => this.handleAddProduct(false)}
            className="product-button restrict"
          >
            {" "}
            Restrict
          </button>
        </div>
        {this.props.conditions[this.props.index].added_products.length +
          this.props.conditions[this.props.index].removed_products.length >
          0 && (
          <div className="recommended-products">
            {this.props.conditions[this.props.index].added_products.map(
              (val, index) => (
                <RecommendedTag
                  key={index}
                  product={val}
                  productName={val.name}
                  handleDelete={(isRecommended, id) => {
                    store.dispatch({
                      type: "DELETE_RECOMMENDATION_CONDITION_PRODUCT",
                      payload: {
                        isRecommended,
                        id,
                        index: this.props.index
                      }
                    });
                  }}
                  type="recommended"
                />
              )
            )}
            {this.props.conditions[this.props.index].removed_products.map(
              (val, index) => (
                <RecommendedTag
                  key={index}
                  product={val}
                  productName={val.name}
                  handleDelete={(isRecommended, id) => {
                    store.dispatch({
                      type: "DELETE_RECOMMENDATION_CONDITION_PRODUCT",
                      payload: {
                        isRecommended,
                        id,
                        index: this.props.index
                      }
                    });
                  }}
                  type="restricted"
                />
              )
            )}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {
    conditions: store.getState().recommendationBuilderReducer.conditions
  };
};
export default connect(mapStateToProps)(ConditionFirst);

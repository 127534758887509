import { Popover, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./header-dropdown.scss";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import ViewReportButton from "../../../PatientFile/PatientDetails/ViewReportButton";
import PrescriptionReportButton from "../../../PatientFile/PatientDetails/PrescriptionReportButton";
import ImageTimelineButton from "../../../PatientFile/PatientDetails/ImageTimelineButton";
import { useSelector } from "react-redux";
import { store } from "../../../../../reducers/Persist";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants";
import CONFIG_CONSTANTS from "../../../../../constants/apiList";
import Axios from "axios";

const HeaderDropdwon = () => {
  const [doctorName, setDoctorName] = useState("");

  useEffect(() => {
    setDoctorName(store.getState().rxWriterReducer.loggedInDr.doctor_name);
  }, []);
  const apptId = useSelector(state => state.rxWriterReducer.id);
  const diagnosis = useSelector(
    state => state.observationReducer[apptId]?.diagnosis
  );

  let diagnosisHeader = [];

  const removeUserToken = async () => {
    addToGTM({
      event: GTM_EVENTS?.LOGOUT
    });
    await Axios.get(CONFIG_CONSTANTS.AUTH.LOGOUT, { withCredentials: true });
    await localStorage.removeItem("jwt");
    window.location.href = "/login";
    store.dispatch({ type: "CLEAR" });
  };
  const handleCheckout = () => {
    removeUserToken();
    // this funcition will basically get executed when we click on checkout btn
  };

  const handleLogout = () => {
    removeUserToken();
    // this function will run when we click on logout btn
  };
  !!diagnosis &&
    diagnosis.length > 0 &&
    diagnosis.map((val, index) => {
      let obj = {
        diagnosisText: [],
        preset: []
      };
      if (!!val.text) {
        obj = {
          diagnosisText: [val.text],
          preset: []
        };
      }
      if (val.pretext && val.pretext.length > 0) {
        obj = {
          diagnosisText: [...obj.diagnosisText],
          preset: [...val.pretext]
        };
      }
      diagnosisHeader.push({ ...obj });
    });
  let allergyHeader = [];
  const allergy = useSelector(
    state => state.observationReducer[apptId]?.allergySpecifications
  );
  const presetData = useSelector(state => state.rxWriterReducer.presetData);

  allergy &&
    allergy.length > 0 &&
    allergy.map(val => {
      if (!!val.text) {
        allergyHeader.push({
          type: val.metadata.type,
          preset: [...val.pretext],
          presetTextArr: [val.text]
        });
      } else
        allergyHeader.push({
          type: val.metadata.type,
          preset: [...val.pretext],
          presetTextArr: []
        });
    });
  for (let val in presetData) {
    diagnosisHeader.length > 0 &&
      diagnosisHeader.map((diagnosisObj, index) => {
        if (diagnosisObj.preset.includes(presetData[val].id)) {
          diagnosisHeader[index] = {
            ...diagnosisHeader[index],
            diagnosisText: [
              ...diagnosisHeader[index].diagnosisText,
              presetData[val].observation_preset_text
            ]
          };
        }
      });
    allergyHeader.length > 0 &&
      allergyHeader.map((allergyObj, index) => {
        if (allergyObj.preset.includes(presetData[val].id)) {
          allergyHeader[index] = {
            ...allergyHeader[index],
            presetTextArr: [
              ...allergyHeader[index].presetTextArr,
              presetData[val].observation_preset_text
            ]
          };
        }
      });
  }
  let diagnosisFinalArr = [];
  diagnosisHeader.length &&
    diagnosisHeader.map((val, index) => {
      !!val.diagnosisText &&
        val.diagnosisText.map((text, index) => {
          diagnosisFinalArr.push(text);
        });
    });
  const prescriptionActive = useSelector(
    state => state.footerReducer.unlockedTabs.prescription
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [active, setActive] = React.useState(false);
  const patientDetails = useSelector(
    state => state.rxWriterReducer.patientDetails
  );
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setActive(currVal => !currVal);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="header-dropdown__wrapper">
      {patientDetails?.name && (
        <div
          className="header-dropdown__name"
          aria-describedby={id}
          onClick={handleClick}
        >
          {doctorName}
          {active ? (
            <MdKeyboardArrowUp className="dropdown__name_icon" />
          ) : (
            <MdKeyboardArrowDown className="dropdown__name_icon" />
          )}
        </div>
      )}
      {active && (
        <div className="header-popup__wrapper">
          <button onClick={handleCheckout}>Checkout</button>
          <button onClick={handleLogout}>Logout</button>
          {/* <div className="header-popup__top">
            <h3 className="header-popup__top-title">Introduction</h3>
            <div className="header-popup__top-category__wrapper">
              <div className="header-popup__top-category">
                <div className="header-popup__top-category__title">Age</div>
                <div className="header-popup__top-category__detail">
                  {patientDetails.age || "-"}
                </div>
              </div>
              <div className="header-popup__top-category">
                <div className="header-popup__top-category__title">Weight</div>
                <div className="header-popup__top-category__detail">
                  {patientDetails.weight
                    ? patientDetails.weight.toString() + "Kgs"
                    : "-"}
                </div>
              </div>
              <div className="header-popup__top-category">
                <div className="header-popup__top-category__title">Gender</div>
                <div className="header-popup__top-category__detail">
                  {patientDetails.gender === "M"
                    ? "Male"
                    : patientDetails.gender === "F"
                    ? "Female"
                    : "-"}
                </div>
              </div>
              <div className="header-popup__top-category">
                <div className="header-popup__top-category__title">
                  Marital Status
                </div>
                <div className="header-popup__top-category__detail">
                  {patientDetails?.marital_status || "-"}
                </div>
              </div>
              <div className="header-popup__top-category">
                <div className="header-popup__top-category__title">City</div>
                <div className="header-popup__top-category__detail">
                  {patientDetails.city || "-"}
                </div>
              </div>
              <div className="header-popup__top-category">
                <div className="header-popup__top-category__title">State</div>
                <div className="header-popup__top-category__detail">
                  {patientDetails.state || "-"}
                </div>
              </div>
            </div>
          </div> */}
          {/* <Grid container spacing={0}>
            <Grid xs={4} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">Last Diagnostic Report</div>
                <ViewReportButton isHeaderQuickView={true} />
              </div>
            </Grid>
            <Grid xs={4} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">Last Prescription</div>
                <PrescriptionReportButton isHeaderQuickView={true} />
              </div>
            </Grid>
            <Grid xs={4} className="col" item={true}>
              <div className="patient-detail">
                <div className="subheading">Images</div>
                <ImageTimelineButton isHeaderQuickView={true} />
              </div>
            </Grid>
          </Grid> */}
          {/* {prescriptionActive && (
            <div className="header-popup__bottom">
              <div className="header-popup__bottom__left">
                <h6>Diagnosis</h6>
                {diagnosisFinalArr.map((val, index) => (
                  <p key={index}>{val}</p>
                ))}
              </div>
              <div className="header-popup__bottom__right">
                <h6>Allergies</h6>
                <div className="header-popup__bottom__right__wrapper">
                  {allergyHeader.map((val, index) => (
                    <p>
                      <span>{val.type}</span>:
                      {val.presetTextArr.map(val => (
                        <span>{val} </span>
                      ))}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default HeaderDropdwon;

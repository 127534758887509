import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import "./PCI.scss";

const emojiMap = {
  1: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1649233547/Icons%20BW%20DA%20flow/Instant%20appt/Group_18.png",
  2: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1649233547/Icons%20BW%20DA%20flow/Instant%20appt/Group_28.png",
  3: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1649233547/Icons%20BW%20DA%20flow/Instant%20appt/Group_23.png",
  4: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1649233547/Icons%20BW%20DA%20flow/Instant%20appt/Group_16.png",
  5: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1649233547/Icons%20BW%20DA%20flow/Instant%20appt/Group_17.png"
};

let cols1 = ["PCI Rating", "1", "2", "3", "4", "5", "Total", "Avg. Rating"].map(
  col => {
    return {
      title: emojiMap[col] ? (
        <img
          style={{ width: "20px", height: "20px" }}
          src={emojiMap[col]}
        ></img>
      ) : (
        col
      ),
      field: col
    };
  }
);
let cols2 = [
  "Source",
  "F",
  "DA",
  "FU",
  "O",
  "MA",
  "Total",
  "No. PCI",
  "PCI %"
].map(col => {
  return {
    title: col,
    field: col
  };
});
let cols3 = ["Source", "F", "DA", "FU", "O", "MA", "Average"].map(col => {
  return {
    title: col,
    field: col
  };
});

export default function PCI({ reqBody }) {
  const [data, setData] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [data4, setData4] = useState([]);
  const [brand, setBrand] = useState("both");

  useEffect(() => {
    if (
      moment(reqBody?.to_date, "YYYY-MM-DD").diff(
        moment(reqBody?.from_date, "YYYY-MM-DD"),
        "days"
      ) > 7
    ) {
      toast.error("Please select date range less than 7 days", {
        position: "top-right"
      });
      return;
    }
    axios
      .post(config.url.PCI_DOCTOR_DASHBOARD, reqBody)
      .then(res => {
        setData(res.data.results);
      })
      .catch(err => {
        console.log(err);
        toast.error(err?.message || "Something Went Wrong!", {
          position: "top-right"
        });
      });
  }, [reqBody?.from_date, reqBody?.to_date]);

  useEffect(() => {
    let map = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    let map2 = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    let total = 0;
    if (!data) return;
    let dataa = data.filter(row => row.brand === brand || brand === "both");
    dataa.forEach(row => {
      if (!row.nps_score || row.nps_irrelevant) return;
      map[row.nps_score] += 1;
      ++total;
    });
    let sum = 0;
    for (let key in map) {
      sum += map[key] * key;
      map2[key] = map[key];

      const map2Percentage =
        (map2[key] / total ? ((map2[key] / total) * 100).toFixed(2) : 0) + "%";
      map[key] = `${map[key]} | ${map2Percentage}`;
    }
    map["Total"] = `${total} | 100%`;
    map["PCI Rating"] = "Count";
    map["Avg. Rating"] = sum / total ? (sum / total).toFixed(2) : 0;
    let data1 = [map];
    for (let key in map2) {
      map2[key] =
        (map2[key] / total ? ((map2[key] / total) * 100).toFixed(2) : 0) + "%";
    }
    map2["Total"] = "100%";
    map2["PCI Rating"] = "Percentage";
    let data2 = [map2];

    let map3 = {
      F: 0,
      DA: 0,
      FU: 0,
      O: 0,
      MA: 0,
      Total: 0,
      "No. PCI": 0,
      Source: "Count"
    };
    dataa.forEach(row => {
      if (row.status === "c" && row.duration > 0) {
        map3[row.source] += 1;
        map3["Total"] += 1;
      }
      if (row.nps_score && !row.nps_irrelevant) {
        map3["No. PCI"] += 1;
      }
    });
    map3["PCI %"] =
      (map3["No. PCI"] / map3["Total"]
        ? ((map3["No. PCI"] / map3["Total"]) * 100).toFixed(2)
        : 0) + "%";
    let data3 = [map3];

    let map4 = {
      F: 0,
      DA: 0,
      FU: 0,
      O: 0,
      MA: 0,
      Total: 0,
      Average: 0
    };
    // console.log("DATAA", dataa);
    let durationSum = 0;
    dataa.forEach(row => {
      if (row.status === "c" && row.duration > 0) {
        map4[row.source] += row.duration;
        durationSum += row.duration;
      }
    });
    ["F", "DA", "FU", "O", "MA"].forEach(col => {
      map4[col] =
        map4[col] > 0
          ? moment("1900-01-01 00:00:00")
              .add(map4[col], "seconds")
              .format("mm:ss")
          : "-";
    });

    map4["Average"] =
      map3["Total"] != 0
        ? moment("1900-01-01 00:00:00")
            .add(durationSum / map3["Total"], "seconds")
            .format("mm:ss")
        : "-";
    map4["Source"] = "Duration";
    let data4 = [map4];

    setData1(data1);
    setData2(data2);
    setData3(data3);
    setData4(data4);
  }, [brand, data]);

  return (
    <PCITable2
      data1={data1}
      data2={data2}
      data3={data3}
      data4={data4}
      brand={brand}
      setBrand={setBrand}
    />
  );
}

function PCITable2({ data1, data2, data3, data4, brand, setBrand }) {
  const brands = ["both", "MM", "BW", "LJ"];
  return data1 && data2 && data3 && data4 ? (
    <div className="pci">
      <div className="brandDropDown">
        <label>Brand</label>
        <Dropdown className="">
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic-button"
            size="sm"
          >
            {brand.toUpperCase() || "Select Brand"}
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              maxHeight: "200px",
              overflow: "auto",
              width: "max-content"
            }}
          >
            {brands.map(Vbrand => {
              return (
                <>
                  <Dropdown.Item onClick={() => setBrand(Vbrand)} name={Vbrand}>
                    {Vbrand.toUpperCase()}
                  </Dropdown.Item>
                </>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Table4
        cols={cols1}
        rows={data1}
        title="Overall Doctor PCI"
        pagin={false}
        disableHeaderStyle={true}
      />
      <Table4
        cols={cols2}
        rows={data3}
        title="Total RX Generated"
        pagin={false}
        disableHeaderStyle={true}
      />
      <Table4
        cols={cols3}
        rows={data4}
        title="Average Duration"
        pagin={false}
        disableHeaderStyle={true}
      />
    </div>
  ) : null;
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MaterialTable from "material-table";
import adminService from "../../../services/adminService";
import { confirmBox } from "../../common/PopupBox/PopupBox";
import CONFIG_CONSTANTS from "./../../../constants/apiList";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditableDataTable5({
  title,
  url,
  reqBody,
  cols,
  urlPost
}) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(url)
      .then(response => setData(response.data))
      .catch(console.error);
  }, [url]);
  return data ? (
    <MaterialTable
      title={title}
      columns={cols}
      data={data?.data}
      options={{
        exportButton: true,
        exportAllData: true,
        pageSize: 10,
        headerStyle: {
          backgroundColor: "#003459",
          color: "#FFF"
        },
        filtering: true
      }}
      editable={{
        onRowAdd: async newData => {
          if (title === "Doctor OOC") {
            confirmBox(
              `You are about to put a doctor into OOC, do you wish to Continue?`,
              () => {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    adminService.sendData(urlPost, {
                      type: "add",
                      data: newData,
                      autoReassign: reqBody.auto_reassign
                    });
                    setTimeout(
                      () =>
                        dispatch({
                          type: "REFRESH"
                        }),
                      3000
                    );
                    resolve();
                  }, 1);
                });
              }
            );
          } else {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                if (
                  !Object.keys(newData).length ||
                  newData?.doctorId === "" ||
                  newData?.languageId === "" ||
                  newData?.categoryId === "" ||
                  newData?.brandId === "" ||
                  newData?.toTime === "" ||
                  newData?.fromTime === "" ||
                  newData?.weekday === ""
                ) {
                  toast.error("All fields except Doctor Name are required");
                  reject();
                  return;
                }
                adminService.sendData(urlPost, {
                  type: "add",
                  data: newData,
                  autoReassign: reqBody.auto_reassign
                });
                setTimeout(
                  () =>
                    dispatch({
                      type: "REFRESH"
                    }),
                  3000
                );
                resolve();
              }, 1);
            });
          }
        },
        onRowUpdate: async (newData, oldData) => {
          if (title === "Doctor OOC") {
            confirmBox(
              `You are about to edit a doctors current OOC Settings, do you wish to Continue?`,
              () => {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    adminService.sendData(urlPost, {
                      id: oldData.id,
                      type: "update",
                      data: newData,
                      autoReassign: reqBody.auto_reassign
                    });
                    setTimeout(
                      () =>
                        dispatch({
                          type: "REFRESH"
                        }),
                      3000
                    );
                    resolve();
                  }, 1);
                });
              }
            );
          } else {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                if (
                  !Object.keys(newData).length ||
                  newData?.doctorId === "" ||
                  newData?.languageId === "" ||
                  newData?.categoryId === "" ||
                  newData?.brandId === "" ||
                  newData?.toTime === "" ||
                  newData?.fromTime === "" ||
                  newData?.weekday === ""
                ) {
                  toast.error("All fields except Doctor Name are required");
                  reject();
                  return;
                }
                adminService.sendData(urlPost, {
                  id: oldData.id,
                  type: "update",
                  data: newData,
                  autoReassign: reqBody.auto_reassign
                });
                setTimeout(
                  () =>
                    dispatch({
                      type: "REFRESH"
                    }),
                  3000
                );
                resolve();
              }, 1);
            });
          }
        },
        onRowDelete: oldData => {
          const newId =
            title === "Doctor Hours" ? oldData.uniqueId : oldData.id;
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              adminService.sendData(urlPost, {
                id: newId,
                type: "delete",
                data: oldData
              });
              setTimeout(
                () =>
                  dispatch({
                    type: "REFRESH"
                  }),
                3000
              );
              resolve();
            }, 1);
          });
        }
      }}
      style={{ border: "1px solid black" }}
    />
  ) : null;
}

import React from "react";
import OrderSelect from "../OrderSelect";
import "./AddProduct.scss";
import SearchProduct from "./SearchProduct";
import { store } from "../../../../reducers/Persist";
import { useSelector } from "react-redux";
export default class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      editActive: true
    };
  }

  handleAddBtn = () => {
    this.setState({
      ...this.state,
      isAdding: false,
      editActive: false
    });
  };

  render() {
    const { prescription_orderSelect: products } = store.getState();
    return (
      <div className="add-product-container">
        {this.state.isAdding ? (
          <div>
            <SearchProduct
              editActive={this.state.editActive}
              handleAddBtn={this.handleAddBtn}
              cancelDelete={true}
            />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <button
              className="add-product-button"
              onClick={() => this.setState({ ...this.state, isAdding: true })}
            >
              Add Product +
            </button>
          </div>
        )}
        <OrderSelect
          products={[...products]}
          editActive={this.props.editActive}
        />
      </div>
    );
  }
}

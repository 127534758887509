import React, { useState, useEffect, useMemo } from "react";
import { NoDataFound, TableContainer } from "./styles";
import MaterialTable from "material-table";
import { MaterialTableWrapper } from "../V2/AppointmentSummary/common/MaterialTableWrapper";
import { allDoctorsOverviewColumns, dataTextFormat } from "./data";

export default function AllDoctorsOverviewSection({ responseData }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  const data = useMemo(
    () =>
      responseData.map(rowData => ({
        doctor: `${rowData?.doctorName}(${rowData?.doctorId})`,
        total: rowData?.ALL?.total,
        cancelled: dataTextFormat(
          rowData?.ALL?.cancelled,
          rowData?.ALL?.cancelledRate
        ),
        addressed: dataTextFormat(
          rowData?.ALL?.addressed,
          rowData?.ALL?.addressalRate
        ),
        totalIA: dataTextFormat(
          rowData?.IA?.total,
          rowData?.IA?.totalPercentage
        ),
        iAAddressal: dataTextFormat(
          rowData?.IA?.addressed,
          rowData?.IA?.addressalRate
        ),
        iACompleted: dataTextFormat(
          rowData?.IA?.completed,
          rowData?.IA?.completionRate
        ),
        totalSchedued: dataTextFormat(
          rowData?.S?.total,
          rowData?.S?.totalPercentage
        ),
        scheduledAddressal: dataTextFormat(
          rowData?.S?.addressed,
          rowData?.S?.addressalRate
        ),
        scheduledCompleted: dataTextFormat(
          rowData?.S?.completed,
          rowData?.S?.completionRate
        ),
        totalLSD: dataTextFormat(
          rowData?.LSD?.total,
          rowData?.LSD?.totalPercentage
        ),
        lsdAddressal: dataTextFormat(
          rowData?.LSD?.addressed,
          rowData?.LSD?.addressalRate
        ),
        lsdCompleted: dataTextFormat(
          rowData?.LSD?.completed,
          rowData?.LSD?.completionRate
        ),
        validTotal: dataTextFormat(
          rowData?.ALL?.valid,
          rowData?.ALL?.validRate
        ),
        validCompleted: dataTextFormat(
          rowData?.ALL?.completed,
          rowData?.ALL?.validCompletionRate
        ),
        averagePci: rowData?.ALL?.averagePci,
        averageDuration: rowData?.ALL?.averageDuration,
        activeHours: `${rowData?.actualHoursDuration}/${rowData?.dutyHoursDuration}`
      })),
    [responseData]
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, []);

  if (!data || (data && data.length === 0))
    return (
      <NoDataFound>
        <span>No Data Found</span>
      </NoDataFound>
    );
  return (
    <TableContainer>
      <MaterialTableWrapper>
        <MaterialTable
          title={null}
          columns={allDoctorsOverviewColumns}
          data={data || []}
          page={10}
          options={{
            search: true,
            paging: true,
            exportAllData: true,
            exportButton: true,
            sorting: false,
            draggable: false,
            headerStyle: {
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "14px"
            },

            rowStyle: {
              backgroundColor: "white",
              width: "min-content",
              padding: "20px"
            },
            cellStyle: {
              fontSize: matches ? "13px" : "15px",
              backgroundColor: "white",
              color: "#6C6C6C"
            }
          }}
          style={{
            margin: "10px",
            padding: "10px",
            marginTop: "0px",
            paddingTop: "0px",
            backgroundColor: "transparent",
            boxShadow: "none"
          }}
        />
      </MaterialTableWrapper>
    </TableContainer>
  );
}

import React, { useCallback } from "react";
import config from "../../../constants/apiList";
import SignInWithGoogle from "./SignInGoogle.ui";

export default function NewLoginPage() {
  const getLoginURL = useCallback(() => {
    return `${config.AUTH.LOGIN}?callbackURL=${window.location.origin}${config.AUTH.CALLBACK_URL}&promt=false`;
  }, []);

  return <SignInWithGoogle url={getLoginURL()} />;
}
export function NewOTPLoginPage() {
  const getLoginURL = useCallback(() => {
    return `${config.AUTH.LOGIN}?callbackURL=${window.location.origin}${config.AUTH.CALLBACK_URL}&promt=false`;
  }, []);

  return <SignInWithGoogle url={getLoginURL()} otp={true} />;
}

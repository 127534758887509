import React from "react";
export default function AudioIcon() {
  return (
    <svg
      style={{
        marginLeft: "50%",
        transform: "translateX(-50%)"
      }}
      width="54.5"
      height="52.5"
      viewBox="0 0 109 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.4671 105C68.1588 104.929 61.2479 103.175 54.6066 100.245C44.4033 95.7453 35.4773 89.3349 27.3601 81.7642C19.0159 73.9528 11.9772 65.1509 6.755 54.9481C3.83169 49.2311 1.60372 43.2594 0.539408 36.9057C-0.638434 29.9292 0.0285369 23.1651 3.56206 16.8962C7.03882 10.7123 12.4172 7.40094 19.4842 6.7783C20.5627 6.67924 21.6554 6.66509 22.7339 6.59433C24.6497 6.48112 26.2532 7.17452 27.6298 8.44811C29.5739 10.2594 30.8511 12.5236 31.8302 14.9292C32.8378 17.3632 33.6609 19.8679 34.5975 22.3443C35.5199 24.8066 36.499 27.2689 37.3931 29.7453C37.7904 30.8491 38.1026 31.9953 38.358 33.1415C38.798 35.1368 38.4432 37.0472 37.3931 38.7877C35.7469 41.5189 33.6893 43.8962 30.993 45.6792C30.3686 46.0896 29.73 46.5142 29.1624 47.0094C27.871 48.1132 27.6865 49.0189 28.6799 50.3915C30.4537 52.8538 32.1708 55.4151 34.2143 57.6368C40.359 64.2736 46.9719 70.4434 54.1667 75.9623C54.5782 76.2736 55.0323 76.5425 55.458 76.8396C56.054 77.2642 56.5649 77.2076 57.09 76.6698C59.5876 74.1934 62.0852 71.717 64.5969 69.2547C66.4417 67.4576 68.6129 66.3962 71.2241 66.6509C72.2458 66.75 73.2675 67.033 74.2325 67.4151C81.2002 70.1321 88.1538 72.8774 95.1215 75.6227C97.4346 76.5283 98.2718 78.5094 98.6408 80.7311C99.2794 84.6227 98.4847 88.3302 96.9237 91.8962C95.0221 96.2264 92.3401 99.9057 88.0544 102.226C85.1737 103.783 82.0943 104.59 78.8587 104.83C77.7377 104.901 76.6024 104.943 75.4671 105Z"
        fill="#5BB47F"
      />
      <path
        d="M55.1175 -2.30339e-06C65.8174 0.466979 75.6658 3.55188 84.4926 9.60849C96.9947 18.1981 104.856 29.9434 107.936 44.7736C108.518 47.5472 108.716 50.4198 108.972 53.25C109.128 54.934 107.922 56.1651 106.276 56.25C104.8 56.3349 103.537 55.1038 103.452 53.4906C102.458 34.4434 91.4319 18.0849 74.1332 10.0896C67.5913 7.06132 60.6803 5.58962 53.4572 5.54717C51.6407 5.53302 50.4771 4.51415 50.3919 2.91509C50.3068 1.33019 51.513 0.0566015 53.1308 0.0141486C53.7977 -0.0141532 54.4505 -2.30339e-06 55.1175 -2.30339e-06Z"
        fill="#5BB47F"
      />
      <path
        d="M54.053 14.5047C70.3441 15.1556 85.0884 25.9811 90.8499 41.5613C92.2406 45.2972 93.0353 49.1604 93.2766 53.1509C93.3901 54.9339 92.2548 56.1934 90.5519 56.2641C89.0761 56.3207 87.8982 55.1179 87.7421 53.4622C86.4082 39.1132 79.1283 28.9104 66.0443 22.8821C62.0851 21.0566 57.842 20.1792 53.4712 20.0519C51.7541 19.9953 50.6188 19.1179 50.4202 17.6745C50.1789 15.9481 51.4561 14.5047 53.2016 14.5047C53.4854 14.5047 53.7692 14.5047 54.053 14.5047Z"
        fill="#5BB47F"
      />
      <path
        d="M76.5171 53.4622C76.5455 54.9481 75.3393 56.1651 73.8066 56.25C72.4585 56.3349 71.1246 55.1886 70.9827 53.7877C69.8616 43.5849 62.7236 35.9858 52.5771 34.217C51.1155 33.9622 50.1647 32.533 50.4201 30.9905C50.6613 29.5754 52.123 28.5 53.5705 28.783C63.7453 30.7075 70.8408 36.4245 74.8 45.9764C75.7934 48.3962 76.2759 50.9434 76.5171 53.4622Z"
        fill="#5BB47F"
      />
    </svg>
  );
}

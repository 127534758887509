import React from "react";
import Product from "./Product";
import "./styles.scss";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import NewProduct from "./NewProduct";
class OtherProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingNewProduct: false
    };
  }
  handleOnCancelNewProduct = () => {
    this.setState({ addingNewProduct: false });
  };
  handleAddNewProduct = product => {
    let tempAddProducts = this.props.products;
    tempAddProducts.push(product);
    console.log("temp add prods", tempAddProducts);
    store.dispatch({ type: "SET_OTHER_PRODUCT", payload: tempAddProducts });
    this.setState({ addingNewProduct: false });
  };

  handleSetProduct = (index, setProduct) => {
    let tempSetProds = this.props.products;
    console.log("tempset", tempSetProds, setProduct);
    tempSetProds[index] = setProduct;
    console.log(tempSetProds);
    store.dispatch({ type: "SET_OTHER_PRODUCTS", payload: tempSetProds });
  };

  handleDeleteProduct = index => {
    let tempProducts = [];
    console.log(index);
    for (let i = 0; i < this.props.products.length; i++) {
      if (i !== index) {
        console.log(i);
        tempProducts.push(this.props.products[i]);
      }
    }

    console.log("deleting ", tempProducts);
    store.dispatch({ type: "SET_OTHER_PRODUCTS", payload: tempProducts });
    console.log(
      "deleted in func",
      this.props.products,
      this.state.addingNewProduct
    );
  };
  render() {
    return (
      <div className="otherProducts__container">
        <h4 className="container__title">3rd Party Products</h4>
        {(this.props.products && this.props.products.length > 0) ||
        this.state.addingNewProduct ? (
          <div className="otherProductsCard">
            <div className="otherProductsCard__top">
              <div className="OPC__top_product">Products</div>
              <div className="OPC__top_days">Days</div>
              <div className="OPC__top_instruction">Instructions</div>
            </div>
            {this.props.products.map((val, index) => (
              <Product
                productItem={val}
                index={index}
                handleSetProduct={this.handleSetProduct}
                handleDeleteProduct={this.handleDeleteProduct}
                key={index}
                // handleOnCancel={this.handleOnCancel}
              />
            ))}
            {!this.state.addingNewProduct ? (
              <button
                onClick={() => this.setState({ addingNewProduct: true })}
                className="btn1__addOtherProducts"
              >
                Add 3rd Party Products +
              </button>
            ) : (
              <NewProduct
                handleAddNewProduct={this.handleAddNewProduct}
                handleOnCancelNewProduct={this.handleOnCancelNewProduct}
              />
            )}
          </div>
        ) : (
          <button
            className="btn2__addOtherProducts"
            onClick={() => this.setState({ addingNewProduct: true })}
          >
            Add 3rd Party Products +
          </button>
        )}
      </div>
    );
  }
}
const mapStateToProps = () => {
  const id = store.getState().rxWriterReducer.id;
  return {
    products: store.getState().observationReducer[id].otherProducts
  };
};
export default connect(mapStateToProps)(OtherProducts);

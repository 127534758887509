import { useEffect, useState, useMemo } from "react";
import { io } from "socket.io-client";
import { videoCallSocketConfig } from "../../../constants/socketList";

const { socketPath, socketUrl } = videoCallSocketConfig;

const TIMEOUT_SOCKET = 1000 * 10;
/**
 * Configurable timeout delay in milliseconds. Suggested to add 5 seconds extra delay only internally because life is not perfect.
 */
const TIMEOUT_DELAY = 1000 * 60 * 1.5;
export const useVideoSocket = (userId, doctorJwtToken, doctorId) => {
  /**
   * @description The socket connection to the server
   */
  const socket = useMemo(
    () =>
      io(socketUrl, {
        auth: {
          user_id: parseInt(userId),
          token: doctorJwtToken,
          doctorId: parseInt(doctorId)
        },
        path: socketPath,
        transports: ["polling"]
      }),
    [userId, doctorJwtToken, doctorId]
  );
  /**
   * Appointment Status in case the doctor has received a new appointment or if the doctor has missed the
   * Video call appointment. These will be handled by the timeout that will be set each time the socket receives a new appointment data
   * for the doctor.
   */
  const [appointmentStatus, setAppointmentStatus] = useState({
    isAppointmentMissed: false,
    isAppointmentReceived: false,
    isAppointmentRejected: false
  });
  /**
   * Socket statuses define whether the socket is connected and if its loading
   */
  const [socketStatus, setSocketStatus] = useState({
    isSocketLoading: true,
    isSocketConnected: false,
    isSocketError: false,
    socketErrorMessaage: ""
  });
  /**
   * The data that we receive from socket
   */
  const [data, setData] = useState(null);
  /**
   * The timer using setTimeout that is setup everytime an appointment is received from the socket
   */
  const [timer, setTimer] = useState(null);
  useEffect(() => {
    socket.on("connect", () => {
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: false,
        isSocketConnected: true,
        isSocketError: false,
        socketErrorMessaage: ""
      });
      setAppointmentStatus({
        isAppointmentMissed: false,
        isAppointmentReceived: false,
        isAppointmentRejected: false
      });
    });
    socket.on("disconnect", () => {
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: true,
        isSocketConnected: false,
        isSocketError: false,
        socketErrorMessaage: ""
      });
      setAppointmentStatus({
        isAppointmentMissed: false,
        isAppointmentReceived: false,
        isAppointmentRejected: false
      });
    });
    socket.on("connect_error", error => {
      setSocketStatus({
        ...socketStatus,
        isSocketConnected: false,
        isSocketError: true,
        socketErrorMessage: error.message,
        isSocketLoading: false
      });
      setData(null);
    });
    /**
     * When a new appointment is assigned to the doctor
     */
    socket.on(VIDEO_STATUS.ASSIGNED, data => {
      if (parseInt(doctorId) === parseInt(data.data.appointmentInfo.doctorId)) {
        setData(data);
        setAppointmentStatus({
          isAppointmentMissed: false,
          isAppointmentReceived: true,
          isAppointmentRejected: false
        });
        const timeout = setTimeout(() => {
          socket
            .timeout(TIMEOUT_SOCKET)
            .emit(VIDEO_STATUS.MISSED, data, (err, response) => {
              if (err || !response) {
                socket.emit(VIDEO_STATUS.MISSED, data);
              }
            });
          setData(null);
          setAppointmentStatus({
            isAppointmentMissed: true,
            isAppointmentReceived: false,
            isAppointmentRejected: false
          });
          setTimer(null);
        }, TIMEOUT_DELAY);
        setTimer(timeout);
      } else {
        console.warn(
          "Doctor Id does not match",
          parseInt(doctorId),
          parseInt(data.data.appointmentInfo.doctorId)
        );
      }
    });
    return () => {
      socket.close();
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: true,
        isSocketConnected: false,
        isSocketError: false,
        socketErrorMessaage: ""
      });
      setAppointmentStatus({
        isAppointmentMissed: false,
        isAppointmentReceived: false,
        isAppointmentRejected: false
      });
    };
  }, [socket]);
  /**
   * When the doctor clicked on Join button in the pop up called
   */
  const onJoinClicked = () => {
    socket
      .timeout(TIMEOUT_SOCKET)
      .emit(VIDEO_STATUS.JOINED, data, (err, response) => {
        if (err || !response) {
          socket.emit(VIDEO_STATUS.JOINED, data);
        }
      });
    clearTimeout(timer);
    setTimer(null);
    setAppointmentStatus({
      isAppointmentMissed: false,
      isAppointmentReceived: false,
      isAppointmentRejected: false
    });
  };
  /**
   * On call completed, send the duration of the video call
   */
  const onCallCompleted = duration => {
    socket
      .timeout(TIMEOUT_SOCKET)
      .emit(VIDEO_STATUS.COMPLETED, { data, duration }, (err, response) => {
        if (err || !response) {
          socket.emit(VIDEO_STATUS.COMPLETED, { data, duration });
        }
      });
    setData(null);
  };
  /**
   * When the doctor rejected the Appoinment by clicking on the Reject button
   */
  const onRejectedClicked = () => {
    socket
      .timeout(TIMEOUT_SOCKET)
      .emit(VIDEO_STATUS.REJECTED, data, (err, response) => {
        if (err || !response) {
          socket.emit(VIDEO_STATUS.REJECTED, data);
        }
      });
    clearTimeout(timer);
    setTimer(null);
    setData(null);
    setAppointmentStatus({
      isAppointmentMissed: false,
      isAppointmentReceived: false,
      isAppointmentRejected: true
    });
  };
  /**
   * Reset all state of the hook, just in case
   */
  const resetData = () => {
    setData(null);
    setAppointmentStatus({
      isAppointmentMissed: false,
      isAppointmentReceived: false,
      isAppointmentRejected: false
    });
    setTimer(null);
  };
  return {
    onJoinClicked,
    onRejectedClicked,
    onCallCompleted,
    appointmentStatus,
    socketStatus,
    data,
    resetData
  };
};
var VIDEO_STATUS;
(function (VIDEO_STATUS) {
  VIDEO_STATUS["JOINED"] = "video:joined";
  VIDEO_STATUS["REJECTED"] = "video:rejected";
  VIDEO_STATUS["MISSED"] = "video:missed";
  VIDEO_STATUS["ASSIGNED"] = "video:assigned";
  VIDEO_STATUS["COMPLETED"] = "video:completed";
})(VIDEO_STATUS || (VIDEO_STATUS = {}));

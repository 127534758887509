import React from "react";
import styles from "./styles.module.scss";
import SearchSection from "./SearchSection";
import ProfileSection from "./ProfileSection";

const Header = () => {
  return (
    <div className={styles["header"]}>
      <div className={styles["header-main"]}>
        <SearchSection />
        <ProfileSection />
      </div>
    </div>
  );
};

export default Header;

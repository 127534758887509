import React from "react";
import { useAsync } from "../../../hooks";
import Table2 from "./Table2";
import dataResource from "../../../constants/dataResource";
const statusListObj = dataResource.statusListObj;
export default function DataTable2({
  title,
  cols,
  url,
  reqBody,
  actions,
  rowStyle,
  search,
  pageSize,
  fromS3
}) {
  const [data] = useAsync(url, reqBody, fromS3);
  const columns = cols.map(col => {
    if (col == "status") {
      return {
        title: col,
        field: col,
        render: row => statusListObj[row.status]
      };
    }
    return {
      title: col,
      field: col
    };
  });
  return data ? (
    <Table2
      title={title}
      cols={columns}
      rows={data}
      actions={actions ? actions : []}
      rowStyle={rowStyle}
      search={search}
      pageSize={pageSize}
    />
  ) : null;
}

import CONFIG_CONSTANTS from "../constants/apiList";
const basePath = "/event-scheduler/mosaic_socket/";

const currentAppointmentSocketConfig = {
  socketUrl: CONFIG_CONSTANTS.url.SOCKET_CURRENT_APPOINTMENT,
  socketPath: basePath
};

const videoCallSocketConfig = {
  socketUrl: CONFIG_CONSTANTS.url.SOCKET_TRIGGER_VIDEO_CALL,
  socketPath: basePath
};

const shortCallSocketConfig = {
  socketUrl: CONFIG_CONSTANTS.url.SOCKET_TRIGGER_SHORT_CALL,
  socketPath: basePath
};

const doctorStatusChangeSocketConfig = {
  socketUrl: CONFIG_CONSTANTS.url.SOCKET_DOCTOR_STATUS_CHANGE,
  socketPath: basePath
};

export {
  currentAppointmentSocketConfig,
  videoCallSocketConfig,
  shortCallSocketConfig,
  doctorStatusChangeSocketConfig
};

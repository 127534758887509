import React from "react";
import styles from "./styles.module.scss";
import { store } from "../../../../reducers/Persist";

const ProfileSection = () => {
  const doctorName = store?.getState()?.userReducer?.doctor_name || "";
  return (
    <div className={styles["profile-section"]}>
      <div className={styles["doctor-name"]}>{doctorName}</div>
    </div>
  );
};

export default ProfileSection;

const CONSTANTS = {
  STATUS_TABLE: {
    TITLE: "Doctor Status View",
    PAGE_SIZE: 1
  },
  UPDATE_TABLE: {
    TITLE: "Last status updated",
    PAGE_SIZE: 80
  },
  BRANDS: [
    { label: "All", value: "all" },
    { label: "Man Matters", value: "MM" },
    { label: "Bodywise", value: "BW" },
    { label: "Little Joys", value: "LJ" }
  ]
};

export default CONSTANTS;

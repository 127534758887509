import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import moment from "moment";
import { Button } from "react-bootstrap";
import Axios from "axios";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4";
import COLUMNS from "./columns";
import CONSTANTS from "./constants";
import EventDataDisplay from "./EventDataDisplay";
import { toast } from "react-toastify";

const AuditTrail = () => {
  const [fromDate, setFromDate] = useState(
    moment().format(CONSTANTS?.DEFAULT_DATE_FORMAT)
  );
  const [toDate, setToDate] = useState(
    moment().format(CONSTANTS?.DEFAULT_DATE_FORMAT)
  );
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getParsedEventData = data => {
    const parsedData = JSON.parse(data ?? {});
    if (!(parsedData && Object.keys(parsedData).length > 0)) return "";
    const keys = Object.keys(parsedData);
    return keys.map(key => `${key}: ${parsedData[key]}`).join(", ");
  };
  const getEventTitle = eventName => {
    if (!eventName) return "";
    return eventName.toUpperCase().replaceAll("_", " ");
  };
  const prepareRows = data => {
    const rows = [];
    for (const row of data) {
      const { eventName, eventData, brand, source, appointmentId, createdAt } =
        row;
      const eventDataString = getParsedEventData(eventData);
      const finalRow = {
        ...row,
        eventName: getEventTitle(eventName),
        eventData: eventDataString,
        appointmentReference: appointmentId
          ? `${brand}-${source}-${appointmentId}`
          : CONSTANTS?.EMPTY_DATA_STRING,
        createdAt: moment(createdAt)
          .add(330, "minutes")
          .format(CONSTANTS?.DEFAULT_DATE_TIME_FORMAT)
      };
      rows.push(finalRow);
    }
    setRows(rows);
  };
  const getAuditTrail = async () => {
    try {
      setIsLoading(true);
      const requestBody = { fromDate, toDate };
      if (
        moment(toDate, "YYYY-MM-DD").diff(
          moment(fromDate, "YYYY-MM-DD"),
          "days"
        ) > 7
      ) {
        toast.error("Date range should not be more than 7 days");
        return;
      }
      const response = await Axios.post(
        CONFIG_CONSTANT.url.GET_AUDIT_LOGS,
        requestBody
      );
      prepareRows(response?.data?.data?.auditLogs);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || err?.message || "Something went wrong",
        {
          position: "top-right"
        }
      );
      console.log("ERROR IN GET AUDIT TRAIL", err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAuditTrail();
  }, []);

  return (
    <div className={styles["audit-trail"]}>
      <div className={styles["date-group-wrapper"]}>
        <input
          type="date"
          className={styles["date-input"]}
          value={fromDate}
          onChange={evt => setFromDate(evt.target.value)}
        />
        <input
          type="date"
          className={styles["date-input"]}
          value={toDate}
          onChange={evt => setToDate(evt.target.value)}
        />
        <Button variant="primary" onClick={getAuditTrail}>
          {CONSTANTS?.SEARCH_BUTTON_TITLE}
        </Button>
      </div>
      <div className={`${styles["content-wrapper"]} audit-trail-content`}>
        <Table4
          cols={COLUMNS}
          rows={rows}
          title={CONSTANTS?.TABLE_TITLE}
          pageSize={CONSTANTS?.TABLE_PAGE_SIZE}
          search={true}
          filtering={true}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default AuditTrail;

import styled from "styled-components";

export const Button = styled.button`
  border: 2px solid rgba(42, 75, 161, 1);
  border-radius: 16px;
  background-color: transparent;
  color: rgba(42, 75, 161, 1);
  padding: 8px 15px;
  display: flex;
  column-gap: 11px;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(42, 75, 161, 1);
    color: white;
  }
  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 25px;
`;

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Loader from "react-loader-spinner";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import { toast } from "react-toastify";

function AppointmentTrail(props) {
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [showLoader, setShowLoader] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [tables, setTables] = useState([]);

  const prepareTitle = key => {
    if (!(key && key.length)) return "";
    let title = key.replace(/([A-Z])/g, " $1").trim();
    title = title.charAt(0).toUpperCase() + title.slice(1);
    return title;
  };
  const prepareColumns = obj => {
    if (!obj) return [];
    const keys = Object.keys(obj);
    const columns = keys.map(key => {
      const title = prepareTitle(key);
      return {
        title,
        field: key,
        cellStyle: { minWidth: "200px", maxWidth: "300px" }
      };
    });
    return columns;
  };
  const searchAppointment = async () => {
    setShowLoader(true);
    await axios
      .post(CONFIG_CONSTANT.url.SEARCH_APPOINTMENT, { appointmentId })
      .then(res => {
        if (res.data.success) {
          const result = res.data.result;
          const tables = [];
          for (const key in result) {
            const rows = [result[key]];
            const columns = prepareColumns(result[key]);
            tables.push({ title: prepareTitle(key), rows, columns });
          }
          setTables(tables);
        } else {
          toast.error(res.data.message);
        }
        setShowLoader(false);
      })
      .catch(err => {
        setShowLoader(false);
        console.log("ERROR WHILE SEARCH APPOINTMENT", err);
      });
  };
  return (
    <div
      className="appointment-trail"
      style={{ maxWidth: "calc(100vw - 270px)" }}
    >
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={startDate}
          onChange={evt => setStartDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={endDate}
          onChange={evt => setEndDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <Button variant="success" onClick={evt => {}}>
          Export
        </Button>
      </div>
      <div style={{ display: "flex", padding: "0px 15px" }}>
        <input
          className="form-control"
          type="number"
          placeholder="Appointment Id"
          value={appointmentId}
          onChange={evt => setAppointmentId(evt.target.value)}
          style={{ width: "280px", marginRight: "10px" }}
        />
        <Button
          variant="primary"
          onClick={searchAppointment}
          disabled={!appointmentId}
        >
          Search
        </Button>
      </div>

      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <div>
          {tables &&
            tables.length > 0 &&
            tables.map(table => {
              return (
                <Table4
                  cols={table.columns}
                  rows={table.rows}
                  title={table.title}
                  pagin={false}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}
export default AppointmentTrail;

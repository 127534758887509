import styled from "styled-components";

const LEFT_ARROW_URL =
  "https://ik.manmatters.com/mosaic-wellness/image/upload/v1653990781/dochub/rx-writer/Rectangle_4811591arrow.png";

export const MenuContainer = styled.div`
  background-color: white;
  margin-right: 10px;
  position: relative;
`;

export const Menu = styled.div`
  background-color: white;
  position: relative;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &[data-collapse="true"] {
    width: 65px;
    overflow: hidden;
    transition: width 0.1s ease-in-out;

    @media screen and (max-width: 1024px) {
      width: 50px;
    }
  }

  &[data-collapse="false"] {
    width: 202px;
    transition: width 0.1s ease-in-out;
    @media screen and (max-width: 1024px) {
      width: 125px;
    }
  }
`;

export const LeftArrow = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  right: -18px;
  height: 30px;
  width: 36px;
  background-color: red;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
  border-radius: 4px;
  background-image: url(${LEFT_ARROW_URL});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 30px;
    height: 24px;
    right: -18px;
  }

  &[data-collapse="true"] {
    transform: rotate(180deg);
  }
`;

export const MenuItemsContainer = styled.div`
  position: relative;
  top: 35px;
  margin: 12px;

  @media screen and (max-width: 1024px) {
    margin: 2px;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 15px 12px;
  border-radius: 8px;
  opacity: 0.8;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    column-gap: 5px;
    padding: 8px 12px;
  }

  svg {
    width: 18px;
    height: 18px;

    @media screen and (max-width: 1024px) {
      width: 12px;
      height: 12px;
    }
  }
  span {
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }

  &[data-selected="true"] {
    opacity: 1;
    background-color: #ebf7ff;
    color: rgba(34, 84, 138, 1);
  }

  &[data-collapse="true"] {
    column-gap: 0;
    padding: 8px;
    margin-top: 13px;
    transition: all 0.2s ease-in-out;
    span {
      display: none;
    }

    svg {
      width: 24px;
      height: 24px;

      @media screen and (max-width: 1024px) {
        width: 15px;
        height: 15px;
      }
    }
  }

  &[data-collapse="false"] {
    transition: all 0.2s ease-in-out;
    span {
      display: block;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
`;

export const ButtonItemsContainer = styled.div`
  width: calc(100% - 24px);
  margin-bottom: 54px;
  padding-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ButtonItemWrapper = styled.div`
  cursor: pointer;
  color: ${props => props.primaryColor ?? "black"};
  border: 1px solid ${props => props.primaryColor ?? "black"};
  background-color: ${props => props.secondaryColor ?? "white"};
  border-radius: 8px;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  column-gap: 12px;
  min-width: 175px;

  svg {
    width: 18px;
    height: 18px;
  }

  @media screen and (max-width: 1024px) {
    svg {
      width: 10px;
      height: 10px;
    }

    padding: 5px 8px;
    font-size: 13px;
  }
`;

export const MenuFixedElement = styled.div`
  background-color: white;
  position: fixed;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &[data-collapse="true"] {
    width: 65px;
    overflow: hidden;
    transition: width 0.1s ease-in-out;

    @media screen and (max-width: 1024px) {
      width: 50px;
    }
  }

  &[data-collapse="false"] {
    width: 202px;
    transition: width 0.1s ease-in-out;

    @media screen and (max-width: 1024px) {
      width: 125px;
    }
  }
`;

import React from "react";
import "../styles.scss";

export default class HeaderBottomCardMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCard: false
    };
  }

  render() {
    //  -1 0 1
    // -1 : all items empty
    // 0 : atleast one item is filled
    // 1 : all items are filled
    return (
      <div
        className="headerBottom__container_indicator"
        onMouseEnter={() => this.setState({ ...this.state, showCard: true })}
        onMouseLeave={() => this.setState({ ...this.state, showCard: false })}
        style={{
          background: `${
            this.props.cardContent.checked === 1
              ? "#379F8E"
              : this.props.cardContent.checked === -1
              ? "#ebebeb"
              : "#808080"
          }`
        }}
      >
        {this.state.showCard && (
          <div className="headerBottom__container_card">
            <div
              className="headerBottom__container_card-title"
              style={{
                background: `${
                  this.props.cardContent.checked === 1
                    ? "#379F8E"
                    : this.props.cardContent.checked === -1
                    ? "#ebebeb"
                    : "#808080"
                }`,
                color: `${
                  this.props.cardContent.checked !== -1 ? "#fff" : "#000"
                }`
              }}
            >
              {this.props.cardContent.title}
            </div>
            {this.props.cardContent.checks.map((val, i) => (
              <div className="headerBottom__container_card-body" key={i}>
                <p
                  style={{
                    color: `${val.filled ? "#379f8e" : "#D9D9D9"}`
                  }}
                >
                  {val.name}
                </p>
                <div
                  className="headerBottom__container_card_dot"
                  style={{
                    background: `${val.filled ? "#379F8E" : "#D9D9D9"}`,
                    border: `1px solid ${val.filled ? "#379f8e" : "#EBEBEB"}`
                  }}
                ></div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

import React, { useState } from "react";

import MealDetails from "./../../core/MealDetails/MealDetails";
export default function MealPlanner1(props) {
  return (
    <MealDetails
      docKey={props.docKey}
      userId={props.userId}
      category={props.category}
      updateData={props.updateData}
      patientDetails={props.patientDetails}
      userInfo={props.userInfo}
      form_details={props.form_details}
      loggedInDr={props.loggedInDr}
      latestStatusUpdate={props.latestStatusUpdate}
      doctor_id={props.doctor_id}
      categories={props.categories}
      brand={props.brand}
      copyPrescription={props.copyPrescription}
    />
  );
}

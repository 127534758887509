import React, { Component } from "react";
import "./PatientHistory.scss";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import Loader from "react-loader-spinner";
import axios from "axios";
import CONFIG_CONSTANTS from "./../../../constants/apiList";
import moment from "moment";
import { Button } from "react-bootstrap";
import { alertBox } from "./../../common/PopupBox/PopupBox";
import { store } from "../../../reducers/Persist";
import Iframe from "react-iframe";
import { Modal } from "react-bootstrap";

export default class PatientHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHistory: false,
      showLoader: false,
      history: [],
      pdf: "",
      showPdfModal: false
    };
  }
  orderPlacedShowClickHandler = (index, orderDetailsRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    const tempHistory = [...this.state.history];
    const tempItem = { ...item };
    tempItem.showing = true;
    tempHistory.splice(index, 1, tempItem);
    this.setState({ history: tempHistory });
    axios
      .post(CONFIG_CONSTANTS.url.ORDER_DETAILS_URL, orderDetailsRequestData)
      .then(response => {
        console.log("ORDER DETAILS RES", response.data);
        if (!response.data) {
          alertBox("cannot retrieve data");
          return;
        }
        const recommended = [];
        response.data.body.data.product_kits.map((prod, index) => {
          prod.product_items.map((singleItem, index) => {
            recommended.push(singleItem.name);
          });
        });
        response.data.body.data.product_items.map((prod, index) => {
          recommended.push(prod.name);
        });
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.showing = false;
        tempItem.recommendedProducts = recommended;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("OrderDetails API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.showing = false;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  formFilledShowClickHandler = (index, prescriptionRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    axios
      .post(CONFIG_CONSTANTS.url.PRESCRIPTION_URL, prescriptionRequestData)
      .then(response => {
        console.log("PRESCRIPTION RES", response.data);
        if (!response.data) {
          alertBox("cannot retrieve data");
          return;
        }
        const recommended = [];
        response.data.body.data.product_kits.map((prod, index) => {
          prod.product_items.map((singleItem, index) => {
            recommended.push(singleItem.name);
          });
        });
        response.data.body.data.product_items.map((prod, index) => {
          recommended.push(prod.name);
        });
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.recommendedProducts = recommended;
        tempItem.form_details = response.data.body.data.form_details;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("PRESCRIPTION API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  formSubmittedShowClickHandler = (index, formSubmitRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    axios
      .post(CONFIG_CONSTANTS.url.FORM_SUBMIT_EVENT_URL, formSubmitRequestData)
      .then(response => {
        console.log("FORM SUBMIT RES", response.data);
        if (!response.data) {
          alertBox("cannot retrieve data");
          return;
        }
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.form_details = response.data.form_details;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("FormSubmit API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  prescriptionGeneratedShowClickHandler = (e, getPdfRequestData) => {
    axios
      .post(CONFIG_CONSTANTS.url.GET_PDF_URL, getPdfRequestData)
      .then(response => {
        this.setState({ pdf: response.data.body.data, showPdfModal: true });
      })
      .catch(err => {
        console.log("IN GET PDF ERROR", err);
        alertBox("Unable to show Prescription. Try again Later");
      });
  };
  renderOrderPlacedCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    let orderDetailsRequestData = {
      form: item.category ? item.category : "OF",
      order_id: item.form_id ? item.form_id : item.order_id,
      event_type: item.event_type,
      brand: this.props.brand
    };
    return (
      <div className="history-card order-placed">
        <div className="history-card-title">
          {this.getDisplayTitle(item.event_type)} {item.order_id}
          <Button
            variant="info"
            size="sm"
            onClick={evt =>
              this.orderPlacedShowClickHandler(index, orderDetailsRequestData)
            }
          >
            {item.showProducts ? (
              "Hide"
            ) : item.showing ? (
              <Loader type="TailSpin" color="#00BFFF" height={20} width={20} />
            ) : (
              "Show"
            )}
          </Button>
        </div>
        <div className="history-date-container">{createdDate}</div>
        <div className="history-order-status">
          Order Status:{" "}
          <span className="highlight">
            {this.getDisplayTitle(item.order_status)}
          </span>
        </div>
        {item.type === "RX" ? (
          <div>
            Appointment Schedule:{" "}
            <span className="highlight">{item.appointment_schedule}</span>
          </div>
        ) : null}
        <div>
          <span className="highlight">{this.props.userInfo.data.name}</span>{" "}
          ordered on <span className="highlight">{createdDate}</span>
        </div>
        {item.type === "RX" && item.doctor_assigned ? (
          <div>
            The consultation was assigned to{" "}
            <span className="highlight">{item.doctor_assigned}</span>
          </div>
        ) : null}
        {item.showProducts ? (
          <>
            <div style={{ marginTop: "10px" }}>
              <span className="highlight">Recommended Products:</span>
            </div>
            <div>
              {item.recommendedProducts && item.recommendedProducts.length > 0
                ? item.recommendedProducts &&
                  item.recommendedProducts.join(", ")
                : "No Product to show."}
            </div>
          </>
        ) : null}
      </div>
    );
  };
  renderQNA = form_details => {
    let data = {
      Hair: form_details.Hair,
      Weight: form_details.Weight,
      Skin: form_details.Skin,
      Performance: form_details.Performance,
      Fitness: form_details.Fitness,
      Nutrition: form_details.Nutrition,
      Stress: form_details.Stress,
      Sleep: form_details.Sleep,
      Lifestyle: form_details.Lifestyle,
      Wellness: form_details.Wellness,
      Health: form_details.Health
    };
    let renderView = [];
    for (let [key, value] of Object.entries(data)) {
      if (value && Object.keys(value).length > 0) {
        let qnaHead = (
          <div>
            <u>
              <b>{key}</b>
            </u>
          </div>
        );
        renderView.push(qnaHead);
        for (let [key2, value2] of Object.entries(value)) {
          if (Object.keys(value).length > 0) {
            let qnaView = (
              <div>
                <div>Q :{key2}</div>
                <div>
                  <span className="ans">A : </span> {value2}
                </div>
                <br />
              </div>
            );
            renderView.push(qnaView);
          }
        }
      }
    }
    console.log(renderView);
    return renderView;
  };
  renderFormFilledCard = (item, index) => {
    let prescriptionRequestData = {
      doctorId: store.getState().userReducer.hash_id,
      form: item.category ? item.category : "OF",
      id: item.form_id ? item.form_id : item.order_id
    };
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className="history-card form-filled">
        <div className="history-card-title">
          {displayTitle}
          <Button
            variant="info"
            size="sm"
            onClick={evt =>
              this.formFilledShowClickHandler(index, prescriptionRequestData)
            }
          >
            {item.showProducts ? "Hide" : "Show"}
          </Button>
        </div>
        <div className="history-date-container">{createdDate}</div>
        <div>
          <span className="highlight">{this.props.userInfo.data.name}</span>{" "}
          filled the {displayTitle.slice(0, displayTitle.indexOf(" "))} Form on{" "}
          <span className="highlight">{createdDate} </span>
          and was recommended.
          <br /> The consultation was assigned to{" "}
          <span className="highlight">{item.doctor_assigned}</span>.
        </div>
        {item.showProducts ? (
          <div>
            <div>
              <span className="highlight">Recommended Products:</span>
            </div>
            {item.recommendedProducts && item.recommendedProducts.length > 0
              ? item.recommendedProducts.join(",")
              : "No Product to show."}
            <div>
              <span className="highlight">QNA</span>
            </div>
            {item.form_details ? (
              <div style={{ maxHeight: "200px", overflow: "auto" }}>
                {this.renderQNA(item.form_details)}
              </div>
            ) : (
              "No QNA available!"
            )}
          </div>
        ) : null}
      </div>
    );
  };
  renderFormSubmittedCard = (item, index) => {
    let formSubmitRequestData = {
      brand: this.props.brand,
      doctorId: store.getState().userReducer.hash_id,
      formID: item.form_id
    };
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className="history-card form-submitted">
        <div className="history-card-title">
          {displayTitle}
          <Button
            variant="info"
            size="sm"
            onClick={evt =>
              this.formSubmittedShowClickHandler(index, formSubmitRequestData)
            }
          >
            {item.showProducts ? "Hide" : "Show"}
          </Button>
        </div>
        <div className="history-date-container">{createdDate}</div>
        <div>
          <span className="highlight">{this.props.userInfo.data.name}</span>{" "}
          filled the Form on <span className="highlight">{createdDate} </span>
        </div>
        {item.showProducts ? (
          <div>
            <div>
              <span className="highlight">QNA</span>
            </div>
            {item.form_details ? (
              <div style={{ maxHeight: "200px", overflow: "auto" }}>
                {this.renderQNA(item.form_details)}
              </div>
            ) : (
              "No QNA available!"
            )}
          </div>
        ) : null}
      </div>
    );
  };
  renderAppointmentRescheduleCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className="history-card appointment-rescheduled">
        <div className="history-card-title">{displayTitle}</div>
        <div className="history-date-container">{createdDate}</div>
        <div>
          Rescheduled By:{" "}
          <span className="highlight">
            {this.getDisplayTitle(item.rescheduleBy)}
          </span>
        </div>
        <div>
          New Appointment:{" "}
          <span className="highlight">{item.new_appointment}</span>
        </div>
        <div>
          Previous Appointment:{" "}
          <span className="highlight">{item.previous_appointmnet}</span>
        </div>
      </div>
    );
  };
  renderAppointmentBookedCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);

    return (
      <div className="history-card appointment-booked">
        <div className="history-card-title">{displayTitle}</div>
        <div className="history-date-container">{createdDate}</div>
        <div>
          Appointment Schedule:{" "}
          <span className="highlight">{item.appointment_schedule}</span>
        </div>
      </div>
    );
  };
  renderPrescriptionGeneratedCard = (item, index, showCopyButton = false) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    let getPdfRequestData = {
      doctorId: store.getState().userReducer.hash_id,
      prescriptionId: item.prescription_id,
      brand: this.props.brand
    };

    return (
      <div className="history-card prescription-generated">
        <div className="history-card-title">
          {displayTitle}
          <div>
            <Button
              variant="info"
              size="sm"
              onClick={evt =>
                this.prescriptionGeneratedShowClickHandler(
                  index,
                  getPdfRequestData
                )
              }
            >
              {item.showProducts ? "Hide" : "Show"}
            </Button>
            {showCopyButton && (
              <Button
                variant="dark"
                size="sm"
                onClick={evt =>
                  this.props.copyPrescription(item.prescription_id)
                }
              >
                Copy Prescription
              </Button>
            )}
          </div>
        </div>
        <div className="history-date-container">{createdDate}</div>
        <div>Prescription has been successfully generated.</div>
      </div>
    );
  };
  renderCard = (item, index) => {
    switch (item.event_type) {
      case "order_placed":
        return this.renderOrderPlacedCard(item, index);
      case "subscription_order_placed":
        return this.renderOrderPlacedCard(item, index);
      case "hair_form_filled":
        return this.renderFormFilledCard(item, index);
      case "performance_form_filled":
        return this.renderFormFilledCard(item, index);
      case "form_submmited":
        return this.renderFormSubmittedCard(item, index);
      case "form_submitted":
        return this.renderFormSubmittedCard(item, index);
      case "appointment_rescheduled":
        return this.renderAppointmentRescheduleCard(item, index);
      case "appointment_booked":
        return this.renderAppointmentBookedCard(item, index);
      case "prescription_generated":
        return this.renderPrescriptionGeneratedCard(item, index);
      case "diet_plan_created":
        return this.renderPrescriptionGeneratedCard(
          item,
          index,
          !!this.props.copyPrescription
        );
      default:
        return (
          <div style={{ margin: "2px", border: "1px solid black" }}>
            {this.getDisplayTitle(item.event_type)}
          </div>
        );
    }
  };
  getDisplayTitle = eventType => {
    let words = eventType.split("_");
    let title = "";
    words.forEach((word, index) => {
      if (word && word.length > 0)
        title += word.charAt(0).toUpperCase() + word.slice(1) + " ";
    });
    return title;
  };
  loadHistory = () => {
    if (this.state.showHistory) {
      this.setState({ showHistory: false, showLoader: false });
      return;
    }
    this.setState({ showLoader: true });
    const getHistoryRequestData = {
      brand: this.props.brand,
      doctorId: store.getState().userReducer.hash_id,
      // email:"rajeev@mosaicwellness.in"
      email: this.props.userInfo.data.email
    };
    axios
      .post(CONFIG_CONSTANTS.url.GET_HISTORY, getHistoryRequestData)
      .then(res => {
        console.log(res.data.body.data);
        const sortedEvents = res.data.body.data.sort((a, b) => {
          return new Date(b.created_dttm) - new Date(a.created_dttm);
        });
        this.setState({ showLoader: false, history: sortedEvents });
      })
      .catch(err => {
        console.log("ERROR IN GET HISTORY", err);
        this.setState({ showLoader: false });
      });

    this.setState({ showHistory: true });
  };
  render() {
    return (
      <div className="patient-history">
        <div className="toggle-bar" onClick={this.loadHistory}>
          <span>View History</span>
          {this.state.showHistory ? (
            <span>
              <ArrowUpIcon />
            </span>
          ) : (
            <span>
              <ArrowDownIcon />
            </span>
          )}
        </div>
        <div className="history-container" hidden={!this.state.showHistory}>
          {this.state.showLoader ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#02bfff"
              }}
            >
              <b>L O A D I N G</b>
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={30}
                width={30}
                style={{ margin: "10px 10px" }}
              />
            </div>
          ) : (
            <div className="history-main-container">
              {this.state.history && this.state.history.length > 0 ? (
                this.state.history.map((item, index) => {
                  return this.renderCard(item, index);
                })
              ) : (
                <center>No Record Found!!!</center>
              )}
            </div>
          )}
        </div>
        <div>
          <Modal
            className="xyz"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.showPdfModal}
            onHide={() => this.setState({ pdf: "", showPdfModal: false })}
          >
            <Modal.Body className="mdl-body">
              {!this.state.pdf && (
                <div className="loader-cm">
                  Loading Prescription...Please Wait
                  <div className="Loader-box">
                    <Loader
                      type="TailSpin"
                      color="#00BFFF"
                      height={30}
                      width={30}
                    />
                  </div>
                </div>
              )}
              <Iframe
                url={this.state.pdf}
                width="700px"
                height="700px"
                id="myId"
                className="myClassname"
                display="initial"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.setState({ pdf: "", showPdfModal: false })}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

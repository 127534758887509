import React, { Component } from "react";
import "./Complaints.scss";
import PastMedical from "./PastMedical";
export default class Complaints extends Component {
  render() {
    return (
      <div>
        <div className="title" style={{ marginTop: "40px" }}>
          Past Medical
        </div>
        <div className="margin-30 Complaints">
          {/* <Concerns /> */}

          <div className="Complaints_bottom">
            <div className="Complaints_bottom_left">
              <PastMedical />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { store } from "../reducers/Persist";
const debug = process.env.REACT_APP_ENV !== "production";

export const addToGTM = dataLayerDetails => {
  try {
    if (window?.dataLayer) {
      const loginDetails = store?.getState()?.userReducer || {};
      const eventData = {
        login: loginDetails,
        ...dataLayerDetails
      };
      if (debug) console.log("DATALAYER PUSH |", eventData);
      window.dataLayer.push(eventData);
    } else {
      console.warn("window.dataLayer not found!!!");
    }
  } catch (err) {
    console.error("ERROR IN addToGTM", err);
  }
};

import React from "react";
import dataResource from "../../../constants/dataResource";
import {
  LegendsContainer,
  LegendBody,
  LegendColor,
  LegendTitle
} from "./styles/legends.styles";
import { getStatusColor } from "./helper/status-colors";

const doctorStatuses = dataResource.DOCTOR_STATUS_LIST;

export default function DoctorStatusLegends() {
  return (
    <LegendsContainer>
      {Object.entries(doctorStatuses).map(([statusName, status], index) => {
        return (
          <LegendBody key={index}>
            <LegendColor color={getStatusColor(status)} />
            <LegendTitle>
              {statusName
                .split("_")
                .map(x => x[0].toUpperCase() + x.slice(1).toLowerCase())
                .join(" ")}
            </LegendTitle>
          </LegendBody>
        );
      })}
    </LegendsContainer>
  );
}

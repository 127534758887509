import React, { useContext, useEffect, useState } from "react";
import { dateContext } from "./AdminPage2";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../../reducers/Persist";

import { changeDate } from "../../../reducers/appointmentReducer";
import { Button, IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Axios from "axios";
import CONFIG_CONSTANTS from "../../../constants/apiList";

const AdminNav = () => {
  return (
    <div className="admin-nav-container">
      <DateGroup />
      <Refresh />
      <Logout />
    </div>
  );
};

export const Refresh = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch({
      type: "REFRESH"
    });
  };
  return (
    <div>
      <Button variant="outlined" onClick={handleClick} color="secondary">
        Refresh
      </Button>
    </div>
  );
};

export const Logout = () => {
  const handleClick = async () => {
    try {
      localStorage.removeItem("jwt");
      await Axios.get(CONFIG_CONSTANTS.AUTH.LOGOUT, { withCredentials: true });
      store.dispatch({ type: "CLEAR" });
    } catch (err) {
      console.log("ERROR IN LOGOUT", err);
    }
    window.location.href = "/login";
  };
  return (
    <div>
      <Button variant="contained" onClick={handleClick} disableElevation>
        Logout
      </Button>
    </div>
  );
};

export const DateGroup = () => {
  const startDate = useSelector(state => state.appointmentReducer.start);
  const endDate = useSelector(state => state.appointmentReducer.end);
  const dispatch = useDispatch();

  const [dates, setDates] = useState({
    start: startDate,
    end: endDate
  });

  const handleChange = (e, type) => {
    if (type === "start") {
      setDates({ ...dates, start: e.target.value });
    }
    if (type === "end") {
      setDates({ ...dates, end: e.target.value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch({
      type: "DATES",
      data: {
        start: dates.start,
        end: dates.end
      }
    });
  };

  return (
    <div className="dategroup">
      <form className="form">
        <input
          type="date"
          className="input"
          value={dates.start}
          onChange={e => handleChange(e, "start")}
        />
        <input
          type="date"
          className="input"
          value={dates.end}
          onChange={e => handleChange(e, "end")}
        />
        <IconButton color="primary" aria-label="Go" onClick={handleSubmit}>
          <ArrowForwardIcon />
        </IconButton>
      </form>
    </div>
  );
};
export const DateGroup2 = () => {
  const { date, setDate } = useContext(dateContext);

  const [dates, setDates] = useState({
    start: date.startDate,
    end: date.endDate
  });

  const handleChange = (e, type) => {
    if (type === "start") {
      setDates({ ...dates, start: e.target.value });
    }
    if (type === "end") {
      setDates({ ...dates, end: e.target.value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDate({ startDate: dates.start, endDate: dates.end });
  };

  return (
    <div className="dategroup">
      <form className="form">
        <input
          type="date"
          className="input"
          value={dates.start}
          onChange={e => handleChange(e, "start")}
        />
        <input
          type="date"
          className="input"
          value={dates.end}
          onChange={e => handleChange(e, "end")}
        />
        <IconButton color="primary" aria-label="Go" onClick={handleSubmit}>
          <ArrowForwardIcon />
        </IconButton>
      </form>
    </div>
  );
};

export default AdminNav;

import React, { Component } from "react";
import PatientDetails from "./PatientDetails";
import AppointmentDetails from "./AppointmentDetails";
import Timeline from "./Timeline";
import SelfAssessmentForm from "./SelfAssesmentForm";
import Confirmation from "./Confirmation";
import { connect } from "react-redux";
import "./styles.scss";
import { store } from "../../../reducers/Persist";
class PatientFile extends Component {
  render() {
    return (
      <div>
        <PatientDetails />
        <div className="PatientFileRow">
          <div className=" PatientFileCol1">
            <AppointmentDetails
              showReassignModal={this.props.showReassignModal}
            />
            <Timeline />
          </div>
          <div className="PatientFileCol2">
            <SelfAssessmentForm />
          </div>
        </div>
        <div className="center">
          <Confirmation />
        </div>
      </div>
    );
  }
}

export default PatientFile;

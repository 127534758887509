import React from "react";
import "./header-text.scss";
import { Link, Redirect } from "react-router-dom";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants";

const HeaderText = () => {
  const refreshPage = () => {
    window.location.reload(false);
  };

  const onRaiseTicket = () => {
    addToGTM({
      event: GTM_EVENTS?.RAISE_TICKET_CLICKED
    });
  };

  return (
    <div className="header-text__wrapper">
      <div className="header-text" onClick={refreshPage}>
        Refresh
      </div>
      <div className="header-text">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdmxl40omMVVUv0pRYJxLO3ICViCh5E-W-u-jppN5PMF1jnhw/viewform"
          target="_blank"
          onClick={onRaiseTicket}
        >
          Raise Ticket
        </a>
      </div>
      <Link
        to={{ pathname: "/dashboard/appointments/summary" }}
        className="header-text"
      >
        View Schedule
      </Link>
      {/* <div className="header-text">Next Patient</div> */}
    </div>
  );
};

export default HeaderText;

import React, { useContext } from "react";
import { GlobalDataContext } from "./MealPlanner2";
import Select from "react-dropdown-select";

const mealType = [
  { name: "Early Morning" },
  { name: "Breakfast" },
  { name: "Mid Meal" },
  { name: "Lunch" },
  { name: "Snacks" },
  { name: "Dinner" },
  { name: "Mid Meal 2" },
  { name: "Snacks 2" },
  { name: "Pre-Workout" },
  { name: "Post-Workout" },
  { name: "Intra-Workout" },
  { name: "Bed-Time" }
];
const mealTypeMap = {
  "Early Morning": {
    key: 1,
    dafaultTime: "07:00"
  },
  Breakfast: {
    key: 2,
    dafaultTime: "09:00"
  },
  "Mid Meal": {
    key: 3,
    dafaultTime: "11:00"
  },
  Lunch: {
    key: 4,
    dafaultTime: "13:00"
  },
  Snacks: {
    key: 5,
    dafaultTime: "15:00"
  },
  Dinner: {
    key: 6,
    dafaultTime: "20:00"
  },
  "Mid Meal 2": {
    key: 7,
    dafaultTime: "11:00"
  },
  "Snacks 2": {
    key: 8,
    dafaultTime: "15:00"
  },
  "Pre-Workout": {
    key: 9,
    dafaultTime: "15:00"
  },
  "Post-Workout": {
    key: 10,
    dafaultTime: "15:00"
  },
  "Intra-Workout": {
    key: 11,
    dafaultTime: "15:00"
  },
  "Bed-Time": {
    key: 12,
    dafaultTime: "21:00"
  }
};

export default function MealTypeRow({ defaultMealType, ind }) {
  const { tableState, setTableState } = useContext(GlobalDataContext);
  return (
    <div style={{ display: "flex", borderBottom: "1px solid #EEF1EF" }}>
      <div
        style={{
          backgroundColor: "#EEF1EF",
          padding: "12px",
          margin: "10px 5px 10px 10px",
          fontWeight: "400",
          borderRadius: "4px"
        }}
      >
        <Select
          options={mealType}
          values={[{ name: defaultMealType }]}
          dropdownPosition="auto"
          labelField="name"
          valueField="name"
          style={{ backgroundColor: "white" }}
          onChange={value => {
            if (!value[0] || !value[0].name) return;

            setTableState({
              ...tableState,
              "Meal Type": [
                ...tableState["Meal Type"].slice(0, ind),
                value[0].name,
                ...tableState["Meal Type"].slice(ind + 1)
              ]
            });
          }}
        />
        <div
          style={{
            cursor: "pointer",
            color: "white",
            width: "160px",
            backgroundColor: "#ff8c66",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "8px",
            padding: "8px"
          }}
          onClick={() => {
            const oldState = tableState;
            for (let key in oldState) {
              oldState[key].splice(ind, 1);
            }
            setTableState({
              "Meal Type": [...oldState["Meal Type"]],
              Monday: [...oldState["Monday"]],
              Tuesday: [...oldState["Tuesday"]],
              Wednesday: [...oldState["Wednesday"]],
              Thursday: [...oldState["Thursday"]],
              Friday: [...oldState["Friday"]],
              Saturday: [...oldState["Saturday"]],
              Sunday: [...oldState["Sunday"]]
            });
          }}
        >
          Delete This Meal
        </div>
      </div>
    </div>
  );
}

import clevertap from "clevertap-web-sdk";
import Axios from "axios";
import dataResource from "../constants/dataResource";
import config from "../constants/apiList";
import { ColorLensOutlined } from "@material-ui/icons";
import { Howl } from "howler";
import sound1 from "../static/notification.mp3";

export const rxOpenedClevertap = payload => {
  try {
    Axios.post(config.url.PRESCRIPTION_URL, payload).then(res => {
      clevertap.event.push("Rx Opened", {
        Source: payload?.source,
        Category: res?.data?.body?.data?.category_label,
        Appointments: payload?.id,
        "Appointment Status":
          dataResource?.statusListObj[res?.data?.body?.data?.status],
        "Call Type":
          res?.data?.body?.data?.mode === "bw_video"
            ? "Video call"
            : "Voice Call",
        Brand: payload?.brand,
        Language: res?.data?.body?.data?.language
      });
    });
  } catch (err) {
    console.log(err);
  }
};
export const playNotification = () => {
  const sound = new Howl({
    src: [sound1],
    volume: 0.5,
    preload: true,
    html5: true
  });
  sound.play();
};
export const showNotification = value => {
  function triggerNotification(para) {
    const brand = para.brand;
    const title = para.title;
    const body = para.body;
    const data = para.data;
    const secTimeout = para.secTimeout;
    let otitle, favicon, obody, odata, osecTimeout;
    if (brand === dataResource?.BRANDS_SHORT_NAME?.MM) {
      favicon = dataResource?.BRANDS_NOTIFICATION?.MM?.FAVICON;
      otitle = title ? title : dataResource?.BRANDS_NOTIFICATION?.MM?.TITLE;
      obody = body ? body : dataResource?.BRANDS_NOTIFICATION?.MM?.BODY;
      odata = data ? data : dataResource?.BRANDS_NOTIFICATION?.MM?.DATA;
      osecTimeout = secTimeout
        ? secTimeout
        : dataResource?.BRANDS_NOTIFICATION?.MM?.TIMEOUT;
    } else if (brand === dataResource?.BRANDS_SHORT_NAME?.BW) {
      favicon = dataResource?.BRANDS_NOTIFICATION?.BW?.FAVICON;
      otitle = title ? title : dataResource?.BRANDS_NOTIFICATION?.BW?.TITLE;
      obody = body ? body : dataResource?.BRANDS_NOTIFICATION?.BW?.BODY;
      odata = data ? data : dataResource?.BRANDS_NOTIFICATION?.BW?.DATA;
      osecTimeout = secTimeout
        ? secTimeout
        : dataResource?.BRANDS_NOTIFICATION?.BW?.TIMEOUT;
    }
    const notification = new Notification(otitle, {
      body: obody,
      icon: favicon,
      data: odata,
      dir: "auto"
    });
    notification.onclick = e => {
      e.preventDefault();
      window.open(para.link, "_blank");
    };
    setTimeout(notification.close.bind(notification), osecTimeout);
  }
  if (Notification.permission === "granted") {
    triggerNotification(value);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        triggerNotification(value);
      }
    });
  }
};

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import axios from "axios";
import React, { useState } from "react";
import { MdVisibility } from "react-icons/md";
import Loader from "react-loader-spinner";
import config from "../../../../constants/apiList";
import dataResource from "../../../../constants/dataResource";
import { getRxWriterLink } from "../../../../helpers/rx-writer.helpers";
import AskImage from "./AppointmentSummary/common/AskImage";

export const newDashboardToday = [
  {
    title: "Appointments",
    field: "appointments"
  },
  {
    title: "Completed",
    field: "completed"
  },
  {
    title: "Rx Generated",
    field: "rx_generated"
  },
  {
    title: "On Time%",
    render: rowData => <div>{rowData.on_time_percent} %</div>
  },
  {
    title: "Completion%",
    render: rowData => <div>{rowData.completion_percent} %</div>
  },
  {
    title: "Scheduled",
    field: "scheduled"
  }
];
export const newDashboardPending = [
  {
    title: "Appointments",
    field: "appointments"
  },
  {
    title: "Completed",
    field: "completed"
  },
  {
    title: "Rx Generated",
    field: "rx_generated"
  }
];
export const newDashboardSummary = [
  {
    title: "Connected",
    field: "connected"
  },
  {
    title: "Completed",
    field: "completed"
  },
  {
    title: "Rx Generated",
    field: "rx_generated"
  },
  {
    title: "PCI",
    field: "pci"
  }
];

export const appointmentsTodaySummary = [
  {
    title: "Connected",
    field: "connected"
  },
  {
    title: "Completed",
    field: "completed"
  },
  {
    title: "RxGenerated",
    field: "rx_generated"
  },
  {
    title: "On Time%",
    render: rowData => (
      <div style={{ padding: "10px" }}>{rowData.on_time_percent} %</div>
    )
  },
  {
    title: "Completion%",
    field: "completion"
  },
  {
    title: "Scheduled",
    field: "scheduled"
  },
  {
    title: "PCI",
    field: "pci"
  }
];
export const appointmentsPriorityTable = [
  {
    title: "Rx",
    render: rowData => {
      return (
        <div
          onClick={e => {
            let param = rowData.brand + "-" + rowData.source + "-" + rowData.id;
            const oldCategories = ["SH", "HC", "OF"];
            if (oldCategories.includes(rowData.source)) {
              param = rowData.source_id;
            }
            window.open(getRxWriterLink(param), "_self");
          }}
          style={{
            cursor: "pointer",
            padding: "10px 0",
            title: "Open Prescription"
          }}
        >
          <MdVisibility />
        </div>
      );
    }
  },
  {
    title: "Status",
    render: rowData => <div>{dataResource.statusListObj[rowData.status]}</div>
  },
  {
    title: "Confirmed",
    render: rowData => <div>{rowData.confirmed ? "Yes" : "-"}</div>
  },
  {
    title: "Patient ID",
    render: rowData => (
      <div style={{ whiteSpace: "nowrap" }}>
        {rowData.brand + "-" + rowData.source + "-" + rowData.id}
      </div>
    )
  },
  {
    title: "Date",
    render: rowData => {
      return (
        <div style={{ whiteSpace: "nowrap" }}>{rowData.appointment_date}</div>
      );
    }
  },
  {
    title: "Start Time",
    field: "from_time"
  },
  {
    title: "Patient Name",
    field: "patient_name",
    render: rowData => {
      return <div style={{ whiteSpace: "nowrap" }}>{rowData.patient_name}</div>;
    }
  },
  {
    title: "Concern",
    render: rowData => {
      return (
        <div>
          {rowData.category &&
            rowData.category
              .split(",")
              .map(c => dataResource.categoryMap[c])
              .join(", ")}
        </div>
      );
    }
  },
  {
    title: "Ask for Image",
    render: rowData => <AskImage rowData={rowData} />
  },
  {
    title: "Callback Time",
    field: "request_callback_time"
  },
  {
    title: "Attempts",
    field: "no_of_attempts"
  }
];

const defaultStyling = {
  fontFamily: "Sofia Pro",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "13px",
  display: "flex",
  alignItems: "center"
};

export const categoryStyleMap = {
  skin: {
    color: "#9A521C",
    backgroundColor: "rgba(254, 243, 234, 0.6)"
  },
  hair: {
    color: "#1C3D74",
    backgroundColor: "rgba(81, 148, 209, 0.15)"
  }
};

export const ivrStatusStyle = {
  null: {
    color: "#000",
    fontWeight: "normal",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    border: "1px solid black"
  },
  confirmed: {
    color: "#379f8e",
    fontWeight: "normal",
    backgroundColor: "#379f8e0f"
  },
  cancelled: {
    color: "#E86669",
    fontWeight: "normal",
    backgroundColor: "#E866690f"
  }
};

export const appointmentsTodayTable = [
  {
    title: "View Rx",
    render: rowData => {
      return (
        <div
          style={{
            cursor: "pointer",
            title: "Open Prescription",
            boxSizing: "border-box",
            width: "30px",
            height: "30px",
            background: "#FFFFFF",
            border: "0.5px solid #C3E0FB",
            borderRadius: "9px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            onClick={e => {
              let param =
                rowData.brand + "-" + rowData.source + "-" + rowData.id;
              const oldCategories = ["SH", "HC", "OF"];
              if (oldCategories.includes(rowData.source)) {
                param = rowData.source_id;
              }
              window.open(getRxWriterLink(param), "_self");
            }}
            style={{
              cursor: "pointer",
              title: "Open Prescription",
              color: "#5194D1"
            }}
          >
            <MdVisibility />
          </div>
        </div>
      );
    }
  },
  {
    title: "Status",
    render: rowData => (
      <div style={defaultStyling}>
        {dataResource.statusListObj[rowData.status]}
      </div>
    )
  },
  {
    title: "Patient ID",
    render: rowData => (
      <div
        style={{
          ...defaultStyling,
          whiteSpace: "nowrap"
        }}
      >
        {rowData.brand + "-" + rowData.source + "-" + rowData.id}
      </div>
    )
  },
  {
    title: "Date",
    render: rowData => {
      return (
        <div
          style={{
            ...defaultStyling,
            whiteSpace: "nowrap"
          }}
        >
          {rowData.appointment_date}
        </div>
      );
    }
  },
  {
    title: "Patient Name",
    field: "patient_name",
    render: rowData => {
      return (
        <div
          style={{
            ...defaultStyling,
            whiteSpace: "nowrap"
          }}
        >
          {rowData.patient_name}
        </div>
      );
    }
  },
  {
    title: "Category",
    render: rowData => {
      return (
        <div
          style={{
            ...defaultStyling
          }}
        >
          {rowData.category.split(",").map((category, index) => {
            return (
              <Chip
                style={{
                  color: "#9A521C",
                  backgroundColor: "rgba(254, 243, 234, 0.6)",
                  border: "1px solid #9A521C",
                  margin: "0px 1px"
                }}
                label={dataResource.categoryMap[category]}
                key={index}
              />
            );
          })}
        </div>
      );
    }
  },
  {
    title: "Ask for Image",
    render: rowData => <AskImage rowData={rowData} />
  },
  {
    title: "Assignment Time",
    field: "assignment_time",
    render: rowData => {
      return (
        <div
          style={{
            ...defaultStyling,
            whiteSpace: "nowrap",
            background: "rgba(81, 148, 209, 0.15)",
            borderRadius: "10px",
            color: "#2A73B6",
            padding: "5px 10px",
            minWidth: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "min-content",
            fontSize: "13px"
          }}
        >
          {rowData.assignment_time}
        </div>
      );
    }
  }
];

export const allCallsRows = [
  {
    title: "",
    render: rowData => {
      if (rowData.type === dataResource?.APPOINTMENT_TYPE?.INSTANT)
        return (
          <Chip
            label={"IA"}
            style={{
              opacity: "0.7",
              fontWeight: "500"
            }}
          />
        );
      return (
        <Chip
          label={"IVR"}
          style={
            ivrStatusStyle[
              rowData.confirmed
                ? "confirmed"
                : rowData.cancelled
                ? "cancelled"
                : "null"
            ]
          }
        />
      );
    }
  }
];

import React, { Component } from "react";
import { toast } from "react-toastify";
import "./MealDetails.scss";
// import { Button } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import NumPad from "react-numpad";
import CallIcon from "@material-ui/icons/Call";
import axios from "axios";
import dataResource from "../../../constants/dataResource";
import Timer from "react-compound-timer";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import UtilityService from "../../../services/utilities";
import Scheduler from "./../Scheduler/Scheduler";
import PatientHistory from "../PatientHistory/PatientHistory";
import {
  Button,
  Dropdown,
  DropdownButton,
  Alert,
  Modal,
  Form,
  Table
} from "react-bootstrap";
import { iaEnabled } from "../../../helpers/features.helper";
var statusBox = {};
var statusObj = {};
dataResource.statusList.forEach((obj, index) => {
  statusObj[obj.value] = obj.label;
});
var timer2;
class MealDetails extends Component {
  constructor(props) {
    super(props);
    // alert(JSON.stringify(this.props.userInfo.data.remarks))
    const fuQuestions =
      (this.props.userInfo &&
        this.props.userInfo.data &&
        this.props.userInfo.data.FU_ques &&
        JSON.parse(this.props.userInfo.data.FU_ques)) ||
      null;
    this.state = {
      s_id: "",
      activityLog: this.props.latestStatusUpdate
        ? [
            this.props.latestStatusUpdate,
            ...[...this.props.userInfo.data.remarks].reverse()
          ]
        : [...this.props.userInfo.data.remarks].reverse(),
      age:
        (this.props.form_details && this.props.form_details.age) ||
        (fuQuestions && fuQuestions.age) ||
        "",
      avoid: "",
      height: (fuQuestions && fuQuestions.height) || "",
      weight: (fuQuestions && fuQuestions.weight) || "",
      bmi: "",
      water_intake: "",
      diet_type: "",
      allergies: "",
      patient_medical_history: "",
      parent_medical_history: "",
      meal: "",
      sleep_patterns: "",
      fitness_routine: "",
      alcohol_intake: "",
      smoking: "",
      plan_duration: "",
      plan_goal: "",
      callStatus: "queued",
      s_id: "",
      currentTimer: 0,
      showTimer: false,
      showError: false,
      seconds: 0,
      minutes: 0,
      phone: "",
      category: true,
      remarkList: dataResource.remarksList,
      complaintList: dataResource.complaintsList,
      diagnosisList: dataResource.diagnosisList,
      historyList: dataResource.historyList,
      instructionsList: dataResource.instructionsList,
      status: { value: "o", label: "Open" },
      showRescheduleModal: false,
      daFormJson: [],
      ...this.props.patientDetails
    };
    this.age = React.createRef();
    this.height = React.createRef();
    this.weight = React.createRef();
    this.bmi = React.createRef();
    this.water_intake = React.createRef();
    this.diet_type = React.createRef();
    this.allergies = React.createRef();
    this.patient_medical_history = React.createRef();
    this.parent_medical_history = React.createRef();
    this.meal = React.createRef();
    this.sleep_patterns = React.createRef();
    this.fitness_routine = React.createRef();
    this.alcohol_intake = React.createRef();
    this.smoking = React.createRef();
    this.plan_duration = React.createRef();
    this.plan_goal = React.createRef();
    this.avoid = React.createRef();
  }
  updateData = () => {
    const data = {
      age: this.state.age,
      avoid: this.state.avoid,
      height: this.state.height,
      weight: this.state.weight,
      bmi: this.state.bmi,
      water_intake: this.state.water_intake,
      diet_type: this.state.diet_type,
      allergies: this.state.allergies,
      patient_medical_history: this.state.patient_medical_history,
      parent_medical_history: this.state.parent_medical_history,
      meal: this.state.meal,
      sleep_patterns: this.state.sleep_patterns,
      fitness_routine: this.state.fitness_routine,
      alcohol_intake: this.state.alcohol_intake,
      smoking: this.state.smoking,
      plan_duration: this.state.plan_duration,
      plan_goal: this.state.plan_goal
    };
    this.props.updateData("page1", data);
  };
  selectedObservations = (setKey, getKey, index) => {
    let obj = {};
    obj[setKey] = this.state[setKey]
      ? this.state[setKey] + ", " + this.state[getKey][index]
      : this.state[getKey][index];
    this.setState(obj);
  };
  handleSlotUpdate = data => {
    //this.state.info.data.appointment_date
    let tempObj = { ...this.props.userInfo.data };
    tempObj.data.appointment_date = data.appointment_date;
    tempObj.data.appointment_slot =
      data.slot_start_time + " - " + data.slot_end_time;
    this.setState({ info: tempObj });
  };
  getFuQuestions = (fuQuestionsJson, questionKeyMap) => {
    const fuQuestions = JSON.parse(fuQuestionsJson);
    const finalFuQuestions = {};
    for (const key in fuQuestions) {
      const text = questionKeyMap[key] || key;
      let value = fuQuestions[key];
      if (typeof value === "object") {
        value = value.join(",");
      }
      finalFuQuestions[text] = value;
    }
    return finalFuQuestions;
  };
  renderDAQna = () => {
    const fuQuestions =
      (this.props.userInfo &&
        this.props.userInfo.data &&
        this.props.userInfo.data.FU_ques) ||
      null;
    if (!fuQuestions) return <></>;

    const questionKeyMap = {};
    for (const daQuestion of this.state.daFormJson) {
      questionKeyMap[daQuestion.question] = daQuestion.text;
    }
    const finalFuQuestion = this.getFuQuestions(fuQuestions, questionKeyMap);
    return Object.keys(finalFuQuestion).map(fuQuestionKey => {
      return (
        <div>
          <div>
            <b>Q :{fuQuestionKey}</b>
          </div>
          <div>
            <span className="ans">A : </span> {finalFuQuestion[fuQuestionKey]}
          </div>
          <br />
        </div>
      );
    });
  };
  renderFormQna = () => {
    if (this.props.category.value == "DA") {
      return this.renderDAQna();
    }
    // let form_details = this.state.showData[index].form_details;
    let data = {
      Weight: this.props.form_details.Weight,
      Fitness: this.props.form_details.Fitness,
      Nutrition: this.props.form_details.Nutrition,
      Stress: this.props.form_details.Stress,
      Sleep: this.props.form_details.Sleep,
      Health: this.props.form_details.Health
    };
    let renderView = [];
    for (let [key, value] of Object.entries(data)) {
      if (value && Object.keys(value).length > 0) {
        let qnaHead = (
          <div>
            <br></br>
            <p>
              <u>
                <b>{key}</b>
              </u>
            </p>
          </div>
        );
        renderView.push(qnaHead);
        for (let [key2, value2] of Object.entries(value)) {
          if (Object.keys(value).length > 0) {
            let qnaView = (
              <div>
                <div>Q :{key2}</div>
                <div>
                  <span className="ans">A : </span> {value2}
                </div>
                <br />
              </div>
            );
            renderView.push(qnaView);
          }
        }
      }
    }
    return renderView;
  };
  handleStatusChange = change => {
    this.setState({ status: change });
  };
  pushActivityToState() {
    const date = new Date();
    const newDate = new Date(date.setMonth(date.getMonth() + 1));
    //var dateStr = this.getFormattedDateUTC(newDate);
    var dateStr = moment().subtract(5.5, "hours").format("YYYY-MM-DD HH:mm:ss");
    let newActivity = {
      updated_dttm: dateStr,
      remarks: this.state.remarks,
      status: this.state.status.value
    };
    let temp = [...this.state.activityLog];
    temp.unshift(newActivity);
    this.setState({ activityLog: temp });
  }
  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };
  printHello = () => {
    if (this.state.s_id != "") {
      const data = {
        callSid: this.state.s_id
      };
      let link = CONFIG_CONSTANTS.url.EXOTEL_CALL_DETAILS_URL;
      // let link = `https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/exotel`;
      axios.post(link, data).then(resp => {
        this.setState({ callStatus: resp.data.results.Call.Status });
        if (resp.data.results.Call.Status != "in-progress") {
          this.setState({ showTimer: false });
          clearInterval(timer2);
        }
      });
    }
  };
  makeCall = value => {
    this.setState({ showTimer: true });
    console.log("Phone number to call is " + value);
    const data = {
      doctor_id: this.props.loggedInDr.doctor_id,
      schedule_id: this.props.userId,
      patient_number: this.state.phone,
      brand: this.props.userInfo.data.brand
    };
    let link = CONFIG_CONSTANTS.url.EXOTEL_C2C_URL;
    // let link = `https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/exotel`;
    axios.post(link, data).then(resp => {
      this.setState({ s_id: resp.data.body.data.Sid });
      if (resp.data.statusCode == 429) {
        toast.error(resp.data.body.data);
        this.setState({ showError: true, showTimer: false });
      } else if (resp.data.statusCode != 200) {
        this.setState({ showError: true });
      } else {
        this.coolOffApi("", dataResource.COOL_OFF_TYPE.REMOVE);
        timer2 = window.setInterval(() => this.printHello(), 6000);
      }
    });
  };

  coolOffApi = (duration, type) => {
    if (!iaEnabled(this?.props?.userInfo?.data?.brand)) return;
    try {
      axios
        .post(CONFIG_CONSTANTS.url.UPDATE_COOLOFF, {
          type,
          duration,
          doctorId: this?.props?.loggedInDr?.doctor_id,
          appointmentId: this.props.userId
        })
        .then(resp => {});
    } catch (err) {
      console.log("ERROR WHILE COOLOFF in MealDetails", type, err);
    }
  };

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", event => {
      let data = {
        doctorId: this.props.docKey,
        form: this.props.category.value, //O
        id: this.props.userId, //1408
        status: this.state.status.value,
        remarks: this.state.remarks
      };
      let statusData = {
        status: data.status,
        remarks: data.remarks
      };
      //event.returnValue = `Are you sure you want to leave?`;

      if (
        statusData.status == statusBox.status.value &&
        statusData.remarks == statusBox.remarks &&
        this.state.orderPlaced == 0
      ) {
        event.returnValue = `Are you sure you want to leave without updating?`;
      }
    });
  };
  handleRemarks = e => {
    e.preventDefault();
    this.pushActivityToState();
    let data = {
      doctorId: this.props.docKey,
      form: this.props.category.value,
      id: this.props.userId,
      status: this.state.status.value,
      remarks: this.state.remarks
    };
    this.setState({ data: data });
    axios.post(CONFIG_CONSTANTS.url.UPDATE_STATUS_URL, data).then(resp => {
      this.setState({ remarks: "" });
    });
  };
  handleWindowClose = () => {
    let data = {
      doctorId: this.state.docKey,
      form: this.state.category.value,
      id: this.state.userId,
      status: this.state.status.value,
      remarks: this.state.remarks
    };
    let statusData = {
      status: data.status,
      remarks: data.remarks
    };
    if (
      statusData.status == statusBox.status.value &&
      statusData.remarks == statusBox.remarks
    ) {
      return "You have unsaved changes. Are you sure you want to leave?";
    }
    return "You have attempted to leave this page. Are you sure?";
  };
  getBMI = (wt, ht) => {
    const weight = parseInt(wt);
    const height_array = ht.split("'");
    const height = height_array[0] * 0.3048 + height_array[1] * 0.0254;
    let bmi = weight / (height * height);
    bmi = bmi.toFixed(1);
    if (isNaN(bmi)) bmi = "";
    return bmi;
  };
  fetchDAFormJson = async () => {
    if (this.props.category.value != "DA") return;
    const daFormUrls = {
      MM: CONFIG_CONSTANTS.url.DA_QUESTION_JSON_MM,
      BW: CONFIG_CONSTANTS.url.DA_QUESTION_JSON_BW
    };
    const url = daFormUrls[this.props.brand];
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common["Authorization"];
      const daFormResponse = await instance.get(url);
      this.setState({
        daFormJson: daFormResponse.data
      });
    } catch (err) {
      console.log("Unable to fetch DA form JSON", err);
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.patientDetails != this.props.patientDetails) {
      this.setState({
        ...this.state,
        ...this.props.patientDetails
      });
    }
  }
  componentDidMount() {
    this.fetchDAFormJson();
    this.setState({ phone: this.props.userInfo.data.phone });
    let sleep_patterns = "";
    let health_conditions = "";
    const water_intake = this.props.form_details["Weight"]
      ? this.props.form_details["Weight"][
          "How much water do you drink on a daily basis?"
        ]
      : "";
    const fitness_routine = this.props.form_details["Fitness"]
      ? this.props.form_details["Fitness"][
          "How do you describe your activity levels?"
        ]
      : "";

    if (this.props.form_details["Sleep"]) {
      Object.keys(this.props.form_details["Sleep"]).forEach(key => {
        let obj = this.props.form_details["Sleep"];
        sleep_patterns = sleep_patterns + obj[key] + ". ";
      });
    }
    if (this.props.form_details["Health"]) {
      Object.keys(this.props.form_details["Health"]).forEach(key => {
        let obj = this.props.form_details["Health"];
        health_conditions = health_conditions + obj[key] + ". ";
      });
    }
    const fuQuestions =
      (this.props.userInfo &&
        this.props.userInfo.data &&
        this.props.userInfo.data.FU_ques &&
        JSON.parse(this.props.userInfo.data.FU_ques)) ||
      null;
    const vWeight = fuQuestions && fuQuestions.weight;
    const vHeight = fuQuestions && fuQuestions.height;
    const vBMI = vWeight && vHeight ? this.getBMI(vWeight, vHeight) : "";
    this.setState(
      {
        age:
          this.props.form_details.age || (fuQuestions && fuQuestions.age) || "",
        weight: this.props.form_details.weight || vWeight || "",
        height: this.props.form_details.height || vHeight || "",
        bmi: this.props.form_details.bmi || vBMI || "",
        sleep_patterns: sleep_patterns || this.state.sleep_patterns,
        // patient_medical_history: health_conditions,
        fitness_routine: fitness_routine || this.state.fitness_routine,
        water_intake: water_intake || this.state.water_intake
      },
      this.updateData
    );
  }
  updateBasicParams = ({
    height = this.state.height,
    weight = this.state.weight
  }) => {
    const bmi = this.getBMI(weight, height) || "";
    this.setState(
      {
        height,
        weight,
        bmi
      },
      this.updateData
    );
  };

  render() {
    return (
      <>
        <div className="mealinfo-container">
          <div className="mealinfo">
            <h3 className="sectionHeading">Basic Details</h3>
            <div
              className="patient-info-box"
              style={{ width: "auto", border: "none" }}
            >
              <div className="pib--info-sec-box">
                <div className="pib--title">Name</div>
                <div className="pib--value">
                  : {this.props.userInfo.data.name}
                </div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">Age</div>
                <div className="pib--value">
                  :{" "}
                  <input
                    type="number"
                    placeholder="Enter Age"
                    value={this.state.age}
                    onChange={evt =>
                      this.setState({ age: evt.target.value }, this.updateData)
                    }
                  />
                </div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">Height</div>
                <div className="pib--value">
                  :{" "}
                  <select
                    value={this.state.height}
                    onChange={evt =>
                      this.updateBasicParams({ height: evt.target.value })
                    }
                    style={{ width: "89%" }}
                  >
                    <option value="" disabled>
                      Select Height
                    </option>
                    {dataResource.heightOptions.map(option => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">Weight</div>
                <div className="pib--value">
                  :{" "}
                  <>
                    <input
                      type="number"
                      placeholder="Enter Weight"
                      value={this.state.weight}
                      onChange={evt =>
                        this.updateBasicParams({ weight: evt.target.value })
                      }
                      style={{ width: "60%", marginRight: "5px" }}
                    />
                    in KGs
                  </>
                </div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">BMI</div>
                <div className="pib--value">: {this.state.bmi}</div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">Gender</div>
                <div className="pib--value">
                  : {this.props.userInfo.data.brand == "MM" ? "Male" : "Female"}
                </div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">Email</div>
                <div className="pib--value">
                  : {this.props.userInfo.data.email}
                </div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">Phone</div>
                <div className="pib--value" style={{ width: "auto" }}>
                  :
                  <NumPad.Number
                    onChange={value => {
                      this.setState({ phone: value }, () => {
                        this.makeCall(value);
                      });
                    }}
                    placeholder={"Enter Number"}
                    value={this.state.phone}
                    decimal={2}
                  />
                  <span
                    className="phone-icon"
                    onClick={() => this.makeCall(this.state.phone)}
                  >
                    <span className="phone-icon2">
                      <CallIcon />
                      CALL
                    </span>
                    {this.state.showError ? (
                      <span className="error-call">
                        Error calling this number
                      </span>
                    ) : null}
                  </span>
                </div>
              </div>
              {this.state.showTimer ? (
                <div className="pib--info-sec-box">
                  <div className="pib--title">Call Status</div>
                  <span className="call-status">
                    {this.state.showTimer
                      ? `${this.state.callStatus}       `
                      : "  "}
                  </span>
                  <span>
                    {this.state.showTimer ? (
                      <span className="timer">
                        <Timer initialTime={0}>
                          {({
                            start,
                            resume,
                            pause,
                            stop,
                            reset,
                            timerState
                          }) => (
                            <React.Fragment>
                              <span>
                                <Timer.Minutes
                                  formatValue={value =>
                                    (this.state.minutes = value)
                                  }
                                />{" "}
                                {this.state.minutes == 0 ? 0 : null} {" min "}
                                <Timer.Seconds
                                  formatValue={value =>
                                    (this.state.seconds = value)
                                  }
                                />
                                {" sec "}
                              </span>
                            </React.Fragment>
                          )}
                        </Timer>
                      </span>
                    ) : null}
                  </span>
                </div>
              ) : null}
              <div className="pib--info-sec-box">
                <div className="pib--title">Date</div>
                <div className="pib--value">
                  :{" "}
                  {moment(this.props.userInfo.data.appointment_date).format(
                    "LL"
                  )}
                </div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">Slot</div>
                <div className="pib--value">
                  : {this.props.userInfo.data.appointment_slot}
                </div>
              </div>
              <div className="pib--info-sec-box">
                <div className="pib--title">Doctor</div>
                <div className="pib--value">
                  : {this.props.userInfo.data.doctor_assigned}
                </div>
              </div>
              <div className="btn-box">
                <Button
                  className="modifyButton"
                  variant="primary"
                  onClick={() => this.setState({ showRescheduleModal: true })}
                >
                  Modify Appointment
                </Button>
                <Modal
                  show={this.state.showRescheduleModal}
                  onHide={() => this.setState({ showRescheduleModal: false })}
                  centered={true}
                  dialogClassName="modal-90w"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h4 className="sectionHeading">Reschedule Appointment</h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Scheduler
                      doctorID={this.props.doctor_id}
                      categories={this.props.categories}
                      email={this.props.userInfo.data.email}
                      phone={this.props.userInfo.data.phone}
                      name={this.props.userInfo.data.name}
                      source={this.props.category.value}
                      brand={this.props.brand}
                      userId={this.props.userId}
                      type={this.props.category}
                      hideScheduler={() => {}}
                      closeScheduler={() => {
                        this.setState({ showRescheduleModal: false });
                        window.forceReloadWithoutChecking = true;
                        window.location.reload();
                      }}
                      handleSlotUpdate={this.handleSlotUpdate}
                    />
                  </Modal.Body>
                </Modal>
              </div>
              <div>
                <PatientHistory
                  userInfo={this.props.userInfo}
                  brand={this.props.brand}
                  copyPrescription={this.props.copyPrescription}
                />
              </div>
            </div>

            {/* <form>
              <div className="sec-box">
                <div className="sectionLable">Age</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.age}
                    placeholder="Enter Age"
                    value={this.state.age}
                    onChange={event =>
                      this.setState(
                        { age: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">Height</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.height}
                    placeholder="Enter Height"
                    value={this.state.height}
                    onChange={event =>
                      this.setState({ height: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">Weight</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.weight}
                    placeholder="Enter Weight"
                    value={this.state.weight}
                    onChange={event =>
                      this.setState(
                        { weight: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">BMI</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.bmi}
                    placeholder="Enter BMI"
                    value={this.state.bmi}
                    onChange={event =>
                      this.setState(
                        { bmi: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
            </form> */}
          </div>
          <div className="mealinfo">
            <h3 className="sectionHeading">Medical History</h3>
            <form>
              <div className="sec-box">
                <div className="sectionLable">Patient Medical History</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.patient_medical_history}
                    placeholder="Enter history"
                    value={this.state.patient_medical_history}
                    onChange={event =>
                      this.setState(
                        {
                          patient_medical_history: event.target.value
                        },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">Parent Medical History</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.parent_medical_history}
                    placeholder="Enter Parent medical history"
                    value={this.state.parent_medical_history}
                    onChange={event =>
                      this.setState(
                        {
                          parent_medical_history: event.target.value
                        },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="mealinfo-container">
          <div className="mealinfo">
            <h3 className="sectionHeading">Dietary Preference</h3>
            <form>
              <div className="sec-box">
                <div className="sectionLable">Meal/Day</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.meal}
                    placeholder="Enter Meal/Day"
                    value={this.state.meal}
                    onChange={event =>
                      this.setState(
                        { meal: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              {/* <div className="sec-box">
                <div className="sectionLable">Water Intake</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.water_intake}
                    placeholder="Enter Water intake in litres"
                    value={this.state.water_intake}
                    onChange={event =>
                      this.setState(
                        { water_intake: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div> */}
              <div className="sec-box">
                <div className="sectionLable">Diet Type</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.diet_type}
                    placeholder="Enter Diet Type"
                    value={this.state.diet_type}
                    onChange={event =>
                      this.setState(
                        { diet_type: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">Allergies</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.allergies}
                    placeholder="Enter Allergies"
                    value={this.state.allergies}
                    onChange={event =>
                      this.setState(
                        { allergies: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">What to avoid</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.avoid}
                    placeholder="Enter what to avoid"
                    value={this.state.avoid}
                    onChange={event =>
                      this.setState(
                        { avoid: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="mealinfo">
            <h3 className="sectionHeading">Lifestyle</h3>
            <form>
              <div className="sec-box">
                <div className="sectionLable">Water Intake</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.water_intake}
                    placeholder="Enter Water intake in litres"
                    value={this.state.water_intake}
                    onChange={event =>
                      this.setState(
                        { water_intake: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>

              <div className="sec-box">
                <div className="sectionLable">Sleep Patterns</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.sleep_patterns}
                    placeholder="Enter Sleep pattern"
                    value={this.state.sleep_patterns}
                    onChange={event =>
                      this.setState(
                        { sleep_patterns: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">Fitness Routine</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.fitness_routine}
                    placeholder="Enter Fitness Routine"
                    value={this.state.fitness_routine}
                    onChange={event =>
                      this.setState(
                        { fitness_routine: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">Alcohol Intake</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.alcohol_intake}
                    placeholder="Enter Alcohol Intake"
                    value={this.state.alcohol_intake}
                    onChange={event =>
                      this.setState(
                        { alcohol_intake: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">Smoking</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.smoking}
                    placeholder="Enter Smoking Habits"
                    value={this.state.smoking}
                    onChange={event =>
                      this.setState(
                        { smoking: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="mealinfo-container">
          <div className="mealinfo">
            <h3 className="sectionHeading">Plan</h3>
            <form>
              <div className="sec-box">
                <div className="sectionLable">Duration</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.plan_duration}
                    placeholder="Enter Duration"
                    value={this.state.plan_duration}
                    onChange={event =>
                      this.setState(
                        { plan_duration: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
              <div className="sec-box">
                <div className="sectionLable">Goals</div>
                <div className="item">
                  <input
                    type="text"
                    ref={this.plan_goal}
                    placeholder="Enter goals"
                    value={this.state.plan_goal}
                    onChange={event =>
                      this.setState(
                        { plan_goal: event.target.value },
                        this.updateData
                      )
                    }
                  />
                </div>
              </div>
            </form>
            <div className="status-info-box" style={{ width: "70%" }}>
              <h4 className="sectionHeading">Status</h4>
              {this.state.category && (
                <div>
                  <div className="status-drop">
                    Call Status
                    <div
                      className="categoryDropdown"
                      style={{ width: "100%", marginTop: "5px" }}
                    >
                      <Select
                        options={dataResource.statusList}
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        className="ssib--st-dd"
                        style={{ width: "100%", padding: "0px" }}
                      />
                    </div>
                  </div>
                  Enter Remarks
                  <textarea
                    cols={20}
                    rows={2}
                    placeholder=" Enter Remarks"
                    type="text"
                    required
                    value={this.state.remarks}
                    name="remarks"
                    onChange={this.handleChange}
                    className="sib--remark-ta"
                    style={{ width: "92%", marginTop: "5px", resize: "none" }}
                  />
                  <div className="dd" style={{ marginTop: "5px" }}>
                    <DropdownButton
                      variant="Secondary"
                      id="dropdown-item-button"
                      title=""
                    >
                      {this.state.remarkList.map((elem, index) => (
                        <Dropdown.Item
                          as="button"
                          key={index}
                          onClick={() =>
                            this.selectedObservations(
                              "remarks",
                              "remarkList",
                              index
                            )
                          }
                        >
                          {elem}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div className="sib--remark-btn-box">
                    <Button
                      className="btn btn-s"
                      variant="primary"
                      onClick={e => this.handleRemarks(e)}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              )}

              <div className="sib--activity-logs">
                <h5>Recent updates</h5>
                {this.state.activityLog ? (
                  <div className="sib--activity-box">
                    {this.state.activityLog.map((elem, index) => (
                      <div className="remarkBox">
                        {/* <p>{elem.remarks}-{elem.updated_dttm}-{elem.status}</p> */}
                        <div className="activitytop">
                          <div className="remarkStatus">
                            {statusObj[elem.status]}
                          </div>
                          <div className="timestamp">
                            {UtilityService.getIST(elem.updated_dttm)}
                          </div>
                        </div>
                        <div className="remark">{elem.remarks}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="sib--no-log">No Recent Activity</div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="mealinfo">
                    <h5>Miscellaneous</h5>
                </div> */}
          <div className="mealinfo">
            <h3 className="sectionHeading">QnA</h3>
            <div className="form-qna">{this.renderFormQna()}</div>
          </div>
        </div>
      </>
    );
  }
}

export default MealDetails;

import React, { useState, useEffect } from "react";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import axios from "axios";
import CONFIG_CONSTANTS from "./../../../constants/apiList";
import Loader from "react-loader-spinner";
import Table4 from "../../common/Tables/Table4";

function VideocallTiming(props) {
  const [startDate, setStartDate] = useState(
    moment().subtract(5, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().add(5, "days").format("YYYY-MM-DD")
  );
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const columns = [
    { title: "Appointment ID", field: "appointment_id" },
    { title: "Brand", field: "brand" },
    { title: "Doctor", field: "doctor_name" },
    { title: "Date", field: "date" },
    { title: "Start Time", field: "start_time" },
    { title: "End Time", field: "end_time" },
    { title: "Duration", field: "duration" }
  ];
  const generateRows = data => {
    const tempRows = [];
    for (const timing of data) {
      const finalRow = { ...timing };
      finalRow.date = moment(finalRow.date, "YYYY-MM-DD").format("DD MMM YYYY");
      finalRow.start_time = moment(finalRow.start_time, "HH:mm:ss").format(
        "hh:mm:ss A"
      );
      finalRow.end_time = moment(finalRow.end_time, "HH:mm:ss").format(
        "hh:mm:ss A"
      );
      tempRows.push(finalRow);
    }
    setRows(tempRows);
  };
  const getVideocallTimings = async () => {
    setLoader(true);
    const requestData = {
      startDate,
      endDate
    };
    await axios
      .post(CONFIG_CONSTANTS.url.GET_VC_TIMING, requestData)
      .then(res => {
        console.log(res);
        if (res.data.success) {
          generateRows(res.data.result);
        }
        setLoader(false);
      })
      .catch(err => {
        console.log("ERROR IN /getVCTiming", err);
        setLoader(false);
      });
  };
  useEffect(() => {
    getVideocallTimings();
  }, []);
  return (
    <div className="videocall-timing">
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={startDate}
          onChange={evt => setStartDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={endDate}
          onChange={evt => setEndDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={getVideocallTimings}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      {loader ? (
        <Loader
          width={40}
          height={40}
          type="ThreeDots"
          color="#144166"
          style={{ marginLeft: "10px" }}
        />
      ) : (
        <Table4
          title="Videocall Timings"
          cols={columns}
          rows={rows}
          pageSize={rows.length > 10 ? 10 : rows.length}
          search={true}
        />
      )}
    </div>
  );
}
export default VideocallTiming;

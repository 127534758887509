import React, { useState, useEffect, useRef } from "react";
import "./PresetBuilder.scss";
import axios from "axios";
import config from "../../../../constants/apiList";
import { Box } from "@mui/system";
import { Cancel } from "@material-ui/icons";
import { toast } from "react-toastify";
import { FormControl, Stack, TextField, Typography } from "@mui/material";
import {
  Grid,
  Input,
  InputAdornment,
  Popover,
  Checkbox
} from "@material-ui/core";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import Loader from "react-loader-spinner";
import { withStyles } from "@material-ui/core/styles";
import { store } from "../../../../reducers/Persist";

const CustomColorCheckbox = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: "black"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

// const GrowingInput = ({value, setTempValue}) => {
//     const [width, setWidth] = useState(value?.length);

//     const changeHandler = evt => {
//         console.log(evt.target.value.length);
//       setWidth(evt.target.value.length + 5);
//       setTempValue(evt.target.value);
//     };

//     return (
//       <input
//       style={{
//           width: width +'ch',
//           border: "none",
//           outline: "none",
//         }}
//       type="text"
//       autoFocus
//       value={value}
//       onChange={changeHandler} />
//     )
//   };

const Tags = ({
  data,
  handleDelete,
  handleChange,
  deleteLoading,
  editLoading
}) => {
  const [edit, setEdit] = useState(false);
  const [tempValue, setTempValue] = useState(data.observation_preset_text);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        background: "#E4E2E2",
        height: "100%",
        display: "flex",
        padding: "0.4rem",
        margin: "5px 3px",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: "5px",
        width: "fit-content"
      }}
    >
      {!edit ? (
        <Stack direction="row" gap={1}>
          <div
            onDoubleClick={() => setEdit(true)}
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {data.observation_preset_text}
          </div>
          <AiOutlineClose
            aria-describedby={id}
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          />
          <Popover
            id={id}
            open={popoverOpen}
            onClose={() => {
              if (!deleteLoading) handleClose();
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            className="delete-popover"
          >
            <div style={{ margin: "10px" }}>
              {deleteLoading ? (
                <Loader type="TailSpin" width="30" height="30" />
              ) : (
                <div>
                  Are you sure you want to delete this preset?
                  <div style={{ display: "flex" }}>
                    <button
                      className="popover-button yes-button"
                      onClick={() => {
                        handleDelete(data.id).then(() => handleClose());
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="popover-button no-button"
                      onClick={handleClose}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Popover>
        </Stack>
      ) : (
        <div>
          {editLoading ? (
            <Loader type="TailSpin" width="25" height="25" />
          ) : (
            <form
              onSubmit={e => {
                e.preventDefault();
                if (tempValue.trim() !== "") {
                  handleChange(data.id, tempValue);
                  setEdit(false);
                }
              }}
              style={{ margin: "0 0" }}
            >
              <input
                type="text"
                value={tempValue}
                style={{
                  padding: "5px 10px",
                  border: "none",
                  outline: "none"
                }}
                onChange={e => setTempValue(e.target.value)}
              />
              {/* <GrowingInput 
                    value={tempValue}
                    setTempValue={setTempValue}
                /> */}
            </form>
          )}
        </div>
      )}
    </Box>
  );
};
function InputTags({ tagData = [], subTitle, type, brand }) {
  const [tags, SetTags] = useState(tagData || []);
  const [value, setValue] = useState("");
  const [createLoading, setCreateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const handleDelete = async value => {
    // API call to delete this preset
    setDeleteLoading(true);
    axios
      .post(config.url.DELETE_PRESET, { id: value })
      .then(res => {
        if (res.data.success) {
          const newtags = tags.filter(val => val.id !== value);
          SetTags(newtags);
          toast.success("Preset deleted successfully");
        } else toast.error("Something went wrong");
        setDeleteLoading(false);
      })
      .catch(err => {
        toast.error("Something went wrong");
        setDeleteLoading(false);
      });
  };
  const handleChange = (id, value) => {
    // API call to edit presets
    const payload = {
      id: id,
      observation_preset_text: value.trim(),
      observation_type: type,
      tag: value,
      brand: brand
    };
    setEditLoading(true);
    axios
      .post(config.url.EDIT_PRESET, payload)
      .then(res => {
        if (res.data.success) {
          const newtags = tags.map(val => {
            if (val.id === id) {
              return { ...val, observation_preset_text: value };
            } else return val;
          });
          SetTags(newtags);
          toast.success("Preset updated successfully");
        } else toast.error("Something went wrong");
        setEditLoading(false);
      })
      .catch(err => {
        toast.error("Something went wrong");
        setEditLoading(false);
      });
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    if (value.trim() !== "") {
      //API call to create preset
      setCreateLoading(true);
      const payload = {
        observation_preset_text: value.trim(),
        observation_type: type,
        tag: value.trim(),
        brand: brand
      };
      axios
        .post(config.url.CREATE_PRESET, payload)
        .then(res => {
          if (res.data.success) {
            SetTags([
              ...tags,
              {
                observation_preset_text: value.trim(),
                id: res.data.data.id,
                brand: brand
              }
            ]);
            setValue("");
            toast.success(res.data.data.message);
          } else {
            toast.error("Something went wrong");
          }
          setCreateLoading(false);
        })
        .catch(err => {
          console.log(err);
          toast.error("Something went wrong");
          setCreateLoading(false);
        });
    }
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}
    >
      <h3>{subTitle}</h3>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {tags?.map((data, index) => {
          if (data.brand === brand)
            return (
              <Tags
                data={data}
                handleDelete={handleDelete}
                key={index}
                handleChange={handleChange}
                editLoading={editLoading}
                deleteLoading={deleteLoading}
              />
            );
          else return null;
        })}
      </div>
      <form onSubmit={handleOnSubmit} style={{ margin: "0" }}>
        <input
          value={value}
          className="input-tags"
          placeholder="Add new tag"
          disabled={createLoading}
          style={{ width: "100%" }}
          onChange={e => {
            setValue(e.target.value);
          }}
        />
      </form>
      {createLoading ? (
        <div className="loader-container">
          <Loader type="TailSpin" />
        </div>
      ) : (
        <button className="update-presets" onClick={handleOnSubmit}>
          Create Preset
        </button>
      )}
    </div>
  );
}
export default function PresetBuilder(props) {
  const [presetData, setPresetData] = useState([]);
  const [idToText, setIdToText] = useState({});
  const loading = props.loading,
    setLoading = props.setLoading;
  const [brand, setBrand] = useState("BW");
  useEffect(() => {
    setLoading(true);
    const url = `${config.url.GET_PRESET_URL}?brand=${brand}`;
    axios
      .get(url)
      .then(res => {
        let obj = {},
          idsToText = {};
        for (const ele of res.data.data) {
          if (!obj[ele.observation_type]) obj[ele.observation_type] = [];
          obj[ele.observation_type].push(ele);
          idsToText[ele.id] = ele.observation_preset_text;
        }
        let presetIdToObj = {};
        for (const item of res.data.data) {
          presetIdToObj[item.id] = item;
        }
        store.dispatch({
          type: "CHANGE_PRESET_ID_TO_OBJ",
          data: presetIdToObj
        });
        setPresetData(obj);
        setIdToText(idsToText);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [brand]);
  return (
    <div className="preset-builder-container">
      <form className="brands">
        <input
          type="radio"
          readOnly
          id="BW"
          name="brand"
          checked={brand === "BW"}
          onClick={() => setBrand("BW")}
          value="BW"
          style={{ marginRight: "5px" }}
        />
        <label htmlFor="BW" style={{ marginRight: "10px" }}>
          be Bodywise
        </label>
        <input
          type="radio"
          readOnly
          id="MM"
          name="brand"
          value="MM"
          checked={brand === "MM"}
          onClick={() => setBrand("MM")}
          className="brand-value"
          style={{ marginRight: "5px" }}
        />
        <label htmlFor="MM" style={{ marginRight: "10px" }}>
          Man Matters
        </label>
        <input
          type="radio"
          readOnly
          id="LJ"
          name="brand"
          value="LJ"
          checked={brand === "LJ"}
          onClick={() => setBrand("LJ")}
          className="brand-value"
          style={{ marginRight: "5px" }}
        />
        <label htmlFor="LJ" style={{ marginRight: "10px" }}>
          Little Joys
        </label>
      </form>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center"
          }}
        >
          <Loader type="TailSpin" />
        </div>
      ) : (
        <div>
          <InputTags
            tagData={presetData.diagnosis}
            subTitle="Diagnosis"
            brand={brand}
            type="diagnosis"
          />
          <InputTags
            tagData={presetData.allergy_food_specification}
            subTitle="Food Allergies"
            brand={brand}
            type="allergy_food_specification"
          />
          <InputTags
            tagData={presetData.allergy_animal_specification}
            subTitle="Animal Allergies"
            brand={brand}
            type="allergy_animal_specification"
          />
          <InputTags
            tagData={presetData.allergy_drug_specification}
            subTitle="Drug Allergies"
            brand={brand}
            type="allergy_drug_specification"
          />
          <InputTags
            tagData={presetData.allergy_environmental_specification}
            subTitle="Environmental Allergies"
            brand={brand}
            type="allergy_environmental_specification"
          />
          <InputTags
            tagData={presetData.allergy_other_specification}
            subTitle="Other Allergies"
            brand={brand}
            type="allergy_other_specification"
          />
          <InputTags
            tagData={presetData.past_treatments}
            subTitle="Past Treatments"
            brand={brand}
            type="past_treatments"
          />
          <InputTags
            tagData={presetData.medical_history}
            subTitle="Medical History"
            brand={brand}
            type="medical_history"
          />
          <InputTags
            tagData={presetData.family_history}
            subTitle="Family History"
            brand={brand}
            type="family_history"
          />
          <InputTags
            tagData={presetData.other_details}
            subTitle="Other Details"
            brand={brand}
            type="other_details"
          />
          <InputTags
            tagData={presetData.concern}
            subTitle="Chief Complaints"
            brand={brand}
            type="concern"
          />
          <InputTags
            tagData={presetData.category}
            subTitle="Category of Complaint"
            brand={brand}
            type="category"
          />
          <InputTags
            tagData={presetData.doctor_notes}
            subTitle="Doctor Notes"
            brand={brand}
            type="doctor_notes"
          />
          <InputTags
            tagData={presetData.suggest_procedure}
            subTitle="Suggest Procedure"
            brand={brand}
            type="suggest_procedure"
          />
          <InputTags
            tagData={presetData.special_consult}
            subTitle="Specialist Consult"
            brand={brand}
            type="special_consult"
          />
          <InputTags
            tagData={presetData.lifestyle}
            subTitle="Lifestyle Recommendations"
            brand={brand}
            type="lifestyle"
          />
          <InputTags
            tagData={presetData.suggest_diagnostics}
            subTitle="Suggest Diagnostics"
            brand={brand}
            type="suggest_diagnostics"
          />
          <InputTags
            tagData={presetData.remarks_post_submit}
            subTitle="Remarks Post Submit"
            brand={brand}
            type="remarks_post_submit"
          />
          <InputTags
            tagData={presetData.remarks_pf}
            subTitle="Remarks PF"
            brand={brand}
            type="remarks_pf"
          />
        </div>
      )}
    </div>
  );
}

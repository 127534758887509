import React from "react";
import styles from "./styles.module.scss";
import CONSTANTS from "./constants";
const noop = () => console.warn("Function missing");

const Header = ({
  state = {},
  actions = {},
  isAllBrandAllowed = true,
  isAdmin = false
}) => {
  if (!(state && actions)) return <></>;
  const { fromDate, toDate, todaySelected, nowSelected, brandState } = state;
  const {
    setFromDate = noop,
    setToDate = noop,
    onTodaySelected = noop,
    onNowSelected = noop,
    fetchCountOverview = noop,
    onBrandChange = noop
  } = actions;
  return (
    <div className={styles["overview__header"]}>
      <div className={styles["left-section"]}>
        <div className={styles["date-range-section"]}>
          <div className={styles["date-group"]}>
            <div className={styles["input-label"]}>From</div>
            <input
              type="date"
              className={styles["date-input"]}
              value={fromDate}
              onChange={evt => setFromDate(evt.target.value)}
            />
          </div>
          <div className={styles["date-group"]}>
            <div className={styles["input-label"]}>To</div>
            <input
              type="date"
              className={styles["date-input"]}
              value={toDate}
              onChange={evt => setToDate(evt.target.value)}
            />
          </div>
        </div>
        <div className={styles["quick-option-section"]}>
          <div className={styles["option-group"]} onClick={onTodaySelected}>
            <input
              type="radio"
              className={styles["radio-input"]}
              checked={todaySelected}
              readOnly
            />
            <div className={styles["input-label"]}>Today</div>
          </div>
          <div className={styles["option-group"]} onClick={onNowSelected}>
            <input
              type="radio"
              className={styles["radio-input"]}
              checked={nowSelected}
              readOnly
            />
            <div className={styles["input-label"]}>Last Hour</div>
          </div>
        </div>
        <div className={styles["button-section"]}>
          <button
            className={styles["search-button"]}
            onClick={fetchCountOverview}
          >
            Go
          </button>
        </div>
      </div>
      <div className={styles["right-section"]}>
        <div className={styles["brand-option-section"]}>
          <div className={styles["input-label"]}>Brand</div>
          <div className={styles["dropdown-wrapper"]}>
            <select
              className={styles["brand-dropdown"]}
              onChange={onBrandChange}
              value={brandState}
            >
              {CONSTANTS?.BRANDS?.map(brand => (
                <option
                  key={brand.value}
                  className={styles["brand-option"]}
                  value={brand.value}
                  disabled={
                    isAdmin &&
                    !isAllBrandAllowed &&
                    brand.value.toLowerCase() === "all"
                  }
                >
                  {brand.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

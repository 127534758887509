import styled from "styled-components";

export const SpoofEmailIdContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  label {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const SpoofEmailIdInput = styled.input`
  width: 300px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
  margin: 10px 0;
`;

export const SpoofEmailIdButton = styled.button`
  width: 300px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
  margin: 10px 0;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`;

import React from "react";
import MealDetails2 from "./../../core/MealDetails2/MealDetails2";

export default function MealPlanner3(props) {
  return (
    <MealDetails2
      form_details={props.form_details}
      updateData={props.updateData}
      patientChanges={props.patientChanges}
      productData={props.productData}
      editItem={props.editItem}
      getDetails={props.getDetails}
      orderPlaced={props.orderPlaced}
      recommended={props.recommended}
      handlePreview={props.handlePreview}
      userInfo={props.userInfo}
      prescriptionsList={props.prescriptionsList}
      deleteElement={props.deleteElement}
      handleMove={props.handleMove}
      editElement={props.editElement}
      complaints={props.complaints}
      history={props.history}
      diagnosis={props.diagnosis}
      instructions={props.instructions}
      description={props.description}
      setPrescriptionID={props.setPrescriptionID}
      recommendationChange={props.recommendationChange}
      isEdit={props.isEdit}
      form={props.form}
      userId={props.userId}
      brand={props.brand}
      type_text={"normal_form"}
      prescription_order_ids={props.prescription_order_ids}
    />
  );
}

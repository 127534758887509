import React, { Component } from "react";
import "./styles.scss";
import { Card, CardHeader, Grid } from "@material-ui/core";
import Lifestyle from "./Lifestyle";
import Habits from "./Habits";
import Allergies from "./Allergies";
import PrescribedProducts from "./PrescribedProducts";
import BMI from "./BMI";
import MaritalStatus from "./MaritalStatus";
export default class AdditionalInformation extends Component {
  render() {
    return (
      <div>
        <div className="title">Lifestyle</div>
        <div className="margin-30 AdditionalInformation">
          <Lifestyle />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <BMI />
            </div>
            <div style={{ width: "48%" }}>
              <MaritalStatus />
            </div>
          </div>
          <Habits />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Allergies />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PrescribedProducts />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import { DoctorViewOuter } from "./styles";
import MaterialTable from "material-table";
import { doctorColumns, mandoryForAdd, mandoryForUpdate } from "./constants";
import { toast } from "react-toastify";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import Axios from "axios";

export default function DoctorView({}) {
  const [row, setRow] = useState([]);
  const fetchDoctors = async () => {
    try {
      const url = CONFIG_CONSTANTS.url.LIST_DOCTORS;
      const res = await Axios.get(url);
      setRow(res.data.data.doctors);
    } catch (err) {
      toast.error(err?.response?.message || "Unable to get doctor Details");
    }
  };

  const getSignImage = () => {
    const SIGN_IMAGE = "signImage";
    const signImage = localStorage.getItem(SIGN_IMAGE);
    localStorage.removeItem(SIGN_IMAGE);
    return signImage;
  };

  const validated = (data, fields) => {
    for (const key of fields) {
      if (!data[key]) return false;
    }
    return true;
  };

  const onRowAdd = async doctorDetails => {
    try {
      const reqBody = {
        ...doctorDetails,
        signImage: getSignImage()
      };
      if (!validated(reqBody, mandoryForAdd)) {
        toast.error("Please fill the medatory details");
        return;
      }
      const res = await Axios.post(CONFIG_CONSTANTS.url.ADD_DOCTOR_V2, reqBody);
      toast.success(res?.data?.message);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Unable to add doctor");
    }
  };

  const onRowUpdate = async (doctorDetails, oldDetails) => {
    try {
      const reqBody = {
        ...doctorDetails,
        signImage: getSignImage()
      };
      if (!validated(reqBody, ["doctorId"])) {
        toast.error("Please fill the medatory details");
        return;
      }
      const res = await Axios.post(
        CONFIG_CONSTANTS.url.UPDATE_DOCTOR_V2,
        reqBody
      );
      toast.success(res?.data?.message);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Unable to update doctor");
    }
  };

  useEffect(() => {
    fetchDoctors();
  }, []);
  return (
    <DoctorViewOuter>
      <MaterialTable
        title="Doctors"
        columns={doctorColumns}
        data={row}
        options={{
          search: true,
          exportButton: true,
          exportAllData: true,
          pageSize: 15,
          headerStyle: {
            backgroundColor: "#003459",
            color: "#FFF"
          }
        }}
        editable={{
          onRowAdd,
          onRowUpdate
        }}
        style={{ border: "1px solid black" }}
      />
    </DoctorViewOuter>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  background-color: ${props => props.background ?? "#E5E5E5"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 12px;
  width: 187px;
  border-radius: 10px;

  @media screen and (max-width: 1024px) {
    width: 140px;
    padding: 15px 8px;
  }
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  bottom: 2px;
`;

export const IndicatorText = styled.span`
  color: ${props => props.color ?? "#000"};
  font-family: "Sofia Pro";
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 0;
  margin: 0;
  width: 82px;
  white-space: nowrap;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
  }
`;

export const Indicator = styled.div`
  height: 17px;
  width: 17px;
  margin-left: 10px;
  border-radius: 10%;
  background-color: ${props => props.background ?? "#fff"};
  border-radius: 5px;
`;

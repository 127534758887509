import React from "react";

export default function Card2({ data2 }) {
  const data = data2.length
    ? data2[0]
    : {
        appointments: 0,
        completed: 0,
        rx_generated: 0,
        pending_completed: 0
      };
  return (
    <div
      style={{
        display: "inline-flex",
        padding: "20px",
        boxShadow: "3px 3px 3px 3px gray",
        backgroundColor: "white",
        marginLeft: "10px"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "black",
          backgroundColor: "#E3E3E3",
          padding: "5px",
          marginRight: "15px",
          width: "140px"
        }}
      >
        <div
          style={{
            fontSize: "30px",
            color: "black",
            fontWeight: "700",
            width: "100%"
          }}
        >
          {data.appointments < 10 && data.appointments > 0
            ? "0" + data.appointments
            : data.appointments}
        </div>
        Appointments
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "black",
          backgroundColor: "#E3E3E3",
          padding: "5px",
          marginRight: "15px",
          width: "140px"
        }}
      >
        <div
          style={{
            fontSize: "30px",
            color: "green",
            fontWeight: "700",
            width: "100%"
          }}
        >
          {data.pending_completed < 10 && data.pending_completed > 0
            ? `0${data.pending_completed}`
            : data.pending_completed}
        </div>
        Completed
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "black",
          backgroundColor: "#E3E3E3",
          padding: "5px",
          width: "140px"
        }}
      >
        <div
          style={{
            fontSize: "30px",
            color: "#154166",
            fontWeight: "700",
            width: "100%"
          }}
        >
          {data.rx_generated < 10 && data.rx_generated > 0
            ? "0" + data.rx_generated
            : data.rx_generated}
        </div>
        Rx Generated
      </div>
    </div>
  );
}

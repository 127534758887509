import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import moment from "moment";

export default function FullSlots() {
  let today = moment().format("YYYY-MM-DD");
  const [data, setData] = useState({});
  const [date, setDate] = useState(today);
  const [brand, setBrand] = useState("MM");
  const [categoryValue, setCategoryValue] = useState("all");
  const reqBody = {
    from_date: date,
    category: categoryValue,
    brand
  };

  useEffect(() => {
    axios
      .post(config.url.SLOTS_FULL, reqBody)
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [date, brand, categoryValue]);
  let current = moment(date).subtract(1, "d");
  const dateRange = Array(7)
    .fill()
    .map(() => current.add(1, "d").format("YYYY-MM-DD"));
  let cols1 = ["Slots", ...dateRange].map(col => {
    return {
      title: col === "Slots" ? "Slots" : moment(col).format("MMM D"),
      field: col
    };
  });
  const rows = [];
  for (const key in data) {
    const row = { Slots: moment(key, "HH:mm:ss").format("hh:mm A") };
    const days = data[key];
    dateRange.forEach((date, dateIndex) => {
      row[date.toString()] =
        days[date.toString()] == null
          ? "-"
          : moment(days[date.toString()])
              .add("05:30", "hours")
              .format("YYYY-MM-DD hh:mm A");
    });
    rows.push(row);
  }
  const categories = [
    { label: "All", value: "all" },
    { label: "Hair", value: "is_hc" },
    { label: "Skin", value: "skin" },
    { label: "Performance", value: "is_sh" },
    { label: "Bodycare", value: "body-care" },
    { label: "PCOS", value: "pcos" },
    { label: "Beard", value: "beard" },
    { label: "General", value: "general" },
    { label: "Nutrition", value: "nutrition" },
    { label: "Other", value: "other" }
  ];
  return (
    <>
      <input
        type="date"
        className="input"
        value={date}
        onChange={e => setDate(e.target.value)}
      />
      <select
        style={{ marginLeft: "10px" }}
        onChange={e => {
          setCategoryValue(e.target.value);
        }}
      >
        {categories.map((category, categoryIndex) => {
          return (
            <option
              key={categoryIndex}
              value={category.value}
              selected={categoryValue === category.value}
            >
              {category.label}
            </option>
          );
        })}
      </select>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>
      <Table4 cols={cols1} rows={rows} title="Full Slots" pageSize={20} />
    </>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MWLogo = styled.img`
  max-width: 200px;
  height: fit-content;
  padding-top: 20px;
`;

export const DochubLogo = styled.img`
  height: 180px;
  width: 180px;
`;

export const GoogleAnchor = styled.a`
  display: flex;
  column-gap: 5px;
  border: 3px solid #bfbfbf;
  outline: 1px solid black;
  border-radius: 6px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  img {
    height: 25px;
    width: 25px;
  }
  p {
    font-size: 24px;
    padding-left: 5px;
    font-family: "Roboto", "sans-serif";
    font-style: normal;
    color: #a1a1a1;
    margin: 0;
  }

  &:hover {
    scale: 1.1;
    transition: 0.2s;
  }
`;

export const BottomBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  padding-bottom: 30px;
`;

export const BottomBarLogos = styled.img`
  height: 40px;
`;

export const pendingMetricsColumns = [
  {
    title: "Appointment Type",
    field: "apptType"
  },
  {
    title: "Total",
    field: "total"
  },
  {
    title: "Assigned",
    field: "assigned"
  },
  {
    title: "Assignment Rate",
    field: "assignmentRate"
  },
  {
    title: "Assigned Addressed",
    field: "assignedAddressed"
  },
  {
    title: "Assigned Addressal",
    field: "assignedAddressal"
  },
  {
    title: "Assigned Completed",
    field: "assignedCompleted"
  },
  {
    title: "Assigned Completion",
    field: "assignedCompletion"
  },
  {
    title: "Valid Assigned",
    field: "validAssigned"
  },
  {
    title: "Valid Assigned Completion",
    field: "validAssignedCompletion"
  },
  {
    title: "Valid Completion",
    field: "validCompletion"
  },
  {
    title: "Average Duration",
    field: "averageDuration"
  },
  {
    title: "PCI",
    field: "averagePci"
  },
  {
    title: "Avg. Duration",
    field: "averageDuration"
  }
];

/**
 * Formats the text to display in the table
 * @param {number} val
 * @returns {string}
 */
export function dataTextFormat(value, valuePercentage) {
  return `${value || 0} | ${valuePercentage || 0}%`;
}

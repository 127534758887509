import React from "react";
import {
  Container,
  MWLogo,
  BottomBar,
  BottomBarLogos,
  DochubLogo,
  GoogleAnchor
} from "./styles";
import OtpLogin from "./OtpLoginModal";
const ASSETS = {
  MMLOGO:
    "https://ik.ourlittlejoys.com/mw-img/Consult_-_Dochub/image_3_V2MS2ImyMU.png?updatedAt=1678881885479",
  MWLOGO:
    "https://ik.ourlittlejoys.com/mw-img/Consult_-_Dochub/image_1_cRK-GVgSPG.png?updatedAt=1678881885465",
  BWLOGO:
    "https://ik.ourlittlejoys.com/mw-img/Consult_-_Dochub/Mask_group-1_JwkOjed_pX.png?updatedAt=1678881885350",
  GLOGO:
    "https://ik.ourlittlejoys.com/mw-img/Consult_-_Dochub/google_NeaSgfuDoa.png?updatedAt=1678881885344",
  LJLOGO:
    "https://ik.ourlittlejoys.com/mw-img/Consult_-_Dochub/Mask_group_9bNdb2ctbD.png?updatedAt=1678881885343",
  DHLOGO:
    "https://ik.ourlittlejoys.com/mw-img/Consult_-_Dochub/dochub_eCPDFST_3j.png?updatedAt=1678881885291"
};

export default function SignInWithGoogle({ url, otp }) {
  return (
    <Container>
      <MWLogo src={ASSETS.MWLOGO} alt="MWLOGO" />
      <DochubLogo src={ASSETS.DHLOGO} alt="DHLOGO" />
      {otp && <OtpLogin />}
      <BottomBar>
        <BottomBarLogos src={ASSETS.MMLOGO} alt="Man Matters" />
        <BottomBarLogos src={ASSETS.BWLOGO} alt="Be Bodywise" />
        <BottomBarLogos src={ASSETS.LJLOGO} alt="Little Joys" />
      </BottomBar>
    </Container>
  );
}

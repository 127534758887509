import React, { useState } from "react";
import styles from "./styles.module.scss";

const SummarySection = ({ state = {} }) => {
  if (!state) return <></>;
  const { summaryList = [] } = state;
  if (!(summaryList && summaryList.length)) return <></>;
  return (
    <div className={styles["overview__summary-section"]}>
      {summaryList.map(summary => {
        const { value, label, variant = "", hide = false } = summary;
        if (hide) return null;
        return (
          <div
            className={`${styles["summary-card"]} ${
              variant ? styles[variant] : ""
            }`}
            key={label}
          >
            <div className={styles["summary-value"]}>{value || "-"}</div>
            <div className={styles["summary-label"]}>{label}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SummarySection;

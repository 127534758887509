import React from "react";

const CONSTANTS = {
  STEP_IN: {
    BUTTON_TEXT: "Step In",
    PRIMARY_COLOR: "rgb(55, 159, 142)",
    SECONDARY_COLOR: "rgba(55, 159, 142`, 0.1)",
    BUTTON_ICON: <ButtonIcon primaryColor="rgb(55, 159, 142)" />
  },
  STEP_AWAY: {
    BUTTON_TEXT: "Step Away",
    PRIMARY_COLOR: "rgb(55, 159, 142)",
    SECONDARY_COLOR: "rgba(55, 159, 142`, 0.1)",
    BUTTON_ICON: <ButtonIcon primaryColor="rgb(55, 159, 142)" />
  },
  CONFIRM: {
    BUTTON_TEXT: "Confirm",
    PRIMARY_COLOR: "rgba(49, 130, 206, 1)",
    SECONDARY_COLOR: "rgba(255, 255, 255, 1)"
  }
};
export default CONSTANTS;

function ButtonIcon({ primaryColor }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H8M11.5 8.5L19 1L11.5 8.5ZM14 1H19V6L14 1Z"
        stroke={primaryColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

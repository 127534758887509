import React, { useContext, useEffect, useState } from "react";
import { GlobalDataContext } from "./MealPlanner2";
import SelectItem from "./SelectItem";

const dropdown = {
  Carbohydrates: [
    {
      name: "Lemon Rice",
      conditions: { vegan: 1, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    },
    {
      name: "Fried Rice",
      conditions: { vegan: 0, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    }
  ],
  Fibers: [
    {
      name: "Lemon Rice",
      conditions: { vegan: 1, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    },
    {
      name: "Fried Rice",
      conditions: { vegan: 0, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    },
    {
      name: "Lemon Rice",
      conditions: { vegan: 1, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    }
  ]
};

export default function Rows({ day, ind }) {
  const { tableState, setTableState } = useContext(GlobalDataContext);
  return (
    <div>
      {tableState[day][ind].rows.map((val, index) => {
        return <Row day={day} ind={ind} key={index} index={index} />;
      })}
    </div>
  );
}

function Row({ day, ind, index }) {
  const { tableState, setTableState, data, setData } = useContext(
    GlobalDataContext
  );

  return (
    <div style={{ borderBottom: "1px solid #E0E0E0" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "12px 0"
        }}
      >
        <SelectItem day={day} ind={ind} index={index} />
        {/* <select
        defaultValue={
          index < tableState[day][ind].rows.length
            ? tableState[day][ind].rows[index].name
            : "Select Item"
        }
      >
        <option value="Select Item" disabled>
          Select Item
        </option>
        {Array.from(Object.keys(dropdown)).map(keyy => {
          return (
            <optgroup label={keyy}>
              {dropdown[keyy].map(meal => {
                return <option>{meal.name}</option>;
              })}
            </optgroup>
          );
        })}
      </select> */}

        <input
          value={tableState[day][ind].rows[index].portion}
          onChange={e => {
            const obj = tableState[day][ind];
            obj.rows[index] = { ...obj.rows[index], portion: e.target.value };
            setTableState({
              ...tableState,
              [day]: [
                ...tableState[day].slice(0, ind),
                { time: obj.time, rows: [...obj.rows] },
                ...tableState[day].slice(ind + 1)
              ]
            });
          }}
          placeholder="Add Portion"
          style={{
            margin: "0 8px",
            padding: "8px 12px",
            border: "1px solid #154166"
          }}
        />

        <input
          value={tableState[day][ind].rows[index].tips}
          onChange={e => {
            const obj = tableState[day][ind];
            obj.rows[index] = { ...obj.rows[index], tips: e.target.value };
            setTableState({
              ...tableState,
              [day]: [
                ...tableState[day].slice(0, ind),
                { time: obj.time, rows: [...obj.rows] },
                ...tableState[day].slice(ind + 1)
              ]
            });
          }}
          style={{ padding: "8px 12px", border: "1px solid #154166" }}
          placeholder="Add Tips"
        />

        <div
          style={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "#ff8c66",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "8px",
            padding: "8px"
          }}
          onClick={() => {
            const obj = tableState[day][ind];
            obj.rows.splice(index, 1);
            console.log("ppp", obj.rows);
            setTableState({
              ...tableState,
              [day]: [
                ...tableState[day].slice(0, ind),
                { time: obj.time, rows: [...obj.rows] },
                ...tableState[day].slice(ind + 1)
              ]
            });
          }}
        >
          DEL
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import Axios from "axios";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4";
import { COLUMNS, UPDATE_COLUMNS } from "./columns";
import CONSTANTS from "./constants";
import dataResource from "../../../constants/dataResource";
import CheckoutDoctor from "./checkout-button";
import CheckinDoctor from "./checkin-doctor";
const DoctorStatusView = () => {
  const [brand, setBrand] = useState("ALL");
  const [rows, setRows] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);

  const getDefaultRow = () => {
    const DOCTOR_STATUS_LIST = dataResource?.DOCTOR_STATUS_LIST || {};
    return {
      [DOCTOR_STATUS_LIST.ACTIVE]: 0,
      [DOCTOR_STATUS_LIST.BUSY]: 0,
      [DOCTOR_STATUS_LIST.COOLOFF]: 0,
      [DOCTOR_STATUS_LIST.BREAK]: 0,
      [DOCTOR_STATUS_LIST.OUT_OF_CLINIC]: 0,
      [DOCTOR_STATUS_LIST.STEP_AWAY]: 0,
      [DOCTOR_STATUS_LIST.CHECKED_OUT]: 0
    };
  };
  const prepareRows = data => {
    if (!(data && data.length)) return;
    let filteredDoctors = data;
    if (brand.toUpperCase() !== "ALL")
      filteredDoctors = data.filter(doctor => doctor?.brands?.includes(brand));
    const row = filteredDoctors.reduce((final, doctor) => {
      const { doctorStatus } = doctor;
      if (!final[doctorStatus]) final[doctorStatus] = 0;
      final[doctorStatus] += 1;
      return final;
    }, getDefaultRow());
    setRows([row]);
  };
  const prepareUpdatesRow = data => {
    if (!(data && data.length)) return;
    let filteredUpdates = data;
    if (brand === dataResource?.BRANDS_SHORT_NAME?.MM) {
      filteredUpdates = data.filter(updateData => updateData.isMen);
    } else if (brand === dataResource?.BRANDS_SHORT_NAME?.BW) {
      filteredUpdates = data.filter(updateData => updateData.isWomen);
    }
    for (const row of filteredUpdates) {
      row.checkOut = <CheckoutDoctor doctorId={row.doctorId} />;
      row.checkIn = <CheckinDoctor doctorId={row.doctorId} />;
    }
    setUpdateRows(filteredUpdates);
  };
  const listDoctors = async () => {
    setLoading(true);
    try {
      const response = await Axios.post(
        CONFIG_CONSTANT.url.DOCTOR_LIST_URL_ES,
        {}
      );
      setDoctors(response?.data?.data?.results);
      prepareRows(response?.data?.data?.results);
    } catch (err) {
      console.log("ERROR IN GET AUDIT TRAIL", err);
    } finally {
      setLoading(false);
    }
  };

  const getDoctorLastUpdates = async () => {
    setUpdateLoading(true);
    try {
      const response = await Axios.get(
        CONFIG_CONSTANT.url.GET_DOCTOR_LAST_UPDATES
      );
      setUpdates(response?.data?.data?.updates);
      prepareUpdatesRow(response?.data?.data?.updates);
    } catch (err) {
      console.log("ERROR IN GET DOCTOR LAST UPDATE", err);
    } finally {
      setUpdateLoading(false);
    }
  };
  const onRefresh = () => {
    listDoctors();
    getDoctorLastUpdates();
  };
  useEffect(() => {
    onRefresh();
  }, []);

  useEffect(() => {
    prepareRows(doctors);
    prepareUpdatesRow(updates);
  }, [brand]);

  return (
    <div className={styles["doctor-status-view"]}>
      <div className={styles["header"]}>
        <Button
          variant="primary"
          onClick={onRefresh}
          disabled={loading || updateLoading}
        >
          Refresh
        </Button>
        <div className={styles["brand-option-section"]}>
          <div className={styles["input-label"]}>Brand</div>
          <div className={styles["dropdown-wrapper"]}>
            <select
              className={styles["brand-dropdown"]}
              onChange={evt => setBrand(evt.target.value)}
            >
              {CONSTANTS?.BRANDS?.map(brand => (
                <option
                  key={brand.value}
                  className={styles["brand-option"]}
                  value={brand.value}
                >
                  {brand.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div>
        <Table4
          cols={COLUMNS}
          rows={rows}
          title={CONSTANTS?.STATUS_TABLE?.TITLE}
          pageSize={CONSTANTS?.STATUS_TABLE?.PAGE_SIZE}
        />
      </div>
      <div>
        <Table4
          cols={UPDATE_COLUMNS}
          rows={updateRows}
          title={CONSTANTS?.UPDATE_TABLE?.TITLE}
          pageSize={CONSTANTS?.UPDATE_TABLE?.PAGE_SIZE}
          search={true}
          filtering={true}
        />
      </div>
    </div>
  );
};

export default DoctorStatusView;

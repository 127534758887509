import styled from "styled-components";

export const CurrentStatusDoctor = styled.div`
  background-color: ${props => props?.backgroundColor ?? "white"};
  height: 12px;
  width: 12px;
  border-radius: 10px;
  margin-left: 8px;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.8);
  }
`;

import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";

function SourceView(props) {
  const [fromDate, setFromDate] = useState(
    moment().subtract(2, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(
    moment().add(2, "days").format("YYYY-MM-DD")
  );
  const [brand, setBrand] = useState("all");
  const [showLoader, setShowLoader] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  useEffect(() => {
    getSourceView();
  }, [brand]);
  const getSourceView = async evt => {
    setShowLoader(true);
    const sourceViewRequestData = {
      brand,
      fromDate,
      toDate
    };
    axios
      .post(CONFIG_CONSTANT.url.SOURCE_VIEW, sourceViewRequestData)
      .then(res => {
        console.log("SOURCE VIEW", res.data.result);
        setData(res.data.result);
        // generateColumns();
        generateRows(res.data.result);
        setShowLoader(false);
      })
      .catch(err => {
        setShowLoader(false);
        console.log("ERROR IN /sourceView", err);
      });
  };
  const generateRows = data => {
    const dates = generateColumns();
    const rows = [];
    const totalRow = { source: "Total" };
    for (const source in data) {
      for (const date of dates) {
        if (!totalRow[date]) totalRow[date] = 0;
        if (!data[source][date]) data[source][date] = "-";
        else totalRow[date] += data[source][date];
      }
      const row = { source, ...data[source] };
      rows.push(row);
    }
    rows.push(totalRow);
    setRows(rows);
  };
  const generateColumns = () => {
    const columns = [];
    const dateLimit =
      moment(toDate, "YYYY-MM-DD").diff(
        moment(fromDate, "YYYY-MM-DD"),
        "days"
      ) + 1;
    for (
      let i = moment(fromDate, "YYYY-MM-DD"), j = 1;
      j <= dateLimit;
      ++j, i = i.add(1, "day")
    ) {
      const date = i.format("YYYY-MM-DD");
      columns.push(date);
    }
    const tempColumns = columns.map(col => {
      return {
        title: moment(col, "YYYY-MM-DD").format("MMM D"),
        field: col
        // cellStyle : {border: "1px solid rgb(230,230,230)"}
      };
    });

    const finalColumns = [
      {
        title: "Source",
        field: "source",
        cellStyle: cellData => {
          if (cellData === "Total") return {};
          return {
            backgroundColor: "rgb(240,240,240)",
            fontWeight: "bold"
          };
        }
      },
      ...tempColumns
    ];
    setColumns(finalColumns);
    return columns;
  };
  return (
    <div className="source-view">
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={fromDate}
          onChange={evt => setFromDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={toDate}
          onChange={evt => setToDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={getSourceView}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => setBrand("all")}
          className={
            brand === "all"
              ? `brand-filter-button brand-filter-all active`
              : `brand-filter-button brand-filter-all`
          }
        >
          ALL
        </div>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>

      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <div>
          <Table4
            title="Source View"
            rows={rows}
            cols={columns}
            pageSize={rows.length}
            rowStyle={rowData => {
              return rowData.source === "Total"
                ? {
                    backgroundColor: "rgb(30,30,30)",
                    color: "white",
                    fontWeight: "500",
                    border: "1px solid rgb(50,50,50)"
                  }
                : {};
            }}
            pagin={false}
          />
        </div>
      )}
    </div>
  );
}
export default SourceView;

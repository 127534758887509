import styled from "styled-components";

export const Button = styled.button`
  color: rgba(255, 255, 255, 0.87);
  background-color: rgba(34, 84, 138, 1);
  padding: 5px 8px;
  border-radius: 8px;
  font-size: 12px;
  box-shadow: none;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    padding: 5px;
  }

  &:disabled {
    background-color: #eaeaea;
    cursor: not-allowed;
    color: rgba(160, 160, 160, 1);
  }

  &[data-upload="true"] {
    background-color: rgba(55, 159, 142, 1);
    color: white;
    cursor: default;
  }
`;

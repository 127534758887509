const initialState = null;
const userReducer = (state, action) => {
  if (state === undefined) return null;
  if (action.type === "USER") {
    return action.payload;
  }
  if (action.type === "CLEAR") return null;
  return state;
};
export default userReducer;

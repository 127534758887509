import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table6 from "../../common/Tables/Table6.js";
import { toast } from "react-toastify";
import { appointmentsTodaySummary, appointmentsTodayTable } from "./NewCols.js";

const endStates = ["ni", "ns", "t", "c", "op", "na", "cn", "otc", "rf"];

export default function AppointmentsSummary({
  reqBody: dateRange,
  rowStyle,
  setCountToday,
  setCountPending,
  setCountPriority
}) {
  const [data, setData] = useState({});
  const today = useMemo(() => {
    let today = new Date();
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
    return today;
  }, []);

  const fetchAppointments = reqBody => {
    axios
      .post(config.url.APPOINTMENTS, reqBody)
      .then(res => {
        let { summary, results } = res.data;
        results = results.filter(row => !row.cancelled);
        let todayC = 0,
          pending = 0,
          priority = 0;
        results.forEach(row => {
          if (row.appointment_date === today) todayC++;
          else if (!endStates.includes(row.status)) pending++;

          if (row.request_callback) priority++;
        });
        setCountToday(todayC);
        setCountPending(pending);
        setCountPriority(priority);
        setData({
          appointmentsPendingTable: results,
          pending_summary: [summary]
        });
      })
      .catch(err => {
        if (err.response.status == 400) {
          toast.warn("Date Range should have a maximum range of 3 days");
        }
        console.log(err);
      });
  };
  useEffect(() => {
    fetchAppointments(dateRange);
    const timer = setInterval(() => fetchAppointments(dateRange), 1000 * 30);
    return () => clearInterval(timer);
  }, [dateRange?.from_date, dateRange?.to_date]);

  return data ? (
    <>
      <Table6
        title="ALL"
        pageSize={100}
        cols={appointmentsTodayTable}
        rows={data.appointmentsPendingTable || []}
        rowStyle={rowStyle}
        search={true}
      />
    </>
  ) : null;
}

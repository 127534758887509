import React from "react";
import "./ImageTimelineButton.scss";
import { Modal } from "react-bootstrap";
import ImageTimeline from "../ImageTimeline";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants";

export default class ImageTimelineButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openImages: false
    };
  }
  onOpen = () => {
    this.setState({ openImages: true });
    addToGTM({
      event: GTM_EVENTS?.IMAGE_TIMELINE_VIEWED
    });
  };
  render() {
    return (
      <div>
        <Modal
          show={this.state.openImages}
          onHide={() => this.setState({ openImages: false })}
          centered
          dialogClassName="image-timeline-modal"
        >
          <ImageTimeline
            modalOpen={this.state.openImages}
            closeModal={() => this.setState({ openImages: false })}
          />
        </Modal>
        <button
          className={
            this.props.isHeaderQuickView
              ? "quickview-images-button"
              : "view-images-button"
          }
          onClick={this.onOpen}
        >
          View Images
        </button>
      </div>
    );
  }
}

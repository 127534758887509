import React, { useState, useEffect } from "react";
import searchIcon from "./searchIcon.png";
import { useSelector } from "react-redux";
import "./header-search.scss";
import { store } from "../../../../../reducers/Persist";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import { rxOpenedClevertap } from "../../../../../helpers";
import config from "../../../../../constants/apiList";
import { toast } from "react-toastify";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
import {
  GTM_ENTRY_POINTS,
  GTM_EVENTS
} from "../../../../../constants/tagManager.constants";
import { getRxWriterLink } from "../../../../../helpers/rx-writer.helpers";

const HeaderSearch = () => {
  const [searchInput, setSearchInput] = useState("");
  const { brand, form, id } = useSelector(state => state.rxWriterReducer);
  useEffect(() => {
    setSearchInput(brand + "-" + form + "-" + id);
  }, [brand, form, id]);

  const redirectToRxWriter = () => {
    addToGTM({
      event: GTM_EVENTS?.RX_WRITER_OPENED,
      redirectDetails: {
        entryPoint: GTM_ENTRY_POINTS?.RX_WRITER_OPENED?.RX_WRITER_HEADER_SEARCH
      }
    });
    window.location.href = getRxWriterLink(searchInput);
  };

  return (
    <div className="header-search">
      <input
        className="header-search_input"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            const payload = {
              doctorId: store.getState().userReducer.hash_id,
              source: form,
              id: id,
              brand: brand
            };
            payload["form"] = payload["source"];
            rxOpenedClevertap(payload);
            redirectToRxWriter();
          }
        }}
        placeholder="search..."
      />
      <img
        src={searchIcon}
        alt="searchIcon"
        className="Header_searchIcon"
        onClick={redirectToRxWriter}
      />
    </div>
  );
};

export default HeaderSearch;

import moment from "moment";
import React from "react";
import CONSTANTS from "../NewDoctorDashboard/Overview/constants";

export const audioLogsColumns = [
  {
    title: "Appointment Id",
    field: "appointment_id"
  },
  {
    title: "Doctor Id",
    field: "doctor_id"
  },
  {
    title: "Call Attempt",
    field: "call_attempt",
    filtering: false
  },
  {
    title: "Overall Call Status",
    field: "overall_call_status",
    filtering: false,
    render: rowData => {
      const isConnected = rowData.overall_call_status === "completed";
      return (
        <span
          style={{
            color: isConnected ? "green" : "red"
          }}
        >
          {rowData?.overall_call_status || "-"}
        </span>
      );
    }
  },
  {
    title: "Doctor Leg",
    field: "doctor_leg_status",
    filtering: false,
    render: rowData => {
      const isConnected = rowData.doctor_leg_status === "completed";
      return (
        <span
          style={{
            color: isConnected ? "green" : "red"
          }}
        >
          {rowData?.doctor_leg_status || "-"}
        </span>
      );
    }
  },
  {
    title: "Doctor Ring Duration",
    field: "doctor_ring_duration",
    filter: false
  },
  {
    title: "Patient Leg",
    field: "patient_leg_status",
    filtering: false,
    render: rowData => {
      const isConnected = rowData.patient_leg_status === "completed";
      return (
        <span
          style={{
            color: isConnected ? "green" : "red"
          }}
        >
          {rowData?.patient_leg_status || "-"}
        </span>
      );
    }
  },
  {
    title: "Patient Ring Duration",
    field: "patient_ring_duration",
    filter: false
  },
  {
    title: "Connected Duration",
    field: "connected_duration",
    filtering: false,
    render: rowData => {
      return <span>{rowData?.connected_duration || "-"}</span>;
    }
  },
  {
    title: "Disconnected By",
    field: "disconnected_by",
    filtering: false
  },
  {
    title: "Sid",
    field: "sid",
    filtering: false
  },
  {
    title: "Connected Recording Url",
    field: "connected_recording_url",
    filtering: false,
    render: rowData => {
      if (rowData?.connected_recording_url) {
        return (
          <a
            href={rowData?.connected_recording_url ?? ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            Listen
          </a>
        );
      }
      return <span>-</span>;
    }
  },
  {
    title: "Timestamp",
    field: "created_at",
    filtering: false,
    render: rowData => {
      return (
        <span>
          {moment(new Date(rowData?.created_at))
            .add(330, "minutes")
            .format(CONSTANTS.DATE_FORMATS.DAY_OF_MONTH_DATETIME_FORMAT)}
        </span>
      );
    }
  },
  {
    title: "Patient First",
    field: "patient_first",
    filtering: true
  },
  {
    title: "Patient Action",
    field: "patient_action",
    filtering: true
  }
];

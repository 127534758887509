import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./checkout.scss";
import Axios from "axios";
import { toast } from "react-toastify";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
function CheckoutDoctor({ doctorId }) {
  const checkoutDoctor = async () => {
    try {
      await Axios.post(CONFIG_CONSTANT.url.ADD_TO_COOLOFF, { doctorId });
      toast.success("SUCCESS: Doctor checkedout");
    } catch (err) {
      console.log("ERROR in checkout doctor", err);
      toast.error("ERROR: Could not checkout doctor");
    }
  };
  return (
    <div className="checkout-toggle">
      <Button className="toggler-button" size="sm" onClick={checkoutDoctor}>
        Checkout Doctor
      </Button>
    </div>
  );
}
export default CheckoutDoctor;

import React, { useEffect } from "react";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import axios from "axios";
import "./ReassignModal.scss";
import { Modal } from "react-bootstrap";
import UnauthorizedModal from "./UnauthorizedModal";
import { addToGTM } from "../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../constants/tagManager.constants";
const noop = () => {};

const ReassignModal = ({
  show,
  setShow = noop,
  appointmentId,
  doctorId,
  disableActivity = false
}) => {
  useEffect(() => {
    if (show) {
      if (disableActivity) {
        addToGTM({
          event: GTM_EVENTS?.UNAUTHORIZED_APPOINTMENT_VIEWED
        });
      } else {
        addToGTM({
          event: GTM_EVENTS?.ASSIGN_MODAL_VIEWED
        });
      }
    }
  }, [disableActivity]);
  const onClose = () => {
    setShow(false);
  };
  const onReassign = () => {
    onClose();
    const data = {
      appointment_id: appointmentId,
      reassign_doctor_id: doctorId
    };
    const link = CONFIG_CONSTANTS.url.REASSIGN_DOCTOR;
    axios.post(link, data).then(resp => {
      addToGTM({
        event: GTM_EVENTS?.REASSIGNED_APPOINTMENT
      });
      window.forceReloadWithoutChecking = true;
      window.location.reload();
    });
  };
  if (disableActivity) {
    return <UnauthorizedModal show={show} />;
  }
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      dialogClassName="reassign-modal"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            fontSize: "30px",
            fontWeight: "600",
            margin: "5px 100px 10px 10px"
          }}
        >
          Reassign Appointment
        </div>
        <img
          loading="lazy"
          src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
          alt="close"
          onClick={onClose}
          style={{
            width: "30px",
            height: "30px",
            cursor: "pointer"
          }}
        />
      </div>
      <div
        style={{
          margin: "0 10px",
          opacity: "0.5",
          marginBottom: "20px",
          fontSize: "20px"
        }}
      >
        Do you want to reassign this appointment to yourself?
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <button
          style={{
            width: "48%",
            border: "none",
            outline: "none",
            borderRadius: "12px",
            padding: "10px",
            fontWeight: "600",
            color: "#6C6C6C",
            backgroundColor: "#F8F8F8"
          }}
          onClick={onClose}
        >
          No
        </button>
        <button
          style={{
            width: "48%",
            border: "none",
            outline: "none",
            borderRadius: "12px",
            padding: "10px",
            color: "white",
            fontWeight: "600",
            backgroundColor: "#379F8E"
          }}
          onClick={onReassign}
        >
          Assign To Me
        </button>
      </div>
    </Modal>
  );
};

export default ReassignModal;

import React from "react";
import { Modal } from "react-bootstrap";
import History from "../History";
import axios from "axios";
import config from "../../../../../constants/apiList";
import Loader from "react-loader-spinner";
import "./GeneralHistoryReport.scss";
import { store } from "../../../../../reducers/Persist";
import { connect } from "react-redux";
class GeneralHistoryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openHistory: false,
      loading: false,
      history: []
    };
  }
  handleViewHistoryClick = () => {
    this.setState({
      openHistory: true
    });
    if (this.state.history.length === 0) {
      //Call API to get history
      this.setState({ loading: true });
      const getHistoryRequestData = {
        brand: this.props.brand,
        doctorId: this.props.doctorId,
        email: this.props.email
      };
      axios
        .post(config.url.GET_HISTORY, getHistoryRequestData)
        .then(res => {
          console.log(res.data.body.data);
          const sortedEvents = res.data.body.data.sort((a, b) => {
            return new Date(b.created_dttm) - new Date(a.created_dttm);
          });
          this.setState({ history: sortedEvents, loading: false });
        })
        .catch(err => {
          console.log("ERROR IN GET HISTORY", err);
          this.setState({ loading: false });
        });
    }
  };
  render() {
    console.log(this.state.history);
    return (
      <div className="general-history-report">
        <Modal
          show={this.state.openHistory}
          onHide={() => this.setState({ openHistory: false, loading: false })}
          centered
          dialogClassName="general-history-report-modal"
        >
          <div className="header-row">
            <div className="modal-title">General History</div>
            <img
              src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
              alt="close"
              onClick={() =>
                this.setState({ openHistory: false, loading: false })
              }
              className="close-button"
            />
          </div>
          {this.state.loading ? (
            <div className="loader-container">
              <Loader type="TailSpin" />
            </div>
          ) : (
            <History
              history={this.state.history}
              doctorId={this.props.doctorId}
              brand={this.props.brand}
            />
          )}
        </Modal>
        <button
          className="view-history-button"
          onClick={() => this.handleViewHistoryClick()}
        >
          View History
        </button>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    email: store.getState().rxWriterReducer.prescriptionData.email,
    doctorId: store.getState().userReducer.hash_id,
    brand: store.getState().rxWriterReducer.brand
  };
};
export default connect(mapStateToProps)(GeneralHistoryReport);

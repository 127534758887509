const COLUMNS = [
  {
    title: "Doctor Id",
    field: "doctorId",
    customFilterAndSearch: (term, rowData) => term == rowData.doctorId
  },
  {
    title: "Event",
    field: "eventName"
  },
  { title: "Event Data", field: "eventData" },
  { title: "Appointment ID", field: "appointmentReference" },
  { title: "Timestamp", field: "createdAt" }
];

export default COLUMNS;

import React, { useState } from "react";
import {
  MenuContainer,
  LeftArrow,
  Menu,
  MenuItemsContainer,
  MenuItemWrapper,
  ButtonItemsContainer,
  ButtonItemWrapper,
  MenuFixedElement
} from "./styles";
import { doctorMenuList, buttonList } from "./doctor.menu.data";
import { useHistory } from "react-router-dom";
import noop from "lodash/noop";
import StatusDisplay from "../NewDoctorDashboard/StatusDisplay";
import StepAwayOptions from "../NewDoctorDashboard/StepAwayOptions";
import StepAwayTimer from "../NewDoctorDashboard/StepAwayTimer";
import StepInModal from "../StepInModal";
import { playNotification } from "../../../helpers";
import dataResource from "../../../constants/dataResource";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import { toast } from "react-toastify";
import { store } from "../../../reducers/Persist";
import Axios from "axios";
import LeaveButton from "../LeaveButton";

export default function DoctorCollapsibleMenu({
  page = "",
  doctorStatus,
  doctorConfig = {},
  checkoutOnClick = noop,
  logoutOnClick = noop,
  stepAwayAvailable,
  stepAwayTimeLeft = 0,
  durationOptions = [],
  attemptsLeft = 0
}) {
  const [isCollapse, setIsCollapse] = useState(false);
  const [showStepInNotification, setShowStepInNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOnLeftArrowClick = () => setIsCollapse(!isCollapse);
  const history = useHistory();
  const isDoctorOoc =
    doctorStatus === dataResource?.DOCTOR_STATUS_LIST?.OUT_OF_CLINIC;

  const handleOnClick = eventName => {
    switch (eventName) {
      case "checkout":
        checkoutOnClick();
        break;
      case "logout":
        logoutOnClick();
        break;
      default:
        noop();
    }
  };

  const triggerNotification = val => {
    setShowStepInNotification(val);
    playNotification();
  };
  const updateStepAway = async (stepAway, duration = 0) => {
    setLoading(true);
    try {
      if (isDoctorOoc) {
        return toast.warn(
          `${
            store?.getState()?.userReducer?.doctor_name || "doctor"
          } is currently out of clinic`
        );
      }
      const requestBody = { stepAway, duration };
      const response = await Axios.post(
        CONFIG_CONSTANTS?.url?.UPDATE_STEP_AWAY,
        requestBody
      );
      setTimeout(() => {
        history.go(0);
        setLoading(false);
      }, 2000);
      if (response.status === 205) {
        toast.warn(response?.data?.message);
      }
      toast.warn(response?.data?.message);
    } catch (err) {
      console.log("ERROR IN updateStepAway", err);
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  return (
    <MenuContainer>
      <Menu data-collapse={isCollapse}>
        <MenuFixedElement>
          <LeftArrow
            onClick={handleOnLeftArrowClick}
            data-collapse={isCollapse}
          />
          <MenuItemsContainer>
            {doctorMenuList.map((item, index) => {
              if (
                !doctorConfig.standby &&
                item.solidIncludesCheck === "pendingAppointments"
              )
                return null;
              return (
                <MenuItem
                  isCollapse={isCollapse}
                  itemName={item.itemName}
                  itemLink={item.itemLink}
                  key={index}
                  lightIcon={item.lightIcon}
                  solidIcon={item.solidIcon}
                  solidIncludesCheck={item.solidIncludesCheck}
                  page={page}
                />
              );
            })}
          </MenuItemsContainer>
          {!isCollapse && (
            <ButtonItemsContainer>
              <StatusDisplay doctorStatus={doctorStatus} />
              <LeaveButton
                availableLeaves={
                  store?.getState()?.userReducer?.available_leaves
                }
              />
              <StepAwayTimer
                stepAwayTimeLeft={stepAwayTimeLeft}
                triggerNotification={triggerNotification}
              />
              <StepAwayOptions
                doctorStatus={doctorStatus}
                stepAwayAvailable={stepAwayAvailable}
                durationOptions={durationOptions}
                attemptsLeft={attemptsLeft}
                loading={loading}
                updateStepAway={updateStepAway}
              />
              {showStepInNotification ? (
                <StepInModal
                  onStepInClick={() => updateStepAway(false)}
                  isLoading={loading}
                  onClose={() => setShowStepInNotification(false)}
                />
              ) : null}
              {buttonList.map((item, index) => {
                return (
                  <ButtonItem
                    onClick={handleOnClick}
                    buttonIcon={item.buttonIcon}
                    buttonText={item.buttonText}
                    primaryColor={item.primaryColor}
                    secondaryColor={item.secondaryColor}
                    onClickFunctionName={item.onClickFunctionName}
                    key={index}
                  />
                );
              })}
            </ButtonItemsContainer>
          )}
        </MenuFixedElement>
      </Menu>
    </MenuContainer>
  );
}

function MenuItem({
  isCollapse,
  itemName,
  itemLink,
  lightIcon,
  solidIcon,
  solidIncludesCheck,
  page = ""
}) {
  const history = useHistory();

  const handleOnClick = () => {
    history.push(itemLink);
  };

  const isSelected = React.useMemo(() => {
    if (!page && !solidIncludesCheck) return true;
    if (page && !solidIncludesCheck) return false;
    return page?.includes(solidIncludesCheck) ?? false;
  }, [page]);
  return (
    <MenuItemWrapper
      data-collapse={isCollapse}
      onClick={handleOnClick}
      data-selected={isSelected}
    >
      {isSelected ? solidIcon : lightIcon}
      <span>{itemName}</span>
    </MenuItemWrapper>
  );
}

function ButtonItem({
  onClick = noop,
  onClickFunctionName = "",
  buttonIcon,
  buttonText,
  primaryColor,
  secondaryColor
}) {
  return (
    <ButtonItemWrapper
      onClick={() => onClick(onClickFunctionName)}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    >
      {buttonIcon}
      <span>{buttonText}</span>
    </ButtonItemWrapper>
  );
}

import React from "react";
import "./NewProduct.scss";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";

class NewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      quantity: "",
      frequency: "",
      instructions: "",
      days: "",
      editState: true
    };
  }

  componentDidMount() {
    // this.initialProduct = {
    //   ...this.props.productItem
    // };
  }

  handleOnCancel = e => {
    e.preventDefault();
    this.props.handleOnCancelNewProduct();
  };
  handleOnAdd = e => {
    e.preventDefault();
    if (
      this.state.productName == "" ||
      !!this.state.quantity == "" ||
      !!this.state.frequency == "" ||
      !!this.state.instructions == "" ||
      !!this.state.days == ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    // TODO Add the product in the redux
    this.props.handleAddNewProduct({
      productName: this.state.productName,
      quantity: this.state.quantity,
      frequency: this.state.frequency,
      instructions: this.state.instructions,
      days: this.state.days
    });
  };
  render() {
    return (
      <div className="otherProducts__product_container">
        <div className="OP_product__container_item">
          {this.state.editState ? (
            <input
              value={this.state.productName}
              onChange={e => {
                this.setState({ ...this.state, productName: e.target.value });
              }}
              placeholder="Product Name"
            />
          ) : (
            <p className="item__title">{this.state.productName}</p>
          )}

          {this.state.editState ? (
            <input
              value={this.state.quantity}
              placeholder="Quantity"
              onChange={e => {
                this.setState({ ...this.state, quantity: e.target.value });
              }}
            />
          ) : (
            <p>{this.state.quantity}</p>
          )}

          {this.state.editState ? (
            <input
              value={this.state.frequency}
              placeholder="Frequency"
              onChange={e => {
                this.setState({ ...this.state, frequency: e.target.value });
              }}
            />
          ) : (
            <p>{this.state.frequency}</p>
          )}
        </div>
        <div className="product__days">
          {this.state.editState ? (
            <input
              value={this.state.days}
              placeholder="Days..."
              className="product__days_input"
              onChange={e => {
                this.setState({ ...this.state, days: e.target.value });
              }}
            />
          ) : (
            <p>{this.state.days}</p>
          )}
        </div>

        <div className="product__instructions">
          <div className="product__instructions_left">
            {this.state.editState ? (
              <input
                placeholder="Instructions..."
                value={this.state.instructions}
                onChange={e => {
                  this.setState({
                    ...this.state,
                    instructions: e.target.value
                  });
                }}
                className="product__instructions_input"
              />
            ) : (
              <p>{this.state.instructions}</p>
            )}
          </div>
          <div className="product__instructions_right">
            <button
              className="product__instructions_right_correct"
              onClick={this.handleOnAdd}
            >
              <CheckIcon color="green" />
            </button>
            <button
              className="product__instructions_right_cancel"
              onClick={this.handleOnCancel}
            >
              <ClearIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default NewProduct;

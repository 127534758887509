import Axios from "axios";
import React, { useState } from "react";
import ColRow from "./ColRow";
import Table from "./Table";
import PrescriptionViewer from "../PrescriptionViewer/PrescriptionViewer";
import PrescriptionEditor from "../PrescriptionEditor/PrescriptionEditor";
const days1 = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun"
};
const reverseDays1 = { Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6, Sun: 7 };
const days2 = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
};
const reverseDays2 = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7
};

const mealType = {
  1: "Early Morning",
  2: "Breakfast",
  3: "Mid Meal",
  4: "Lunch",
  5: "Snacks",
  6: "Dinner",
  7: "Mid Meal 2",
  8: "Snacks 2",
  9: "Pre-Workout",
  10: "Post-Workout",
  11: "Intra-Workout",
  12: "Bed-Time"
};
const mealType2 = {
  "Early Morning": {
    key: 1,
    dafaultTime: "07:00"
  },
  Breakfast: {
    key: 2,
    dafaultTime: "09:00"
  },
  "Mid Meal": {
    key: 3,
    dafaultTime: "11:00"
  },
  Lunch: {
    key: 4,
    dafaultTime: "13:00"
  },
  Snacks: {
    key: 5,
    dafaultTime: "15:00"
  },
  Dinner: {
    key: 6,
    dafaultTime: "20:00"
  },
  "Mid Meal 2": {
    key: 7,
    dafaultTime: "11:00"
  },
  "Snacks 2": {
    key: 8,
    dafaultTime: "15:00"
  },
  "Pre-Workout": {
    key: 9,
    dafaultTime: "15:00"
  },
  "Post-Workout": {
    key: 10,
    dafaultTime: "15:00"
  },
  "Intra-Workout": {
    key: 11,
    dafaultTime: "15:00"
  },
  "Bed-Time": {
    key: 12,
    dafaultTime: "21:00"
  }
};

export const GlobalDataContext = React.createContext();

const headerArr = [
  "Meal Type",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

export default function MealPlanner2({
  col,
  setCol,
  tableState,
  setTableState,
  data,
  setData,
  header,
  setHeader,

  orderPlaced,
  recommended,
  setPrescriptionID,
  userInfo,
  prescriptionsList,
  deleteElement,

  handleMove,
  editElement,
  complaints,
  history,
  diagnosis,
  instructions,
  description,
  recommendationChange,
  isEdit,
  form,
  userId,
  brand,
  prescription_order_ids,

  productData,
  getDetails,
  editItem
}) {
  const addRow = () => {
    setTableState({
      "Meal Type": [...tableState["Meal Type"], "Dinner"],
      Monday: [
        ...tableState["Monday"],
        {
          rows: [{ item: "", portion: "", tips: "" }],
          time: mealType2["Dinner"].dafaultTime
        }
      ],
      Tuesday: [
        ...tableState["Tuesday"],
        {
          rows: [{ item: "", portion: "", tips: "" }],
          time: mealType2["Dinner"].dafaultTime
        }
      ],
      Wednesday: [
        ...tableState["Wednesday"],
        {
          rows: [{ item: "", portion: "", tips: "" }],
          time: mealType2["Dinner"].dafaultTime
        }
      ],
      Thursday: [
        ...tableState["Thursday"],
        {
          rows: [{ item: "", portion: "", tips: "" }],
          time: mealType2["Dinner"].dafaultTime
        }
      ],
      Friday: [
        ...tableState["Friday"],
        {
          rows: [{ item: "", portion: "", tips: "" }],
          time: mealType2["Dinner"].dafaultTime
        }
      ],
      Saturday: [
        ...tableState["Saturday"],
        {
          rows: [{ item: "", portion: "", tips: "" }],
          time: mealType2["Dinner"].dafaultTime
        }
      ],
      Sunday: [
        ...tableState["Sunday"],
        {
          rows: [{ item: "", portion: "", tips: "" }],
          time: mealType2["Dinner"].dafaultTime
        }
      ]
    });
  };

  return (
    <GlobalDataContext.Provider
      value={{
        data,
        setData,
        tableState,
        setTableState,
        col,

        productData,
        getDetails,
        editItem,
        orderPlaced,
        recommended,
        setPrescriptionID,
        userInfo,
        prescriptionsList,
        deleteElement,
        handleMove,
        editElement,
        complaints,
        history,
        diagnosis,
        instructions,
        description,
        recommendationChange,
        isEdit,
        form,
        userId,
        brand,
        prescription_order_ids
      }}
    >
      <div className="mealinfo-container">
        <div className="mealinfo">
          <PrescriptionEditor
            productData={productData}
            getDetails={getDetails}
            editItem={editItem}
            brand={brand}
          ></PrescriptionEditor>
        </div>
        <div className="mealinfo">
          <PrescriptionViewer
            // ref={instance => {
            //   this.child = instance;
            // }}
            type_category={"weight"}
            orderPlaced={orderPlaced}
            recommended={recommended}
            setPrescriptionID={setPrescriptionID}
            userInfo={userInfo}
            prescriptionsList={prescriptionsList}
            deleteElement={deleteElement}
            handleMove={handleMove}
            editElement={editElement}
            complaints={complaints}
            history={history}
            diagnosis={diagnosis}
            instructions={instructions}
            description={description}
            recommendationChange={recommendationChange}
            isEdit={isEdit}
            form={form}
            userId={userId}
            brand={brand}
            type_text={"normal_form"}
            prescription_order_ids={prescription_order_ids}
          ></PrescriptionViewer>
        </div>
      </div>
      <div>
        <ColRow col={col} setCol={setCol} setHeader={setHeader} />
        <Table header={header} />
        <div style={{ backgroundColor: "white", padding: "12px 0 12px 8px" }}>
          <div
            onClick={addRow}
            style={{
              color: "white",
              cursor: "pointer",
              backgroundColor: "#154166",
              padding: "5px 15px",
              display: "inline-flex",
              fontWeight: "400",
              borderRadius: "4px"
            }}
          >
            Add New Meal Type
          </div>
        </div>
      </div>
      {/* <div
        style={{
          color: "white",
          cursor: "pointer",
          margin: "20px 0",
          backgroundColor: "#154166",
          padding: "5px 15px",
          display: "inline-flex"
        }}
        onClick={() => {
          const mealPlan = tableState;
          for (let day in mealPlan) {
            if (day === "Meal Type") continue;

            for (let key in mealPlan[day]) {
              mealPlan[day][key].rows = mealPlan[day][key].rows.filter(row => {
                if (row.item === "") return false;
                return true;
              });
            }
          }

          const reqBody = {
            form: "F",
            id: "1502",
            brand,
            mealPlan
          };

          Axios.post(
            "https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/createMealPlan",
            reqBody
          )
            .then(res => console.log(res))
            .catch(err => console.log(err));
        }}
      >
        Test Submit
      </div> */}
    </GlobalDataContext.Provider>
  );
}

import MaterialTable from "material-table";
import React, { useMemo } from "react";

export default function Table6({
  title,
  cols,
  rows,
  pageSize,
  rowStyle,
  pagin,
  search,
  filtering
}) {
  return (
    <div id={title.replace(/\s/g, "").toLowerCase()}>
      <MaterialTable
        columns={cols}
        data={useMemo(() => rows, [rows])}
        title={title}
        options={{
          rowStyle: rowStyle ? rowStyle : null,
          pageSize: pageSize ? pageSize : 1,
          paging: pagin === undefined ? true : false,
          search: search ? true : false,
          headerStyle: {
            borderBottom: "1px solid #E5E5E5",
            fontWeight: "700",
            fontSize: "16px"
          },
          filtering: filtering ? true : false
        }}
        style={{ margin: "20px 10px 10px" }}
      />
    </div>
  );
}

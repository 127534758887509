import React, { useEffect, useRef, useState } from "react";
import { DateGroup } from "../DashboardPage2";
import axios from "axios";
import config from "../../../../../constants/apiList";
import { useSelector } from "react-redux";
import {
  AuditLogsViewContainer,
  DropdownContainer,
  HeaderContainer,
  TableTitle
} from "./styles";
import { Select, MenuItem } from "@material-ui/core";
import dataResource from "../../../../../constants/dataResource";
import MaterialTable from "material-table";
import { CALL_SOCKET_EMITTER_STATUS } from "../../../../../constants/call-status.socket-emitter-status";
import { useMemo } from "react";
import moment from "moment";

const CancelToken = axios.CancelToken;
let cancel;
const BRANDS = dataResource.BRANDS_SHORT_NAME;
const BRANDS_FULL_NAME = dataResource.BRANDS_SHORT_TO_FULL_NAME;
const STATUS_LIST = dataResource.statusListObj;

const columns = [
  {
    title: "Updated At",
    render: rowData => (
      <span>
        {moment(rowData.updatedAt, "YYYY-MM-DD HH:mm:ss").format(
          "Do MMM YYYY hh:mm A"
        )}
      </span>
    )
  },
  {
    title: "Appointment Id",
    render: rowData => {
      return (
        <span>{`${rowData.brand}-${rowData.source}-${rowData.appointmentId}`}</span>
      );
    }
  },
  {
    title: "Call Status",
    render: rowData => <CallStatusIndicator status={rowData.callStatus} />
  },
  {
    title: "Appointment Status",
    render: rowData => {
      const appointmentStatusText = STATUS_LIST[rowData.status || ""] ?? "-";

      return (
        <span
          style={{
            fontWeight: "700",
            opacity: "0.8"
          }}
        >
          {appointmentStatusText}
        </span>
      );
    }
  }
  // {
  //   title: "Short Call",
  //   render: rowData => {
  //     let isShortCallText = "No";
  //     if (!rowData.shortCall) {
  //       isShortCallText = "-";
  //     } else {
  //       isShortCallText = "Yes";
  //     }

  //     return <span>{isShortCallText}</span>;
  //   }
  // }
];

export default function AuditLogsView({ reqRange }) {
  const [data, setData] = useState([]);
  const doctorId = useSelector(state => state?.userReducer?.doctor_id);
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState(BRANDS.BW);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, []);

  useEffect(() => {
    if (!doctorId) return;
    setLoading(true);
    axios
      .post(
        config.url.DOCTOR_VIEW_AUDIT_LOGS,
        {
          doctorId,
          fromDateTime: `${reqRange.startDate} 00:00:00`,
          toDateTime: `${reqRange.endDate} 23:59:59`,
          brand
        },
        {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          })
        }
      )
      .then(response => setData(response.data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [reqRange, brand]);
  return (
    <AuditLogsViewContainer>
      <HeaderContainer>
        <DateGroup />
        <DropdownContainer>
          <span>Brand</span>
          <AuditLogDropdown brand={brand} setBrand={setBrand} />
        </DropdownContainer>
      </HeaderContainer>
      <MaterialTable
        title={<TableTitle>Audit Logs</TableTitle>}
        columns={columns}
        data={data || []}
        page={10}
        isLoading={loading}
        options={{
          sorting: false,
          draggable: false,
          pageSize: 10,
          headerStyle: {
            backgroundColor: "rgba(34, 84, 138, 1)",
            color: "white",
            fontSize: "16px",
            border: "none"
          },

          rowStyle: {
            backgroundColor: "white",
            width: "min-content",
            padding: "20px",
            borderRadius: "20px"
          },
          cellStyle: {
            fontSize: matches ? "13px" : "15px",
            borderBottom: "5px solid rgba(246, 248, 251, 1)",
            backgroundColor: "white",
            color: "#6C6C6C"
          }
        }}
        style={{
          margin: "10px",
          padding: "10px",
          marginTop: "0px",
          paddingTop: "0px",
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: "40px"
        }}
      />
    </AuditLogsViewContainer>
  );
}

function AuditLogDropdown({ brand, setBrand }) {
  return (
    <DropdownContainer>
      <Select
        value={brand}
        label="Brand"
        onChange={e => setBrand(e.target.value)}
      >
        {Object.keys(BRANDS).map(key => {
          return (
            <MenuItem key={key} value={BRANDS[key]}>
              {BRANDS_FULL_NAME[key]}
            </MenuItem>
          );
        })}
      </Select>
    </DropdownContainer>
  );
}

function CallStatusIndicator({ status }) {
  const statusValue = useMemo(() => {
    switch (status) {
      case CALL_SOCKET_EMITTER_STATUS.CONNECTED:
        return {
          containerBackgroundColor: "#379F8E1F",
          indicatorTextColor: "#379F8E",
          indicatorText: "Connected"
        };

      case CALL_SOCKET_EMITTER_STATUS.PATIENT_MISSED:
        return {
          containerBackgroundColor: "#D773251F",
          indicatorTextColor: "#D77325",
          indicatorText: "Patient Missed"
        };

      case CALL_SOCKET_EMITTER_STATUS.DOCTOR_MISSED:
        return {
          containerBackgroundColor: "#E866691F",
          indicatorTextColor: "#E86669",
          indicatorText: "Doctor Missed"
        };

      case CALL_SOCKET_EMITTER_STATUS.IN_ATTEMPT:
        return {
          containerBackgroundColor: "#E5E5E5",
          indicatorTextColor: "#000",
          indicatorText: "In Progress"
        };
      default:
        return {
          containerBackgroundColor: "#E5E5E5",
          indicatorTextColor: "#000",
          indicatorText: "-"
        };
    }
  }, [status]);
  return (
    <span
      style={{
        color: statusValue.indicatorTextColor,
        fontWeight: "700",
        opacity: "0.8"
      }}
    >
      {statusValue.indicatorText}
    </span>
  );
}

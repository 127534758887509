import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const NewCommp = ({
  page,
  setFlag,
  countToday,
  countPending,
  countPriority
}) => {
  const handler = () => setFlag(true);
  return (
    <div
      style={{
        display: "flex",
        height: "48px",
        padding: "5px",
        marginBottom: "5px"
      }}
    >
      <div className="appointment-summary__menu-item" onClick={handler}>
        <Link
          className={
            page === "appointments/summary"
              ? "appointment-summary__menu--active"
              : "appointment-summary__menu--active2"
          }
          to="/dashboard/appointments/summary"
        >
          Summary
        </Link>
      </div>
      <div className="appointment-summary__menu-item" onClick={handler}>
        <Link
          className={
            page === "appointments/pending"
              ? "appointment-summary__menu--active"
              : "appointment-summary__menu--active2"
          }
          to="/dashboard/appointments/pending"
        >
          Pending
          {countPending === undefined ? null : " (" + countPending + ") "}
        </Link>
      </div>
      <div className="appointment-summary__menu-item" onClick={handler}>
        <Link
          className={
            page === "appointments/priority"
              ? "appointment-summary__menu--active"
              : "appointment-summary__menu--active2"
          }
          to="/dashboard/appointments/priority"
        >
          Priority
          {countPriority === undefined ? null : " (" + countPriority + ") "}
        </Link>
      </div>
    </div>
  );
};

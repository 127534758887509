import React, { Component } from "react";
import "./PlanTreatment.scss";
import { Card, CardHeader } from "@material-ui/core";

export default class Confirmation extends Component {
  render() {
    return (
      <div className="margin-30 PlanTreatment">
        <CardHeader title="Appoint Detail Card" />
      </div>
    );
  }
}

import moment from "moment";
const initialState = {
  dummyProduct: false,
  prescriptionData: {},
  patientDetails: {},
  bwProducts: {},
  presetData: {},
  loggedInDr: {},
  id: null,
  brand: "",
  form: "",
  pdfLoading: true,
  call_duration: null,
  isSpecialistConsultRecommended: false,
  isSuggestDiagnosticsRecommended: false,
  isSuggestProcedureRecommended: false,
  patientDetailEditable: false,
  presetIdToObj: {},
  followup: {
    followupDurationValue: "30",
    followupDurationUnit: "DAYS"
  },
  patient_medical_history: [],
  showUpdateStatusModal: false,
  showUpdateStatusLink: false,
  prescriptionId: "",
  language: "",
  lastGenerateReportPayload: {},
  recommendedProducts: [],
  restrictedProducts: []
};
const rxWriterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_LAST_GENERATE_REPORT_PAYLOAD":
      return {
        ...state,
        lastGenerateReportPayload: action.payload
      };
    case "CHANGE_RECOMMENDED_RESTRICTED_PROUDCTS":
      return {
        ...state,
        recommendedProducts: action.payload.recommendedProducts,
        restrictedProducts: action.payload.restrictedProducts
      };
    case "GET_PRODUCTS":
      return {
        ...state,
        get_products: { ...action.payload }
      };
    case "CHANGE_CALL_DURATION":
      return {
        ...state,
        call_duration: action.payload
      };
    case "CHANGE_LANGUAGE":
      return {
        ...state,
        language: action.payload
      };
    case "SET_PRESCRIPTION_ID":
      return {
        ...state,
        prescriptionId: action.payload
      };
    case "EDIT_IS_FIELD_RECOMMENDED":
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    case "CHANGE_MARITAL_STATUS":
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          marital_status: action.payload.marital_status
        }
      };
    case "SET_UPDATE_STATUS_MODAL":
      return {
        ...state,
        showUpdateStatusModal: action.payload
      };
    case "SET_UPDATE_STATUS_LINK":
      return {
        ...state,
        showUpdateStatusLink: action.payload
      };
    case "SET_DUMMY_PRODUCT":
      return { ...state, dummyProduct: action.payload };
    case "ADD_PATIENT_MEDICAL_HISTORY":
      return { ...state, patient_medical_history: action.data };
    case "PATIENT_DETAIL_EDITABLE":
      return { ...state, patientDetailEditable: action.data };
    case "PRESCRIPTION_PDF_LOADING_TRUE":
      return { ...state, pdfLoading: true };
    case "PRESCRIPTION_PDF_LOADING_FALSE":
      return { ...state, pdfLoading: false };
    case "CHANGE_PRESCRIPTION_DATA":
      return {
        ...state,
        prescriptionData: action.data,
        language: action.data.language
      };
    case "CHANGE_BODYWISE_DATA":
      return { ...state, bwProducts: action.data };
    case "CHANGE_PRESET_DATA":
      return { ...state, presetData: action.data };
    case "CHANGE_BRAND":
      return { ...state, brand: action.data };
    case "CHANGE_FORM":
      return { ...state, form: action.data };
    case "CHANGE_ID":
      return { ...state, id: action.data };
    case "CHANGE_PATIENT_DETAILS":
      return { ...state, patientDetails: action.data };
    case "CHANGE_FOLLOWUP_DATA":
      return {
        ...state,
        followup: { ...state.followup, [action.key]: action.data }
      };
    case "CHANGE_LOGGEDIN_DR":
      return { ...state, loggedInDr: action.data };

    case "CHANGE_PRESET_ID_TO_OBJ":
      return {
        ...state,
        presetIdToObj: action.data
      };
    case "CHANGE_PATIENT_DETAILS_DATA":
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          [action.key]: action.data
        }
      };
    case "ADD_REMARK":
      const date = new Date();
      const newDate = new Date(date.setMonth(date.getMonth() + 1));
      var dateStr = moment()
        .subtract(5.5, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      let newActivity = {
        updated_dttm: dateStr,
        remarks: action.data.remarks,
        status: action.data.status
      };
      return {
        ...state,
        prescriptionData: {
          ...state.prescriptionData,
          remarks: [...state.prescriptionData.remarks, newActivity]
        }
      };
    default:
      return { ...state };
  }
};
export default rxWriterReducer;

import moment from "moment";
import dataResource from "../../../../constants/dataResource";

export const formatTime = (time, fromFormat, toFormat) => {
  return moment(time, fromFormat).format(toFormat);
};

export function convertActiveHoursTime(time) {
  return formatTime(
    time,
    dataResource.DATE_FORMATS.LONG_TIME_FORMAT,
    dataResource.DATE_FORMATS.SHORT_TIME_FORMAT
  );
}

export function convertOOCTime(time) {
  return formatTime(
    time,
    dataResource.DATE_FORMATS.DEFAULT_DATETIME_FORMAT,
    dataResource.DATE_FORMATS.DAY_OF_MONTH_DATETIME_FORMAT
  );
}

export function convertLogsTime(timeStamp) {
  return formatTime(
    timeStamp,
    dataResource.DATE_FORMATS.DEFAULT_DATETIME_FORMAT,
    dataResource.DATE_FORMATS.LONG_TIME_FORMAT
  );
}

export function getLogsTimeDifference(currentTime, previousTime) {
  const currentMoment = moment(
    currentTime,
    dataResource.DATE_FORMATS.LONG_TIME_FORMAT
  );
  const previousMoment = moment(
    previousTime,
    dataResource.DATE_FORMATS.LONG_TIME_FORMAT
  );
  const duration = moment.duration(currentMoment.diff(previousMoment));
  return {
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds()
  };
}

export const convertAPIDate = date => {
  return moment(date).format(dataResource.DATE_FORMATS.SHORT_DATE_FORMAT);
};

import styled from "styled-components";

export const AppointmentAudioLogsContainer = styled.div`
  margin-top: 20px;
`;

export const AppointmentAudioLogsHeader = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-left: 20px;
`;

export const SubmitButton = styled.button`
  width: 80px;
  padding: 5px;
  border-radius: 5px;
  background-color: black;
  color: white;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const InfoTable = styled.table`
  &,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 5px;
  }
`;

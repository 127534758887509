import React, { useMemo, useState } from "react";
import { CALL_SOCKET_EMITTER_STATUS } from "../../../../constants/call-status.socket-emitter-status";
import {
  IndicatorsContainer,
  Indicator,
  IndicatorText,
  Container
} from "./styles";

/**
 * Returns the UI for the current appointment status indicator
 * @param {*} param0
 * @returns
 */
export default function CurrentStatusIndicator({
  status,
  doctorAttempts,
  patientAttempts,
  isInstant
}) {
  const [prevAttempts, setPrevAttempts] = useState({
    doctorAttempts: 0,
    patientAttempts: 0,
    prevColorTextMap: {
      colors: {
        indicator: [],
        containerBackgroundColor: "",
        indicatorTextColor: ""
      },
      indicatorText: "Upcoming"
    }
  });

  const colorTextMap = useMemo(() => {
    const colorTextMapResponse = getColorTextMap(
      status,
      doctorAttempts,
      patientAttempts,
      prevAttempts,
      isInstant
    );
    setPrevAttempts({
      doctorAttempts: parseInt(doctorAttempts),
      patientAttempts: parseInt(patientAttempts),
      prevColorTextMap: { ...colorTextMapResponse }
    });
    return colorTextMapResponse;
  }, [status, doctorAttempts, patientAttempts]);

  return (
    <Container background={colorTextMap.colors.containerBackgroundColor}>
      <IndicatorText color={colorTextMap.colors.indicatorTextColor}>
        {colorTextMap.indicatorText}
      </IndicatorText>
      {/* <IndicatorsContainer>
        <Indicator background={colorTextMap.colors.indicator?.[0]} />
        <Indicator background={colorTextMap.colors.indicator?.[1]} />
        <Indicator background={colorTextMap.colors.indicator?.[2]} />
      </IndicatorsContainer> */}
    </Container>
  );
}

/**
 * Returns the color text map for the current appointment status indicator
 * @param {*} status
 * @param {*} doctorAttempts
 * @param {*} patientAttempts
 * @param {*} prevAttemptsStateValues
 * @returns
 */
const getColorTextMap = (
  status,
  doctorAttempts,
  patientAttempts,
  prevAttemptsStateValues,
  isInstant
) => {
  const colors = {
    indicator: [],
    containerBackgroundColor: "#E5E5E5",
    indicatorTextColor: "#000"
  };

  let indicatorText = "Upcoming";

  switch (status) {
    case CALL_SOCKET_EMITTER_STATUS.CONNECTED:
      colors.containerBackgroundColor = "#379F8E1F";
      colors.indicatorTextColor = "#379F8E";
      indicatorText = "Connected";
      break;

    case CALL_SOCKET_EMITTER_STATUS.PATIENT_MISSED:
      colors.containerBackgroundColor = "#D773251F";
      colors.indicatorTextColor = "#D77325";
      indicatorText = "Patient Missed";
      break;

    case CALL_SOCKET_EMITTER_STATUS.DOCTOR_MISSED:
      colors.containerBackgroundColor = "#E866691F";
      colors.indicatorTextColor = "#E86669";
      indicatorText = "Doctor Missed";
      break;

    case CALL_SOCKET_EMITTER_STATUS.IN_ATTEMPT:
      colors.containerBackgroundColor = "#E5E5E5";
      colors.indicatorTextColor = "#000";
      indicatorText = "In Progress";
      break;
    default:
      colors.containerBackgroundColor = "#E5E5E5";
      colors.indicatorTextColor = "#000";
      indicatorText = isInstant ? "Upcoming" : "-";
  }

  colors.indicator = getIndicatorsColors(
    status,
    doctorAttempts,
    patientAttempts,
    prevAttemptsStateValues.doctorAttempts,
    prevAttemptsStateValues.patientAttempts,
    prevAttemptsStateValues.prevColorTextMap.colors.indicator
  );

  return {
    colors,
    indicatorText
  };
};

const getIndicatorsColors = (
  status,
  doctorAttempts,
  patientAttempts,
  prevDoctorAttempts,
  prevPatientAttempts,
  prevIndicators
) => {
  try {
    return attemptsStatusColorMap(
      status,
      doctorAttempts,
      patientAttempts,
      prevPatientAttempts
    );
  } catch (e) {
    console.warn(e);
    return prevIndicators;
  }
};

const attemptsStatusColorMap = (
  status,
  doctorAttempts,
  patientAttempts,
  previousPatientAttempts
) => {
  const COLORS = {
    UNKNOWN: "#fff",
    PATIENT_MISS: "#D77325",
    DOCTOR_MISS: "#E86669",
    CONNECTED: "#379F8E"
  };
  const colorMap = {
    [CALL_SOCKET_EMITTER_STATUS.CONNECTED]: {
      1: {
        0: [COLORS.CONNECTED],
        1: [COLORS.PATIENT_MISS, COLORS.CONNECTED],
        2: [COLORS.PATIENT_MISS, COLORS.PATIENT_MISS, COLORS.CONNECTED]
      },
      2: {
        0: [COLORS.DOCTOR_MISS, COLORS.CONNECTED],
        1: [COLORS.PATIENT_MISS, COLORS.DOCTOR_MISS, COLORS.CONNECTED],
        A1: [COLORS.DOCTOR_MISS, COLORS.PATIENT_MISS, COLORS.CONNECTED]
      },
      3: {
        0: [COLORS.DOCTOR_MISS, COLORS.DOCTOR_MISS, COLORS.CONNECTED]
      }
    },
    [CALL_SOCKET_EMITTER_STATUS.PATIENT_MISSED]: {
      1: {
        2: [COLORS.PATIENT_MISS, COLORS.PATIENT_MISS, COLORS.PATIENT_MISS]
      },
      2: {
        1: [COLORS.DOCTOR_MISS, COLORS.DOCTOR_MISS, COLORS.PATIENT_MISS],
        A1: [COLORS.DOCTOR_MISS, COLORS.PATIENT_MISS, COLORS.PATIENT_MISS]
      },
      3: {
        0: [COLORS.DOCTOR_MISS, COLORS.DOCTOR_MISS, COLORS.PATIENT_MISS]
      }
    },
    [CALL_SOCKET_EMITTER_STATUS.DOCTOR_MISSED]: {
      1: {
        2: [COLORS.PATIENT_MISS, COLORS.PATIENT_MISS, COLORS.DOCTOR_MISS]
      },
      2: {
        1: [COLORS.PATIENT_MISS, COLORS.DOCTOR_MISS, COLORS.DOCTOR_MISS],
        A1: [COLORS.DOCTOR_MISS, COLORS.PATIENT_MISS, COLORS.DOCTOR_MISS]
      },
      3: {
        0: [COLORS.DOCTOR_MISS, COLORS.DOCTOR_MISS, COLORS.DOCTOR_MISS]
      }
    },
    [CALL_SOCKET_EMITTER_STATUS.IN_ATTEMPT]: {
      1: {
        0: [COLORS.UNKNOWN, COLORS.UNKNOWN, COLORS.UNKNOWN],
        1: [COLORS.PATIENT_MISS, COLORS.UNKNOWN, COLORS.UNKNOWN],
        2: [COLORS.PATIENT_MISS, COLORS.PATIENT_MISS, COLORS.UNKNOWN]
      },
      2: {
        0: [COLORS.DOCTOR_MISS, COLORS.UNKNOWN, COLORS.UNKNOWN],
        1: [COLORS.PATIENT_MISS, COLORS.DOCTOR_MISS, COLORS.UNKNOWN],
        A1: [COLORS.DOCTOR_MISS, COLORS.PATIENT_MISS, COLORS.UNKNOWN]
      },
      3: {
        0: [COLORS.DOCTOR_MISS, COLORS.DOCTOR_MISS, COLORS.UNKNOWN]
      }
    }
  };

  try {
    let _doctorAttempt = parseInt(doctorAttempts);
    let _patientAttempt = parseInt(patientAttempts);
    if (
      _doctorAttempt === 2 &&
      _patientAttempt === 1 &&
      previousPatientAttempts === 0
    ) {
      _patientAttempt = "A1";
    }
    return colorMap[status][parseInt(_doctorAttempt)][
      parseInt(_patientAttempt)
    ];
  } catch (e) {
    return [COLORS.UNKNOWN, COLORS.UNKNOWN, COLORS.UNKNOWN];
  }
};

import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-dropdown-select";
import "./styles.scss";
import { useSelector } from "react-redux";
import { store } from "../../../../../reducers/Persist";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";

const CustomColorCheckbox = withStyles({
  root: {
    color: "#379F8E",
    "&$checked": {
      color: "#379F8E"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);
const Habits = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  var [expanded, setExpanded] = useState(false);
  const habitsCheck = useSelector(
    state => state.observationCheckReducer.habits
  );
  const habitOtherCheck = useSelector(
    state => state.observationCheckReducer.habitOther
  );
  const apptId = useSelector(state => state.rxWriterReducer.id);
  const habitData = useSelector(
    state => state.observationReducer[apptId].lifestyleExisting.metadata.habits
  );

  const otherText = useSelector(
    state =>
      state.observationReducer[apptId].lifestyleExisting.metadata.habitOther
  );
  function showCheckboxes() {
    var checkboxes = document.getElementById("AIHabitscheckboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  }
  useEffect(() => {
    let tempArr = [];
    for (let key in habitData) {
      if (habitData[key]) {
        tempArr.push(key);
      }
    }
    setSelectedItems(tempArr);
  }, [habitData]);
  return (
    <div className="habits">
      {/* <p className="habits_title">Habits</p> */}
      <div className="habits_row">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex" }}>
            <p style={{ fontWeight: "600" }}>Social History</p>
            <p style={{ color: "red", marginLeft: "-15px" }}>*</p>
          </div>
          {habitsCheck && (
            <div style={{ color: "#E86669", marginTop: "2px" }}>
              This field cannot be left blank
            </div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          {Object.keys(habitData).map(val => (
            <label
              key={Math.random()}
              htmlFor={`AIHabits${val}`}
              className="habitsOptionlabel"
              style={{ fontSize: "1.2rem", margin: "0 8px" }}
            >
              <FormControlLabel
                control={
                  <CustomColorCheckbox
                    id={`AIHabits${val}`}
                    onClick={() => {
                      store.dispatch({
                        type: "SET_KEY_CHECK",
                        payload: {
                          key: "habits",
                          value: false
                        }
                      });
                      store.dispatch({
                        type: "SET_ADDITIONAL_INFO_HABITS",
                        payload: val
                      });
                    }}
                    checked={habitData[val]}
                    iconStyle={{ color: "#379F8E" }}
                    labelStyle={{ color: "#379F8E" }}
                    value="#379F8E"
                    name="checkedB"
                    className="checkbox"
                  />
                }
                label={val}
              />
            </label>
          ))}
          <div></div>
          {/* <div className="habitsmultiselect">
            <div className="habitsselectBox" onClick={showCheckboxes}>
              <select
                style={{ border: habitsCheck ? "1px solid #E86669" : "none" }}
              >
                {selectedItems.length === 0 ? (
                  <option>-Select-</option>
                ) : (
                  <option className="habitsmultiselectOption">
                    {selectedItems.map(val => val + " ")}
                  </option>
                )}
              </select>
              <div className="habitsoverSelect"></div>
            </div>
            <div id="AIHabitscheckboxes">
              {Object.keys(habitData).map(val => (
                <label
                  key={Math.random()}
                  htmlFor={`AIHabits${val}`}
                  className="habitsOptionlabel"
                >
                  {val}
                  <input
                    type="checkbox"
                    id={`AIHabits${val}`}
                    checked={habitData[val]}
                    onChange={() => {
                      store.dispatch({
                        type: "SET_KEY_CHECK",
                        payload: {
                          key: "habits",
                          value: false
                        }
                      });
                      store.dispatch({
                        type: "SET_ADDITIONAL_INFO_HABITS",
                        payload: val
                      });
                    }}
                  />
                </label>
              ))}
            </div>
            {habitsCheck && (
              <div style={{ color: "#E86669", marginTop: "2px" }}>
                This field cannot be left blank
              </div>
            )}
          </div> */}
        </div>
      </div>
      {habitData.Others && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            width: "40%"
          }}
          className="alertContainer"
        >
          <input
            placeholder="If other, specify which"
            className="other-text-input"
            value={otherText}
            onChange={e => {
              store.dispatch({
                type: "SET_KEY_CHECK",
                payload: {
                  key: "habitOther",
                  value: false
                }
              });
              store.dispatch({
                type: "SET_HABIT_OTHER_TEXT",
                payload: e.target.value
              });
            }}
            style={{
              border: habitOtherCheck ? "1px solid #E86669" : "none"
            }}
          />
          {habitOtherCheck && (
            <div style={{ color: "#E86669", marginTop: "2px" }}>
              This field cannot be left blank
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Habits;

import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CurrentAppointmentNoticationModal from "./current-appointment.modal";
import { useCurrentAppointmentSocket } from "../../../hooks/useCurrentAppointmentSocket";
import { useHistory } from "react-router-dom";
import CONFIG_CONSTANTS from "../../../../constants/apiList";
import axios from "axios";
import VideoModalContainer from "../../VideoModal";
import { showNotification } from "../../../../helpers/index";
import { playNotification } from "../../../../helpers/index";
import { useShortCallAlertSocket } from "../../../hooks/useShortCallAlertSocket";
import { showShortCallAlert } from "../../../../helpers/shortCall.helper";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import {
  GTM_ENTRY_POINTS,
  GTM_EVENTS
} from "../../../../constants/tagManager.constants";
import { getRxWriterLink } from "../../../../helpers/rx-writer.helpers";
export const DEFAULT_DOCTOR_ID = -1;
const APPOINTMENT_SUMMARY_PATH = "/dashboard/appointments/summary";

export const CurrentAppointmentContext = React.createContext();

export default function withSocketHOC(Component) {
  return function WrappedComponent(props) {
    const [show, setShow] = useState(false);
    const history = useHistory();
    const userId = useSelector(state => state.userReducer?.user_id);
    const doctorHashId = useSelector(state => state.userReducer?.hash_id);
    const [doctorId, setDoctorId] = useState(DEFAULT_DOCTOR_ID);
    const [popUpAppointmentData, setpopUpAppointmentData] = useState({
      appointmentId: 26168,
      brand: "MM",
      category: ["is_hc"],
      doctorId: 484,
      patientName: "Prathamesh Mali",
      source: "DA"
    });
    const jwtToken = useMemo(
      () => localStorage.getItem("jwt") ?? "",
      [doctorId]
    );
    const [isDoctorIdLoading, setDoctorIdLoading] = useState(true);
    const [popupData, setPopupData] = useState(null);
    useEffect(() => {
      if (show) {
        playNotification();
      } else {
        setpopUpAppointmentData(null);
      }
    }, [show]);
    useEffect(() => {
      try {
        if (popupData) {
          const {
            brand = "",
            source = "",
            appointmentId = ""
          } = popupData ?? {};
          if (!brand || !source || !appointmentId) {
            throw new Error("Invalid data");
          }
          const appointmentReference = `${brand}-${source}-${appointmentId}`;
          const audioRedirectPath = getRxWriterLink(appointmentReference);
          showNotification({
            brand: brand,
            link: audioRedirectPath,
            title: "",
            body: "",
            data: "",
            secTimeout: ""
          });
        }
      } catch (err) {
        console.log(err);
        return;
      }
    }, [popupData]);
    useEffect(() => {
      getDoctorId();
      async function getDoctorId() {
        if (!doctorHashId) return;
        try {
          const payload = { doctor_id: doctorHashId };
          const response = await axios.post(
            CONFIG_CONSTANTS.url.GET_DOCTOR_INFO_URL,
            payload
          );
          if (response.status === 200) {
            const { doctor_id } = response.data.body.data;
            if (!doctor_id) {
              throw new Error("No doctor found");
            }
            setDoctorId(parseInt(doctor_id) ?? DEFAULT_DOCTOR_ID);
          }
        } catch (err) {
          console.error("ERROR IN getDoctorInfo", err);
          setDoctorId(DEFAULT_DOCTOR_ID);
        } finally {
          setDoctorIdLoading(false);
        }
      }
    }, [doctorHashId]);

    const { socketStatus, currentAppointmentData } =
      useCurrentAppointmentSocket(
        userId,
        jwtToken,
        doctorId,
        (state, response) => {
          setShow(state ?? false);
          if (state) {
            if (response) {
              setpopUpAppointmentData(response);
            }

            addToGTM({
              event: GTM_EVENTS?.APPOINTMENT_NOTIFICATION_VIEWED,
              appointmentNotificationDetails: {
                callType: "audio",
                appointmentId: response?.appointmentId
              }
            });
          }
          setPopupData(response);
        }
      );

    const { shortCallAlertData } = useShortCallAlertSocket(
      userId,
      jwtToken,
      doctorId
    );

    useEffect(() => {
      if (shortCallAlertData) {
        showShortCallAlert(shortCallAlertData);
      }
    }, [shortCallAlertData]);

    const handleClose = () => {
      addToGTM({
        event: GTM_EVENTS?.APPOINTMENT_NOTIFICATION_CLOSED,
        appointmentNotificationClosedDetails: {
          callType: "audio",
          appointmentId: popupData?.appointmentId
        }
      });
      setShow(false);
    };
    const handleBtnClick = () => {
      handleClose();
      try {
        const { brand = "", source = "", appointmentId = "" } = popupData ?? {};
        if (!brand || !source || !appointmentId) {
          throw new Error("Invalid data");
        }
        const appointmentReference = `${brand}-${source}-${appointmentId}`;
        const audioRedirectPath = getRxWriterLink(appointmentReference);
        if (history.location.pathname !== audioRedirectPath) {
          addToGTM({
            event: GTM_EVENTS?.RX_WRITER_OPENED,
            redirectDetails: {
              entryPoint:
                GTM_ENTRY_POINTS?.RX_WRITER_OPENED
                  ?.AUDIO_APPOINTMENT_NOTIFICATION
            }
          });
          window.open(audioRedirectPath, "_self");
        }
      } catch (err) {
        console.error(err);
        history.push(APPOINTMENT_SUMMARY_PATH);
        return;
      }
    };
    return (
      <CurrentAppointmentContext.Provider
        value={[
          socketStatus,
          currentAppointmentData,
          doctorId,
          isDoctorIdLoading
        ]}
      >
        <VideoModalContainer doctorId={doctorId} />

        <CurrentAppointmentNoticationModal
          handleClose={handleClose}
          show={show}
          handleBtnClick={handleBtnClick}
          patientName={
            popUpAppointmentData?.patientName
              ? popUpAppointmentData?.patientName
              : ""
          }
          source={
            popUpAppointmentData?.source ? popUpAppointmentData?.source : ""
          }
          categories={
            popUpAppointmentData?.category?.length
              ? popUpAppointmentData?.category
              : []
          }
        />
        {isDoctorIdLoading ? <p>loading...</p> : <Component {...props} />}
      </CurrentAppointmentContext.Provider>
    );
  };
}

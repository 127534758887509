import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import config from "../../../../constants/apiList.js";
import AllAppointmentsWrapper from "./AppointmentSummary/AllAppointmentTable/index.jsx";
import dataResource from "../../../../constants/dataResource";
import CurrentAppointmentTableWrapper from "./AppointmentSummary/CurrentAppointmentTable/index.jsx";
import { HorizontalRuleAppointmentSummary } from "./AppointmentSummary/common/HorizontalRule/styles";
import { toast } from "react-toastify";

const endStates = ["ni", "ns", "t", "c", "op", "na", "cn", "otc", "rf"];

export default function AppointmentsSummary({
  reqBody: dateRange,
  rowStyle,
  setCountToday,
  setCountPending,
  setCountPriority
}) {
  const [data, setData] = useState({});
  const getCurrentAppointments = results => {
    const currentAppointments = results.filter(appt => {
      const bufferTime = 2;
      const { from_time, to_time, appointment_date, status } = appt;
      if (!dataResource.CURRENT_APPOINTMENT_STATUS_LIST.includes(status))
        return false;
      const currentMoment = moment().utcOffset(330);
      const startMoment = moment(from_time, "HH:mm:ss").subtract(
        bufferTime,
        "minutes"
      );
      const endMoment = moment(to_time, "HH:mm:ss").add(
        bufferTime + 1,
        "minutes"
      );
      const appointmentDateMoment = moment(appointment_date, "YYYY-MM-DD");
      return (
        currentMoment.isSameOrAfter(startMoment) &&
        currentMoment.isSameOrBefore(endMoment) &&
        appointmentDateMoment.isSame(currentMoment, "day")
      );
    });
    return currentAppointments || [];
  };

  const today = useMemo(() => {
    let today = new Date();
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
    return today;
  }, []);

  const fetchAppointments = reqBody => {
    axios
      .post(config.url.APPOINTMENTS, reqBody)
      .then(res => {
        let { summary, results } = res.data;
        results = results.filter(row => !row.cancelled);
        let todayC = 0,
          pending = 0,
          priority = 0;
        results.forEach(row => {
          if (row.appointment_date === today) todayC++;
          else if (!endStates.includes(row.status)) pending++;

          if (row.request_callback) priority++;
        });
        setCountToday(todayC);
        setCountPending(pending);
        setCountPriority(priority);
        setData({
          appointmentsPendingTable: results,
          currentAppointments: getCurrentAppointments(results),
          pending_summary: [summary]
        });
      })
      .catch(err => {
        if (err.response.status == 400) {
          toast.warn("Date Range should have a maximum range of 3 days");
        }
        console.log(err);
      });
  };
  useEffect(() => {
    fetchAppointments(dateRange);
    const timer = setInterval(() => fetchAppointments(dateRange), 1000 * 30);
    return () => clearInterval(timer);
  }, [dateRange?.from_date, dateRange?.to_date]);

  return data ? (
    <>
      <CurrentAppointmentTableWrapper data={data} />
      <HorizontalRuleAppointmentSummary />
      <AllAppointmentsWrapper data={data} />
    </>
  ) : null;
}

import React from "react";
import "./styles.scss";

const OrderTableCard = ({ product }) => {
  return (
    <div className="orderTableCard">
      <div className="orderTableCard_product">
        <div className="OTC_product_img">
          <img src="" alt="" />
        </div>
        <div className="OTC_product_desc">
          <h4>{product.name}</h4>
          <div>
            Units:{" "}
            {!!product.usage_unit &&
              product.usage_unit.length > 0 &&
              product.usage_unit[0]}
          </div>
          <div>
            {!!product.usage_frequency &&
              product.usage_frequency.length > 0 &&
              product.usage_frequency[0]}
          </div>
        </div>
      </div>
      <div className="orderTableCard_days">
        <p>{product.days || 30}</p>
      </div>
      <div className="orderTableCard_cartQty">
        <p>{product.cart_quantity}</p>
      </div>
      <div className="orderTableCard_instructions">
        <p>
          {!!product.usage_instructions &&
            product.usage_instructions.length > 0 &&
            product.usage_instructions[0]}
        </p>
      </div>
      <div className="orderTableCard_ingredients">
        <p>{product.ingredients || "NA"}</p>
      </div>
      <div className="orderTableCard_modeOfAction">
        <p>{product.ingredients || "NA"}</p>
      </div>
      <div className="orderTableCard_expectationSetting">
        <span>-</span>
        <span>-</span>
        <p>- </p>
        <span>-</span>
        <p>- </p>
      </div>
    </div>
  );
};

export default OrderTableCard;

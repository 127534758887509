import React, { useState } from "react";
import CONSTANTS from "./constants";
import styles from "./styles.module.scss";
import SummarySection from "./SummarySection.js";

const OverviewSection = ({ state = {} }) => {
  if (!state) return <></>;
  const { title = "", icon = "", color = "", summaryList = [] } = state;
  return (
    <div className={styles["overview__overview-section"]}>
      <div className={styles["title-section"]} style={{ color }}>
        {icon && <img className={styles["icon"]} src={icon} />}
        {title}
      </div>
      <div className={styles["info-section"]}>
        {summaryList.length ? (
          <SummarySection state={{ summaryList }} />
        ) : (
          <div className={styles["no-data-message"]}>
            {CONSTANTS?.NODATA_MESSAGE}
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewSection;

import React, { Component } from "react";
import "./ImageTimeline.scss";
import { store } from "../../../../reducers/Persist";
import moment from "moment";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../../../constants/apiList";
import Loader from "react-loader-spinner";
import RequestImages from "../../Observations/Complaints/DoctorNotes/RequestImages";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";
import dataResource from "../../../../constants/dataResource";

class ImageTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showSingleImageModal: false
    };
  }
  returnImageArray(Date_image) {
    let imageArr = [];
    let ind = 0;
    [...Object.keys(Date_image).reverse()].map((key, index) => {
      Date_image[key].map((str, index) => {
        imageArr = [...imageArr, str];
      });
    });
    return imageArr;
  }
  componentDidMount() {
    this.setState({ loading: true });
    const payload = {
      email: this.props.email,
      phone: this.props.phone
    };
    this.fetchImages(payload, true);
  }

  fetchImages(payload, isMount) {
    this.setState({ loading: false });
    axios
      .post(config.url.GET_RX_TIMELINE_IMAGES, payload)
      .then(res => {
        if (!isMount) {
          const isRefreshRequired = Object.keys(res.data?.signedImages).reduce(
            (acc, curr) => {
              if (
                Object.keys(this.props?.imageTimeline?.[curr])?.length !==
                Object.keys(res.data?.signedImages?.[curr])?.length
              ) {
                return true;
              }
              return acc;
            },
            false
          );

          if (!isRefreshRequired) {
            return;
          }
        }

        store.dispatch({
          type: "UPDATE_IMAGE_TIMELINE",
          data: res.data.signedImages
        });
        this.setState({ loading: false });
        // this.setState({ imageTimeline: res.data.signedImages });
      })
      .catch(e => {
        console.log(e);
        this.setState({ loading: false });
      });
  }

  refreshImages() {
    const payload = {
      email: this.props.email,
      phone: this.props.phone
    };
    this.fetchImages(payload, false);
    addToGTM({
      event: GTM_EVENTS?.IMAGE_REFRESHED
    });
  }
  render() {
    if (!dataResource.IMAGE_SUPPORTED_BRAND.includes(this.props.brand)) {
      return (
        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p>
            Images are currently not supported for{" "}
            {dataResource.BRANDS_SHORT_TO_FULL_NAME[this.props.brand] ||
              this.props.brand}
          </p>
        </div>
      );
    }

    return (
      <div className="general-modal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="modal-title" style={{ marginRight: "100px" }}>
            Image Timeline
          </div>
          <img
            loading="lazy"
            src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
            alt="close"
            onClick={this.props.closeModal}
            className="close-button"
          />
        </div>
        <div>
          {this.state.loading ? (
            <div className="loader-container">
              <Loader type="TailSpin" />
            </div>
          ) : this.props.imageTimeline ? (
            <>
              <RequestImages refreshImages={() => this.refreshImages()} />
              <div className="imageTimelineModalContainer">
                {[
                  ...Object.keys(this.props.imageTimeline).sort(
                    (dateA, dateB) => new Date(dateB) - new Date(dateA)
                  )
                ].map((key, index) => {
                  return (
                    <div key={index} className="">
                      <div className="timeline-date">
                        {moment(key).format("MMMM D, YYYY (dddd)")}
                      </div>
                      <div className="row">
                        {this.props.imageTimeline[key].map((str, index) => {
                          return (
                            <img
                              onClick={() => {
                                store.dispatch({
                                  type: "SHOW_SINGLE_IMAGE_MODAL",
                                  payload: {
                                    image_src: str,
                                    image_arr: this.returnImageArray(
                                      this.props.imageTimeline
                                    ),
                                    image_date: moment(key).format(
                                      "MMMM D, YYYY (dddd)"
                                    )
                                  }
                                });
                              }}
                              loading="lazy"
                              className="timeline-image"
                              alt="timeline"
                              src={str}
                              key={index}
                              style={{ cursor: "pointer" }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div>
              <div>No Images Found!</div>
              <RequestImages refreshImages={() => this.refreshImages()} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    imageTimeline: store.getState().imageTimelineReducer.imageTimeline,
    email: store.getState().rxWriterReducer.prescriptionData.email,
    phone: store.getState().rxWriterReducer.phone,
    brand: store.getState().rxWriterReducer.brand
  };
};
export default connect(mapStateToProps)(ImageTimeline);

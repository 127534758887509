import React, { useEffect, useState } from "react";
import "./styles.scss";
import phoneIcon from "../../asset/phoneIcon.svg";
import crossIcon from "../../asset/crossIcon.svg";
import videoCallIcon from "../../asset/videoCallIcon.png";
import { useSelector } from "react-redux";
import ViewImages from "../../Observations/Complaints/DoctorNotes/ViewImages";
import PrescriptionReportButton from "../../PatientFile/PatientDetails/PrescriptionReportButton/index";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";
function QuickAccess() {
  const [accessActive, setAccessActive] = useState(true);
  const prescriptionData = useSelector(
    state => state.rxWriterReducer.prescriptionData
  );
  const { currentTab } = useSelector(state => state.footerReducer);

  const patientDetails = useSelector(
    state => state.rxWriterReducer.patientDetails
  );
  let city = patientDetails?.address;
  const handleButtonClick = e => {
    e.preventDefault();
    const enableQuickAccess = !accessActive;
    if (enableQuickAccess) {
      addToGTM({
        event: GTM_EVENTS?.QUICK_ACCESS_VIEWED
      });
    } else {
      addToGTM({
        event: GTM_EVENTS?.QUICK_ACCESS_CLOSED
      });
    }
    setAccessActive(e => !e);
  };

  const apptId = useSelector(state => state.rxWriterReducer.id);
  const diagnosis = useSelector(
    state => state.observationReducer[apptId]?.diagnosis
  );
  const medicalHistory = useSelector(
    state => state.observationReducer[apptId]?.medicalHistory
  );
  const familyHistory = useSelector(
    state => state.observationReducer[apptId]?.familyHistory
  );
  const pastTreatments = useSelector(
    state => state.observationReducer[apptId]?.pastTreatments
  );
  let diagnosisHeader = [];
  !!diagnosis &&
    diagnosis.length > 0 &&
    diagnosis.map((val, index) => {
      let obj = {
        diagnosisText: [],
        preset: []
      };
      if (!!val.text) {
        obj = {
          diagnosisText: [val.text],
          preset: []
        };
      }
      if (val?.pretext && val?.pretext.length > 0) {
        obj = {
          diagnosisText: [...obj.diagnosisText],
          preset: [...val.pretext]
        };
      }
      diagnosisHeader.push({ ...obj });
    });
  let allergyHeader = [];
  let familyHistoryHeader = [];
  let medicalHistoryHeader = [];
  let pastTreatmentsHeader = [];
  let familyHistoryFinal = [];
  let medicalHistoryFinal = [];
  let pastTreatmentsFinal = [];

  if (pastTreatments?.pretext.length > 0)
    pastTreatmentsHeader = [...pastTreatments.pretext];
  if (medicalHistory?.pretext.length > 0) {
    medicalHistoryHeader = [...medicalHistory.pretext];
  }
  if (familyHistory?.pretext.length > 0) {
    familyHistoryHeader = [...familyHistory.pretext];
  }
  let complaints = [];
  let severity = [];
  const allergy = useSelector(
    state => state.observationReducer[apptId]?.allergySpecifications
  );
  const concerns = useSelector(
    state => state.observationReducer[apptId]?.concerns
  );

  const presetData = useSelector(state => state.rxWriterReducer?.presetData);
  !!concerns &&
    concerns.length > 0 &&
    concerns.map((val, index) => {
      let metaData = { ...val.metadata };
      let complaint = !!val.text ? val.text : "";
      let pretext = [...val.pretext];
      Object.values(presetData).map((obj, i) => {
        if (pretext.includes(obj.id)) {
          complaint +=
            complaint.length > 0
              ? ", " + obj.observation_preset_text
              : obj.observation_preset_text;
        }
      });
      complaints.push(complaint);
      severity.push(metaData.severity);
    });
  !!allergy &&
    allergy.length > 0 &&
    allergy.map(val => {
      if (!!val.text) {
        allergyHeader.push({
          type: val.metadata.type,
          preset: [...val.pretext],
          presetTextArr: [val.text]
        });
      } else
        allergyHeader.push({
          type: val.metadata.type,
          preset: [...val.pretext],
          presetTextArr: []
        });
    });
  Object.values(presetData).map((val, i) => {
    if (medicalHistoryHeader.includes(val.id)) {
      medicalHistoryFinal.push(val.observation_preset_text);
    }
    if (familyHistoryHeader.includes(val.id)) {
      familyHistoryFinal.push(val.observation_preset_text);
    }
    if (pastTreatmentsHeader.includes(val.id)) {
      pastTreatmentsFinal.push(val.observation_preset_text);
    }
  });
  for (let val in presetData) {
    diagnosisHeader.map((diagnosisObj, index) => {
      if (diagnosisObj.preset.includes(presetData[val].id)) {
        diagnosisHeader[index] = {
          ...diagnosisHeader[index],
          diagnosisText: [
            ...diagnosisHeader[index].diagnosisText,
            presetData[val].observation_preset_text
          ]
        };
      }
    });
    // familyHistoryHeader.map((val,index)=>{
    //   if(familyHistoryHeader.=)
    // })
    allergyHeader.map((allergyObj, index) => {
      if (allergyObj.preset.includes(presetData[val].id)) {
        allergyHeader[index] = {
          ...allergyHeader[index],
          presetTextArr: [
            ...allergyHeader[index].presetTextArr,
            presetData[val].observation_preset_text
          ]
        };
      }
    });
  }
  if (!!pastTreatments?.text) {
    pastTreatmentsFinal.push(pastTreatments.text);
  }
  if (!!medicalHistory?.text) {
    medicalHistoryFinal.push(medicalHistory.text);
  }
  if (!!familyHistory?.text) {
    familyHistoryFinal.push(familyHistory.text);
  }
  let diagnosisFinalArr = [];
  !!diagnosisHeader &&
    diagnosisHeader.length > 0 &&
    diagnosisHeader.map((val, index) => {
      !!val.diagnosisText &&
        val.diagnosisText.map((text, index) => {
          diagnosisFinalArr.push(text);
        });
    });
  const prescriptionActive = useSelector(
    state => state.footerReducer.unlockedTabs.prescription
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [active, setActive] = React.useState(false);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setActive(currVal => !currVal);
  };

  const open = Boolean(anchorEl);

  return (
    currentTab !== 3 && (
      <div className="quickAccess">
        {accessActive ? (
          <div
            className="quickAccess__container__parent"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className="quickAccess__container"
              style={{ marginBottom: "0px" }}
            >
              {/* <div className="crossIcon" onClick={handleButtonClick} style={{border: "1px solid red"}}> */}
              {/* <img src={crossIcon} alt="" /> */}
              <img
                // style={{ width: "20px", height: "20px", marginRight: "10px" }}
                loading="lazy"
                src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
                alt="close"
                className="crossIcon"
                onClick={handleButtonClick}
              />
              {/* </div> */}
              <div className="quickAccess__left">
                <div>
                  <div className="quickAccess__left_nameAge">
                    <h4>{patientDetails?.name}</h4>
                    <span></span>
                    <p>{patientDetails?.age}</p>
                  </div>
                  <div
                    className="quickAccess__left_icon"
                    style={{
                      background: `${
                        prescriptionData?.mode === "bw_video"
                          ? "transparent"
                          : "rgba(91, 180, 127, 0.4)"
                      }`,
                      padding: `${
                        prescriptionData?.mode === "bw_video"
                          ? "0rem"
                          : "0.3rem"
                      }`
                    }}
                  >
                    <img
                      src={
                        prescriptionData?.mode === "bw_video"
                          ? videoCallIcon
                          : phoneIcon
                      }
                      alt=""
                    />
                  </div>
                  <div className="quickAccess__left_category">
                    {/* <p>Hair</p> */}
                    <p>{prescriptionData.category_label}</p>
                  </div>
                </div>

                <div className="quickAccess__left_maritalStatus">
                  <p>Marital Status</p>
                  <span>{patientDetails?.marital_status}</span>
                </div>
                <div className="quickAccess__left_city">
                  <p>City</p>
                  <span title={city}>{city}</span>
                </div>
              </div>
              <div className="quickAccess__right">
                <div>
                  <PrescriptionReportButton quickAccess={true} />
                </div>
                <div>
                  <ViewImages quickAccess={true} />
                </div>
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "space-between",
                    display: "none"
                  }}
                >
                  {/* TODO: remove this when video link and reports button need to be made active*/}
                  <button disabled>Video link</button>
                  <button disabled>Reports</button>
                </div>
              </div>
            </div>
            {prescriptionActive && (
              <div className="quickAccess-popup__bottom">
                {complaints?.length > 0 && (
                  <div className="quickAccess-popup__bottom_subParent">
                    <h6>Complaints</h6>
                    <div>
                      {complaints.map((val, index) => (
                        <div key={index}>
                          <span>{val}</span>
                          <span
                            style={{
                              marginLeft: "4px",
                              border: "1.5px solid transparent",
                              fontWeight: "400",
                              color: "#fff",
                              background:
                                severity[index] === "critical"
                                  ? "rgba(152, 66, 68, 1)"
                                  : severity[index] === "high"
                                  ? "rgba(232, 102, 105, 1)"
                                  : severity[index] === "moderate"
                                  ? "rgba(233, 149, 47, 1)"
                                  : "rgba(228, 174, 44, 1)",
                              // opacity: "0.8",
                              padding: "2px",
                              borderRadius: "4px",
                              fontSize: "12px"
                            }}
                          >
                            {severity[index]}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {diagnosisFinalArr?.length > 0 && (
                  <div className="quickAccess-popup__bottom_subParent">
                    <h6>Diagnosis</h6>
                    <div>
                      {diagnosisFinalArr.map((val, index) => (
                        <span key={index}>{val}</span>
                      ))}
                    </div>
                  </div>
                )}
                {pastTreatmentsFinal?.length > 0 && (
                  <div className="quickAccess-popup__bottom_subParent">
                    <h6>Past Treatments</h6>
                    <div>
                      <span>{pastTreatmentsFinal.join(", ")}</span>
                    </div>
                  </div>
                )}
                {medicalHistoryFinal?.length > 0 && (
                  <div className="quickAccess-popup__bottom_subParent">
                    <h6>Medical History</h6>
                    <div>
                      <span>{medicalHistoryFinal.join(", ")}</span>
                    </div>
                  </div>
                )}
                {familyHistoryFinal?.length > 0 && (
                  <div className="quickAccess-popup__bottom_subParent">
                    <h6>Family History</h6>
                    <div>
                      <span>{familyHistoryFinal.join(", ")}</span>
                    </div>
                  </div>
                )}
                {allergyHeader?.length > 0 &&
                  allergyHeader[0]?.presetTextArr?.length > 0 && (
                    <div className="quickAccess-popup__bottom_subParent">
                      <h6>Allergies</h6>
                      <div className="quickAccess-popup__bottom__right__wrapper">
                        {allergyHeader.map((val, index) => (
                          <p>
                            <span>{val.type}</span>:
                            {val.presetTextArr.map(val => (
                              <span>{val} </span>
                            ))}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        ) : (
          <button
            onClick={handleButtonClick}
            className="quickAccess__btn"
            style={{ marginLeft: "auto" }}
          >
            Quick Access
          </button>
        )}
      </div>
    )
  );
}

export default QuickAccess;

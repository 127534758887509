import { store } from "../reducers/Persist";

export const getRxWriterLink = (appointmentReference, queryParams = {}) => {
  const queryString = getQueryString(queryParams);
  const defaultLink = `/prescription/${appointmentReference}${
    queryString ? `?${queryString}` : ""
  }`;
  try {
    /**
     * Enabling new Rx writer for non weight doctors only
     */
    const loggedInDoctor = store.getState().userReducer;

    if (!loggedInDoctor?.nutritionist)
      return `/dashboard/rx-writer?appointmentId=${appointmentReference}${
        queryString ? `&${queryString}` : ""
      }`;
    else return defaultLink;
  } catch (err) {
    console.log("ERROR IN getRxWriterLink", err);
    return defaultLink;
  }
};

const getQueryString = queryParams => {
  try {
    return (
      Object.keys(queryParams)
        ?.map(key => `${key}=${queryParams[key]}`)
        ?.join("&") || ""
    );
  } catch (err) {
    console.log("ERROR IN getQueryString", err);
    return "";
  }
};

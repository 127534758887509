import React from "react";

export const CONSTANTS = {
  BUTTON: {
    TEXT: "Apply Leave",
    PRIMARY_COLOR: "rgb(55, 159, 142)",
    SECONDARY_COLOR: "rgba(55, 159, 142`, 0.1)",
    ICON: <ButtonIcon primaryColor="rgb(55, 159, 142)" />
  },
  LEAVE_FORM_LINK: "https://forms.gle/gRxezE7ZQLnDfoQh9"
};

function ButtonIcon({ primaryColor }) {
  return (
    <svg fill={primaryColor} viewBox="0 0 16 16" height="20" width="20">
      <path
        d="M3.5 0a.5.5 0 01.5.5V1h8V.5a.5.5 0 011 0V1h1a2 2 0 012 2v11a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2h1V.5a.5.5 0 01.5-.5zM2 2a1 1 0 00-1 1v1h14V3a1 1 0 00-1-1H2zm13 3H1v9a1 1 0 001 1h12a1 1 0 001-1V5z"
        stroke={primaryColor}
        stroke-width="0.3"
      />
    </svg>
  );
}

import React, { useState } from "react";
import CONFIG_CONSTANTS from "../../../../../constants/apiList.js";
import axios from "axios";
import Timer from "react-compound-timer";
import "./header-buttons.scss";
import AgoraVCFrame from "../../../../core/AgoraVCFrame/AgoraVCFrame.js";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { store } from "../../../../../reducers/Persist.js";
import clevertap from "clevertap-web-sdk";
import RESOURCE_CONST from "../../../../../constants/dataResource";
import { iaEnabled } from "../../../../../helpers/features.helper.js";
import { addToGTM } from "../../../../../helpers/tagManager.helper.js";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants.js";

var phoneCallTimer;
const cooloffUrl = CONFIG_CONSTANTS.url.UPDATE_COOLOFF;
class HeaderButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPhoneCall: false,
      isVideoCall: false,
      s_id: "",
      showTimerCall: false,
      showTimerVC: false,
      showVCFrame: false,
      instantAppointment: false,
      phoneDisable: false
    };
    this.sendFcmNotification = async () => {
      const sendFcmNotificationUrl = CONFIG_CONSTANTS.url.SEND_FCM_NOTIFICATION;
      const sendFcmNotificationRequestData = {
        brand: this.props.rxData.brand,
        userId: this.props.rxData.prescriptionData.user_id,
        notification: {
          title: "Start Consultation",
          body: `${this.props.rxData.prescriptionData.doctor_assigned} has joined the consultation call.`,
          click_action: `https://manmatters.app.link/joinCall?scheduleToken=${this.props.rxData.prescriptionData.schedule_token}`,
          doctor_name: this.props.rxData.prescriptionData.doctor_assigned,
          // doctor_image: this.state.info.data.doctor_img,
          ringer: "true"
        }
      };
      await axios
        .post(sendFcmNotificationUrl, sendFcmNotificationRequestData)
        .then(res => {
          console.log("SEND FCM RES", res);
        })
        .catch(err => {
          console.log("ERROR IN /sendFcmNotification", err);
        });
    };
  }

  phoneCall = () => {
    if (this.state.s_id !== "") {
      const data = {
        callSid: this.state.s_id
      };
      let link = CONFIG_CONSTANTS.url.EXOTEL_CALL_DETAILS_URL;
      axios.post(link, data).then(resp => {
        const result = resp?.data?.results;
        if (result?.Call) {
          const callObject = result.Call;
          if (callObject?.Status != RESOURCE_CONST?.CALL_STATUS?.INPROGRESS) {
            const difference = moment(callObject.EndTime).diff(
              callObject.StartTime,
              "seconds"
            );
            // if (!this.state.isVideoCall) {
            //   this.coolOffApi(difference, RESOURCE_CONST?.COOL_OFF_TYPE?.ADD);
            // }
            store.dispatch({ type: "SET_CALL_DURATION", payload: difference });
            this.setState({
              isPhoneCall: false,
              s_id: "",
              showTimerCall: false
            });
            clearInterval(phoneCallTimer);
          }
        }
      });
    }
  };

  turnOnVideo = () => {
    this.setState({ isVideoCall: true, showTimerVC: true });
  };

  componentDidMount() {
    try {
      if (
        this.props?.user?.standby !== 1 &&
        iaEnabled(this.props?.rxData?.brand)
      ) {
        const slot_start_time =
          this.props?.rxData?.prescriptionData?.appointment_slot
            .split("-")[0]
            .trim();
        const appointment_date =
          this.props?.rxData?.prescriptionData?.appointment_date;
        const diff = moment().diff(
          moment(
            `${appointment_date} ${slot_start_time}`,
            "YYYY-MM-DD HH:mm:ss"
          ),
          "seconds"
        );
        if (diff < 75) {
          this.setState({ phoneDisable: true });
          setTimeout(() => {
            this.setState({ phoneDisable: false });
          }, 180000);
        }
      }
    } catch (e) {
      console.log(e);
    }
    let search = window.location.search;
    let urlParams = new URLSearchParams(search);
    let openVCFlag = urlParams.get("openVC");
    if (openVCFlag) {
      this.setState({
        instantAppointment: true
      });
      this.turnOnVideo();
    }
  }

  coolOffApi = (difference, type) => {
    if (!iaEnabled(this.props?.rxData?.brand)) return;
    try {
      axios
        .post(cooloffUrl, {
          type: type,
          duration: difference,
          doctorId: this.props?.rxData?.prescriptionData?.doctor_id,
          appointmentId: this.props?.rxData?.id
        })
        .then(resp => {});
    } catch (err) {
      console.log("ERROR WHILE COOLOFF ", type, err);
    }
  };

  handlePhoneCall = () => {
    let loggedInDocId =
      store?.getState()?.rxWriterReducer?.loggedInDr?.doctor_id;
    let prescriptionDocId = this.props.rxData.prescriptionData.doctor_id;
    if (loggedInDocId === prescriptionDocId) {
      this.setState({ isPhoneCall: true, showTimerCall: true });
      const data = {
        doctor_id: loggedInDocId,
        schedule_id: this.props?.rxData.id,
        patient_number: this.props?.rxData?.patientDetails.phone,
        brand: this.props?.rxData?.brand
      };

      let link = CONFIG_CONSTANTS.url.EXOTEL_C2C_URL;
      !!data.doctor_id &&
        !!data.brand &&
        !!data.patient_number &&
        !!data.schedule_id &&
        axios.post(link, data).then(resp => {
          this.setState({ s_id: resp.data.body.data.Sid });
          if (resp.data.statusCode === 429) {
            toast.error(resp.data.body.data);
            this.setState({ isPhoneCall: false, showTimerCall: false });
          } else if (resp.data.statusCode !== 200) {
            toast.error("Something went wrong!");
          } else {
            phoneCallTimer = window.setInterval(() => this.phoneCall(), 6000);
            this.coolOffApi("", RESOURCE_CONST.COOL_OFF_TYPE.REMOVE);
          }
        });
    } else {
      this.props.showReassignModal();
    }
  };
  handleSendVideoLink = () => {
    const sendMessageUrl = CONFIG_CONSTANTS.url.SEND_WHATSAPP_LINK;
    const data = {
      appointmentID: this.props.rxData.id
    };
    axios.post(sendMessageUrl, data).then(resp => {
      if (resp.status !== 200) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Message sent succesfully!!");
      }
    });
  };
  onVCClose = (duration, disableCooloff = false) => {
    this.setState({
      showVCFrame: false,
      isVideoCall: false,
      showTimerVC: false
    });
    if (!this.state.isPhoneCall && !disableCooloff) {
      this.coolOffApi(duration, RESOURCE_CONST?.COOL_OFF_TYPE?.ADD);
    }
  };
  onVCJoin = () => {
    this.coolOffApi("", RESOURCE_CONST.COOL_OFF_TYPE.REMOVE);
  };

  render() {
    return (
      <div className="header-buttons__wrapper">
        {this.state.showTimerCall ? (
          <div className="ongoing-phone-call">
            <Timer initialTime={0}>
              {({ start, resume, pause, stop, reset, timerState }) => (
                <React.Fragment>
                  <span>
                    <Timer.Minutes
                      formatValue={value => (value < 10 ? `0${value}` : value)}
                    />{" "}
                    :
                    <Timer.Seconds
                      formatValue={value => (value < 10 ? `0${value}` : value)}
                    />
                  </span>
                </React.Fragment>
              )}
            </Timer>
          </div>
        ) : (
          <button
            className="phone-call__button"
            disabled={this.state.phoneDisable}
            onClick={() => {
              this.handlePhoneCall();
              addToGTM({
                event: GTM_EVENTS?.PHONE_CALL_CLICKED
              });
              clevertap.event.push("Calls Initiated", {
                Source: this.props.rxData.form,
                Category: this.props.rxData.prescriptionData.category_label,
                Appointments: this.props.rxData.id,
                Brand: this.props.rxData.brand,
                "Call Type": "Voice Call",
                slot_start_time:
                  this.props.rxData.prescriptionData.appointment_slot
                    .split("-")[0]
                    .trim(),
                Language: this.props.rxData.prescriptionData.language
              });
            }}
          >
            Phone Call
          </button>
        )}
        {/* {this.state.showTimerVC ? (
          <div className="ongoing-video-call">
            <Timer initialTime={0}>
              {({ start, resume, pause, stop, reset, timerState }) => (
                <React.Fragment>
                  <span>
                    <Timer.Minutes
                      formatValue={value => (value < 10 ? `0${value}` : value)}
                    />{" "}
                    :
                    <Timer.Seconds
                      formatValue={value => (value < 10 ? `0${value}` : value)}
                    />
                  </span>
                </React.Fragment>
              )}
            </Timer>
          </div>
        ) : (
          <button
            className="video-call__button"
            // disabled={this.state.isPhoneCall}
            onClick={() => {
              this.setState({ isVideoCall: true, showTimerVC: true });
              clevertap.event.push("Calls Initiated", {
                Source: this.props.rxData.form,
                Category: this.props.rxData.prescriptionData.category_label,
                Appointments: this.props.rxData.id,
                Brand: this.props.rxData.brand,
                "Call Type": "Video Call",
                slot_start_time:
                  this.props.rxData.prescriptionData.appointment_slot
                    .split("-")[0]
                    .trim(),
                Language: this.props.rxData.prescriptionData.language
              });
            }}
          >
            Video Call
          </button>
        )} */}
        {/* {iaEnabled(this.props.rxData.brand) ? (
          <button
            className="send-video-link__button"
            onClick={() => {
              this.handleSendVideoLink();
            }}
          >
            Send Video Link
          </button>
        ) : (
          <></>
        )} */}
        {this.state.isVideoCall && (
          <div>
            {/* Video Call is Disabled until further notice */}
            {/* <AgoraVCFrame
              onClose={this.onVCClose}
              onJoin={this.onVCJoin}
              brand={this.props.rxData.brand}
              category={this.props.rxData.form}
              userId={this.props.rxData.id}
              schedule_token={this.props.rxData.prescriptionData.schedule_token}
              sendFcmNotification={this.sendFcmNotification}
              makeCall={this.handlePhoneCall}
              phone={this.props.rxData.patientDetails.phone}
              instantAppointment={this.state.instantAppointment}
              isPhoneCall={this.state.isPhoneCall}
            /> */}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    rxData: store.getState().rxWriterReducer,
    user: store.getState().userReducer
  };
};
export default connect(mapStateToProps)(HeaderButtons);

import React, { Component } from "react";
import {
  Checkbox,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  FormControlLabel
} from "@material-ui/core";
import { Combobox } from "react-widgets";
import "./RxWriterinputBox.scss";
import { connect } from "react-redux";
import { store } from "../../../../reducers/Persist";
import { AiOutlineClose } from "react-icons/ai";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

class RxWriterInputBox extends Component {
  state = {
    selectedPresets: [],
    selectedPresetValue: ""
  };
  componentDidMount() {
    let tempTags = [];
    for (const preset of this.props.presetValue) {
      tempTags.push(this.props.presetIdToObj[preset]);
    }
    this.setState({ selectedPresets: tempTags });
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.presetValue) !==
      JSON.stringify(this.props.presetValue)
    ) {
      let tempTags = [];
      for (const preset of this.props.presetValue) {
        tempTags.push(this.props.presetIdToObj[preset]);
      }
      this.setState({ selectedPresets: tempTags });
    }
  }
  handleChange = value => {
    let flag = false;
    const selectedPresetIds = [];
    for (const preset of this.state.selectedPresets) {
      if (preset.id === value.id) {
        flag = true;
        break;
      }
      selectedPresetIds.push(preset.id);
    }
    if (!flag) {
      this.setState(
        { selectedPresets: [...this.state.selectedPresets, value] },
        () => {
          this.props.onPresetSelect([...selectedPresetIds, value.id]);
        }
      );
    }
  };

  onInputChange = e => {
    this.props.onChangeInput(e.target.value);
  };
  handleDelete = id => {
    const selectedPresetIds = [],
      selectedPresets = [];
    for (const preset of this.state.selectedPresets) {
      if (preset.id !== id) {
        selectedPresetIds.push(preset.id);
        selectedPresets.push(preset);
      }
    }
    this.setState({ selectedPresets: selectedPresets });
    this.props.onPresetSelect(selectedPresetIds);
  };
  renderPresets = () => {
    let id = "id";
    let presetsToBeShown = [
      ...new Map(
        this.state.selectedPresets.map(item => [item[id], item])
      ).values()
    ];
    if (!!this.props.isAllergies && !!this.props.allergyType) {
      if (this.props.allergyType === "Food") {
        return (
          <div style={{ display: "flex" }}>
            {presetsToBeShown
              .filter(
                item => item.observation_type === "allergy_food_specification"
              )
              .map((preset, index) => {
                return (
                  <div className="preset-style" key={Math.random()}>
                    {preset.tag}
                    <AiOutlineClose
                      style={{ cursor: "pointer", marginLeft: "3px" }}
                      onClick={() => this.handleDelete(preset.id)}
                    />
                  </div>
                );
              })}
          </div>
        );
      }
      if (this.props.allergyType === "Drug") {
        return (
          <div style={{ display: "flex" }}>
            {presetsToBeShown
              .filter(
                item => item.observation_type === "allergy_drug_specification"
              )
              .map((preset, index) => {
                return (
                  <div className="preset-style" key={Math.random()}>
                    {preset.tag}
                    <AiOutlineClose
                      style={{ cursor: "pointer", marginLeft: "3px" }}
                      onClick={() => this.handleDelete(preset.id)}
                    />
                  </div>
                );
              })}
          </div>
        );
      }
      if (this.props.allergyType === "Animal") {
        return (
          <div style={{ display: "flex" }}>
            {presetsToBeShown
              .filter(
                item => item.observation_type === "allergy_animal_specification"
              )
              .map((preset, index) => {
                return (
                  <div className="preset-style" key={Math.random()}>
                    {preset.tag}
                    <AiOutlineClose
                      style={{ cursor: "pointer", marginLeft: "3px" }}
                      onClick={() => this.handleDelete(preset.id)}
                    />
                  </div>
                );
              })}
          </div>
        );
      }
      if (this.props.allergyType === "Environmental") {
        return (
          <div style={{ display: "flex" }}>
            {presetsToBeShown
              .filter(
                item =>
                  item.observation_type ===
                  "allergy_environmental_specification"
              )
              .map((preset, index) => {
                return (
                  <div className="preset-style" key={Math.random()}>
                    {preset.tag}
                    <AiOutlineClose
                      style={{ cursor: "pointer", marginLeft: "3px" }}
                      onClick={() => this.handleDelete(preset.id)}
                    />
                  </div>
                );
              })}
          </div>
        );
      }

      if (this.props.allergyType === "Others") {
        return null;
      }
    }
    return (
      <div style={{ display: "flex" }}>
        {presetsToBeShown.map(
          (preset, index) => {
            return (
              <div className="preset-style" key={Math.random()}>
                {preset.tag}
                <AiOutlineClose
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  onClick={() => this.handleDelete(preset.id)}
                />
              </div>
            );
          }
        )}
      </div>
    );
  };

  render() {
    const { inputBoxHeight = 177 } = this.props;
    return (
      <div>
        <div className="header">
          {this.props.isDiagnosis ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="diagnosis-radio">
                <label
                  htmlFor="Provisional"
                  onClick={() => {
                    this.props.changeDiagnosis("provisional");
                  }}
                >
                  <input
                    type="radio"
                    checked={this.props.typeVal === "provisional"}
                    onClick={() => {
                      this.props.changeDiagnosis("provisional");
                    }}
                    readOnly
                  />
                  <span
                    style={{
                      marginLeft: "5px",
                      marginRight: "10px",
                      fontWeight: "500",
                      opacity: "0.7"
                    }}
                  >
                    Provisional Diagnosis
                  </span>
                </label>
                <label
                  htmlFor="Final"
                  onClick={() => {
                    this.props.changeDiagnosis("final");
                  }}
                >
                  <input
                    type="radio"
                    checked={this.props.typeVal === "final"}
                    onClick={() => {
                      this.props.changeDiagnosis("final");
                    }}
                    readOnly
                  />
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "500",
                      opacity: "0.7"
                    }}
                  >
                    Final Diagnosis
                  </span>
                </label>
                {this.props.typeAlert && (
                  <div style={{ color: "#E86669", marginTop: "2px" }}>
                    This field cannot be left blank
                  </div>
                )}
              </div>
            </div>
          ) : this.props.isStrong ? (
            <div
              className="header-title"
              style={{ fontWeight: "600", fontSize: "24px", display: "flex" }}
            >
              {this.props.headerTitle}
              {this.props.isRequired && (
                <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
              )}
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <div className="header-title">{this.props.headerTitle}</div>
                {this.props.isRequired && (
                  <p
                    style={{
                      color: "red",
                      paddingLeft: "5px",
                      marginTop:
                        this.props.headerTitle === "Chief Complaints"
                          ? "12px"
                          : "0"
                    }}
                  >
                    *
                  </p>
                )}
              </div>
              {this.props.enterYesNo && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="diagnosis-radio"
                    style={{ marginTop: "10px" }}
                  >
                    <label
                      htmlFor="Yes"
                      onClick={() => {
                        this.props.changeYesNo("yes");
                      }}
                    >
                      <input
                        type="radio"
                        checked={this.props.typeVal === "yes"}
                        readOnly
                      />
                      <span
                        style={{
                          marginLeft: "5px",
                          marginRight: "10px",
                          opacity: this.props.typeVal === "yes" ? "1" : "0.7",
                          fontWeight:
                            this.props.typeVal === "yes" ? "600" : "400"
                        }}
                      >
                        Yes
                      </span>
                    </label>
                    <label
                      htmlFor="no"
                      onClick={() => {
                        this.props.changeYesNo("no");
                        this.setState({
                          selectedPresets: [],
                          selectedPresetValue: ""
                        });
                      }}
                    >
                      <input
                        type="radio"
                        checked={this.props.typeVal === "no"}
                        readOnly
                      />
                      <span
                        style={{
                          marginLeft: "5px",
                          opacity: this.props.typeVal === "no" ? "1" : "0.7",
                          fontWeight:
                            this.props.typeVal === "no" ? "600" : "400"
                        }}
                      >
                        No
                      </span>
                    </label>
                    {this.props.typeAlert && (
                      <div style={{ color: "#E86669", marginTop: "2px" }}>
                        This field cannot be left blank
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {(!this.props.enterYesNo ||
            (this.props.enterYesNo && this.props.typeVal === "yes")) && (
            <FormControl sx={{ m: 2, width: 150 }} className="parent">
              <Combobox
                containerClassName="search-combobox"
                hideCaret={true}
                placeholder="Select Preset"
                data={this.props.mainPresetData}
                onChange={value => {
                  this.setState({ selectedPresetValue: value });
                }}
                onSelect={val => {
                  this.handleChange(val);
                }}
                filter="contains"
                textField="observation_preset_text"
                value={this.state.selectedPresetValue}
              />
            </FormControl>
          )}
        </div>
        {(!this.props.enterYesNo ||
          (this.props.enterYesNo && this.props.typeVal === "yes")) && (
          <div>
            <div className="selected-presets">{this.renderPresets()}</div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <textarea
                className="input-box"
                style={{
                  height: inputBoxHeight,
                  resize: "none",
                  border: this.props.alert ? "1px solid #E86669" : "none"
                }}
                placeholder={this.props.inputPlaceHolder}
                onChange={e => this.onInputChange(e)}
                value={this.props.textValue}
              />
              {this.props.alert && (
                <div style={{ color: "#E86669", marginTop: "2px" }}>
                  This field cannot be left blank
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {
    presetIdToObj: store.getState().rxWriterReducer.presetIdToObj
  };
};
export default connect(mapStateToProps)(RxWriterInputBox);

import React from "react";
import "./OrderPlaced.scss";
import OrderTable from "./OrderTable";
import { useSelector } from "react-redux";

const OrderPlaced = () => {
  const orderedProducts = useSelector(state => state.orderedProducts.top);
  const orderId = useSelector(state => state.orderedProducts.topOrderId);

  const orderedProductsBottom = useSelector(
    state => state.orderedProducts.bottom
  );
  return (
    <>
      {orderedProducts.length > 0 ? (
        <>
          <div className="OrderTable__title">Order Placed</div>
          <div className="OrderTable__subtitle">
            Items will be added to the order & prescription will be for all the
            products
          </div>
          <div className="margin-30 OrderTable">
            <OrderTable />
          </div>
          {orderedProductsBottom.length > 0 &&
            orderedProductsBottom.map(val => (
              <div className="margin-30 OrderTable">
                {/* we can get orderId and orders for that orderId from val */}
                <OrderTable parent="orderedProductsBottom" data={val} />
              </div>
            ))}
        </>
      ) : null}
    </>
  );
};

export default OrderPlaced;

import React, { useState, useEffect } from "react";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Loader from "react-loader-spinner";
import axios from "axios";
import CONFIG_CONSTANT from "../../../constants/apiList";
import Table4 from "../../common/Tables/Table4.js";

function DoctorPerformanceView(props) {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [brand, setBrand] = useState("all");
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const getDoctorPerformanceView = async evt => {
    setShowLoader(true);
    const doctorPerformanceViewRequestData = {
      brand,
      fromDate,
      toDate
    };
    await axios
      .post(
        CONFIG_CONSTANT.url.DOCTOR_PERFORMANCE_VIEW,
        doctorPerformanceViewRequestData
      )
      .then(res => {
        console.log(res.data);
        generateRows(res.data);
        setShowLoader(false);
      })
      .catch(err => {
        setShowLoader(false);
        console.log("ERROR IN /doctorPerformanceView", err);
      });
  };
  useEffect(() => {
    getDoctorPerformanceView();
  }, [brand]);
  const getCellStyle = (column, cellData) => {
    if (!cellData || cellData === "-") return {};
    let backgroundColor = "inital";
    switch (column) {
      case "doctorName":
        return {
          minWidth: 180,
          fontWeight: 500,
          backgroundColor: "rgb(240,240,240)"
        };
      case "distribution":
        const distribution = Number(cellData.slice(0, cellData.length - 1));
        backgroundColor = distribution > 5.25 ? "rgb(200,245,220)" : "inital";
        return { backgroundColor };
      case "onTimePercentage":
        const onTime = Number(cellData.slice(0, cellData.length - 1));
        backgroundColor = onTime < 60 ? "rgb(255,200,200)" : "inital";
        return { backgroundColor };
      case "completionPercentage":
        const completion = Number(cellData.slice(0, cellData.length - 1));
        backgroundColor = completion < 65 ? "rgb(255,200,200)" : "inital";
        return { backgroundColor };
      case "averageAttempts":
        const attempts = Number(cellData + "");
        backgroundColor = attempts < 1.5 ? "rgb(255,200,200)" : "inital";
        return { backgroundColor };
      case "averageDuration":
        const avgDuration = Number(cellData + "");
        backgroundColor = avgDuration < 6 ? "rgb(255,200,200)" : "inital";
        return { backgroundColor };
      case "pci":
        const pci = Number(cellData + "");
        backgroundColor = pci < 4.7 ? "rgb(255,200,200)" : "inital";
        return { backgroundColor };
      default:
        return {};
    }
  };
  const columns = [
    {
      title: "Doctor",
      field: "doctorName",
      cellStyle: cellData => {
        return getCellStyle("doctorName", cellData);
      }
    },
    { title: "Appointments", field: "appointments" },
    { title: "Avg Appointments", field: "averageAppointments" },
    {
      title: "Distribution %",
      field: "distribution",
      cellStyle: cellData => {
        return getCellStyle("distribution", cellData);
      }
    },
    {
      title: "OnTime %",
      field: "onTimePercentage",
      cellStyle: cellData => {
        return getCellStyle("onTimePercentage", cellData);
      }
    },
    {
      title: "Completion %",
      field: "completionPercentage",
      cellStyle: cellData => {
        return getCellStyle("completionPercentage", cellData);
      }
    },
    { title: "Completed", field: "completed" },
    {
      title: "Avg Attempts",
      field: "averageAttempts",
      cellStyle: cellData => {
        return getCellStyle("averageAttempts", cellData);
      }
    },
    {
      title: "Avg Duration",
      field: "averageDuration",
      cellStyle: cellData => {
        return getCellStyle("averageDuration", cellData);
      }
    },
    { title: "Duration <1 min", field: "durationBelowOnePercentage" },
    { title: "Duration >6 mins", field: "durationAboveSixPercentage" },
    { title: "Duration >7 mins", field: "durationAboveSevenPercentage" },
    { title: "Duration >8 mins", field: "durationAboveEightPercentage" },
    {
      title: "PCI",
      field: "pci",
      cellStyle: cellData => {
        return getCellStyle("pci", cellData);
      }
    },
    { title: "Immediate Slot %", field: "immediateSlotPercentage" },
    { title: "Orders", field: "orders" }
  ];
  const generateRows = ({ result, totalAppointments }) => {
    const rows = [];
    const days =
      moment(toDate, "YYYY-MM-DD").diff(
        moment(fromDate, "YYYY-MM-DD"),
        "days"
      ) + 1;
    let totalCompleted = 0;
    for (const doctorId in result) {
      const doctorData = result[doctorId];
      totalCompleted += doctorData.completed;
      const row = {};
      row.doctorName = doctorData.doctorName;
      row.appointments = doctorData.appointments;
      row.averageAppointments =
        days > 0 ? (doctorData.appointments / days).toFixed(2) : "-";
      row.distribution =
        totalAppointments > 0
          ? ((doctorData.appointments / totalAppointments) * 100).toFixed(2) +
            "%"
          : "-";
      row.onTimePercentage =
        doctorData.totalOnTime > 0
          ? ((doctorData.onTime / doctorData.totalOnTime) * 100).toFixed(2) +
            "%"
          : "-";
      row.completionPercentage =
        doctorData.appointments > 0
          ? ((doctorData.completed / doctorData.appointments) * 100).toFixed(
              2
            ) + "%"
          : "-";
      row.completed = doctorData.completed;
      row.averageAttempts =
        doctorData.attemptCount > 0
          ? (doctorData.attemptTotal / doctorData.attemptCount).toFixed(2)
          : "-";
      row.averageDuration =
        doctorData.durationCount > 0
          ? (doctorData.durationTotal / doctorData.durationCount / 60).toFixed(
              2
            )
          : "-";
      row.durationBelowOnePercentage =
        doctorData.durationCount > 0
          ? (
              (doctorData.durationBelowOne / doctorData.durationCount) *
              100
            ).toFixed(2) + "%"
          : "-";
      row.durationAboveSixPercentage =
        doctorData.durationCount > 0
          ? (
              (doctorData.durationAboveSix / doctorData.durationCount) *
              100
            ).toFixed(2) + "%"
          : "-";
      row.durationAboveSevenPercentage =
        doctorData.durationCount > 0
          ? (
              (doctorData.durationAboveSeven / doctorData.durationCount) *
              100
            ).toFixed(2) + "%"
          : "-";
      row.durationAboveEightPercentage =
        doctorData.durationCount > 0
          ? (
              (doctorData.durationAboveEight / doctorData.durationCount) *
              100
            ).toFixed(2) + "%"
          : "-";
      row.pci =
        doctorData.pciCount > 0
          ? (doctorData.pciTotal / doctorData.pciCount).toFixed(2)
          : "-";
      row.immediateSlotPercentage =
        doctorData.appointments > 0 && doctorData.immediateSlots > 0
          ? (
              (doctorData.immediateSlots / doctorData.appointments) *
              100
            ).toFixed(2) + "%"
          : "-";
      row.orders = doctorData.orders;
      rows.push(row);
    }
    const totalRow = {};
    totalRow.doctorName = "Total";
    totalRow.appointments = totalAppointments;
    totalRow.completionPercentage =
      totalAppointments > 0
        ? ((totalCompleted / totalAppointments) * 100).toFixed(2) + "%"
        : "-";
    totalRow.completed = totalCompleted;
    rows.push(totalRow);
    setRows(rows);
  };
  return (
    <div className="doctor-performance-view">
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={fromDate}
          onChange={evt => setFromDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={toDate}
          onChange={evt => setToDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={getDoctorPerformanceView}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => setBrand("all")}
          className={
            brand === "all"
              ? `brand-filter-button brand-filter-all active`
              : `brand-filter-button brand-filter-all`
          }
        >
          ALL
        </div>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>

      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <div>
          <Table4
            title={"Doctor Performance View"}
            rows={rows}
            cols={columns}
            pageSize={rows.length}
          />
        </div>
      )}
    </div>
  );
}
export default DoctorPerformanceView;

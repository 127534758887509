import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import config from "../../../../constants/apiList";
import axios from "axios";
import NumPad from "react-numpad";
import { toast } from "react-toastify";
import { IoIosArrowUp } from "react-icons/io";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import dataResource from "../../../../constants/dataResource";
import ViewAllUpdatesModal from "./ViewAllUpdatesModal";
import clevertap from "clevertap-web-sdk";
import CONFIG_CONSTANTS from "../../../../constants/apiList.js";

import "./AppointmentDetails.scss";
import Scheduler from "../../../core/Scheduler/Scheduler";
import { iaEnabled } from "../../../../helpers/features.helper";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import {
  GTM_ENTRY_POINTS,
  GTM_EVENTS
} from "../../../../constants/tagManager.constants";

class AppointmentDetails extends Component {
  // state for open and not open
  // if open show dropdown
  constructor(props) {
    super(props);
    this.state = {
      remarkActive: false,
      phoneActive: false,
      status: "",
      phone: this.props.prescriptionData?.phone,
      remarkActiveText: "Select Preset",
      remarksList: new Array(dataResource.remarksList.length).fill(false),
      remarks: "",
      viewAllUpdatesOpen: false,
      showModal: false,
      prevStatus: "",
      patientFileStatusList: []
    };
    this.myRef = React.createRef();
  }
  async componentDidMount() {
    const appointmentId = store?.getState()?.rxWriterReducer.id;
    const reqBody = { appointmentId };
    const response = await axios.post(config.url.SEND_RX_STATUS_LIST, reqBody);
    this.setState({
      patientFileStatusList: response?.data?.data?.rxStatusList
    });
    this.setState({
      ...this.state,
      prevStatus:
        this.props.prescriptionData?.remarks &&
        this.props.prescriptionData?.remarks.length &&
        this.props.prescriptionData?.remarks[
          this.props.prescriptionData?.remarks.length - 1
        ]?.status
    });
  }

  onPhoneNumberEdit = () => {
    this.myRef.current.disabled = false;
    this.setState({
      ...this.state,
      phoneActive: true
    });
    this.myRef.current.focus();
  };

  handleLanguageChange = value => {
    const updateLanguageRequestData = {
      language: value,
      appointmentId: this.props.id
    };
    axios
      .post(config.url.UPDATE_LANGUAGE, updateLanguageRequestData)
      .then(res => {
        toast.success(res.data.message, { hideProgressBar: true });
        addToGTM({
          event: GTM_EVENTS?.LANGUAGE_CHANGED,
          langaugeUpdateData: {
            updatedLanguage: value,
            appointmentId: this.props.id
          }
        });
        store.dispatch({ type: "CHANGE_LANGUAGE", payload: value });
      })
      .catch(err => {
        toast.error("Unable to update Language. Try again Later", {
          hideProgressBar: true
        });
      });
  };
  onModifyApppointment = () => {
    this.setState({ showModal: true });
    addToGTM({
      event: GTM_EVENTS?.MODIFIED_APPOINTMENT_CLICKED
    });
  };
  onUpdate = () => {
    let remarksStr = this.state.remarks;

    for (let i = 0; i < this.state.remarksList.length; i++) {
      if (this.state.remarksList[i]) {
        if (remarksStr.length > 0) {
          remarksStr += ", ";
        }
        remarksStr += dataResource.remarksList[i];
      }
    }

    if (!this.state.status) {
      toast.error("Please select the call status again");
      return;
    }
    const payload = {
      doctorId: this.props.doctorId,
      form: this.props.form,
      id: this.props.id,
      remarks: remarksStr,
      status: this.state.status
    };
    axios
      .post(config.url.UPDATE_STATUS_URL, payload)
      .then(res => {
        toast.success(res.data.body.result);

        store.dispatch(
          {
            type: "ADD_REMARK",
            data: { remarks: remarksStr, status: this.state.status }
          },
          () => {
            this.setState({
              remarks: "",
              remarksList: new Array(dataResource.remarksList.length).fill(
                false
              )
            });
          }
        );
        addToGTM({
          event: GTM_EVENTS?.STATUS_UPDATED,
          statusUpdatedData: {
            status: payload.status,
            remarks: remarksStr,
            entryPoint:
              GTM_ENTRY_POINTS?.STATUS_UPDATED?.APPOINTMENT_DETAILS_SECTION
          }
        });
        clevertap.event.push("Status Updated", {
          Source: this.props.form,
          Category: this.props.prescriptionData.category_label,
          "Appointment Status": payload.status,
          "Call Type":
            this.props.prescriptionData?.mode === "bw_video"
              ? "Video call"
              : "Voice Call",
          Appointments: this.props.id,
          Brand: this.props.brand,
          Language: this.props.language,
          "Doctor ID": this.props.prescriptionData.doctor_id,
          "Doctor Name": this.props.patientDetails.doctor_name,
          "Call Duration": this.props.call_duration
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  onRemarksSelect = e => {
    let tempText;
    if (this.state.remarkActive === false) {
      tempText = "Type & Search";
    } else {
      tempText = "Select Preset";
    }
    this.setState({
      ...this.state,
      remarkActive: !this.state.remarkActive,
      remarkActiveText: tempText
    });
  };
  onHandleKeyPress = e => {
    if (e.key === "Enter") {
      this.setState({ ...this.state, phoneActive: false });
    }
  };
  handlePhoneCall = phoneNumber => {
    const loggedInDocId = this.props.rxData?.loggedInDr?.doctor_id;
    const prescriptionDocId = this.props.rxData?.prescriptionData?.doctor_id;
    if (loggedInDocId === prescriptionDocId) {
      this.setState({ isPhoneCall: true, showTimerCall: true });
      const data = {
        doctor_id: loggedInDocId,
        schedule_id: this.props.rxData?.id,
        patient_number: phoneNumber,
        brand: this.props.rxData?.brand
      };
      const flag =
        !!data.doctor_id &&
        !!data.brand &&
        !!data.patient_number &&
        !!data.schedule_id;
      const link = CONFIG_CONSTANTS.url.EXOTEL_C2C_URL;

      flag &&
        axios.post(link, data).then(resp => {
          this.setState({ s_id: resp?.data?.body?.data?.Sid });
          if (resp.data.statusCode == 429) {
            toast.error(resp.data.body.data);
            this.setState({ isPhoneCall: false, showTimer: false });
          } else if (resp?.data?.statusCode !== 200) {
            toast.error("Something went wrong!");
          } else {
            this.coolOffApi("", dataResource.COOL_OFF_TYPE.REMOVE);
            const timer2 = window.setInterval(() => this.phoneCall(), 6000);
          }
        });
    } else {
      this.props.showReassignModal();
    }
  };

  coolOffApi = (duration, type) => {
    if (!iaEnabled(this?.props?.rxData?.brand)) return;
    try {
      axios
        .post(CONFIG_CONSTANTS.url.UPDATE_COOLOFF, {
          type,
          duration,
          doctorId: this?.props?.rxData?.loggedInDr?.doctor_id,
          appointmentId: this?.props?.rxData?.id
        })
        .then(resp => {});
    } catch (err) {
      console.log("ERROR WHILE COOLOFF in AppointmentDetails", type, err);
    }
  };

  shouldHideRescheduleOption() {
    return (
      iaEnabled(this.props.brand) && this.props.prescriptionData?.repeat != 1
    );
  }
  render() {
    let updatedStatus =
      this.props.prescriptionData?.remarks &&
      this.props.prescriptionData?.remarks.length &&
      this.props.prescriptionData?.remarks[
        this.props.prescriptionData?.remarks.length - 1
      ]?.status;
    if (this.state.prevStatus !== updatedStatus) {
      this.setState({
        ...this.state,
        status: "",
        prevStatus: updatedStatus
      });
    }
    return (
      <div className="margin-30 AppointmentDetails">
        <ViewAllUpdatesModal
          open={this.state.viewAllUpdatesOpen}
          onClose={() => {
            this.setState({ viewAllUpdatesOpen: false });
          }}
        />
        <h2 className="AppointmentDetailsTitle">Appointment Details</h2>
        <div className="Appoint_content">
          {/* Left side of the AppointmentDetails Page */}
          <div className="Appoint_content_left">
            <div className="Appoint_content_left_top">
              <div>
                <p>ID</p>
                <span>
                  {this.props.brand}-{this.props.form}-{this.props.id}
                </span>
              </div>
              <div>
                <p>Date</p>
                <span>
                  {moment(
                    this.props?.prescriptionData?.appointment_date
                  ).format("MMMM DD, YYYY")}
                </span>
              </div>
              <div>
                <p>Slot</p>
                <span>{this.props.prescriptionData?.appointment_slot}</span>
              </div>
            </div>
            <div className="Appoint_content_left_bottom">
              <div>
                <p>Language</p>
                <div>
                  <select
                    onChange={e => this.handleLanguageChange(e.target.value)}
                  >
                    <option value="select" disabled hidden>
                      Select
                    </option>
                    {dataResource.languages.map((language, index) => {
                      return (
                        <option
                          selected={language === this.props.language}
                          key={index}
                          value={language}
                        >
                          {language}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* Phone */}
              <div>
                <p>Phone</p>
                <div>
                  <div className="appointment_details_phone_right">
                    <NumPad.Number
                      onChange={value => {
                        this.handlePhoneCall(value);
                        this.setState({ phone: value });
                      }}
                      placeholder={"Enter Number"}
                      value={this.state.phone}
                      decimal={2}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>Call Type</p>
                <div>
                  <div className="Appoint_content_callType">
                    {this.props.prescriptionData?.mode === "bw_video"
                      ? "Video call"
                      : "Voice Call"}
                  </div>
                </div>
              </div>
              <button onClick={this.onModifyApppointment}>
                Modify Appointment
              </button>
              <Modal
                show={this.state.showModal}
                onHide={() => this.setState({ showModal: false })}
                centered
                dialogClassName="modal-90w"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4 className="sectionHeading">Reschedule Appointment</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Scheduler
                    doctorID={this.props.prescriptionData?.doctor_id}
                    categories={
                      this.props.prescriptionData?.category || "is_men"
                    }
                    app={this.props.app}
                    userId={this.props.id}
                    form={this.props.form}
                    email={this.props.prescriptionData?.email}
                    phone={this.props.prescriptionData?.phone}
                    name={this.props.prescriptionData?.name}
                    source={this.props.prescriptionData?.source}
                    brand={this.props.prescriptionData?.brand}
                    type={{}} // type is an object
                    hideRescheduleOption={this.shouldHideRescheduleOption()}
                    hideScheduler={() => {}}
                    closeScheduler={() => {
                      this.setState({ showModal: false });
                      window.forceReloadWithoutChecking = true;
                      window.location.reload();
                    }}
                    handleSlotUpdate={this.handleSlotUpdate}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>

          {/* Right side of the AppointmentDetails page */}
          <div className="Appoint_content_right">
            <div className="Appoint_content_right_div">
              <p>Call Status</p>
              <div className="callStatus_right">
                <select
                  onChange={e => {
                    this.setState({ status: e.target.value });
                  }}
                  onClick={async e => {
                    const appointmentId = store?.getState()?.rxWriterReducer.id;
                    const reqBody = { appointmentId };
                    const response = await axios.post(
                      config.url.SEND_RX_STATUS_LIST,
                      reqBody
                    );
                    this.setState({
                      patientFileStatusList: response?.data?.data?.rxStatusList
                    });
                  }}
                >
                  <option value="" selected hidden>
                    {" "}
                    Select{" "}
                  </option>
                  {this.state.patientFileStatusList.map(status => {
                    return (
                      <option
                        value={status.value}
                        key={status.value}
                        selected={
                          (this.state.status || this.state.prevStatus) ===
                          status.value
                        }
                      >
                        {status.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="Appoint_content_right_div">
              <p>Remarks</p>
            </div>

            <textarea
              name="remark"
              placeholder="Click to start Typing"
              value={this.state.remarks}
              onChange={e => this.setState({ remarks: e.target.value })}
            />

            <div className="Appoint_content_right_btns">
              <button
                className="Appoint_content_update"
                onClick={this.onUpdate}
              >
                Update
              </button>
              <button
                className="Appoint_content_allUpdates"
                onClick={() => this.setState({ viewAllUpdatesOpen: true })}
              >
                View All Updates
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let rxData = store.getState().rxWriterReducer;
  return {
    prescriptionData: rxData?.prescriptionData,
    id: rxData?.id,
    brand: rxData?.brand,
    form: rxData?.form,
    doctorId: store.getState().userReducer.hash_id,
    app: rxData?.prescriptionData?.app,
    call_duration: rxData?.call_duration,
    loggedInDr: rxData?.loggedInDr,
    patientDetails: rxData?.patientDetails,
    language: rxData?.language,
    rxData: rxData
  };
};
export default connect(mapStateToProps)(AppointmentDetails);

export const isMobileCheck = () => {
  try {
    const userAgent = window?.navigator?.userAgent;
    // eslint-disable-next-line no-restricted-globals
    const screenWidth = Math.min(window?.screen?.width, window?.innerWidth);
    return (
      /Android|iPhone|iPad|iPod|Mobile|Windows Phone/i.test(userAgent) ||
      (screenWidth <= 500 && typeof window?.orientation !== "undefined")
    );
  } catch (err) {
    console.log("Error in isMobile", err);
    return false;
  }
};

import React from "react";
import Iframe from "react-iframe";
import "./PrescriptionReport.scss";
import Loader from "react-loader-spinner";
import moment from "moment";

const PrescriptionReport = props => {
  return (
    <div className="prescription-report">
      <div className="header-row">
        <div className="modal-title">Prescription</div>
        <img
          src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
          alt="close"
          onClick={props.closeModal}
          className="close-button"
        />
      </div>
      {props.prescriptionLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader type="TailSpin" />
        </div>
      ) : (
        <div>
          <div className="prescription-row">
            <div className="subheading">Date </div>
            <div className="val">
              {props.lastRxId === -1
                ? "N/A"
                : moment(props.lastRxDate).format("MMMM D, YYYY")}
            </div>
            <div className="subheading"> Slot </div>
            <div className="val">
              {
                props.lastRxId === -1 ? "N/A" : "11:00 AM - 11:30 AM" // HARDCODED - CHANGE BEFORE DEPLOYING TO PROD
              }
            </div>
          </div>
          {props.lastRxId === -1 || !props.prescriptionUrl ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              No previous prescription found
            </div>
          ) : (
            <div>
              <Iframe
                url={props.prescriptionUrl}
                width="700px"
                height="600px"
                id="iframe"
                name="iframe"
                allowtransparency="true"
                // className="myClassname"
                style={{ background: "white" }}
                display="initial"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default PrescriptionReport;

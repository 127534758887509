import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";

function PendingOrdersView(props) {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [brand, setBrand] = useState("MM");
  const [showLoader, setShowLoader] = useState(false);
  const [datewiseRows, setDatewiseRows] = useState([]);
  const [attemptwiseRows, setAttemptwiseRows] = useState([]);
  const [doctorwiseRows, setDoctorwiseRows] = useState([]);
  const [pendingCompletedRows, setPendingCompletedRows] = useState([]);
  const [statuswiseRows, setStatuswiseRows] = useState([]);
  const [tab, setTab] = useState("DATEWISE");
  const getPendingOrdersStatistics = async evt => {
    setShowLoader(true);
    const pendingOrdersStatisticsRequestData = {
      fromDate,
      toDate,
      brand
    };
    await axios
      .post(
        CONFIG_CONSTANT.url.PENDING_ORDERS_STATISTICS,
        pendingOrdersStatisticsRequestData
      )
      .then(res => {
        console.log("GET_SHORT_CALLS RES", res);
        generateDatewiseRows(res.data.result.datewiseResults);
        generateAttemptwiseRows(res.data.result.attemptwiseResults);
        generateDoctorwiseRows(res.data.result.doctorwiseResults);
        generatePendingCompletedRows(res.data.result.pendingCompletedResults);
        generateStatuswiseRows(res.data.result.statuswiseResults);
        setShowLoader(false);
      })
      .catch(err => {
        setShowLoader(false);
        console.log("Error in /getShortCalls", err);
      });
  };
  useEffect(() => {
    getPendingOrdersStatistics();
  }, [brand]);
  const generateDatewiseRows = data => {
    const rows = [];
    for (const date in data) {
      const statistics = data[date];
      const row = { date, ...statistics };
      rows.push(row);
    }
    setDatewiseRows(rows);
  };
  const generateAttemptwiseRows = data => {
    const rows = [];
    for (const attempts in data) {
      const statistics = data[attempts];
      const row = { attempts, ...statistics };
      rows.push(row);
    }
    setAttemptwiseRows(rows);
  };

  const generateDoctorwiseRows = data => {
    const rows = Object.values(data);
    setDoctorwiseRows(rows);
  };
  const generatePendingCompletedRows = data => {
    const rows = Object.values(data);
    setPendingCompletedRows(rows);
  };
  const generateStatuswiseRows = data => {
    const rows = [];
    for (const date in data) {
      const row = { date, ...data[date] };
      rows.push(row);
    }
    setStatuswiseRows(rows);
  };
  const datewiseColumns = [
    { title: "Date", field: "date" },
    { title: "Hair Rx", field: "hair" },
    { title: "Performance Rx", field: "performance" },
    { title: "Skin Rx", field: "skin" },
    { title: "Others Rx", field: "others" },
    { title: "Total", field: "total" }
  ];
  const attemptwiseColumns = [
    { title: "Attempts", field: "attempts" },
    { title: "Hair Rx", field: "hair" },
    { title: "Performance Rx", field: "performance" },
    { title: "Skin Rx", field: "skin" },
    { title: "Others Rx", field: "others" },
    { title: "Total", field: "total" }
  ];

  const doctorwiseColumns = [
    { title: "Doctor", field: "doctorName" },
    { title: "Hair Rx", field: "hair" },
    { title: "Performance Rx", field: "performance" },
    { title: "Skin Rx", field: "skin" },
    { title: "Others Rx", field: "others" },
    { title: "Total", field: "total" }
  ];
  const pendingCompletedColumns = [
    { title: "Doctor", field: "doctorName" },
    { title: "Pending Completed", field: "pendingCompleted" }
  ];
  const statuswiseColumns = [
    { title: "Date", field: "date" },
    { title: "Open", field: "open" },
    { title: "Closed", field: "closed" },
    { title: "Total", field: "total" }
  ];

  const renderTab = () => {
    const navTabs = [
      { label: "DATE WISE", value: "DATEWISE" },
      { label: "ATTEMPT WISE", value: "ATTEMPTWISE" },
      { label: "DOCTOR WISE", value: "DOCTORWISE" },
      { label: "STATUS WISE", value: "STATUSWISE" },
      { label: "PENDING COMPLETED", value: "PENDINGCOMPLETED" }
    ];
    return (
      <div className="nav-tab" style={{ borderColor: "rgb(230,230,230)" }}>
        {navTabs.map((navTab, index) => {
          return (
            <div
              className={`tab ${tab === navTab.value ? "active" : ""}`}
              onClick={evt => setTab(navTab.value)}
              style={{ fontSize: "14px" }}
            >
              {navTab.label}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="short-calls">
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={fromDate}
          onChange={evt => setFromDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={toDate}
          onChange={evt => setToDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={getPendingOrdersStatistics}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>

      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <div>
          {renderTab()}
          <div hidden={tab !== "DATEWISE"}>
            <Table4
              title="Date Wise View"
              rows={datewiseRows}
              cols={datewiseColumns}
              pageSize={datewiseRows.length > 10 ? 10 : datewiseRows.length}
            />
          </div>
          <div hidden={tab !== "ATTEMPTWISE"}>
            <Table4
              title="Attempt Wise View"
              rows={attemptwiseRows}
              cols={attemptwiseColumns}
              pageSize={attemptwiseRows.length}
            />
          </div>
          <div hidden={tab !== "DOCTORWISE"}>
            <Table4
              title="Doctor Wise View"
              rows={doctorwiseRows}
              cols={doctorwiseColumns}
              pageSize={doctorwiseRows.length > 10 ? 10 : doctorwiseRows.length}
            />
          </div>
          <div hidden={tab !== "PENDINGCOMPLETED"}>
            <Table4
              title="Pending Completed"
              rows={pendingCompletedRows}
              cols={pendingCompletedColumns}
              pageSize={
                pendingCompletedRows.length > 10
                  ? 10
                  : pendingCompletedRows.length
              }
            />
          </div>
          <div hidden={tab !== "STATUSWISE"}>
            <Table4
              title="Status Wise View"
              rows={statuswiseRows}
              cols={statuswiseColumns}
              pageSize={statuswiseRows.length > 10 ? 10 : statuswiseRows.length}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default PendingOrdersView;

import React, { useState, useEffect, useContext, useMemo } from "react";
import { CurrentAppointmentContext } from "../CurrentAppointment/withSocketHOC";
import CONFIG_CONSTANTS from "../../../../constants/apiList";
import axios from "axios";
import Table6 from "../../../common/Tables/Table6";
import styles from "./styles.module.scss";
import { columns } from "./columns";
import { toast } from "react-toastify";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import {
  GTM_EVENTS,
  GTM_ENTRY_POINTS
} from "../../../../constants/tagManager.constants";
import { getRxWriterLink } from "../../../../helpers/rx-writer.helpers";

const PendingAppointments = ({}) => {
  const [_, __, doctorId] = useContext(CurrentAppointmentContext);
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const pollingIntervalId = useMemo(
    () => setInterval(() => getPendingAppointments(), 60 * 1000),
    []
  );

  const reassignAppointment = async (appointmentId, patientId) => {
    try {
      const data = {
        appointment_id: appointmentId,
        reassign_doctor_id: doctorId,
        patient_id: patientId
      };
      await axios.post(CONFIG_CONSTANTS.url.REASSIGN_DOCTOR, data);
      addToGTM({
        event: GTM_EVENTS?.REASSIGNED_APPOINTMENT
      });

      const standbyInitiateCallData = {
        appointment_id: appointmentId,
        stand_by_doctor_id: doctorId
      };
      await axios.post(
        CONFIG_CONSTANTS.url.STANDBY_INITIATE_CALL,
        standbyInitiateCallData
      );

      toast.success("System Call Initiate Request Sent Successfully");
      return true;
    } catch (err) {
      console.log("ERROR IN REASSIGN", err);
      return false;
    }
  };

  const assignAndOpenRx = async row => {
    const { brand, source, appointmentId, patientId } = row || {};
    const isReassigned = await reassignAppointment(appointmentId, patientId);
    if (!isReassigned) {
      return toast.error(
        "Unable to Assign the appointment. Kindly Refresh the page"
      );
    }
    getPendingAppointments();
    addToGTM({
      event: GTM_EVENTS?.RX_WRITER_OPENED,
      redirectDetails: {
        entryPoint: GTM_ENTRY_POINTS?.RX_WRITER_OPENED?.PENDING_APPPOINTMENT
      }
    });
    window &&
      window.open(
        getRxWriterLink(`${brand}-${source}-${appointmentId}`),
        "_blank"
      );
  };

  const prepareColumns = () => {
    const finalColumns = [
      {
        title: "Assign yourself",
        field: "assignYourself",
        render: row => (
          <button
            className={styles["assign-button"]}
            onClick={() => assignAndOpenRx(row)}
          >
            Assign yourself
          </button>
        )
      },
      ...columns
    ];
    return finalColumns;
  };

  const getPendingAppointments = async () => {
    if (doctorId === -1) return;
    try {
      const requestBody = { doctorId };
      const response = await axios.post(
        CONFIG_CONSTANTS.url.PENDING_APPOINTMENTS_FOR_STANDBY,
        requestBody
      );
      addToGTM({
        event: GTM_EVENTS?.PENDING_SECTION_VIEWED,
        pendingSectionData: {
          ...response?.data
        }
      });
      setPendingAppointments(response?.data?.data?.otherAppointments || []);
    } catch (err) {
      console.log("ERROR IN pendingAppointmentForStandby", err);
    }
  };

  useEffect(() => {
    getPendingAppointments();
    return () => clearInterval(pollingIntervalId);
  }, [doctorId]);

  return (
    <div className={styles["pending-appointments__main"]}>
      <Table6
        title="Pending Appointments"
        pageSize={100}
        cols={prepareColumns()}
        rows={pendingAppointments}
        search={true}
      />
    </div>
  );
};

export default PendingAppointments;

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCross,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import dataResource from "./../../../constants/dataResource";
import UtilityService from "./../../../services/utilities";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";
import Iframe from "react-iframe";
import { store } from "../../../reducers/Persist";
import CONFIG_CONSTANTS from "./../../../constants/apiList";
import { alertBox } from "./../../common/PopupBox/PopupBox";
import "./History.scss";

class History extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showModal: false,
      pdf: "",
      data: [],
      show: [],
      showData: [],
      recommendedProd: [],
      prodString: []
    };
    this.docId = store.getState().userReducer.hash_id;
  }

  handleClose() {
    this.setState({ showModal: false, pdf: "" });
  }

  renderQna = index => {
    let renderView = [];
    for (let [key, value] of Object.entries(this.state.showData[index].qna)) {
      let qnaView = (
        <div className="qna--single-box">
          <div className="qna--question">{key}</div>
          <div className="qna--answer">
            <span className="ans">A : </span> {value}
          </div>
        </div>
      );
      renderView.push(qnaView);
    }
    return renderView;
  };

  renderNewQna = index => {
    let form_details = this.state.showData[index].form_details;
    let data = {
      Hair: form_details.Hair,
      Weight: form_details.Weight,
      Skin: form_details.Skin,
      Performance: form_details.Performance,
      Fitness: form_details.Fitness,
      Nutrition: form_details.Nutrition,
      Stress: form_details.Stress,
      Sleep: form_details.Sleep,
      Lifestyle: form_details.Lifestyle,
      Wellness: form_details.Wellness,
      Health: form_details.Health
    };
    let renderView = [];
    for (let [key, value] of Object.entries(data)) {
      if (value && Object.keys(value).length > 0) {
        let qnaHead = (
          <div>
            <br></br>
            <p>
              <u>
                <b>{key}</b>
              </u>
            </p>
          </div>
        );
        renderView.push(qnaHead);
        for (let [key2, value2] of Object.entries(value)) {
          if (Object.keys(value).length > 0) {
            let qnaView = (
              <div>
                <div>Q :{key2}</div>
                <div>
                  <span className="ans">A : </span> {value2}
                </div>
                <br />
              </div>
            );
            renderView.push(qnaView);
          }
        }
      }
    }
    console.log(renderView);
    return renderView;
  };
  componentDidMount() {
    let data = {
      email: this.props.userInfo.data.email,
      doctorId: store.getState().userReducer.hash_id,
      brand: this.props.brand
    };
    //CONFIG_CONSTANTS.url.GET_HISTORY
    //CONFIG_CONSTANTS.url.GET_HISTORY
    //Fetching all events and storing them in this.state.data array
    let temp =
      "https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/eventInformation";
    axios.post(CONFIG_CONSTANTS.url.GET_HISTORY, data).then(resp => {
      if (resp.data.statusCode !== 200) {
        this.props.goBack();
        alertBox("Something went wrong..could not retrieve patient history");
        return;
      }
      this.setState({ data: resp.data.body.data }, () => {
        this.state.data.sort(this.dateSortFn);
        this.state.data.forEach((element, index) => {
          this.state.show[index] = false;
          let temp = this.state.show;
          temp[index] = false;
          this.setState({ show: temp });
        });
      });
    });
  }
  handleModal = (e, prescription_id) => {
    e.preventDefault();
    let data = {
      doctorId: store.getState().userReducer.hash_id,
      prescriptionId: prescription_id,
      brand: this.props.brand
    };
    axios.post(CONFIG_CONSTANTS.url.GET_PDF_URL, data).then(response => {
      this.setState({ pdf: response.data.body.data });
      this.setState({ showModal: true });
    });
  };
  handleFormSubmit = (e, data, index) => {
    e.preventDefault();
    axios
      .post(CONFIG_CONSTANTS.url.FORM_SUBMIT_EVENT_URL, data)
      .then(response => {
        // if (response.data.statusCode !== 200){
        //   alertBox("cannot retrieve data")
        //   return;
        // }
        //showData is an array containing the data that has to be shown for each event in each endex of showData
        let holder = this.state.showData;
        holder[index] = response.data;
        console.log(holder[index]);
        this.setState({ showData: holder });
        //show is an array which contains flag in each index which tells us whether particular event has to show more or not
        let temp = this.state.show;
        temp[index] = !this.state.show[index];
        this.setState({ show: temp });
      });
  };

  handleOrderPlaced = (e, data, index) => {
    console.log(this.props.userInfo);
    e.preventDefault();
    // let url = `https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/order-details`
    axios.post(CONFIG_CONSTANTS.url.ORDER_DETAILS_URL, data).then(response => {
      console.log("response.data");
      console.log(response.data);
      if (!response.data) {
        alertBox("cannot retrieve data");
        return;
      }
      let holder = this.state.showData;
      holder[index] = response.data;
      this.setState({ showData: holder });
      let temp = this.state.show;
      temp[index] = !this.state.show[index];
      this.setState({ show: temp });
      let recommended = [];
      let products = "";
      response.data.body.data.product_kits.map((prod, index) => {
        prod.product_items.map((singleItem, index) => {
          recommended.push(singleItem.name);
          products = products + singleItem.name + "," + " ";
        });
      });
      response.data.body.data.product_items.map((prod, index) => {
        // prod.product_items.map((singleItem,index)=>{
        //   recommended.push(singleItem.name);
        // })
        recommended.push(prod.name);
      });
      //console.log(recommended);
      let recommendedArr = this.state.recommendedProd;
      recommendedArr[index] = recommended.toString();
      this.setState({ recommendedProd: recommendedArr });
    });
  };
  handleExpand = (e, data, index) => {
    e.preventDefault();
    axios.post(CONFIG_CONSTANTS.url.PRESCRIPTION_URL, data).then(response => {
      if (response.data.statusCode != 200) {
        alertBox("cannot retrieve data");
        return;
      }
      //showData is an array containing the data that has to be shown for each event in each endex of showData
      let holder = this.state.showData;
      holder[index] = response.data.body.data;
      this.setState({ showData: holder });
      //show is an array which contains flag in each index which tells us whether particular event has to show more or not
      let temp = this.state.show;
      temp[index] = !this.state.show[index];
      this.setState({ show: temp });
      let recommended = [];
      let products = "";
      response.data.body.data.product_kits.map((prod, index) => {
        prod.product_items.map((singleItem, index) => {
          recommended.push(singleItem.name);
          products = products + singleItem.name + "," + " ";
        });
        response.data.body.data.product_items.map((prod, index) => {
          // prod.product_items.map((singleItem,index)=>{
          //   recommended.push(singleItem.name);
          // })
          recommended.push(prod.name);
        });
        //console.log(recommended);
        let recommendedArr = this.state.recommendedProd;
        recommendedArr[index] = recommended.toString();
        this.setState({ recommendedProd: recommendedArr });
      });
    });
  };

  dateSortFn = (a, b) => {
    return new Date(b.created_dttm) - new Date(a.created_dttm);
  };

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  renderPreviousSummary = () => {
    let renderSummary = false;
    var htmlElem;
    this.state.data.forEach((item, index) => {
      if (item.event_type == "prescription_generated") {
      }
    });
    for (var i = 0; i < this.state.data.length; i++) {
      let item = this.state.data[i];
      if (item.event_type == "prescription_generated" && item.complaints) {
        renderSummary = true;
        htmlElem = (
          <div className="summary">
            <h5>History</h5>
            <p>{item.history}</p>
            <h5>Complaints</h5>
            <p>{item.complaints}</p>
            <h5>Diagnosis</h5>
            <p>{item.diagnosis}</p>
          </div>
        );
        break;
      }
    }
    if (renderSummary == true) return htmlElem;
    else return null;
  };

  renderDiv = () => {
    let renderView = [];
    var htmlElem;
    let url = window.location.href;
    let urlArr = url.split("/");
    let userId = urlArr[urlArr.length - 1];
    userId =
      userId.charAt(0).toUpperCase() +
      userId.charAt(1).toUpperCase() +
      userId.slice(2);
    //console.log(ans);
    this.state.data.map((item, index) => {
      if (item.event_type == "order_placed") {
        let data = {
          doctor_id: store.getState().userReducer.hash_id,
          form: item.category ? item.category : "OF",
          order_id: item.form_id ? item.form_id : item.order_id,
          event_type: "order_placed",
          brand: this.props.brand
        };
        console.log(data);
        let titleArr = item.event_type.split("_");
        let title = "";
        for (var i = 0; i < titleArr.length; i++) {
          let str = titleArr[i];
          let temp = str.charAt(0).toUpperCase() + str.slice(1);
          title = title + temp + " ";
        }
        //let newId="idk";
        let newId = data.form.toUpperCase() + "-" + data.id;
        title = title + ": " + newId;
        let orderStatus = item.order_status.split("_");
        let status = "";
        for (var i = 0; i < orderStatus.length; i++) {
          let str = orderStatus[i];
          let temp = str.charAt(0).toUpperCase() + str.slice(1);
          status = status + temp + " ";
        }
        let titleTemp = title.split("-");
        let orderTitle = titleTemp[0] + "-" + data.order_id;

        htmlElem = (
          <div key={index}>
            <div className="historybox raisedbox">
              <Button
                className="toggleButton btn-sm"
                variant="clear"
                onClick={e => this.handleOrderPlaced(e, data, index)}
              >
                {this.state.show[index] ? "Hide" : "Show"}
              </Button>
              {/* <h5>{title.split("-")[0] - data.form}</h5> */}
              <h5>{orderTitle}</h5>
              <p>{UtilityService.formatISTDateforDisplay(item.created_dttm)}</p>
              <p>
                Order Status:<strong>{status}</strong>
              </p>
              {item.type == "RX" ? (
                <p>
                  Appointment Schedule:{" "}
                  <strong>{item.appointment_schedule}</strong>.
                </p>
              ) : null}
              <p>
                {this.props.userInfo.data.name} filled the Order Form on{" "}
                <strong>
                  {" "}
                  {UtilityService.formatISTDateforDisplay(item.created_dttm)}
                </strong>
                .{"\n"}{" "}
              </p>
              {item.type == "RX" ? (
                <p>
                  The consultation was assigned to{" "}
                  <strong>{item.doctor_assigned}</strong>.
                </p>
              ) : null}
              {this.state.show[index] ? (
                <div>
                  <div>
                    Purchased Products :{"\n"}
                    <br></br>
                    <strong>{this.state.recommendedProd[index]}</strong>
                  </div>
                  <br></br>
                  {this.state.showData[index].qna ? <h5>QnA</h5> : null}

                  {this.state.showData[index].qna
                    ? this.renderQna(index)
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        );
      } else if (
        item.event_type == "hair_form_filled" ||
        item.event_type == "performance_form_filled"
      ) {
        let data = {
          doctorId: store.getState().userReducer.hash_id,
          form: item.category ? item.category : "OF",
          id: item.form_id ? item.form_id : item.order_id
        };
        let titleArr = item.event_type.split("_");
        let title = "";
        for (var i = 0; i < titleArr.length; i++) {
          let str = titleArr[i];
          let temp = str.charAt(0).toUpperCase() + str.slice(1);
          title = title + temp + " ";
        }
        let newId = data.form.toUpperCase() + "-" + data.id;
        title = title + ": " + newId;

        htmlElem = (
          <div key={index}>
            <div className="historybox raisedbox">
              {item.form_id ? (
                <Button
                  className="toggleButton btn-sm"
                  variant="clear"
                  onClick={e => this.handleExpand(e, data, index)}
                >
                  {this.state.show[index] ? "Hide" : "Show"}
                </Button>
              ) : null}
              <h5>{title}</h5>
              <p>{UtilityService.formatISTDateforDisplay(item.created_dttm)}</p>
              <p>
                {this.props.userInfo.data.name} filled the {titleArr[0]} form on{" "}
                <strong>
                  {" "}
                  {UtilityService.formatISTDateforDisplay(item.created_dttm)}
                </strong>{" "}
                and was recommended <strong>{}</strong>.{"\n"} The consultation
                was assigned to <strong>{item.doctor_assigned}</strong>.
              </p>
              {this.state.show[index] ? (
                <div>
                  <div>
                    Recommended Products :{"\n"}
                    <br></br>
                    <strong>{this.state.recommendedProd[index]}</strong>
                  </div>
                  <br></br>
                  <br></br>
                  {this.state.showData[index].qna ? <h5>QnA</h5> : null}

                  {this.state.showData[index].qna
                    ? this.renderQna(index)
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        );
      } else if (item.event_type == "form_submmited") {
        let titleArr = item.event_type.split("_");
        let title = "";
        for (var i = 0; i < titleArr.length; i++) {
          let str = titleArr[i];
          let temp = str.charAt(0).toUpperCase() + str.slice(1);
          title = title + temp + " ";
          let data = {
            brand: this.props.brand,
            doctorId: store.getState().userReducer.hash_id,
            formID: item.form_id
          };
          htmlElem = (
            <div key={index}>
              <div className="historybox raisedbox">
                {item.form_id ? (
                  <Button
                    className="toggleButton btn-sm"
                    variant="clear"
                    onClick={e => this.handleFormSubmit(e, data, index)}
                  >
                    {this.state.show[index] ? "Hide" : "Show"}
                  </Button>
                ) : null}
                <h5>{title}</h5>
                <p>
                  {UtilityService.formatISTDateforDisplay(item.created_dttm)}
                </p>
                <p>
                  {this.props.userInfo.data.name} filled the {titleArr[0]} form
                  on{" "}
                  <strong>
                    {" "}
                    {UtilityService.formatISTDateforDisplay(item.created_dttm)}
                  </strong>
                  .
                </p>
                {this.state.show[index] ? (
                  <div>
                    {this.state.showData[index].data ? <h5>QnA</h5> : null}

                    {this.state.showData[index].form_details
                      ? this.renderNewQna(index)
                      : null}
                  </div>
                ) : null}
              </div>
            </div>
          );
        }
      } else if (item.event_type == "appointment_rescheduled") {
        let titleArr = item.event_type.split("_");
        let title = "";
        for (var i = 0; i < titleArr.length; i++) {
          let str = titleArr[i];
          let temp = str.charAt(0).toUpperCase() + str.slice(1);
          title = title + temp + " ";
        }

        htmlElem = (
          <div key={index}>
            <div className="historybox raisedbox">
              <h5>{title}</h5>
              <p>{UtilityService.formatISTDateforDisplay(item.created_dttm)}</p>

              <p>
                {" "}
                <strong>New Appointment :{item.new_appointment}</strong>
              </p>
              <p> Rescheduled By:{item.rescheduleBy} </p>
              <p>Previous Appointment :{item.previous_appointmnet}</p>
            </div>
          </div>
        );
      } else if (item.event_type == "appointment_booked") {
        let titleArr = item.event_type.split("_");
        let title = "";
        for (var i = 0; i < titleArr.length; i++) {
          let str = titleArr[i];
          let temp = str.charAt(0).toUpperCase() + str.slice(1);
          title = title + temp + " ";
        }

        htmlElem = (
          <div key={index}>
            <div className="historybox raisedbox">
              <h5>{title}</h5>
              <p>{UtilityService.formatISTDateforDisplay(item.created_dttm)}</p>
              <p>
                Appointment Schedule:
                <strong>{item.appointment_schedule}</strong>
              </p>
            </div>
          </div>
        );
      } else if (item.event_type == "prescription_generated") {
        let titleArr = item.event_type.split("_");
        let title = "";
        for (var i = 0; i < titleArr.length; i++) {
          let str = titleArr[i];
          let temp = str.charAt(0).toUpperCase() + str.slice(1);
          title = title + temp + " ";
        }

        htmlElem = (
          <div key={index}>
            <div className="historybox raisedbox">
              <Button
                className="toggleButton btn-sm"
                variant="clear"
                onClick={e => this.handleModal(e, item.prescription_id)}
              >
                {this.state.showModal ? "Hide" : "Show"}
              </Button>
              <h5>{title}</h5>
              <p>{UtilityService.formatISTDateforDisplay(item.created_dttm)}</p>
              <p>Prescription has been successfully generated.</p>
              {this.state.showModal ? (
                <div>
                  <Modal
                    className="xyz"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showModal}
                    onHide={this.handleClose}
                  >
                    <Modal.Body className="mdl-body">
                      {!this.state.pdf && (
                        <div className="loader-cm">
                          Loading Prescription...Please Wait
                          <div className="Loader-box">
                            <Loader
                              type="TailSpin"
                              color="#00BFFF"
                              height={30}
                              width={30}
                            />
                          </div>
                        </div>
                      )}
                      <Iframe
                        url={this.state.pdf}
                        width="700px"
                        height="700px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              ) : null}
            </div>
          </div>
        );
      }
      renderView.push(htmlElem);
    });
    if (renderView.length == 0) {
      htmlElem = (
        <div>
          <h1>Loading</h1>
        </div>
      );
      renderView.push(htmlElem);
    }
    return renderView;
  };
  render() {
    return (
      <section className="history-sec-box" data-test="history-container">
        <div className="Header">
          <Button
            onClick={() => this.props.goBack()}
            variant="light"
            className="hsb--back"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div>{this.renderPreviousSummary()}</div>
        <div className="container">{this.renderDiv()}</div>
      </section>
    );
  }
}

export default History;

import React, { useState, useEffect } from "react";
import "./styles.scss";
import { store } from "../../../../../reducers/Persist";
import RxWriterInputBox from "../../../common/RxWriterInputBox/RxwriterInputBox";
import { useSelector } from "react-redux";
const DoctorNotes = () => {
  const apptId = useSelector(state => state.rxWriterReducer.id);
  const doctorNotes = useSelector(
    state => state.observationReducer[apptId].doctorNotes
  );
  const brand = useSelector(state => state.rxWriterReducer.brand);

  const { presetData } = useSelector(state => state.rxWriterReducer);
  const [presetDoctorNotesData, setPresetDoctorNotesData] = useState([]);
  useEffect(() => {
    let arrData = [];
    for (const i in presetData) {
      if (
        presetData[i].observation_type === "doctor_notes" &&
        brand === presetData[i].brand
      ) {
        arrData.push(presetData[i]);
      }
    }
    setPresetDoctorNotesData(arrData);
  }, [presetData, brand]);

  const handlePresetSelect = value => {
    store.dispatch({
      type: "SET_DOCTOR_NOTES_PRETEXT",
      payload: value
    });
  };

  return (
    <div className="doctorNotes">
      <RxWriterInputBox
        headerTitle="Doctor Notes"
        isStrong={true}
        mainPresetData={presetDoctorNotesData}
        onChangeInput={text =>
          store.dispatch({ type: "SET_DOCTOR_NOTES_TEXT", payload: text })
        }
        presetValue={doctorNotes.pretext}
        textValue={doctorNotes.text}
        inputPlaceHolder="Click to start typing"
        inputBoxHeight={100}
        onPresetSelect={value => {
          handlePresetSelect(value);
        }}
      />
    </div>
  );
};

export default DoctorNotes;

import React, { useMemo, useState } from "react";
import MaterialTable from "material-table";
import Select from "react-select";

export default function CustomizableHeaderTable({
  title,
  cols,
  rows,
  pageSize,
  search,
  pagin,
  selectOptions,
  defaultSelection,
  setDivideDuration
}) {
  const [option, setOption] = useState({ label: "8 mins", value: 8 });
  function handleOptionSelected(selectedOption) {
    setOption(selectedOption);
    setDivideDuration(selectedOption.value);
  }
  return (
    <div id={title.replace(/\s/g, "").toLowerCase()}>
      <MaterialTable
        columns={cols}
        data={useMemo(() => rows, [rows])}
        title={title}
        options={{
          exportButton: true,
          exportAllData: true,
          pageSize: pageSize ? pageSize : 1,
          paging: pagin === undefined ? true : false,
          search: search ? true : false,
          sorting: false,
          headerStyle: {
            backgroundColor: "#003459",
            color: "#FFF"
          }
        }}
        style={{ margin: "20px 10px 10px" }}
        components={{
          Action: props => (
            <div
              style={{
                width: "160px",
                zIndex: "10000",
                position: "absolute",
                right: "62px",
                margin: "0",
                bottom: "11px"
              }}
            >
              <Select
                name="Call duration"
                value={option}
                options={selectOptions}
                defaultValue={defaultSelection}
                onChange={option => handleOptionSelected(option)}
              />
            </div>
          )
        }}
        actions={[
          {
            isFreeAction: true
          }
        ]}
      />
    </div>
  );
}

import Axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import CONFIG_CONSTANTS from "./../../../constants/apiList";
import Tooltip from "react-simple-tooltip";
const ActiveStatus = () => {
  let intervals = {
    "08:00:00": false,
    "08:05:00": false,
    "08:10:00": false,
    "08:15:00": false,
    "08:20:00": false,
    "08:25:00": false,
    "08:30:00": false,
    "08:35:00": false,
    "08:40:00": false,
    "08:45:00": false,
    "08:50:00": false,
    "08:55:00": false,
    "09:00:00": false,
    "09:05:00": false,
    "09:10:00": false,
    "09:15:00": false,
    "09:20:00": false,
    "09:25:00": false,
    "09:30:00": false,
    "09:35:00": false,
    "09:40:00": false,
    "09:45:00": false,
    "09:50:00": false,
    "09:55:00": false,
    "10:00:00": false,
    "10:05:00": false,
    "10:10:00": false,
    "10:15:00": false,
    "10:20:00": false,
    "10:25:00": false,
    "10:30:00": false,
    "10:35:00": false,
    "10:40:00": false,
    "10:45:00": false,
    "10:50:00": false,
    "10:55:00": false,
    "11:00:00": false,
    "11:05:00": false,
    "11:10:00": false,
    "11:15:00": false,
    "11:20:00": false,
    "11:25:00": false,
    "11:30:00": false,
    "11:35:00": false,
    "11:40:00": false,
    "11:45:00": false,
    "11:50:00": false,
    "11:55:00": false,
    "12:00:00": false,
    "12:05:00": false,
    "12:10:00": false,
    "12:15:00": false,
    "12:20:00": false,
    "12:25:00": false,
    "12:30:00": false,
    "12:35:00": false,
    "12:40:00": false,
    "12:45:00": false,
    "12:50:00": false,
    "12:55:00": false,
    "13:00:00": false,
    "13:05:00": false,
    "13:10:00": false,
    "13:15:00": false,
    "13:20:00": false,
    "13:25:00": false,
    "13:30:00": false,
    "13:35:00": false,
    "13:40:00": false,
    "13:45:00": false,
    "13:50:00": false,
    "13:55:00": false,
    "14:00:00": false,
    "14:05:00": false,
    "14:10:00": false,
    "14:15:00": false,
    "14:20:00": false,
    "14:25:00": false,
    "14:30:00": false,
    "14:35:00": false,
    "14:40:00": false,
    "14:45:00": false,
    "14:50:00": false,
    "14:55:00": false,
    "15:00:00": false,
    "15:05:00": false,
    "15:10:00": false,
    "15:15:00": false,
    "15:20:00": false,
    "15:25:00": false,
    "15:30:00": false,
    "15:35:00": false,
    "15:40:00": false,
    "15:45:00": false,
    "15:50:00": false,
    "15:55:00": false,
    "16:00:00": false,
    "16:05:00": false,
    "16:10:00": false,
    "16:15:00": false,
    "16:20:00": false,
    "16:25:00": false,
    "16:30:00": false,
    "16:35:00": false,
    "16:40:00": false,
    "16:45:00": false,
    "16:50:00": false,
    "16:55:00": false,
    "17:00:00": false,
    "17:05:00": false,
    "17:10:00": false,
    "17:15:00": false,
    "17:20:00": false,
    "17:25:00": false,
    "17:30:00": false,
    "17:35:00": false,
    "17:40:00": false,
    "17:45:00": false,
    "17:50:00": false,
    "17:55:00": false,
    "18:00:00": false,
    "18:05:00": false,
    "18:10:00": false,
    "18:15:00": false,
    "18:20:00": false,
    "18:25:00": false,
    "18:30:00": false,
    "18:35:00": false,
    "18:40:00": false,
    "18:45:00": false,
    "18:50:00": false,
    "18:55:00": false,
    "19:00:00": false,
    "19:05:00": false,
    "19:10:00": false,
    "19:15:00": false,
    "19:20:00": false,
    "19:25:00": false,
    "19:30:00": false,
    "19:35:00": false,
    "19:40:00": false,
    "19:45:00": false,
    "19:50:00": false,
    "19:55:00": false,
    "20:00:00": false,
    "20:05:00": false,
    "20:10:00": false,
    "20:15:00": false,
    "20:20:00": false,
    "20:25:00": false,
    "20:30:00": false,
    "20:35:00": false,
    "20:40:00": false,
    "20:45:00": false,
    "20:50:00": false,
    "20:55:00": false,
    "21:00:00": false,
    "21:05:00": false,
    "21:10:00": false,
    "21:15:00": false,
    "21:20:00": false,
    "21:25:00": false,
    "21:30:00": false,
    "21:35:00": false,
    "21:40:00": false,
    "21:45:00": false,
    "21:50:00": false,
    "21:55:00": false
  };
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  let d = new Date(new Date().setDate(new Date().getDate() - 30));
  const minDate =
    d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    Axios.post(CONFIG_CONSTANTS.url.DOCTOR_ONLINE_STATUS, { date })
      .then(res => {
        setTableData(res.data.body.activeDoctors);
      })
      .catch(err => console.log(err));
  }, [date]);
  return (
    <>
      <input
        type="date"
        className="input"
        value={date}
        onChange={e => setDate(e.target.value)}
        min={minDate}
        max={new Date().toISOString().substring(0, 10)}
      />
      <br />
      <MaterialTable
        title="Active Status"
        columns={[
          { title: "Doctor Name", field: "doctor_name", width: "15%" },
          {
            title: "Availability Status (8am-10pm)",
            field: "intervals",
            width: "85%",
            render: rowData => {
              return (
                <>
                  {Object.keys(intervals).map(item => {
                    return (
                      <Tooltip
                        content={
                          item.substring(0, 5) +
                          " " +
                          (rowData.intervals[item] === true
                            ? "Active"
                            : "Inactive")
                        }
                        zIndex={9999}
                      >
                        <div
                          key={item}
                          className={
                            rowData.intervals[item] === true
                              ? "statusLineGreen"
                              : "statusLineRed"
                          }
                        ></div>
                      </Tooltip>
                    );
                  })}
                  <br />
                  {Object.keys(intervals).map(item => {
                    if (
                      item.substring(3, 5) === "00" ||
                      item.substring(3, 5) === "30"
                    )
                      return (
                        <div
                          style={{
                            display: "inline-block",
                            width: "48px",
                            fontSize: "12px"
                          }}
                        >
                          {" "}
                          {item.substring(0, 5)}
                        </div>
                      );
                  })}
                </>
              );
            }
          }
        ]}
        data={tableData}
        options={{
          tableLayout: "auto",
          pageSize: 10,
          pageSizeOptions: [10, 25]
        }}
      />
    </>
  );
};
export default ActiveStatus;

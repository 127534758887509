import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import { useAsync } from "../../../hooks";
import Table1 from "./Table1";
import dataResource from "../../../constants/dataResource";
const statusListObj = dataResource.statusListObj;
export default function DataTable1({
  title,
  cols,
  url,
  reqBody,
  actions,
  rowStyle,
  search
}) {
  const [data] = useAsync(url, reqBody);
  const [columns, setColumns] = useState(cols);
  useEffect(() => {
    axios
      .post(CONFIG_CONSTANTS.url.DOCTOR_LIST_URL, {
        admin_key: localStorage.getItem("adminKey")
      })
      .then(
        response => {
          let res = {};
          response.data.results.map((item, index) => {
            res = { ...res, [item["doctor_id"]]: item["doctor_name"] };
          });
          const tempColumns = cols.map(col => {
            if (col.title == "status") {
              return {
                title: col.title,
                field: col.field,
                render: row => statusListObj[row.status]
              };
            }
            return {
              title: col.title,
              field: col.field
            };
          });
          tempColumns[0]["lookup"] = res;
          setColumns(tempColumns);
          // scheduleCols[scheduleCols.length - 3]["lookup"] = res;
        },
        err => {
          console.log(err);
        }
      );
  }, []);
  return data ? (
    <Table1
      title={title}
      cols={columns}
      rows={data}
      actions={actions ? actions : []}
      rowStyle={rowStyle}
      search={search}
    />
  ) : null;
}

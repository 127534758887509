import React from "react";
import {
  DoctorLogDivContainer,
  DoctorIdSection,
  SectionHeaderContainer,
  ActiveHoursContainer,
  ActiveHoursHeader,
  OOCHoursContainer,
  OOCHoursHeader,
  DoctorLogDivParentContainer
} from "./styles";
import OOCHours from "./ooc-hours";
import ActiveHours from "./active-hours";
import StatusLogsViewer from "./logs-viewer";
import SectionBodyContainer from "react-indiana-drag-scroll";
import CurrentStatus from "./currentStatus";

export default function DoctorLog({ data, selectedDate, requestSuccessTime }) {
  const [isScrolling, setIsScrolling] = React.useState(false);
  return (
    <DoctorLogDivParentContainer>
      <DoctorLogDivContainer>
        <SectionHeaderContainer>
          <DoctorIdSection>
            {data?.doctorName ?? "N/A"}{" "}
            <span style={{ marginLeft: "5px" }}>
              ({data?.doctorId ?? "N/A"})
            </span>{" "}
            <CurrentStatus
              doctorStatus={data?.doctorStatus}
              selectedDate={selectedDate}
              requestSuccessTime={requestSuccessTime}
            />
          </DoctorIdSection>
          {data?.activeHours && data?.activeHours?.length > 0 && (
            <ActiveHoursContainer>
              <ActiveHoursHeader>Active Hours: </ActiveHoursHeader>
              <ActiveHours data={data?.activeHours ?? []} />
            </ActiveHoursContainer>
          )}
        </SectionHeaderContainer>
        <SectionBodyContainer
          style={{
            width: "85%",
            cursor: isScrolling ? "grabbing" : "grab"
          }}
          onStartScroll={() => setIsScrolling(true)}
          onEndScroll={() => setIsScrolling(false)}
        >
          <StatusLogsViewer logs={data?.logs ?? []} />
        </SectionBodyContainer>
      </DoctorLogDivContainer>
      {data?.outOfOfficeHours && data?.outOfOfficeHours?.length > 0 && (
        <OOCHoursContainer>
          <OOCHoursHeader>OOC Hours: </OOCHoursHeader>
          <OOCHours data={data?.outOfOfficeHours ?? []} />
        </OOCHoursContainer>
      )}
    </DoctorLogDivParentContainer>
  );
}

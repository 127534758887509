import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToGTM } from "../../../helpers/tagManager.helper";
import { toast } from "react-toastify";
import { GTM_EVENTS } from "../../../constants/tagManager.constants";
import axios from "axios";
import config from "../../../constants/apiList";
import * as Sentry from "@sentry/react";
import LoadingLogin from "./LoadingLogin";
import "./OtpLoginModal.scss";
export default function OtpLogin({}) {
  const history = useHistory();
  const [otpSent, setOtpSent] = useState(false);
  const [callback, setCallback] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [description, setDescription] = useState(
    "OTP will be sent to registered phone number"
  );
  const [descriptionClass, setDescriptionClass] = useState("success");
  const [placeHolder, setPlaceHolder] = useState("Enter Email");
  const [input, setInput] = useState("");
  const [identifier, setIdentifier] = useState("");
  const dispatch = useDispatch();
  const setResponse = response => {
    localStorage.setItem("jwt", response.data.jwt);
    if (process.env.REACT_APP_ENV === "production") {
      Sentry.setUser({
        email: payload?.doctor_email,
        id: payload?.doctor_id
      });
    }
    const payload = response.data.user;
    dispatch({ type: "USER", payload: payload });
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("jwt");
    switch (payload.role_name) {
      case "admin":
        history.push("/admin");
        break;
      case "doctor":
        history.push("/dashboard");
        break;
      case "assistant":
        history.push("/cs");
        break;
      default: {
        history.push("/login");
        break;
      }
    }
  };

  const logoutOnError = async () => {
    localStorage.removeItem("jwt");
    await axios.get(config.AUTH.LOGOUT, { withCredentials: true });
    dispatch({ type: "CLEAR" });
    history.push("/login");
  };

  const setCreds = async verifierdResponse => {
    try {
      if (verifierdResponse.errorCode === 200) {
        setResponse(verifierdResponse);
        const requestData = {
          doctor_id: verifierdResponse?.data?.user?.hash_id
        };
        axios.post(config.url.GET_DOCTOR_INFO_URL, requestData).then(res => {
          if (res.status === 200) {
            addToGTM({
              event: GTM_EVENTS?.LOGIN,
              login: {
                doctor_phone: res?.data?.body?.data?.doctor_phone,
                ...(verifierdResponse?.data?.user || {})
              }
            });
            if (window?.clevertap) {
              window.clevertap.onUserLogin.push({
                Site: {
                  Name: verifierdResponse?.data?.user?.doctor_name,
                  Identity: res?.data?.body?.data?.doctor_id,
                  Phone: "+91" + res?.data?.body?.data?.doctor_phone
                }
              });
            }
          }
        });
      }
    } catch (e) {
      toast.error("Something went wrong");
      logoutOnError();
    }
  };
  const handleSubmit = async () => {
    if (!otpSent) {
      setDisableSubmit(true);
      const data = await sendOtp(input);
      if (!data) {
        setInput("");
        setDescription("User does not exist.");
        setPlaceHolder("Enter Valid Email");
        setDescriptionClass("danger");
      } else {
        setDescriptionClass("success");
        setInput("");
        setDescription(
          data?.data?.data?.message || "OTP sent via SMS and Whatsapp"
        );
        setPlaceHolder("OTP");
        setShowResend(true);
        setIdentifier(input);
        setOtpSent(true);
      }
      setDisableSubmit(false);
    } else {
      const { data: verifyData } = await verifyOtp(identifier, input);
      if (verifyData?.data?.jwt) {
        setCallback(true);
        setCreds(verifyData);
        // setDescription("OTP Verified");
      } else {
        setDescriptionClass("danger");
        setDescription("Wrong OTP, Try again");
        setInput("");
      }
    }
  };

  const inputChange = e => {
    setInput(e?.target?.value || "");
  };
  const resendOtp = async () => {
    setDisableResend(true);
    const data = await sendOtp(identifier);
    setDescription(
      data?.data?.data?.message || "OTP sent via SMS and Whatsapp"
    );
    setDisableResend(false);
  };
  const callBackModal = <div>{<LoadingLogin message={"Logging in"} />}</div>;
  const form = (
    <div className="otp-login-container">
      <div className="otp-login-label">Login with OTP</div>
      <div>
        <input
          className="otp-input"
          onChange={inputChange}
          type="text"
          placeholder={placeHolder}
          value={input}
        />
        <div className={descriptionClass}>{description}</div>
      </div>
      <div className="button-container">
        <button
          className="action-button"
          disabled={!input?.length || disableSubmit}
          onClick={handleSubmit}
        >
          Submit
        </button>
        {showResend && (
          <button
            disabled={disableResend}
            className="action-button resend-btn"
            onClick={resendOtp}
          >
            Resend
          </button>
        )}
      </div>
    </div>
  );
  return callback ? callBackModal : form;
}
const sendOtp = async identifier => {
  try {
    return await axios.post(config.url.DOC_SEND_OTP, { identifier });
  } catch (e) {
    return false;
  }
};
const verifyOtp = async (identifier, otp) => {
  try {
    return await axios.post(
      config.url.DOC_VERIFY_OTP,
      { identifier, otp },
      { withCredentials: true }
    );
  } catch (e) {
    return false;
  }
};

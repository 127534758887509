import React, { useState, useEffect } from "react";
import "./styles.scss";
import { store } from "../../../../../reducers/Persist";
import { useSelector } from "react-redux";

export default function MaritalStatus(props) {
  const apptId = useSelector(state => state.rxWriterReducer.id);
  let weight = 1;
  const marital_status = useSelector(
    state => state.rxWriterReducer.patientDetails.marital_status
  );
  const [marital_status_state, setMarital_Status_State] =
    useState(marital_status);
  const state = useSelector(state => state.rxWriterReducer);
  let maritalStatusArr = ["Married", "Unmarried", "Others"];
  const curHeight = useSelector(
    state => state.observationReducer[apptId].height
  );
  const currentBMI = () => {
    const feet = parseInt(curHeight.split("'")[0]);
    const inches = parseInt(curHeight.split("'")[1].split('"')[0]);
    const totalInches = feet * 12 + inches;
    const metres = totalInches * 0.0254;
    return (weight / (metres * metres)).toFixed(2);
  };
  const [heightArr, setHeightArr] = useState([]);
  useEffect(() => {
    const tempArr = [];
    for (let i = 6; i < 12; i++) tempArr.push(`4'${i}''`);
    for (let i = 0; i < 12; i++) tempArr.push(`5'${i}''`);
    for (let i = 0; i < 7; i++) tempArr.push(`6'${i}''`);
    setHeightArr(tempArr);
  }, []);
  return (
    <div className="maritalStatus">
      <span>Marital Status</span>
      <select
        value={marital_status_state}
        onChange={e => {
          setMarital_Status_State(e.target.value);
          store.dispatch({
            type: "CHANGE_MARITAL_STATUS",
            payload: {
              marital_status: e.target.value
            }
          });
        }}
      >
        {maritalStatusArr.map((val, index) => (
          <option key={index} value={`${val}`}>
            {val}
          </option>
        ))}
      </select>
    </div>
  );
}

import styled from "styled-components";

export const LegendsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LegendBody = styled.div`
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-out;
  &:hover {
    position: relative;
    top: 10px;
    background-color: white;
    padding: 5px;
    border-radius: 50px;
    transform: scale(2);
  }
`;

export const LegendTitle = styled.span`
  font-size: 14px;
`;

export const LegendColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${props => props.color};
`;

const initialState = {
  currentTab: 0,
  unlockedTabs: {
    patientFile: true,
    observations: false,
    prescription: false // should be made false before deploying to prod
  }
};

const footerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_TAB":
      return { ...state, currentTab: action.data };
    case "UNLOCK_OBSERVATION_TAB":
      return {
        ...state,
        unlockedTabs: { ...state.unlockedTabs, observations: true }
      };
    case "UNLOCK_PRESCRIPTION_TAB":
      return {
        ...state,
        unlockedTabs: { ...state.unlockedTabs, prescription: true }
      };
    default:
      return { ...state };
  }
};

export default footerReducer;

import React, { useState } from "react";
import moment from "moment";

import DataTable2 from "../../common/Tables/DataTable2";

export default function CustomDateTable({ title, cols, url, reqBody }) {
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const reqBodyLoc = {
    ...reqBody,
    startDate: Number(moment(startDate).utcOffset(330).format("x")),
    endDate:
      Number(moment(endDate).utcOffset(330).format("x")) + 24 * 60 * 60 * 1000
  };
  return (
    <>
      <form>
        <input
          type="date"
          className="input"
          onChange={e => setStartDate(e.target.value)}
          value={startDate}
        />
        <input
          type="date"
          className="input"
          onChange={e => setEndDate(e.target.value)}
          value={endDate}
        />
      </form>
      <DataTable2
        title={title}
        cols={cols}
        url={url}
        reqBody={reqBodyLoc}
        fromS3={true}
      />
    </>
  );
}

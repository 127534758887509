import dataResource from "../../../../constants/dataResource";
import { convertLogsTime, getLogsTimeDifference } from "./date-helper";

export const getToolTipText = (allLogs, currentLog, index) => {
  const statusText =
    Object.entries(dataResource.DOCTOR_STATUS_LIST)
      .find(e => e[1] === parseInt(currentLog?.status) || 0)?.[0]
      ?.split("_")
      ?.join(" ") ?? "N/A";

  if (index === allLogs?.length - 1) {
    return statusText;
  }

  const currentTime = convertLogsTime(currentLog.dateTime);
  const nextTime = convertLogsTime(allLogs[index + 1].dateTime);
  const { hours, minutes, seconds } = getLogsTimeDifference(
    nextTime,
    currentTime
  );

  let timeText = "";

  if (hours > 0) {
    timeText += `${hours}h `;
  }

  if (minutes > 0) {
    timeText += `${minutes}m `;
  }

  if (seconds > 0) {
    timeText += `${seconds}s `;
  }

  if (!timeText) {
    return statusText;
  }

  return `${statusText} | ${timeText}`;
};

export const getStatusText = status => {
  return (
    Object.entries(dataResource.DOCTOR_STATUS_LIST)
      .find(e => e[1] === parseInt(status) || 0)?.[0]
      ?.split("_")
      ?.join(" ") ?? "N/A"
  );
};

import React, { useState, useEffect } from "react";
import CurrentAppointment from "../../../CurrentAppointment";
import { MdVisibility } from "react-icons/md";
import config from "../../../../../../constants/apiList";
import dataResource from "../../../../../../constants/dataResource";
import AskImage from "../common/AskImage";
import MaterialTable from "material-table";
import { ivrStatusStyle } from "../../NewCols";
import { CurrentTitleSpan } from "./styles";
import Chip from "@mui/material/Chip";
import CategoryChip from "../common/CategoryChip/index";
import { getRxWriterLink } from "../../../../../../helpers/rx-writer.helpers";

const defaultStyling = {
  fontFamily: "Sofia Pro",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "13px",
  display: "flex",
  alignItems: "center"
};

const currentAppointsTable = [
  {
    title: "View Rx",
    render: rowData => {
      return (
        <div
          style={{
            cursor: "pointer",
            title: "Open Prescription",
            boxSizing: "border-box",
            width: "30px",
            height: "30px",
            background: "#FFFFFF",
            border: "0.5px solid #C3E0FB",
            borderRadius: "7px"
          }}
        >
          <div
            onClick={e => {
              let param =
                rowData.brand + "-" + rowData.source + "-" + rowData.id;
              const oldCategories = ["SH", "HC", "OF"];
              if (oldCategories.includes(rowData.source)) {
                param = rowData.source_id;
              }
              window.open(getRxWriterLink(param), "_self");
            }}
            style={{
              cursor: "pointer",
              margin: "4px 6px",
              title: "Open Prescription",
              color: "#5194D1"
            }}
          >
            <MdVisibility />
          </div>
        </div>
      );
    }
  },
  {
    title: "Status",
    render: rowData => (
      <div style={defaultStyling}>
        {dataResource.statusListObj[rowData.status]}
      </div>
    )
  },
  {
    title: "Patient ID",
    render: rowData => (
      <div
        style={{
          ...defaultStyling,
          whiteSpace: "nowrap"
        }}
      >
        {rowData.brand + "-" + rowData.source + "-" + rowData.id}
      </div>
    )
  },
  {
    title: "Patient Name",
    render: rowData => {
      return (
        <div
          style={{
            ...defaultStyling,
            whiteSpace: "nowrap"
          }}
        >
          {rowData.patient_name}
        </div>
      );
    }
  },
  {
    title: "Category",
    field: "category",
    render: rowData => {
      return (
        <div
          style={{
            ...defaultStyling,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap"
          }}
        >
          {rowData.category.split(",").map((category, index) => {
            return <CategoryChip category={category} key={index} />;
          })}
        </div>
      );
    }
  },

  {
    title: "Image",
    render: rowData => <AskImage rowData={rowData} />
  }
];

let newColumns = [
  ...currentAppointsTable,
  {
    title: "",
    render: rowData => {
      if (rowData.type === dataResource?.APPOINTMENT_TYPE?.INSTANT)
        return (
          <Chip
            label={"IA"}
            style={{
              opacity: "0.7",
              fontWeight: "500"
            }}
          />
        );
      return (
        <Chip
          label={"IVR"}
          style={
            ivrStatusStyle[
              rowData.confirmed
                ? "confirmed"
                : rowData.cancelled
                ? "cancelled"
                : "null"
            ]
          }
        />
      );
    }
  },
  {
    title: "",
    render: data => (
      <CurrentAppointment appointmentData={data} isInstant={true} />
    )
  }
];
export default function CurrentAppointmentTableWrapper({ data }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, []);
  return (
    <MaterialTable
      title={<CurrentTitleSpan>Current Appointment</CurrentTitleSpan>}
      columns={newColumns}
      data={data.currentAppointments || []}
      localization={{
        body: {
          emptyDataSourceMessage: <p>No Current Appointments</p>
        }
      }}
      options={{
        search: false,
        paging: false,
        sorting: false,
        draggable: false,
        headerStyle: {
          backgroundColor: "transparent",
          color: "#000",
          fontSize: "14px",
          paddingBottom: "33px",
          border: "none"
        },
        rowStyle: {
          backgroundColor: "white",
          width: "min-content",
          padding: "20px",
          borderRadius: "5px"
        },
        cellStyle: {
          fontSize: matches ? "13px" : "15px",
          borderBottom: "none",
          backgroundColor: "white",
          color: "#6C6C6C"
        }
      }}
      style={{
        margin: "10px",
        padding: "10px",
        marginTop: "0px",
        paddingTop: "0px",
        backgroundColor: "transparent",
        boxShadow: "none"
      }}
    />
  );
}

import React from "react";
import { connect } from "react-redux";
import { store } from "../../../../../reducers/Persist";
import HeaderBottomCardMessage from "./HeaderBottomCardMessage";
import "./styles.scss";

class HeaderBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCard: false
    };
  }
  render() {
    let concerns = this.props.observationReducer[this.props.id]?.concerns;
    let thirdPartyProducts =
      this.props.observationReducer[this.props.id]?.otherProducts;
    let observationData = this.props.observationReducer[this.props.id]; // this is observationReducer
    // 0 for partially filled
    // 1 for completely filled
    // -1 for not filled

    let concernObj = {
      category: true,
      concern: true,
      severity: true,
      onset: true
    };

    concerns?.length > 0 &&
      concerns.map((val, index) => {
        if (concernObj.concern)
          concernObj.concern =
            val.text?.length > 0 || val.pretext?.length > 0 ? true : false;
        if (concernObj.severity)
          concernObj.severity =
            val?.metadata.severity?.length > 0 ? true : false;
        if (concernObj.category)
          concernObj.category =
            !!val?.metadata.category && val?.metadata.category?.length > 0
              ? true
              : false;
        if (concernObj.onset)
          concernObj.onset =
            !!val.metadata.onsetValue &&
            val.metadata.category?.length > 0 &&
            !!val.metadata.onsetDuration &&
            val.metadata.onsetDuration?.length > 0
              ? true
              : false;
      });

    let checkConcern = 1;
    {
      let flag = true;
      for (let key in concernObj) {
        if (concernObj[key] === false && flag === true) {
          flag = false;
          checkConcern = 0;
        } else if (flag === false && concernObj[key] === true) {
          checkConcern = 0;
          break;
        } else if (flag === false && concernObj[key] === false) {
          checkConcern = -1;
        }
      }
    }

    let basicDetailsCheck = 1;
    if (
      !!this.props.patientDetails?.age === false &&
      !!this.props.patientDetails?.gender === true
    ) {
      basicDetailsCheck = 0;
    } else if (
      !!this.props.patientDetails?.age === true &&
      !!this.props.patientDetails?.gender === false
    ) {
      basicDetailsCheck = 0;
    } else if (
      !!this.props.patientDetails?.age === false &&
      !!this.props.patientDetails?.gender === false
    ) {
      basicDetailsCheck = -1;
    } else {
      basicDetailsCheck = 1;
    }
    let pastTreatments = observationData?.pastTreatments;
    let familyHistory = observationData?.familyHistory;
    let medicalHistory = observationData?.medicalHistory;
    let otherDetails = observationData?.otherDetails;

    let pastMedicalObj = {
      past_treatments:
        pastTreatments?.metadata?.isEntering === "no"
          ? true
          : pastTreatments?.metadata?.isEntering === "yes" &&
            (pastTreatments.text?.length > 0 ||
              pastTreatments.pretext?.length) > 0
          ? true
          : false,
      family_history:
        familyHistory?.metadata?.isEntering === "no"
          ? true
          : familyHistory?.metadata?.isEntering === "yes" &&
            (familyHistory.text?.length > 0 || familyHistory.pretext?.length) >
              0
          ? true
          : false,
      medical_history:
        medicalHistory?.metadata?.isEntering === "no"
          ? true
          : medicalHistory?.metadata?.isEntering === "yes" &&
            (medicalHistory.text?.length > 0 ||
              medicalHistory.pretext?.length) > 0
          ? true
          : false,
      other_details:
        otherDetails?.metadata?.isEntering === "no"
          ? true
          : otherDetails?.metadata?.isEntering === "yes" &&
            (otherDetails.text?.length > 0 || otherDetails.pretext?.length) > 0
          ? true
          : false
    };
    let checkPastMedical = -1;
    {
      const { past_treatments, family_history, medical_history } =
        pastMedicalObj;
      if (past_treatments && family_history && medical_history)
        checkPastMedical = 1;
      else if (past_treatments || family_history || medical_history)
        checkPastMedical = 0;
    }
    let lifestyleExisting = observationData?.lifestyleExisting;
    let allergySpecifications = observationData?.allergySpecifications;
    //TODO Not able to find the store where allergy specification(dropdown is stored)
    let allergyTest = true;
    const allergyIsEntering = observationData?.allergyIsEntering;
    if (allergyIsEntering === "yes") {
      allergySpecifications.map(val => {
        if (
          val.text?.length > 0 ||
          (val.pretext?.length > 0 && val?.metadata.type?.length > 0)
        ) {
        } else if (allergyTest) {
          allergyTest = false;
        }
      });
    } else if (allergyIsEntering === "no") allergyTest = true;
    else allergyTest = false;
    let lifestyleObj = {
      diet: lifestyleExisting?.metadata.diet?.length > 0 && true,
      sleep: lifestyleExisting?.metadata.sleep?.length > 0 && true,
      exercise: lifestyleExisting?.metadata.exercise?.length > 0 && true,
      stress: lifestyleExisting?.metadata.stress?.length > 0 && true,
      weight: observationData?.weight?.length > 0 && true,
      height: observationData?.height?.length > 0 && true,
      marital_status: this.props.patientDetails?.marital_status,
      social_history: true, // Not able to find in redux store i.e y I hardcoded it with value true
      allergy: allergyTest
    };

    let checkLifeStyle = 1;
    {
      let flag = true;
      for (let key in lifestyleObj) {
        if (key !== "weight" && key !== "height") {
          if (lifestyleObj[key] === false && flag === true) {
            flag = false;
            checkLifeStyle = 0;
          } else if (flag === false && lifestyleObj[key] === true) {
            checkLifeStyle = 0;
            break;
          } else if (flag === false && lifestyleObj[key] === false) {
            checkLifeStyle = -1;
          }
        }
      }
    }

    let diagnosis = observationData?.diagnosis;
    //TODO Not able to find the store where provisional or final is stored
    let diagnosisObj = {
      details: true,
      provisional_final: true
    };
    diagnosis &&
      diagnosis.length > 0 &&
      diagnosis.map(val => {
        if (val.text?.length > 0 || val.pretext?.length > 0) {
        } else if (diagnosisObj.details === true) {
          diagnosisObj.details =
            val.text?.length > 0 || val.pretext?.length > 0 ? true : false;
        }
        if (!val?.metadata?.type) {
          diagnosisObj.provisional_final = false;
        }
      });

    let checkDiagnosis = 1;
    {
      let flag = true;
      for (let key in diagnosisObj) {
        if (diagnosisObj[key] === false && flag === true) {
          flag = false;
          checkDiagnosis = 0;
        } else if (flag === false && diagnosisObj[key] === true) {
          checkDiagnosis = 0;
          break;
        } else if (flag === false && diagnosisObj[key] === false) {
          checkDiagnosis = -1;
        }
      }
    }

    let checkProducts;
    if (
      this.props.recommendedProducts?.length > 0 ||
      thirdPartyProducts?.length > 0
    ) {
      checkProducts = 1;
    } else checkProducts = -1;

    let checkRecommendation = -1;
    let recommendationObj = {
      lifeStyle:
        (observationData?.lifestyle.text?.length > 0 ||
          observationData?.lifestyle.pretext?.length > 0) &&
        true,
      specialistConsult:
        (observationData?.specialConsult.text?.length > 0 ||
          observationData?.specialConsult.pretext?.length > 0) &&
        true,
      specialistDiagnostics:
        (observationData?.suggestDiagnostics.text?.length > 0 ||
          observationData?.suggestDiagnostics.pretext?.length > 0) &&
        true,
      specialistProcedure:
        (observationData?.suggestProcedure.text?.length > 0 ||
          observationData?.suggestProcedure.pretext?.length > 0) &&
        true
    };
    for (const key in recommendationObj) {
      if (recommendationObj[key]) checkRecommendation = 1;
    }
    const data = [
      {
        title: "Basic Details",
        checked: basicDetailsCheck,
        checks: [
          {
            name: "Age",
            filled: !!this.props.patientDetails?.age ? true : false
          },
          {
            name: "Gender",
            filled: !!this.props.patientDetails?.gender ? true : false
          }
        ]
      },
      {
        title: "Concerns",
        checked: checkConcern,
        checks: [
          {
            name: "Category",
            filled: concernObj.category
          },
          {
            name: "Concern",
            filled: concernObj?.concern
          },
          {
            name: "Severity",
            filled: concernObj?.severity
          },
          {
            name: "Onset",
            filled: concernObj?.onset
          }
        ]
      },
      {
        title: "Past medical",
        checked: checkPastMedical,
        checks: [
          {
            name: "Past Treatment",
            filled: pastMedicalObj.past_treatments
          },
          {
            name: "Family History",
            filled: pastMedicalObj.family_history
          },
          {
            name: "Medical History",
            filled: pastMedicalObj.medical_history
          },
          {
            name: "Other Details",
            filled: pastMedicalObj.other_details
          }
        ]
      },
      {
        title: "Lifestyle",
        checked: checkLifeStyle,

        checks: [
          {
            name: "Diet",
            filled: lifestyleObj.diet
          },
          {
            name: "Sleep",
            filled: lifestyleObj.sleep
          },
          {
            name: "Exercise",
            filled: lifestyleObj.exercise
          },
          {
            name: "Stress",
            filled: lifestyleObj.stress
          },
          {
            name: "Weight",
            filled: lifestyleObj.weight
          },
          {
            name: "Height",
            filled: lifestyleObj.height
          },
          {
            name: "Marital Status",
            filled: lifestyleObj.marital_status
          },
          {
            name: "Social History",
            filled: lifestyleObj.social_history
          },
          {
            name: "Allergy",
            filled: lifestyleObj.allergy
          }
        ]
      },
      {
        title: "Diagnosis",
        checked: checkDiagnosis,
        checks: [
          {
            name: "Provisional/Final",
            filled: diagnosisObj.provisional_final
          },
          {
            name: "Details",
            filled: diagnosisObj.details
          }
        ]
      },
      {
        title: "Products",
        checked: checkProducts,

        checks: [
          {
            name: "Inhouse",
            filled: this.props.recommendedProducts?.length > 0
          },
          {
            name: "3rd Party",
            filled: thirdPartyProducts?.length > 0 && true
          }
        ]
      },
      {
        title: "Recommendations",
        checked: checkRecommendation,
        checks: [
          {
            name: "Lifestyle",
            filled: recommendationObj.lifeStyle
          },
          {
            name: "Specialist Consult",
            filled: recommendationObj.specialistConsult
          },
          {
            name: "Diagnostics",
            filled: recommendationObj.specialistDiagnostics
          },
          {
            name: "Procedure",
            filled: recommendationObj.specialistProcedure
          }
        ]
      }
    ];

    return (
      <div className="headerBottom__container">
        {[...data].map((val, index) => (
          <HeaderBottomCardMessage
            index={index}
            key={index}
            cardContent={val}
          />
        ))}
      </div>
    );
  }
}

function mapSateToProps(state) {
  return {
    patientDetails: store.getState().rxWriterReducer.patientDetails,
    observationData: store.getState().observationCheckReducer,
    id: store.getState().rxWriterReducer.id,
    observationReducer: store.getState().observationReducer,
    rxData: store.getState().rxWriterReducer,
    recommendedProducts: store.getState().recommendedProducts
  };
}

export default connect(mapSateToProps)(HeaderBottom);

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import "./ViewAllUpdatesModal.scss";
import { useSelector } from "react-redux";
import dataResource from "../../../../../constants/dataResource";
import moment from "moment";
import UtilityService from "../../../../../services/utilities";
const statusObj = dataResource.statusListObj;
export default function ViewAllUpdatesModal(props) {
  const remarksData = useSelector(
    state => state.rxWriterReducer.prescriptionData.remarks
  );
  // const remarks = [...remarksData].reverse();
  return (
    <Modal
      show={props.open}
      onHide={() => props.onClose()}
      dialogClassName="view-all-updates-modal"
      centered
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="modal-title" style={{ marginRight: "100px" }}>
          Recent Updates
        </div>
        <img
          src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
          alt="close"
          onClick={props.onClose}
          className="close-button"
        />
      </div>
      <div className="modal-data-container">
        {remarksData?.length === 0 ? (
          <div
            style={{
              paddingTop: "10px",
              fontSize: "20px",
              fontWeight: "500",
              opacity: "0.7"
            }}
          >
            No updates found
          </div>
        ) : (
          <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
            {remarksData
              ?.slice(0)
              ?.reverse()
              ?.map((elem, index) => (
                <div
                  className="remarkBox"
                  style={{ width: "fitContent" }}
                  key={index}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={7} className="remarkStatus">
                      {statusObj[elem?.status] || "-"}
                      <div className="remark">{elem?.remarks}</div>
                    </Grid>
                    <Grid item xs={3} className="remark-date">
                      {moment(elem.updated_dttm)
                        .add(5.5, "hours")
                        .format("DD MMM, YYYY")}
                    </Grid>
                    <Grid item xs={2} className="remark-time">
                      {moment(elem.updated_dttm)
                        .add(5.5, "hours")
                        .format("hh:mm A")}
                    </Grid>
                  </Grid>
                </div>
              ))}
          </div>
        )}
      </div>
    </Modal>
  );
}

import React from "react";
import moment from "moment";
import { CurrentStatusDoctor } from "./styles/current-status.styles";
import { getStatusColor } from "./helper/status-colors";
import Tooltip from "@mui/material/Tooltip";
import { getStatusText } from "./helper/tooltip-text";

export default function CurrentStatus({
  doctorStatus,
  selectedDate,
  requestSuccessTime
}) {
  if (!moment(selectedDate).isSame(moment(), "day")) {
    return <></>;
  }
  return (
    <Tooltip
      title={
        getStatusText(doctorStatus) +
        (requestSuccessTime ? " as of " + requestSuccessTime : "")
      }
      placement="top"
      arrow
    >
      <CurrentStatusDoctor backgroundColor={getStatusColor(doctorStatus)} />
    </Tooltip>
  );
}

const initialState = {
  allergy_y_n: false,
  allergy: [
    {
      type: false,
      text: false
    }
  ],
  lifestyle: {
    diet: false,
    sleep: false,
    exercise: false,
    stress: false
  },
  habits: false,
  habitOther: false,
  concerns: [
    {
      category: false,
      text: false,
      severity: false,
      onsetValue: false,
      onsetDuration: false
    }
  ],
  past_treatments: false,
  medical_history: false,
  family_history: false,
  past_treatments_y_n: false,
  medical_history_y_n: false,
  family_history_y_n: false,
  diagnosis: [
    {
      type: false,
      text: false
    }
  ]
};
const sampleAllergy = {
  type: false,
  text: false
};
const sampleDiagnosis = {
  type: false,
  text: false
};
const sampleConcerns = {
  category: false,
  text: false,
  severity: false,
  onset_month: false,
  onset_year: false
};

const observationCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_FALSE":
      const newState = { ...state };
      for (const i in newState) {
        if (i === "allergy") {
          newState[i] = Array(state.allergy.length).fill(sampleAllergy);
        } else if (i === "diagnosis") {
          newState[i] = Array(state.diagnosis.length).fill(sampleDiagnosis);
        } else if (i === "concerns") {
          newState[i] = Array(state.concerns.length).fill(sampleConcerns);
        } else newState[i] = initialState[i];
      }
      return newState;
    case "SET_ARRAY_OBJECT_CHECK":
      let tempArr = state[action.payload.category];
      tempArr[action.payload.index] = {
        ...tempArr[action.payload.index],
        [action.payload.key]: action.payload.value
      };
      return {
        ...state,
        [action.payload.category]: tempArr
      };
    case "SET_KEY_CHECK":
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    case "SET_OBJECT_CHECK":
      return {
        ...state,
        [action.payload.category]: {
          ...state[action.payload.category],
          [action.payload.key]: action.payload.value
        }
      };
    case "ADD_ALLERGY_CHECK":
      return {
        ...state,
        allergy: [...state.allergy, sampleAllergy]
      };
    case "ADD_DIAGNOSIS_CHECK":
      return {
        ...state,
        diagnosis: [...state.diagnosis, sampleDiagnosis]
      };
    case "ADD_CONCERNS_CHECK":
      return {
        ...state,
        concerns: [...state.concerns, sampleConcerns]
      };
    case "DELETE_ALLERGY_CHECK":
      return {
        ...state,
        allergy: state.allergy.slice(0, state.allergy.length - 1)
      };
    case "DELETE_DIAGNOSIS_CHECK":
      return {
        ...state,
        diagnosis: state.diagnosis.slice(0, state.diagnosis.length - 1)
      };
    case "DELETE_CONCERNS_CHECK":
      return {
        ...state,
        concerns: state.concerns.slice(0, state.concerns.length - 1)
      };
    default:
      return state;
  }
};

export default observationCheckReducer;

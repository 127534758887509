import axios from "axios";
import config from "../../../../../../constants/apiList";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./RequestImages.scss";
import Loader from "react-loader-spinner";
import clevertap from "clevertap-web-sdk";
import { Button, ButtonRow } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { addToGTM } from "../../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../../constants/tagManager.constants";

const RequestImages = ({ refreshImages }) => {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const doctorId = useSelector(state => state.userReducer.hash_id);
  const rxData = useSelector(state => state.rxWriterReducer);
  const { form, brand, id, prescriptionData, language } = useSelector(
    state => state.rxWriterReducer
  );
  const handeRequestImages = () => {
    setIsLoading(true);
    const payload = {
      category: rxData.form,
      doctor_hash: doctorId,
      id: rxData.id
    };
    axios
      .post(config.url.PRESCRIPTION_IMAGE_UPLOAD_URL, payload)
      .then(res => {
        console.log(res);
        addToGTM({
          event: GTM_EVENTS?.IMAGE_REQUESTED
        });
        if (res.data.body.result === "Upload request sms sent successfully") {
          clevertap.event.push("Image Request Sent", {
            Source: form,
            Category: prescriptionData.category_label,
            Appointments: id,
            "Call Type":
              prescriptionData?.mode === "bw_video"
                ? "Video call"
                : "Voice Call",
            Brand: brand,
            Language: language
          });
          setIsRequestSent(true);
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <div className="request-images">
      <div className="button-container">
        {isLoading ? (
          <div style={{ padding: "10px" }}>
            <Loader type="TailSpin" />
          </div>
        ) : (
          <ButtonRow>
            <Button onClick={refreshImages}>
              <FontAwesomeIcon icon={faArrowsRotate} />
              Refresh
            </Button>
            <Button disabled={isRequestSent} onClick={handeRequestImages}>
              <FontAwesomeIcon icon={faImage} />
              {isRequestSent ? "Request Sent" : "Request Images"}
            </Button>
          </ButtonRow>
        )}
      </div>
      {isRequestSent && (
        <div className="request-sent-text">
          Request sent successfully to patient!{" "}
        </div>
      )}
    </div>
  );
};
export default RequestImages;

import React, { useContext, useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { GlobalDataContext } from "./MealPlanner2";

const dropdown = [
  {
    Carbohydrates: [
      {
        name: "Lemon Rice",
        conditions: { vegan: 1, keto: 0 },
        Portion: "Bowl",
        Tips: "Some default tip"
      },
      {
        name: "Fried Rice",
        conditions: { vegan: 0, keto: 0 },
        Portion: "Bowl",
        Tips: "Some default tip"
      }
    ]
  },
  {
    Fibers: [
      {
        name: "Lemon Rice",
        conditions: { vegan: 1, keto: 0 },
        Portion: "Bowl",
        Tips: "Some default tip"
      },
      {
        name: "Fried Rice",
        conditions: { vegan: 0, keto: 0 },
        Portion: "Bowl",
        Tips: "Some default tip"
      },
      {
        name: "Lemon Rice",
        conditions: { vegan: 1, keto: 0 },
        Portion: "Bowl",
        Tips: "Some default tip"
      }
    ]
  }
];

export default function SelectItem({ day, ind, index }) {
  const { data, setData, tableState, setTableState } = useContext(
    GlobalDataContext
  );

  return (
    <Select
      options={data}
      values={[{ name: tableState[day][ind].rows[index].item }]}
      addPlaceholder={
        tableState[day][ind].rows[index].item ? "" : "Select Item"
      }
      style={{
        width: "150px",
        backgroundColor: "white",
        padding: "10px 12px",
        border: "1px solid #154166",
        fontSize: "inherit"
      }}
      create
      onCreateNew={item => setData([...data, item])}
      onChange={value => {
        if (!value[0] || !value[0].name) return;
        const obj = tableState[day][ind];
        obj.rows[index] = { ...obj.rows[index], item: value[0].name };
        setTableState({
          ...tableState,
          [day]: [
            ...tableState[day].slice(0, ind),
            { time: obj.time, rows: [...obj.rows] },
            ...tableState[day].slice(ind + 1)
          ]
        });
        console.log(ind, index, tableState);
      }}
      dropdownPosition="auto"
      labelField="name"
      valueField="name"
    />
  );
}

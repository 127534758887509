import React, { useMemo } from "react";
import MaterialTable from "material-table";

export default function Table2({
  title,
  cols,
  rows,
  actions,
  rowStyle,
  search,
  pageSize
}) {
  return (
    <div id={title.replace(/\s/g, "").toLowerCase()}>
      <MaterialTable
        columns={cols}
        data={useMemo(() => rows, [rows])}
        title={title}
        options={{
          search: search ? search : false,
          exportButton: true,
          exportAllData: true,
          pageSize: pageSize ? pageSize : 5,
          sorting: false,
          rowStyle: rowStyle,
          headerStyle: {
            backgroundColor: "#003459",
            color: "#FFF"
          },
          filtering: true
        }}
        actions={actions}
        style={{ border: "1px solid #1b263b" }}
      />
    </div>
  );
}

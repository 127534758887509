import React from "react";
import "./styles.scss";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      productName: this.props.productItem.productName,
      quantity: this.props.productItem.quantity,
      frequency: this.props.productItem.frequency,
      days: this.props.productItem.days,
      instructions: this.props.productItem.instructions
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productItem !== this.props.productItem) {
      this.setState({
        productName: this.props.productItem.productName,
        quantity: this.props.productItem.quantity,
        frequency: this.props.productItem.frequency,
        days: this.props.productItem.days,
        instructions: this.props.productItem.instructions
      });
    }
  }

  handleOnCancel = e => {
    e.preventDefault();
    this.setState({
      // ...this.props.productItem,
      editState: false,
      productName: this.props.productItem.productName,
      quantity: this.props.productItem.quantity,
      frequency: this.props.productItem.frequency,
      days: this.props.productItem.days,
      instructions: this.props.productItem.instructions
    });
  };
  handleOnAdd = e => {
    e.preventDefault();
    if (
      this.state.productName == "" ||
      !!this.state.quantity == "" ||
      !!this.state.frequency == "" ||
      !!this.state.instructions == "" ||
      !!this.state.days == ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    // TODO Add the product in the redux
    this.props.handleSetProduct(this.props.index, {
      productName: this.state.productName,
      quantity: this.state.quantity,
      frequency: this.state.frequency,
      instructions: this.state.instructions,
      days: this.state.days
    });
    this.setState({
      editState: false
    });
  };
  handleOnEdit = e => {
    e.preventDefault();

    this.setState({
      editState: true
    });
  };
  handleOnDelete = e => {
    e.preventDefault();
    // TODO Delete the product from the redux
    this.props.handleDeleteProduct(this.props.index);
  };
  render() {
    return (
      <div className="otherProducts__product_container">
        <div className="OP_product__container_item">
          {this.state.editState ? (
            <input
              value={this.state.productName}
              onChange={e => {
                this.setState({ productName: e.target.value });
              }}
              placeholder="Product Name"
            />
          ) : (
            <p className="item__title">{this.state.productName}</p>
          )}

          {this.state.editState ? (
            <input
              value={this.state.quantity}
              placeholder="Quantity"
              onChange={e => {
                this.setState({ quantity: e.target.value });
              }}
            />
          ) : (
            <p>{this.state.quantity}</p>
          )}

          {this.state.editState ? (
            <input
              value={this.state.frequency}
              placeholder="Frequency"
              onChange={e => {
                this.setState({ frequency: e.target.value });
              }}
            />
          ) : (
            <p>{this.state.frequency}</p>
          )}
        </div>
        <div className="product__days">
          {this.state.editState ? (
            <input
              value={this.state.days}
              placeholder="Days..."
              className="product__days_input"
              onChange={e => {
                this.setState({ days: e.target.value });
              }}
            />
          ) : (
            <p>{this.state.days}</p>
          )}
        </div>

        <div className="product__instructions">
          <div className="product__instructions_left">
            {this.state.editState ? (
              <input
                placeholder="Instructions..."
                value={this.state.instructions}
                onChange={e => {
                  this.setState({
                    instructions: e.target.value
                  });
                }}
                className="product__instructions_input"
              />
            ) : (
              <p>{this.state.instructions}</p>
            )}
          </div>
          {this.state.editState ? (
            <div className="product__instructions_right">
              <button
                className="product__instructions_right_correct"
                onClick={this.handleOnAdd}
              >
                <CheckIcon color="green" />
              </button>
              <button
                className="product__instructions_right_cancel"
                onClick={this.handleOnCancel}
              >
                <ClearIcon />
              </button>
            </div>
          ) : (
            <div className="product__instructions_right">
              <button
                className="product__instructions_right_edit"
                onClick={this.handleOnEdit}
              >
                <EditOutlined />
              </button>
              <button
                className="product__instructions_right_delete"
                onClick={this.handleOnDelete}
              >
                <DeleteOutline />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Product;

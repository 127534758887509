import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { store } from "../../../../reducers/Persist";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../../../constants/apiList";
import { toast } from "react-toastify";
import "./Confirmation.scss";
import Loader from "react-loader-spinner";
import clevertap from "clevertap-web-sdk";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";

const CustomColorCheckbox = withStyles({
  root: {
    color: "#379F8E",
    "&$checked": {
      color: "#379F8E"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);
class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicDetailsViewed: this.props.unlockedTabs.observations,
      loading: false
    };
  }
  onNextClick = () => {
    const { age, gender, name } = this.props.patientDetails;
    console.log(this.props.patientDetails);
    const unfilled = [];
    if (!name?.trim()) unfilled.push("Name");
    if (!age || !age?.toString()?.trim()) unfilled.push("Age");
    if (!gender?.trim()) unfilled.push("Gender");
    if (unfilled.length > 0) {
      toast.error(
        "Please fill the following field(s): \n " + unfilled.join(", ")
      );
      return;
    } else {
      // api call

      this.setState({ loading: true });
      //this.props.patientDetails.patient_medical_history=null;
      const editProfilePayload = this.props.patientDetails;
      editProfilePayload.patient_medical_history = null;
      editProfilePayload.userId = this.props?.prescriptionData.user_id;
      editProfilePayload.brand = this.props?.prescriptionData.brand;
      editProfilePayload.maritalStatus =
        this.props?.patientDetails?.marital_status;

      axios
        .post(config.url.EDIT_PROFILE, editProfilePayload)
        .then(res => {
          this.setState({
            ...this.state,
            isLoading: false
          });
          clevertap.event.push("Observations Viewed", {
            Source: this.props.form,
            Category: this.props.prescriptionData.category_label,
            Appointments: this.props.id,
            "Call Type":
              this.props.prescriptionData?.mode === "bw_video"
                ? "Video call"
                : "Voice Call",
            Brand: this.props.brand,
            Language: this.props.language
          });
          store.dispatch({
            type: "CHANGE_PATIENT_DETAILS_EDITABLE",
            data: false
          });
          this.setState({ loading: false });
          store.dispatch({ type: "UNLOCK_OBSERVATION_TAB" });
          store.dispatch({ type: "CHANGE_TAB", data: 1 });
          addToGTM({
            event: GTM_EVENTS?.OBSERVATION_VIEWED
          });
        })
        .catch(err => {
          this.setState({ loading: false });
          console.log(err);
          toast.error("Something went wrong");
          return;
        });
    }
  };
  render() {
    return (
      <div className="confirmation">
        {this.state.loader ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader />
          </div>
        ) : (
          <button
            onClick={this.onNextClick}
            // disabled={
            //   !this.state.basicDetailsViewed ||
            //   this.props.unlockedTabs.observations
            // }
            className="next-button"
          >
            Next
          </button>
        )}
      </div>
    );
  }
}

const mapSateToProps = (state, ownProps) => ({
  currentTab: store.getState().footerReducer.currentTab,
  unlockedTabs: store.getState().footerReducer.unlockedTabs,
  patientDetails: store.getState().rxWriterReducer.patientDetails,
  form: store.getState().rxWriterReducer.form,
  id: store.getState().rxWriterReducer.id,
  prescriptionData: store.getState().rxWriterReducer.prescriptionData,
  brand: store.getState().rxWriterReducer.brand,
  language: store.getState().rxWriterReducer.language
});
export default connect(mapSateToProps)(Confirmation);

import styled from "styled-components";

export const LoadingOverLayContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 10000000000000000000;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0 0 0 0;
  cursor: progress;
`;

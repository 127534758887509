import React, { Component } from "react";
import { toast } from "react-toastify";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import "./Update.scss";
import moment from "moment";
import axios from "axios";
import config from "../../../../constants/apiList";
import clevertap from "clevertap-web-sdk";
import Loader from "react-loader-spinner";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";
class Update extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastMessage: "",
      loader: false
    };
  }
  pretextPusher = (condition_type, pretext) => {
    const conditions = [];
    for (const condition_preset of pretext)
      conditions.push({ condition_type, condition_preset });
    return conditions;
  };
  getRecommendationProducts = async (payload, conditions, diagnosis) => {
    try {
      let promises = [];
      for (const diag of diagnosis)
        for (const val of diag.pretext)
          promises.push(
            axios.post(config.url.GET_RECOMMENDED_PRODUCTS, {
              ...payload,
              diagnosis: val
            })
          );
      this.setState({ loader: true });
      const res = await Promise.all(promises);
      const data = res.map(res => res.data);

      if (
        data
          .flat()
          .map(item => item.success)
          .includes(false)
      ) {
        this.setState({ loader: false });
        toast.error("Something went wrong!");
        return;
      }
      let recommendedObject = {},
        restrictedObject = {};
      for (const res of data.flat()) {
        const { recommendedProducts, restrictedProduct } = res.data;
        for (const product of recommendedProducts)
          recommendedObject[product] = true;
        for (const product of restrictedProduct)
          restrictedObject[product] = true;
      }
      store.dispatch({
        type: "CHANGE_RECOMMENDED_RESTRICTED_PROUDCTS",
        payload: {
          recommendedProducts: Object.keys(recommendedObject),
          restrictedProducts: Object.keys(restrictedObject)
        }
      });
      store.dispatch({ type: "UNLOCK_PRESCRIPTION_TAB" });
      store.dispatch({ type: "CHANGE_TAB", data: 2 });
      addToGTM({
        event: GTM_EVENTS?.PRESCRIPTION_SECTION_VIEWED
      });
      clevertap.event.push("Observation Completed", {
        Source: this.props.form,
        Category: this.props.prescriptionData.category_label,
        Appointments: this.props.id,
        "Call Type":
          this.props.prescriptionData?.mode === "bw_video"
            ? "Video call"
            : "Voice Call",
        Brand: this.props.brand,
        Language: this.props.language
      });
      this.setState({ loader: false });
    } catch (err) {
      console.log(err);
      throw Error("Promise failed");
    }
  };

  handleSendRecommendedProducts = () => {
    // this.setState({loader: true});
    const products = [],
      conditions = [];
    // { "condition_type": "allergy_food", "condition_preset": "1766"}
    for (const product of this.props.prescribeProducts) {
      let last_date_ordered = moment(product.metadata.last_date_ordered).format(
        "YYYY-MM-DD"
      );
      let start_date = moment(product.metadata.start_date).format("YYYY-MM-DD");
      let diff = moment(last_date_ordered).diff(moment(start_date), "days");
      products.push({
        product_id: product.metadata.productId,
        duration: diff,
        improvement: product.metadata.improvement
          ? product.metadata.improvement === "yes"
            ? true
            : false
          : null
      });
    }
    // for concerns:
    const {
      concerns,
      pastTreatments,
      familyHistory,
      medicalHistory,
      otherDetails,
      diagnosis,
      allergySpecifications
    } = this.props.observations;
    for (const concern of concerns)
      conditions.push(...this.pretextPusher("concern", concern.pretext));
    for (const diag of diagnosis)
      conditions.push(...this.pretextPusher("diagnosis", diag.pretext));
    conditions.push(
      ...this.pretextPusher("past_treatments", pastTreatments.pretext)
    );
    conditions.push(
      ...this.pretextPusher("family_history", familyHistory.pretext)
    );
    conditions.push(
      ...this.pretextPusher("medical_history", medicalHistory.pretext)
    );
    conditions.push(
      ...this.pretextPusher("other_details", otherDetails.pretext)
    );
    allergySpecifications.map(allergy => {
      let { type } = allergy.metadata;
      const { pretext } = allergy;
      pretext.map(val => {
        if (type === "Food") {
          type = "allergy_food_specification";
        } else if (type === "Animal") {
          type = "allergy_animal_specification";
        } else if (type === "Environmental") {
          type = "allergy_environmental_specification";
        } else if (type === "Drug") {
          type = "allergy_drug_specification";
        } else if (type === "Other") {
          type = "allergy_others_specification";
        }
        conditions.push({
          condition_type: type,
          condition_preset: val.toString()
        });
      });
    });
    let id = "condition_preset";
    const payload = {
      brand: this.props.brand,
      age: this.props.age,
      products,
      conditions: [
        ...new Map(conditions.map(item => [item[id], item])).values()
      ]
    };
    this.getRecommendationProducts(payload, conditions, diagnosis);
  };
  handleClick = () => {
    const {
      concerns,
      lifestyleExisting,
      familyHistory,
      medicalHistory,
      pastTreatments,
      allergySpecifications,
      diagnosis,
      allergyIsEntering
    } = this.props.observations;
    const lifestyle = lifestyleExisting.metadata;
    let checker = true;
    let str = "";
    let checkComplaintOthers = false;
    // check for concerns
    for (let i = 0; i < concerns.length; i++) {
      if (
        concerns[i]?.metadata?.category === "2097" ||
        concerns[i]?.metadata?.category === "2115" ||
        concerns[i]?.metadata?.category === "2230"
      ) {
        checkComplaintOthers = true;
      }
      if (!concerns[i].metadata.category) {
        store.dispatch({
          type: "SET_ARRAY_OBJECT_CHECK",
          payload: {
            category: "concerns",
            index: i,
            value: true,
            key: "category"
          }
        });
        str += str.length > 0 ? " , concern" : "concern";
        store.dispatch({ type: "ADD_CONCERNS_CHECK" });
        store.dispatch({ type: "DELETE_CONCERNS_CHECK" });
      }

      if (!concerns[i]?.metadata?.severity) {
        if (checkComplaintOthers) {
          store.dispatch({
            type: "SET_ARRAY_OBJECT_CHECK",
            payload: {
              category: "concerns",
              index: i,
              value: false,
              key: "severity"
            }
          });
        } else if (
          !concerns[i]?.metadata?.category ||
          (concerns[i]?.metadata?.category &&
            this.props.presetIdToObj[concerns[i]?.metadata?.category]
              ?.observation_preset_text !== "PCOS")
        ) {
          store.dispatch({
            type: "SET_ARRAY_OBJECT_CHECK",
            payload: {
              category: "concerns",
              index: i,
              value: true,
              key: "severity"
            }
          });
          str += str.length > 0 ? " , concern" : "concern";
          store.dispatch({ type: "ADD_CONCERNS_CHECK" });
          store.dispatch({ type: "DELETE_CONCERNS_CHECK" });
        }
      }
      if (!concerns[i].metadata.onsetValue) {
        if (checkComplaintOthers) {
          store.dispatch({
            type: "SET_ARRAY_OBJECT_CHECK",
            payload: {
              category: "concerns",
              index: i,
              value: false,
              key: "onsetValue"
            }
          });
        } else {
          store.dispatch({
            type: "SET_ARRAY_OBJECT_CHECK",
            payload: {
              category: "concerns",
              index: i,
              value: true,
              key: "onsetValue"
            }
          });
          str += str.length > 0 ? " , concern" : "concern";
          store.dispatch({ type: "ADD_CONCERNS_CHECK" });
          store.dispatch({ type: "DELETE_CONCERNS_CHECK" });
        }
      }
      if (!concerns[i].metadata.onsetDuration) {
        if (checkComplaintOthers) {
          store.dispatch({
            type: "SET_ARRAY_OBJECT_CHECK",
            payload: {
              category: "concerns",
              index: i,
              value: false,
              key: "onsetDuration"
            }
          });
        } else {
          store.dispatch({
            type: "SET_ARRAY_OBJECT_CHECK",
            payload: {
              category: "concerns",
              index: i,
              value: true,
              key: "onsetDuration"
            }
          });
          str += str.length > 0 ? " , concern" : "concern";
          store.dispatch({ type: "ADD_CONCERNS_CHECK" });
          store.dispatch({ type: "DELETE_CONCERNS_CHECK" });
        }
      }
      if (concerns[i].pretext?.length === 0 && !concerns[i].text) {
        store.dispatch({
          type: "SET_ARRAY_OBJECT_CHECK",
          payload: {
            category: "concerns",
            index: i,
            value: true,
            key: "text"
          }
        });
        str += str.length > 0 ? " , concern" : "concern";
        store.dispatch({ type: "ADD_CONCERNS_CHECK" });
        store.dispatch({ type: "DELETE_CONCERNS_CHECK" });
      }
    }
    // check for diagnosis
    for (let i = 0; i < diagnosis.length; i++) {
      if (!diagnosis[i].metadata?.type) {
        store.dispatch({
          type: "SET_ARRAY_OBJECT_CHECK",
          payload: {
            category: "diagnosis",
            index: i,
            value: true,
            key: "type"
          }
        });
        store.dispatch({ type: "ADD_DIAGNOSIS_CHECK" });
        store.dispatch({ type: "DELETE_DIAGNOSIS_CHECK" });
      }
      if (diagnosis[i].pretext?.length === 0 && !diagnosis[i].text) {
        store.dispatch({
          type: "SET_ARRAY_OBJECT_CHECK",
          payload: {
            category: "diagnosis",
            index: i,
            value: true,
            key: "text"
          }
        });
        store.dispatch({ type: "ADD_DIAGNOSIS_CHECK" });
        store.dispatch({ type: "DELETE_DIAGNOSIS_CHECK" });
      }
      if (
        (diagnosis[i].text || diagnosis[i].pretext?.length > 0) &&
        diagnosis[i].metadata?.type
      );
      else {
        str += str.length > 0 ? " , diagnosis" : "diagnosis";
      }
    }
    // check for past treatments, family history and medical history
    if (!pastTreatments.metadata.isEntering) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "past_treatments_y_n", value: true }
      });
      str += "past treatment";
    } else if (
      !pastTreatments.text &&
      pastTreatments.pretext?.length === 0 &&
      pastTreatments.metadata.isEntering === "yes"
    ) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "past_treatments", value: true }
      });
      str += str.length > 0 ? " , past treatment" : "past treatment";
    }

    if (!medicalHistory.metadata.isEntering) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "medical_history_y_n", value: true }
      });
      str += "medical history";
    } else if (
      !medicalHistory.text &&
      medicalHistory.pretext?.length === 0 &&
      medicalHistory.metadata.isEntering === "yes"
    ) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "medical_history", value: true }
      });
      str += str.length > 0 ? " , medical history" : "medical history";
    }
    if (!familyHistory.metadata.isEntering) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "family_history_y_n", value: true }
      });
      str += "family history";
    } else if (
      !familyHistory.text &&
      familyHistory.pretext?.length === 0 &&
      familyHistory.metadata.isEntering === "yes"
    ) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "family_history", value: true }
      });
      str += str.length > 0 ? " , family history" : "family history";
    }
    // allergies
    if (checkComplaintOthers) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "allergy_y_n", value: false }
      });
    } else if (!allergyIsEntering) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "allergy_y_n", value: true }
      });
      str += "allergy";
    } else if (allergyIsEntering === "yes") {
      for (let i = 0; i < allergySpecifications.length; i++) {
        if (!allergySpecifications[i].metadata.type) {
          store.dispatch({
            type: "SET_ARRAY_OBJECT_CHECK",
            payload: {
              category: "allergy",
              index: i,
              key: "type",
              value: true
            }
          });
        }
        if (
          allergySpecifications[i].metadata?.type &&
          allergySpecifications[i].metadata?.type !== "None" &&
          allergySpecifications[i].pretext.length === 0 &&
          !allergySpecifications[i].text
        ) {
          store.dispatch({
            type: "SET_ARRAY_OBJECT_CHECK",
            payload: {
              category: "allergy",
              index: i,
              key: "text",
              value: true
            }
          });
        }
        if (
          allergySpecifications[i].metadata?.type === "None" ||
          ((allergySpecifications[i].text ||
            allergySpecifications[i].pretext?.length > 0) &&
            allergySpecifications[i].metadata?.type)
        );
        else {
          str += str.length > 0 ? " , allergy" : "allergy";
        }
      }
    }
    if (checkComplaintOthers) {
      store.dispatch({
        type: "SET_OBJECT_CHECK",
        payload: {
          category: "lifestyle",
          key: "diet",
          value: false
        }
      });
    } else if (!lifestyle.diet) {
      store.dispatch({
        type: "SET_OBJECT_CHECK",
        payload: {
          category: "lifestyle",
          key: "diet",
          value: true
        }
      });
      str += str.length > 0 ? " , diet" : "diet";
    }
    if (checkComplaintOthers) {
      store.dispatch({
        type: "SET_OBJECT_CHECK",
        payload: {
          category: "lifestyle",
          key: "sleep",
          value: false
        }
      });
    } else if (!lifestyle.sleep) {
      store.dispatch({
        type: "SET_OBJECT_CHECK",
        payload: {
          category: "lifestyle",
          key: "sleep",
          value: true
        }
      });
      str += str.length > 0 ? " , sleep" : "sleep";
    }
    if (checkComplaintOthers) {
      store.dispatch({
        type: "SET_OBJECT_CHECK",
        payload: {
          category: "lifestyle",
          key: "exercise",
          value: false
        }
      });
    } else if (!lifestyle.exercise) {
      store.dispatch({
        type: "SET_OBJECT_CHECK",
        payload: {
          category: "lifestyle",
          key: "exercise",
          value: true
        }
      });
      str += str.length > 0 ? " , exercise" : "exercise";
    }
    if (checkComplaintOthers) {
      store.dispatch({
        type: "SET_OBJECT_CHECK",
        payload: {
          category: "lifestyle",
          key: "stress",
          value: false
        }
      });
    } else if (!lifestyle.stress) {
      store.dispatch({
        type: "SET_OBJECT_CHECK",
        payload: {
          category: "lifestyle",
          key: "stress",
          value: true
        }
      });
      str += str.length > 0 ? " , stress" : "stress";
    }
    let habitFlag = false;
    for (const key in lifestyle.habits) {
      if (lifestyle.habits[key]) {
        habitFlag = true;
        break;
      }
    }
    if (checkComplaintOthers) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "habits", value: false }
      });
    } else if (checker && !habitFlag) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "habits", value: true }
      });
      str += str.length > 0 ? " , habits" : "habits";
    }
    if (
      lifestyle.habits.Others &&
      lifestyle.habitOther.length === 0 &&
      !checkComplaintOthers
    ) {
      store.dispatch({
        type: "SET_KEY_CHECK",
        payload: { key: "habitOther", value: true }
      });
      str += str.length > 0 ? " , Other habits" : "Other habits";
    }
    if (str.length === 0) {
      // api call
      this.handleSendRecommendedProducts();
    } else {
      toast.error(`Please fill all the mandatory fields`);
    }
  };
  render() {
    return (
      <>
        {this.state.loader ? (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Loader type="TailSpin" />
          </div>
        ) : (
          <button className="button_style" onClick={this.handleClick}>
            Update and Write Prescription
          </button>
        )}
      </>
    );
  }
}
const mapStateToProps = () => {
  const id = store.getState().rxWriterReducer.id;
  return {
    prescribeProducts:
      store.getState().observationReducer[id].prescribeProducts,
    age: store.getState().rxWriterReducer.patientDetails.age,
    brand: store.getState().rxWriterReducer.brand,
    observations: store.getState().observationReducer[id],
    presetIdToObj: store.getState().rxWriterReducer.presetIdToObj,
    prescriptionData: store.getState().rxWriterReducer.prescriptionData,
    form: store.getState().rxWriterReducer.form,
    id: store.getState().rxWriterReducer.id,
    language: store.getState().rxWriterReducer.language,
    rxData: store.getState().rxWriterReducer
  };
};
export default connect(mapStateToProps)(Update);

const sampleState = {
  singleImageModal: { show: false, image_src: "", image_date: "" },
  weight: "",
  height: "",
  concerns: [
    {
      text: "",
      pretext: [],
      metadata: {
        severity: "",
        category: null,
        onsetValue: null,
        onsetDuration: null
      }
    }
  ],
  lifestyle: {
    text: "",
    pretext: []
  },
  diagnosis: [
    {
      text: "",
      pretext: [],
      metadata: { type: "" }
    }
  ],
  specialConsult: {
    text: "",
    pretext: []
  },
  suggestDiagnostics: {
    text: "",
    pretext: []
  },
  suggestProcedure: {
    text: "",
    pretext: []
  },
  doctorNotes: {
    text: "",
    pretext: []
  },
  allergyIsEntering: "",
  allergySpecifications: [
    {
      text: "",
      pretext: [],
      metadata: { type: "" }
    }
  ],
  pastTreatments: {
    text: "",
    pretext: [],
    metadata: {
      isEntering: ""
    }
  },
  medicalHistory: {
    text: "",
    pretext: [],
    metadata: {
      isEntering: ""
    }
  },
  familyHistory: {
    text: "",
    pretext: [],
    metadata: {
      isEntering: ""
    }
  },
  otherDetails: {
    text: "",
    pretext: [],
    metadata: {
      isEntering: ""
    }
  },
  lifestyleExisting: {
    metadata: {
      diet: "",
      sleep: "",
      exercise: "",
      stress: "",
      habits: {
        None: false,
        Alcohol: false,
        Tobacco: false,
        Narcotics: false,
        Caffeine: false,
        Others: false
      },
      habitOther: ""
    }
  },
  prescribeProducts: [],
  otherProducts: []
};

const sampleConcern = {
  text: "",
  pretext: [],
  metadata: { severity: "", category: null, day: null, month: null, year: null }
};
const sampleAllergy = {
  text: "",
  pretext: [],
  metadata: { type: "" }
};
const sampleDiagnosis = {
  text: "",
  pretext: [],
  metadata: { type: "" }
};
const initialState = {
  appointmentId: ""
};
const observationReducer = (state = initialState, action) => {
  const { appointmentId } = state;
  switch (action.type) {
    case "SET_CURRENT_APPOINTMENT_ID":
      return {
        ...state,
        appointmentId: action.payload
      };
    case "ADD_APPOINTMENT_ID":
      return { ...state, [action.payload]: sampleState };
    case "SET_LIFESTYLE_EXISTING":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          lifestyleExisting: action.payload
        }
      };
    case "SET_WEIGHT_HEIGHT_DATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          [action.payload.key]: action.payload.value
        }
      };
    case "SHOW_SINGLE_IMAGE_MODAL":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          singleImageModal: {
            show: true,
            image_date: action.payload.image_date,
            image_src: action.payload.image_src,
            image_arr: action.payload.image_arr
          }
        }
      };
    case "CLOSE_SINGLE_IMAGE_MODAL":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          singleImageModal: { show: false, image_src: "", image_date: "" }
        }
      };
    case "CLEAR_OBSERVATION_STATE":
      return {
        ...state,
        [appointmentId]: sampleState
      };
    case "SET_CONCERN_DATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          concerns: state[appointmentId].concerns.map((val, index) => {
            if (index === action.payload.index)
              return { ...val, [action.payload.key]: action.payload.value };
            else return val;
          })
        }
      };
    case "SET_CONCERN_METADATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          concerns: state[appointmentId].concerns.map((val, index) => {
            if (index === action.payload.index)
              return {
                ...val,
                metadata: {
                  ...val.metadata,
                  [action.payload.key]: action.payload.value
                }
              };
            else return val;
          })
        }
      };
    case "ADD_CONCERN":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          concerns: [...state[appointmentId].concerns, sampleConcern]
        }
      };
    case "DELETE_CONCERN":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          concerns: state[appointmentId].concerns.slice(
            0,
            state[appointmentId].concerns.length - 1
          )
        }
      };
    case "SET_ALLERGY":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          allergySpecifications: action.payload
        }
      };
    case "SET_ALLERGY_IS_ENTERING":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          allergyIsEntering: action.payload
        }
      };
    case "RESET_ALLERGY":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          allergySpecifications: sampleState.allergySpecifications
        }
      };
    case "SET_ALLERGY_DATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          allergySpecifications: state[appointmentId].allergySpecifications.map(
            (val, index) => {
              if (index === action.payload.index)
                return { ...val, [action.payload.key]: action.payload.value };
              else return val;
            }
          )
        }
      };
    case "SET_ALLERGY_TYPE":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          allergySpecifications: state[appointmentId].allergySpecifications.map(
            (val, index) => {
              if (index === action.payload.index)
                return { ...val, metadata: { type: action.payload.value } };
              else return val;
            }
          )
        }
      };
    case "ADD_ALLERGY":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          allergySpecifications: [
            ...state[appointmentId].allergySpecifications,
            sampleAllergy
          ]
        }
      };
    case "DELETE_ALLERGY":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          allergySpecifications: state[
            appointmentId
          ].allergySpecifications.slice(
            0,
            state[appointmentId].allergySpecifications.length - 1
          )
        }
      };
    case "SET_ADDITIONAL_INFO_LIFESTYLE":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          lifestyleExisting: {
            ...state[appointmentId].lifestyleExisting,
            metadata: {
              ...state[appointmentId].lifestyleExisting.metadata,
              [action.payload.key]: action.payload.value
            }
          }
        }
      };
    case "SET_ADDITIONAL_INFO_HABITS":
      if (action.payload === "None") {
        return {
          ...state,
          [appointmentId]: {
            ...state[appointmentId],
            lifestyleExisting: {
              ...state[appointmentId].lifestyleExisting,
              metadata: {
                ...state[appointmentId].lifestyleExisting.metadata,
                habits: {
                  ...sampleState.lifestyleExisting.metadata.habits,
                  [action.payload]:
                    !state[appointmentId].lifestyleExisting.metadata.habits[
                      action.payload
                    ]
                },
                habitOther: ""
              }
            }
          }
        };
      } else if (action.payload === "Other") {
        return {
          ...state,
          [appointmentId]: {
            ...state[appointmentId],
            lifestyleExisting: {
              ...state[appointmentId].lifestyleExisting,
              metadata: {
                ...state[appointmentId].lifestyleExisting.metadata,
                habits: {
                  ...state[appointmentId].lifestyleExisting.metadata.habits,
                  [action.payload]:
                    !state[appointmentId].lifestyleExisting.metadata.habits[
                      action.payload
                    ]
                },
                habitOther: ""
              }
            }
          }
        };
      } else
        return {
          ...state,
          [appointmentId]: {
            ...state[appointmentId],
            lifestyleExisting: {
              ...state[appointmentId].lifestyleExisting,
              metadata: {
                ...state[appointmentId].lifestyleExisting.metadata,
                habits: {
                  ...state[appointmentId].lifestyleExisting.metadata.habits,
                  [action.payload]:
                    !state[appointmentId].lifestyleExisting.metadata.habits[
                      action.payload
                    ],
                  None: false
                }
              }
            }
          }
        };
    case "SET_HABIT_OTHER_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          lifestyleExisting: {
            ...state[appointmentId].lifestyleExisting,
            metadata: {
              ...state[appointmentId].lifestyleExisting.metadata,
              habitOther: action.payload
            }
          }
        }
      };
    case "SET_LIFESTYLE_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          lifestyle: { ...state[appointmentId].lifestyle, text: action.payload }
        }
      };
    case "SET_LIFESTYLE_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          lifestyle: {
            ...state[appointmentId].lifestyle,
            pretext: action.payload
          }
        }
      };
    case "SET_SPECIAL_CONSULT_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          specialConsult: {
            ...state[appointmentId].specialConsult,
            text: action.payload
          }
        }
      };
    case "SET_SPECIAL_CONSULT_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          specialConsult: {
            ...state[appointmentId].specialConsult,
            pretext: action.payload
          }
        }
      };
    case "SET_SUGGEST_DIAGNOSTICS_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          suggestDiagnostics: {
            ...state[appointmentId].suggestDiagnostics,
            text: action.payload
          }
        }
      };
    case "SET_SUGGEST_DIAGNOSTICS_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          suggestDiagnostics: {
            ...state[appointmentId].suggestDiagnostics,
            pretext: action.payload
          }
        }
      };
    case "SET_SUGGEST_PROCEDURE_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          suggestProcedure: {
            ...state[appointmentId].suggestProcedure,
            text: action.payload
          }
        }
      };
    case "SET_SUGGEST_PROCEDURE_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          suggestProcedure: {
            ...state[appointmentId].suggestProcedure,
            pretext: action.payload
          }
        }
      };
    case "SET_DOCTOR_NOTES_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          doctorNotes: {
            ...state[appointmentId].doctorNotes,
            text: action.payload
          }
        }
      };
    case "SET_DOCTOR_NOTES_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          doctorNotes: {
            ...state[appointmentId].doctorNotes,
            pretext: action.payload
          }
        }
      };
    case "SET_PAST_MEDICAL":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          [action.payload.key]: action.payload.value
        }
      };
    case "SET_PAST_TREATMENTS_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          pastTreatments: {
            ...state[appointmentId].pastTreatments,
            text: action.payload
          }
        }
      };
    case "SET_PAST_TREATMENTS_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          pastTreatments: {
            ...state[appointmentId].pastTreatments,
            pretext: action.payload
          }
        }
      };
    case "SET_PAST_TREATMENTS_METADATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          pastTreatments: {
            ...state[appointmentId].pastTreatments,
            metadata: { isEntering: action.payload }
          }
        }
      };
    case "SET_MEDICAL_HISTORY_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          medicalHistory: {
            ...state[appointmentId].medicalHistory,
            text: action.payload
          }
        }
      };
    case "SET_MEDICAL_HISTORY_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          medicalHistory: {
            ...state[appointmentId].medicalHistory,
            pretext: action.payload
          }
        }
      };
    case "SET_MEDICAL_HISTORY_METADATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          medicalHistory: {
            ...state[appointmentId].medicalHistory,
            metadata: { isEntering: action.payload }
          }
        }
      };
    case "SET_FAMILY_HISTORY_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          familyHistory: {
            ...state[appointmentId].familyHistory,
            text: action.payload
          }
        }
      };
    case "SET_FAMILY_HISTORY_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          familyHistory: {
            ...state[appointmentId].familyHistory,
            pretext: action.payload
          }
        }
      };
    case "SET_FAMILY_HISTORY_METADATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          familyHistory: {
            ...state[appointmentId].familyHistory,
            metadata: { isEntering: action.payload }
          }
        }
      };
    case "SET_OTHER_DETAILS_TEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          otherDetails: {
            ...state[appointmentId].otherDetails,
            text: action.payload
          }
        }
      };
    case "SET_OTHER_DETAILS_PRETEXT":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          otherDetails: {
            ...state[appointmentId].otherDetails,
            pretext: action.payload
          }
        }
      };
    case "SET_OTHER_DETAILS_METADATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          otherDetails: {
            ...state[appointmentId].otherDetails,
            metadata: { isEntering: action.payload }
          }
        }
      };
    case "RESET_PAST_MEDICAL_FIELD":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          [action.payload]: sampleState[action.payload]
        }
      };
    case "SET_DIAGNOSIS_TYPE":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          diagnosis: state[appointmentId].diagnosis.map((val, index) => {
            if (index === action.payload.index)
              return { ...val, metadata: { type: action.payload.value } };
            else return val;
          })
        }
      };
    case "SET_DIAGNOSIS_DATA":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          diagnosis: state[appointmentId].diagnosis.map((val, index) => {
            if (index === action.payload.index)
              return { ...val, [action.payload.key]: action.payload.value };
            else return val;
          })
        }
      };
    case "ADD_DIAGNOSIS":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          diagnosis: [...state[appointmentId].diagnosis, sampleDiagnosis]
        }
      };
    case "DELETE_DIAGNOSIS":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          diagnosis: state[appointmentId].diagnosis.slice(
            0,
            state[appointmentId].diagnosis.length - 1
          )
        }
      };
    case "SET_PRESCRIBED_PRODUCTS":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          prescribeProducts: action.payload
        }
      };
    case "SET_PRESCRIBED_PRODUCTS_IMPROVEMENT":
      let newPrescribedProducts = state[appointmentId].prescribeProducts;
      newPrescribedProducts[action.payload.index].metadata.improvement =
        action.payload.value;
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          prescribeProducts: newPrescribedProducts
        }
      };
    case "SET_OTHER_PRODUCTS":
      return {
        ...state,
        [appointmentId]: {
          ...state[appointmentId],
          otherProducts: action.payload
        }
      };
    default:
      return state;
  }
};
export default observationReducer;

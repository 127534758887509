import React, { Component } from "react";
import RecommendedProducts from "./RecommendedProducts";
import OrderPlaced from "./OrderPlaced";
import RecommendationsForLifestyle from "./RecommendationsForLifestyle";
import AppointmentDetails from "./AppointmentDetails";
import PreviewPrescription from "./PreviewPrescription";
import Cancel from "./Cancel";
import AddProduct from "./AddProduct";
import "./Perscription.scss";
import OtherProducts from "./OtherProducts";

export default class Perscription extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="prescription-container">
        <RecommendedProducts />
        <AddProduct />
        <OrderPlaced />
        <OtherProducts />
        <RecommendationsForLifestyle />
        <AppointmentDetails />
        <div className="bottom-actions">
          <Cancel />
          <PreviewPrescription />
        </div>
      </div>
    );
  }
}

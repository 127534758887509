import dataResource from "../../../constants/dataResource";
export const COLUMNS = [
  {
    title: "Active",
    field: dataResource?.DOCTOR_STATUS_LIST?.ACTIVE
  },
  {
    title: "Busy",
    field: dataResource?.DOCTOR_STATUS_LIST?.BUSY
  },
  {
    title: "Cool Off",
    field: dataResource?.DOCTOR_STATUS_LIST?.COOLOFF
  },
  {
    title: "Break",
    field: dataResource?.DOCTOR_STATUS_LIST?.BREAK
  },
  {
    title: "OOC",
    field: dataResource?.DOCTOR_STATUS_LIST?.OUT_OF_CLINIC
  },
  {
    title: "Step Away",
    field: dataResource?.DOCTOR_STATUS_LIST?.STEP_AWAY
  },
  {
    title: "Checkout",
    field: dataResource?.DOCTOR_STATUS_LIST?.CHECKED_OUT
  }
];

export const UPDATE_COLUMNS = [
  {
    title: "Doctor ID",
    field: "doctorId"
  },
  {
    title: "Doctor Name",
    field: "doctorName"
  },
  {
    title: "curentStatus",
    field: "status"
  },
  {
    title: "updatedSince",
    field: "lastUpdated"
  },
  {
    title: "Checkout Doctor",
    field: "checkOut"
  },
  {
    title: "Checkin Doctor",
    field: "checkIn"
  }
];

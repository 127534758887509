import {
  CALL_SOCKET_EMITTER_STATUS,
  PRIORITY_LIST_CALL_SOCKET_EMITTER_STATUS
} from "../../../../constants/call-status.socket-emitter-status";

export const audioStatusCompare = (socketAudioStatus, pollingAudioStatus) => {
  try {
    if (
      (!socketAudioStatus && !pollingAudioStatus) ||
      (!CALL_SOCKET_EMITTER_STATUS[socketAudioStatus] &&
        !CALL_SOCKET_EMITTER_STATUS[pollingAudioStatus])
    ) {
      return CALL_SOCKET_EMITTER_STATUS.NONE;
    }

    if (!CALL_SOCKET_EMITTER_STATUS[socketAudioStatus]) {
      return pollingAudioStatus;
    }

    if (!CALL_SOCKET_EMITTER_STATUS[pollingAudioStatus]) {
      return socketAudioStatus;
    }
    /**
     * Lower the number, higher the priority
     */
    if (
      PRIORITY_LIST_CALL_SOCKET_EMITTER_STATUS[socketAudioStatus] <
      PRIORITY_LIST_CALL_SOCKET_EMITTER_STATUS[pollingAudioStatus]
    ) {
      return socketAudioStatus;
    }

    return pollingAudioStatus;
  } catch (e) {
    console.warn(e);
    return pollingAudioStatus;
  }
};

export const getCallStatusFromRx = appointmentData => {
  const showConnectedStatuses = ["c", "rf", "ns", "otc", "csp", "csd"];
  try {
    if (showConnectedStatuses.includes(appointmentData.status)) {
      return CALL_SOCKET_EMITTER_STATUS.CONNECTED;
    }

    if (!appointmentData.es_audio_status) {
      return CALL_SOCKET_EMITTER_STATUS.NONE;
    }

    return appointmentData.es_audio_status;
  } catch (e) {
    console.error(e);
    return "";
  }
};

import React from "react";
import { useAsync } from "../../../hooks";
import Table3 from "./Table3";
import dataResource from "../../../constants/dataResource";
import { DateGroup } from "../../core/DashboardPage/AdminNav";
const statusListObj = dataResource.statusListObj;
export default function NewDataTableViewHistory2({
  title,
  cols,
  url,
  reqBody,
  actions,
  rowStyle,
  search,
  pageSize,
  pageSizeOptions,
  fromS3,
  obj
}) {
  const [data] = useAsync(url, reqBody, fromS3);
  // console.log("ss");
  // console.log(data);
  const columns = cols.map(col => {
    if (col === "status") {
      return {
        title: col,
        field: col,
        render: row => statusListObj[row.status]
      };
    } else if (col === "brand") {
      return { field: col, hidden: 1 };
    } else if (col === "category") {
      return {
        title: "Category",
        field: col,
        render: row => {
          let arr = row.category.split(",");
          return arr
            .map(category => dataResource.categoryMap[category])
            .join(", ");
        }
      };
    } else if (
      col === "prescription_generated" ||
      col === "image_uploaded" ||
      col === "image_upload_reminder_sent"
    ) {
      return {
        title: col,
        field: col,
        lookup: {
          0: "No",
          1: "Yes"
        }
      };
    }
    return {
      title: col,
      field: col
    };
  });
  return (
    <>
      <DateGroup />
      {data ? (
        <Table3
          title={title}
          cols={columns}
          rows={data}
          actions={actions ? actions : []}
          rowStyle={rowStyle}
          search={search}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          obj={obj}
        />
      ) : null}
    </>
  );
}

import React, { useEffect } from "react";
import Confirmation from "./Update";
import Complaints from "./Complaints";
import PlanTreatment from "./PlanTreatment";
import AdditionalInformation from "./ Additional Information";
import ConcernsCard from "./ConcernsCard";
import DiagnosisAndDoctorNotes from "./DiagnosisAndDoctorNotes";
import RequestImages from "./Complaints/DoctorNotes/RequestImages";
import ViewImages from "./Complaints/DoctorNotes/ViewImages";
import SendVideoLink from "./Complaints/DoctorNotes/SendVideoLink";
import MedicalHistory from "./Complaints/DoctorNotes/MedicalHistory";
import LatestImages from "./LatestImages";

const Observations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ marginTop: "-20px" }}>
      <LatestImages />
      {/* <PrefillObservations /> */}
      <ConcernsCard />
      <Complaints />
      <AdditionalInformation />
      {/* <PlanTreatment /> */}
      <DiagnosisAndDoctorNotes />
      <div className="center" style={{ marginBottom: 90, padding: 37 }}>
        <Confirmation />
      </div>
    </div>
  );
};

export default Observations;

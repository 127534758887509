import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table6 from "../../common/Tables/Table6.js";
import {
  appointmentsTodaySummary,
  appointmentsPriorityTable
} from "./NewCols.js";

export default function AppointmentsPriority({
  reqBody,
  rowStyle,
  setCountPriority
}) {
  const [data, setData] = useState({});
  useEffect(() => {
    axios
      .post(config.url.MISSED_CALLS, reqBody)
      .then(res => {
        let { summary, results } = res.data;
        results = results.filter(row => !row.cancelled);
        setCountPriority(results.length);
        setData({
          appointmentsPriorityTable: results,
          priority_summary: [summary]
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, [reqBody?.from_date, reqBody?.to_date]);

  return data ? (
    <>
      <Table6
        title="ALL"
        pageSize={100}
        cols={appointmentsPriorityTable}
        rows={data.appointmentsPriorityTable || []}
        rowStyle={rowStyle}
        search={true}
      />
    </>
  ) : null;
}

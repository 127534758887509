import React from "react";
import { useAsync } from "../../../hooks";
import Table2 from "./Table2";

export default function DataTable3({ title, cols, url, reqBody, actions }) {
  const [data] = useAsync(url, reqBody);
  return data ? (
    <Table2
      title={title}
      cols={cols}
      rows={data}
      actions={actions ? actions : []}
    />
  ) : null;
}

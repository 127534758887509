import React from "react";
export const doctorColumns = [
  "doctorId",
  "doctorName",
  "doctorEmail",
  "doctorPhone",
  "gender",
  "medicalId",
  "qualifications",
  "specialization",
  "experience",
  "active",
  "paid",
  "app",
  "immediate",
  "scheduled",
  "standby",
  "pending"
]
  .map(col => {
    return {
      title: col,
      field: col
    };
  })
  .concat([
    {
      title: "description",
      field: "description",
      cellStyle: { minWidth: "600px", wordBreak: "break-all" }
    },
    {
      title: "doctorImage",
      field: "doctorImage",
      cellStyle: { minWidth: "300px", wordBreak: "break-all" }
    },
    {
      // TODO: remove this if not useful
      title: "signImage",
      field: "signImage",
      editComponent: () => {
        function encodeImageFileAsURL(e) {
          if (e.target.files.length) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onloadend = function () {
              let base64Img = reader.result.slice(22);
              localStorage.setItem("signImage", base64Img);
            };
            reader.readAsDataURL(file);
          }
        }
        return (
          <input
            type="file"
            accept="image/png"
            onChange={e => encodeImageFileAsURL(e)}
          />
        );
      },
      render: rowData => {
        if (rowData.signImage)
          return (
            <img
              src={rowData.signImage}
              style={{ width: "100px", height: "100px" }}
              alt="error"
            />
          );
        else if (rowData.signImage) {
          return (
            <img
              src={"data:image/png;base64," + rowData.sign_image}
              style={{ width: "100px", height: "100px" }}
              alt="error"
            />
          );
        }
        return <input type="file" disabled />;
      }
    },
    {
      title: "WAPhone",
      field: "waPhone"
    }
  ]);

export const mandoryForAdd = [
  "doctorName",
  "doctorEmail",
  "doctorPhone",
  "gender",
  "medicalId",
  "qualifications",
  "specialization",
  "experience",
  "active",
  "paid",
  "app",
  "immediate",
  "scheduled",
  "standby",
  "pending",
  "description",
  "signImage"
];

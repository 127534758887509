import React, { useState, useEffect, useMemo } from "react";
import { NoDataFound, TableContainer } from "./styles";
import MaterialTable from "material-table";
import { MaterialTableWrapper } from "../V2/AppointmentSummary/common/MaterialTableWrapper";
import { doctorHoursColumn } from "./doctorHoursColumn";
export default function DoctorHoursSection({ responseData }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  const data = useMemo(
    () =>
      responseData?.doctorWiseMap?.map(rowData => ({
        doctorId: rowData?.doctorId,
        actualHoursDuration: rowData?.actualDuty,
        dutyHoursDuration: rowData?.dutyHours,
        activeOoc: rowData?.oocStatus?.["1"] / 60 || 0,
        absentOoc: rowData?.oocStatus?.["2"] / 60 || 0,
        leaveOoc: rowData?.oocStatus?.["3"] / 60 || 0,
        active: rowData?.statusWise?.active || 0,
        busy: rowData?.statusWise?.busy / 60 || 0,
        coolOff: rowData?.statusWise?.cooloff / 60 || 0,
        stepAwayCount: rowData?.stepAwayCount / 60 || 0,
        callDuration: rowData?.callDuration / 60 || 0
      }))[responseData]
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, []);

  if (!data || (data && data.length === 0))
    return (
      <NoDataFound>
        <span>No Data Found</span>
      </NoDataFound>
    );
  return (
    <TableContainer>
      <MaterialTableWrapper>
        <MaterialTable
          title={null}
          columns={doctorHoursColumn}
          data={data || []}
          page={10}
          options={{
            search: true,
            paging: true,
            exportAllData: true,
            exportButton: true,
            sorting: false,
            draggable: false,
            headerStyle: {
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "14px"
            },

            rowStyle: {
              backgroundColor: "white",
              width: "min-content",
              padding: "20px"
            },
            cellStyle: {
              fontSize: matches ? "13px" : "15px",
              backgroundColor: "white",
              color: "#6C6C6C"
            }
          }}
          style={{
            margin: "10px",
            padding: "10px",
            marginTop: "0px",
            paddingTop: "0px",
            backgroundColor: "transparent",
            boxShadow: "none"
          }}
        />
      </MaterialTableWrapper>
    </TableContainer>
  );
}

import React from "react";
import "./styles.scss";
import DoctorNotes from "../Complaints/DoctorNotes";
import Diagnosis from "../Complaints/Diagnosis";
import PastDoctorNotes from "../Complaints/PastDoctorNotes";
const DiagnosisAndDoctorNotes = () => {
  return (
    <div className="DADN__container">
      <div className="DADN__left">
        <Diagnosis />
      </div>
      <div className="DADN__right">
        <DoctorNotes />
        <PastDoctorNotes />
      </div>
    </div>
  );
};

export default DiagnosisAndDoctorNotes;

import React, { Component } from "react";
import { Button, Modal, TextField } from "@material-ui/core";
import "./SendPrescription.scss";
import AppointmentDetailsModal from "../../Perscription/AppointmentDetailsModal";
import Loader from "react-loader-spinner";
import Combobox from "react-widgets/lib/Combobox";
import config from "../../../../constants/apiList";
import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import clevertap from "clevertap-web-sdk";
import { Autocomplete } from "@mui/material";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";
class SendPrescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: this.props.email,
      phone: this.props.phone,
      emailSearchValue: "",
      selectedEmailArr: [this.props.emailArr],
      emailArr: this.props.emailArr,
      phoneArr: this.props.phoneArr,
      status: null
    };
  }
  componentDidMount() {
    let emailArray = this.state.emailArr;
    for (const [key, value] of emailArray.entries()) {
      if (key === 0) {
        this.setState({
          email: value
        });
      }
    }
    let phoneArray = this.state.phoneArr;
    for (const [key, value] of phoneArray.entries()) {
      if (key === 0) {
        this.setState({ phone: value });
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.email !== this.props.email ||
      prevProps.phone !== this.props.phone
    ) {
      this.setState({
        email: this.props.email,
        phone: this.props.phone
        //selectedEmailArr: [this.props.email],
        //emailArr: [this.props.email, ""]
      });
    }
  }
  isValidEmail = email => {
    if (email) {
      const atpos = email.indexOf("@");
      if (email.length >= 3 && atpos > 0 && atpos < email.length - 1) {
        return true;
      }
      return false;
    }
    return false;
  };
  isValidPhone = phone => {
    if (phone) {
      if (phone.length == 10) {
        return true;
      }
      return false;
    }
    return false;
  };
  handleSendPrescription = () => {
    clevertap.event.push("Send Prescription Clicked", {
      Source: this.props.form,
      Category: this.props.prescriptionData.category_label,
      "Call Type":
        this.props.prescriptionData?.mode === "bw_video"
          ? "Video call"
          : "Voice Call",
      Appointments: this.props.id,
      Brand: this.props.brand,
      Language: this.props.language
    });
    clevertap.event.push("Rx Products Sent", {
      Count: this.props.lastGenerateReportPayload.medicines.length,
      "Product ID": this.props.lastGenerateReportPayload.medicines
        .map(medicine => medicine.product_id)
        ?.join(","),
      Category: this.props.prescriptionData.category_label,
      "Product Name": this.props.lastGenerateReportPayload.medicines
        .map(medicine => medicine.name)
        ?.join(","),
      Language: this.props.language
    });

    if (
      this.isValidEmail(this.state.email) &&
      this.isValidPhone(this.state.phone)
    ) {
      this.setState({ loading: true });
      const payload = {
        brand: this.props.brand,
        doctorId: this.props.doctorId,
        order_confirmed: this.props.orderConfirmed ? 1 : 0,
        prescriptionId: this.props.prescriptionId,
        sendTo: this.state.email,
        phone: this.state.phone
      };
      store.dispatch({
        type: "SET_PRESCRIPTION_ID",
        payload: this.props.prescriptionId
      });
      axios
        .post(config.url.SEND_REPORT_URL, payload)
        .then(res => {
          toast.info(res.data.body.data);
          addToGTM({
            event: GTM_EVENTS?.PRESCRIPTION_SENT,
            prescriptionSentData: {
              phone: this.state.phone,
              email: this.state.email,
              products: (
                this.props?.lastGenerateReportPayload?.medicines?.map(
                  medicine => medicine?.name
                ) || []
              ).join(", ")
            }
          });
          clevertap.event.push("Prescription sent", {
            Appointments: this.props.id,
            Source: this.props.form,
            Category: this.props.prescriptionData?.category_label,
            "Call Type":
              this.props.prescriptionData?.mode === "bw_video"
                ? "Video call"
                : "Voice Call",
            Brand: this.props.brand,
            "Doctor ID": this.props.prescriptionData?.doctor_id,
            "Doctor Name": this.props.patientDetails?.doctor_name,
            Language: this.props.language
          });
          this.setState({ loading: false, status: res?.data?.body?.rxStatus });
          store.dispatch({ type: "SET_UPDATE_STATUS_MODAL", payload: true });
          store.dispatch({ type: "SET_UPDATE_STATUS_LINK", payload: true });
          store.dispatch({ type: "CLEAR_OBSERVATION_STATE" });
        })
        .catch(err => {
          console.log(err);
        });
      const editProfilePayload = this.props.patientDetails;
      editProfilePayload.patient_medical_history = null;
      editProfilePayload.email = this.state.email;
      editProfilePayload.phone = this.state.phone;
      editProfilePayload.userId = this.props?.prescriptionData.user_id;
      editProfilePayload.brand = this.props?.prescriptionData.brand;
      editProfilePayload.maritalStatus =
        this.props?.patientDetails?.marital_status;

      axios
        .post(config.url.EDIT_PROFILE, editProfilePayload)
        .then(res => {
          this.setState({
            ...this.state,
            isLoading: false
          });
          store.dispatch({
            type: "CHANGE_PATIENT_DETAILS",
            data: editProfilePayload
          });
          store.dispatch({
            type: "CHANGE_PATIENT_DETAILS_EDITABLE",
            data: false
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    } else {
      toast.error("Please enter a valid email address and phone number");
    }
  };
  handleDelete = email => {
    const temp = this.state.selectedEmailArr.filter(item => item !== email);
    this.setState({ selectedEmailArr: temp });
  };
  handleChange = email => {
    if (this.state.selectedEmailArr.indexOf(email) === -1) {
      this.setState({
        selectedEmailArr: [...this.state.selectedEmailArr, email]
      });
    }
  };
  changeSearchValue = value => {
    this.setState({ emailSearchValue: value });
    const temp = [...this.state.emailArr];
    temp[temp.length - 1] = value;
    this.setState({ emailArr: temp });
  };
  getEmailValue = () => {
    const isDummyEmail = email => {
      const dummyEmailPattern = /^[A-Za-z0-9._%+-]+@mw\.co$/;
      return email.match(dummyEmailPattern) !== null;
    };

    if (isDummyEmail(this.state.email)) {
      return "";
    }
    return this.state.email;
  };

  isSendPrescriptionDisabled = () => {
    if (this.props.disabled) {
      return !(this.props.disabled && this.getEmailValue() !== "");
    }
    return this.getEmailValue() === "";
  };

  render() {
    return (
      <>
        <AppointmentDetailsModal
          open={this.props.showModal}
          status={this.state.status}
          onClose={() =>
            store.dispatch({ type: "SET_UPDATE_STATUS_MODAL", payload: false })
          }
        />
        {this.state.loading ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Loader type="TailSpin" />
          </div>
        ) : (
          <div style={{ paddingTop: "50px" }}>
            <h3>Email</h3>
            {/*} <input
              className="send-prescription-email"
              type="email"
              placeholder="Enter Email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            /> */}
            <Combobox
              containerClassName="first-condition-search-combobox"
              hideCaret={true}
              placeholder="Type your email name here..."
              data={this.state.emailArr}
              onChange={value => {
                this.setState({
                  ...this.state,
                  email: value
                });
              }}
              onSelect={val => {
                this.setState(
                  {
                    email: undefined
                  },
                  () => {
                    this.setState({ email: val });
                  }
                );
              }}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  this.setState({
                    ...this.state,
                    email: ""
                  });
                }
              }}
              filter="contains"
              textField="name"
              value={this.getEmailValue()}
            />
            <div style={{ paddingTop: "50px" }}>
              <h3>Phone Number</h3>
              <Combobox
                containerClassName="first-condition-search-combobox"
                hideCaret={true}
                placeholder="Type your Mobile Number name here..."
                data={this.state.phoneArr}
                onChange={value => {
                  this.setState({
                    ...this.state,
                    phone: value
                  });
                }}
                onSelect={val => {
                  this.setState(
                    {
                      phone: undefined
                    },
                    () => {
                      this.setState({ phoneNumber: val });
                    }
                  );
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    this.setState({
                      ...this.state,
                      phone: ""
                    });
                  }
                }}
                filter="contains"
                textField="name"
                value={this.state.phone}
              />
            </div>
            <div style={{ paddingTop: "50px" }}>
              <button
                className="send-prescription-button"
                onClick={() => {
                  this.handleSendPrescription();
                }}
                disabled={this.isSendPrescriptionDisabled()}
              >
                Send Prescription
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    brand: store.getState().rxWriterReducer.brand,
    form: store.getState().rxWriterReducer.form,
    id: store.getState().rxWriterReducer.id,
    prescriptionData: store.getState().rxWriterReducer.prescriptionData,
    patientDetails: store.getState().rxWriterReducer.patientDetails,
    language: store.getState().rxWriterReducer.language,
    doctorId: store.getState().userReducer.hash_id,
    email: store.getState().rxWriterReducer.patientDetails?.email,
    emailArr: store.getState().rxWriterReducer.patientDetails?.alt_email,
    phone: store.getState().rxWriterReducer.patientDetails?.phone,
    phoneArr: store.getState().rxWriterReducer.patientDetails?.alt_phone,
    showModal: store.getState().rxWriterReducer.showUpdateStatusModal,
    lastGenerateReportPayload:
      store.getState().rxWriterReducer.lastGenerateReportPayload,
    language: store.getState().rxWriterReducer.language
  };
};
export default connect(mapStateToProps)(SendPrescription);

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./DownloadToggle.scss";
import { FiDownload } from "react-icons/fi";

function DownloadToggle(props) {
  return (
    <div className="download-toggle">
      <Button className="toggler-button" size="sm" onClick={props.onToggle}>
        Play
      </Button>
      {props.show ? (
        <div className="download-list-wrapper">
          <div className="download-list">
            {props.files.map((file, index) => {
              return (
                <div
                  className="download-button"
                  onClick={evt => props.onDownloadClick(file)}
                >
                  <div>
                    Part {file.part} | {file.created_dttm}
                  </div>
                  <FiDownload style={{ marginLeft: "20px" }} />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default DownloadToggle;

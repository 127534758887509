import styled from "styled-components";

export const TitleSpan = styled.span`
  color: #22548a;
  font-size: 20px;
  font-weight: 600;
  margin-right: 50px;
`;

export const ReviewTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 15px;
`;
import React, { useState } from "react";
import "./ViewImages.scss";
import { Modal } from "react-bootstrap";
import ImageTimeline from "../../../../PatientFile/PatientDetails/ImageTimeline";
import { addToGTM } from "../../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../../constants/tagManager.constants";
const ViewImages = props => {
  const [open, setOpen] = useState(false);
  const quickAccessBtnStyle = {
    background: " rgba(34, 84, 138, 0.05)",
    color: "rgba(34, 84, 138, 1)",
    border: "1.5px solid rgba(81, 148, 209, 0.5)"
  };
  const onOpen = () => {
    setOpen(true);
    addToGTM({
      event: GTM_EVENTS?.IMAGE_TIMELINE_VIEWED
    });
  };
  return (
    <div style={{ width: "100%" }}>
      <Modal
        width="70%"
        show={open}
        onHide={() => setOpen(false)}
        centered
        dialogClassName="view-images-modal"
      >
        <ImageTimeline closeModal={() => setOpen(false)} modalOpen={open} />
      </Modal>
      <div className="viewImages__button-container">
        <button
          className="medical-history-button_"
          onClick={onOpen}
          style={props.quickAccess ? quickAccessBtnStyle : {}}
          onMouseEnter={e => {
            if (props.quickAccess) {
              e.target.style.background = "#22548a";
              e.target.style.color = "white";
              e.target.style.border = "1.5px solid #22548a";
            }
          }}
          onMouseLeave={e => {
            if (props.quickAccess) {
              e.target.style.background = "rgba(34, 84, 138, 0.05)";
              e.target.style.color = "rgba(34, 84, 138, 1)";
              e.target.style.border = "1.5px solid rgba(81, 148, 209, 0.5)";
            }
          }}
        >
          {props.quickAccess ? "Images" : "View Images"}
        </button>
      </div>
    </div>
  );
};
export default ViewImages;

import React, { useState } from "react";
import "./RecommendationEngine.scss";
import PresetBuilder from "./PresetBuilder";
import RecommendationBuilder from "./RecommendationBuilder";
export default function RecommendationEngine() {
  const [pageNo, setPageNo] = useState(1);
  const [presetLoading, setPresetLoading] = useState(false);
  return (
    <div className="recommendation-engine-container">
      <div className="header-row">
        <button
          className="builder"
          onClick={() => setPageNo(1)}
          style={{ backgroundColor: pageNo === 1 ? "#C4C4C4" : "#EEECEC" }}
        >
          <div
            style={{
              fontWeight: pageNo === 1 ? "600" : "400",
              padding: "0 10px 5px 10px",
              width: "fit-content",
              borderBottom: pageNo === 1 ? "5px solid black" : "none"
            }}
          >
            Preset Builder
          </div>
        </button>
        <button
          className="builder"
          style={{ backgroundColor: pageNo === 2 ? "#C4C4C4" : "#EEECEC" }}
          onClick={() => setPageNo(2)}
        >
          <div
            style={{
              fontWeight: pageNo === 2 ? "600" : "400",
              padding: "0 5px 5px 5px",
              width: "fit-content",
              borderBottom: pageNo === 2 ? "5px solid black" : "none"
            }}
          >
            Recommendation Builder
          </div>
          {}
        </button>
      </div>
      {pageNo === 1 ? (
        <>
          <PresetBuilder
            loading={presetLoading}
            setLoading={setPresetLoading}
          />
        </>
      ) : pageNo === 2 ? (
        <>
          <RecommendationBuilder presetLoading={presetLoading} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

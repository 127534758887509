import React from "react";
import "./PrescriptionReportButton.scss";
import { Modal } from "react-bootstrap";
import PrescriptionReport from "../PrescriptionReport";
import axios from "axios";
import config from "../../../../../constants/apiList";
import { store } from "../../../../../reducers/Persist";
import { connect } from "react-redux";
import { addToGTM } from "../../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../../constants/tagManager.constants";
class PrescriptionReportButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPrescription: false,
      prescriptionLoading: false,
      prescriptionUrl: "",
      lastRxId: null,
      lastAppointmentDate: null
    };
  }
  getPdfUrl = data => {
    axios
      .post(config.url.GET_PDF_URL, data)
      .then(res => {
        addToGTM({
          event: GTM_EVENTS?.LAST_RX_VIEWED,
          lastRxData: {
            prescriptionId: data.prescriptionId
          }
        });
        this.setState({
          prescriptionUrl: res.data.body.data,
          prescriptionLoading: false
        });
      })
      .catch(err => {
        console.log("Error in get pdf url", err);
        this.setState({ prescriptionLoading: false });
      });
  };
  handleViewPrescriptionClick = () => {
    if (this.state.lastRxId === -1) return;

    this.setState({
      prescriptionLoading: true,
      openPrescription: true,
      prescriptionUrl: ""
    });
    if (!this.state.lastRxId) {
      const payload = {
        appointmentId: this.props.id,
        brand: this.props.brand,
        doctorId: this.props.doctorId,
        email: this.props.email
      };
      axios
        .post(config.url.GET_QUICK_VIEW, payload)
        .then(res => {
          this.setState({
            lastRxId: res.data.body.data.lastRxId,
            lastAppointmentDate: res.data.body.data.lastAppointmentDate
          });
          if (res.data.body.data.lastRxId !== -1) {
            const data = {
              doctorId: this.props.doctorId,
              prescriptionId: res.data.body.data.lastRxId,
              brand: this.props.brand
            };
            this.getPdfUrl(data);
          } else this.setState({ prescriptionLoading: false });
        })
        .catch(err => {
          console.log(err);
          this.setState({ prescriptionLoading: false });
        });
    } else {
      const data = {
        doctorId: this.props.doctorId,
        prescriptionId: this.state.lastRxId,
        brand: this.props.brand
      };
      this.getPdfUrl(data);
    }
  };
  quickAccessBtnStyle = {
    background: " rgba(34, 84, 138, 0.05)",
    color: "rgba(34, 84, 138, 1)",
    border: "1.5px solid rgba(81, 148, 209, 0.5)"
  };
  render() {
    return (
      <div>
        <Modal
          show={this.state.openPrescription}
          onHide={() => this.setState({ openPrescription: false })}
          centered
          dialogClassName="prescription-report-modal"
        >
          <PrescriptionReport
            closeModal={() => this.setState({ openPrescription: false })}
            prescriptionUrl={this.state.prescriptionUrl}
            prescriptionLoading={this.state.prescriptionLoading}
            lastRxId={this.state.lastRxId}
            lastAppointmentDate={this.state.lastAppointmentDate}
            centered
          />
        </Modal>
        <button
          className={
            this.props.isHeaderQuickView
              ? "quickview-prescription-button"
              : "view-prescription-button"
          }
          style={this.props.quickAccess ? this.quickAccessBtnStyle : {}}
          onClick={() => this.handleViewPrescriptionClick()}
          onMouseEnter={e => {
            if (this.props.quickAccess) {
              e.target.style.background = "#22548a";
              e.target.style.color = "white";
              e.target.style.border = "1.5px solid #22548a";
            }
          }}
          onMouseLeave={e => {
            if (this.props.quickAccess) {
              e.target.style.background = "rgba(34, 84, 138, 0.05)";
              e.target.style.color = "rgba(34, 84, 138, 1)";
              e.target.style.border = "1.5px solid rgba(81, 148, 209, 0.5)";
            }
          }}
        >
          {this.props.quickAccess ? "Last Rx" : "View Prescription"}
        </button>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    doctorId: store.getState().userReducer.hash_id,
    brand: store.getState().rxWriterReducer.brand,
    id: store.getState().rxWriterReducer.id,
    email: store.getState().rxWriterReducer.prescriptionData.email
  };
};
export default connect(mapStateToProps)(PrescriptionReportButton);

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import eventEmitter from "event-emitter";
import "./PopupBox.scss";

/****************** USAGE INSTRUCTIONS *********************
 *
 * pass the following this.props to the PopupBox component.
 *
 * type => type prop defines if it is a alert or confirm. values accepted (alert / confirm).
 * onSuccessCallback => The callback function to be called on success.
 * onCancelCallback => The callback function  to be called on cancel.
 *
 * eg. <PopupBox type="confirm" onSuccessCallback={} onCancelCallback={}></PopupBox>
 *
 ************************************************************/

const ee = new eventEmitter();
export const alertBox = (message, successCallback) => {
  ee.emit("alertB", message, successCallback);
};

export const confirmBox = (message, successCallback, cancelCallback) => {
  ee.emit("confirmB", message, successCallback, cancelCallback);
};

class PopupBox extends Component {
  initialState = {
    type: "",
    onSuccessCallback: "",
    onCancelCallback: "",
    show: false,
    message: ""
  };
  state = { ...this.initialState };

  constructor(props) {
    super(props);
    ee.on("alertB", (message, successCallback) => {
      this.setState({
        type: "alert",
        message: message,
        onSuccessCallback: successCallback,
        show: true
      });
    });
    ee.on("confirmB", (message, successCallback, cancelCallback) => {
      this.setState({
        type: "confirm",
        message: message,
        onSuccessCallback: successCallback,
        onCancelCallback: cancelCallback,
        show: true
      });
    });
  }

  clearState() {
    this.setState(this.initialState);
  }

  handleCancel() {
    if (this.state.onCancelCallback) {
      this.state.onCancelCallback();
    }
    this.clearState();
  }

  handleSuccess() {
    if (this.state.onSuccessCallback) {
      this.state.onSuccessCallback();
    }
    this.clearState();
  }

  render() {
    return (
      <div data-test="popup-container">
        <React.Fragment>
          {this.state.show && (
            <div className="pop-overlay">
              <div className="popup-container">
                <div className="popup-type">{this.state.type}</div>
                <div className="popup-message">{this.state.message}</div>
                <div className="p-action-box">
                  {this.state.type === "alert" && (
                    <Button
                      className="pbtn"
                      variant="primary"
                      onClick={() => this.handleSuccess()}
                    >
                      OK
                    </Button>
                  )}
                  {this.state.type === "confirm" && (
                    <>
                      <Button
                        className="pbtn"
                        variant="primary"
                        onClick={() => this.handleSuccess()}
                      >
                        OK
                      </Button>

                      <Button
                        className="pbtn"
                        variant="secondary"
                        onClick={() => this.handleCancel()}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}

export default PopupBox;

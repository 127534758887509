import styled from "styled-components";

export const TitleSpan = styled.span`
  color: #22548a;
  font-size: 20px;
  font-weight: 600;
  margin-right: 50px;
`;

export const AllAppointmentsSummaryTitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 60px;
  margin-bottom: 26px;
`;
import { useEffect, useState, useMemo } from "react";
import { io } from "socket.io-client";
import { doctorStatusChangeSocketConfig } from "../../../constants/socketList";

const { socketPath, socketUrl } = doctorStatusChangeSocketConfig;

const EVENT_NAME = {
  DOCTOR_STATUS_CHANGE: "doctorStatusChange"
};

export const useDoctorStatusChangeSocket = (
  userId,
  doctorJwtToken,
  doctorId
) => {
  /**
   * @description The socket connection to the server
   */
  const socket = useMemo(
    () =>
      io(socketUrl, {
        auth: {
          user_id: userId,
          token: doctorJwtToken,
          doctorId
        },
        path: socketPath,
        transports: ["polling"]
      }),
    [doctorId, doctorJwtToken, userId]
  );
  /**
   * Socket statuses define whether the socket is connected and if its loading
   */
  const [socketStatus, setSocketStatus] = useState({
    isSocketLoading: true,
    isSocketConnected: false,
    isSocketError: false,
    socketErrorMessage: ""
  });
  /**
   * The data that we receive from socket
   */
  const [data, setData] = useState(null);
  /**
   * The timer using setTimeout that is setup everytime an appointment is received from the socket
   */
  useEffect(() => {
    socket.on("connect", () => {
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: false,
        isSocketConnected: true,
        isSocketError: false,
        socketErrorMessage: ""
      });
    });
    socket.on("disconnect", () => {
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: true,
        isSocketConnected: false,
        isSocketError: false,
        socketErrorMessage: ""
      });
    });
    socket.on("connect_error", error => {
      setSocketStatus({
        ...socketStatus,
        isSocketConnected: false,
        isSocketError: true,
        socketErrorMessage: error.message,
        isSocketLoading: false
      });
      setData(null);
    });
    /**
     * When a new appointment is assigned to the doctor
     */
    socket.on(EVENT_NAME?.DOCTOR_STATUS_CHANGE, response => {
      const { data } = response;
      if (!data?.doctorId || parseInt(data?.doctorId) !== parseInt(doctorId)) {
        console.warn(
          "Received data from socket for doctor status change with wrong doctor id: ",
          data?.doctorId ?? "No doctor id"
        );
        return;
      }

      setData(data);
    });
    return () => {
      socket.close();
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: true,
        isSocketConnected: false,
        isSocketError: false,
        socketErrorMessage: ""
      });
    };
  }, [socket]);
  /**
   * Reset all state of the hook, just in case
   */
  const resetData = () => {
    setData(null);
  };
  return {
    socketStatus,
    doctorStatusChangeData: data,
    resetData
  };
};

import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-dropdown-select";
import { GlobalDataContext } from "./MealPlanner2";
import Rows from "./Rows";

const mealType = {
  1: "Early Morning",
  2: "Breakfast",
  3: "Mid Meal",
  4: "Lunch",
  5: "Snacks",
  6: "Dinner",
  7: "Mid Meal 2",
  8: "Snacks 2",
  9: "Pre-Workout",
  10: "Post-Workout",
  11: "Intra-Workout",
  12: "Bed-Time"
};
const mealType2 = {
  "Early Morning": {
    key: 1,
    dafaultTime: "07:00"
  },
  Breakfast: {
    key: 2,
    dafaultTime: "09:00"
  },
  "Mid Meal": {
    key: 3,
    dafaultTime: "11:00"
  },
  Lunch: {
    key: 4,
    dafaultTime: "13:00"
  },
  Snacks: {
    key: 5,
    dafaultTime: "15:00"
  },
  Dinner: {
    key: 6,
    dafaultTime: "20:00"
  },
  "Mid Meal 2": {
    key: 7,
    dafaultTime: "11:00"
  },
  "Snacks 2": {
    key: 8,
    dafaultTime: "15:00"
  },
  "Pre-Workout": {
    key: 9,
    dafaultTime: "15:00"
  },
  "Post-Workout": {
    key: 10,
    dafaultTime: "15:00"
  },
  "Intra-Workout": {
    key: 11,
    dafaultTime: "15:00"
  },
  "Bed-Time": {
    key: 12,
    dafaultTime: "21:00"
  }
};

const dropdown = {
  Carbohydrates: [
    {
      name: "Lemon Rice",
      conditions: { vegan: 1, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    },
    {
      name: "Fried Rice",
      conditions: { vegan: 0, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    }
  ],
  Fibers: [
    {
      name: "Lemon Rice",
      conditions: { vegan: 1, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    },
    {
      name: "Fried Rice",
      conditions: { vegan: 0, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    },
    {
      name: "Lemon Rice",
      conditions: { vegan: 1, keto: 0 },
      Portion: "Bowl",
      Tips: "Some default tip"
    }
  ]
};

const reverseDays1 = { Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6, Sun: 7 };
const days2 = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
};

export default function DayTypeRow({ defaultMealType, day, meal, ind }) {
  const { tableState, setTableState, col } = useContext(GlobalDataContext);
  const [repeat, setRepeat] = useState([]);
  const prevRepeatRef = useRef([]);

  useEffect(() => {
    setRepeat([]);
  }, [col]);

  useEffect(() => {
    const rowsToCopy = tableState[day][ind].rows;
    const timeToCopy = tableState[day][ind].time;

    let arr1 = prevRepeatRef.current.map(obj => obj.name);
    let arr2 = repeat.map(obj => obj.name);
    let arr3 = arr1.filter(name => !arr2.find(name2 => name === name2));
    let objj = tableState;

    for (let name of arr2) {
      objj = {
        ...objj,
        [days2[reverseDays1[name]]]: [
          ...objj[days2[reverseDays1[name]]].slice(0, ind),
          { time: timeToCopy, rows: [...rowsToCopy] },
          ...objj[days2[reverseDays1[name]]].slice(ind + 1)
        ]
      };
    }

    for (let name of arr3) {
      objj = {
        ...objj,
        [days2[reverseDays1[name]]]: [
          ...objj[days2[reverseDays1[name]]].slice(0, ind),
          {
            time: mealType2[tableState["Meal Type"][ind]].dafaultTime,
            rows: [{ item: "", portion: "", tips: "" }]
          },
          ...objj[days2[reverseDays1[name]]].slice(ind + 1)
        ]
      };
    }
    setTableState(objj);
  }, [repeat]);

  const days = [
    { name: "Mon" },
    { name: "Tue" },
    { name: "Wed" },
    { name: "Thu" },
    { name: "Fri" },
    { name: "Sat" },
    { name: "Sun" }
  ];
  return (
    <div style={{ borderBottom: "1px solid #EEF1EF" }}>
      <div
        style={{
          backgroundColor: "#EEF1EF",
          padding: "12px",
          margin: "10px 5px 10px 10px",
          fontWeight: "400",
          borderRadius: "4px"
        }}
      >
        <input
          type="time"
          className="input"
          value={tableState[day][ind].time}
          onChange={e => {
            const obj = { ...tableState[day][ind] };
            obj.time = e.target.value;
            setTableState({
              ...tableState,
              [day]: [
                ...tableState[day].slice(0, ind),
                obj,
                ...tableState[day].slice(ind + 1)
              ]
            });
          }}
        />
        <div style={{ display: "inline-flex", color: "#154166" }}>
          <div
            style={{
              display: "flex",
              padding: "10px 15px",
              backgroundColor: "white",
              marginLeft: "10px",
              fontWeight: "1000"
            }}
          >
            {defaultMealType}
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px 15px",
              backgroundColor: "white",
              marginLeft: "10px",
              fontWeight: "1000"
            }}
          >
            {day}
          </div>
        </div>
        {/* { new Date("01/01/2020 " + tableState[day][ind].time).toLocaleString('en-US').slice(-9,)} */}
        {<Rows day={day} ind={ind} />}
        <div style={{ display: "flex", margin: "5px 0" }}>
          <div
            style={{ cursor: "pointer", color: "#2F80ED", marginRight: "auto" }}
            onClick={() => {
              const obj = tableState[day][ind];
              obj.rows.push({ item: "", portion: "", tips: "" });
              setTableState({
                ...tableState,
                [day]: [
                  ...tableState[day].slice(0, ind),
                  { time: obj.time, rows: [...obj.rows] },
                  ...tableState[day].slice(ind + 1)
                ]
              });
            }}
          >
            {" "}
            + Add New Item{" "}
          </div>
          <div id={day + ind} style={{ display: "flex", color: "#2F80ED" }}>
            <div>Repeat on:&nbsp;</div>
            <Select
              options={days}
              values={repeat}
              multi
              onChange={value => {
                console.log(value);
                prevRepeatRef.current = repeat;
                setRepeat([...value]);
              }}
              dropdownPosition="auto"
              labelField="name"
              valueField="name"
              style={{ backgroundColor: "white" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

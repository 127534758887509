import React, { forwardRef, Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import { Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import UtilityService from "./../../../services/utilities";
import "./Scheduler.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { store } from "../../../reducers/Persist";
import { Form, Col } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import clevertap from "clevertap-web-sdk";
import { toast } from "react-toastify";
import { iaEnabled } from "../../../helpers/features.helper";
import { addToGTM } from "../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../constants/tagManager.constants";
import dataResource from "../../../constants/dataResource";

const getLocalConfig = brand => {
  const LOCAL_CONFIG = {
    APPOINTMENT_SCHEDULER_POINTING: {
      SLOTS: CONFIG_CONSTANTS.url.SLOTS_AVAILABLE_3,
      RESCHEDULE_APPOINTMENT: CONFIG_CONSTANTS.url.RESCHEDULE_APPOINTMENT,
      DOCTOR_SLOTS: CONFIG_CONSTANTS.url.SLOTS_AVAILABLE_3,
      RESCHEDULE_BY: {
        DOCTOR: "doctor",
        ADMIN: "doctor"
      }
    },
    EVENT_SCHEDULER_POINTING: {
      SLOTS: CONFIG_CONSTANTS.url.CATEGORY_SLOTS,
      RESCHEDULE_APPOINTMENT: CONFIG_CONSTANTS.url.RESCHEDULE_APPOINTMENT_V2,
      DOCTOR_SLOTS: CONFIG_CONSTANTS.url.DOCTOR_SLOTS,
      RESCHEDULE_BY: {
        DOCTOR: "doctor",
        ADMIN: "admin"
      }
    }
  };
  if (iaEnabled(brand)) return LOCAL_CONFIG.EVENT_SCHEDULER_POINTING;
  else return LOCAL_CONFIG.APPOINTMENT_SCHEDULER_POINTING;
};

class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      parameter: this.props.parameter,
      smsStatus: "",
      dateee: "Select Date",
      slotee: "Select Slot",
      exactSlot: null,
      date_rangeee: [],
      slots_obj: null,
      lookingForSlots: true,
      rescheduleStatus: "none",
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.fetchSlotsAvailable();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.doctorID != prevProps.doctorID ||
      this.props.appointment_id != prevProps.appointment_id ||
      this.props.app != prevProps.app ||
      this.props.language != prevProps.language ||
      this.props.anyDoctor != prevProps.anyDoctor
    ) {
      this.setState({
        smsStatus: "",
        dateee: "Select Date",
        slotee: "Select Slot",
        exactSlot: null,
        date_rangeee: [],
        slots_obj: null,
        lookingForSlots: true,
        rescheduleStatus: "none",
        errorMessage: ""
      });
      this.fetchSlotsAvailable();
    }
  }

  fetchSlotsAvailable = async () => {
    if (!this.props.brand) return;
    console.log("DATA FETCHED");
    let date = this.initialSelectedDate();
    let date_range = [];
    let today = moment();
    date_range.push(moment().format("dddd, DD MMMM YY"));
    for (var i = 1; i < 14; i++) {
      let temp_date = today.add(1, "days").format("dddd, DD MMMM YY");
      date_range.push(temp_date);
    }
    this.setState({ selectedDate: date });
    let slot_time_period = 10;
    let slot_length = 10;
    if (this.props.paid) {
      slot_time_period = 15;
      slot_length = 15;
    }
    if (this.props.categories && this.props.categories.includes("weight")) {
      slot_time_period = 30;
      slot_length = 30;
    }
    let data = {
      date_range,
      brand: this.props.brand,
      email: this.props.email,
      phone: this.props.phone,
      category: [...this.props.categories.split(",")],
      slot_time_period,
      slot_length,
      grouping_length: 30,
      app: this.props.app,
      source_id: 123456789,
      patient_name: this.props.name,
      source: this.props.source,
      doctorID: this.props.doctorID,
      paid: this.props.paid,
      language: this.props.language
    };
    const url = data.doctorID
      ? getLocalConfig(this.props.brand).DOCTOR_SLOTS
      : getLocalConfig(this.props.brand).SLOTS;
    axios
      .post(url, data)
      .then(res => {
        if (!Object.keys(res?.data?.slots_available ?? {}).length) {
          toast.error("No Slots Available!", { position: "top-right" });
          return;
        }
        let obj = {};
        for (let key in res.data.slots_available) {
          if (Object.keys(res.data.slots_available[key]).length > 0) {
            obj[key] = res.data.slots_available[key];
          }
        }

        let arr = Array.from(Object.keys(obj));
        this.setState({
          date_rangeee: arr,
          slots_obj: obj,
          lookingForSlots: false
        });
      })
      .catch(err => {
        toast.error(err.response?.data?.message || "Something went wrong");
        console.log(err);
      });
  };

  initialSelectedDate = () => {
    let today = new Date();
    if (today.getMinutes() > 30) {
      today.setHours(today.getHours() + 1, 0, 0);
    } else {
      today.setMinutes(30, 0);
    }
    if (
      (today.getHours() > 18 && today.getMinutes() > 30) ||
      today.getHours() >= 19
    ) {
      today.setDate(today.getDate() + 1);
      today.setHours(10, 0);
    }
    return today;
  };

  setSelectedDate = date => {
    this.setState({ selectedDate: date });
  };

  hideScheduler = value => {
    this.props.hideScheduler(value);
  };

  setMinutes = (date, start) => {
    return new Date(date.setMinutes(start));
  };

  setHours = (date, start) => {
    return new Date(date.setHours(start));
  };

  addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
  };

  getDateData = () => {
    let dt = this.state.selectedDate;
    let date = this.state.selectedDate.toISOString().split("T")[0];
    let startTime = this.state.selectedDate.toLocaleTimeString("en-GB");
    let endTime = new Date(dt.getTime() + 30 * 60000).toLocaleTimeString(
      "en-GB"
    );
    // console.log(this.state.parameter);
    let parameter = {
      doctorId: store.getState().userReducer.hash_id,
      form: this.props.type.value,
      id: this.props.userId,
      language: this.props.language,
      appointment_date: date,
      slot_start_time: startTime,
      slot_end_time: endTime,
      reschedule_by: this.props.isAdmin
        ? getLocalConfig(this.props.brand)?.RESCHEDULE_BY?.ADMIN
        : getLocalConfig(this.props.brand)?.RESCHEDULE_BY?.DOCTOR
    };
    return parameter;
  };
  handleUpdate = e => {
    let data = this.getDateData();
    let link = CONFIG_CONSTANTS.url.UPDATE_TIME_SLOT;
    //console.log(data);
    axios.post(link, data).then(resp => {
      clevertap.event.push("Appointment Rescheduled", {
        Source: this.props.form,
        Category: this.props.prescriptionData.category_label,
        Appointments: this.props.id,
        "Call Type":
          this.props.prescriptionData?.mode === "bw_video"
            ? "Video call"
            : "Voice Call",
        Brand: this.props.brand,
        Language: this.props.language
      });
      this.props.handleSlotUpdate(data);
      this.hideScheduler(false);
    });
  };

  handleReschedule = e => {
    e.preventDefault();
    if (
      !this.state.slots_obj ||
      this.state.slotee === "Select Slot" ||
      !this.state.exactSlot
    )
      return;
    let data = {
      doctorId: store.getState().userReducer.hash_id,
      selected_doctor_id_for_reschedule: this.props.doctorID,
      slot: this.state.exactSlot,
      app: this.props.app,
      paid: this.props.paid,
      language: this.props.language,
      appointment_date: this.state.dateee,
      reschedule_by: this.props.isAdmin
        ? getLocalConfig(this.props.brand)?.RESCHEDULE_BY?.ADMIN
        : getLocalConfig(this.props.brand)?.RESCHEDULE_BY?.DOCTOR,
      form: this.props.source,
      id: this.props.userId
    };
    this.setState({ rescheduleStatus: "sending" });
    axios
      .post(getLocalConfig(this.props.brand)?.RESCHEDULE_APPOINTMENT, data)
      .then(res => {
        if (res.data.body.Slot_Full) {
          this.setState({
            slotee: "Select Slot",
            rescheduleStatus: "none",
            errorMessage: "Slot Full! Please select another time."
          });
          this.fetchSlotsAvailable();
          return;
        } else if (res.statusCode == 401) {
          console.log("--error-res.body-", res.body);
          this.setState({
            rescheduleStatus: "failed",
            errorMessage: res.body
          });
        } else {
          this.setState({ rescheduleStatus: "done" });
          toast.success("Appointment Successfully Rescheduled!!!");
          addToGTM({
            event: GTM_EVENTS?.APPOINTMENT_RESCHEDULED
          });
          this.props.closeScheduler();
        }
        console.log(res);
      })
      .catch(err => {
        this.setState({ rescheduleStatus: "failed" });
        console.log(err);
      });
  };
  handleSMS = e => {
    //let data = this.getDateData();
    let data = {
      doctorId: store.getState().userReducer.hash_id,
      form: this.props.type.value || this.props.form,
      id: this.props.userId,
      minsInterval: 30
    };
    let link = CONFIG_CONSTANTS.url.SEND_RESCHEDULR_MSG;
    this.setState({ smsStatus: "sending" });
    axios.post(link, data).then(resp => {
      addToGTM({
        event: GTM_EVENTS?.RESCHEDULE_MESSAGE_SENT
      });
      clevertap.event.push("Reschedule Request Sent", {
        Source: this.props.form,
        Category: this.props.prescriptionData.category_label,
        Appointments: this.props.id,
        "Call Type":
          this.props.prescriptionData?.mode === "bw_video"
            ? "Video call"
            : "Voice Call",
        Brand: this.props.brand,
        Language: this.props.language
      });
      this.setState({ smsStatus: "sent" });
      setTimeout(() => {
        this.hideScheduler(false);
        this.setState({ smsStatus: "" });
      }, 3000);
    });
  };

  customDateInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <span className="cal-out-datetime" data-test="scheduler-container">
        {this.state.selectedDate
          ? UtilityService.formatDateforDisplay(this.state.selectedDate)
          : "Select an appointment slot =>"}
      </span>
      <Button
        variant="outline-secondary"
        onClick={onClick}
        className="calendar-btn"
      >
        <FontAwesomeIcon icon={faCalendarDay} />
      </Button>
    </>
  ));

  render() {
    return (
      <>
        {!this.props.hideRescheduleOption && (
          <>
            {this.state.lookingForSlots ? (
              <div>Looking for slots. Wait!</div>
            ) : this.state.slots_obj ? (
              <div className="set-schedule-box">
                {/* <div className="title">Date & Time</div> */}
                <div style={{ marginTop: "-40px" }}>
                  <Form>
                    <Form.Group controlId="formGridState">
                      <Form.Label>Appointment Date</Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue="Select Date"
                        value={this.state.dateee}
                        name="date"
                        onChange={e =>
                          this.setState({
                            dateee: e.target.value,
                            slotee: "Select Slot",
                            exactSlot: null,
                            errorMessage: ""
                          })
                        }
                      >
                        <option>Select Date</option>
                        {this.state.date_rangeee.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formGridState">
                      <Form.Label>Select Slot</Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue=""
                        value={this.state.slotee}
                        name="slot"
                        onChange={e => {
                          const slotee = e.target.value;
                          const exactSlot = this.state.slots_obj[
                            this.state.dateee
                          ][slotee].length
                            ? this.state.slots_obj[this.state.dateee][slotee][0]
                            : null;
                          this.setState({
                            slotee,
                            exactSlot,
                            errorMessage: ""
                          });
                        }}
                      >
                        <option>Select Slot</option>
                        {this.state.dateee !== "Select Date" &&
                          this.state.slots_obj[this.state.dateee] &&
                          Object.keys(this.state.slots_obj[this.state.dateee])
                            .length &&
                          // this.state.slots_obj[this.state.dateee].length &&
                          Object.keys(
                            this.state.slots_obj[this.state.dateee]
                          ).map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                  {this.state.slotee !== "Select Slot" ? (
                    <div>
                      {this.state.slots_obj[this.state.dateee][
                        this.state.slotee
                      ].map(es => {
                        return (
                          <div
                            onClick={() => this.setState({ exactSlot: es })}
                            style={{
                              padding: "4px 8px",
                              margin: "4px 0px",
                              width: "max-content"
                            }}
                            className={
                              this.state.exactSlot === es
                                ? `brand-filter-button brand-filter-all active`
                                : `brand-filter-button brand-filter-all`
                            }
                          >
                            {es}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                <div
                  className="ssb--control-box"
                  style={{ textAlign: "center" }}
                >
                  {/* <DatePicker
              selected={this.state.selectedDate}
              onChange={date => this.setSelectedDate(date)}
              showTimeSelect
              minTime={this.setHours(this.setMinutes(new Date(), 0), 10)}
              maxTime={this.setHours(this.setMinutes(new Date(), 30), 18)}
              minDate={new Date()}
              maxDate={this.addDays(new Date(), 12)}
              dateFormat="MMMM d, yyyy h:mm aa"
              className="cal-inp"
              calendarClassName="cal-box"
              placeholderText="Click to select a slot"
              customInput={<this.customDateInput />}
            /> */}
                  {this.state.errorMessage ? (
                    <div style={{ color: "red" }}>
                      {this.state.errorMessage}
                    </div>
                  ) : null}
                  <Button
                    className="btn btn-update"
                    variant="primary"
                    onClick={this.handleReschedule}
                    disabled={
                      !this.state.slots_obj ||
                      this.state.slotee === "Select Slot" ||
                      !this.state.exactSlot
                    }
                    style={{ width: "100%", margin: "5px 0" }}
                  >
                    <div className="loader-cm">
                      {this.state.rescheduleStatus === "sending" ? (
                        <>
                          <span>Updating</span>{" "}
                          <div className="Loader-box">
                            <Loader
                              type="TailSpin"
                              color="#00BFFF"
                              height={30}
                              width={30}
                            />
                          </div>
                        </>
                      ) : (
                        <div>
                          {this.state.rescheduleStatus === "failed"
                            ? "Something Went Wrong"
                            : this.state.rescheduleStatus === "done"
                            ? "Updated"
                            : "Update"}
                        </div>
                      )}
                    </div>
                  </Button>
                </div>
              </div>
            ) : (
              <div>Sorry no slots available in next 15 days!</div>
            )}
          </>
        )}
        {dataResource.RESCHEDULE_SUPPORTED_BRAND.includes(this.props.brand) ? (
          <>
            <div style={{ textAlign: "center" }}>OR </div>
            <div className="sib--send-sms-box">
              <Button
                className="btn btn-update"
                variant="white"
                onClick={e => this.handleSMS()}
                style={{
                  width: "100%",
                  margin: "5px 0",
                  border: "1px solid blue"
                }}
              >
                <div className="loader-cm">
                  {this.state.smsStatus === "sending" ? (
                    <>
                      <span>Sending Message</span>{" "}
                      <div className="Loader-box">
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={30}
                          width={30}
                        />
                      </div>
                    </>
                  ) : (
                    "Send Message to reschedule"
                  )}
                </div>
              </Button>
              {this.state.smsStatus === "sent" && <span>Message sent!</span>}
            </div>
          </>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    form: store.getState().rxWriterReducer.form,
    id: store.getState().rxWriterReducer.id,
    brand: ownProps?.brand || store.getState().rxWriterReducer.brand,
    prescriptionData: store.getState().rxWriterReducer.prescriptionData,
    language: ownProps?.language || store.getState().rxWriterReducer.language,
    anyDoctor: ownProps?.anyDoctor
  };
};
export default connect(mapStateToProps)(Scheduler);

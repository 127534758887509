const recommendedProducts = (state = [], action) => {
  const { type, payload } = action;
  let temp, index, arr;
  switch (type) {
    case "UPDATE_RECOMMENDED_PRODUCT":
      return [...payload];
    case "ADD_RECOMMENDED_PRODUCT":
      return [
        ...state,
        {
          ...payload,
          edit: action.edit === false ? action.edit : true,
          cancelDelete: action.cancelDelete
        }
      ];
    case "DELETE_RECOMMENDED_PRODUCT":
      temp = [...state];
      index = payload;
      temp = temp.filter((val, i) => i !== index);
      return [...temp];
    case "COMPLETELY__ADD_RECOMMENDED_PRODUCT":
      index = payload;
      arr = [...state];
      arr[index] = { ...arr[index], edit: false, cancelDelete: false };
      return [...arr];
    case "EDIT_RECOMMENDED_PRODUCT":
      index = payload;
      arr = [...state];
      arr[index] = { ...arr[index], edit: true };
      return [...arr];
    case "CHANGE_RECOMMENDED_DATA":
      index = payload.index;
      const { newData } = payload;
      const NewArr = [...state];
      NewArr[index] = { ...newData, edit: true };
      return [...NewArr];
    default:
      return [...state];
  }
};
export default recommendedProducts;

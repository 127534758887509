import React from "react";
import "./RecommendedTag.scss";
import { AiOutlineClose } from "react-icons/ai";

export default class RecommendedTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={`product-tag ${this.props.type}`}>
        <div className="product-name">{this.props.productName}</div>
        <AiOutlineClose
          onClick={() =>
            this.props.handleDelete(
              this.props.type === "recommended",
              this.props.product.id
            )
          }
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  }
}

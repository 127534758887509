import React, { useState, useEffect } from "react";
import "./styles.scss";
import RxWriterInputBox from "../../../common/RxWriterInputBox/RxwriterInputBox";
import { useSelector } from "react-redux";
import { store } from "../../../../../reducers/Persist";

const Diagnosis = () => {
  const diagnosisCheck = useSelector(
    state => state.observationCheckReducer.diagnosis
  );
  const brand = useSelector(state => state.rxWriterReducer.brand);
  const apptId = useSelector(state => state.rxWriterReducer.id);
  const presetData = useSelector(state => state.rxWriterReducer.presetData);
  const diagnosisObservation = useSelector(
    state => state.observationReducer[apptId].diagnosis
  );
  const [presetDiagnosisData, setPresetDiagnosisData] = useState([]);
  useEffect(() => {
    let arrData = [];
    for (const i in presetData) {
      if (
        presetData[i].observation_type === "diagnosis" &&
        brand === presetData[i].brand
      ) {
        arrData.push(presetData[i]);
      }
    }
    setPresetDiagnosisData(arrData);
  }, [presetData, brand]);

  const handlePresetSelect = (value, index) => {
    store.dispatch({
      type: "SET_ARRAY_OBJECT_CHECK",
      payload: {
        category: "diagnosis",
        index: index,
        value: false,
        key: "text"
      }
    });
    store.dispatch({
      type: "SET_DIAGNOSIS_DATA",
      payload: {
        value: value,
        index: index,
        key: "pretext"
      }
    });
  };
  const handleDelete = e => {
    e.preventDefault();
    store.dispatch({ type: "DELETE_DIAGNOSIS_CHECK" });
    store.dispatch({ type: "DELETE_DIAGNOSIS" });
  };
  const handleAdd = e => {
    e.preventDefault();
    store.dispatch({ type: "ADD_DIAGNOSIS_CHECK" });
    store.dispatch({ type: "ADD_DIAGNOSIS" });
  };

  return (
    <div className="diagnosis">
      <div style={{ display: "flex" }}>
        <p className="diagnosis_title" style={{ display: "flex" }}>
          Diagnosis
          <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        </p>
      </div>
      {diagnosisObservation.map((val, index) => (
        <div className="diagnosis_card" key={index}>
          <RxWriterInputBox
            isDiagnosis={true}
            isRequired={true}
            mainPresetData={presetDiagnosisData}
            onChangeInput={text => {
              store.dispatch({
                type: "SET_ARRAY_OBJECT_CHECK",
                payload: {
                  category: "diagnosis",
                  index: index,
                  value: false,
                  key: "text"
                }
              });
              store.dispatch({
                type: "SET_DIAGNOSIS_DATA",
                payload: { key: "text", value: text, index: index }
              });
            }}
            presetValue={val.pretext}
            typeVal={val.metadata?.type || ""}
            textValue={val.text}
            inputPlaceHolder="Click to start typing"
            inputBoxHeight={100}
            typeAlert={diagnosisCheck[index].type}
            alert={diagnosisCheck[index].text}
            onPresetSelect={value => {
              handlePresetSelect(value, index);
            }}
            changeDiagnosis={value => {
              store.dispatch({
                type: "SET_ARRAY_OBJECT_CHECK",
                payload: {
                  category: "diagnosis",
                  index: index,
                  value: false,
                  key: "type"
                }
              });
              store.dispatch({
                type: "SET_DIAGNOSIS_TYPE",
                payload: { value: value, index: index }
              });
            }}
          />
          {index > 0 ? (
            <button onClick={handleDelete} className="diagnosis_deleteBtn">
              Delete Diagnosis
            </button>
          ) : (
            <></>
          )}
        </div>
      ))}
      <div className="diagnosis_bottom">
        <button onClick={handleAdd} className="diagnosis_addBtn">
          Add Diagnosis +
        </button>
      </div>
    </div>
  );
};

export default Diagnosis;

const CONSTANTS = {
  SECTIONS: {
    TOTAL: {
      title: "Total",
      color: "#22548A"
    },
    INSTANT: {
      title: "Instant",
      color: "#379F8E",
      icon: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1652381285/dochub/rx-writer/Vector_10.png"
    },
    SCHEDULED: {
      title: "Scheduled",
      color: "#5288B9",
      icon: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1652445210/dochub/rx-writer/Group_2.png"
    },
    LSD: {
      title: "Scheduled (Repeat)",
      color: "#5288B9",
      icon: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1652445210/dochub/rx-writer/Group_2.png"
    },
    PENDING: {
      title: "Pending",
      color: "#E86669",
      icon: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1652445210/dochub/rx-writer/Vector_11.png"
    },
    ACTIVITY_LOG: {
      title: "Activity Logs",
      color: "#379F8E",
      icon: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1652381285/dochub/rx-writer/Vector_10.png"
    }
  },
  BRANDS: [
    { label: "All", value: "all" },
    { label: "Man Matters", value: "MM" },
    { label: "Bodywise", value: "BW" },
    { label: "Little Joys", value: "LJ" }
  ],
  DEFAULT_BRAND_VALUE: "all",
  VARIANTS: {
    SUCCESS: "success",
    DANGER: "danger",
    WARNING: "warning"
  },
  SECIION_TYPES: {
    IA: "IA",
    S: "S",
    LSD: "LSD",
    ALL: "ALL"
  },
  DATE_FORMATS: {
    DEFAULT_DATE_FORMAT: "YYYY-MM-DD",
    DAY_OF_MONTH_DATETIME_FORMAT: "Do MMM, YYYY h:mm A"
  },
  NODATA_MESSAGE: "No Data available!"
};
export default CONSTANTS;

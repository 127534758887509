import React, { Component } from "react";
import Scheduler from "./../Scheduler/Scheduler";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import ImageCarousel from "./../../common/ImageCarousel/ImageCarousel";
import Loader from "react-loader-spinner";
import NumPad from "react-numpad";
import Timer from "react-compound-timer";
import CallIcon from "@material-ui/icons/Call";
import VideocamIcon from "@material-ui/icons/Videocam";
import AgoraVCFrame from "../AgoraVCFrame/AgoraVCFrame";
import RefreshIcon from "@material-ui/icons/Refresh";
import qs from "qs";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { Image } from "react-bootstrap";
import dataResource from "../../../constants/dataResource";
import CONFIG_CONSTANTS from "../../../constants/apiList";
//import ImageCarousel from './../../common'
import "./PatientInfo.scss";
import Gallery from "react-grid-gallery";
import { store } from "../../../reducers/Persist";
import History from "../History/History";
import PatientHistory from "../PatientHistory/PatientHistory";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";
import {
  BsArrowDown,
  BsArrowRight,
  BsBoxArrowInDown,
  BsFileArrowDown
} from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
let loader_flag = 0;
var timer2;
const imgUrls = [
  "https://images.unsplash.com/photo-1581498692102-eae0b781e672?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1490&q=80",
  "https://images.unsplash.com/photo-1600221286082-2e33f87bdccf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80",
  "https://images.unsplash.com/photo-1581498692102-eae0b781e672?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1490&q=80",
  "https://images.unsplash.com/photo-1600221286082-2e33f87bdccf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
];
let jsonEscape = str => {
  return str
    .replace(/\n/g, ". ")
    .replace(/\r/g, "\\\\r")
    .replace(/\t/g, "\\\\t");
};

const CustomToggle = ({ eventKey, showHistory, showing, closeHistory }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    let el = document.getElementById("toggleCustom");
    if (el.innerText === "V") showHistory();
    else closeHistory();
  });

  return (
    <div
      onClick={decoratedOnClick}
      style={{ fontWeight: "bold", float: "right", cursor: "pointer" }}
      id="toggleCustom"
    >
      {!showing ? "V" : "Λ"}
    </div>
  );
};
export default class PatientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImagesLoader: false,
      callStatus: "queued",
      s_id: "",
      currentTimer: 0,
      showTimer: false,
      showError: false,
      seconds: 0,
      minutes: 0,
      requestImages: 0,
      userID: "",
      imageArray: [],
      info: this.props.userInfo,
      handleParentState: this.props.handleParentState,
      gender: this.props.brand == "BW" ? "Female" : "Male",
      isSchedulerActive: false,
      showEditModal: false,
      showAssignModal: false,
      showRescheduleModal: false,
      hairLineImage: this.props.userInfo.data.hairline_img
        ? this.props.userInfo.data.hairline_img
        : this.props.userInfo.data.form_details &&
          this.props.userInfo.data.form_details.Hair
        ? this.props.userInfo.data.form_details.Hair.hairLineImg
        : null,
      crownImage: this.props.userInfo.data.crown_img
        ? this.props.userInfo.data.crown_img
        : this.props.userInfo.data.form_details &&
          this.props.userInfo.data.form_details.Hair
        ? this.props.userInfo.data.form_details.Hair.crownImg
        : null,
      showVCFrame: false,
      language: ""
    };
    // debugger;
    this.sendFcmNotification = async () => {
      const sendFcmNotificationUrl = CONFIG_CONSTANTS.url.SEND_FCM_NOTIFICATION;
      const sendFcmNotificationRequestData = {
        brand: this.props.brand,
        userId: this.props.customer_id,
        notification: {
          title: "Start Consultation",
          body: `${this.state.info.data.doctor_assigned} has joined the consultation call.`,
          click_action: `https://manmatters.app.link/joinCall?scheduleToken=${this.props.schedule_token}`,
          doctor_name: this.state.info.data.doctor_assigned,
          doctor_image: this.state.info.data.doctor_img,
          ringer: "true"
        }
      };
      await axios
        .post(sendFcmNotificationUrl, sendFcmNotificationRequestData)
        .then(res => {
          console.log("SEND FCM RES", res);
        })
        .catch(err => {
          console.log("ERROR IN /sendFcmNotification", err);
        });
    };
  }

  // fetchAPIData=()=>{
  //     fetch(CONFIG_CONSTANTS.url.PRESCRIPTION_GET_RX_IMAGES,{schedule_id:Number(this.props.userId)})
  //     .then(res => {
  //             if(res.statusCode == 200){
  //                 // Process the response and update the view.
  //                 // Recreate a setTimeout API call which will be fired after 1 second.
  //                 console.log(res)
  //                 let apiTimeout = setTimeout(fetchAPIData, 1000);
  //             }else{
  //                 clearTimeout(apiTimeout);
  //                 // Failure case. If required, alert the user.
  //             }
  //     })
  //     .fail(function(){
  //          clearTimeout(apiTimeout);
  //          // Failure case. If required, alert the user.
  //     });
  // }
  componentWillReceiveProps(nextProps) {
    if (this.props.userInfo !== nextProps.userInfo) {
      this.setState({ info: nextProps.userInfo });
      //console.log(this.state.info);
    }
  }
  printHello = () => {
    console.log("heeelloooooo!!!!!*********");
    if (this.state.s_id != "") {
      const data = {
        callSid: this.state.s_id
      };
      let link = CONFIG_CONSTANTS.url.EXOTEL_CALL_DETAILS_URL;
      // let link = `https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/exotel`;
      axios.post(link, data).then(resp => {
        console.log("line 44 printhello");
        console.log(resp.data);
        this.setState({ callStatus: resp.data.results.Call.Status });
        if (resp.data.results.Call.Status != "in-progress") {
          this.setState({ showTimer: false });
          clearInterval(timer2);
        }
      });
    }
  };
  componentDidMount() {
    if (this.props.loggedInDr.doctor_id != this.props.userInfo.data.doctor_id)
      this.setState({ showAssignModal: true });
    else {
      this.setState({ showAssignModal: false });
    }
    // if(!this.state.showTimer){
    //   clearInterval(this.timer);
    // }
    this.getRxImages();
    this.setState({
      language: this.props.userInfo.data.language || ""
    });
  }

  getRxImages = () => {
    console.log("---getRxImages---");
    this.setState({ showImagesLoader: true });
    let data = {
      schedule_id: Number(this.props.userId),
      brand: this.props.brand
    };
    axios
      .post(CONFIG_CONSTANTS.url.PRESCRIPTION_GET_RX_IMAGES, data)
      .then(resp => {
        this.setState({ showImagesLoader: false });
        if (resp.data.imageFound == 1) {
          let arr = [];
          resp.data.signedUrl.map(url => {
            arr.push({
              src: url,
              thumbnail: url,
              thumbnailWidth: 320,
              thumbnailHeight: 174
            });
          });
          this.setState({
            imageArray: arr
          });
        }
      })
      .catch(error => {
        this.setState({ showImagesLoader: false });
        console.log("Error getting rx images", error);
      });
  };

  renderNewQna = () => {
    console.log("NEW************");
    let newData = this.state.info.data;
    let data = {
      Hair: newData.form_details.Hair,
      Weight: newData.form_details.Weight,
      Skin: newData.form_details.Skin,
      Performance: newData.form_details.Performance,
      Fitness: newData.form_details.Fitness,
      Nutrition: newData.form_details.Nutrition,
      Stress: newData.form_details.Stress,
      Sleep: newData.form_details.Sleep,
      Lifestyle: newData.form_details.Lifestyle,
      Wellness: newData.form_details.Wellness,
      Health: newData.form_details.Health
    };
    let renderView = [];
    for (let [key, value] of Object.entries(data)) {
      if (value && Object.keys(value).length > 0) {
        let qnaHead = (
          <div className="qna--single-box">
            <p>
              <u>
                <b>{key}</b>
              </u>
            </p>
          </div>
        );
        renderView.push(qnaHead);

        for (let [key2, value2] of Object.entries(value)) {
          if (Object.keys(value).length > 0) {
            let imageFlag = !(key2 === "crownImg" || key2 === "hairLineImg");
            let qnaView = (
              <div className="qna--single-box">
                <div className="qna--question">{key2}</div>
                <div className="qna--answer">
                  <span className="ans">A : </span> {value2}
                </div>
              </div>
            );
            if (imageFlag) {
              renderView.push(qnaView);
            }
          }
        }
      }
    }
    return renderView;
  };

  renderFUquestions = () => {
    let renderView = [];
    if (!this.state.info.data.FU_ques) {
      return renderView;
    }
    var jsonFU = JSON.parse(jsonEscape(this.state.info.data.FU_ques));
    console.log(jsonFU);
    for (let [key, value] of Object.entries(jsonFU)) {
      if (!value) {
        continue;
      }
      let qnaView = (
        <div className="qna--single-box">
          <div className="qna--question">
            Q :
            {dataResource.FU_Questions[key]
              ? dataResource.FU_Questions[key]
              : key}
          </div>
          <div className="qna--answer">
            <span className="ans">A : </span> {value}
          </div>
        </div>
      );
      renderView.push(qnaView);
    }
    return renderView;
  };
  renderQna = () => {
    console.log("OLD***********");
    let renderView = [];
    for (let [key, value] of Object.entries(this.state.info.data.qna)) {
      let qnaView = (
        <div className="qna--single-box">
          <div className="qna--question">{key}</div>
          <div className="qna--answer">
            <span className="ans">A : </span> {value}
          </div>
        </div>
      );
      renderView.push(qnaView);
    }
    return renderView;
  };

  handleNameChange = name => {
    let temp = this.state.info;
    temp.data.name = name;
    this.setState({ info: temp });
  };

  handleAgeChange = age => {
    let temp = this.state.info;
    temp.data.age = age;
    this.setState({ info: temp });
  };

  handleGenderChange = gender => {
    this.setState({
      gender: gender || (this.props.brand == "BW" ? "Female" : "Male")
    });
    let temp = this.state.info;
    temp.data["gender"] = gender;
    this.setState({ info: temp });
  };

  showScheduler = value => {
    this.setState({ isSchedulerActive: value });
  };
  makeCall = value => {
    this.setState({ showTimer: true });
    console.log("Phone number to call is " + value);
    const data = {
      doctor_id: this.props.loggedInDr.doctor_id,
      schedule_id: this.props.userId,
      patient_number: this.state.info.data.phone,
      brand: this.props.brand
    };
    let link = CONFIG_CONSTANTS.url.EXOTEL_C2C_URL;
    // let link = `https://2i32fj62uk.execute-api.ap-south-1.amazonaws.com/staging/exotel`;
    axios.post(link, data).then(resp => {
      console.log(resp.data);
      this.setState({ s_id: resp.data.body.data.Sid });
      console.log("242");
      if (resp.data.statusCode == 429) {
        toast.error(resp.data.body.data);
        this.setState({ showError: true, showTimer: false });
      } else if (resp.data.statusCode != 200) {
        this.setState({ showError: true });
      } else {
        timer2 = window.setInterval(() => this.printHello(), 6000);
      }
    });
  };

  sendUploadLink = value => {
    loader_flag = 1;
    console.log(value);
    console.log(this.props);
    this.setState({
      requestImages: 1,
      userID: this.props.userId
    });
    console.log(this.state.requestImages);
    console.log("this.state.requestImages");

    let link = CONFIG_CONSTANTS.url.PRESCRIPTION_IMAGE_UPLOAD_URL;
    console.log(link);
    axios.post(link, value).then(resp => {
      console.log(resp);
    });

    var j = Number(this.props.userId);
    var arr = this.state.imageArray;
    var timer = setInterval(() => {
      console.log("this.state.requestImages");
      console.log(this.state.requestImages);
      console.log("this.state.imageArray");
      console.log(this.state.imageArray);

      console.log("param");
      //console.log(this.state);
      axios
        .post(CONFIG_CONSTANTS.url.PRESCRIPTION_GET_RX_IMAGES, {
          schedule_id: Number(this.props.userId),
          brand: this.props.brand
        })
        .then(resp => {
          if (resp.data.imageFound == 1) {
            console.log(resp.data.signedUrl);
            arr = resp.data.signedUrl;
            if (resp.data.signedUrl.length !== this.state.imageArray.length) {
              let arr = [];
              resp.data.signedUrl.map(url => {
                arr.push({
                  src: url,
                  thumbnail: url,
                  thumbnailWidth: 320,
                  thumbnailHeight: 174
                });
              });
              this.setState({
                imageArray: arr,
                requestImages: 0
              });
            }
          }
        });
      // if (this.state.imageArray.length > 0) clearInterval(timer);
      //this.setState({requestImages:0})
      console.log("post-interval"); //this will still run after clearing
      // this.setState({
      //   imageArray: arr,
      //   requestImages: 0
      // });
    }, 5000);
    //this.setState({timer:timer_temp})
  };

  handleSlotUpdate = data => {
    //this.state.info.data.appointment_date
    let tempObj = { ...this.state.info };
    tempObj.data.appointment_date = data.appointment_date;
    tempObj.data.appointment_slot =
      data.slot_start_time + " - " + data.slot_end_time;
    this.setState({ info: tempObj });
  };

  formatSlotDate = dateStr => {
    let date = new Date(dateStr);
    var options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  formatSlotTime = timeStr => {
    let tmSplit = timeStr.split("-");
    let timeArr1 = tmSplit[0].split(":");
    let slotStart = new Date().setHours(timeArr1[0], timeArr1[1], 0);
    let timeArr2 = tmSplit[1].split(":");
    let slotEnd = new Date().setHours(timeArr2[0], timeArr2[1], 0);
    return (
      new Date(slotStart).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit"
      }) +
      " - " +
      new Date(slotEnd).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit"
      })
    );
  };
  onUpdateLanguage = async evt => {
    const updateLanguageRequestData = {
      appointmentId: this.props.userId,
      language: this.state.language
    };
    await axios
      .post(CONFIG_CONSTANTS.url.UPDATE_LANGUAGE, updateLanguageRequestData)
      .then(res => {
        console.log("UPDATE LANGUAGE RES", res);
        toast.success(res.data.message, { hideProgressBar: true });
      })
      .catch(err => {
        toast.error("Unable to update Language. Try again Later", {
          hideProgressBar: true
        });
        console.log("Error in sending req to /updateLanguage", err);
      });
  };
  render() {
    return (
      <div className="infobox">
        <section className="patient-info-box">
          <div className="pib--name">
            <h3
              style={{ color: "black", marginLeft: "3px" }}
              data-letters={this.state.info.data.name.substring(0, 1)}
            >
              {this.state.info.data.name}
              <FontAwesomeIcon
                icon={faEdit}
                style={{ float: "right", fontSize: "23px", cursor: "pointer" }}
                onClick={() => this.setState({ showEditModal: true })}
              />
            </h3>
            <Modal
              show={this.state.showEditModal}
              onHide={() => this.setState({ showEditModal: false })}
              centered
              dialogClassName="modal-90w"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4 className="sectionHeading">Edit Details</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ margin: "0" }}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={this.state.info.data.name}
                      onChange={e => {
                        this.state.handleParentState({
                          ...this.state.info,
                          data: {
                            ...this.state.info.data,
                            name: e.target.value
                          }
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Age</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Age"
                      value={this.state.info.data.age}
                      onChange={e => {
                        this.state.handleParentState({
                          ...this.state.info,
                          data: {
                            ...this.state.info.data,
                            age: e.target.value
                          }
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      as="select"
                      value={this.state.info.data.gender}
                      onChange={e => {
                        this.state.handleParentState({
                          ...this.state.info,
                          data: {
                            ...this.state.info.data,
                            gender: e.target.value
                          }
                        });
                        this.setState({ gender: e.target.value });
                      }}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Control>
                  </Form.Group>
                </Form>
                <Button
                  variant="primary"
                  onClick={() => {
                    console.log("Call new api to be developed to save");
                    this.setState({ showEditModal: false });
                  }}
                  style={{ width: "100%" }}
                >
                  Done
                </Button>
              </Modal.Body>
            </Modal>
            <div className="subheading">
              <b>Age: </b>
              {this.state.info.data.age
                ? this.state.info.data.age
                : this.state.info.data.form_details &&
                  this.state.info.data.form_details.age
                ? this.state.info.data.form_details.age
                : ""}
            </div>
            <div className="subheading">
              <b>Gender: </b> {this.state.gender}
            </div>
            <div className="subheading">
              <b>ID: </b> {window.location.href.split("prescription/")[1]}
            </div>
          </div>
          <hr />
          <div className="pib--info-sec-box">
            <div className="pib--title">Email</div>
            <div className="pib--value">: {this.state.info.data.email}</div>
          </div>
          {this.state.info.data.appointment_date && (
            <div className="pib--info-sec-box">
              <div className="pib--title">Date</div>
              <div className="pib--value" style={{ width: "142px" }}>
                : {this.formatSlotDate(this.state.info.data.appointment_date)}
              </div>
            </div>
          )}
          <div className="pib--info-sec-box">
            <div className="pib--title">Phone</div>
            <div className="pib--value" style={{ width: "auto" }}>
              :
              <NumPad.Number
                onChange={value => {
                  let temp = this.state.info;
                  temp.data.phone = value;
                  this.setState({ info: temp }, () => {
                    console.log("after setstate");
                    console.log(value);
                    this.makeCall(value);
                  });
                  console.log("hello");
                  console.log("value", value);
                }}
                placeholder={"Enter Number"}
                value={this.state.info.data.phone}
                decimal={2}
              />
              <span
                className="phone-icon"
                onClick={() => this.makeCall(this.state.info.data.phone)}
                style={
                  this.state.showTimer
                    ? { pointerEvents: "none", opacity: "0.3" }
                    : {}
                }
              >
                <span className="phone-icon2">
                  <CallIcon />
                  CALL
                </span>
                {this.state.showError ? (
                  <span className="error-call">Error calling this number</span>
                ) : null}
              </span>
              <span
                className="phone-icon"
                onClick={() => this.setState({ showVCFrame: false })}
                hidden={
                  !this.props.app_installed && !(this.props.mode === "bw_video")
                }
              >
                <span
                  className="phone-icon2"
                  style={{
                    width: "max-content",
                    padding: "0px 4px",
                    backgroundColor: "rgb(0,150,140)"
                  }}
                >
                  <VideocamIcon />
                  VIDEO CALL
                </span>
                {this.state.showError ? (
                  <span className="error-call">Error calling this number</span>
                ) : null}
              </span>
            </div>
          </div>
          {this.state.showTimer ? (
            <div className="pib--info-sec-box">
              <div className="pib--title">Call Status</div>
              <span className="call-status">
                {this.state.showTimer
                  ? `${this.state.callStatus}       `
                  : "  "}
              </span>
              <span>
                {this.state.showTimer ? (
                  <span className="timer">
                    <Timer initialTime={0}>
                      {({ start, resume, pause, stop, reset, timerState }) => (
                        <React.Fragment>
                          <span>
                            <Timer.Minutes
                              formatValue={value =>
                                (this.state.minutes = value)
                              }
                            />{" "}
                            {this.state.minutes == 0 ? 0 : null} {" min "}
                            <Timer.Seconds
                              formatValue={value =>
                                (this.state.seconds = value)
                              }
                            />
                            {" sec "}
                          </span>
                        </React.Fragment>
                      )}
                    </Timer>
                  </span>
                ) : null}
              </span>
            </div>
          ) : null}

          {this.state.info.data.appointment_date && (
            <div className="pib--info-sec-box">
              <div className="pib--title">Date</div>
              <div className="pib--value">
                : {this.formatSlotDate(this.state.info.data.appointment_date)}
              </div>
            </div>
          )}
          {this.state.info.data.appointment_slot && (
            <div className="pib--info-sec-box">
              <div className="pib--title">Slot</div>
              <div className="pib--value" style={{ width: "142px" }}>
                : {this.formatSlotTime(this.state.info.data.appointment_slot)}
              </div>
            </div>
          )}
          <div className="pib--info-sec-box">
            <div className="pib--title">Doctor</div>
            <div className="pib--value">
              : {this.state.info.data.doctor_assigned}
            </div>
          </div>
          <div className="pib--info-sec-box">
            <div style={{ display: "flex", width: "max-content" }}>
              <div
                className="pib--title"
                style={{ width: "max-content", marginRight: "2px" }}
              >
                Language
              </div>
              <div className="pib--valuek">
                {" "}
                :{" "}
                <select
                  value={this.state.language}
                  onChange={evt =>
                    this.setState({ language: evt.target.value })
                  }
                >
                  <option value="">Select</option>
                  {dataResource.languages.map(language => {
                    return <option value={language}>{language}</option>;
                  })}
                </select>
                <span
                  className="phone-icon2"
                  style={{
                    padding: "1px 5px",
                    fontSize: "12px",
                    width: "max-content",
                    backgroundColor: "#154166"
                  }}
                  onClick={this.onUpdateLanguage}
                >
                  SET
                </span>
              </div>
            </div>
          </div>
          <div className="btn-box">
            <Button
              className="modifyButton"
              variant="primary"
              onClick={() => this.setState({ showRescheduleModal: true })}
            >
              Modify Appointment
            </Button>
            <Modal
              show={this.state.showRescheduleModal}
              onHide={() => this.setState({ showRescheduleModal: false })}
              centered
              dialogClassName="modal-90w"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4 className="sectionHeading">Reschedule Appointment</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Scheduler
                  doctorID={this.props.doctor_id}
                  categories={this.props.categories}
                  email={this.state.info.data.email}
                  phone={this.state.info.data.phone}
                  name={this.state.info.data.name}
                  source={
                    window.location.href.split("prescription/")[1].split("-")[1]
                  }
                  brand={this.props.brand}
                  userId={this.props.userId}
                  type={this.props.type}
                  hideScheduler={this.showScheduler}
                  closeScheduler={() => {
                    this.setState({ showRescheduleModal: false });
                    window.forceReloadWithoutChecking = true;
                    window.location.reload();
                  }}
                  handleSlotUpdate={this.handleSlotUpdate}
                  app={this.props.app}
                />
                {/* <Button variant="primary" onClick={()=>{this.setState({showRescheduleModal: false})}} style={{width:'100%',margin:'5px 0'}}>
                  Update
                </Button>
                <div style={{textAlign:'center'}}>Or</div>
                <Button variant="white" onClick={()=>{this.setState({showRescheduleModal: false})}} style={{width:'100%',margin:'5px 0',border:'1px solid blue'}}>
                  Send Message to Reschedule
                </Button> */}
              </Modal.Body>
            </Modal>
          </div>
          {/* <div style={{ margin: "20px" }}>
            <Accordion>
              <Card>
                <Card.Header>
                  <b>View History</b>
                  <CustomToggle
                    eventKey="0"
                    showHistory={this.props.showHistory}
                    showing={this.props.showingPatientHistory}
                    closeHistory={this.props.closeHistory}
                  ></CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {this.props.showingPatientHistory && (
                      <History
                        prescription_id={this.props.prescriptionID}
                        userInfo={this.props.userInfo}
                        goBack={this.props.closeHistory}
                        id={this.props.userId}
                        category={this.props.category.value}
                        brand={this.props.brand}
                      />
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div> */}
          <div style={{ margin: "20px" }}>
            <PatientHistory
              userInfo={this.props.userInfo}
              brand={this.props.brand}
            />
          </div>
        </section>
        {this.state.isSchedulerActive && (
          <Scheduler
            userId={this.props.userId}
            type={this.props.type}
            hideScheduler={this.showScheduler}
            handleSlotUpdate={this.handleSlotUpdate}
          />
        )}
        {(this.props.type.value === "F" ||
          this.props.type.value === "SH" ||
          this.props.type.value === "HC" ||
          this.props.type.value === "DA" ||
          this.props.type.value === "MA" ||
          this.props.type.value === "FU") && (
          <section className="qna-info-box">
            {this.state.info.data.FU_ques ? (
              <div className="qna">
                <h4 className="sectionHeading">Appointment QnA</h4>
                {this.state.info.data.FU_ques ? this.renderFUquestions() : null}
              </div>
            ) : null}

            <div className="row">
              {/* {this.state.info.data.crown_img?( */}
              {this.state.crownImage ? (
                <div className="imageContainer column">
                  <center>
                    <p>Crown Image</p>
                  </center>
                  <Image src={this.state.crownImage} />
                </div>
              ) : null}

              {this.state.hairLineImage ? (
                <div className="imageContainer column">
                  <center>
                    <p>Hairline Image</p>
                  </center>
                  <Image src={this.state.hairLineImage} />
                </div>
              ) : null}
            </div>
            {this.state.info.data.form_details || this.state.info.data.qna ? (
              <div className="qna">
                <h4>QnA</h4>
                {this.state.info.data.qna
                  ? this.renderQna()
                  : this.renderNewQna()}
              </div>
            ) : null}
          </section>
        )}
        <div className="qna-info-box">
          <h4 className="sectionHeading">
            Images
            <span style={{ float: "right" }}>
              <Button
                className="modifyButton  requestButton"
                variant="primary"
                onClick={() =>
                  this.sendUploadLink({
                    category: this.props.type.value,
                    id: this.props.userId,
                    doctor_hash: store.getState().userReducer.hash_id
                  })
                }
                style={{ margin: "0" }}
              >
                Request Images
              </Button>
              {this.state.showImagesLoader ? (
                <Loader
                  type="TailSpin"
                  color="#027bfe"
                  height={25}
                  width={28}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    marginRight: "10px"
                  }}
                />
              ) : (
                <RefreshIcon
                  onClick={() => this.getRxImages()}
                  style={{
                    color: "#027bfe",
                    fontSize: "28px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    cursor: "pointer"
                  }}
                />
              )}
            </span>
          </h4>
          {this.state.imageArray.length > 0 ? (
            // <ImageCarousel imgUrls={this.state.imageArray} />
            <Gallery
              images={this.state.imageArray}
              enableImageSelection={false}
              // maxRows={1}
            />
          ) : (
            "No Images Sent"
          )}
        </div>
        {this.state.showVCFrame ? (
          <div>
            <AgoraVCFrame
              onClose={() => this.setState({ showVCFrame: false })}
              brand={this.props.brand}
              category={this.props.category}
              userId={this.props.userId}
              schedule_token={this.props.schedule_token}
              sendFcmNotification={this.sendFcmNotification}
              makeCall={this.makeCall}
              phone={this.state.info.data.phone}
            />
          </div>
        ) : null}

        <div className="loadergif">
          {this.state.requestImages == 1 &&
          this.state.imageArray.length == 0 ? (
            // <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
            <Alert variant="success">
              <Alert.Heading>Request SMS sent successfully!</Alert.Heading>
            </Alert>
          ) : null}
        </div>
      </div>
    );
  }
}

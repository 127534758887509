import "./PrescriptionPdf.scss";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { Component } from "react";

import Iframe from "react-iframe";
import Loader from "react-loader-spinner";
import SendPrescription from "../SendPrescription";
import axios from "axios";
import config from "../../../../constants/apiList";
import { connect } from "react-redux";
import moment from "moment";
import { store } from "../../../../reducers/Persist";
import { withStyles } from "@material-ui/core/styles";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";

const CustomColorCheckbox = withStyles({
  root: {
    color: "#379F8E",
    "&$checked": {
      color: "#379F8E"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);
class PrescriptionPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callStatus: "ns",
      prescriptionLoading: false,
      prescriptionUrl: "",
      orderConfirmed: false,
      prescriptionId: null
    };
  }
  replaceSpecialCharacters = str => {
    return str?.toString()?.replace(/'|"|\+/g, " ");
  };
  componentDidMount() {
    this.setState({
      prescriptionLoading: true,
      prescriptionUrl: ""
    });
    let beBodyWiseProductData = store.getState().orderedProducts.bwProductData;

    let temp = {};
    for (let k in beBodyWiseProductData) {
      if (!temp[beBodyWiseProductData[k].name]) {
        temp[beBodyWiseProductData[k].name] = k;
      }
    }
    let addition_Category = [];

    let beBodyWiseProductIds = Object.keys(beBodyWiseProductData);
    let medicines = [];
    if (this.props.orderedProducts.top.length === 0) {
      if (this.props.recommendedProducts.length > 0) {
        for (const product of this.props.recommendedProducts) {
          medicines.push({
            name: this.replaceSpecialCharacters(product.name),
            cart_quantity: this.replaceSpecialCharacters(product.cart_quantity),
            frequency: this.replaceSpecialCharacters(
              product.usage_frequency?.[product.currentFreqIndex || 0]
            ),
            category: this.replaceSpecialCharacters(product.category),
            instruction: this.replaceSpecialCharacters(
              product.usage_instructions?.[product.currentFreqIndex || 0]
            ),
            noOfDays: this.replaceSpecialCharacters(product.days),
            product_id: this.replaceSpecialCharacters(temp[product.name]),
            quantity: this.replaceSpecialCharacters(
              product.prescription_quantity
            ),
            unit: this.replaceSpecialCharacters(
              product.usage_unit?.[product.currentUnitIndex || 0]
            )
          });
          addition_Category.push(
            this.replaceSpecialCharacters(product.category)
          );
        }
        this.state.callStatus = "c";
      }
    } else {
      let orderId = "";
      for (const product of this.props.orderedProducts.top) {
        const productDataFromJson = beBodyWiseProductData[temp[product.name]];
        if (product.order_id) {
          orderId = product.order_id;
        }
        medicines.push({
          name: this.replaceSpecialCharacters(product.name),
          cart_quantity: this.replaceSpecialCharacters(product.cart_quantity),
          frequency: this.replaceSpecialCharacters(
            product.usage_frequency?.[product.currentFreqIndex || 0]
          ),
          category: this.replaceSpecialCharacters(product.category),
          instruction: this.replaceSpecialCharacters(
            product.usage_instructions?.[product.currentFreqIndex || 0]
          ),
          noOfDays: this.replaceSpecialCharacters(product.days),
          product_id: this.replaceSpecialCharacters(temp[product.name]),
          quantity: this.replaceSpecialCharacters(
            productDataFromJson.prescription_quantity
          ),
          unit: this.replaceSpecialCharacters(
            product.usage_unit?.[product.currentUnitIndex || 0]
          ),
          order_id: product.order_id || orderId
        });
        addition_Category.push(this.replaceSpecialCharacters(product.category));
      }
      for (const bottomProductsObj of this.props.orderedProducts.bottom) {
        medicines.push({
          name: this.replaceSpecialCharacters(bottomProductsObj.name),
          cart_quantity: this.replaceSpecialCharacters(
            bottomProductsObj.cart_quantity
          ),
          frequency: this.replaceSpecialCharacters(
            bottomProductsObj.usage_frequency?.[
              bottomProductsObj.currentFreqIndex || 0
            ]
          ),
          category: this.replaceSpecialCharacters(bottomProductsObj.category),
          instruction: this.replaceSpecialCharacters(
            bottomProductsObj.usage_instructions?.[
              bottomProductsObj.currentFreqIndex || 0
            ]
          ),
          noOfDays: this.replaceSpecialCharacters(bottomProductsObj.days),
          product_id: this.replaceSpecialCharacters(
            temp[bottomProductsObj.name]
          ),
          quantity: this.replaceSpecialCharacters(
            bottomProductsObj.prescription_quantity
          ),
          unit: this.replaceSpecialCharacters(
            bottomProductsObj.usage_unit?.[
              bottomProductsObj.currentUnitIndex || 0
            ]
          ),
          order_id: !!bottomProductsObj?.order_id
            ? bottomProductsObj?.order_id
            : null
        });
        addition_Category.push(
          this.replaceSpecialCharacters(bottomProductsObj.category)
        );
      }
    }

    for (const otherProduct of this.props.otherProducts) {
      medicines.push({
        name: this.replaceSpecialCharacters(otherProduct.productName),
        cart_quantity: "0",
        frequency: this.replaceSpecialCharacters(otherProduct.frequency),
        category: "",
        instruction: this.replaceSpecialCharacters(otherProduct.instructions),
        noOfDays: this.replaceSpecialCharacters(otherProduct.days),
        product_id: "new",
        quantity: this.replaceSpecialCharacters(otherProduct.quantity),
        unit: this.replaceSpecialCharacters(otherProduct.quantity)
      });
    }
    let observations = {
      ...this.props.observations,
      concerns: this.props.observations.concerns.map((val, index) => {
        return {
          ...val,
          metadata: {
            category: val.metadata.category,
            severity: val.metadata.severity,
            onSet: moment()
              .subtract(
                parseInt(val.metadata.onsetValue),
                val.metadata.onsetDuration?.toLowerCase()
              )
              .format("YYYY-MM-DD")
          }
        };
        // let month = val.metadata.month,
        //   day = val.metadata.day,
        //   year = val.metadata.year;
        // if (month.length < 2) month = "0" + month;
        // if (day.length < 2) day = "0" + day;
        // while (year.length < 4) year = "0" + year;
        // return {
        //   ...val,
        //   metadata: {
        //     severity: val.metadata.severity,
        //     category: val.metadata.category,
        //     onSet:
        //       val.metadata.year +
        //       "-" +
        //       val.metadata.month +
        //       "-" +
        //       val.metadata.day
        //   }
        // };
      }),
      lifestyleExisting: {
        metadata: {
          ...this.props.observations.lifestyleExisting.metadata,
          habits: []
        }
      },
      doctorNotes: {
        ...this.props.observations.doctorNotes,
        metadata: {
          doctorName: this.props.doctorName
        }
      }
    };
    for (const key in observations) {
      if (!!observations[key] && observations[key].constructor === Array) {
        for (let i = 0; i < observations[key].length; i++) {
          if (observations[key][i].text)
            observations[key][i].text = this.replaceSpecialCharacters(
              observations[key][i].text
            );
        }
      } else if (
        !!observations[key] &&
        observations[key].constructor === Object
      ) {
        if (observations[key].text)
          observations[key].text = this.replaceSpecialCharacters(
            observations[key].text
          );
      }
    }

    for (const val in this.props.observations.lifestyleExisting.metadata
      .habits) {
      if (this.props.observations.lifestyleExisting.metadata.habits[val]) {
        if (val === "Others")
          observations.lifestyleExisting.metadata.habits.push(
            this.props.observations.lifestyleExisting.metadata.habitOther
          );
        else observations.lifestyleExisting.metadata.habits.push(val);
      }
    }
    if (!!this.props.rxData.dummyProduct) {
      medicines.push({
        name: "No Prescribed Product",
        cart_quantity: "-",
        frequency: "-",
        category: "",
        instruction: "Follow instructions given by your doctor",
        noOfDays: "-",
        product_id: "new",
        quantity: "-",
        unit: "-"
      });
    }

    //FIXME: This is a quick fix this should be done at data layer
    let allergySpecifications = observations.allergySpecifications;
    let newAllergySpecification = [];
    allergySpecifications.map(item => {
      let allergySpecification = {};
      allergySpecification.text = item.text;
      allergySpecification.pretext = [...new Set(item.pretext)];
      allergySpecification.metadata = { ...item.metadata };
      newAllergySpecification.push(allergySpecification);
    });
    observations.allergySpecifications = [...newAllergySpecification];
    const payload = {
      brand: this.props.brand,
      doctorId: this.props.doctorId,
      form: this.props.form,
      id: this.props.id,
      medicines: medicines,
      followup: this.props.followup,
      patient: {
        email: this.props.rxData.prescriptionData.email,
        gender: this.props.rxData.patientDetails.gender,
        name: this.props.rxData.patientDetails.name,
        phone: this.props.rxData.patientDetails.phone,
        age: this.props.rxData.patientDetails.age,
        weight: this.props.rxData.patientDetails.weight
      },
      patientMedical: {
        category:
          addition_Category.length > 0 ? addition_Category.join(",") : "other",
        complaints: "",
        diagnosis: "",
        history: "",
        instruction: []
      },
      observations: observations,
      // version for debugging in logs
      version: "1.0"
    };

    if (payload.form === "O") {
      payload.medicines.forEach((elem, index) => {
        if (!elem.order_id || elem.order_id === "") {
          console.error(
            `v2 no order id found for product: ${
              elem.product_id
            } in payload: ${JSON.stringify(payload)}, `
          );
        }
      });
    }

    axios
      .post(config.url.GENERATE_REPORT_URL__BW, payload)
      .then(res => {
        this.setState({
          prescriptionUrl: res.data.body.data.url,
          prescriptionId: res.data.body.data.prescription_id,
          prescriptionLoading: false
        });
        store.dispatch({ type: "PRESCRIPTION_PDF_LOADING_FALSE" });
        store.dispatch({
          type: "CHANGE_LAST_GENERATE_REPORT_PAYLOAD",
          payload: payload
        });
        addToGTM({
          event: GTM_EVENTS?.PRESCRIPTION_GENERATED
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    return (
      <div className="prescription-pdf-container">
        <div className="prescription-pdf-title">Prescription</div>
        {this.state.prescriptionLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader type="TailSpin" />
          </div>
        ) : (
          <div style={{ width: "700px" }}>
            <Iframe
              url={this.state.prescriptionUrl}
              className="prescription-pdf-iframe"
              width="700px"
              height="600px"
              allowtransparency="true"
              display="initial"
            />
            {this.props.form === "O" ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                <FormControlLabel
                  control={
                    <CustomColorCheckbox
                      onClick={() => {
                        this.setState({
                          orderConfirmed: !this.state.orderConfirmed
                        });
                      }}
                      checked={this.state.orderConfirmed}
                      iconStyle={{ color: "#379F8E" }}
                      labelStyle={{ color: "#379F8E" }}
                      value="#379F8E"
                      name="checkedB"
                      className="checkbox"
                    />
                  }
                  label="Mark Order as confirmed"
                />
                <SendPrescription
                  disabled={!this.state.orderConfirmed}
                  prescriptionId={this.state.prescriptionId}
                  orderConfirmed={this.state.orderConfirmed}
                />
              </div>
            ) : (
              <SendPrescription
                prescriptionId={this.state.prescriptionId}
                orderConfirmed={this.state.orderConfirmed}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const id = store.getState().rxWriterReducer.id;
  return {
    brand: store.getState().rxWriterReducer.brand,
    form: store.getState().rxWriterReducer.form,
    id: store.getState().rxWriterReducer.id,
    rxData: store.getState().rxWriterReducer,
    doctorId: store.getState().userReducer.hash_id,
    observations: store.getState().observationReducer[id],
    products: store.getState().prescription_R_Products,
    followup: store.getState().rxWriterReducer.followup,
    orderedProducts: store.getState().orderedProducts,
    recommendedProducts: store.getState().recommendedProducts,
    doctorName: store.getState().rxWriterReducer.patientDetails?.doctor_name,
    otherProducts: store.getState().observationReducer[id].otherProducts
  };
};
export default connect(mapStateToProps)(PrescriptionPdf);

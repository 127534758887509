import Axios from "axios";
import React, { useEffect, useState } from "react";
import MealPlanner1 from "./MealPlanner1";
import MealPlanner2 from "./MealPlanner2";
import MealPlanner3 from "./MealPlanner3";
import "./MealPlanner.css";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import StatusPopUp from "./StatusPopUp";
import { toast } from "react-toastify";
import moment from "moment";
import { GTM_EVENTS } from "../../../constants/tagManager.constants";
import { addToGTM } from "../../../helpers/tagManager.helper";
import clevertap from "clevertap-web-sdk";
const mealType = {
  1: "Early Morning",
  2: "Breakfast",
  3: "Mid Meal",
  4: "Lunch",
  5: "Snacks",
  6: "Dinner",
  7: "Mid Meal 2",
  8: "Snacks 2",
  9: "Pre-Workout",
  10: "Post-Workout",
  11: "Intra-Workout",
  12: "Bed-Time"
};

const mealType2 = {
  "Early Morning": {
    key: 1,
    dafaultTime: "07:00"
  },
  Breakfast: {
    key: 2,
    dafaultTime: "09:00"
  },
  "Mid Meal": {
    key: 3,
    dafaultTime: "11:00"
  },
  Lunch: {
    key: 4,
    dafaultTime: "13:00"
  },
  Snacks: {
    key: 5,
    dafaultTime: "15:00"
  },
  Dinner: {
    key: 6,
    dafaultTime: "20:00"
  },
  "Mid Meal 2": {
    key: 7,
    dafaultTime: "11:00"
  },
  "Snacks 2": {
    key: 8,
    dafaultTime: "15:00"
  },
  "Pre-Workout": {
    key: 9,
    dafaultTime: "15:00"
  },
  "Post-Workout": {
    key: 10,
    dafaultTime: "15:00"
  },
  "Intra-Workout": {
    key: 11,
    dafaultTime: "15:00"
  },
  "Bed-Time": {
    key: 12,
    dafaultTime: "21:00"
  }
};

const table = {
  "Meal Type": [
    "Early Morning",
    "Breakfast",
    "Mid Meal",
    "Lunch",
    "Snacks",
    "Dinner"
  ],
  Monday: [
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[1]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[2]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[3]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[4]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[5]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[6]].dafaultTime
    }
  ],
  Tuesday: [
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[1]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[2]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[3]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[4]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[5]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[6]].dafaultTime
    }
  ],
  Wednesday: [
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[1]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[2]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[3]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[4]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[5]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[6]].dafaultTime
    }
  ],
  Thursday: [
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[1]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[2]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[3]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[4]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[5]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[6]].dafaultTime
    }
  ],
  Friday: [
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[1]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[2]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[3]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[4]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[5]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[6]].dafaultTime
    }
  ],
  Saturday: [
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[1]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[2]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[3]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[4]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[5]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[6]].dafaultTime
    }
  ],
  Sunday: [
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[1]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[2]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[3]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[4]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[5]].dafaultTime
    },
    {
      rows: [{ item: "", portion: "", tips: "" }],
      time: mealType2[mealType[6]].dafaultTime
    }
  ]
};

const dropdown2 = [{ name: "Lemon rice" }, { name: "fried rice" }];

const headerArr = [
  "Meal Type",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

export default function MealPlanner(props) {
  const [page, setPage] = useState(1);
  const [col, setCol] = useState(1);
  const [tableState, setTableState] = useState(table);
  const [patientDetails, setPatientDetails] = useState({});
  const [patientChanges, setPatientChanges] = useState({
    exerciseTips: [],
    exerciseRemarks: [],
    dietTips: [],
    dietRemarks: [],
    medicalTips: [],
    medicalRemarks: [],
    portions: [],
    portionRemarks: []
  });
  const [medicines, setMedicines] = useState([]);
  const [data, setData] = useState([]);
  const [done, setDone] = useState("");
  const [prescriptionId, setPrescriptionId] = useState("");
  useEffect(() => {
    if (done === "sent" || done === "failed") {
      setTimeout(() => {
        setDone();
        setTimeout(() => setPage(1));
      }, 1500);
    }
  }, [done]);

  let updateData = (type, data) => {
    if (type == "page3") {
      setPatientChanges({ ...patientChanges, ...data });
    }
    if (type == "page1") {
      setPatientDetails(data);
    }
  };
  useEffect(() => {
    Axios.post(CONFIG_CONSTANTS.url.GET_FOOD_ITEMS, {})
      .then(res => {
        console.log(res.data.body.data);
        let dataa = res.data.body.data["food_list"];
        dataa.forEach((food, ind) => (dataa[ind] = { name: food }));

        setData([...dataa]);
      })
      .catch(err => console.log(err));
    const getMealPlanDraftRequestData = {
      id: props.userId
    };
    Axios.post(
      CONFIG_CONSTANTS.url.GET_MEAL_PLAN_DRAFT,
      getMealPlanDraftRequestData
    )
      .then(res => {
        if (res.data.body.success) {
          const draft = JSON.parse(res.data.body.result.draft);
          setTableState(draft);
        }
      })
      .catch(err => console.log("GET MEAL DRAFT ERROR", err));
  }, []);
  const [header, setHeader] = useState(headerArr);
  const [statusPopUp, setStatusPopUp] = useState(false);
  const [p, setP] = useState(false);
  const [latestStatusUpdate, setLatestStatusUpdate] = useState(null);

  const getFormattedMealRows = items => {
    return items.map(i => {
      const { item_name: item, portion, tips } = i;
      return { item, portion, tips };
    });
  };

  const updateMealPlanData = data => {
    const tempTable = { ...tableState };
    for (const dayData of data) {
      const day = dayData.day;
      const meals = dayData.meals;
      for (const meal of meals) {
        const time = moment(meal.time, "HH:mm:ss").format("HH:mm");
        let found = false;
        for (const index in tempTable[day]) {
          if (tempTable[day][index].time === time) {
            tempTable[day][index].rows = getFormattedMealRows(meal.items);
            found = true;
            break;
          }
        }
        if (!found) {
          tempTable[day].push({
            time,
            rows: getFormattedMealRows(meal.items)
          });
          !tempTable["Meal Type"].includes(meal.meal_type) &&
            tempTable["Meal Type"].push(meal.meal_type);
        }
      }
    }
    setTableState(tempTable);
  };

  const updatePatientDetails = data => {
    const patientData = data && data.length > 0 && data[0];
    patientData &&
      setPatientDetails({
        ...patientData,
        meal: patientData.meal_per_day,
        avoid: patientData.want_to_avoid,
        plan_duration: patientData.duration,
        plan_goal: patientData.goals
      });
  };

  const updatePatientChanges = data => {
    const tempPatientChanges = {
      exerciseTips: data.exercise_tips || [],
      exerciseRemarks: [],
      dietTips: data.diet_tips || [],
      dietRemarks: [],
      medicalTips: data.medical_tips || [],
      medicalRemarks: [],
      portions: data.portion_tips || [],
      portionRemarks: []
    };
    setPatientChanges(tempPatientChanges);
  };

  const getDietDetails = prescriptionId => {
    const requestData = {
      schedule_token: props.schedule_token,
      prescriptionId
    };
    Axios.post(CONFIG_CONSTANTS.url.GET_DIET_DETAILS, requestData)
      .then(res => {
        const data = res.data.body.data;
        const prescription =
          data &&
          data.prescriptions &&
          data.prescriptions.length > 0 &&
          data.prescriptions[0];
        if (!prescription) return;

        updatePatientDetails(prescription.patient_data);

        updateMealPlanData(prescription.data);

        updatePatientChanges(prescription);
        toast.success("Prescription Copied.", { autoClose: 2200 });
      })
      .catch(err => {
        console.log("ERROR IN get diet details", err);
      });
  };
  useEffect(() => {
    if (p) {
      setStatusPopUp(false);
      setDone("sent");
      setP(false);
    }
  }, [p]);
  const generateMealPlan = () => {
    const mealPlan = { ...tableState };
    return mealPlan;
  };
  const saveDraftClickHandler = evt => {
    const mealPlan = generateMealPlan();
    const saveMealPlanDraftRequestData = {
      mealPlan,
      id: props.userId
    };
    Axios.post(
      CONFIG_CONSTANTS.url.SAVE_MEAL_PLAN_DRAFT,
      saveMealPlanDraftRequestData
    )
      .then(res => {
        toast.success("Draft Saved!");
      })
      .catch(err => {
        console.log("ERROR IN saveMealPlanDraft api", err);
      });
  };
  const onNext = page => {
    if (page === 2) {
      // TRYING TO GO ON PAGE TWO
      if (
        !(patientDetails.age && patientDetails.height && patientDetails.weight)
      ) {
        toast.error("Please Provide Age, Height and Weight");
        return;
      }
    }
    setPage(page);
  };
  return (
    <>
      {/* <div style={{ backgroundColor: '#154166', padding: '5px 10px' }}>
        <div style={{ display: 'flex', backgroundColor: '#154166', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ height: "30px 100px", color: 'white', cursor: 'pointer' }}>Image Here</div>
          <div><input style={{ display: 'flex', border: '2px solid #154166', width: '50vw', padding: '5px', backgroundColor: '#45467E', color: 'white' }} type="search" /></div>
          <div style={{ color: 'white', backgroundColor: '#E57373', padding: '5px 20px', cursor: 'pointer' }}>Logout</div>
        </div>
      </div> */}
      <div style={{ padding: "0 10px 20px 10px", backgroundColor: "#E5E5E5" }}>
        <div style={{ padding: "10px 0" }}>
          <div
            style={{
              fontSize: "30px",
              display: "inline-block",
              width: "33.33%"
            }}
          >
            <b>{props.userInfo.data.name}'s</b> Diet Plan
          </div>
          <div
            style={{
              fontSize: "30px",
              display: "inline-flex",
              alignContent: "center",
              justifyContent: "center",
              width: "33.33%"
            }}
          >
            <div
              onClick={() => setPage(1)}
              style={{
                cursor: "pointer",
                color: "#23C323",
                border: "2px solid #23C323",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              1
            </div>
            <div
              style={{
                border: page > 1 ? "1px solid #23C323" : "1px solid #154166",
                width: "70px",
                margin: "0 5px",
                alignSelf: "center"
              }}
            ></div>
            <div
              onClick={() => setPage(2)}
              style={{
                cursor: "pointer",
                color: page > 1 ? "#23C323" : "#154166",
                border: page > 1 ? "2px solid #23C323" : "2px solid #154166",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              2
            </div>
            <div
              style={{
                border: page > 2 ? "1px solid #23C323" : "1px solid #154166",
                width: "70px",
                margin: "0 5px",
                alignSelf: "center"
              }}
            ></div>
            <div
              onClick={() => setPage(3)}
              style={{
                cursor: "pointer",
                color: page > 2 ? "#23C323" : "#154166",
                border: page > 2 ? "2px solid #23C323" : "2px solid #154166",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              3
            </div>
          </div>
          <div style={{ width: "33.33%", display: "inline-block" }}></div>
        </div>
        {page === 1 && (
          <MealPlanner1
            category={props.category}
            docKey={props.docKey}
            userId={props.userId}
            updateData={updateData}
            patientDetails={patientDetails}
            userInfo={props.userInfo}
            loggedInDr={props.loggedInDr}
            form_details={props.form_details}
            latestStatusUpdate={latestStatusUpdate}
            doctor_id={props.doctor_id}
            brand={props.brand}
            categories={props.categories}
            copyPrescription={getDietDetails}
          />
        )}
        {page === 2 && data && (
          <MealPlanner2
            updateData={updateData}
            form={props.form}
            // userId={props.userId}
            brand={props.brand}
            col={col}
            setCol={setCol}
            tableState={tableState}
            setTableState={setTableState}
            data={data}
            setData={setData}
            header={header}
            setHeader={setHeader}
            form_details={props.form_details}
            updateData={updateData}
            getDetails={props.getDetails}
            productData={props.productData}
            editItem={props.editItem}
            setPrescriptionID={props.setPrescriptionID}
            // ref={instance => {
            //   this.child = instance;
            // }}
            handlePreview={props.handlePreview}
            orderPlaced={props.orderPlaced}
            recommended={props.recommended}
            userInfo={props.userInfo}
            prescriptionsList={props.prescriptionsList}
            deleteElement={props.deleteElement}
            handleMove={props.handleMove}
            editElement={props.editElement}
            complaints={props.complaints}
            history={props.history}
            diagnosis={props.diagnosis}
            instructions={props.instructions}
            description={props.description}
            recommendationChange={props.recommendationChange}
            isEdit={props.isEdit}
            form={props.form}
            userId={props.userId}
            brand={props.brand}
            type_text={"normal_form"}
            prescription_order_ids={props.prescription_order_ids}
          />
        )}
        {page === 3 && (
          <MealPlanner3
            form_details={props.form_details}
            updateData={updateData}
            patientChanges={patientChanges}
            getDetails={props.getDetails}
            productData={props.productData}
            editItem={props.editItem}
            setPrescriptionID={props.setPrescriptionID}
            // ref={instance => {
            //   this.child = instance;
            // }}
            handlePreview={props.handlePreview}
            orderPlaced={props.orderPlaced}
            recommended={props.recommended}
            userInfo={props.userInfo}
            prescriptionsList={props.prescriptionsList}
            deleteElement={props.deleteElement}
            handleMove={props.handleMove}
            editElement={props.editElement}
            complaints={props.complaints}
            history={props.history}
            diagnosis={props.diagnosis}
            instructions={props.instructions}
            description={props.description}
            recommendationChange={props.recommendationChange}
            isEdit={props.isEdit}
            form={props.form}
            userId={props.userId}
            brand={props.brand}
            type_text={"normal_form"}
            prescription_order_ids={props.prescription_order_ids}
          />
        )}
        <div style={{ display: "flex" }}>
          {page > 1 && (
            <div
              className="class1"
              style={{
                cursor: "pointer",
                padding: "10px 70px",
                color: "#154166",
                backgroundColor: "white",
                border: "2px solid #154166"
              }}
              onClick={() => setPage(page - 1)}
            >
              Back
            </div>
          )}
          {page === 2 ? (
            <div
              className="class1"
              style={{
                cursor: "pointer",
                padding: "10px 70px",
                color: "#154166",
                backgroundColor: "white",
                border: "2px solid #154166",
                marginLeft: "5px"
              }}
              onClick={saveDraftClickHandler}
            >
              Save Draft
            </div>
          ) : null}
          {page < 3 && (
            <div
              className="class1"
              style={{
                cursor: "pointer",
                padding: "10px 70px",
                color: "#154166",
                backgroundColor: "white",
                border: "2px solid #154166",
                marginLeft: "auto"
              }}
              onClick={evt => onNext(page + 1)}
            >
              Next
            </div>
          )}
          {done === "sending" && (
            <div className="loader-cm inln-blk">
              <div className="Loader-box">
                <Loader
                  type="TailSpin"
                  color="#00BFFF"
                  height={30}
                  width={30}
                />
              </div>
            </div>
          )}
          {done === "sent" && (
            <div className="loader-cm  inln-blk">
              Sent Meal Plan Successfully!
            </div>
          )}
          {done === "failed" && (
            <div className="loader-cm  inln-blk">
              Some Error Occured while creating Meal Plan!
            </div>
          )}
          {page === 3 && (
            <>
              <div
                onClick={() => {
                  setDone("sending");
                  const mealPlan = { ...tableState };
                  for (let key in mealPlan) {
                    mealPlan[key] = [...tableState[key]];
                    if (key === "Meal Type") continue;
                    for (let i = 0; i < mealPlan[key].length; ++i) {
                      mealPlan[key][i].rows = [...mealPlan[key][i].rows];
                      for (let j = 0; j < mealPlan[key][i].rows.length; ++j) {
                        mealPlan[key][i].rows[j] = {
                          ...mealPlan[key][i].rows[j]
                        };
                      }
                    }
                  }
                  let emptyMealPlan = true;
                  for (let day in mealPlan) {
                    if (day === "Meal Type") continue;

                    for (let key in mealPlan[day]) {
                      mealPlan[day][key].rows = mealPlan[day][key].rows.filter(
                        row => {
                          if (row.item === "") return false;
                          return true;
                        }
                      );
                      if (mealPlan[day][key].rows.length > 0)
                        emptyMealPlan = false;
                    }
                  }
                  if (emptyMealPlan) {
                    toast.error("Diet Plan is Empty. Please check your input.");
                    setDone("");
                    return;
                  }
                  // let data = patientChanges;
                  // console.log(data);
                  // data.diet_tips &&
                  //   data.diet_tips.forEach((item, index) => {
                  //     if (item.value == 0) {
                  //       data.diet_tips.splice(index, 1);
                  //     }
                  //   });
                  // data.medical_tips &&
                  //   data.medical_tips.forEach((item, index) => {
                  //     if (item.value == 0) {
                  //       data.medical_tips.splice(index, 1);
                  //     }
                  //   });
                  // data.exercise_changes &&
                  //   data.exercise_changes.forEach((item, index) => {
                  //     if (item.value == 0) {
                  //       data.exercise_changes.splice(index, 1);
                  //     }
                  //   });
                  // data.portions &&
                  //   data.portions.forEach((item, index) => {
                  //     if (item.value == 0) {
                  //       data.portions.splice(index, 1);
                  //     }
                  //   });
                  // console.log("line 584");
                  const patient_changes = {
                    exerciseTips: [
                      ...patientChanges.exerciseTips,
                      ...patientChanges.exerciseRemarks
                    ],
                    dietTips: [
                      ...patientChanges.dietTips,
                      ...patientChanges.dietRemarks
                    ],
                    medicalTips: [
                      ...patientChanges.medicalTips,
                      ...patientChanges.medicalRemarks
                    ],
                    portions: [
                      ...patientChanges.portions,
                      ...patientChanges.portionRemarks
                    ]
                  };
                  const reqBody = {
                    form: props.form,
                    id: props.userId,
                    brand: props.brand,
                    medicines: props.prescriptionsList,
                    patient_changes,
                    patient_data: patientDetails,
                    mealPlan
                  };

                  Axios.post(CONFIG_CONSTANTS.url.CREATE_MEAL_PLAN, reqBody)
                    .then(res => {
                      addToGTM({
                        event: GTM_EVENTS?.PRESCRIPTION_GENERATED
                      });
                      console.log(res);
                      window.open(res.data.body.cartLink, "_blank");
                      setDone("gotLink");
                      setPrescriptionId(res.data.body.prescriptionId);
                    })
                    .catch(err => {
                      console.log(err);
                      setDone("failed");
                    });
                }}
                className="class1"
                style={{
                  cursor: "pointer",
                  padding: "10px 70px",
                  color: "#154166",
                  backgroundColor: "white",
                  border: "2px solid #154166",
                  marginLeft: "auto"
                }}
              >
                Preview
              </div>
              <div
                onClick={() => {
                  if (done !== "gotLink") return;
                  setDone("sending");
                  Axios.post(CONFIG_CONSTANTS.url.SEND_MEAL_PLAN, {
                    id: props.userId,
                    prescriptionId
                  })
                    .then(res => {
                      console.log(res);
                      setStatusPopUp(true);
                      try {
                        addToGTM({
                          event: GTM_EVENTS?.PRESCRIPTION_SENT,
                          prescriptionSentData: {
                            phone: props?.userInfo?.data?.phone,
                            email: props?.userInfo?.data?.email
                          }
                        });
                        console.log("adding prescritpion");
                        clevertap.event.push("Prescription sent", {
                          Appointments: props?.userId,
                          Source: props?.userInfo?.data?.source,
                          Category: props?.userInfo?.data?.category,
                          "Call Type":
                            props?.userInfo?.data?.mode === "bw_video"
                              ? "Video call"
                              : "Voice Call",
                          Brand: props?.userInfo?.data?.brand,
                          "Doctor ID": props?.loggedInDr?.doctor_id,
                          "Doctor Name": props?.loggedInDr?.doctor_name,
                          Language: props?.userInfo?.data?.language
                        });
                        console.log(
                          `pushed to ct: ${JSON.stringify({
                            Appointments: props?.userId,
                            Source: props?.userInfo?.data?.source,
                            Category: props?.userInfo?.data?.category,
                            "Call Type":
                              props?.userInfo?.data?.mode === "bw_video"
                                ? "Video call"
                                : "Voice Call",
                            Brand: props?.userInfo?.data?.brand,
                            "Doctor ID": props?.loggedInDr?.doctor_id,
                            "Doctor Name": props?.loggedInDr?.doctor_name,
                            Language: props?.userInfo?.data?.language
                          })}`
                        );
                      } catch (err) {
                        console.log("error in sending analytics", err);
                      }
                    })
                    .catch(err => {
                      console.log(err);
                      setDone("failed");
                    });
                }}
                className={done === "gotLink" ? "class1" : ""}
                style={
                  done === "gotLink"
                    ? {
                        cursor: "pointer",
                        padding: "10px 70px",
                        color: "#154166",
                        backgroundColor: "white",
                        border: "2px solid #154166",
                        marginLeft: "15px"
                      }
                    : {
                        padding: "10px 70px",
                        color: "#154166",
                        backgroundColor: "gray",
                        border: "2px solid black",
                        marginLeft: "15px"
                      }
                }
              >
                Send
              </div>
            </>
          )}
        </div>
        <Modal
          show={statusPopUp}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-center"
          centered
          onHide={() => setP(true)}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <StatusPopUp
              form={props.form}
              id={props.userId}
              doctorId={props.docKey}
              setP={setP}
              setLatestStatusUpdate={setLatestStatusUpdate}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

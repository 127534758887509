import React, { useState, useEffect } from "react";
import "./styles.scss";
import RxWriterInputBox from "../../../common/RxWriterInputBox/RxwriterInputBox";
import { store } from "../../../../../reducers/Persist";
import { useSelector } from "react-redux";
const Allergies = () => {
  const allergiesOption = ["Food", "Animal", "Environmental", "Drug", "Others"];
  const [curTypes, setCurTypes] = useState([]);
  const apptId = useSelector(state => state.rxWriterReducer.id);
  const allergies = useSelector(
    state => state.observationReducer[apptId].allergySpecifications
  );
  const typeVal = useSelector(
    state => state.observationReducer[apptId].allergyIsEntering
  );
  const typeAlert = useSelector(
    state => state.observationCheckReducer.allergy_y_n
  );
  const allergyCheck = useSelector(
    state => state.observationCheckReducer.allergy
  );
  const brand = useSelector(state => state.rxWriterReducer.brand);
  const patient_medical_history = useSelector(
    state => state.rxWriterReducer.patient_medical_history
  );
  const presetIdToObj = useSelector(
    state => state.rxWriterReducer.presetIdToObj
  );
  const { presetData } = useSelector(state => state.rxWriterReducer);
  const [presetAllergySpecificationsData, setPresetAllergySpecificationsData] =
    useState({});
  useEffect(() => {
    const temp = [];
    allergies.forEach(element => {
      temp.push(element.metadata?.type);
    });
    setCurTypes(temp);
  }, [allergies]);
  useEffect(() => {
    let obj = {
      Food: [],
      Animal: [],
      Environmental: [],
      Drug: [],
      Others: []
    };
    for (const i in presetData) {
      if (presetData[i].brand === brand) {
        if (presetData[i]?.observation_type === `allergy_food_specification`) {
          obj["Food"].push(presetData[i]);
        } else if (
          presetData[i]?.observation_type === `allergy_animal_specification`
        ) {
          obj["Animal"].push(presetData[i]);
        } else if (
          presetData[i]?.observation_type ===
          `allergy_environmental_specification`
        ) {
          obj["Environmental"].push(presetData[i]);
        } else if (
          presetData[i]?.observation_type === `allergy_drug_specification`
        ) {
          obj["Drug"].push(presetData[i]);
        } else if (
          presetData[i]?.observation_type === `allergy_others_specification`
        ) {
          obj["Others"].push(presetData[i]);
        }
      }
    }
    setPresetAllergySpecificationsData(obj);
  }, [presetData, allergies, brand]);

  useEffect(() => {
    if (patient_medical_history.length > 0 && !typeVal) {
      const lastRecord =
        patient_medical_history[patient_medical_history.length - 1];
      const sampleAllergy = {
        text: "",
        pretext: [],
        metadata: { type: "" }
      };
      const curAllergies = [];
      for (const observation of lastRecord.observations) {
        if (observation?.observation_type === "allergy_specifications") {
          let temp = { ...sampleAllergy };
          temp.metadata = JSON.parse(observation.metadata);
          if (temp.metadata?.type) {
            temp.text = observation.observation_text;
            for (const preset of lastRecord.presetData) {
              const observationType =
                presetIdToObj[preset.observation_preset_id]?.observation_type;
              if (
                observationType ===
                `allergy_${temp.metadata?.type.toLowerCase()}_specification`
              ) {
                temp.pretext.push(preset.observation_preset_id);
              }
            }
            curAllergies.push(temp);
          }
        }
      }
      if (curAllergies.length === 0) {
        store.dispatch({ type: "SET_ALLERGY_IS_ENTERING", payload: "no" });
      } else {
        store.dispatch({ type: "SET_ALLERGY_IS_ENTERING", payload: "yes" });
        store.dispatch({ type: "SET_ALLERGY", payload: curAllergies });
      }
    }
  }, [patient_medical_history, presetIdToObj]);

  const handlePresetSelect = (index, value) => {
    store.dispatch({
      type: "SET_ARRAY_OBJECT_CHECK",
      payload: {
        category: "allergy",
        index,
        key: "text",
        value: false
      }
    });
    store.dispatch({
      type: "SET_ALLERGY_DATA",
      payload: {
        index: index,
        value: value,
        key: "pretext"
      }
    });
  };
  const handleAllergyTypeChange = (e, index) => {
    store.dispatch({
      type: "SET_ALLERGY_TYPE",
      payload: { index: index, key: "type", value: e.target.value }
    });
    store.dispatch({
      type: "SET_ARRAY_OBJECT_CHECK",
      payload: {
        category: "allergy",
        index,
        key: "type",
        value: false
      }
    });
  };
  const handleDelete = e => {
    e.preventDefault();
    store.dispatch({ type: "DELETE_ALLERGY_CHECK " });
    store.dispatch({ type: "DELETE_ALLERGY" });
  };
  const handleAdd = e => {
    e.preventDefault();
    store.dispatch({ type: "ADD_ALLERGY_CHECK" });
    store.dispatch({ type: "ADD_ALLERGY" });
  };
  const changeYesNo = value => {
    store.dispatch({
      type: "SET_KEY_CHECK",
      payload: { key: "allergy_y_n", value: false }
    });
    store.dispatch({ type: "SET_ALLERGY_IS_ENTERING", payload: value });
    store.dispatch({ type: "RESET_ALLERGY" });
  };
  return (
    <div className="allergies">
      <div style={{ display: "flex" }}>
        <p className="allergies_title">Allergies</p>
        <p style={{ color: "red", paddingLeft: "5px", fontSize: "20px" }}>*</p>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="allergy-radio">
          <label
            htmlFor="Yes"
            onClick={() => {
              changeYesNo("yes");
            }}
          >
            <input type="radio" checked={typeVal === "yes"} readOnly />
            <span
              style={{
                marginLeft: "5px",
                marginRight: "10px",
                opacity: typeVal === "yes" ? "1" : "0.7",
                fontWeight: typeVal === "yes" ? "600" : "400"
              }}
            >
              Yes
            </span>
          </label>
          <label
            htmlFor="no"
            onClick={() => {
              changeYesNo("no");
            }}
          >
            <input type="radio" checked={typeVal === "no"} readOnly />
            <span
              style={{
                marginLeft: "5px",
                opacity: typeVal === "no" ? "1" : "0.7",
                fontWeight: typeVal === "no" ? "600" : "400"
              }}
            >
              No
            </span>
          </label>
          {typeAlert && (
            <div style={{ color: "#E86669", marginTop: "2px" }}>
              This field cannot be left blank
            </div>
          )}
        </div>
      </div>
      {typeVal === "yes" &&
        allergies.map((allergy, index) => (
          <div className="allergies_content" key={index}>
            <div className="allergies_content_top">
              <div style={{ display: "flex" }}>
                <p style={{ fontWeight: "400" }}>Type of allergy</p>
                <p style={{ color: "red", paddingLeft: "5px" }}>*</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end"
                  }}
                >
                  {allergy.pretext.length === 0 && !allergy.text ? (
                    <select
                      onChange={e => handleAllergyTypeChange(e, index)}
                      style={{
                        border: allergyCheck[index]?.type
                          ? "1px solid #E86669"
                          : "none"
                      }}
                    >
                      <option value="select" selected>
                        -select-
                      </option>
                      {allergiesOption.map(val => (
                        <>
                          {(curTypes.indexOf(val) < 0 ||
                            allergy.metadata?.type === val) && (
                            <option
                              value={val}
                              key={val}
                              selected={allergy.metadata?.type === val}
                            >
                              {val}
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                  ) : (
                    <div className="allergy-type">{allergy.metadata?.type}</div>
                  )}
                </div>
                {allergyCheck[index]?.type && (
                  <div style={{ color: "#E86669", marginTop: "2px" }}>
                    This field cannot be left blank
                  </div>
                )}
              </div>
            </div>
            {allergies[index].metadata?.type &&
              allergies[index].metadata?.type !== "None" && (
                <RxWriterInputBox
                  headerTitle="Specifications"
                  isRequired={true}
                  isAllergies={true}
                  allergyType={allergy.metadata?.type}
                  mainPresetData={
                    presetAllergySpecificationsData[allergy.metadata?.type] ||
                    []
                  }
                  onChangeInput={text => {
                    store.dispatch({
                      type: "SET_ARRAY_OBJECT_CHECK",
                      payload: {
                        category: "allergy",
                        index,
                        key: "text",
                        value: false
                      }
                    });
                    store.dispatch({
                      type: "SET_ALLERGY_DATA",
                      payload: {
                        index: index,
                        value: text,
                        key: "text"
                      }
                    });
                  }}
                  presetValue={allergy.pretext}
                  textValue={allergy?.text}
                  onPresetSelect={value => handlePresetSelect(index, value)}
                  inputPlaceHolder="Click to start typing"
                  inputBoxHeight={60}
                  alert={allergyCheck[index]?.text}
                />
              )}
          </div>
        ))}
      {typeVal === "yes" && (
        <div className="allergies_bottom">
          {allergies.length > 1 ? (
            <button
              type="button"
              onClick={handleDelete}
              className="allergies_deleteBtn"
            >
              Delete Allergy
            </button>
          ) : null}
          {allergies.length < allergiesOption.length && (
            <button
              type="button"
              onClick={handleAdd}
              className="allergies_addBtn"
            >
              Add Allergy +
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Allergies;

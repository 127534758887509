import React from "react";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
const noop = () => {};

const ReassignModal = ({ show, setShow = noop, appointmentId, doctorId }) => {
  const onClose = () => {
    setShow(false);
  };
  const onReassign = () => {
    onClose();
    const data = {
      appointment_id: appointmentId,
      reassign_doctor_id: doctorId
    };
    const link = CONFIG_CONSTANTS.url.REASSIGN_DOCTOR;
    axios.post(link, data).then(resp => {
      window.forceReloadWithoutChecking = true;
      window.location.reload();
    });
  };
  return (
    <Modal show={show} onHide={onClose} centered dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="sectionHeading">Reassign Appointment [MODAL]</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form style={{ margin: "0" }}>
          <Form.Group>
            <Form.Label>
              Do you want to reassign this appointment to yourself?
            </Form.Label>
          </Form.Group>
        </Form>
        <Button
          variant="primary"
          onClick={onReassign}
          style={{ width: "100%", marginBottom: "15px" }}
        >
          Assign To Me
        </Button>
        <Button variant="danger" onClick={onClose} style={{ width: "100%" }}>
          No
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ReassignModal;

import React from "react";
import {
  StatusLogsViewerContainer,
  LogsViewerDiv
} from "./styles/logs-viewer.styles";
import { convertLogsTime } from "./helper/date-helper";
import { getStatusColor } from "./helper/status-colors";
import Tooltip from "@mui/material/Tooltip";
import { getToolTipText } from "./helper/tooltip-text";

export default function StatusLogsViewer({ logs }) {
  return (
    <StatusLogsViewerContainer>
      {logs.map((log, index) => {
        return (
          <Tooltip
            title={getToolTipText(logs, log, index)}
            placement="bottom"
            arrow
          >
            <LogsViewerDiv
              key={index}
              data-status={log.status}
              statusColor={getStatusColor(log.status)}
              data-logTime={
                convertLogsTime(log.dateTime) +
                (index + 1 < logs.length
                  ? " - " + convertLogsTime(logs[index + 1]?.dateTime)
                  : "")
              }
            />
          </Tooltip>
        );
      })}
    </StatusLogsViewerContainer>
  );
}

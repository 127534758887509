import React, { useState, useEffect } from "react";
import "./PrescribedProducts.scss";
import { Grid } from "@material-ui/core";
import axios from "axios";
import config from "../../../../../constants/apiList";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { store } from "../../../../../reducers/Persist";
import moment from "moment";
const PrescribedProducts = () => {
  const id = useSelector(state => state.rxWriterReducer.id);
  const prescribeProducts = useSelector(
    state => state.observationReducer[id].prescribeProducts
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .post(config.url.GET_PRESCRIBED_PRODUCTS, { appointmentId: id })
      .then(res => {
        if (res.data.success) {
          let arr = [];
          for (const item of res.data.data) {
            arr.push({
              metadata: {
                ...item,
                productId: item.id,
                improvement: ""
              }
            });
          }
          store.dispatch({ type: "SET_PRESCRIBED_PRODUCTS", payload: arr });
        } else console.log("error");
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }, [id]);
  return (
    <div className="prescribed-products">
      {loading ? (
        <div
          style={{
            width: "100%",
            marinTop: "40px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Loader type="TailSpin" />
        </div>
      ) : (
        <>
          {prescribeProducts && prescribeProducts.length > 0 ? (
            <div>
              <p className="pp-title">Prescribed Products</p>
              <div className="table-header" style={{ width: "100%" }}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    Product Name
                  </Grid>
                  <Grid item xs={2}>
                    <center>Start Date</center>
                  </Grid>
                  <Grid item xs={3}>
                    <center>Last date ordered</center>
                  </Grid>
                  <Grid item xs={2}>
                    <center>Quantity</center>
                  </Grid>
                  <Grid item xs={2}>
                    <center>Improvement</center>
                  </Grid>
                </Grid>
              </div>
              <div>
                {prescribeProducts.map((product, index) => {
                  return (
                    <div className="table-body-item" key={index}>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          {product.metadata.name}
                        </Grid>
                        <Grid item xs={2}>
                          <center>
                            {moment(product.metadata.start_date).format(
                              "DD/MM/YYYY"
                            )}
                          </center>
                        </Grid>
                        <Grid item xs={3}>
                          <center>
                            {moment(product.metadata.last_date_ordered).format(
                              "DD/MM/YYYY"
                            )}
                          </center>
                        </Grid>
                        <Grid item xs={2}>
                          <center>{parseInt(product.metadata.quantity)}</center>
                        </Grid>
                        <Grid item xs={2}>
                          <center>
                            <select
                              onChange={e => {
                                store.dispatch({
                                  type: "SET_PRESCRIBED_PRODUCTS_IMPROVEMENT",
                                  payload: {
                                    index,
                                    value: e.target.value
                                  }
                                });
                              }}
                            >
                              <option value="select" selected disabled hidden>
                                -Select-
                              </option>
                              <option
                                value="yes"
                                selected={
                                  product.metadata.improvement === "yes"
                                }
                              >
                                yes
                              </option>
                              <option
                                value="no"
                                selected={product.metadata.improvement === "no"}
                              >
                                no
                              </option>
                            </select>
                          </center>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default PrescribedProducts;

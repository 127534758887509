import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAsync } from "../../../hooks";
import adminService from "../../../services/adminService";
import EditableTable from "../../common/Tables/EditableTable";

export default function EditableDataTable2({
  title,
  url,
  reqBody,
  cols,
  urlPost,
  actions,
  search,
  pageSize,
  rowStyle,
  fromS3
}) {
  const dispatch = useDispatch();

  const [fetchedData] = useAsync(url, reqBody, fromS3);
  const [updatedData, setUpdatedData] = useState();

  useEffect(() => {
    if (fetchedData && updatedData) {
      let mainRow;
      fetchedData.forEach(el => {
        const row = updatedData.find(
          row => row.id === el.id && row.doctor_id !== el.doctor_id
        );
        if (row !== undefined) {
          mainRow = row;
        }
      });
      if (mainRow !== undefined) {
        const body = {
          appointment_id: mainRow.id,
          reassign_doctor_id: Number(mainRow.doctor_id),
          admin_reschedule: true
        };
        adminService.sendData(urlPost, body);
        setUpdatedData(null);
        setTimeout(
          () =>
            dispatch({
              type: "REFRESH"
            }),
          3000
        );
      }
    }
  }, [updatedData, fetchedData, urlPost]);

  return fetchedData ? (
    <div>
      <EditableTable
        title={title}
        cols={cols}
        rows={fetchedData}
        setUpdatedData={setUpdatedData}
        actions={actions}
        search={search}
        rowStyle={rowStyle}
        pageSize={pageSize}
      />
    </div>
  ) : null;
}

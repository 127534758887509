import axios from "axios";
import { toast } from "react-toastify";

async function getData(url, data, fromS3) {
  // console.log(url);
  try {
    const res = await axios.post(url, data);
    // console.log(res);
    if (res.status === 200) {
      //return res.data.statusCode == 200 ? res.data.results : null;
      if (res.data.statusCode == 200 && fromS3) {
        // console.log("line number 14 adminservice");
        let newUrl = res.data.url;
        // console.log(res.data);
        var instance = axios.create();
        delete instance.defaults.headers.common["Authorization"];
        const res2 = await instance.get(newUrl);
        // console.log(newUrl);
        return res2.data.records;
      }
    }
    if (res.status === 200) {
      return res.data.statusCode == 200 ? res.data.results : null;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}

async function sendData(url, data) {
  // console.log(url);
  // console.log(data);
  try {
    const res = await axios.post(url, data);
    // console.log(res);
    toast.success(res.data.results);
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.results);
  }
}

export default {
  getData,
  sendData
};

import React, { useCallback, useState } from "react";
import { NewCommp } from "./NewCommp";

export const Comp = ({ renderSwitch, page }) => {
  const [countToday, setCountToday] = useState();
  const [countPending, setCountPending] = useState();
  const [countPriority, setCountPriority] = useState();
  const [flag, setFlag] = useState(false);

  const ff = useCallback(() => {
    return renderSwitch(setCountToday, setCountPending, setCountPriority);
  }, [flag]);

  return (
    <div
      style={{ flexGrow: "1", backgroundColor: "#f6f8fb", fontWeight: "1000" }}
    >
      <NewCommp
        page={page}
        setFlag={setFlag}
        countToday={countToday}
        countPending={countPending}
        countPriority={countPriority}
      />
      {ff()}
    </div>
  );
};

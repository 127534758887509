const initialState = {
  imageTimeline: {}
};
const imageTimelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_IMAGE_TIMELINE":
      return { ...state, imageTimeline: action.data };
    default:
      return state;
  }
};
export default imageTimelineReducer;

import React from "react";

export default function Card({ data }) {
  return (
    <div>
      <div>
        <span>Brand</span> <br /> <br />
        <span>{data.brand}</span>
      </div>
      <div>
        <span>
          Total
          <br />
          Appointments
        </span>{" "}
        <br />
        <span>{data.totalAppointments}</span>
      </div>
      <div>
        <span>
          Total
          <br />
          Pending
        </span>{" "}
        <br />
        <span>{data.totalPending}</span>
      </div>
      <div>
        <span>Completed</span> <br /> <br />
        <span>{data.completed}</span>
      </div>
      <div>
        <span>Rx Generated</span> <br /> <br />
        <span>{data.rxGenerated}</span>
      </div>
      <div>
        <span>
          Total
          <br />
          Completion%
        </span>{" "}
        <br />
        <span>
          {data.completed
            ? (data.completed / data.totalAppointments) * 100 < 10 &&
              (data.completed / data.totalAppointments) * 100 > 0
              ? "0" +
                ((data.completed / data.totalAppointments) * 100).toFixed(2)
              : ((data.completed / data.totalAppointments) * 100).toFixed(2)
            : 0}
          %
        </span>
      </div>
    </div>
  );
}

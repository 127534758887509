const initialState = {
  top: [],
  bottom: [],
  topOrderId: "",
  bwProductData: {}
};
let temp, arr, index;
const orderedProducts = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_TOP_PRODUCT":
      const order_id =
        payload.order_id ||
        state.top.find(product => !!product?.order_id)?.order_id ||
        "";
      if (!order_id) {
        console.error("!!! No order id found in ADD_TOP_PRODUCT", payload);
      }
      return {
        ...state,
        top: [
          ...state.top,
          {
            ...payload,
            edit: false,
            cancelDelete: action.cancelDelete,
            order_id
          }
        ]
      };
    case "SET_BW_PRODUCT_DATA":
      return {
        ...state,
        bwProductData: payload.bwProductData
      };
    case "DELETE_TOP_PRODUCT":
      temp = [...state.top];
      index = payload;
      temp = temp.filter((val, i) => i !== index);
      return { ...state, top: [...temp] };
    case "COMPLETELY__ADD_TOP_PRODUCT":
      index = payload;
      arr = [...state.top];
      arr[index] = { ...arr[index], edit: false, cancelDelete: false };
      // console.log(arr[index]);
      return { ...state, top: [...arr] };
    case "EDIT_TOP_PRODUCT":
      index = payload;
      arr = [...state.top];
      arr[index] = { ...arr[index], edit: true };
      return { ...state, top: [...arr] };
    case "CHANGE_TOP_DATA":
      index = payload.index;
      const { newData } = payload;
      const NewArr = [...state.top];
      NewArr[index] = { ...newData, edit: false };
      // console.log(NewArr[index]);
      return { ...state, top: [...NewArr] };
    case "ADD_TOP_ORDER_ID":
      return {
        ...state,
        topOrderId: payload
      };
    case "ADD_BOTTOM_PRODUCT":
      return {
        ...state,
        bottom: [...state.bottom, payload]
      };
    default:
      return { ...state };
  }
};
export default orderedProducts;

export const doctorHoursColumn = [
  {
    title: "Doctor",
    field: "doctorId"
  },
  {
    title: "Duty Hours",
    field: "dutyHoursDuration"
  },
  {
    title: "Live Hours",
    field: "actualHoursDuration"
  },
  {
    title: "Step Away Duration",
    field: "stepAwayCount"
  },
  {
    title: "On Call Duration (Connected Duration)",
    field: "callDuration"
  },
  {
    title: "Active",
    field: "active"
  },
  {
    title: "Busy",
    field: "busy"
  },
  {
    title: "Cool Off",
    field: "coolOff"
  },
  {
    title: "Step Away",
    field: "stepAway"
  },
  {
    title: "Leave OOC",
    field: "leaveOoc"
  },
  {
    title: "Absent OOC",
    field: "absentOoc"
  },
  {
    title: "Active OOC",
    field: "activeOoc"
  }
];

/**
 * Formats the text to display in the table
 * @param {number} val
 * @returns {string}
 */
export function dataTextFormat(value, valuePercentage) {
  return `${value || 0} | ${valuePercentage || 0}%`;
}

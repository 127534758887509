import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import { toast } from "react-toastify";

function SystemCheck(props) {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [brand, setBrand] = useState("MM");
  const [showLoader, setShowLoader] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [timewiseRows, setTimewiseRows] = useState([]);
  const [languagewiseRows, setLanguagewiseRows] = useState([]);
  const [doctorwiseRows, setDoctorwiseRows] = useState([]);
  const [categorywiseRows, setCategorywiseRows] = useState([]);
  const [sourcewiseRows, setSourcewiseRows] = useState([]);
  const [doctorHourWiseRows, setDoctorHourwiseRows] = useState([]);
  const [tab, setTab] = useState("TIMEWISE");
  const getSystemCheckStatistics = async evt => {
    setFetchError(false);
    setShowLoader(true);
    toast.dismiss();
    const systemCheckRequestData = {
      duration: { fromDate, toDate },
      brand
    };
    await axios
      .post(CONFIG_CONSTANT.url.SYSTEM_CHECK_URL, systemCheckRequestData)
      .then(res => {
        console.log("GET_SHORT_CALLS RES", res.data);
        toast.success("Data Fetched Successfully");
        generateTimewiseRows(res.data.data.time);
        generateLanguagewiseRows(res.data.data.language);
        generateDoctorwiseRows(res.data.data.doctor);
        generateCategorywiseRows(res.data.data.category);
        generateSourcewiseRows(res.data.data.source);
        generateDoctorHourwiseRows(res.data.data.doctorHourGroup);
      })
      .catch(err => {
        console.log(err);
        toast.error(
          `Error in fetching Data. Please Try Again! ${err?.message}`,
          {
            position: "top-right",
            autoClose: 100000
          }
        );
        setFetchError(true);
        console.log("Error in system Health", err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getSystemCheckStatistics();
  }, [brand]);
  const generateTimewiseRows = data => {
    const rows = [];
    for (const hour in data) {
      const statistics = data[hour];
      statistics["hours"] = `${hour}:00`;
      const row = { hour, ...statistics };
      rows.push(row);
    }
    setTimewiseRows(rows);
  };
  const generateLanguagewiseRows = data => {
    const rows = [];
    for (const language in data) {
      const langData = data[language];
      langData["languageName"] = language;
      const row = { language, ...langData };
      rows.push(row);
    }
    setLanguagewiseRows(rows);
  };

  const generateDoctorwiseRows = data => {
    const rows = [];
    for (const doctorId in data) {
      const docDat = data[doctorId];
      docDat["doctorId"] = doctorId;
      const row = { doctorId, ...docDat };
      rows.push(row);
    }
    setDoctorwiseRows(rows);
  };
  const generateCategorywiseRows = data => {
    const rows = [];
    for (const category in data) {
      const catData = data[category];
      catData["categoryName"] = category;
      const row = { category, ...catData };
      rows.push(row);
    }
    setCategorywiseRows(rows);
  };
  const generateSourcewiseRows = data => {
    const rows = [];
    for (const source in data) {
      const sourceData = data[source];
      sourceData["sourceName"] = source;
      const row = { source, ...sourceData };
      rows.push(row);
    }
    setSourcewiseRows(rows);
  };
  const generateDoctorHourwiseRows = data => {
    const rows = [];
    for (const doctorId in data) {
      const doctorData = data[doctorId];
      const doctorName = doctorData["doctorName"];
      for (const hour in doctorData) {
        const hourDoctorData = doctorData[hour];
        if (
          hourDoctorData?.["totalCapacity"] &&
          hourDoctorData?.["totalCapacity"] > 0
        ) {
          const row = { doctorId, doctorName, hour, ...hourDoctorData };
          rows.push(row);
        }
      }
    }
    setDoctorHourwiseRows(rows);
  };
  const timewiseColumns = [
    { title: "Hour Stamp", field: "hours" },
    { title: "Total Capacity", field: "totalCapacity" },
    { title: "Active Hours", field: "activeHours" },
    { title: "Total Appointments", field: "totalAppts" },
    { title: "Total Addressal", field: "totalUtilization" },
    { title: "Total Completion", field: "totalEfficiency" },
    { title: "Total Velocity", field: "totalVelocity" },
    { title: "Total Instant", field: "totalInstant" },
    { title: "IA Addressal", field: "iaUtilization" },
    { title: "IA Completion", field: "iaEfficiency" },
    { title: "IA Velocity", field: "iaVelocity" },
    { title: "Total Scheduled", field: "totalScheduled" },
    { title: "SA Addressal", field: "saUtilization" },
    { title: "SA Completion", field: "saEfficiency" },
    { title: "SA Velocity", field: "saVelocity" }
  ];
  const languagewiseColumns = [
    { title: "Language", field: "languageName" },
    { title: "Total Capacity", field: "totalCapacity" },
    { title: "Active Hours", field: "activeHours" },
    { title: "Total Appointments", field: "totalAppts" },
    { title: "Total Addressal", field: "totalUtilization" },
    { title: "Total Completion", field: "totalEfficiency" },
    { title: "Total Velocity", field: "totalVelocity" },
    { title: "Total Instant", field: "totalInstant" },
    { title: "IA Addressal", field: "iaUtilization" },
    { title: "IA Completion", field: "iaEfficiency" },
    { title: "IA Velocity", field: "iaVelocity" },
    { title: "Total Scheduled", field: "totalScheduled" },
    { title: "SA Addressal", field: "saUtilization" },
    { title: "SA Completion", field: "saEfficiency" },
    { title: "SA Velocity", field: "saVelocity" }
  ];

  const doctorwiseColumns = [
    { title: "Doctor Id", field: "doctorId" },
    { title: "Doctor Name", field: "doctorName" },
    { title: "Total Capacity", field: "totalCapacity" },
    { title: "Active Hours", field: "activeHours" },
    { title: "Overview Total", field: "totalOverview" },
    { title: "Total Appointments", field: "totalAppts" },
    { title: "Total Addressal", field: "totalUtilization" },
    { title: "Total Completion", field: "totalEfficiency" },
    { title: "Total Velocity", field: "totalVelocity" },
    { title: "Total Instant", field: "totalInstant" },
    { title: "IA Addressal", field: "iaUtilization" },
    { title: "IA Completion", field: "iaEfficiency" },
    { title: "IA Velocity", field: "iaVelocity" },
    { title: "Total Scheduled", field: "totalScheduled" },
    { title: "SA Addressal", field: "saUtilization" },
    { title: "SA Completion", field: "saEfficiency" },
    { title: "SA Velocity", field: "saVelocity" }
  ];
  const categorywiseColumns = [
    { title: "Category", field: "categoryName" },
    { title: "Total Capacity", field: "totalCapacity" },
    { title: "Active Hours", field: "activeHours" },
    { title: "Total Appointments", field: "totalAppts" },
    { title: "Total Addressal", field: "totalUtilization" },
    { title: "Total Completion", field: "totalEfficiency" },
    { title: "Total Velocity", field: "totalVelocity" },
    { title: "Total Instant", field: "totalInstant" },
    { title: "IA Addressal", field: "iaUtilization" },
    { title: "IA Completion", field: "iaEfficiency" },
    { title: "IA Velocity", field: "iaVelocity" },
    { title: "Total Scheduled", field: "totalScheduled" },
    { title: "SA Addressal", field: "saUtilization" },
    { title: "SA Completion", field: "saEfficiency" },
    { title: "SA Velocity", field: "saVelocity" }
  ];
  const sourcewiseColumns = [
    { title: "Source Name", field: "sourceName" },
    { title: "Total Capacity", field: "totalCapacity" },
    { title: "Active Hours", field: "activeHours" },
    { title: "Total Appointments", field: "totalAppts" },
    { title: "Total Addressal", field: "totalUtilization" },
    { title: "Total Completion", field: "totalEfficiency" },
    { title: "Total Velocity", field: "totalVelocity" },
    { title: "Total Instant", field: "totalInstant" },
    { title: "IA Addressal", field: "iaUtilization" },
    { title: "IA Completion", field: "iaEfficiency" },
    { title: "IA Velocity", field: "iaVelocity" },
    { title: "Total Scheduled", field: "totalScheduled" },
    { title: "SA Addressal", field: "saUtilization" },
    { title: "SA Completion", field: "saEfficiency" },
    { title: "SA Velocity", field: "saVelocity" }
  ];
  const doctorHourWiseColumns = [
    { title: "Doctor Id", field: "doctorId" },
    { title: "Doctor Name", field: "doctorName" },
    { title: "Hour", field: "hour" },
    { title: "Total Capacity", field: "totalCapacity" },
    { title: "Active Hours", field: "activeHours" },
    { title: "Total Appointments", field: "totalAppts" },
    { title: "Total Addressal", field: "totalUtilization" },
    { title: "Total Completion", field: "totalEfficiency" },
    { title: "Total Velocity", field: "totalVelocity" },
    { title: "Total Instant", field: "totalInstant" },
    { title: "IA Addressal", field: "iaUtilization" },
    { title: "IA Completion", field: "iaEfficiency" },
    { title: "IA Velocity", field: "iaVelocity" },
    { title: "Total Scheduled", field: "totalScheduled" },
    { title: "SA Addressal", field: "saUtilization" },
    { title: "SA Completion", field: "saEfficiency" },
    { title: "SA Velocity", field: "saVelocity" }
  ];

  const renderTab = () => {
    const navTabs = [
      { label: "TIME WISE", value: "TIMEWISE" },
      { label: "LANGUAGE WISE", value: "LANGUAGEWISE" },
      { label: "DOCTOR WISE", value: "DOCTORWISE" },
      { label: "CATEGORY WISE", value: "CATEGORYWISE" },
      { label: "SOURCE WISE", value: "SOURCEWISE" },
      { label: "DOCTOR HOUR WISE", value: "DOCTORHOURWISE" }
    ];
    return (
      <div className="nav-tab" style={{ borderColor: "rgb(230,230,230)" }}>
        {navTabs.map((navTab, index) => {
          return (
            <div
              className={`tab ${tab === navTab.value ? "active" : ""}`}
              onClick={evt => setTab(navTab.value)}
              style={{ fontSize: "14px" }}
            >
              {navTab.label}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="short-calls">
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={fromDate}
          onChange={evt => setFromDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={toDate}
          onChange={evt => setToDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={getSystemCheckStatistics}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>

      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : fetchError ? (
        <ErrorUI />
      ) : (
        <div>
          {renderTab()}
          <div hidden={tab !== "TIMEWISE"}>
            <Table4
              title="Date Wise View"
              rows={timewiseRows}
              cols={timewiseColumns}
              pageSize={timewiseRows.length > 10 ? 10 : timewiseRows.length}
            />
          </div>
          <div hidden={tab !== "LANGUAGEWISE"}>
            <Table4
              title="Language Wise View"
              rows={languagewiseRows}
              cols={languagewiseColumns}
              pageSize={languagewiseRows.length}
            />
          </div>
          <div hidden={tab !== "DOCTORWISE"}>
            <Table4
              title="Doctor Wise View"
              rows={doctorwiseRows}
              cols={doctorwiseColumns}
              pageSize={doctorwiseRows.length > 10 ? 10 : doctorwiseRows.length}
            />
          </div>
          <div hidden={tab !== "CATEGORYWISE"}>
            <Table4
              title="Category Wise View"
              rows={categorywiseRows}
              cols={categorywiseColumns}
              pageSize={
                categorywiseRows.length > 10 ? 10 : categorywiseRows.length
              }
            />
          </div>
          <div hidden={tab !== "SOURCEWISE"}>
            <Table4
              title="Source Wise View"
              rows={sourcewiseRows}
              cols={sourcewiseColumns}
              pageSize={sourcewiseRows.length > 10 ? 10 : sourcewiseRows.length}
            />
          </div>
          <div hidden={tab !== "DOCTORHOURWISE"}>
            <Table4
              title="Doctor Hour View"
              rows={doctorHourWiseRows}
              cols={doctorHourWiseColumns}
              pageSize={
                doctorHourWiseRows.length > 10 ? 10 : doctorHourWiseRows.length
              }
              filtering={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default SystemCheck;

function ErrorUI() {
  return <div>Error in Fetching Data :(</div>;
}

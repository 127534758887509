import { useState, useEffect } from "react";
import adminService from "./services/adminService";
import { useSelector } from "react-redux";
import Axios from "axios";

export const useAsync = (url, reqBody, fromS3) => {
  const [data, setData] = useState(null);

  const hook = () => {
    let isSubscribed = true;
    const fetchData = async () => {
      const resBody = await adminService.getData(url, reqBody, fromS3);
      if (isSubscribed) {
        setData(resBody);
      }
    };
    fetchData();
    return () => (isSubscribed = false);
  };
  useEffect(hook, [
    reqBody,
    useSelector(state => state.appointmentReducer.refresh)
  ]);
  return [data];
};

import React from "react";
import "./ViewPrescriptionModal.scss";
import axios from "axios";
import config from "../../../../../constants/apiList";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { store } from "../../../../../reducers/Persist";
import Iframe from "react-iframe";

class ViewPrescriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      prescriptionUrl: ""
    };
  }
  componentDidMount() {
    if (this.props.prescriptionId) {
      this.setState({ loading: true });
      const payload = {
        doctorId: this.props.doctorId,
        prescriptionId: this.props.prescriptionId,
        brand: this.props.brand
      };
      axios
        .post(config.url.GET_PDF_URL, payload)
        .then(res => {
          this.setState({
            prescriptionUrl: res.data.body.data,
            loading: false
          });
        })
        .catch(err => console.log(err));
    }
  }
  render() {
    return (
      <div className="timeline-prescription">
        <div className="header-row">
          <div className="modal-title">Prescription</div>
          <img
            src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
            alt="close"
            onClick={this.props.closeModal}
            className="close-button"
          />
        </div>
        {this.state.loading ? (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Loader type="TailSpin" />
          </div>
        ) : (
          <div>
            {this.props.prescriptionId ? (
              <Iframe
                url={this.state.prescriptionUrl}
                width="700px"
                height="600px"
                id="iframe"
                name="iframe"
                allowtransparency="true"
                style={{ background: "white" }}
                display="initial"
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%"
                }}
              >
                Prescription not found!
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {
    doctorId: store.getState().userReducer.hash_id,
    brand: store.getState().rxWriterReducer.brand
  };
};
export default connect(mapStateToProps)(ViewPrescriptionModal);

import axios from "axios";
import React, { useState, useEffect } from "react";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import Table4 from "../../common/Tables/Table4";
import Loader from "react-loader-spinner";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DownloadToggle from "./DownloadToggle";

function DownloadVCRecording(props) {
  const [startDate, setStartDate] = useState(
    moment().subtract(5, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().add(5, "days").format("YYYY-MM-DD")
  );
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const columns = [
    { title: "Appointment ID", field: "appointmentId" },
    { title: "Brand", field: "brand" },
    { title: "Patient", field: "patientName" },
    { title: "Doctor", field: "doctorName" },
    { title: "Play", field: "download" }
  ];
  const downloadRecording = async data => {
    setDownloadLoader(true);
    const requestData = {
      filePath: data.filepath
    };
    await axios
      .post(CONFIG_CONSTANTS.url.DOWNLOAD_VC_RECORDING, requestData)
      .then(async res => {
        console.log(res);
        if (res.data.success) {
          window.open(res.data.signedUrl, "_blank");
          setDownloadLoader(false);
        } else {
          console.log(res.data.message);
          setDownloadLoader(false);
        }
      })
      .catch(e => {
        console.log(e);
        setDownloadLoader(false);
      });
  };
  const updateRows = rows => {
    const tempRows = [...rows];
    tempRows.forEach((row, index) => {
      row.download = (
        <DownloadToggle
          files={row.files}
          show={activeIndex === index}
          onToggle={evt => setActiveIndex(activeIndex === index ? -1 : index)}
          onDownloadClick={downloadRecording}
        />
      );
    });
    setRows(tempRows);
  };
  useEffect(() => {
    updateRows(rows);
  }, [activeIndex]);
  const generateRows = data => {
    const finalRows = [];
    for (const appointmentId in data) {
      const finalRow = { ...data[appointmentId], appointmentId };
      finalRows.push(finalRow);
    }
    updateRows(finalRows);
  };
  const getVCRecordings = async () => {
    setLoader(true);
    const requestData = {
      startDate,
      endDate
    };
    await axios
      .post(CONFIG_CONSTANTS.url.GET_VC_RECORDINGS, requestData)
      .then(res => {
        if (res.data.success) {
          generateRows(res.data.result);
        }
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        console.log("ERROR IN /getVCRecordings");
      });
  };
  useEffect(() => {
    getVCRecordings();
  }, []);
  const outsideClick = evt => {
    const className = evt.target.className;
    console.log("CLASSNAME", className);
    if (typeof className !== "string" || !className.includes("toggler-button"))
      setActiveIndex(-1);
  };
  return (
    <div
      className="download-vc-recording"
      style={{ padding: "10px", minHeight: "100vh" }}
      onClick={outsideClick}
    >
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={startDate}
          onChange={evt => setStartDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={endDate}
          onChange={evt => setEndDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={getVCRecordings}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      {downloadLoader ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#144166",
            fontWeight: "bold",
            padding: "0px 10px",
            justifyContent: "flex-end"
          }}
        >
          DOWNLOADING
          <Loader
            width={40}
            height={40}
            type="ThreeDots"
            color="#144166"
            style={{ marginLeft: "10px" }}
          />
        </div>
      ) : null}
      {loader ? (
        <Loader width={40} height={40} type="ThreeDots" color="#144166" />
      ) : (
        <Table4
          title="Video Call Recordings"
          cols={columns}
          rows={rows}
          pageSize={rows.length > 10 ? 10 : rows.length}
          search={true}
        />
      )}
    </div>
  );
}

export default DownloadVCRecording;

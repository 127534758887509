import React, { Component } from "react";
import "./ListDropdownInput.scss";
export default class ListDropdownInput extends Component {
  constructor(props) {
    super(props);
    this.selectedLi = React.createRef();
    this.tagInput = React.createRef();
    this.state = {
      filteredSuggestions: [],
      activeIndex: -1
    };
    this.onLiClickHandler = evt => {
      const value = evt.target.value;
      if (value.length > 0) {
        this.props.onAdd(value);
      }
      evt.target.selectedIndex = 0;
    };
    this.onRemoveClickHandler = evt => {
      const index = Number(evt.target.id);
      this.props.onRemove(index);
    };
  }
  componentDidUpdate() {
    if (this.state.activeIndex > -1) {
      this.selectedLi.current.scrollIntoView();
    }
  }
  render() {
    let placeholder = this.props.placeholder;
    return (
      <div>
        <div className="list-dropdown-input">
          <div
            style={{
              fontWeight: "bold",
              fontSize: "14px",
              color: "rgb(80,80,80)"
            }}
            hidden={this.props.collections.length === 0}
          >
            {this.props.listTitle}
          </div>
          <ul className="collection-list">
            {this.props.collections.map((item, index) => {
              return (
                <li key={index} className="collection-li">
                  {item}{" "}
                  <button
                    type="button"
                    id={index}
                    className="collection-remove-button"
                    onClick={this.onRemoveClickHandler}
                    style={{ outline: "none" }}
                  >
                    X
                  </button>
                </li>
              );
            })}
          </ul>
          <select
            onChange={this.onLiClickHandler}
            className="form-control minimal"
            style={{ padding: "5px" }}
          >
            <option selected disabled>
              {placeholder}
            </option>
            {this.props.suggestions.map((item, index) => {
              return <option key={index}>{item}</option>;
            })}
          </select>
        </div>
      </div>
    );
  }
}

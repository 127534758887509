import React, { useState } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import axios from "axios";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import FileDownload from "js-file-download";
import { toast } from "react-toastify";

function DAExport(props) {
  const [startDate, setStartDate] = useState(
    moment().subtract(5, "days").utcOffset(330).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().utcOffset(330).format("YYYY-MM-DD")
  );
  const [brand, setBrand] = useState("MM");
  const [filterByCreated, setFilterByCreated] = useState(false);
  const exportData = async () => {
    const requestData = {
      startDate,
      endDate,
      brand,
      filterByCreated
    };
    await axios
      .post(CONFIG_CONSTANTS.url.EXPORT_DA, requestData)
      .then(res => {
        if (res.data.success) {
          FileDownload(res.data.csv, "DA-export.csv");
        } else {
          toast.info(res.data.message);
        }
      })
      .catch(err => {
        console.log("ERROR IN EXPORT DA", err);
      });
  };
  return (
    <div className="da-export__main">
      <div style={{ display: "flex" }}>
        <div
          style={{ margin: "0px 10px" }}
          onClick={evt => setFilterByCreated(false)}
        >
          <label style={{ cursor: "pointer", margin: "10px 5px" }}>
            Appointment Date
          </label>
          <input type="radio" checked={!filterByCreated} />
        </div>
        <div
          style={{ margin: "0px 10px" }}
          onClick={evt => setFilterByCreated(true)}
        >
          <label style={{ cursor: "pointer", margin: "10px 5px" }}>
            Created Date
          </label>
          <input type="radio" checked={filterByCreated} />
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>

      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={startDate}
          onChange={evt => setStartDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={endDate}
          onChange={evt => setEndDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <Button varient="primary" onClick={exportData}>
          Export
        </Button>
      </div>
    </div>
  );
}

export default DAExport;

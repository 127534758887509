import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles.scss";
import { store } from "../../../../reducers/Persist";
import ZoomInZoomOut from "../ZoomInZoomOut";

function ViewSingleImageModal(props) {
  const [showModal, setShowModal] = useState(props.modalOpen);
  const transformRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(props.image_src);
  const [nextImageIndex, setNextImageIndex] = useState(0);
  const [prevImageIndex, setPrevImageIndex] = useState(0);
  useEffect(() => {
    props.image_arr.filter((key, index) => {
      if (key === props.image_src) {
        setNextImageIndex(index + 1);
        setPrevImageIndex(index - 1);
      }
    });
  }, []);
  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          store.dispatch({ type: "CLOSE_SINGLE_IMAGE_MODAL" });
          setShowModal(false);
        }}
        dialogClassName="image-timeline-modal"
      >
        <div className="viewSingleImageModal__container">
          <div className="top">
            {!!props.date && (
              <div className="modal_date" style={{ marginRight: "100px" }}>
                {props.date}
              </div>
            )}
            <img
              style={{ width: "40px", height: "40px", marginLeft: "auto" }}
              loading="lazy"
              src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1640077189/dochub/rx-writer/Close.png"
              alt="close"
              onClick={() => {
                store.dispatch({ type: "CLOSE_SINGLE_IMAGE_MODAL" });
                setShowModal(false);
              }}
              className="close-button"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {props.image_arr[prevImageIndex] && (
                <div>
                  <button
                    style={{
                      height: "57vh",
                      width: "5vw",
                      background: "transparent",
                      border: "none"
                    }}
                    onClick={() => {
                      setPrevImageIndex(prevImageIndex - 1);
                      setNextImageIndex(nextImageIndex - 1);
                      setImageSrc(props.image_arr[prevImageIndex]);
                    }}
                  >
                    <img
                      style={{ height: "5vh", width: "5vw" }}
                      src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1650615667/Rx%20Writer/chevron-left.png"
                    />
                  </button>
                </div>
              )}
            </div>
            <div>
              <ZoomInZoomOut>
                <img
                  src={imageSrc}
                  alt="modalImage"
                  style={{
                    width: "100%",
                    height: "100%",
                    minWidth: "600px",
                    aspectRatio: "1/1",
                    minHeight: "100%"
                  }}
                />
              </ZoomInZoomOut>
            </div>
            <div>
              {props.image_arr[nextImageIndex] && (
                <div>
                  <button
                    style={{
                      height: "57vh",
                      width: "5vw",
                      background: "transparent",
                      border: "none"
                    }}
                    onClick={() => {
                      setNextImageIndex(nextImageIndex + 1);
                      setPrevImageIndex(prevImageIndex + 1);
                      setImageSrc(props.image_arr[nextImageIndex]);
                    }}
                  >
                    <img
                      style={{ height: "5vh", width: "5vw" }}
                      src="https://ik.manmatters.com/mosaic-wellness/image/upload/v1650615667/Rx%20Writer/chevron-right.png"
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <div style={{ fontSize: "14px" }}>Scroll to ZOOM IN & ZOOM OUT</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ViewSingleImageModal;

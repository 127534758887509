import axios from "axios";
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import config from "../../../constants/apiList";
import dataResource from "../../../constants/dataResource";
import { MdVisibility } from "react-icons/md";
import { store } from "../../../reducers/Persist";
import { rxOpenedClevertap } from "../../../helpers";
import { getRxWriterLink } from "../../../helpers/rx-writer.helpers";

export const newDashboardToday = [
  {
    title: "Appointments",
    field: "appointments"
  },
  {
    title: "Completed",
    field: "completed"
  },
  {
    title: "Rx Generated",
    field: "rx_generated"
  },
  {
    title: "On Time%",
    render: rowData => <div>{rowData.on_time_percent} %</div>
  },
  {
    title: "Completion%",
    render: rowData => <div>{rowData.completion_percent} %</div>
  },
  {
    title: "Scheduled",
    field: "scheduled"
  }
];
export const newDashboardPending = [
  {
    title: "Appointments",
    field: "appointments"
  },
  {
    title: "Completed",
    field: "completed"
  },
  {
    title: "Rx Generated",
    field: "rx_generated"
  }
];
export const newDashboardSummary = [
  {
    title: "Connected",
    field: "connected"
  },
  {
    title: "Completed",
    field: "completed"
  },
  {
    title: "Rx Generated",
    field: "rx_generated"
  },
  {
    title: "PCI",
    field: "pci"
  }
];

export const appointmentsTodaySummary = [
  {
    title: "Connected",
    field: "connected"
  },
  {
    title: "Completed",
    field: "completed"
  },
  {
    title: "RxGenerated",
    field: "rx_generated"
  },
  {
    title: "On Time%",
    render: rowData => (
      <div style={{ padding: "10px" }}>{rowData.on_time_percent} %</div>
    )
  },
  {
    title: "Completion%",
    field: "completion"
  },
  {
    title: "Scheduled",
    field: "scheduled"
  },
  {
    title: "PCI",
    field: "pci"
  }
];
export const appointmentsPriorityTable = [
  {
    title: "Rx",
    render: rowData => {
      return (
        <div
          onClick={e => {
            let param = rowData.brand + "-" + rowData.source + "-" + rowData.id;
            const oldCategories = ["SH", "HC", "OF"];
            if (oldCategories.includes(rowData.source)) {
              param = rowData.source_id;
            }
            window.open(getRxWriterLink(param), "_self");
          }}
          style={{
            cursor: "pointer",
            padding: "10px 0",
            title: "Open Prescription"
          }}
        >
          <MdVisibility />
        </div>
      );
    }
  },
  {
    title: "Status",
    render: rowData => <div>{dataResource.statusListObj[rowData.status]}</div>
  },
  {
    title: "Confirmed",
    render: rowData => <div>{rowData.confirmed ? "Yes" : "-"}</div>
  },
  {
    title: "Patient ID",
    render: rowData => (
      <div style={{ whiteSpace: "nowrap" }}>
        {rowData.brand + "-" + rowData.source + "-" + rowData.id}
      </div>
    )
  },
  {
    title: "Date",
    render: rowData => {
      return (
        <div style={{ whiteSpace: "nowrap" }}>{rowData.appointment_date}</div>
      );
    }
  },
  {
    title: "Start Time",
    field: "from_time"
  },
  {
    title: "Patient Name",
    field: "patient_name",
    render: rowData => {
      return <div style={{ whiteSpace: "nowrap" }}>{rowData.patient_name}</div>;
    }
  },
  {
    title: "Concern",
    render: rowData => {
      return (
        <div>
          {rowData.category &&
            rowData.category
              .split(",")
              .map(c => dataResource.categoryMap[c])
              .join(", ")}
        </div>
      );
    }
  },
  {
    title: "Ask for Image",
    render: rowData => <AskImage rowData={rowData} />
  },
  {
    title: "Callback Time",
    field: "request_callback_time"
  },
  {
    title: "Attempts",
    field: "no_of_attempts"
  }
];

export const appointmentsTodayTable = [
  {
    title: "Rx",
    render: rowData => {
      return (
        <div
          onClick={e => {
            let param = rowData.brand + "-" + rowData.source + "-" + rowData.id;
            const oldCategories = ["SH", "HC", "OF"];
            if (oldCategories.includes(rowData.source)) {
              param = rowData.source_id;
            }
            const payload = {
              doctorId: store.getState().userReducer.hash_id,
              source: rowData?.source,
              id: rowData?.id,
              brand: rowData?.brand
            };
            payload["form"] = payload["source"];
            rxOpenedClevertap(payload);
            window.open(getRxWriterLink(param), "_self");
          }}
          style={{
            cursor: "pointer",
            padding: "10px 0",
            title: "Open Prescription"
          }}
        >
          <MdVisibility />
        </div>
      );
    }
  },
  {
    title: "Status",
    render: rowData => <div>{dataResource.statusListObj[rowData.status]}</div>
  },
  {
    title: "Confirmed",
    render: rowData => <div>{rowData.confirmed ? "Yes" : "-"}</div>
  },
  {
    title: "Patient ID",
    render: rowData => (
      <div style={{ whiteSpace: "nowrap" }}>
        {rowData.brand + "-" + rowData.source + "-" + rowData.id}
      </div>
    )
  },
  {
    title: "Date",
    render: rowData => {
      return (
        <div style={{ whiteSpace: "nowrap" }}>{rowData.appointment_date}</div>
      );
    }
  },
  {
    title: "Start Time",
    field: "from_time"
  },
  {
    title: "Patient Name",
    field: "patient_name",
    render: rowData => {
      return <div style={{ whiteSpace: "nowrap" }}>{rowData.patient_name}</div>;
    }
  },
  {
    title: "Concern",
    render: rowData => {
      return (
        <div>
          {rowData.category &&
            rowData.category
              .split(",")
              .map(c => dataResource.categoryMap[c])
              .join(", ")}
        </div>
      );
    }
  },
  {
    title: "Ask for Image",
    render: rowData => <AskImage rowData={rowData} />
  },
  {
    title: "Attempts",
    field: "no_of_attempts"
  }
];

function AskImage({ rowData }) {
  const [requested, setRequested] = useState(
    rowData.image_upload_requested === 1
  );

  const [done, setDone] = useState("");

  const handleClick = () => {
    setDone("requesting");
    axios
      .post(config.url.PRESCRIPTION_IMAGE_UPLOAD_URL, {
        id: rowData.id,
        category: rowData.source
      })
      .then(res => {
        setRequested(true);
        setDone("requested");
      })
      .catch(err => {
        console.log(err);
        alert("error occured while requesting image");
        setDone("error");
      });
  };

  return (
    <div style={{ display: "flex" }}>
      {rowData.image_uploaded ? (
        <div style={{ color: "green" }}>Uploaded</div>
      ) : (
        <button
          onClick={handleClick}
          disabled={requested}
          style={{ padding: "5px 8px" }}
        >
          {requested ? "Requested" : "Request"}
        </button>
      )}
      {done === "requesting" && (
        <div>
          <div>
            <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
          </div>
        </div>
      )}
    </div>
  );
}

const CONSTANTS = {
  DEFAULT_DATE_FORMAT: "YYYY-MM-DD",
  DEFAULT_DATE_TIME_FORMAT: "DD MMM YYYY hh:mm A",
  EMPTY_DATA_STRING: "-",
  TABLE_TITLE: "Audit Trail",
  TABLE_PAGE_SIZE: 10,
  SEARCH_BUTTON_TITLE: "Search"
};

export default CONSTANTS;

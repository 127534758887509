import React, { useMemo } from "react";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";

export default function Table1({
  title,
  cols,
  rows,
  actions,
  rowStyle,
  search
}) {
  // console.log("data for table 1");
  // console.log(useMemo(() => rows, [rows]));
  // console.log("columns for table1");
  // console.log(cols);
  return (
    <div id={title.replace(/\s/g, "").toLowerCase()}>
      <MaterialTable
        columns={cols}
        data={useMemo(() => rows, [rows])}
        //data={rows}
        title={title}
        options={{
          search: search ? search : false,
          exportButton: true,
          exportAllData: true,
          pageSize: 5,
          sorting: false,
          rowStyle: rowStyle,
          headerStyle: {
            backgroundColor: "#003459",
            color: "#FFF"
          },
          filtering: true,
          toolbarButtonAlignment: "left",
          searchFieldAlignment: "left"
        }}
        components={{
          Pagination: props => {
            return (
              <td>
                <table
                  style={{
                    position: "relative",
                    bottom: 0,
                    left: 0,
                    width: "100%"
                  }}
                >
                  <tbody>
                    <tr>
                      <TablePagination {...props} />
                    </tr>
                  </tbody>
                </table>
              </td>
            );
          }
        }}
        actions={actions}
        style={{ border: "1px solid #1b263b" }}
      />
    </div>
  );
}

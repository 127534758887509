import React, { Component } from "react";
import "./AppointmentDetails.scss";
import { Card, CardHeader } from "@material-ui/core";
import { store } from "../../../../reducers/Persist";
import { connect } from "react-redux";
import moment from "moment";
class AppointmentDetails extends Component {
  render() {
    return (
      <div>
        <div className="AppointmentDetails_title">Appointment Details</div>
        <div className="margin-30 AppointmentDetails">
          <div className="appointment-details-container">
            <div className="subheading">ID</div>
            <div className="val">
              {this.props.brand}-{this.props.form}-{this.props.id}
            </div>
            <div className="subheading">Date</div>
            <div className="val">
              {moment(this.props.prescriptionData.appointment_date).format(
                "MMMM DD, YYYY"
              )}
            </div>
            <div className="subheading">Slot</div>
            <div className="val">
              {this.props.prescriptionData.appointment_slot}
            </div>
            <div className="subheading">Follow up</div>
            <div className="val">
              <input
                type="number"
                className="follow-up-val"
                value={this.props.followup.followupDurationValue}
                onChange={e =>
                  store.dispatch({
                    type: "CHANGE_FOLLOWUP_DATA",
                    data: e.target.value,
                    key: "followupDurationValue"
                  })
                }
              />
              <select
                onChange={e => {
                  store.dispatch({
                    type: "CHANGE_FOLLOWUP_DATA",
                    data: e.target.value,
                    key: "followupDurationUnit"
                  });
                }}
              >
                <option value="select" selected disabled hidden>
                  {" "}
                  -Select-{" "}
                </option>
                <option
                  selected={this.props.followup.followupDurationUnit === "DAYS"}
                  value="DAYS"
                >
                  Days
                </option>
                <option
                  selected={
                    this.props.followup.followupDurationUnit === "WEEKS"
                  }
                  value="WEEKS"
                >
                  Weeks
                </option>
                <option
                  selected={
                    this.props.followup.followupDurationUnit === "MONTHS"
                  }
                  value="MONTHS"
                >
                  Months
                </option>
                <option
                  selected={
                    this.props.followup.followupDurationUnit === "YEARS"
                  }
                  value="YEARS"
                >
                  Years
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {
    id: store.getState().rxWriterReducer.id,
    brand: store.getState().rxWriterReducer.brand,
    form: store.getState().rxWriterReducer.form,
    prescriptionData: store.getState().rxWriterReducer.prescriptionData,
    followup: store.getState().rxWriterReducer.followup
  };
};
export default connect(mapStateToProps)(AppointmentDetails);

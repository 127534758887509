import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { store } from "../../../../reducers/Persist";
import "./Cancel.scss";

export default class Cancel extends Component {
  render() {
    return (
      <button
        type="submit"
        className="cancel_button"
        onClick={() => store.dispatch({ type: "CHANGE_TAB", data: 1 })}
      >
        Cancel
      </button>
    );
  }
}

import Chip from "@mui/material/Chip";
import React from "react";
import dataResource from "../../../../../../../constants/dataResource";

export default function CategoryChip({ category = "null" }) {
  return (
    <Chip
      style={{
        color: "#9A521C",
        backgroundColor: "rgba(254, 243, 234, 0.6)",
        border: "1px solid #9A521C"
      }}
      label={dataResource.categoryMap[category]}
    />
  );
}

import React from "react";
import { convertOOCTime } from "./helper/date-helper";

export default function OOCHours({ data }) {
  return (
    <>
      {data.map((item, index) => {
        return (
          <p key={index}>
            {convertOOCTime(item.fromDateTime)} -{" "}
            {convertOOCTime(item.toDateTime)}
          </p>
        );
      })}
    </>
  );
}

import React, { useMemo } from "react";
import MaterialTable from "material-table";

export default function Table4({
  title,
  cols,
  rows,
  pageSize,
  search,
  pagin,
  rowStyle,
  exportButton = true,
  disableHeaderStyle = false,
  filtering = false,
  actions = false,
  isLoading = false
}) {
  const headerStyle = disableHeaderStyle
    ? {}
    : { backgroundColor: "#003459", color: "#FFF" };
  return (
    <div id={title.replace(/\s/g, "").toLowerCase()}>
      <MaterialTable
        columns={cols}
        data={useMemo(() => rows, [rows])}
        title={title}
        options={{
          exportButton: exportButton,
          exportAllData: true,
          pageSize: pageSize ? pageSize : 1,
          paging: pagin === undefined ? true : false,
          search: search ? true : false,
          sorting: false,
          headerStyle,
          filtering,
          rowStyle
        }}
        isLoading={isLoading}
        actions={actions}
        style={{ margin: "20px 10px 10px" }}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import { useAsync } from "../../../hooks";
import adminService from "../../../services/adminService";
import EditableTable from "../../common/Tables/EditableTable";

export default function EditableDataTable({
  title,
  url,
  reqBody,
  cols,
  urlPost,
  pageSize
}) {
  const dispatch = useDispatch();
  const [fetchedData] = useAsync(url, reqBody);
  const [updatedData, setUpdatedData] = useState();
  useEffect(() => {
    setTimeout(
      () => adminService.sendData(urlPost, { table: updatedData, ...reqBody }),
      3000
    );
    setTimeout(
      () =>
        dispatch({
          type: "REFRESH"
        }),
      3000
    );
  }, [updatedData]);
  // const handleClick = () => {
  //   setTimeout(()=>adminService.sendData(urlPost, { table: updatedData, ...reqBody }),3000);
  //   setTimeout(
  //     () =>
  //       dispatch({
  //         type: "REFRESH"
  //       }),
  //     3000
  //   );
  // };

  return fetchedData ? (
    <div>
      {/* <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<SaveIcon />}
        onClick={handleClick}
      >
        Save
      </Button> */}
      <EditableTable
        title={title}
        cols={cols}
        rows={fetchedData}
        setUpdatedData={setUpdatedData}
        pageSize={pageSize}
      />
    </div>
  ) : null;
}

import React, { Component } from "react";
import "./styles.scss";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Check";

export default class CustomDropdownInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredSuggestions: [...this.props.suggestions],
      collections: [],
      customCollection: ""
    };
  }

  onAddCollection = evt => {
    const value = evt.target.value || "";
    if (value.length > 0) {
      const tempCollections = [
        ...this.state.collections,
        { value, custom: false }
      ];
      const tempFilteredSuggestions = [...this.state.filteredSuggestions];
      const index = tempFilteredSuggestions.indexOf(value);
      tempFilteredSuggestions.splice(index, 1);
      this.props.onChange(tempCollections.map(collection => collection.value));
      this.setState({
        collections: tempCollections,
        filteredSuggestions: tempFilteredSuggestions
      });
    }
    evt.target.selectedIndex = 0;
  };
  onAddCustomCollection = () => {
    const value = this.state.customCollection || "";
    if (value.length > 0) {
      const tempCollections = [
        ...this.state.collections,
        { value, custom: true }
      ];
      this.props.onChange(tempCollections.map(collection => collection.value));
      this.setState({
        collections: tempCollections,
        customCollection: ""
      });
    }
  };
  onRemoveCollection = index => {
    const tempCollections = [...this.state.collections];
    const collection = tempCollections.splice(index, 1)[0];
    const tempFilteredSuggestions = [...this.state.filteredSuggestions];
    if (!collection.custom) {
      tempFilteredSuggestions.push(collection.value);
    }
    this.props.onChange(tempCollections.map(collection => collection.value));
    this.setState({
      collections: tempCollections,
      filteredSuggestions: tempFilteredSuggestions
    });
  };
  handleKeyDown = evt => {
    const keyCode = evt.keyCode;
    if (keyCode === 13) {
      this.onAddCustomCollection();
    }
  };

  render() {
    let placeholder = this.props.placeholder;
    return (
      <div>
        <div className="custom-dropdown-input">
          <div
            className="collection-list-title"
            hidden={this.state.collections.length === 0}
          >
            {this.props.listTitle}
          </div>
          <ul className="collection-list">
            {this.state.collections.map((collection, index) => {
              return (
                <li key={index} className="collection-li">
                  {collection.value}
                  <button
                    type="button"
                    className="collection-remove-button"
                    onClick={evt => this.onRemoveCollection(index)}
                  >
                    X
                  </button>
                </li>
              );
            })}
          </ul>
          <div className="input-wrapper">
            <input
              className="form-control instruction-input"
              placeholder={placeholder}
              value={this.state.customCollection}
              onChange={evt =>
                this.setState({ customCollection: evt.target.value })
              }
              onKeyDown={this.handleKeyDown}
              style={{ boxShadow: "none" }}
            />
            <IconButton
              color=""
              aria-label="Go"
              onClick={this.onAddCustomCollection}
              className="add-button"
              disabled={!this.state.customCollection}
            >
              <AddIcon />
            </IconButton>
            <select
              onChange={this.onAddCollection}
              className="form-control minimal"
              style={{ boxShadow: "none" }}
            >
              <option selected disabled></option>
              {this.state.filteredSuggestions.map((item, index) => {
                return <option key={index}>{item}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

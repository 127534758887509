import React, { useEffect, useState, useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import CONFIG_CONSTANTS from "../../../../constants/apiList";
import { useSelector } from "react-redux";
import moment from "moment";
import Axios from "axios";
import PCI from "../../DashboardPage/PCI";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { store } from "../../../../reducers/Persist";
import ActiveStatus from "../../AdminPage/ActiveStatus";
import Reviews from "./ReviewSummary";
import YourPerformance from "../../DashboardPage/YourPerformance";
import "../AdminPage.scss";
import AppointmentsPending from "../AppointmentsPending";
import AppointmentsPriority from "../AppointmentsPriority";
import AppointmentsSummary from "./AppointmentsSummary"; // V2
import { Comp } from "../Comp";
import "../DashboardPage.scss";
import Overview from "../Overview";
import PendingAppointments from "../PendingAppointments";
import DoctorCollapsibleMenu from "../../CollapsibleMenu";
import { toast } from "react-toastify";
import Header from "../Header";
import styles from "./dashboard.module.scss";
import AuditLogsView from "./AuditLogsView/index";
import { CurrentAppointmentContext } from "../CurrentAppointment/withSocketHOC";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";
import { useDoctorStatusChangeSocket } from "../../../hooks/useDoctorStatusChangeSocket";
import { getRxWriterLink } from "../../../../helpers/rx-writer.helpers";
const getColor = row => {
  let { appointment_date, from_time, to_time, status, confirmed, cancelled } =
    row;
  if (
    appointment_date === undefined ||
    from_time === undefined ||
    to_time === undefined ||
    status === undefined
  )
    return {};
  if (cancelled === 1) return { backgroundColor: "#D3D3D3" };
  let st = status.toUpperCase();
  let endStates = ["T", "C", "NI", "NS", "OP", "NA", "CN", "OTC", "RF"];
  let currentTimeStamp = ~~(+new Date() / 1000);
  let fromTimeStamp = moment(appointment_date + " " + from_time).unix();
  let toTimeStamp = moment(appointment_date + " " + to_time).unix();
  if (endStates.includes(st)) {
    return { backgroundColor: "#33FF4F" };
  }
  if (
    currentTimeStamp >= fromTimeStamp &&
    currentTimeStamp <= toTimeStamp &&
    !endStates.includes(st)
  ) {
    return { backgroundColor: "#FFA833" };
  }
  if (currentTimeStamp > toTimeStamp && !endStates.includes(st)) {
    return { backgroundColor: "#e47d7d" };
  }
  if (confirmed === 1) return { backgroundColor: "#ffd399" };
};
const getColor2 = row => {
  let { appointment_date, from_time, to_time, status, updated_dttm } = row;
  if (
    appointment_date === undefined ||
    from_time === undefined ||
    to_time === undefined ||
    status === undefined ||
    updated_dttm === undefined
  )
    return {};
  let today = new Date();
  if (moment(today).format("YYYY-MM-DD") === updated_dttm.slice(0, 10)) {
    return { backgroundColor: "#99FCA6" };
  }
  let st = status.toUpperCase();
  let endStates = ["T", "C", "NI", "NS", "OP", "NA", "CN"];
  let currentTimeStamp = ~~(+new Date() / 1000);
  let fromTimeStamp = moment(appointment_date + " " + from_time).unix();
  let toTimeStamp = moment(appointment_date + " " + to_time).unix();
  if (endStates.includes(st)) {
    return { backgroundColor: "#33FF4F" };
  }
  if (
    currentTimeStamp >= fromTimeStamp &&
    currentTimeStamp <= toTimeStamp &&
    !endStates.includes(st)
  ) {
    return { backgroundColor: "#FFA833" };
  }
  if (currentTimeStamp > toTimeStamp && !endStates.includes(st)) {
    return { backgroundColor: "#e47d7d" };
  }
};

const dateContext = React.createContext();

const PCITable = ({ brand }) => {
  const today = moment().format("YYYY-MM-DD");
  const weekStart = moment().startOf("week").format("YYYY-MM-DD");
  const [date, setDate] = useState({
    startDate: weekStart,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="pciTable">
        <DateGroup />
        <PCI
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          brand={brand}
        />
      </div>
    </dateContext.Provider>
  );
};

const AppointmentsSummaryView = ({
  setCountToday,
  setCountPending,
  setCountPriority
}) => {
  const [date, setDate] = useState(() => {
    let today = new Date();
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
    return {
      startDate: today,
      endDate: today
    };
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <AppointmentsSummary
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          setCountToday={setCountToday}
          setCountPending={setCountPending}
          setCountPriority={setCountPriority}
        />
      </div>
    </dateContext.Provider>
  );
};

const AppointmentsPendingView = ({ setCountPending }) => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  const [date, setDate] = useState({
    startDate: today,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup />
        <AppointmentsPending
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          rowStyle={row => getColor2(row)}
          setCountPending={setCountPending}
        />
      </div>
    </dateContext.Provider>
  );
};

const AppointmentsPriorityView = ({ setCountPriority }) => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  const [date, setDate] = useState({
    startDate: today,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup />
        <AppointmentsPriority
          reqBody={{ from_date: date.startDate, to_date: date.endDate }}
          rowStyle={row => getColor(row)}
          setCountPriority={setCountPriority}
        />
      </div>
    </dateContext.Provider>
  );
};

const YourPerformanceView = () => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

  const [date, setDate] = useState({
    startDate: today,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="newDashboard">
        <DateGroup />
        <YourPerformance
          reqBody={{
            from_date: date.startDate,
            to_date: date.endDate,
            doctor_id: store.getState().userReducer.hash_id
          }}
        />
      </div>
    </dateContext.Provider>
  );
};

const ReviewsTable = () => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  let firstDayOfMonth = today.slice(0, -2) + "01";
  const [date, setDate] = useState({
    startDate: firstDayOfMonth,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <div className="review_table--container">
        <div className="review_table--date-group">
          <DateGroup />
        </div>
        <Reviews
          reqBody={{
            from_date: date.startDate,
            to_date: date.endDate,
            doctor_id: store.getState().userReducer.hash_id
          }}
        />
      </div>
    </dateContext.Provider>
  );
};

const AuditLogsViewWrapper = () => {
  let today = new Date();
  today =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  let firstDayOfMonth = today.slice(0, -2) + "01";
  const [date, setDate] = useState({
    startDate: firstDayOfMonth,
    endDate: today
  });

  return (
    <dateContext.Provider value={{ date, setDate }}>
      <AuditLogsView reqRange={date} />
    </dateContext.Provider>
  );
};

function DashboardPage2({ setIsCheckedOut }) {
  const userId = useSelector(state => state.userReducer?.user_id);
  const [_, __, doctorId] = useContext(CurrentAppointmentContext); // doctorId as third value in array
  const jwtToken = useMemo(() => localStorage.getItem("jwt") ?? "", [doctorId]);
  const [page, setPage] = useState(null);
  const [doctorStatus, setDoctorStatus] = useState(0);
  const [stepAwayAvailable, setStepAwayAvailable] = useState(false);
  const [stepAwayTimeLeft, setStepAwayTimeLeft] = useState(0);
  const [durationOptions, setDurationOptions] = useState([]);
  const [attemptsLeft, setAttemptsLeft] = useState(0);
  const [doctorConfig, setDoctorConfig] = useState({
    immediate: false,
    standby: false,
    scheduled: false
  });
  const location = useLocation();
  const [isInitialPageLoading, setInitialPageLoading] = useState(true);
  const { doctorStatusChangeData } = useDoctorStatusChangeSocket(
    userId,
    jwtToken,
    doctorId
  );
  useEffect(() => {
    if (doctorStatusChangeData) {
      setDoctorStatus(doctorStatusChangeData?.status);
      if (doctorStatusChangeData?.status === 7) setIsCheckedOut(true);
    }
  }, [doctorStatusChangeData]);
  useEffect(() => {
    const finalLocation = location.pathname.split("/dashboard/")[1];
    setPage(finalLocation || "");
    setInitialPageLoading(false);
  }, [location.pathname]);
  //Send request to an api every minute
  useEffect(() => {
    let today = new Date();
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

    if (localStorage.getItem("activityDay") !== today) {
      localStorage.setItem("activityDay", today);
      localStorage.removeItem("lastRequestTime");
    }
    const getDoctorStatus = () => {
      Axios.post(CONFIG_CONSTANTS.url.DOCTOR_LOGIN_DETAILS_API, {}).then(
        res => {
          const doctorStatus = res?.data?.data?.doctorStatus || 0;
          setDoctorStatus(doctorStatus);
          setStepAwayAvailable(res?.data?.data?.stepAwayAvailable);
          setDurationOptions(res?.data?.data?.stepAwayOptions);
          setStepAwayTimeLeft(res?.data?.data?.stepAwayTimeLeft);
          setDoctorConfig(res?.data?.data?.doctorConfig || {});
          setAttemptsLeft(res?.data?.data?.attemptsLeft);
          if (doctorStatus === 7) setIsCheckedOut(true);
        }
      );
    };
    getDoctorStatus();
  }, []);
  //End sending request every minute

  const [rerender, setRerender] = useState(true);
  useEffect(() => {
    window.refreshRowStatus = setInterval(() => setRerender(!rerender), 30000);
    return () => clearInterval(window.refreshRowStatus);
  });

  const renderSwitch = (setCountToday, setCountPending, setCountPriority) => {
    switch (page) {
      case "appointments/summary":
        return (
          <AppointmentsSummaryView
            setCountToday={setCountToday}
            setCountPending={setCountPending}
            setCountPriority={setCountPriority}
          />
        );

      case "appointments/pending":
        return <AppointmentsPendingView setCountPending={setCountPending} />;

      case "appointments/priority":
        return <AppointmentsPriorityView setCountPriority={setCountPriority} />;

      case "yourPerformance": {
        return <YourPerformanceView />;
      }

      case "pci":
        return <PCITable brand="both" />;

      case "reviews":
        return <ReviewsTable />;

      case "onlineStatus": {
        return <ActiveStatus />;
      }

      case "pendingAppointments":
        return <PendingAppointments />;

      case "auditLogs":
        return <AuditLogsViewWrapper />;

      default:
        return <></>;
    }
  };

  const handleLogoutClick = async () => {
    addToGTM({
      event: GTM_EVENTS?.CHECK_OUT,
      checkoutData: {
        checkedOutBy: "doctor"
      }
    });
    addToGTM({
      event: GTM_EVENTS?.LOGOUT
    });
    localStorage.removeItem("jwt");
    await Axios.get(CONFIG_CONSTANTS.AUTH.LOGOUT, { withCredentials: true });
    store.dispatch({ type: "CLEAR" });
    try {
      await Axios.post(CONFIG_CONSTANTS.url.SAVE_CHECKIN_CHECKOUT, {
        record_type: "co"
      });
      setIsCheckedOut(true);
    } catch (err) {
      console.log("ERROR WHILE CHECKOUT", err);
      toast.error("Error while logout");
    }
    window.location.href = "/login";
  };

  const handleCheckoutClick = async () => {
    addToGTM({
      event: GTM_EVENTS?.CHECK_OUT,
      checkoutData: {
        checkedOutBy: "doctor"
      }
    });
    Axios.post(CONFIG_CONSTANTS.url.SAVE_CHECKIN_CHECKOUT, {
      record_type: "co"
    })
      .then(res => {
        setIsCheckedOut(true);
      })
      .catch(err => {
        console.log(err);
        toast.error("Something went wrong while trying to checkout");
      });
  };
  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          height: "100vh"
        }}
      >
        <DoctorCollapsibleMenu
          page={page}
          doctorStatus={doctorStatus}
          doctorConfig={doctorConfig}
          checkoutOnClick={handleCheckoutClick}
          logoutOnClick={handleLogoutClick}
          stepAwayAvailable={stepAwayAvailable}
          stepAwayTimeLeft={stepAwayTimeLeft}
          durationOptions={durationOptions}
          attemptsLeft={attemptsLeft}
        />
        {!isInitialPageLoading && page && page.includes("appointments") && (
          <Comp renderSwitch={renderSwitch} page={page} />
        )}
        {!isInitialPageLoading && (!page || !page.includes("appointments")) && (
          <div style={{ flexGrow: "1", backgroundColor: "#f6f8fb" }}>
            {!page ? <Overview doctorId={doctorId} /> : renderSwitch()}
          </div>
        )}
      </div>
    </>
  );
}

export const IdGroup = () => {
  const [id, setId] = useState("");
  const handleSubmit = () => {
    window.open(getRxWriterLink(id), "_self");
  };
  const handleChange = e => {
    e.preventDefault();
    setId(e.target.value);
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        width: "245px",
        justifyContent: "space-between",
        margin: "5px 5px 5px 1px"
      }}
    >
      <input
        type="text"
        placeholder="Enter Id"
        className="input2"
        id="id"
        value={id}
        onChange={handleChange}
      />
      <br />
      <br />
      <button
        type="submit"
        disabled={!id}
        className={"button button--primary" + (id ? "" : " disabled")}
      >
        Go to App
      </button>
    </form>
  );
};

export const DateGroup = () => {
  const { date, setDate } = useContext(dateContext);

  const [dates, setDates] = useState({
    start: date.startDate,
    end: date.endDate
  });

  const handleChange = (e, type) => {
    if (type === "start") {
      setDates({ ...dates, start: e.target.value });
    }
    if (type === "end") {
      setDates({ ...dates, end: e.target.value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDate({ startDate: dates.start, endDate: dates.end });
  };

  return (
    <div className={styles["left-section"]}>
      <div className={styles["date-range-section"]}>
        <div className={styles["date-group"]}>
          <div className={styles["input-label"]}>From</div>
          <input
            type="date"
            className={styles["date-input"]}
            value={dates.start}
            onChange={e => handleChange(e, "start")}
          />
        </div>
        <div className={styles["date-group"]}>
          <div className={styles["input-label"]}>To</div>
          <input
            type="date"
            className={styles["date-input"]}
            value={dates.end}
            onChange={e => handleChange(e, "end")}
          />
        </div>
      </div>
      <div className={styles["quick-option-section"]}>
        <button className={styles["search-button"]} onClick={handleSubmit}>
          Go
        </button>
      </div>
    </div>
  );
};
export default React.memo(DashboardPage2);

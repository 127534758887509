import styled from "styled-components";

export const OptionButtonWrapper = styled.button`
  cursor: pointer;
  color: ${props => props.primaryColor ?? "black"};
  border: 1px solid ${props => props.primaryColor ?? "black"};
  background-color: ${props => props.secondaryColor ?? "white"};
  border-radius: 8px;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  column-gap: 8px;
  width: 100%;
  min-width: 175px;
  position: relative;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
  }

  svg {
    width: 18px;
    height: 18px;
  }

  @media screen and (max-width: 1024px) {
    svg {
      width: 10px;
      height: 10px;
    }

    padding: 5px 8px;
    font-size: 13px;
  }
`;
export const LeaveInfoWrapper = styled.div`
  max-width: 560px;
  width: max-content;

  position: absolute;
  top: 0%;
  left: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border: 1px solid #dde0e4;
  border-radius: 6px;
  padding: 12px 16px;

  color: red;
  background: #fff;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.15),
    0px 10px 15px -3px rgba(0, 0, 0, 0.3);
  .info {
    font-size: 20px;
    color: #000;
    .hightlight {
      color: rgb(55, 159, 142);
      font-weight: bold;
      font-size: 24px;
    }
  }
  .note {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    text-align: left;
    .hightlight {
      color: #da7000;
      font-weight: bold;
    }
  }
`;

export const AttemptLeftWrapper = styled.div`
  background-color: #3690db;
  font-family: Inter;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionWrapper = styled.form`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000;
  overflow: hidden;

  button {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #e2e8f0;

    input {
      width: 10px;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border: 2px solid var(--gray-200, #e2e8f0);
      margin-left: 70px;
    }
  }
  button:last-child {
    border-bottom: none;
  }
  button:hover {
    background-color: #f7fafc;
  }
`;

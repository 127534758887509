import { combineReducers } from "redux";
import appointmentReducer from "./appointmentReducer";
import userReducer from "./userReducer";
import imageTimelineReducer from "./imageTimelineReducer";
import footerReducer from "./footerReducer";
import rxWriterReducer from "./rxWriterReducer";
import observationReducer from "./observationReducer";
import prescription_orderSelect from "./prescription_orderSelect";
import recommendedProducts from "./recommendedProducts";
import orderedProducts from "./orderedProducts";
import observationCheckReducer from "./observationCheckReducer";
import recommendationBuilderReducer from "./recommendationBuilderReducer";
import recommendationRulesReducer from "./recommendationRulesReducer";
export default combineReducers({
  appointmentReducer,
  userReducer,
  imageTimelineReducer,
  footerReducer,
  rxWriterReducer,
  observationReducer,
  prescription_orderSelect,
  recommendedProducts,
  orderedProducts,
  observationCheckReducer,
  recommendationBuilderReducer,
  recommendationRulesReducer
});

import React, { Component } from "react";
import "./RecommendedProducts.scss";
import RecommendedTable from "./RecommendedTable";
export default class RecommendedProducts extends Component {
  render() {
    return (
      <div style={{ marginTop: "60px" }}>
        <div className="RecommendedProducts__title">Recommended Products</div>
        <div className="RecommendedProducts__subtitle">
          Patient got these recommendations from the information they shared
        </div>
        <div className="margin-30 RecommendedProducts">
          <RecommendedTable />
        </div>
      </div>
    );
  }
}

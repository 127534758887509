import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.module.scss";
import Header from "./Header";
import OverviewSection from "./OverviewSection";
import CONFIG_CONSTANTS from "../../../../constants/apiList";
import AssignmentMetricsOverviewSection from "./PendingMetricsOverviewSection";
import DoctorHoursSection from "./doctorHoursSection";
import Axios from "axios";
import moment from "moment";
import CONSTANTS from "./constants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  CurrentAppointmentContext,
  DEFAULT_DOCTOR_ID
} from "../CurrentAppointment/withSocketHOC";
import AllDoctorsOverviewSection from "./AllDoctorsOverviewSection";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";

const Overview = ({ doctorId, isAdmin = false, setDoctorComboDisabled }) => {
  const [totalList, setTotalList] = useState([]);
  const [instantList, setInstantList] = useState([]);
  const [scheduledList, setScheduledList] = useState([]);
  const [LSDList, setLSDList] = useState([]);
  const [activityLogList, setActivityLogList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [normalViewLoading, setNormalViewLoading] = useState(false);
  const [tableViewLoading, setTableViewLoading] = useState(false);

  const [fromDate, setFromDate] = useState(
    moment().format(CONSTANTS?.DATE_FORMATS?.DEFAULT_DATE_FORMAT)
  );
  const [toDate, setToDate] = useState(
    moment().format(CONSTANTS?.DATE_FORMATS?.DEFAULT_DATE_FORMAT)
  );
  const [todaySelected, setTodaySelected] = useState(true);
  const [nowSelected, setNowSelected] = useState(false);
  const [brand, setBrand] = useState(CONSTANTS?.DEFAULT_BRAND_VALUE);
  const [allDoctorsOverviewData, setAllDoctorsOverviewData] = useState([]);
  const [assignmentMetrics, setAssignmentMetricsData] = useState([]);

  const onTodaySelected = () => {
    setTodaySelected(true);
    setNowSelected(false);
    const todayDate = moment().format(
      CONSTANTS?.DATE_FORMATS?.DEFAULT_DATE_FORMAT
    );
    setFromDate(todayDate);
    setToDate(todayDate);
  };
  const onNowSelected = () => {
    setNowSelected(true);
    setTodaySelected(false);
  };
  const onBrandChange = evt => {
    setBrand(evt.target.value);
  };
  const getVariant = value => {
    if (value >= 90) return CONSTANTS?.VARIANTS?.SUCCESS;
    else if (value > 65) return CONSTANTS?.VARIANTS?.WARNING;
    else return CONSTANTS?.VARIANTS?.DANGER;
  };
  const resetToDefault = () => {
    setTotalList([]);
    setInstantList([]);
    setScheduledList([]);
    setPendingList([]);
  };
  const onDateUpdate = () => {
    const todayDate = moment().format(
      CONSTANTS?.DATE_FORMATS?.DEFAULT_DATE_FORMAT
    );
    const enableToday = fromDate === todayDate && toDate === todayDate;
    setTodaySelected(enableToday);
    setNowSelected(false);
  };

  const fetchCountOverview = async () => {
    fetchAllDoctorsOverview();
    fetchPendingMetrics();
    if (doctorId === DEFAULT_DOCTOR_ID) return;
    const applyBrand = brand && brand.toLowerCase() !== "all";
    setNormalViewLoading(true);
    try {
      const fromMoment = moment(
        fromDate,
        CONSTANTS?.DATE_FORMATS?.DEFAULT_DATE_FORMAT
      );
      const toMoment = moment(
        toDate,
        CONSTANTS?.DATE_FORMATS?.DEFAULT_DATE_FORMAT
      );
      if (toMoment.diff(fromMoment, "days") > 7) {
        toast.error("Please select date range less than 7 days", {
          position: "top-right"
        });
        return;
      }
      const requestData = {
        metricType: nowSelected ? "now" : "dateRange",
        doctorId,
        dateRange: {
          from: fromDate,
          to: toDate
        }
      };
      if (applyBrand) requestData.brand = brand;
      const { data } = await Axios.post(
        CONFIG_CONSTANTS.url.COUNT_OVERVIEW,
        requestData
      );
      addToGTM({
        event: GTM_EVENTS?.OVERVIEW_VIEWED,
        overviewData: { ...data }
      });
      if (data?.cacheTimeStamp) {
        toast.info("Last Updated at:  " + data?.cacheTimeStamp, {
          position: "top-right",
          autoClose: 5000
        });
      }
      if (!data?.sections?.length) {
        resetToDefault();
        return;
      }
      for (const section of data.sections) {
        const {
          type = "",
          addressedAppts = 0,
          completedAppts = 0,
          completionRate = 0,
          addressedRate = 0,
          averagePci = 0,
          averageDuration = 0,
          pending = 0,
          totalAppt = 0,
          valid = 0,
          validCompletionRate = 0,
          shortCalls = 0,
          connectedConversed = 0
        } = section;
        const list = [
          { label: "Total", value: totalAppt },
          { label: "Valid Total", value: valid, hide: !isAdmin },
          { label: "Addressed", value: addressedAppts },
          { label: "Completed", value: completedAppts },
          {
            label: "Completion",
            value: `${completionRate}%`,
            variant: getVariant(completionRate)
          },
          {
            label: "Connected Conversed",
            value: `${connectedConversed}%`,
            variant: getVariant(connectedConversed)
          },
          {
            label: "Valid Completion",
            value: `${validCompletionRate}%`,
            variant: getVariant(validCompletionRate),
            hide: !isAdmin
          },
          {
            label: "Addressal",
            value: `${addressedRate}%`,
            variant: getVariant(addressedRate)
          },
          { label: "PCI", value: averagePci || "-" },
          { label: "Avg Duration", value: averageDuration || "-" },
          { label: "Short Calls", value: shortCalls || 0, hide: !isAdmin }
        ];
        if (type === CONSTANTS?.SECIION_TYPES?.ALL) {
          setTotalList([
            ...list,
            {
              label: "Active Hours",
              value: `${data?.activeHoursOverview?.actualHoursDuration}/${data?.activeHoursOverview?.dutyHoursDuration}`
            }
          ]);
          setPendingList([
            { label: "Pending", value: pending },
            { label: "Addressed", value: addressedAppts },
            { label: "Avg Duration", value: averageDuration || "-" }
          ]);
        } else if (type === CONSTANTS?.SECIION_TYPES?.IA) {
          setInstantList(list);
        } else if (type === CONSTANTS?.SECIION_TYPES?.S) {
          setScheduledList(list);
        } else if (type === CONSTANTS?.SECIION_TYPES?.LSD) {
          setLSDList(list);
        }
      }
      const {
        activeHours,
        availabilityPercentage,
        inactiveDuration,
        stepAwayTotalDuration,
        stepAwayUsedDuration,
        totalHours
      } = data?.activityLogSection || {};
      const tempActivityLogList = [
        { label: "Total Hours", value: totalHours },
        { label: "Active Hours", value: activeHours },
        {
          label: "Step Away",
          value: `${stepAwayUsedDuration}/${stepAwayTotalDuration}`
        },
        { label: "Inactive", value: inactiveDuration },
        {
          label: "Availability",
          value: `${availabilityPercentage}%`,
          variant: getVariant(availabilityPercentage)
        }
      ];
      setActivityLogList(tempActivityLogList);
    } catch (err) {
      toast.error(err?.message ?? "Something went wrong");
      console.log("ERROR IN fetchCountOverview", err);
    } finally {
      setNormalViewLoading(false);
    }
  };

  const fetchAllDoctorsOverview = async () => {
    if (brand?.toLowerCase() === "all" || !brand) return;
    setTableViewLoading(true);
    try {
      const response = await Axios.post(
        CONFIG_CONSTANTS.url.ALL_DOCTORS_OVERVIEW_DETAILS,
        {
          fromDate,
          toDate,
          brand
        }
      );
      setAllDoctorsOverviewData(response.data?.data?.overviewList);
    } catch (err) {
      toast.error(err?.message ?? "Something went wrong");
      console.log("ERROR IN fetchAllDoctorsOverview", err);
    } finally {
      setTableViewLoading(false);
    }
  };

  const fetchPendingMetrics = async () => {
    if (brand?.toLowerCase() === "all" || !brand) return;
    setTableViewLoading(true);
    try {
      const response = await Axios.post(
        CONFIG_CONSTANTS.url.ASSIGNMENT_METRICS,
        {
          metricType: nowSelected ? "now" : "dateRange",
          doctorId,
          dateRange: {
            from: fromDate,
            to: toDate
          },
          brand
        }
      );
      setAssignmentMetricsData(response.data);
    } catch (err) {
      toast.error(err?.message ?? "Something went wrong");
      console.log("ERROR IN fetchPendingMetrics", err);
    } finally {
      setTableViewLoading(false);
    }
  };
  useEffect(() => {
    fetchCountOverview();
  }, [brand, doctorId]);
  useEffect(() => {
    onDateUpdate();
  }, [fromDate, toDate]);
  useEffect(() => {
    if (isAdmin) {
      if (tabIndex !== 0) {
        setDoctorComboDisabled(true);
      } else {
        setDoctorComboDisabled(false);
      }

      if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3) {
        if (brand === CONSTANTS.BRANDS[0].value) {
          setBrand(CONSTANTS.BRANDS[1].value);
        }
      }
    }
  }, [tabIndex]);

  const isLoading = () => {
    if (tabIndex === 0 && normalViewLoading) return true;
    if (tabIndex === 1 && tableViewLoading) return true;
    return false;
  };

  if (isLoading()) return <Loader />;

  return (
    <div className={styles["overview"]}>
      <Header
        state={{
          fromDate,
          toDate,
          todaySelected,
          nowSelected,
          brandState: brand
        }}
        actions={{
          setFromDate,
          setToDate,
          onNowSelected,
          onTodaySelected,
          fetchCountOverview,
          onBrandChange
        }}
        isAllBrandAllowed={tabIndex === 0}
        isAdmin={isAdmin}
      />
      {isAdmin && (
        <Tabs value={tabIndex} onChange={(_, value) => setTabIndex(value)}>
          <Tab label="Normal View" />
          <Tab label="Table View" />
          <Tab label="Assignment Metrics View" />
        </Tabs>
      )}
      {(!isAdmin || tabIndex === 0) && (
        <>
          <OverviewSection
            state={{
              ...CONSTANTS?.SECTIONS?.TOTAL,
              summaryList: totalList
            }}
          />
          <OverviewSection
            state={{
              ...CONSTANTS?.SECTIONS?.INSTANT,
              summaryList: instantList
            }}
          />
          <OverviewSection
            state={{
              ...CONSTANTS?.SECTIONS?.SCHEDULED,
              summaryList: scheduledList
            }}
          />
          <OverviewSection
            state={{
              ...CONSTANTS?.SECTIONS?.LSD,
              summaryList: LSDList
            }}
          />
          <OverviewSection
            state={{
              ...CONSTANTS?.SECTIONS?.ACTIVITY_LOG,
              summaryList: activityLogList
            }}
          />
          <OverviewSection
            state={{
              ...CONSTANTS?.SECTIONS?.PENDING,
              summaryList: pendingList
            }}
          />
        </>
      )}
      {isAdmin && tabIndex === 1 && (
        <AllDoctorsOverviewSection responseData={allDoctorsOverviewData} />
      )}
      {isAdmin && tabIndex === 2 && (
        <AssignmentMetricsOverviewSection responseData={assignmentMetrics} />
      )}
      {isAdmin && tabIndex === 3 && (
        <DoctorHoursSection responseData={assignmentMetrics} />
      )}
    </div>
  );
};

export default Overview;

export const customRoles = ["coach"];

export const roles = {
  ADMIN: "admin",
  DOCTOR: "doctor",
  ASSISTANT: "assistant",
  EXTERNAL_RESCHEDULE: "external_reschedule",
  EXTERNAL_VIEW_ONLY: "external_view_only",
  SUPER_ADMIN: "super_admin",
  AUDITOR: "auditor",
  COACH: "coach"
};

import styled from "styled-components";

export const AuditLogsViewContainer = styled.div`
  margin: 20px 0 0 10px;

  .MuiTableRow-root.MuiTableRow-head {
    th:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    th:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  .MuiTableHead-root:after {
    content: "";
    display: block;
    height: 0.5em;
    width: 100%;
    background: transparent;
  }

  .MuiTableBody-root tr {
    td:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    td:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-bottom: 15px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: baseline;
  span {
    color: rgba(34, 84, 138, 1);
    font-weight: 600;
    font-size: 1rem;
    margin-right: 15px;
  }
`;

export const TableTitle = styled.span`
  color: rgba(34, 84, 138, 1);
  font-weight: 600;
  font-size: 1.5rem;
`;

import moment from "moment";
import React from "react";
// import dataResource from "../../../constants/dataResource";
import dataResource from "../../../constants/dataResource";
import LanguageEditor from "./LanguageEditor";
import { MarkIrrelevance } from "./Reviews";
const statusListObj = dataResource.statusListObj;
const getCodPending = row => {
  const NmR = row.cop + row.cc;
  const DnR =
    row.cs +
    row.cr +
    row.cb +
    row.cso +
    row.cnr +
    row.crs +
    row.cd +
    row.ccb +
    row.cop +
    row.cc +
    row.cco;
  const returnVal = ((1 - NmR / DnR) * 100).toFixed(2);
  return returnVal;
};

const getPrepaidPending = row => {
  const NmR = row.pop + row.pc + row.rop + row.rc;
  const DnR =
    row.ps +
    row.pr +
    row.pb +
    row.pso +
    row.pnr +
    row.prs +
    row.pd +
    row.pcb +
    row.pop +
    row.pc +
    row.pco +
    row.rs +
    row.ror +
    row.rb +
    row.rso +
    row.rnr +
    row.rrs +
    row.rd +
    row.rcb +
    row.rop +
    row.rc +
    row.rco;
  const returnVal = ((1 - NmR / DnR) * 100).toFixed(2);
  return returnVal;
};

const getPerCompleted = datapt => {
  const NmR = datapt.Completed * 100;
  const DnR =
    datapt.allRows - datapt.NI - datapt.NS - datapt.Testing - datapt.Ordered;
  const returnVal = (NmR / DnR).toFixed(2);
  return returnVal;
};

const getPerValid = datapt => {
  const NmR =
    (datapt.allRows -
      datapt.NI -
      datapt.NS -
      datapt.Testing -
      datapt.NI -
      datapt.LANG -
      datapt.WN -
      datapt.Reap) *
    100;
  const DnR = datapt.allRows;
  const returnVal = (NmR / DnR).toFixed(2);
  return returnVal;
};

const colMapper = col => {
  return {
    title: col,
    field: col
  };
};

export const hoursCols2 = [
  {
    title: "id",
    field: "id",
    editable: "never"
  },
  {
    title: "doctor_name",
    field: "doctor_id"
  },
  {
    title: "doctor_id",
    field: "doctor_id"
  },

  {
    title: "date_time_from",
    field: "date_time_from",
    editComponent: props => (
      <input
        type="datetime-local"
        value={props.value}
        onChange={e =>
          props.onChange(moment(e.target.value).format("YYYY-MM-DD[T]HH:mm:ss"))
        }
      />
    )
  },
  {
    title: "date_time_to",
    field: "date_time_to",
    editComponent: props => (
      <input
        type="datetime-local"
        value={props.value}
        onChange={e =>
          props.onChange(moment(e.target.value).format("YYYY-MM-DD[T]HH:mm:ss"))
        }
      />
    )
  },
  {
    title: "type_of_ooc",
    field: "typeOfOoc",
    lookup: {
      1: "Active",
      2: "Absent",
      3: "Leave"
    },
    validate: rowData =>
      !rowData.typeOfOoc
        ? { isValid: false, helperText: "Type of ooc cannot be empty" }
        : true
  },
  {
    title: "remarks",
    field: "remarks"
  }
];

export const doctorCols = [
  "doctor_id",
  "doctor_name",
  "medical_id",
  "doctor_qualifications",
  "gender",
  "is_hc",
  "is_sh",
  "weight",
  "skin",
  "is_men",
  "is_women",
  "active",
  "uti",
  "pcos",
  "beard",
  "only_order",
  "paid",
  "doctor_email",
  "doctor_phone",
  "specialization",
  "experience",
  "app",
  "web_app",
  "doctor_status",
  "immediate",
  "scheduled",
  "standby",
  "pending"
]
  .map(col => {
    return {
      title: col,
      field: col
    };
  })
  .concat([
    {
      title: "description",
      field: "description",
      cellStyle: { minWidth: "600px", wordBreak: "break-all" }
    },
    {
      title: "doctor_img",
      field: "doctor_img",
      cellStyle: { minWidth: "300px", wordBreak: "break-all" }
    },
    {
      title: "sign_image",
      field: "image_url",
      editComponent: () => {
        function encodeImageFileAsURL(e) {
          if (e.target.files.length) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onloadend = function () {
              let base64Img = reader.result.slice(22);
              localStorage.setItem("sign_image", base64Img);
            };
            reader.readAsDataURL(file);
          }
        }
        return (
          <input
            type="file"
            accept="image/png"
            onChange={e => encodeImageFileAsURL(e)}
          />
        );
      },
      render: rowData => {
        if (rowData.image_url)
          return (
            <img
              src={rowData.image_url}
              style={{ width: "100px", height: "100px" }}
              alt="error"
            />
          );
        else if (rowData.sign_image) {
          return (
            <img
              src={"data:image/png;base64," + rowData.sign_image}
              style={{ width: "100px", height: "100px" }}
              alt="error"
            />
          );
        }
        return <input type="file" disabled />;
      }
    },
    {
      title: "WAPhone",
      field: "waPhone"
    }
  ]);

export const hoursCols = [
  {
    title: "doctor_name",
    field: "doctor_id"
  },
  {
    title: "doctor_id",
    field: "doctor_id"
  },
  {
    title: "brand",
    field: "brand",
    lookup: {
      MM: "MM",
      BW: "BW"
    }
  },
  {
    title: "weekday",
    field: "weekday",
    lookup: {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday"
    }
  },
  {
    title: "from_time",
    field: "from_time",
    editComponent: props => (
      <input
        type="time"
        value={props.value}
        onChange={e => props.onChange(e.target.value + ":00")}
      />
    )
  },
  {
    title: "to_time",
    field: "to_time",
    editComponent: props => (
      <input
        type="time"
        value={props.value}
        onChange={e => props.onChange(e.target.value + ":00")}
      />
    )
  }
];

export const newDoctorHours = [
  {
    title: "doctorName",
    field: "doctor_name"
  },
  {
    title: "doctorId",
    field: "doctorId"
  },
  {
    title: "Brand",
    field: "brandId",
    lookup: {
      1: "MM",
      2: "BW",
      3: "LJ"
    }
  },
  {
    title: "weekday",
    field: "weekday",
    lookup: {
      0: "Monday",
      1: "Tuesday",
      2: "Wednesday",
      3: "Thursday",
      4: "Friday",
      5: "Saturday",
      6: "Sunday"
    }
  },
  {
    title: "fromTime",
    field: "fromTime",
    editComponent: props => (
      <input
        type="time"
        value={props.value}
        onChange={e => props.onChange(e.target.value + ":00")}
      />
    )
  },
  {
    title: "toTime",
    field: "toTime",
    editComponent: props => (
      <input
        type="time"
        value={props.value}
        onChange={e => props.onChange(e.target.value + ":00")}
      />
    )
  }
];

export const doctorBrandColumns = [
  {
    title: "doctorName",
    field: "doctor_name"
  },
  {
    title: "doctorId",
    field: "doctorId"
  },
  {
    title: "brand",
    field: "brandId",
    lookup: {
      1: "MM",
      2: "BW",
      3: "LJ"
    }
  }
];

export const doctorLanguageColumns = [
  {
    title: "doctorName",
    field: "doctor_name"
  },
  {
    title: "doctorId",
    field: "doctorId"
  },
  {
    title: "Brand",
    field: "brandId",
    lookup: {
      1: "MM",
      2: "BW",
      3: "LJ"
    }
  },
  {
    title: "language",
    field: "languageId",
    lookup: {
      1: "English",
      2: "Tamil",
      3: "Telugu",
      4: "Bengali",
      5: "Marathi",
      6: "Hindi",
      7: "Kannada",
      8: "Malayalam"
    }
  }
];

export const doctorCategoryColumns = [
  {
    title: "doctorName",
    field: "doctor_name"
  },
  {
    title: "doctorId",
    field: "doctorId"
  },
  {
    title: "Brand",
    field: "brandId",
    lookup: {
      1: "MM",
      2: "BW",
      3: "LJ"
    }
  },
  {
    title: "category",
    field: "categoryId",
    lookup: {
      1: "Skin",
      2: "Hair",
      3: "Performance",
      4: "Weight",
      5: "PCOS",
      6: "Bodycare",
      7: "Beard",
      8: "General",
      9: "Bodycare",
      10: "Nutrition"
    }
  }
];

export const breakHoursCols = [
  {
    title: "doctor_id",
    field: "doctor_id"
  },
  {
    title: "start_date",
    field: "start_date",
    editComponent: props => (
      <input
        type="date"
        value={props.value}
        onChange={e =>
          props.onChange(moment(e.target.value).format("YYYY-MM-DD"))
        }
      />
    )
  },
  {
    title: "end_date",
    field: "end_date",
    editComponent: props => (
      <input
        type="date"
        value={props.value}
        onChange={e =>
          props.onChange(moment(e.target.value).format("YYYY-MM-DD"))
        }
      />
    )
  },
  {
    title: "start_time",
    field: "start_time",
    editComponent: props => (
      <input
        type="time"
        value={props.value}
        onChange={e => props.onChange(e.target.value + ":00")}
      />
    )
  },
  {
    title: "end_time",
    field: "end_time",
    editComponent: props => (
      <input
        type="time"
        value={props.value}
        onChange={e => props.onChange(e.target.value + ":00")}
      />
    )
  }
];
export const formDataCols = [
  "first_name",
  "last_name",
  "age",
  "weight",
  "height",
  "email",
  "phone",
  "date",
  "time",
  "formID",
  "source",
  "primary_source",
  "category",
  "describe_hair_loss",
  "start_hair_loss",
  "current_condtion_hair",
  "hair_treatments",
  "hair_identify",
  "family_hair_loss",
  "describe_beard",
  "crownImg",
  "hairLineImg",
  "first_time_performance",
  "frequency_performance",
  "foreplay_performance",
  "drive_performance",
  "erections_performance",
  "maturbation_performance",
  "ejaculation_performance",
  "skin_concern",
  "skin_type",
  "skin_acne_appear",
  "skin_acne_how_many",
  "skin_wrinkles",
  "skin_aging_condition",
  "exercise_lifestyle",
  "fruits_veg_lifestyle",
  "eating_lifestyle",
  "stress_lifestyle",
  "tired_lifestyle",
  "sleep_time_lifestyle",
  "sleep_quality_lifestyle",
  "health_condtion_lifestyle",
  "habit_lifestyle",
  "quit_smoking",
  "smoking_frequency",
  "medication_smoking",
  "gain_loose_weight",
  "calories_weight",
  "gut_weight",
  "water_weight",
  "medication_weight",
  "programs_weight",
  "scheduleDate",
  "scheduleSlot"
];

export const consultationCols = [
  {
    title: "doctor_name",
    field: "doctor_name"
  },
  {
    title: "TotalConsultations",
    field: "TotalConsultations",
    render: r =>
      r.oco +
      r.oc +
      r.oco +
      r.oop +
      r.ocb +
      r.ors +
      r.onr +
      r.od +
      r.oso +
      r.ob +
      r.oor +
      r.os +
      r.hco +
      r.hc +
      r.hco +
      r.hop +
      r.hcb +
      r.hrs +
      r.hnr +
      r.hd +
      r.hso +
      r.ob +
      r.hr +
      r.hs +
      r.pco +
      r.pc +
      r.pco +
      r.pop +
      r.pcb +
      r.prs +
      r.pnr +
      r.pd +
      r.pso +
      r.pb +
      r.pr +
      r.ps
  },
  {
    title: "Orders_Rx_Pending%",
    field: "Orders_Rx_Pending%",
    render: r =>
      (
        100 -
        (100 * (r.oop + r.oc)) /
          (r.oco +
            r.oc +
            r.oco +
            r.oop +
            r.ocb +
            r.ors +
            r.onr +
            r.od +
            r.oso +
            r.ob +
            r.oor +
            r.os)
      ).toFixed(2)
  },
  {
    title: "Form_Rx_Completion%",
    field: "Form_Rx_Completion%",
    render: r =>
      (
        (100 * (r.hop + r.pop + r.hc + r.pc)) /
        (r.hco +
          r.hc +
          r.hco +
          r.hop +
          r.hcb +
          r.hrs +
          r.hnr +
          r.hd +
          r.hso +
          r.ob +
          r.hr +
          r.hs +
          r.pco +
          r.pc +
          r.pco +
          r.pop +
          r.pcb +
          r.prs +
          r.pnr +
          r.pd +
          r.pso +
          r.pb +
          r.pr +
          r.ps)
      ).toFixed(2)
  }
];

export const summaryCols = ["doctor_name", "Total", "SOF", "SH", "HC"];
export const reviewsColsDoctor = [].concat(
  [
    "brand",
    "source",
    "id",
    "patient_name",
    "category",
    "nps_score",
    "nps_suggestion",
    "nps_score_given_at"
  ].map(col => {
    return {
      title: col,
      field: col
    };
  })
);
export const reviewsCols = [
  {
    title: "doctor_name",
    field: "doctor_consulted"
  }
].concat(
  [
    "brand",
    "source",
    "id",
    "nps_score",
    "nps_suggestion",
    "nps_score_given_at",
    "pci_improve",
    "nps_irrelevant"
  ].map(col => {
    if (col === "nps_irrelevant") {
      return {
        title: "Relevance",
        field: col,
        render: rowData => <MarkIrrelevance rowData={rowData} />
      };
    }
    if (col === "pci_improve") {
      return {
        title: col,
        field: col,
        cellStyle: {
          minWidth: "280px"
        }
      };
    }
    return {
      title: col,
      field: col
    };
  })
);
let countObj = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10
};
export const scheduleCols = [
  {
    title: "doctor_name",
    field: "doctor_id"
  },
  {
    title: "doctor_id",
    field: "doctor_id"
  },
  {
    title: "status",
    field: "status",
    render: row => statusListObj[row.status],
    lookup: statusListObj
  }
].concat(
  [
    "brand",
    "id",
    "source",
    "type",
    "Rescheduled",
    "appointment_date",
    "from_time",
    "to_time",
    "assignment_time",
    "image_uploaded_at",
    "patient_name",
    "category",
    "unassignable",
    "unassigned",
    "short_call_count",
    "prescription_order_ids",
    "optional_rx",
    "email",
    "phone",
    "source_id",
    "created_dttm",
    "updated_dttm",
    "es_audio_status",
    "requeued",
    "requeued_assigned",
    "prescription_generated",
    "reschedule_sent",
    "image_uploaded",
    "image_upload_reminder_sent",
    "no_of_attempts",
    "nps_score",
    "nps_score_given_at",
    "nps_suggestion",
    "nps_irrelevant",
    "pci_improve",
    "on_time",
    "call_initiated",
    "duration",
    "email_sent",
    "rescheduled_by",
    "FU_ques",
    "language",
    "type_of_call",
    "repeat_customer",
    "followup_status",
    "email_sent_dttm",
    "primary_source",
    "secondary_source",
    "app",
    "mode",
    "paid",
    "remarks",
    "disconnected_by",
    "doctor_feedback",
    "original_start_time",
    "original_end_time",
    "post_order_response",
    "doctor_consulted"
  ].map(col => {
    if (col === "pci_improve") {
      return {
        title: col,
        field: col,
        cellStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "200px"
        }
      };
    }
    if (col === "reschedule_sent" || col === "no_of_attempts") {
      return {
        title: col,
        field: col,
        lookup: countObj
      };
    }
    if (col === "source") {
      return {
        title: col,
        field: col,
        lookup: { F: "F", O: "O", FU: "FU", DA: "DA", MA: "MA" }
      };
    }
    if (col === "category") {
      return {
        title: "Category",
        field: col,
        render: row => {
          let arr = row.category.split(",");
          return arr
            .map(category => dataResource.categoryMap[category])
            .join(", ");
        },
        // lookup: dataResource.categoryMap,
        customFilterAndSearch: (terms, rowData) => {
          if (terms.length === 0) return true;
          let categorySearchableItems = "";
          const arr = rowData.category.split(",");
          arr.forEach(item => {
            categorySearchableItems +=
              dataResource.categoryMap[item].toUpperCase() + " ";
          });
          if (categorySearchableItems.includes(terms.toUpperCase()))
            return true;
          return false;
        }
      };
    } else if (
      col === "prescription_generated" ||
      col === "image_uploaded" ||
      col === "image_upload_reminder_sent" ||
      col === "on_time"
    ) {
      return {
        title: col,
        field: col,
        lookup: {
          0: "No",
          "-1": "No",
          1: "Yes"
        }
      };
    }
    return {
      title: col,
      field: col,
      editable: "never"
    };
  }),
  ["confirmed", "cancelled"].map(col => {
    if (col === "confirmed") {
      return {
        title: col,
        field: col,
        render: rowData => (rowData.confirmed ? rowData.confirmed : "-")
      };
    } else if (col === "cancelled") {
      return {
        title: col,
        field: col,
        render: rowData => (rowData.cancelled ? rowData.cancelled : "-")
      };
    } else if (col === "original_start_time") {
      return {
        title: col,
        field: col,
        render: rowData =>
          rowData.original_start_time ? rowData.original_start_time : "-"
      };
    } else if (col === "original_end_time") {
      return {
        title: col,
        field: col,
        render: rowData =>
          rowData.original_end_time ? rowData.original_end_time : "-"
      };
    }
  })
);

export const weeklyCols = [
  {
    title: "Week",
    field: "Week"
  },
  {
    title: "Total",
    field: "Total",
    render: row => row.allRows - row.Ordered
  },
  {
    title: "Valid",
    field: "Valid",
    render: row => row.allRows - row.Ordered - row.NI - row.NS - row.Testing
  },
  {
    title: "Completed",
    field: "Completed"
  },
  {
    title: "%Complete",
    field: "%Complete",
    render: row => getPerCompleted(row)
  }
];

export const weeklyFFCols = ["week", "total", "valid", "active"]
  .map(el => colMapper(el))
  .concat([
    {
      title: "pending",
      field: "pending",
      render: row => row.active - row.rxComplete
    },
    {
      title: "%pending",
      render: row =>
        ((100 * (row.active - row.rxComplete)) / row.active).toFixed(2)
    }
  ]);

export const pendingTodayCols = [
  "doctor_name",
  "Completed",
  "allRows",
  "Ordered",
  "NI",
  "NS",
  "Testing",
  "Ringing",
  "Disconnected",
  "Cool_off",
  "Busy",
  "Switched_Off"
];

export const pendingCumulativeCols = [
  {
    title: "doctor_name",
    field: "doctor_name"
  },
  {
    title: "Total",
    field: "Total",
    render: row => row.allRows - row.Ordered
  },
  {
    title: "Valid",
    field: "Valid",
    render: row => row.allRows - row.Ordered - row.NI - row.NS - row.Testing
  },
  {
    title: "Completed",
    field: "Completed"
  },
  {
    title: "%Complete",
    field: "%Complete",
    render: row => getPerCompleted(row)
  }
];

export const codAndPrepaidCols = [
  {
    title: "Week",
    field: "Week"
  },
  {
    title: "CODTotal",
    field: "CODTotal",
    render: r =>
      r.cs +
      r.cr +
      r.cb +
      r.cso +
      r.cnr +
      r.crs +
      r.cd +
      r.ccb +
      r.cop +
      r.cc +
      r.cco
  },
  {
    title: "PrepaidTotal",
    field: "PrepaidTotal",
    render: r =>
      r.ps +
      r.pr +
      r.pb +
      r.pso +
      r.pnr +
      r.prs +
      r.pd +
      r.pcb +
      r.pop +
      r.pc +
      r.pco +
      r.rs +
      r.ror +
      r.rb +
      r.rso +
      r.rnr +
      r.rrs +
      r.rd +
      r.rcb +
      r.rop +
      r.rc +
      r.rco
  },
  {
    title: "PendingCOD",
    field: "PendingCOD",
    render: r =>
      r.cs + r.cr + r.cb + r.cso + r.cnr + r.crs + r.cd + r.ccb + r.cco
  },
  {
    title: "PendingPrepaid",
    field: "PendingPrepaid",
    render: r =>
      r.ps +
      r.pr +
      r.pb +
      r.pso +
      r.pnr +
      r.prs +
      r.pd +
      r.pcb +
      r.pco +
      r.rs +
      r.ror +
      r.rb +
      r.rso +
      r.rnr +
      r.rrs +
      r.rd +
      r.rcb +
      r.rco
  },
  {
    title: "COD Pending %",
    field: "COD Pending %",
    render: r => getCodPending(r)
  },
  {
    title: "Prepaid Pending %",
    field: "Prepaid Pending %",
    render: r => getPrepaidPending(r)
  }
];

export const codVsPrepaidCols = [
  {
    title: "day",
    field: "day"
  },
  {
    title: "COD Total",
    field: "COD Total",
    render: r =>
      r.cs +
      r.cr +
      r.cb +
      r.cso +
      r.cnr +
      r.crs +
      r.cd +
      r.ccb +
      r.cop +
      r.cc +
      r.cco
  },
  {
    title: "Prepaid Total",
    field: "Prepaid Total",
    render: r =>
      r.ps +
      r.pr +
      r.pb +
      r.pso +
      r.pnr +
      r.prs +
      r.pd +
      r.pcb +
      r.pop +
      r.pc +
      r.pco +
      r.rs +
      r.ror +
      r.rb +
      r.rso +
      r.rnr +
      r.rrs +
      r.rd +
      r.rcb +
      r.rop +
      r.rc +
      r.rco
  },
  {
    title: "Pending COD",
    field: "Pending COD",
    render: r =>
      r.cs + r.cr + r.cb + r.cso + r.cnr + r.crs + r.cd + r.ccb + r.cco
  },
  {
    title: "Pending Prepaid",
    field: "Pending Prepaid",
    render: r =>
      r.ps +
      r.pr +
      r.pb +
      r.pso +
      r.pnr +
      r.prs +
      r.pd +
      r.pcb +
      r.pco +
      r.rs +
      r.ror +
      r.rb +
      r.rso +
      r.rnr +
      r.rrs +
      r.rd +
      r.rcb +
      r.rco
  },
  {
    title: "COD Pending %",
    field: "COD Pending %",
    render: r => getCodPending(r)
  },
  {
    title: "Prepaid Pending %",
    field: "Prepaid Pending %",
    render: r => getPrepaidPending(r)
  }
];

export const dailyAppCols = [
  {
    title: "Day",
    field: "day"
  },
  {
    title: "Total",
    field: "Total",
    render: row => row.allRows - row.Ordered
  },
  {
    title: "Valid",
    field: "Valid",
    render: row => row.allRows - row.Ordered - row.NI - row.NS - row.Testing
  },
  {
    title: "%Valid",
    field: "%Valid",
    render: row => getPerValid(row)
  },
  {
    title: "Completed",
    field: "Completed"
  },
  {
    title: "%Complete",
    field: "%Complete",
    render: row => getPerCompleted(row)
  }
];

export const dailyFFCols = ["day", "total", "valid", "active", "rxComplete"]
  .map(el => colMapper(el))
  .concat([
    {
      title: "%complete",
      field: "%complete",
      render: row => ((row.rxComplete * 100) / row.active).toFixed(2)
    }
  ]);

export const doctorTableCols = [
  "doctor_id",
  "doctor_name",
  "is_hc",
  "is_sh",
  "weight",
  "skin",
  "is_men",
  "medical_id",
  "doctor_qualifications",
  "gender",
  "doctor_email",
  "doctor_phone",
  "doctor_img",
  "WAPhone"
].map(el => colMapper(el));

import React from "react";
import {
  SpoofEmailIdButton,
  SpoofEmailIdContainer,
  SpoofEmailIdInput
} from "./styles";
import axios from "axios";
import config from "../../../constants/apiList";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

function SpoofEmailId() {
  const [emailId, setEmailId] = React.useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = async () => {
    try {
      const authVerifyResponse = await axios.get(config.url.DOC_VERIFY_TOKEN, {
        withCredentials: true
      });

      if (authVerifyResponse.status !== 200) {
        throw new Error("Authentication Failed");
      }
      const spoofRequestURL = `${config.AUTH.SPOOF}?email=${emailId}`;
      await axios.get(spoofRequestURL, { withCredentials: true });
      const setResponse = response => {
        localStorage.setItem("jwt", response.data.body.jwt);
        const payload = response.data.body.user;
        dispatch({ type: "USER", payload: payload });
        axios.defaults.headers.common["Authorization"] =
          localStorage.getItem("jwt");
        history.push("/login");
      };
      const response = await axios.post(
        config.url.LOGIN,
        { key: "" },
        { withCredentials: true }
      );
      if (response.data.statusCode === 200) {
        setResponse(response);
      }
    } catch (err) {
      console.log(err);
      toast.error(`Something went wrong: ${err?.message}`);
    }
  };
  return (
    <SpoofEmailIdContainer>
      <label>Spoof Email Id</label>
      <SpoofEmailIdInput
        onChange={e => setEmailId(e.target.value)}
        type="email"
        placeholder="Spoof Email Id"
      />
      <SpoofEmailIdButton onClick={handleClick}>Spoof</SpoofEmailIdButton>
    </SpoofEmailIdContainer>
  );
}

export default SpoofEmailId;

import React, { Component } from "react";
import Arrow from "./Arrow";
import ImageSlide from "./ImageSlide";
import "./ImageCarousel.scss";
const imgUrls = [
  "https://cmeimg-a.akamaihd.net/640/clsd/getty/c64f76dc20c246ca88ee180fe4b4b781",
  "https://lh3.googleusercontent.com/oxPeODS2m6rYIVbhcQChRtOWEYeGDwbeeeB1cDU2o_WYAVPU61VIgx-_6BAh5gSL8Sw=h900",
  "https://i0.wp.com/www.universodegatos.com/wp-content/uploads/2017/04/fivfelv7.jpg?resize=582%2C328",
  "https://i.pinimg.com/736x/07/c3/45/07c345d0eca11d0bc97c894751ba1b46.jpg",
  "https://mm-customer-images.s3.ap-south-1.amazonaws.com/staging-scalp-img-7cbbc409ec990f19c78c75bd1e06f215.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAYRXDAXY2PFMGAFGF%2F20200618%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20200618T113102Z&X-Amz-Expires=1800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHIaCmFwLXNvdXRoLTEiRzBFAiEAkpWDy0O0uFXFaI6FPrWL3wPf%2FWI9j8WFkZO5PETGZxYCID9b4NAWkd%2BaiDyCEbDSuSwmC869gewAh8WDofAWGuPJKv4BCOv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMNTg3ODEyODc5OTI0IgzV%2B4h2qd0CIROAOLoq0gEiUF4VjWVx08FU7AbSZpGn8eU26fdO9UbnIRnLJWEp1LipMnhTw%2FMqrROxE6KnV0jX%2Fhb7AaiibSDsW5rEnqi%2FN6OB6M6VV7vAUHvGAl2Ak33%2FKXCJsgMFqiW0mvaskdCLBgfDw3kiXKUBxV9WHGH2wN4kfbmgv2dIYKfYJ8BTDAddGT%2FfkW%2BMExxVbFBKmm%2Bn8vBZbhxwRxJHCtrYypxC3KBWefllEk6nvFr3yi76MV%2BoZb7X%2FoLKFjQYGOp2pOMPlQL74Whv8Q%2FQ9iF2WCS0po4wvvGs9wU64AGYRDh96kfKA7C7XlbicXMeqLj0KC3OwIq5t8KVebyRzUNXxMKwyrSK2ObpU6cUNvsY9dCUEGuf7u7JzLIksx1Qs4UUZuEI7ePZjSSp0ZwAzzu%2BMOe9mBBnVreI%2BaoIs6EC0%2BKADrI8xBc5eEJ%2F08eI1tmwcPc3UG3tt7wHAHYYbKooBjjivagGNpnmA7ag1C%2B2JpmJ0zrzez1NBYXdC980aecfM2Aa3duPVcYF2yPOwBZRyyMawZubsHr7CKC%2Blj110BR%2FpFlto5BydA3nEMfwhL6OQlcxObDuiHQ4W%2FBE%2Bw%3D%3D&X-Amz-Signature=ee83a518d31ca5f5f4fff36ee4156ade670d543781fc988dc0d9d5eb92310fcd&X-Amz-SignedHeaders=host"
];
export default class ImageCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIndex: 0
    };
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
  }

  previousSlide() {
    const lastIndex = this.props.imgUrls.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === 0;
    const index = shouldResetIndex ? lastIndex : currentImageIndex - 1;

    this.setState({
      currentImageIndex: index
    });
  }

  nextSlide() {
    const lastIndex = this.props.imgUrls.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === lastIndex;
    const index = shouldResetIndex ? 0 : currentImageIndex + 1;

    this.setState({
      currentImageIndex: index
    });
  }
  render() {
    return (
      <div className="carousel">
        <div className="onelineParent">
          <div className="oneline">
            <Arrow
              direction="left"
              clickFunction={this.previousSlide}
              glyph="&#9664;"
            />
          </div>
          <div className="oneline">
            <p>
              Image {this.state.currentImageIndex + 1} of{"              "}
              {this.props.imgUrls.length}{" "}
              {
                "                                                          \t \t"
              }
            </p>
          </div>
          <div className="oneline rightarrow">
            <Arrow
              direction="right"
              clickFunction={this.nextSlide}
              glyph="&#9654;"
            />
          </div>
        </div>
        <ImageSlide url={this.props.imgUrls[this.state.currentImageIndex]} />
      </div>
    );
  }
}

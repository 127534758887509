import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./LatestImages.scss";
import RequestImages from "../Complaints/DoctorNotes/RequestImages";
import axios from "axios";
import config from "../../../../constants/apiList";
import { store } from "../../../../reducers/Persist";
import { ImagesTitle, Paragraph } from "./styles";
import { addToGTM } from "../../../../helpers/tagManager.helper";
import { GTM_EVENTS } from "../../../../constants/tagManager.constants";
import dataResource from "../../../../constants/dataResource";
export default function LatestImages(props) {
  const brand = useSelector(state => state.rxWriterReducer.brand);
  const schedule_id = useSelector(state => state.rxWriterReducer.id);
  const [images, setImages] = useState([]);
  useEffect(() => {
    getImages();
  }, [brand, schedule_id]);

  function getImages() {
    const payload = {
      brand,
      schedule_id
    };
    axios
      .post(config.url.PRESCRIPTION_GET_RX_IMAGES, payload)
      .then(res => {
        setImages(images => {
          if (res.data?.signedUrl?.length === images?.length) {
            return images;
          }
          return res.data.signedUrl || [];
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  const refreshImages = () => {
    getImages();
    addToGTM({
      event: GTM_EVENTS?.IMAGE_REFRESHED
    });
  };

  if (!dataResource.IMAGE_SUPPORTED_BRAND.includes(brand)) {
    return (
      <div className="margin-30 latest-images">
        <ImagesTitle>Images</ImagesTitle>
        <Paragraph>
          Images are currently not supported for{" "}
          {dataResource.BRANDS_SHORT_TO_FULL_NAME[brand] || brand}
        </Paragraph>
      </div>
    );
  }

  return (
    <div className="margin-30 latest-images">
      <ImagesTitle>Images</ImagesTitle>
      <Paragraph>Uploaded Images from the user will be shown here</Paragraph>
      <div className="images-container">
        {images.map((image, index) => {
          return (
            <div key={index}>
              <img
                loading="lazy"
                src={image}
                alt=""
                className="patient-image"
                onClick={() => {
                  store.dispatch({
                    type: "SHOW_SINGLE_IMAGE_MODAL",
                    payload: {
                      image_date: false,
                      image_src: image,
                      image_arr: images
                    }
                  });
                }}
              />
            </div>
          );
        })}
      </div>
      <RequestImages refreshImages={refreshImages} />
    </div>
  );
}

import axios from "axios";
import React, { useState, useEffect } from "react";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Loader from "react-loader-spinner";
import Table4 from "../../common/Tables/Table4";
import dataResource from "../../../constants/dataResource";
import CONFIG_CONSTANTS from "./../../../constants/apiList";
import { getRxWriterLink } from "../../../helpers/rx-writer.helpers.js";

function RxTracker(props) {
  const [startDate, setStartDate] = useState(
    moment().subtract(5, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [brand, setBrand] = useState("MM");
  const [filterByCreated, setFilterByCreated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const columns = [
    { title: "Order ID", field: "orderId" },
    {
      title: "Appointment ID",
      field: "appointmentId",
      cellStyle: { minWidth: "150px" }
    },
    {
      title: "Created Date",
      field: "createdDate",
      cellStyle: { minWidth: "150px" }
    },
    {
      title: "Appointment Date",
      field: "appointmentDate",
      cellStyle: { minWidth: "200px" }
    },
    {
      title: "Confirmed Date",
      field: "confirmedDate",
      cellStyle: { minWidth: "150px" }
    },
    {
      title: "Order Status",
      field: "orderStatus",
      cellStyle: { minWidth: "150px" }
    },
    { title: "Rx Status", field: "status" },
    {
      title: "Payment Mode",
      field: "paymentMode",
      cellStyle: { minWidth: "150px" }
    },
    { title: "Category", field: "category", cellStyle: { minWidth: "200px" } },
    {
      title: "Doctor Name",
      field: "doctorName",
      cellStyle: { minWidth: "200px" }
    },
    { title: "Attempts", field: "attempts" },
    {
      title: "Products",
      field: "displayProducts",
      cellStyle: { minWidth: "500px" }
    },
    {
      title: "Start Time",
      field: "from_time",
      cellStyle: { minWidth: "500px" }
    },
    {
      title: "End Time",
      field: "to_time",
      cellStyle: { minWidth: "500px" }
    },
    {
      title: "Language",
      field: "language",
      cellStyle: { minWidth: "500px" }
    },
    {
      title: "Patient Phone Number",
      field: "phone",
      cellStyle: { minWidth: "500px" }
    },
    {
      title: "Order Auto Clear",
      field: "autoClear"
    }
  ];
  const generateRows = data => {
    const finalRows = [];
    for (const orderId in data) {
      const row = data[orderId];
      const finalRow = { ...row };
      finalRow.createdDate = finalRow.createdDate
        ? moment(finalRow.createdDate, "YYYY-MM-DD HH:mm:ss").format(
            "D MMM YYYY"
          )
        : "-";
      finalRow.appointmentDate = finalRow.appointmentDate
        ? moment(finalRow.appointmentDate, "YYYY-MM-DD").format("D MMM YYYY")
        : "-";
      finalRow.confirmedDate = finalRow.confirmedDate
        ? moment(finalRow.confirmedDate, "YYYY-MM-DD").format("D MMM YYYY")
        : "-";
      finalRow.status = dataResource.statusListObj[finalRow.status];
      finalRow.displayProducts = finalRow.products.join(", ");
      finalRows.push(finalRow);
    }
    setRows(finalRows);
  };
  const trackRx = async () => {
    setLoader(true);
    const requestData = {
      startDate,
      endDate,
      brand,
      filterByCreated
    };
    await axios
      .post(CONFIG_CONSTANT.url.TRACK_RX, requestData)
      .then(async res => {
        if (res.data.success) {
          const instance = axios.create();
          delete instance.defaults.headers.common["Authorization"];
          const jsonResponse = await instance.get(res.data.url);
          generateRows(jsonResponse.data);
        }
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        console.log("ERROR IN /trackRx", err);
      });
  };
  useEffect(() => {
    trackRx();
  }, [brand, filterByCreated]);
  return (
    <div className="rx-tracker">
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={startDate}
          onChange={evt => setStartDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={endDate}
          onChange={evt => setEndDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={trackRx}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{ margin: "0px 10px" }}
          onClick={evt => setFilterByCreated(false)}
        >
          <label style={{ cursor: "pointer", margin: "10px 5px" }}>
            Appointment Date
          </label>
          <input type="radio" checked={!filterByCreated} />
        </div>
        <div
          style={{ margin: "0px 10px" }}
          onClick={evt => setFilterByCreated(true)}
        >
          <label style={{ cursor: "pointer", margin: "10px 5px" }}>
            Created Date
          </label>
          <input type="radio" checked={filterByCreated} />
        </div>
      </div>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <div>
          <Table4
            title="Rx Data"
            rows={rows}
            cols={columns}
            pageSize={10}
            actions={appointmentAction}
            filtering={true}
          />
        </div>
      )}
    </div>
  );
}
const appointmentAction = [
  {
    icon: "launch",
    tooltip: "Open Prescription",
    onClick: (event, rowData) => {
      const param = rowData.appointmentId;
      window.open(getRxWriterLink(param), "_blank");
    }
  },
  {
    icon: "update",
    tooltip: "Reschedule Appointment",
    onClick: (event, rowData) => {
      const appointmentId = rowData.appointmentId;
      window.open(`/admin/rescheduleAppointment/${appointmentId}`, "_blank");
    }
  }
];
export default RxTracker;

import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import CONFIG_CONSTANT from "../../../constants/apiList.js";
import { setWeekYearWithOptions } from "date-fns/fp";
import Table4 from "../../common/Tables/Table4.js";

function ShortCalls(props) {
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const getShortCalls = async evt => {
    setShowLoader(true);
    const getShortCallsRequestBody = {
      startDate,
      endDate
    };
    await axios
      .post(CONFIG_CONSTANT.url.GET_SHORT_CALLS, getShortCallsRequestBody)
      .then(res => {
        console.log("GET_SHORT_CALLS RES", res);
        setRows(res.data.result);
        setShowLoader(false);
      })
      .catch(err => {
        setShowLoader(false);
        console.log("Error in /getShortCalls", err);
      });
  };
  useEffect(() => {
    getShortCalls();
  }, []);
  const columns = [
    { title: "Appointment ID", field: "appointmentId" },
    { title: "Type", field: "source" },
    { title: "Doctor", field: "doctorName" },
    { title: "Duration (In Sec)", field: "duration" },
    {
      title: "Brand",
      field: "brand",
      cellStyle: brand => {
        const color = brand === "MM" ? "#507da1" : "#665099";
        return { color, fontWeight: "bold" };
      }
    }
  ];
  return (
    <div className="short-calls">
      <div style={{ display: "flex", padding: "10px" }}>
        <input
          type="date"
          className="input"
          value={startDate}
          onChange={evt => setStartDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={endDate}
          onChange={evt => setEndDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <IconButton color="primary" onClick={getShortCalls}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <div>
          <Table4
            title="Short Calls"
            rows={rows}
            cols={columns}
            pageSize={rows.length > 10 ? 10 : rows.length}
          />
        </div>
      )}
    </div>
  );
}
export default ShortCalls;

import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table4 from "../../common/Tables/Table4.js";
import CustomizableHeaderTable from "../../common/Tables/CustomizableHeaderTable.js";
import moment from "moment";

let cols1 = ["PCI Rating", "1", "2", "3", "4", "5", "Total", "Avg. Rating"].map(
  col => {
    return {
      title: col,
      field: col
    };
  }
);
let cols2 = ["name", "1", "2", "3", "4", "5", "Total", "Avg. Rating"].map(
  col => {
    if (col === "name") {
      return {
        title: "PCI Rating",
        field: col
      };
    }
    return {
      title: col,
      field: col
    };
  }
);
let cols3 = [
  "name",
  "F",
  "DA",
  "FU",
  "O",
  "MA",
  "Total",
  "No. PCI",
  "PCI %"
].map(col => {
  if (col === "name") {
    return {
      title: "Source",
      field: col
    };
  }
  return {
    title: col,
    field: col
  };
});
let cols4 = ["name", "F", "DA", "FU", "O", "MA", "Average"].map(col => {
  if (col === "name") {
    return {
      title: "Source",
      field: col
    };
  }
  return {
    title: col,
    field: col
  };
});
let duationPercentileCols = [
  "name",
  "F",
  "DA",
  "FU",
  "O",
  "MA",
  "Percentile (>1 min)"
].map(col => {
  if (col === "name") {
    return {
      title: "Source",
      field: col
    };
  }
  return {
    title: col,
    field: col
  };
});
const selectOptions = [
  { label: "2 mins", value: "2" },
  { label: "3 mins", value: "3" },
  { label: "4 mins", value: "4" },
  { label: "5 mins", value: "5" },
  { label: "6 mins", value: "6" },
  { label: "7 mins", value: "7" },
  { label: "8 mins", value: "8" }
];
const defaultSelection = { label: "8 mins", value: "8" };

export default function PCI({ brand, reqBody }) {
  const [dataa, setDataa] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [data4, setData4] = useState();
  const [data5, setData5] = useState();
  const [durationPercentile, setDurationPercentile] = useState();
  const [consultations, setConsultations] = useState();
  const [divideDuration, setDivideDuration] = useState(8);

  useEffect(() => {
    axios
      .post(config.url.PCI_ADMIN_DASHBOARD, reqBody)
      .then(res => {
        setDataa(res.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  }, [reqBody?.from_date, reqBody?.to_date]);

  useEffect(() => {
    let overallData = [];
    for (let key in dataa) {
      let data = dataa[key].data;
      let name = dataa[key].name;
      data = data.filter(row => row.brand === brand || brand === "both");
      let obj = { name, data };
      overallData.push(obj);
    }
    let map = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    let map2 = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    let total = 0;
    overallData.forEach(record => {
      record.data.forEach(row => {
        if (row.nps_score === 0 || row.nps_irrelevant) return;
        map[row.nps_score] += 1;
        ++total;
      });
    });
    let sum = 0;
    for (let key in map) {
      sum += map[key] * key;
      map2[key] = map[key];
    }
    map["Total"] = total;
    map["PCI Rating"] = "Count";
    map["Avg. Rating"] = sum / total ? (sum / total).toFixed(2) : 0;
    let data1 = [map];
    for (let key in map2) {
      map2[key] =
        (map2[key] / total ? ((map2[key] / total) * 100).toFixed(2) : 0) + "%";
    }
    map2["Total"] = "100%";
    map2["PCI Rating"] = "Percentage";
    let data2 = [map2];

    let data3 = [];
    let totalMap = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      Total: 0,
      "Avg. Rating": 0,
      name: "Total"
    };
    overallData.forEach(record => {
      let total = 0;
      let map = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
      record.data.forEach(row => {
        if (row.nps_score === 0 || row.nps_irrelevant) return;
        map[row.nps_score] += 1;
        ++total;
        totalMap[row.nps_score] += 1;
        totalMap["Avg. Rating"] += row.nps_score;
        totalMap["Total"] += 1;
      });
      let sum = 0;
      for (let key in map) {
        sum += map[key] * key;
      }
      map["Total"] = total;
      map["name"] = record.name;
      map["Avg. Rating"] = sum / total ? (sum / total).toFixed(2) : 0;
      data3.push(map);
    });
    totalMap["Avg. Rating"] =
      totalMap["Avg. Rating"] / totalMap["Total"]
        ? (totalMap["Avg. Rating"] / totalMap["Total"]).toFixed(2)
        : 0;
    data3.push(totalMap);

    let consultations = [];
    totalMap = {
      F: 0,
      DA: 0,
      FU: 0,
      O: 0,
      MA: 0,
      Total: 0,
      "No. PCI": 0,
      "PCI %": "0%",
      name: "Total"
    };
    overallData.forEach(record => {
      let map = { F: 0, DA: 0, FU: 0, O: 0, MA: 0, Total: 0, "No. PCI": 0 };
      record.data.forEach(row => {
        if (row.status === "c") {
          map[row.source] += 1;
          map["Total"] += 1;
          totalMap[row.source] += 1;
          totalMap["Total"] += 1;
          if (row.nps_score && !row.nps_irrelevant) {
            map["No. PCI"] += 1;
            totalMap["No. PCI"] += 1;
          }
        }
      });
      map["name"] = record.name;
      map["PCI %"] =
        (map["No. PCI"] / map["Total"]
          ? ((map["No. PCI"] / map["Total"]) * 100).toFixed(2)
          : 0) + "%";
      consultations.push(map);
    });
    totalMap["name"] = "Total";
    totalMap["PCI %"] =
      (totalMap["No. PCI"] / totalMap["Total"]
        ? ((totalMap["No. PCI"] / totalMap["Total"]) * 100).toFixed(2)
        : 0) + "%";
    consultations.push(totalMap);

    let data4 = [];
    totalMap = {
      F: 0,
      DA: 0,
      FU: 0,
      O: 0,
      MA: 0,
      Total: 0,
      "No. PCI": 0,
      "PCI %": "0%",
      name: "Total"
    };
    overallData.forEach(record => {
      let map = { F: 0, DA: 0, FU: 0, O: 0, MA: 0, Total: 0, "No. PCI": 0 };
      record.data.forEach(row => {
        map[row.source] += 1;
        map["Total"] += 1;
        totalMap[row.source] += 1;
        totalMap["Total"] += 1;
        if (row.nps_score && !row.nps_irrelevant) {
          map["No. PCI"] += 1;
          totalMap["No. PCI"] += 1;
        }
      });

      map["name"] = record.name;
      map["PCI %"] =
        (map["No. PCI"] / map["Total"]
          ? ((map["No. PCI"] / map["Total"]) * 100).toFixed(2)
          : 0) + "%";
      data4.push(map);
    });
    totalMap["name"] = "Total";
    totalMap["PCI %"] =
      (totalMap["No. PCI"] / totalMap["Total"]
        ? ((totalMap["No. PCI"] / totalMap["Total"]) * 100).toFixed(2)
        : 0) + "%";
    data4.push(totalMap);

    let data5 = [];
    overallData.forEach(record => {
      let map = { F: 0, DA: 0, FU: 0, O: 0, MA: 0, Average: 0 };
      let validCalls = { F: 0, DA: 0, FU: 0, O: 0, MA: 0, Average: 0 };
      let duration = { F: 0, DA: 0, FU: 0, O: 0, MA: 0, Average: 0 };
      let durationSum = 0;
      let validCallsSum = 0;
      record.data.forEach(row => {
        if (row.status === "c") {
          duration[row.source] += row.duration;
          validCalls[row.source] += row.duration ? 1 : 0;
          durationSum += row.duration;
          validCallsSum += row.duration ? 1 : 0;
        }
        if (row.nps_score && !row.nps_irrelevant) {
          map["No. PCI"] += 1;
        }
      });
      for (let orderType in map) {
        const avgDuration =
          validCalls[orderType] > 0
            ? duration[orderType] / validCalls[orderType]
            : 0;
        map[orderType] = avgDuration
          ? moment("1900-01-01 00:00:00")
              .add(avgDuration, "seconds")
              .format("mm:ss")
          : "-";
      }
      map["name"] = record.name;
      const avg =
        validCallsSum > 0 ? (durationSum / validCallsSum).toFixed(2) : 0;
      map["Average"] = moment("1900-01-01 00:00:00")
        .add(avg, "seconds")
        .format("mm:ss");
      data5.push(map);
    });
    let durationPercentile = [];
    overallData.forEach(record => {
      let map = { F: 0, DA: 0, FU: 0, O: 0, MA: 0, Average: 0 };
      let stage2 = { F: 0, DA: 0, FU: 0, O: 0, MA: 0 };
      let stage1 = { F: 0, DA: 0, FU: 0, O: 0, MA: 0 };
      let stage2Sum = 0;
      let stage1Sum = 0;
      record.data.forEach(row => {
        stage2[row.source] += row.duration > divideDuration * 60 ? 1 : 0;
        stage1[row.source] += row.duration > 60 ? 1 : 0;
        stage2Sum += row.duration > divideDuration * 60 ? 1 : 0;
        stage1Sum += row.duration > 60 ? 1 : 0;
      });
      for (let orderType in map) {
        map[orderType] =
          stage2[orderType] > 0
            ? `${((stage2[orderType] / stage1[orderType]) * 100).toFixed(2)}%`
            : 0;
      }
      map["name"] = record.name;
      let sumOfStage1 = 0;
      let sumOfStage2 = 0;
      for (let orderType in stage1) {
        sumOfStage1 += stage1[orderType];
      }
      for (let orderType in stage2) {
        sumOfStage2 += stage2[orderType];
      }
      // console.log("---stage1Sum stage2Sum----", stage1Sum, stage2Sum);
      // console.log(
      //   "--stage1 stage2 sumOfStage1 sumOfStage2-----",
      //   stage1,
      //   stage2,
      //   sumOfStage1,
      //   sumOfStage2
      // );
      map["Percentile (>1 min)"] =
        sumOfStage1 > 0
          ? `${((sumOfStage2 / sumOfStage1) * 100).toFixed(2)}%`
          : 0;
      durationPercentile.push(map);
    });

    setData1(data1);
    setData2(data2);
    setData3(data3);
    setData4(data4);
    setData5(data5);
    setDurationPercentile(durationPercentile);
    setConsultations(consultations);
  }, [brand, dataa, divideDuration]);

  return (
    <PCITable2
      data1={data1}
      data2={data2}
      data3={data3}
      data4={data4}
      data5={data5}
      durationPercentile={durationPercentile}
      consultations={consultations}
      setDivideDuration={setDivideDuration}
    />
  );
}

function PCITable2({
  data1,
  data2,
  data3,
  data4,
  data5,
  durationPercentile,
  consultations,
  setDivideDuration
}) {
  return data1 && data2 && data3 && data4 ? (
    <>
      <Table4 cols={cols1} rows={data1} title="Overall Company PCI" />
      <Table4
        cols={cols1.slice(0, -1)}
        rows={data2}
        title="Overall Company PCI %"
      />
      <Table4 cols={cols2} rows={data3} title="Doctor Wise PCI" pageSize={10} />
      <Table4
        cols={cols3}
        rows={consultations}
        title="Total Consultation"
        pageSize={10}
      />
      <Table4
        cols={cols3}
        rows={data4}
        title="Total RX Generated"
        pageSize={10}
      />
      <Table4
        cols={cols4}
        rows={data5}
        title="Average Duration (In minutes)"
        pageSize={10}
      />
      <CustomizableHeaderTable
        cols={duationPercentileCols}
        rows={durationPercentile}
        title="Duration percentiile"
        pageSize={10}
        selectOptions={selectOptions}
        defaultSelection={defaultSelection}
        setDivideDuration={setDivideDuration}
      />
    </>
  ) : null;
}

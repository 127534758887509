import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Gallery from "react-grid-gallery";
import config from "../../../constants/apiList";

export default function ImageTimeLine({ param }) {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    Axios.post(config.url.GET_RX_TIMELINE_IMAGES, param)
      .then(res => {
        if (
          Object.keys(res.data?.signedImages)?.length ===
          Object.keys(data)?.length
        ) {
          return;
        }
        setData(res.data.signedImages);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);
  return (
    <div style={{ margin: "6px 6px 0" }}>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{ padding: "10px 15px", fontWeight: "600", fontSize: "25px" }}
        >
          Image Timeline
        </div>
        <div style={{ padding: "10px 15px", fontWeight: "800" }}>
          <Button
            variant="primary"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {!open ? "Open" : "Close"}
          </Button>
        </div>
      </div>
      {data && open && (
        <div>
          {Array.from(Object.keys(data)).map(key => {
            return (
              <div
                style={{
                  overflowY: "auto",
                  padding: "10px 0 10px 15px",
                  backgroundColor: "white"
                }}
              >
                <div style={{ padding: "5px 0", fontWeight: "800" }}>{key}</div>
                <Gallery
                  id={key}
                  images={data[key].map(str => ({
                    src: str,
                    thumbnail: str,
                    thumbnailWidth: 320,
                    thumbnailHeight: 212
                  }))}
                  enableImageSelection={false}
                  backdropClosesModal={true}
                />
              </div>
            );
          })}
        </div>
      )}
      {!data && open && (
        <div
          style={{
            overflowY: "auto",
            padding: "10px 0 10px 15px",
            backgroundColor: "white"
          }}
        >
          No Images Found!
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { CONSTANTS } from "./constants";
import {
  LeaveInfoWrapper,
  OptionButtonWrapper,
  AttemptLeftWrapper
} from "./styles";

const LeaveButton = ({ availableLeaves = 0, disabled = false }) => {
  const [showInfo, setShowInfo] = useState(false);
  const { TEXT, PRIMARY_COLOR, SECONDARY_COLOR, ICON } = CONSTANTS?.BUTTON;
  return (
    <OptionButtonWrapper
      onClick={() => window.open(CONSTANTS.LEAVE_FORM_LINK, "_blank")}
      primaryColor={PRIMARY_COLOR}
      secondaryColor={SECONDARY_COLOR}
      disabled={disabled}
    >
      {ICON}
      <span>{TEXT}</span>
      <AttemptLeftWrapper
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
      >
        <span>i</span>
        {showInfo ? (
          <LeaveInfoWrapper>
            <div className="info">
              Total Privileged Leaves Balance:{" "}
              <span className="hightlight">{availableLeaves}</span>
            </div>
            <div className="note">
              <span className="hightlight">Note:</span> For the most accurate
              leave balance, we recommend logging out and logging in again.
              Changes will reflect then.
            </div>
          </LeaveInfoWrapper>
        ) : null}
      </AttemptLeftWrapper>
    </OptionButtonWrapper>
  );
};

export default LeaveButton;

import React, { Component } from "react";
import "./PrescriptionViewer.scss";
import Iframe from "react-iframe";
import {
  Button,
  Table,
  Modal,
  Dropdown,
  DropdownButton,
  Form
} from "react-bootstrap";
import axios from "axios";
import Loader from "react-loader-spinner";
import { alertBox, confirmBox } from "./../../common/PopupBox/PopupBox";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import { store } from "../../../reducers/Persist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
var descriptionList = [
  "30 minutes of regular exercise",
  "Medicines not to be taken with alcohol (gap of 8 hours)",
  "Use a wide toothed wood comb after Minoxidil application, comb for 5 minutes",
  "Medicines to be taken after food",
  "Drink 3 to 4 liters of water everyday",
  "Get 6 to 8 hours of sleep",
  "Eat a balanced diet, include green leafy vegetables, fruits and nuts",
  "Activator Hygiene - Wash with warm water. Immerse Activator into alcohol / surgical spirit, needles should be immersed. Keep for 5-10 minutes. Remove Activator from alcohol / surgical spirit and allow to dry. Repeat steps before and after using the Activator"
];
class PrescriptionViewer extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShowEmail = this.handleShowEmail.bind(this);
    this.handleCloseEmail = this.handleCloseEmail.bind(this);
    this.state = {
      prescription_id: "",
      data: {},
      pdf: "",
      info: this.props.userInfo,
      email: this.props.userInfo.data.email,
      showEmail: false,
      show: false,
      medicines: [],
      description: "",
      emailState: "",
      descriptionList: descriptionList,
      editMode: false
    };
    this.docId = store.getState().userReducer.hash_id;
  }
  componentDidMount() {
    if (!this.props.nicotex) return;
    const medicine = {
      product: {
        prod_name: "Nicotex 2 mg",
        prod_id: "new"
      },
      frequency: "SOS",
      prescription_quantity: "1",
      cart_quantity: "1",
      notes:
        "Chew the Nicotex gum slowly when you feel an urge to smoke, place and rest it between your cheek and gum- to allow nicotine absorption through the inner lining of the mouth. Park the Nicotex gum for about 30 minutes or until the taste dissipates"
    };
    if (this.props.nicotex == "2mg") {
      medicine.product.prod_name = "Nicotex 2 mg";
    } else if (this.props.nicotex == "4mg") {
      medicine.product.prod_name = "Nicotex 4 mg";
    }
    this.props.prescriptionsList.push(medicine);
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("CDM PV");
    console.log(this.props);
    console.log(this.props.prescriptionsList);
    if (
      this.props.prescriptionsList.length !== prevProps.prescriptionsList.length
    ) {
      this.setState({ editMode: this.props.isEdit });
    }
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false, pdf: "" });
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleShowEmail() {
    this.setState({ showEmail: true });
  }
  handleCloseEmail() {
    this.setState({ showEmail: false });
  }

  sendEmail = e => {
    this.setState({ emailState: "sending" });
    let data = {
      doctorId: this.docId,
      prescriptionId: this.state.prescription_id,
      sendTo: this.state.email,
      order_confirmed: this.state.confirmCheck ? 1 : 0,
      brand: this.props.brand
    };
    axios.post(CONFIG_CONSTANTS.url.SEND_REPORT_URL, data).then(res => {
      if (res.data.statusCode == 200) {
        this.props.setModalState("statusModal", true);
        this.setState({ emailState: "sent" }, () => {
          setTimeout(() => {
            this.setState({ show: false, emailState: "" });
          }, 2000);
        });
      } else {
        this.setState({ show: false, emailState: "" });
        alertBox(
          "There seems to be a issue with sending the email. Please contact Admin"
        );
      }
    });
  };

  setPatientMedicalRecords() {
    return {
      category: this.props.category,
      complaints: this.props.complaints,
      history: this.props.history,
      diagnosis: this.props.diagnosis,
      instruction: this.props.instructions
    };
  }

  setPatientRecords() {
    return {
      name: this.state.info.data.name,
      age: this.state.info.data.age,
      gender:
        this.state.info.data.gender ||
        (this.props.brand == "BW" ? "Female" : "Male"),
      email: this.state.info.data.email,
      phone: this.state.info.data.phone
    };
  }

  setMedicineRecords() {
    let medicines = [];
    //console.log(this.props.prescriptionsList);
    this.props.prescriptionsList &&
      this.props.prescriptionsList.forEach((elem, index) => {
        medicines.push({
          product_id: elem.product.prod_id,
          category: elem.product.prod_cat,
          name: elem.product.generic_name || elem.product.prod_name,
          unit: elem.unit,
          frequency: elem.frequency,
          noOfDays: elem.days,
          quantity: elem.prescription_quantity ? elem.prescription_quantity : 1,
          cart_quantity: elem.cart_quantity ? elem.cart_quantity : 1,
          instruction: elem.notes
        });
      });
    return medicines;
  }

  handlePreview = e => {
    e.preventDefault();
    this.setState({ show: true });
    let reqData = {
      doctorId: this.docId,
      brand: this.props.brand,
      patient: {
        name: this.props.userInfo.data.name,
        age: this.props.userInfo.data.age,
        gender:
          this.props.userInfo.data.gender ||
          (this.props.brand == "BW" ? "Female" : "Male"),
        email: this.props.userInfo.data.email,
        phone: this.props.userInfo.data.phone
      },
      patientMedical: this.setPatientMedicalRecords(),
      medicines: this.setMedicineRecords(),
      recommendedChanged: this.props.recommendationChange,
      form: this.props.form,
      id: this.props.userId
    };
    console.log("dd", reqData);
    this.setState({ data: reqData });
    axios
      .post(CONFIG_CONSTANTS.url.GENERATE_REPORT_URL, reqData)
      .then(res => {
        this.props.setPrescriptionID(res.data.body.data.prescription_id);
        this.setState({
          show: true,
          pdf: res.data.body.data.url,
          prescription_id: res.data.body.data.prescription_id
        });
      });
  };

  selectedInstruction = index => {
    let value = this.state.description
      ? this.state.description + ", " + this.state.descriptionList[index]
      : this.state.descriptionList[index];
    this.setState({ description: value });
  };

  handleDelete(index) {
    confirmBox("Are you sure you want to delete this item", () => {
      if (this.props.keyVal) {
        this.props.deleteElement(index, this.props.keyVal);
      } else {
        this.props.deleteElement(index);
      }
    });
  }

  handleMove(index) {
    confirmBox("Are you sure you want to move this item to order", () => {
      this.props.handleMove(index);
    });
  }

  handleMoveOther(index) {
    confirmBox(
      "Are you sure you want to move this item to prescription?",
      () => {
        this.props.handleMoveOther(index);
      }
    );
  }

  handleEdit(index) {
    this.setState({ editMode: true });
    console.log(`INDEX:${index}`);
    console.log(this.props.keyVal);
    if (this.props.keyVal) {
      this.props.editElement(index, this.props.keyVal);
    } else {
      this.props.editElement(index);
    }
  }

  handleCheck = e => {
    this.setState({ confirmCheck: e.target.checked });
  };

  expandCollapseInstructions(e, elem) {
    let el = e.target.parentNode.parentNode;
    if (
      !!el.nextSibling &&
      !!el.nextSibling.innerHTML &&
      el.nextSibling.innerHTML.includes("Instructions")
    ) {
      el.nextSibling.remove();
    } else {
      el.insertAdjacentHTML(
        "afterEnd",
        '<tr><td colspan="6">Instructions: <br/>' + elem.notes + "</td></tr>"
      );
    }
  }

  collapseInstructions(e) {
    let el = document.getElementsByTagName("tr");
    for (let i = 0; i < el.length; i++) {
      if (el[i].innerHTML.includes("Instructions")) {
        el[i].remove();
      }
    }
  }

  render() {
    return (
      <>
        {/* {this.props.keyVal ? (
          <p>
            <b>
              ORDER ID :{this.props.keyVal} The following order has already been
              placed
            </b>
          </p>
        ) : (
          <p>
            <b>
              The following are products recommended to the customer but not yet
              purchased
            </b>
          </p>
        )} */}
        {this.props.indexVal == 0 ? (
          <p>
            <b>
              ORDER ID :{this.props.keyVal} | ITEMS WILL BE ADDED TO THIS ORDER.
              Prescription will be for all orders
            </b>
          </p>
        ) : null}
        {this.props.indexVal > 0 ? (
          <p>
            <b>
              ORDER ID :{this.props.keyVal} | You can remove items from this
              order. Prescription will be for all orders
            </b>
          </p>
        ) : null}
        {this.props.type_text == "other_recommended_products" ? (
          <p>
            <b>
              These items are extra recommendations to the customer, you can
              MOVE them to the prescription
            </b>
          </p>
        ) : null}
        {this.props.type_text == "normal_form" &&
        this.props.prescription_order_ids ? (
          <p>
            <b>
              User got these products in his form filled. You can MOVE these
              items to order
            </b>
          </p>
        ) : null}
        {this.props.type_text == "normal_form" &&
        !this.props.prescription_order_ids ? (
          <p>
            <b>
              User got these products in his form filled.Please make changes for
              prescription here itself.
            </b>
          </p>
        ) : null}
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Product</th>
              <th>Frequency</th>
              <th>Unit</th>
              <th>Days</th>
              <th>Pres Qty</th>
              <th>Cart Qty</th>
              {/* <th>Instructions</th> */}
            </tr>
          </thead>

          <tbody>
            {this.props.prescriptionsList &&
              this.props.prescriptionsList.map((elem, index) => {
                if (elem.product.prod_name.toUpperCase() === "FREE SAMPLE")
                  return null;
                return (
                  <tr key={index}>
                    <td>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={e =>
                          this.expandCollapseInstructions(
                            e,
                            elem,
                            undefined,
                            false
                          )
                        }
                      >
                        &gt;
                      </span>{" "}
                      {elem.product.prod_name}
                    </td>
                    <td>{elem.frequency}</td>
                    <td>{elem.unit}</td>
                    <td>{elem.days}</td>
                    <td>{elem.prescription_quantity}</td>
                    <td>{elem.cart_quantity}</td>
                    {/* <td>{elem.notes}</td> */}
                    <>
                      <td>
                        {!(
                          this.props.prescription_order_ids &&
                          !this.props.keyVal &&
                          this.props.form == "F"
                        ) &&
                        this.props.type_text !==
                          "other_recommended_products" ? (
                          <Button
                            className="btn"
                            variant="primary"
                            onClick={e => {
                              this.handleEdit(index);
                              this.collapseInstructions(e, elem);
                            }}
                            disabled={
                              this.state.editMode ||
                              (this.props.optional_rx &&
                                this.props.optional_rx == "1")
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        ) : null}
                      </td>
                      {this.props.type_text == "other_recommended_products" ? (
                        <td>
                          <Button
                            className="btn"
                            variant="primary"
                            onClick={() => this.handleMoveOther(index)}
                            disabled={this.state.editMode}
                          >
                            Move
                          </Button>
                        </td>
                      ) : null}
                      <td>
                        <Button
                          className="btn"
                          variant="danger"
                          onClick={e => {
                            this.handleDelete(index);
                            this.collapseInstructions(e);
                          }}
                          disabled={
                            this.state.editMode ||
                            (this.props.optional_rx &&
                              this.props.optional_rx == "1")
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                      {this.props.prescription_order_ids &&
                      !this.props.keyVal &&
                      (this.props.form == "F" ||
                        this.props.form == "DA" ||
                        this.props.form == "MA") ? (
                        <td>
                          <Button
                            className="btn"
                            variant="primary"
                            onClick={() => this.handleMove(index)}
                            disabled={this.state.editMode}
                          >
                            Move
                          </Button>
                        </td>
                      ) : null}
                    </>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {/* <div className="col-ins" data-test="table-container">
          <div className="label-inp">Enter Instructions</div>
          <textarea
            cols={70}
            rows={3}
            placeholder="Enter Instructions"
            type="text"
            required
            name="description"
            value={this.state.description}
            onChange={this.handleChange}
            //className="column"
          />
          <div className="dd">
            <DropdownButton
              variant="Secondary"
              id="dropdown-item-button"
              title=""
            >
              {this.state.descriptionList.map((elem, index) => (
                <Dropdown.Item
                  as="button"
                  key={index}
                  onClick={() => this.selectedInstruction(index)}
                >
                  {elem}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </div> */}

        {this.props.prescriptionsList &&
          this.props.prescriptionsList.length <= 0 && (
            <p className="warn-msg">
              Prescription list is empty...Add prescriptions to continue.
            </p>
          )}
        {this.props.prescriptionsList &&
          this.props.prescriptionsList.length > 0 && (
            <>
              {/* {this.props.type_category == "weight" ? (
                <Button
                  className="v-btn"
                  variant="primary"
                  onClick={e => this.handlePreview(e)}
                >
                  Preview
                </Button>
              ) : null} */}

              <Modal
                className="xyz"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.show}
                onHide={this.handleClose}
              >
                <Modal.Body className="mdl-body">
                  {!this.state.pdf && (
                    <div className="loader-cm">
                      Loading Prescription...Please Wait
                      <div className="Loader-box">
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={30}
                          width={30}
                        />
                      </div>
                    </div>
                  )}
                  <Iframe
                    url={this.state.pdf}
                    width="700px"
                    height="700px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={e => this.sendEmail(e)}
                    disabled={
                      this.state.emailState ||
                      ((this.props.form === "OF" || this.props.form === "O") &&
                        !this.state.confirmCheck)
                    }
                  >
                    Send Email
                  </Button>
                  <div className="userEmail">
                    <input
                      placeholder="Enter Email ID"
                      type="text"
                      required
                      name="userId"
                      value={this.state.email}
                      onChange={e => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                    {(this.props.form === "OF" || this.props.form === "O") && (
                      <div className="confirmed-check">
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check
                            value={this.state.confirmCheck}
                            onChange={this.handleCheck}
                            type="checkbox"
                            label="Mark Order as Confirmed"
                          />
                        </Form.Group>
                      </div>
                    )}
                    {this.state.emailState === "sending" && (
                      <div className="loader-cm inln-blk">
                        <div className="Loader-box">
                          <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={30}
                            width={30}
                          />
                        </div>
                      </div>
                    )}
                    {this.state.emailState === "sent" && (
                      <div className="loader-cm  inln-blk">
                        Email Sent Successfully
                      </div>
                    )}
                  </div>
                </Modal.Footer>
              </Modal>
            </>
          )}
      </>
    );
  }
}

export default PrescriptionViewer;

import React, { Component } from "react";
import { Button, Form, Modal, Col } from "react-bootstrap";
import { alertBox } from "../../common/PopupBox/PopupBox";
import CONFIG_CONSTANTS from "../../../constants/apiList";
import dataResource from "../../../constants/dataResource";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import axios from "axios";
import { store } from "../../../reducers/Persist";
import Loader from "react-loader-spinner";
import Combobox from "react-widgets/lib/Combobox";
import { iaEnabled } from "../../../helpers/features.helper";
import { toast } from "react-toastify";
import LoadingOverLay from "./LoadingOverlay";
import Axios from "axios";
var brand = ["MM", "BW", "LJ"];
var language = [
  "English",
  "Hindi",
  "Tamil",
  "Telugu",
  "Kannada",
  "Malayalam",
  "Marathi",
  "Bengali"
];

const brandToCategory = {
  MM: ["Hair", "Performance", "Skin", "Weight", "Beard", "Nutrition"],
  BW: ["Hair", "Skin", "Weight", "UTI", "PCOS", "Nutrition"],
  LJ: ["Weight", "General", "Hair", "Nutrition"]
};

const getLocalConfig = brand => {
  const LOCAL_CONFIG = {
    APPOINTMENT_SCHEDULER_POINTING: {
      SLOTS: CONFIG_CONSTANTS.url.SLOTS_AVAILABLE_3,
      BOOK: CONFIG_CONSTANTS.url.BOOK_APPOINTMENT,
      DOCTOR_SLOTS: CONFIG_CONSTANTS.url.SLOTS_AVAILABLE_3
    },
    EVENT_SCHEDULER_POINTING: {
      SLOTS: CONFIG_CONSTANTS.url.CATEGORY_SLOTS,
      BOOK: CONFIG_CONSTANTS.url.CS_BOOK_APPOINTENT,
      DOCTOR_SLOTS: CONFIG_CONSTANTS.url.DOCTOR_SLOTS
    }
  };
  if (iaEnabled(brand)) return LOCAL_CONFIG.EVENT_SCHEDULER_POINTING;
  else return LOCAL_CONFIG.APPOINTMENT_SCHEDULER_POINTING;
};

class CustomerServiceBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "Select Date",
      slot: "Select Slot",
      doctorList: [],
      filteredDoctorList: [],
      doctor: "",
      doctorMap: {},
      YourInputName: false,
      showDoctors: false,
      Hair: false,
      Performance: false,
      Skin: false,
      Weight: false,
      UTI: false,
      PCOS: false,
      Beard: false,
      brand: "MM",
      email: "",
      phone: "",
      language: "English",
      slot_time_period: 10,
      slot_length: 10,
      category: [],
      source_id: "123456789",
      patient_name: "",
      doctor_id: 2,
      source: "DA",
      slots_available: null,
      date_range: [],
      last_known: {},
      showInfo: false,
      slotBooking: false,
      paid: false,
      app: false,
      userId: 0,
      fetchCallLoading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getPreviousDoctor = e => {
      let category = [];
      let categoryObj = {
        Hair: "is_hc",
        Performance: "is_sh",
        Skin: "skin",
        Weight: "weight",
        UTI: "uti",
        PCOS: "pcos",
        Beard: "beard",
        General: "general",
        Nutrition: "nutrition"
      };
      Object.keys(categoryObj).map(item => {
        if (this.state[item] == true) category.push(categoryObj[item]);
      });
      let value = {
        brand: this.state.brand,
        email: this.state.email,
        phone: this.state.phone,
        category: category,
        patient_name: this.state.patient_name
      };
      if (
        this.state.patient_name == "" ||
        this.state.phone == "" ||
        this.state.email == ""
      ) {
        alertBox("Please fill details!");
        return;
      }
      if (
        !(
          this.state.Hair ||
          this.state.Performance ||
          this.state.Skin ||
          this.state.Weight ||
          this.state.UTI ||
          this.state.PCOS ||
          this.state.Beard
        )
      ) {
        alertBox("Please select category!");
        return;
      }
      this.setState({ fetchCallLoading: true });
      axios
        .post(CONFIG_CONSTANTS.url.GET_USER_DETAILS_FOR_CS, value)
        .then(resp => {
          const userId = resp?.data?.data?.userId;
          if (!userId) {
            return alertBox(
              `User does not have account on ${
                dataResource?.BRANDS_SHORT_TO_FULL_NAME?.[this.state.brand]
              }. Please ask user to create the account`
            );
          }
          this.setState({
            last_known: resp?.data?.data?.lastDoctorDetails,
            userId,
            showInfo: true
          });
        })
        .catch(err => {
          toast.error(err?.message || "Unable to fetch userDetails");
        })
        .finally(() => {
          this.setState({ fetchCallLoading: false });
        });
    };
  }
  Logout = () => {
    const handleClick = async () => {
      await Axios.get(CONFIG_CONSTANTS.AUTH.LOGOUT, { withCredentials: true });
      console.log("clearing loginKey");
      localStorage.removeItem("jwt");
      store.dispatch({ type: "CLEAR" });
      window.location.href = "/login";
    };
    return (
      <div style={{ textAlign: "right" }}>
        <button
          type="button"
          className="button button--red"
          onClick={handleClick}
        >
          Logout
        </button>
      </div>
    );
  };
  componentDidMount() {
    var date_range = [];
    var today = moment();
    date_range.push(moment().format("dddd, DD MMMM YY"));
    for (var i = 1; i < 14; i++) {
      let temp_date = today.add(1, "days").format("dddd, DD MMMM YY");
      date_range.push(temp_date);
    }
    /*axios.post(CONFIG_CONSTANTS.url.DOCTOR_LIST_URL, {}).then(response => {
      let arr = [];
      let temp = {};
      response.data.results &&
        response.data.results.map((item, index) => {
          arr.push(item["doctor_id"]);
          temp[item["doctor_id"]] = {
            name: item.doctor_name,
            is_men: item.is_men,
            is_women: item.is_women
          };
        });
      this.setState({
        doctorList: arr,
        doctorMap: temp
      });
      this.filterDoctors(this.state.brand);
    });
    */
    this.setState({ date_range: date_range });
  }

  getIsCategoryForBrand(categoryName) {
    const brand = this.state.brand;
    const categoriesForBrand = brandToCategory[brand];
    return categoriesForBrand?.includes(categoryName);
  }

  filterDoctors = (app, paid, language) => {
    //filter here
    paid = paid || this.state.paid;
    language = language || this.state.language;
    app = app || this.state.app;
    const doctorHash = this.state.doctorMap;
    const filteredDoctors = this.state.doctorList.reduce((acc, doctorId) => {
      const doctor = doctorHash[doctorId];
      if (app && doctor?.app !== 1) return acc;
      if (paid && doctor?.paid !== 1) return acc;
      if (language && doctor?.languages?.indexOf(language) === -1) return acc;
      acc.push({
        name: doctor?.name,
        value: doctorId
      });
      return acc;
    }, []);
    this.setState({
      newFilteredList: filteredDoctors
    });
  };
  fetchDoctors = evt => {
    const category = [];
    if (this.state.Hair) {
      category.push("is_hc");
    }
    if (this.state.Performance) {
      category.push("is_sh");
    }
    if (this.state.Skin) {
      category.push("skin");
    }
    if (this.state.Weight) {
      category.push("weight");
    }
    if (this.state.UTI) {
      category.push("uti");
    }
    if (this.state.PCOS) {
      category.push("pcos");
    }
    if (this.state.Beard) {
      category.push("beard");
    }
    const requestData = {
      categories: category,
      brand: this.state.brand
    };
    this.setState({ fetchCallLoading: true });
    axios
      .post(CONFIG_CONSTANTS.url.GET_DOCTORS_BY_CATEGORY_ES, requestData)
      .then(response => {
        let arr = [];
        let temp = {};
        response.data.data.doctorsByCategory &&
          response.data.data.doctorsByCategory.map((item, index) => {
            arr.push(item["doctorId"]);
            temp[item["doctorId"]] = {
              name: item.doctorName,
              phone: item.doctorPhone,
              gender: item.gender,
              only_order: item.onlyOrder,
              immediate: item.immediate,
              languages: item.languages,
              app: item.app,
              web_app: item.webApp,
              paid: item.paid
            };
          });
        this.setState({
          doctorList: arr,
          doctorMap: temp
        });
        this.filterDoctors(
          this.state.app,
          this.state.paid,
          this.state.language
        );
      })
      .catch(err => {
        console.log(err, "Error in fetching doctors");
        toast.error("Something went wrong");
      })
      .finally(() => {
        this.setState({ fetchCallLoading: false });
      });
  };
  handleChangeCheckbox = event => {
    const target = event.target;
    const checked = target.checked;
    const name = target.name;
    const showInfo = target.name === "showDoctors";
    this.setState(() => ({
      [target.name]: checked,
      showInfo
    }));
    //this.fetchDoctors();
  };
  handleSelectDoctorCheckboxChange = evt => {
    const target = evt.target;
    const checked = target.checked;
    const newDoctorId = this.state.doctor
      ? this.state.doctor
      : this.state.filteredDoctorList[0];
    this.setState(() => ({
      [target.name]: checked,
      doctor: newDoctorId,
      slots_available: false
    }));
    if (checked) {
      this.fetchDoctors();
    }
  };
  showSlotsAvailable = e => {
    e.preventDefault();
    if (
      this.state.patient_name == "" ||
      this.state.phone == "" ||
      this.state.email == ""
    ) {
      alertBox("Please fill details!");
      return;
    }
    if (
      !(
        this.state.Hair ||
        this.state.Performance ||
        this.state.Skin ||
        this.state.Weight ||
        this.state.UTI ||
        this.state.PCOS ||
        this.state.Beard
      )
    ) {
      alertBox("Please select category!");
      return;
    }

    let link =
      this.state.showDoctors && this.state.doctor
        ? getLocalConfig(this.state.brand)?.DOCTOR_SLOTS
        : getLocalConfig(this.state.brand)?.SLOTS;
    if (!link) return;
    let category = [];
    let categoryObj = {
      Hair: "is_hc",
      Performance: "is_sh",
      Skin: "skin",
      Weight: "weight",
      UTI: "uti",
      PCOS: "pcos",
      Beard: "beard"
    };
    Object.keys(categoryObj).map(item => {
      if (this.state[item] == true) category.push(categoryObj[item]);
    });
    let slot_time_period = this.state.slot_time_period;
    let slot_length = this.state.slot_length;
    if (this.state.paid) {
      slot_time_period = 15;
      slot_length = 15;
    }
    if (category && category.includes("weight")) {
      slot_time_period = 30;
      slot_length = 30;
    }
    let value = {
      date_range: this.state.date_range,
      brand: this.state.brand,
      email: this.state.email,
      phone: this.state.phone,
      category: category,
      slot_time_period,
      slot_length,
      grouping_length: 30,
      source_id: this.state.source_id,
      patient_name: this.state.patient_name,
      source: this.state.source,
      paid: this.state.paid,
      app: this.state.app,
      language: this.state.language,
      doctorID:
        this.state.showDoctors && this.state.doctor != ""
          ? this.state.doctor
          : null
    };
    this.setState({ fetchCallLoading: true });
    axios
      .post(link, value)
      .then(resp => {
        if (!Object.keys(resp?.data?.slots_available ?? {}).length) {
          alertBox("No Slots Available!");
          toast.error("No Slots Available!", { position: "top-right" });
          return;
        }
        let temp_slots_available = { ...resp.data.slots_available };
        for (const date in temp_slots_available) {
          const dateData = temp_slots_available[date];
          if (!Object.keys(dateData).length) {
            delete temp_slots_available[date];
          }
        }
        const dates = Object.keys(temp_slots_available);
        let date = "";
        let slot = "";
        let exactSlot = "";
        if (dates.length === 0) {
          alertBox("No Slots Available!");
          toast.error("No Slots Available!", { position: "top-right" });
          temp_slots_available = null;
        } else {
          date = Object.keys(temp_slots_available)[0];
          slot = Object.keys(temp_slots_available[date])[0];
          exactSlot = temp_slots_available[date][slot][0];
        }
        this.setState({
          date,
          slot,
          exactSlot,
          category: category,
          slots_available: temp_slots_available
        });
      })
      .catch(err => {
        toast.error(err.response?.data?.message || "Something went wrong");
      })
      .finally(() => {
        this.setState({ fetchCallLoading: false });
      });
  };
  bookAppointment = e => {
    e.preventDefault();

    if (
      !this.state.phone ||
      this.state.phone.length !== 10 ||
      !this.state.phone.match(/^[0-9]+$/)
    ) {
      toast.error("Please enter valid phone number");
      return;
    }

    if (!this.state.patient_name || this.state.patient_name.length < 3) {
      toast.error("Please enter valid patient name");
      return;
    }

    this.setState({ slotBooking: true });
    let slot_time_period = 10;
    let slot_length = 10;
    if (this.state.paid) {
      slot_time_period = 15;
      slot_length = 15;
    }
    if (this.state.category && this.state.category.includes("weight")) {
      slot_time_period = 30;
      slot_length = 30;
    }
    let value = {
      date: this.state.date,
      brand: this.state.brand,
      email: this.state.email,
      phone: this.state.phone,
      category: this.state.category,
      slot: this.state.exactSlot,
      slot_time_period,
      slot_length,
      source_id: "csbooking",
      patient_name: this.state.patient_name,
      source: this.state.source,
      paid: this.state.paid,
      app: this.state.app,
      language: this.state.language,
      user_id: this.state.userId,
      doctor_id:
        this.state.showDoctors && this.state.doctor != ""
          ? this.state.doctor
          : null
    };
    let link = getLocalConfig(this.state.brand)?.BOOK;
    if (!link) return;
    this.setState({ fetchCallLoading: true });
    axios
      .post(link, value)
      .then(resp => {
        if (resp.data.Slot_Full) {
          alertBox("Appointment could not be booked.Slots full!");
          this.setState({ slotBooking: false });
          return;
        }
        alertBox(`Appointment booked with ID ${resp.data.id}`);
        this.setState({ slotBooking: false });
      })
      .catch(err => {
        console.log("BOOK SLOT ERROR", err);
        const message = `Error: ${
          err.response?.data?.data?.error ||
          err.response?.data?.message ||
          "Unable to book appointment. Try again..."
        }`;
        alertBox(message);
        this.setState({ slotBooking: false });
      })
      .finally(() => {
        this.setState({ fetchCallLoading: false });
      });
  };
  handleChange = ({ target }) => {
    const value = target.value;
    const name = target.name;
    let slot = this.state.slot;
    let exactSlot = this.state.exactSlot;
    const showInfo =
      name === "slot" ||
      name === "doctor" ||
      name === "date" ||
      name === "language";
    if (name == "brand") {
      this.filterDoctors();
    } else if (name == "date") {
      slot = Object.keys(this.state.slots_available[value])[0];
      exactSlot = this.state.slots_available[value][slot][0];
    } else if (name == "slot") {
      slot = value;
      exactSlot = this.state.slots_available[this.state.date][value][0];
    } else if (name == "language") {
      slot = null;
      exactSlot = null;
    }
    this.setState({
      [name]: value,
      showInfo
      // showDoctors: false
    });

    if (name !== "slot" && name !== "date") {
      this.setState({
        slots_available: false,
        slot: null,
        exactSlot: null
      });
    } else {
      this.setState({
        slot,
        exactSlot
      });
    }

    if (name === "brand") {
      this.setState({
        Hair: false,
        Performance: false,
        Skin: false,
        Weight: false,
        UTI: false,
        PCOS: false,
        Beard: false
      });
    }
  };

  handleSubmit(e) {
    alert("The value is: " + this.input.value);
    e.preventDefault();
  }
  render() {
    return (
      <>
        <LoadingOverLay show={this.state.fetchCallLoading ?? false} />
        <div style={{ padding: "2%" }}>
          <h4 className="sectionHeading" style={{ color: "#3f51b5" }}>
            Customer Service Booking
          </h4>
          <this.Logout />
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={this.state.patient_name}
                  name="patient_name"
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  value={this.state.email}
                  name="email"
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="phone"
                  placeholder="Enter phone"
                  value={this.state.phone}
                  name="phone"
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={this.state.brand}
                  value={this.state.brand}
                  name="brand"
                  onChange={this.handleChange}
                >
                  {brand.map((item, index) => {
                    return (
                      <option key={index} value={item.label}>
                        {item}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Label>Category</Form.Label>
            </Form.Row>
            <Form.Row>
              {this.getIsCategoryForBrand("Hair") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.Hair}
                      onChange={this.handleChangeCheckbox}
                      name="Hair"
                    />
                  }
                  label="Hair"
                />
              )}
              {this.getIsCategoryForBrand("Performance") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.Performance}
                      onChange={this.handleChangeCheckbox}
                      name="Performance"
                    />
                  }
                  label="Performance"
                />
              )}
              {this.getIsCategoryForBrand("Skin") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.Skin}
                      onChange={this.handleChangeCheckbox}
                      name="Skin"
                    />
                  }
                  label="Skin"
                />
              )}
              {this.getIsCategoryForBrand("Weight") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.Weight}
                      onChange={this.handleChangeCheckbox}
                      name="Weight"
                    />
                  }
                  label="Weight"
                />
              )}
              {this.getIsCategoryForBrand("UTI") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.UTI}
                      onChange={this.handleChangeCheckbox}
                      name="UTI"
                    />
                  }
                  label="Bodycare"
                />
              )}
              {this.getIsCategoryForBrand("PCOS") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.PCOS}
                      onChange={this.handleChangeCheckbox}
                      name="PCOS"
                    />
                  }
                  label="PCOS"
                />
              )}
              {this.getIsCategoryForBrand("Beard") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.Beard}
                      onChange={this.handleChangeCheckbox}
                      name="Beard"
                    />
                  }
                  label="Beard"
                />
              )}
            </Form.Row>
            <Form.Group>
              <Button
                variant="info"
                type="button"
                onClick={this.getPreviousDoctor}
                hidden={this.state.showInfo}
              >
                Get Information
              </Button>
            </Form.Group>
            <div>
              {this.state.showInfo ? (
                <>
                  <div>
                    <b>Previous Doctor: </b>
                    {this.state.last_known.doctorName
                      ? this.state.last_known.doctorName
                      : "N/A"}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="paid-button-group">
                      <div
                        onClick={() =>
                          this.setState({ app: false, showDoctors: false })
                        }
                        style={{ padding: "4px 8px" }}
                        className={
                          !this.state.app
                            ? `brand-filter-button brand-filter-all active`
                            : `brand-filter-button brand-filter-all`
                        }
                      >
                        WEB
                      </div>
                      <div
                        onClick={() =>
                          this.setState({ app: true, showDoctors: false })
                        }
                        style={{ padding: "4px 8px" }}
                        className={
                          this.state.app
                            ? `brand-filter-button brand-filter-mm active`
                            : `brand-filter-button brand-filter-mm`
                        }
                      >
                        APP
                      </div>
                    </div>
                    <div className="paid-button-group paid-group">
                      <div
                        onClick={() =>
                          this.setState({ paid: false, showDoctors: false })
                        }
                        style={{ padding: "4px 8px" }}
                        className={`paid-button free ${
                          !this.state.paid ? "active" : ""
                        }`}
                      >
                        FREE
                      </div>
                      <div
                        onClick={() =>
                          this.setState({ paid: true, showDoctors: false })
                        }
                        style={{ padding: "4px 8px" }}
                        className={`paid-button paid ${
                          this.state.paid ? "active" : ""
                        }`}
                      >
                        PAID
                      </div>
                    </div>
                  </div>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      as="select"
                      type="language"
                      placeholder="Enter Language"
                      defaultValue={this.state.language}
                      value={this.state.language}
                      name="language"
                      onChange={this.handleChange}
                    >
                      {language.map((item, index) => {
                        return (
                          <option key={index} value={item.label}>
                            {item}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.showDoctors}
                          onChange={this.handleSelectDoctorCheckboxChange}
                          name="showDoctors"
                        />
                      }
                      label="Select Doctor"
                    ></FormControlLabel>
                    {this.state.showDoctors == true && (
                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Doctor</Form.Label>
                        {/* <Form.Control
                          as="select"
                          defaultValue={this.state.doctor}
                          value={this.state.doctor}
                          name="doctor"
                          onChange={this.handleChange}
                        >
                          {this.state.filteredDoctorList.map((item, index) => {
                            var value={item};

                            return (
                              <option key={index} value={item}>
                                {this.state.doctorMap[item].name}
                              </option>
                            );
                          })}
                          </Form.Control> */}
                        <Combobox
                          containerClassName="search-combobox"
                          hideCaret={true}
                          placeholder="Type Doctor name here..."
                          data={this.state.newFilteredList} //list display
                          onChange={value => {
                            //list search typing
                            // console.log("this is" + value);
                            this.setState({
                              doctor: value.value,
                              showInfo: true,
                              slots_available: false
                            }); //also may be last_known
                          }}
                          onSelect={val => {
                            //selection
                            this.setState({
                              ...this.state,
                              doctor: ""
                            });
                          }}
                          onKeyPress={e => {
                            if (e.key == "Enter") {
                              this.setState({
                                ...this.state,
                                doctor: ""
                              });
                            }
                          }}
                          filter="contains"
                          textField="name"
                          value={this.state.doctorID}
                        />
                      </Form.Group>
                    )}
                  </Form.Row>
                  <Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={this.showSlotsAvailable}
                    >
                      Find Slots
                    </Button>
                  </Form.Group>
                  {this.state.slots_available && (
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Appointment Date</Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue=""
                        value={this.state.date}
                        name="date"
                        onChange={this.handleChange}
                      >
                        {Object.keys(this.state.slots_available).map(
                          (item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          }
                        )}
                      </Form.Control>
                    </Form.Group>
                  )}
                  {this.state.slots_available &&
                    this.state.date != "Select Date" && (
                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Slot</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue=""
                          value={this.state.slot}
                          name="slot"
                          onChange={this.handleChange}
                        >
                          {Object.keys(
                            this.state.slots_available[this.state.date]
                          ).map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    )}
                  {this.state.slots_available &&
                    this.state.slot != "Select Slot" && (
                      <div className="paid-button-group paid-group">
                        {this.state.slots_available[this.state.date][
                          this.state.slot
                        ] &&
                          this.state.slots_available[this.state.date][
                            this.state.slot
                          ].map(exactSlot => {
                            return (
                              <div
                                key={exactSlot}
                                onClick={() => this.setState({ exactSlot })}
                                style={{ padding: "4px 8px" }}
                                className={`paid-button paid ${
                                  this.state.exactSlot === exactSlot
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {exactSlot}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  {this.state.slots_available &&
                    this.state.slot != "Select Slot" && (
                      <Form.Row>
                        <Button
                          variant="primary"
                          type="submit"
                          onClick={this.bookAppointment}
                          disabled={this.state.slotBooking}
                        >
                          {this.state.slotBooking ? (
                            <div style={{ display: "flex" }}>
                              <Loader type="TailSpin" width={20} height={20} />
                              Booking...
                            </div>
                          ) : (
                            "Book Appointment"
                          )}
                        </Button>
                      </Form.Row>
                    )}
                </>
              ) : null}
            </div>
          </Form>
        </div>
      </>
    );
  }
}

export default CustomerServiceBooking;

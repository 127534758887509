import React, { useRef } from "react";
import "./styles.scss";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ZoomInZoomOut = ({ children }) => {
  const transformRef = useRef(null);
  return (
    <TransformWrapper initialScale={1} ref={transformRef}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <div className="TransformWrapper__tools">
            {/* <button onClick={zoomIn}>Zoom In</button>
            <button onClick={zoomOut}>Zoom Out</button>
            <button onClick={resetTransform}>Reset Zoom</button> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%"
            }}
          >
            <TransformComponent>{children}</TransformComponent>
          </div>
        </>
      )}
    </TransformWrapper>
  );
};
export default ZoomInZoomOut;

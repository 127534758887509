import React, { useState } from "react";

const days1 = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun"
};
const reverseDays1 = { Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6, Sun: 7 };

const daysArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const headerArr = [
  "Meal Type",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

export default function ColRow({ col, setCol, setHeader }) {
  return (
    <div
      style={{
        display: "flex",
        marginTop: "20px",
        marginBottom: "20px",
        justifyContent: "center",
        fontWeight: "400"
      }}
    >
      {daysArr.map((val, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              setCol(index + 1);
              setHeader([
                headerArr[0],
                headerArr[index + 1],
                ...headerArr.slice(1, index + 1),
                ...headerArr.slice(index + 2)
              ]);
            }}
            style={{
              color: col === index + 1 ? "#154166" : "gray",
              borderBottom: col === index + 1 ? "2px solid #154166" : "",
              cursor: "pointer",
              padding: "5px"
            }}
          >
            {val}
          </div>
        );
      })}
    </div>
  );
}

export const CALL_SOCKET_EMITTER_STATUS = {
  CONNECTED: "connected",
  DOCTOR_MISSED: "doctor-missed",
  PATIENT_MISSED: "patient-missed",
  IN_ATTEMPT: "in-attempt",
  NONE: "none"
};

export const PRIORITY_LIST_CALL_SOCKET_EMITTER_STATUS = {
  [CALL_SOCKET_EMITTER_STATUS.CONNECTED]: 1,
  [CALL_SOCKET_EMITTER_STATUS.DOCTOR_MISSED]: 2,
  [CALL_SOCKET_EMITTER_STATUS.PATIENT_MISSED]: 2,
  [CALL_SOCKET_EMITTER_STATUS.IN_ATTEMPT]: 4,
  [CALL_SOCKET_EMITTER_STATUS.NONE]: 5
};

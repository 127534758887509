import React, { Component } from "react";
import { Card, CardHeader } from "@material-ui/core";
import HeaderSearchComponent from "./HeaderSearch";
import HeaderTextComponent from "./HeaderText";
import HeaderButtonsComponent from "./HeaderButtonsComponent";
import HeaderBottom from "./HeaderBottom";
import HeaderNextConsultationComponent from "./HeaderNextConsultation";
import HeaderDropdownComponent from "./HeaderDropdown";
import ViewImages from "../../Observations/Complaints/DoctorNotes/ViewImages";
import SendVideoLink from "../../Observations/Complaints/DoctorNotes/SendVideoLink";
import MedicalHistory from "../../Observations/Complaints/DoctorNotes/MedicalHistory";
import "./Header.scss";
import HeaderCallStatusButton from "./HeaderCallStatusButton";
class Header extends Component {
  render() {
    return (
      <div className="header__card">
        <div className="header__wrapper">
          <div className="header-wrapper-left">
            <HeaderSearchComponent />
          </div>
          <HeaderCallStatusButton />
          {/* <HeaderNextConsultationComponent /> */}
          <div
            style={{
              display: "flex",
              width: "70%",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <HeaderTextComponent />
            <HeaderButtonsComponent
              showReassignModal={() => this.props.showReassignModal()}
            />
            <div
              style={{
                display: "flex",
                width: "25%",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              {/* <ViewImages /> */}
              {/* <SendVideoLink />
              <MedicalHistory /> */}
              <HeaderDropdownComponent />
            </div>
          </div>
        </div>
        <HeaderBottom />
      </div>
    );
  }
}

export default Header;

import config from "../../../../../constants/apiList.js";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import dataResource from "../../../../../constants/dataResource";
import { store } from "../../../../../reducers/Persist";
import "./header-callbuttons.scss";
import { useSelector } from "react-redux";
import ViewAllUpdatesModal from "../../../PatientFile/AppointmentDetails/ViewAllUpdatesModal/index.js";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { addToGTM } from "../../../../../helpers/tagManager.helper.js";
import {
  GTM_ENTRY_POINTS,
  GTM_EVENTS
} from "../../../../../constants/tagManager.constants.js";

const statusObj = dataResource.statusListObj;

const HeaderCallStatusButton = () => {
  let remarksData =
    store?.getState()?.rxWriterReducer?.prescriptionData?.remarks;
  const prescriptionData = useSelector(
    state => state?.rxWriterReducer.prescriptionData
  );
  const [viewAllUpdatesOpen, setViewAllUpdatesOpen] = useState(false);
  const [btnClassName, setBtnClassName] = useState("");
  const [currStatus, setCurrStatus] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [patientFileStatusList, setpatientFileStatusList] = useState([]);
  useEffect(() => {
    remarksData && setCurrStatus(remarksData[remarksData?.length - 1]?.status);
    remarksData &&
      setSelectedOption(remarksData[remarksData?.length - 1]?.status);
    remarksData &&
      handleBtnClassName(remarksData[remarksData?.length - 1]?.status);
  }, [remarksData]);

  useEffect(() => {
    let prescriptionData = store.getState()?.rxWriterReducer?.prescriptionData;
    setCurrStatus(prescriptionData?.status);
  }, []);

  const handleBtnClassName = status => {
    if (
      status === "b" ||
      status === "cb" ||
      status === "cn" ||
      status === "co" ||
      status === "d" ||
      status === "l" ||
      status === "nr" ||
      status === "ni" ||
      status === "ns" ||
      status === "od" ||
      status === "op" ||
      status === "rs" ||
      status === "s" ||
      status === "so" ||
      status === "t" ||
      status === "wn"
    ) {
      setBtnClassName("other_status_buttons");
    } else if (
      status === "c" ||
      status === "csd" ||
      status === "csp" ||
      status === "na" ||
      status === "otc" ||
      status === "r" ||
      status === "re" ||
      status === "rf" ||
      status === "cq"
    ) {
      setBtnClassName("complete_status_buttons");
    } else if (
      status === "p" ||
      status === "pbt" ||
      status === "pc" ||
      status === "pi" ||
      status === "pl" ||
      status === "pp"
    ) {
      setBtnClassName("pending_status_buttons");
    }
  };
  const handleShowOptions = async () => {
    const appointmentId = store?.getState()?.rxWriterReducer.id;
    const reqBody = { appointmentId };
    const response = await axios.post(config.url.SEND_RX_STATUS_LIST, reqBody);
    setpatientFileStatusList(response?.data?.data?.rxStatusList);
    setShowOptions(prev => !prev);
  };
  const handleStatusUpdate = selectedVal => {
    let requiredData = {
      prescriptionData: store?.getState()?.rxWriterReducer.prescriptionData,
      id: store?.getState()?.rxWriterReducer.id,
      brand: store?.getState()?.rxWriterReducer.brand,
      form: store?.getState()?.rxWriterReducer.form,
      doctorId: store?.getState()?.userReducer.hash_id,
      app: store?.getState()?.rxWriterReducer.prescriptionData.app,
      call_duration: store?.getState()?.rxWriterReducer.call_duration,
      loggedInDr: store?.getState()?.rxWriterReducer.loggedInDr,
      patientDetails: store?.getState()?.rxWriterReducer.patientDetails,
      language: store?.getState()?.rxWriterReducer.language
    };
    // for (let i = 0; i < remarksList.length; i++) {
    //   if (this.state.remarksList[i]) {
    //     if (remarksStr.length > 0) {
    //       remarksStr += ", ";
    //     }
    //     remarksStr += dataResource.remarksList[i];
    //   }
    // }
    const payload = {
      doctorId: requiredData.doctorId,
      form: requiredData.form,
      id: requiredData.id,
      remarks: "",
      status: selectedVal
    };
    axios
      .post(config.url.UPDATE_STATUS_URL, payload)
      .then(res => {
        toast.success(res?.data?.body?.result);
        store.dispatch({
          type: "ADD_REMARK",
          data: { remarks: "remarksStr", status: selectedVal }
        });
        addToGTM({
          event: GTM_EVENTS?.STATUS_UPDATED,
          statusUpdatedData: {
            status: selectedVal,
            remarks: "NA",
            entryPoint: GTM_ENTRY_POINTS?.STATUS_UPDATED?.RX_WRITER_HEADER
          }
        });
        clevertap.event.push("Status Updated", {
          Source: requiredData.form,
          Category: requiredData?.prescriptionData?.category_label,
          "Appointment Status": payload.status,
          "Call Type":
            requiredData?.prescriptionData?.mode === "bw_video"
              ? "Video call"
              : "Voice Call",
          Appointments: requiredData?.id,
          Brand: requiredData?.brand,
          Language: requiredData?.language,
          "Doctor ID": requiredData?.prescriptionData?.doctor_id,
          "Doctor Name": requiredData?.patientDetails?.doctor_name,
          "Call Duration": requiredData?.call_duration
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div className="header-buttons__wrapper_a">
      <ViewAllUpdatesModal
        open={viewAllUpdatesOpen}
        onClose={() => {
          setViewAllUpdatesOpen(false);
        }}
      />
      <button onClick={handleShowOptions} className={btnClassName}>
        {statusObj[currStatus]}
        {showOptions ? (
          <MdKeyboardArrowUp
            className={`header_buttons__dropdown-icon ${btnClassName}`}
          />
        ) : (
          <MdKeyboardArrowDown
            className={`header_buttons__dropdown-icon ${btnClassName}`}
          />
        )}
      </button>
      {showOptions && (
        <div className="header_status_options-container">
          <div className="header_status_options-container__content">
            {patientFileStatusList &&
              patientFileStatusList?.map((val, index) => (
                <div
                  onClick={() => {
                    handleShowOptions();
                    handleStatusUpdate(val.value);
                  }}
                  key={index}
                  className="header_status_option"
                >
                  {statusObj[val.value]}
                </div>
              ))}
          </div>

          <div
            className="header-buttons__viewAllUpdates"
            onClick={() => setViewAllUpdatesOpen(true)}
          >
            View All Updates
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderCallStatusButton;

import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import CONFIG_CONSTANT from "../../../constants/apiList";
import { Dropdown } from "react-bootstrap";
import Table4 from "../../common/Tables/Table4.js";
import dataResource from "../../../constants/dataResource";
import Loader from "react-loader-spinner";

function AppointmentCounts(props) {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [metricType, setMetricType] = useState("dateRange");
  const [toDate, setToDate] = useState(
    moment().add(7, "days").format("YYYY-MM-DD")
  );
  const [apptType, setApptType] = useState("");
  const [doctorId, setDoctorId] = useState(0);
  const [attendStatus, setAttendStatus] = useState(0);
  const [language, setLanguage] = useState("");
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const columns = [
    {
      title: "Doctor",
      field: "doctorName"
    },
    {
      title: "Total",
      field: "totalAppt"
    },
    {
      title: "Immediate",
      field: "IAAppts"
    },
    {
      title: "Scheduled",
      field: "SAppts"
    },
    {
      title: "1st Attempt",
      field: "firstAttempt"
    },
    {
      title: "2nd Attempt",
      field: "secondAttempt"
    },
    {
      title: "Pending",
      field: "pending"
    },
    {
      title: "Addressed",
      field: "addressesAppts"
    },
    {
      title: "Completed",
      field: "completedAppts"
    }
  ];
  const getDoctorAppointmentTotal = async evt => {
    setShowLoader(true);
    const payload = {
      dateRange: {
        from: fromDate,
        to: toDate
      },
      doctorId,
      apptType,
      metricType,
      appointmentStatus,
      language
    };
    await axios
      .post(CONFIG_CONSTANT.url.APPOINTMENT_COUNTS, payload)
      .then(res => {
        setRows(res?.data?.result);
        setShowLoader(false);
      })
      .catch(err => {
        setShowLoader(false);
        console.log("ERROR IN /doctorPerformanceView", err);
      });
  };
  useEffect(() => {
    getDoctorAppointmentTotal();
  }, [metricType, fromDate, toDate, apptType, appointmentStatus, language]);
  return (
    <div className="doctor-performance-view">
      <div>
        <input
          type="radio"
          id="dateRange"
          name="metricType"
          onClick={() => setMetricType("dateRange")}
          defaultChecked
        />
        <label htmlFor="dateRange">Date Range</label>
        <input
          type="radio"
          id="now"
          name="metricType"
          onClick={() => setMetricType("now")}
        />
        <label htmlFor="now">Current Hour</label>
      </div>
      <div>
        <input
          type="date"
          className="input"
          value={fromDate}
          onChange={evt => setFromDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
        <input
          type="date"
          className="input"
          value={toDate}
          onChange={evt => setToDate(evt.target.value)}
          style={{ margin: "0px 5px" }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Dropdown className="m-2" onSelect={evt => setLanguage(evt)}>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {language || "Select Language"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="">ALL</Dropdown.Item>
            {dataResource?.languages.map(language => {
              return (
                <Dropdown.Item eventKey={language}>{language}</Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {showLoader ? (
        <div className="loader-cm" style={{ marginTop: "20px" }}>
          Loading... Please Wait
          <div className="Loader-box">
            <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
          </div>
        </div>
      ) : (
        <Table4
          title={"Appointment Counts"}
          rows={rows}
          cols={columns}
          pageSize={rows.length}
        />
      )}
    </div>
  );
}

export default AppointmentCounts;
